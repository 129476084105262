import api from '../api';
import {
  AppsResponse,
  BranchesResponse,
  BushinessTypesResource,
  CurrenciesResource,
  DaysResponse,
  LanguagesResponse,
  PaymentHistoryResponse,
  PaymentTypesResource,
  SalesByCategoryResponse,
  SalesByProductResponse,
  SalesBySectionResponse,
  SalesByUsersResponse,
  TaxRatesResponse,
  WorkingTypesResource,
} from './types';
import { ApiSuccessResponse } from '../types';

type LanguagesRes = ApiSuccessResponse<LanguagesResponse>;
const getLanguages = async () => api.get<LanguagesRes>('/languages');

type BranchesRes = ApiSuccessResponse<BranchesResponse>;
const getBranches = async () => api.get<BranchesRes>('/branches');

type AppsRes = ApiSuccessResponse<AppsResponse>;
const getApps = async () => api.get<AppsRes>('/apps');

const installApp = async (slug: string) => api.post(`apps/${slug}/install`);

type PaymentHistoryRes = ApiSuccessResponse<PaymentHistoryResponse>;
const getPaymentHistory = async () =>
  api.get<PaymentHistoryRes>('/app-payments');

type DaysRes = ApiSuccessResponse<DaysResponse>;
const getDays = async (
  page?: number,
  start_date?: string | null,
  end_date?: string | null,
) =>
  api.get<DaysRes>('/branches/days', {
    params: {
      filter: {
        start_date,
        end_date,
      },
      per_page: 10,
      page,
    },
  });

type SalesByUsersRes = ApiSuccessResponse<SalesByUsersResponse>;
const getSalesByUsers = async (
  page?: number,
  start_date?: string | null,
  end_date?: string | null,
) =>
  api.get<SalesByUsersRes>('/reports/sales-by-users', {
    params: {
      start_date,
      end_date,
      per_page: 10,
      page,
    },
  });

type SalesByProductRes = ApiSuccessResponse<SalesByProductResponse>;
const getSalesByProduct = async (
  page?: number,
  start_date?: string | null,
  end_date?: string | null,
) =>
  api.get<SalesByProductRes>('/reports/sales-by-products', {
    params: {
      start_date,
      end_date,
      per_page: 10,
      page,
    },
  });

type SalesBySectionRes = ApiSuccessResponse<SalesBySectionResponse>;
const getSalesBySection = async (
  page?: number,
  start_date?: string | null,
  end_date?: string | null,
) =>
  api.get<SalesBySectionRes>('/reports/sales-by-sections', {
    params: {
      start_date,
      end_date,
      per_page: 10,
      page,
    },
  });

type SalesByCategoryRes = ApiSuccessResponse<SalesByCategoryResponse>;
const getSalesByCategory = async (
  page?: number,
  start_date?: string | null,
  end_date?: string | null,
) =>
  api.get<SalesByCategoryRes>('/reports/sales-by-categories', {
    params: {
      start_date,
      end_date,
      per_page: 10,
      page,
    },
  });

const updateBranchStartDate = async (branchId: string) =>
  api.post(`/branches/${branchId}/start-day`);
const updateBranchEndDate = async (branchId: string) =>
  api.post(`/branches/${branchId}/end-day`);

type TaxRatesRes = ApiSuccessResponse<TaxRatesResponse>;
const getTaxRates = async () => api.get<TaxRatesRes>('/tax-rates');

type PaymentTypesRes = ApiSuccessResponse<PaymentTypesResource>;
const paymentTypes = async () => api.get<PaymentTypesRes>('/payment-types');

type BushinessTypesRes = ApiSuccessResponse<BushinessTypesResource>;
const businessTypes = async () => api.get<BushinessTypesRes>('/business-types');

type WorkingTypesRes = ApiSuccessResponse<WorkingTypesResource>;
const workingTypes = async () => api.get<WorkingTypesRes>('/working-types');

type CurrenciesRes = ApiSuccessResponse<CurrenciesResource>;
const currencies = async () => api.get<CurrenciesRes>('/currencies');

export default {
  getLanguages,
  getBranches,
  getDays,
  updateBranchStartDate,
  updateBranchEndDate,
  getTaxRates,
  paymentTypes,
  businessTypes,
  workingTypes,
  currencies,
  getApps,
  getPaymentHistory,
  getSalesByUsers,
  getSalesByProduct,
  getSalesBySection,
  getSalesByCategory,
  installApp,
};
