/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import {
  Box,
  TableContainer,
  Table,
  TableSortLabel,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
} from '@mui/material';

/** Components */
import LoadingState from '../../../../components/LoadingState';

/** Hooks */
import useIntegrations from '../../../../hooks/useIntegrations';
import useProductsMenu from '../../../../hooks/useProductMenu';
import getActiveLanguage from '../../../../helpers/getActiveLanguage';
import FormSelectSearchable from '../../../../components/FormSelectSearchable';
import SearchBar from '../../../../components/SearchBar';

function ProductsForm() {
  const { t } = useTranslation('integrations');
  const { allProducts, fetchAllProducts } = useProductsMenu();
  const {
    fetchProductSettings,
    settingsProductList,
    matchIntegrationProduct,
    unmatchIntegrationProduct,
    metaProductList,
    loading,
  } = useIntegrations();

  const [sort, setSort] = useState('');
  const [isSorted, setIsSorted] = useState({
    category_name: '',
    name: '',
    price: '',
  });
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState<{
    inputValue: string;
    isActive: boolean | null;
  }>({
    inputValue: '',
    isActive: null,
  });
  const [debouncedSearch, setDebouncedSearch] = useState({
    inputValue: '',
    isActive: true,
  });

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (value !== page) {
      setPage(value);
      fetchProductSettings('trendyol', value, searchValue.inputValue, sort);
    }
  };

  const handleClick = async (rowId: string, productId: string) => {
    if (productId) {
      await matchIntegrationProduct('trendyol', rowId, productId);
    } else {
      await unmatchIntegrationProduct('trendyol', rowId);
    }

    await fetchProductSettings('trendyol', page, searchValue.inputValue, sort);
  };

  const debouncedAndFilteredSearch = async () => {
    try {
      fetchProductSettings('trendyol', 1, searchValue.inputValue, sort);
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  const handleSort = async (type: string, sortType: string) => {
    let sortValue = '';

    if (type === 'category_name') {
      setIsSorted({
        ...isSorted,
        category_name: sortType,
      });
      sortValue = `${sortType},${isSorted.name},${isSorted.price}`;
    } else if (type === 'name') {
      setIsSorted({
        ...isSorted,
        name: sortType,
      });
      sortValue = `${isSorted.category_name},${sortType},${isSorted.price}`;
    } else {
      setIsSorted({
        ...isSorted,
        price: sortType,
      });
      sortValue = `${isSorted.category_name},${isSorted.name},${sortType}`;
    }

    sortValue = sortValue.replace(/^,+|,+$/g, '');
    sortValue = sortValue.replace(/,+/g, ',');

    setSort(sortValue);

    fetchProductSettings('trendyol', page, searchValue.inputValue, sortValue);
  };

  useEffect(() => {
    fetchAllProducts('', null, null);
  }, []);

  useEffect(() => {
    debouncedAndFilteredSearch();
  }, [debouncedSearch.inputValue]);

  if (loading) {
    return <LoadingState />;
  }

  return (
    <Box sx={{ py: 3, px: 1 }}>
      <SearchBar
        placeholder={t('placeholder')}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setDebouncedSearch={setDebouncedSearch}
      />
      <TableContainer sx={{ py: 1 }}>
        <Table sx={{ minWidth: 1280 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: 'primary.light' }}>
            <TableRow>
              <TableCell
                onClick={() => {
                  handleSort(
                    'category_name',
                    isSorted.category_name === ''
                      ? 'category_name'
                      : isSorted.category_name === 'category_name'
                      ? '-category_name'
                      : '',
                  );
                }}
              >
                <TableSortLabel
                  active={isSorted.category_name.length > 0}
                  direction={
                    isSorted.category_name === 'category_name' ? 'asc' : 'desc'
                  }
                >
                  {t('tables.productTable.category_name')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                onClick={() => {
                  handleSort(
                    'name',
                    isSorted.name === ''
                      ? 'name'
                      : isSorted.name === 'name'
                      ? '-name'
                      : '',
                  );
                }}
              >
                <TableSortLabel
                  active={isSorted.name.length > 0}
                  direction={isSorted.name === 'name' ? 'asc' : 'desc'}
                >
                  {t('tables.productTable.integration_food_name')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                onClick={() => {
                  handleSort(
                    'price',
                    isSorted.price === ''
                      ? 'price'
                      : isSorted.price === 'price'
                      ? '-price'
                      : '',
                  );
                }}
              >
                <TableSortLabel
                  active={isSorted.price.length > 0}
                  direction={isSorted.price === 'price' ? 'asc' : 'desc'}
                >
                  {t('tables.productTable.amount')}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                {t('tables.productTable.kitchrest_food_name')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {settingsProductList?.map(tableRow => (
              <TableRow>
                <TableCell>{tableRow.category_name}</TableCell>
                <TableCell>{tableRow.name}</TableCell>
                <TableCell>{tableRow.price.formatted}</TableCell>
                <TableCell>
                  <FormSelectSearchable
                    label=""
                    onChange={e => handleClick(tableRow.id, e)}
                    value={tableRow?.product?.id || ''}
                    options={allProducts
                      .map(p => ({
                        id: p.id,
                        text: `${getActiveLanguage(p.translations).name}  (${
                          p?.units?.find(u => u.is_default)?.amount
                            ?.formatted || '₺0,00'
                        })`,
                        group: getActiveLanguage(p.category.translations).name,
                      }))
                      .sort((a, b) => a.group.localeCompare(b.group))}
                    groupBy={option => option.group || ''}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          sx={{ margin: '10px' }}
          // @ts-ignore
          count={Math.ceil(
            (metaProductList?.total || 1) / (metaProductList?.per_page || 1),
          )}
          page={page}
          onChange={handleChange}
        />
      </TableContainer>
    </Box>
  );
}

export default ProductsForm;
