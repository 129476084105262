import React from 'react';
import { Box, Chip, Grid, Stack, Typography, useTheme } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

interface Props {
  title: string;
  count: string;
  extra: any;
  isShowTrend?: boolean;
  rate?: string;
}

function InfoCard({ title, count, extra, isShowTrend, rate }: Props) {
  const theme = useTheme();

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Stack
        sx={{
          padding: 3,
          border: '1px solid',
          borderRadius: 2,
          backgroundColor: 'white',
          borderColor: theme.palette.divider,
          boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
          '& pre': {
            m: 0,
            p: '16px !important',
            fontFamily: theme.typography.fontFamily,
            fontSize: '0.75rem',
          },
        }}
      >
        <Stack spacing={0.5}>
          <Typography variant="subtitle2" color="textSecondary">
            {title}
          </Typography>
          <Grid container alignItems="center">
            <Grid item>
              <Typography variant="h5" color="inherit">
                {count}
              </Typography>
            </Grid>
            {isShowTrend && (
              <Grid item>
                <Chip
                  variant="filled"
                  color="error"
                  icon={
                    <TrendingUpIcon
                      style={{ fontSize: '0.75rem', color: 'inherit' }}
                    />
                  }
                  label={rate || '70%'}
                  sx={{ ml: 1.25, pl: 1 }}
                  size="small"
                />
              </Grid>
            )}
          </Grid>
        </Stack>
        {/* <Box sx={{ pt: 2.25 }}> */}
        {/*  <Typography variant="caption" color="textSecondary"> */}
        {/*    You made an extra{' '} */}
        {/*    <Typography component="span" variant="caption"> */}
        {/*      {extra} */}
        {/*    </Typography>{' '} */}
        {/*    this year */}
        {/*  </Typography> */}
        {/* </Box> */}
      </Stack>
    </Grid>
  );
}

export default InfoCard;
