import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialState } from './types';
import { IdAndName } from '../../../models/General';

const initialState: InitialState = {
  list: [],
  days: [],
  salesByUsers: null,
  salesByProducts: null,
  salesBySections: null,
  salesByCategories: null,
  meta: [],
  apps: [],
  paymentHistories: [],
};

const branchSlice = createSlice({
  name: 'branchSlice',
  initialState,
  reducers: {
    getBranches: (state, action: PayloadAction<IdAndName[]>) => ({
      ...state,
      list: action.payload,
    }),
    getDays: (state, action) => ({
      ...state,
      days: action.payload.data,
      meta: action.payload.meta,
    }),
    getSalesByUsers: (state, action) => ({
      ...state,
      salesByUsers: action.payload.data,
    }),
    getSalesByProducts: (state, action) => ({
      ...state,
      salesByProducts: action.payload.data,
    }),
    getSalesBySections: (state, action) => ({
      ...state,
      salesBySections: action.payload.data,
    }),
    getSalesByCategories: (state, action) => ({
      ...state,
      salesByCategories: action.payload.data,
    }),
    getApps: (state, action) => ({
      ...state,
      apps: action.payload,
    }),
    installApp: (state, action) => ({
      ...state,
      apps: state.apps.map(item =>
        item.slug === action.payload
          ? {
              ...item,
              subscription_info: {
                is_installed: true,
              },
            }
          : item,
      ),
    }),
    getPaymentHistories: (state, action) => ({
      ...state,
      paymentHistories: action.payload,
    }),
  },
});

export const {
  getBranches,
  getDays,
  getSalesByUsers,
  getSalesByProducts,
  getSalesBySections,
  getSalesByCategories,
  getApps,
  getPaymentHistories,
  installApp,
} = branchSlice.actions;

export default branchSlice.reducer;
