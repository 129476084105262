/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Grid, Paper } from '@mui/material';

/** Components */
import UnPaidOrders from './Components/UnPaidOrders';
import PaidOrders from './Components/PaidOrders';
import SelectedOrders from './Components/SelectedOrders';

/** Types */
import { BasketProduct } from '../../../../../../models/Product';

/** Styles */
import styles from '../styles';

/** Helpers */
import { priceFormatter } from '../../../../../../helpers/moneyFormatter';

interface Props {
  list: BasketProduct[];
  handleAddPieceOrderClick: (
    id: string,
    itemTotalQuantity: number,
    itemId?: string,
  ) => void;
  handleDecreasePieceOrderClick: (id: string) => void;
  pieceValue: string;
  currencyCode: string;
}

function PaymentModalLeftSide({
  list,
  handleAddPieceOrderClick,
  handleDecreasePieceOrderClick,
  pieceValue,
  currencyCode,
}: Props) {
  const { t } = useTranslation('pos');

  return (
    <Paper
      style={{ overflow: 'auto' }}
      sx={styles.paymentModalPaper}
      elevation={3}
    >
      <Grid container justifyContent="space-between">
        <Grid> {t('paymentModal.installment')}</Grid>
        <Grid>{priceFormatter(Number(pieceValue), currencyCode)}</Grid>
      </Grid>
      <Divider />
      <br />
      <UnPaidOrders
        list={list}
        handleAddPieceOrderClick={handleAddPieceOrderClick}
        handleDecreasePieceOrderClick={handleDecreasePieceOrderClick}
        currencyCode={currencyCode}
      />
      <PaidOrders list={list} currencyCode={currencyCode} />
      <SelectedOrders list={list} currencyCode={currencyCode} />
    </Paper>
  );
}

export default PaymentModalLeftSide;
