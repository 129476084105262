/** Dependencies */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

/** Styles */
import { Grid, Box } from '@mui/material';
import styles from '../../Register/styles';

/** Components */
import Button from '../../../components/Button';
import FormPasswordInput from '../../../components/FormPasswordInput';

/** Types */
import {
  changePasswordDefaultValues,
  ChangePasswordFormValues,
  validationChangePasswordSchema,
} from './types';

/** Hooks */
import useProfile from '../../../hooks/useProfile';

function ChangePasswordForm() {
  const { t } = useTranslation('auth');
  const { changePassword } = useProfile();

  const [loading, setLoading] = useState(false);

  const { handleSubmit, control, reset } = useForm<ChangePasswordFormValues>({
    defaultValues: changePasswordDefaultValues,
    resolver: validationChangePasswordSchema,
  });

  const handleClick = () => {
    handleSubmit(async values => {
      setLoading(true);
      try {
        await changePassword({ ...values });
        reset(changePasswordDefaultValues);
        // eslint-disable-next-line no-empty
      } catch (err) {
      } finally {
        setLoading(false);
      }
    })();
  };

  return (
    <Box
      sx={{
        py: 10,
        px: 10,
        paddingX: {
          xxs: 0,
          xs: 0,
          sm: 0,
          md: 3,
          lg: 15,
          xl: 20,
        },
      }}
    >
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="current_password"
              control={control}
              render={({ field, fieldState }) => (
                <FormPasswordInput
                  sx={styles.field}
                  label={t('profile.change_password.current_password.label')}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState }) => (
                <FormPasswordInput
                  sx={styles.field}
                  label={t('profile.change_password.password.label')}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="password_confirmation"
              control={control}
              render={({ field, fieldState }) => (
                <FormPasswordInput
                  sx={styles.field}
                  label={t(
                    'profile.change_password.password_confirmation.label',
                  )}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container justifyContent="right">
        <Button
          onClick={handleClick}
          sx={styles.submit}
          variant="contained"
          size="medium"
        >
          {t('profile.change_password.submit')}
        </Button>
      </Grid>
    </Box>
  );
}

export default ChangePasswordForm;
