import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/Modal';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
function ContractModal({ open, onClose, onConfirm }: Props) {
  const { t } = useTranslation('auth');

  return (
    <Modal
      title={t('register.contract.confirmation_modal.title')}
      open={open}
      onClose={onClose}
      positiveAction={t('register.contract.confirmation_modal.confirm')}
      negativeAction={t('register.contract.confirmation_modal.cancel')}
      onPositiveClick={onConfirm}
      onNegativeClick={onClose}
    >
      <Typography>
        KULLANIM KOŞULLARI
        <br />
        <br />
        <p>
          <b>Madde 1.</b>
          Taraflar İşbu Kullanıcı Sözleşmesi (&quot;Sözleşme&quot;), Kitchrest
          A.Ş (&quot;Şirket&quot;) ile adresinde yer alan siteye
          (&quot;Site&quot;) kullanıcı (&quot;Kullanıcı(lar)&quot;) olarak
          kaydolan kişiler arasında akdedilmektedir. Sözleşme, Kullanıcı
          tarafından elektronik ortamda kabulü ile birlikte yürürlüğe girecek
          olup; taraflarca Sözleşme&apos;de belirtilen usuller doğrultusunda
          sona erdirilmediği sürece yürürlükte kalmaya devam edecektir.
        </p>
        <p>
          <b>Madde 2.</b>
          Sözleşme&apos;nin Konusu ve Kapsamı İşbu Sözleşme, Kullanıcı
          tarafından Site üzerinden erişilen bulut tabanlı finans ve iş yönetimi
          uygulamasından (&quot;Uygulama&quot;) faydalanılmasına ve Kullanıcı
          tarafından Site&apos;ye yüklenen verilere (&quot;İçerik&quot;) ilişkin
          koşul ve şartlar ile ilgili tarafların hak ve yükümlülüklerinin
          belirlenmesi amacıyla akdedilmektedir. Site kapsamında Şirket
          tarafından Kullanıcılara sunulan kullanım koşulları, kural ve şartlar
          da işbu Sözleşme&apos;nin bir parçasını oluşturmakta olup burada yer
          alan hak ve yükümlülüklerle birlikte tarafların hak ve
          yükümlülüklerinin tamamını oluşturmaktadır.
        </p>
        <p>
          <b>Madde 3.</b>
          <br />
          Tarafların Hak ve Yükümlülükleri
          <ul>
            <li>
              <b>3.1</b>
              Kullanıcı, Uygulama&apos;dan faydalanmak için Şirket tarafından
              talep edilen bilgileri tam, doğru ve güncel bir şekilde sağlayarak
              işbu Sözleşme&apos;yi onaylaması gerektiğini beyan eder. Kullanıcı
              statüsünün tesisi sırasında sağlanan bilgilerde herhangi bir
              değişiklik olması halinde, söz konusu bilgiler derhal
              güncellenecektir. Bu bilgilerin eksik veya gerçeğe aykırı olarak
              verilmesi ya da güncel olmaması nedeniyle Site ya da
              Uygulama&apos;ya erişim sağlanamamasından ve bunlardan
              faydalanılamamasından Şirket sorumlu değildir.
            </li>
            <li>
              <b>3.2</b>
              Kullanıcı, 18 yaşını doldurmuş olduğunu ve işbu Sözleşme&apos;yi
              akdetmek için gereken yasal ehliyete sahip bulunduğunu beyan eder.
              Kullanıcı&apos;nın Site&apos;ye bir işletme adına erişiyor olması
              halinde, Kullanıcı buna ilişkin gerekli yetkiyi haiz olduğunu
              kabul ve beyan eder. Bu durumda Kullanıcı statüsü ile hak ve
              yükümlülükler söz konusu işletmeye ait olacaktır.
            </li>
            <li>
              <b>3.3</b> Kullanıcı, tek bir Kullanıcı hesabı tesis etme hakkına
              sahip olup, Kullanıcı hesabının Şirket tarafından askıya alınması
              veya sona erdirilmesini takiben Kullanıcı tarafından aynı veya
              başka bilgiler kullanılarak ikinci bir hesap tesis edilmesi
              yasaktır. Şirket’in herhangi bir gerekçe göstermeksizin, tamamen
              kendi iradesine tabi olarak Kullanıcı hesabına açılmasını reddetme
              hakkı saklıdır.
            </li>
            <li>
              <b>3.4</b>
              Kullanıcı, Site&apos;ye erişim için kullanılan e-posta adresi veya
              telefon numarası ve şifresinin gizliliği ve güvenliğinin
              korunmasından tamamen kendisi sorumlu olacaktır. Söz konusu
              bilgilerin kullanımı ile gerçekleştirilen her türlü faaliyetin
              Kullanıcı tarafından gerçekleştirildiği kabul edilecek, bu
              faaliyetlerden doğan her türlü yasal ve cezai sorumluluk
              Kullanıcı&apos;ya ait olacaktır. Kullanıcı, şifresinin yetkisiz
              kullanımı veya güvenliğinin başka şekilde ihlal edilmesi
              durumunda, bu durumu derhal Şirket&apos;e bildirecektir.
            </li>
            <li>
              <b>3.5</b> Kullanıcı, Uygulama’yı yalnızca yasalara uygun
              faaliyetleri için kullanacağını,işbu Sözleşme, ekleri,
              yürürlükteki mevzuat ve Uygulama’ya ilişkin olarak Site’de
              öngörülen diğer hüküm ve koşullara uygun davranacağını kabul ve
              taahhüt eder. Kullanıcı, Uygulama ve Site’yi üçüncü kişilere
              hizmet sunmak için yetkili olduğu sürece üçüncü kişiadına
              kullanabilecektir. Kullanıcı bu kapsamda, söz konusu kişilerin de
              işbuSözleşme ve kendisi için geçerli olan diğertüm hükümlere uygun
              davranmasını sağlayacaktır.
            </li>
            <li>
              <b>3.6</b>
              Kullanıcı, muhtelif zamanlarda Uygulama’yı kullanması için üçüncü
              bir kişiyi (“Yetkilendirilmiş Kullanıcı”) yetkilendirebilir.
              Yetkilendirilmiş Kullanıcı&apos;nın kim olacağı ve Uygulama
              kapsamındaki yetki seviyesi Kullanıcı tarafından belirlenecektir.
              Kullanıcı, Yetkilendirilmiş Kullanıcılar&apos;ın Uygulama&apos;yı
              kullanmasından sorumlu olup Yetkilendirilmiş Kullanıcılar&apos;ın
              Uygulama&apos;ya erişimini her zaman kontrol edecek ve
              Yetkilendirilmiş Kullanıcı&apos;nın Uygulama&apos;ya erişim
              seviyesini her zaman ve bir neden bağlı olmaksızın
              değiştirebilecek veya erişimini iptal edebilecektir. Kullanıcı ve
              Yetkilendirilmiş Kullanıcı arasında Uygulama&apos;ya erişime
              ilişkin olarak bir uyuşmazlık çıkması halinde Yetkilendirilmiş
              Kullanıcı&apos;nın Uygulama veya İçerik&apos;e erişimi ve erişim
              seviyesine ilişkin kararı Kullanıcı verecektir.
            </li>
            <li>
              <b>3.7</b>
              Kullanıcı tarafından paylaşılan İçerik, Kullanıcı&apos;nın
              mülkiyetinde olup İçerik&apos;e ilişkin tüm sorumluluk
              Kullanıcı&apos;ya aittir. Şirket, işbu Sözleşme kapsamında
              Kullanıcı tarafından kendisine sağlanan lisans kapsamında
              İçerik&apos;i kullanma hakkını haizdir. İçerik&apos;e veya
              İçerik&apos;in yol açabileceği kayıp veya zararlara ilişkin olarak
              Şirket sorumlu tutulamayacak olup, sayılanlarla sınırlı
              olmaksızın, hukuka uygunluk, İçerik&apos;in doğruluğu, faturaların
              ödenmesi, tahsilatın yapılması, finansal işlemler ve vergi
              bildirimine ilişkin olarak Şirket&apos;in hiçbir sorumluluğu
              bulunmamaktadır. Finansal işlemler, vergi ve diğer hususlar
              hakkında ilgili mevzuata uygunluğun sağlanması münhasıran
              Kullanıcı&apos;nın sorumluluğundadır. Kullanıcı, Şirket&apos;in
              finansal düzenlemeler başta olmak üzere, yürürlükteki mevzuattan
              kaynaklanan gereksinimlere dayanarak İçerik&apos;i
              Uygulama&apos;dan ve sistemlerinden silebileceğini ve
              Şirket&apos;in kayıp veriler de dahil olmak üzere bu kapsamda
              meydana gelebilecek zararlardan sorumlu olmadığını kabul eder.
            </li>
            <li>
              <b>3.8</b> Kullanıcı, Şirket’in veya Uygulama’nın üçüncü kişiler
              tarafından barındırılıyor olması halinde bu üçüncü kişilerin
              bilgisayar ve ağ sistemlerinin güvenlik ve bütünlüğünü tehlikeye
              düşürecek faaliyetlerde bulunmayacağını, Uygulama’yı,
              Uygulama’nın, Site’nin veya hizmetlerin sunulduğu diğer
              sistemlerin işlerliğine ya da Uygulama ve Site’den faydalanan
              diğer kullanıcıların bunlardan faydalanmasına engel olacak veya
              bunlara zarar verecek şekilde veya hatalı kullanmamayı,
              Uygulama’nın barındırıldığı bilgisayar sistemlerine veya
              Uygulama’ya kendisine tanınan erişim kapsamı dışında yetkisiz
              erişim sağlamayacağını, Site’ye Şirket’in ve üçüncü kişilerin
              bilgisayar sistemlerine, cihazlarına ve yazılımlarına zarar
              verecek dosyalar veya hukuka aykırı İçerikler (Kullanıcı’nın
              üzerinde kullanma hakkı olmayan telif hakkıyla korunan veya ticari
              sır niteliğinde olan İçerikler ve diğer materyaller de dahil olmak
              üzere) aktarmayacağını ve yüklemeyeceğini, hizmetlerin
              sunulmasında veya Site’nin çalışmasındaki kullanılan bilgisayar
              programlarını olağan kullanım için mutlak olarak gerekli olmadığı
              sürece değiştirmeyeceğini, kopyalamayacağını, uyarlamayacağını,
              çoğaltmayacağını, kaynak kodu oluşturmayacağını ya da tersine
              mühendislik işlemleri yapmayacağını kabul ve taahhüt eder.
            </li>
            <li>
              <b>3.9</b> Kullanıcı,Uygulama’yı kullanmasının aylık işlem ve
              saklama hacimleri de dahil olmak üzere kısıtlamalara tabi
              olabileceğini kabul eder. Söz konusu kısıtlamalar Uygulama içinde
              belirtilecektir.
            </li>
            <li>
              <b>3.10</b> Kullanıcı, Uygulama’ya yüklenen İçerikler’in
              kopyalarını saklayacaktır.Şirket, veri kaybını önlemek için
              gerekli politika ve usullere uymakla beraber, İçerik’inkaybının
              söz konusu olmayacağına dair garanti vermemektedir. Şirket,nasıl
              ortaya çıktığına bakılmaksızın İçerik’in kaybından sorumlu
              değildir.
            </li>
            <li>
              <b>3.11</b> Şirket, Kullanıcı tarafından kendisi ile paylaşılan
              bilgi ve verileri işbuSözleşme’ye ek niteliğinde olan “Gizlilik
              Politikası” kapsamında saklayacak ve kullanacaktır.
              Kullanıcı,Şirket’in yürürlükteki mevzuat uyarınca yetkili
              makamlardan talep gelmesi halinde Kullanıcı’nın kendisinde bulunan
              bilgilerini ilgili yetkili makamlarla paylaşabileceğini kabul
              eder. Bunun dışında Kullanıcı’ya ve Kullanıcı tarafından Site
              üzerinden gerçekleştirilen işlemlere ait bilgiler Kullanıcı’nın
              güvenliği, Şirket’in yükümlülüklerini ifa ve bazı istatistiki
              değerlendirmeler için kullanılabilecektir. Şirket ayrıca, fatura
              gönderme, ödeme bilgisi paylaşma gibi talep edilen hizmetleri
              sağlamak için İçerik’i diğer kullanıcılarla paylaşma hakkına
              sahiptir. Kullanıcı, diğer kullanıcılara ait İçerikler’i kullanmak
              istemesi halinde ilgili kullanıcıların onayını alacak ve söz
              konusu İçerikler’i diğer kullanıcı tarafından verilen onay
              kapsamında kullanacaktır. Bu bilgiler ayrıca bir veritabanı
              üzerinde tasnif edilip muhafaza edilebilecek ve Şirket,
              Kullanıcı’nın söz konusu kullanım ve işlem bilgilerini performans
              değerlendirmeleri, Şirket’in ve iş ortaklarının pazarlama
              kampanyaları, yıllık raporlar ve benzeri işlemler için bunlar için
              gerekli olan süre boyunca söz konusu verilerin anonim hale
              getirilmesini takiben kullanabilecektir. Kullanıcı, İçerikve diğer
              bilgilerin Şirket veya üçüncü kişiler tarafından Türkiye’de veya
              yurt dışında bulunan veri merkezlerinde saklanabileceğini kabul
              eder.
            </li>
            <li>
              <b>3.12</b> Uygulama’ya ilişkin teknik sorunlar çıkması halinde
              Kullanıcı, Şirket’le iletişime geçemeden önce sorunu tespit ve
              teşhis etmek için makul çabayı sarf edecektir. Kullanıcı’nın
              teknik destek ihtiyacının devam etmesi halinde gerekli destek
              Site, Uygulama yahut diğer uygun kanallardan sağlanacaktır.
            </li>
            <li>
              <b>3.13</b>
              Kullanıcı&apos;ya site üzerinden iletişim araçları (forum, sohbet
              araçları veya mesaj merkezi gibi) sağlanması durumunda, Kullanıcı
              bu iletişim araçlarını yalnızca hukuka uygun amaçlar çerçevesinde
              kullanacağını beyan ve taahhüt eder. Kullanıcı, söz konusu
              iletişim araçlarını ürün ve hizmet satışı, karşı tarafın rızası
              olmadan gönderilen e-postalar, üçüncü kişilerin yazılım ve
              bilgisayar sistemlerine zarar verebilecek dosyalar, diğer
              kullanıcılar bakımından hakaret içeren içerikler yahut hukuka
              aykırı her türlü içerik de dahil olmak üzere Uygulama amacı
              dışındaki materyaller paylaşmak için kullanmayacaktır. Kullanıcı,
              site üzerinden gerçekleştirdiği her türlü iletişim bakımından bunu
              yapma yetkisini haiz olduğunu taahhüt eder. Şirket&apos;in site
              üzerinden gerçekleştirilen iletişimlerin uygunluğunu ya da
              bunların Uygulama&apos;nın kullanım amaçlarına yönelik olduğunu
              kontrol etme yükümlülüğü bulunmamaktadır. Uygulama üzerinden
              erişilen veya Uygulama&apos;ya ilişkin olarak kullanılan diğer web
              tabanlı iletişim araçları bakımından da, Kullanıcı site üzerinden
              sağlanan iletişim araçlarını kullanırken göstermekle yükümlü
              olduğu özeni gösterecektir. Şirket, site üzerinden sağladığı
              iletişim araçlarını kendi takdirine bağlı olarak dilediği zaman
              kaldırma hakkına sahiptir.
            </li>
            <li>
              <b>3.14</b> Şirket, herhangi bir ön bildirimde bulunmaksızın işbu
              Sözleşme ve eklerini revize etme hakkına sahip olup bu hakkın
              kullanılması halinde ilgili değişiklik Kullanıcı tarafından
              Site’nin bir sonraki kullanımı ile birlikte yürürlüğe girecektir.
              Kullanıcı’nın söz konusu değişiklikleri kabul etmemesi halinde
              işbuSözleşme’yi aşağıda belirtilen şekilde feshetme hakkı
              saklıdır.
            </li>
            <li>
              <b>3.15</b> Kullanıcı, Kullanıcı hesabını ve işbu Sözleşme ile
              Site kullanımından doğan hak ve yükümlülüklerini herhangi bir
              şekilde üçüncü bir kişiye devir veya temlik edemez.
            </li>
            <li>
              <b>3.16</b> Kullanıcı’nın işbu Sözleşme ve Site kapsamında yer
              alan diğer koşul ve şartlar ile bu kapsamdaki beyan ve
              taahhütlerine aykırı davranması halinde Şirket Kullanıcı’nın
              üyeliğini askıya alma veya Sözleşme’yi aşağıda belirtilen şekilde
              feshederek kullanıcı statüsünü bu şekilde sona erdirme hakkına
              sahip olacaktır. Böyle bir durumda Şirket’in söz konusu
              aykırılıktan doğan zararlarının Kullanıcı’dan talep hakkısaklıdır.
            </li>
          </ul>
        </p>
        <p>
          <b>Madde 4.</b>
          Ödeme Koşulları
          <ul>
            <li>
              <b>4.1</b> Kullanıcı Uygulama’dan ancak Site’de beyan edilen
              ücretleri yine Site’de beyan edilen ödeme koşulları ve araçları
              ile tam ve eksiksiz olarak ödemesi karşılığında
              faydalanabilecektir.
            </li>
            <li>
              <b>4.2</b> Kullanıcı, Uygulama’yı Site’de belirtilecek süre
              boyunca ücret ödemeden kullanabilecektir. Söz konusu deneme
              periyodunun bitimiyle Kullanıcı’nın üyeliği, türü hizmet seviyesi,
              işlevsellik, kampanyalar ya da sözleşme süresine göre belirlenecek
              ücretli üyelik haline gelecektir. Uygulama’ya ilişkin ücretler,
              ödeme koşulları, ücretlerin yürürlük tarihleri Site’nin ilgili
              bölümlerinde ilan edilecektir. Kullanıcı, kendi isteğine bağlı
              olarak üyelik paketini yükseltebilecek veya düşürebilecektir. Buna
              ilişkin talepler, Şirkettarafından aksi öngörülmedikçe ilgili
              üyelik döneminin sonunda gerçekleştirilecektir. Kullanıcı’nın
              üyelik süresi boyunca üyelik paketine ilişkin ücret ve ödeme
              koşullarında yapılacak değişiklikler, Kullanıcı’nın üyelik
              döneminin sona ermesine dek uygulanmayacak, yeni ücretler ve ödeme
              koşulları yeni üyelik döneminin başlamasıyla geçerli olacaktır.
              Üyelik dönemi boyunca Sözleşme’nin feshi de dahil olmak üzere
              üyeliğin herhangi bir nedenle sona ermesi halinde geri ödeme
              yapılmayacaktır.
            </li>
            <li>
              <b>4.3</b> Kullanıcıtarafından periyod bitiminden 14 (on dört) gün
              öncesine kadar aksi talep edilmediği sürece her periyodun
              bitiminde Kullanıcı’nın üyeliği otomatik olarak yenilenecektir.
            </li>
            <li>
              <b>4.4</b> Şirket, Kullanıcıtarafından iletilen iletişim adresine
              üyelik döneminin başlangıcında kullanım ücretlerine ilişkin
              faturayı iletecektir. Tüm faturalar, sonradan ödemeli üyelik
              halinde bir önceki üyelik dönemine ilişkin ücretleri, ön ödemeli
              üyeliklerde ise bir sonraki üyelik dönemine ilişkin ücretleri
              içerecektir. Kullanıcı, faturadaki ilgili tutarı fatura tarihini
              takip eden 14 (on dört) gün içinde ödeyecektir. İlgili ücretlere
              ilişkin vergi ve harçların ödenmesinden Kullanıcı sorumludur.
            </li>
            <li>
              <b>4.5</b> Kullanıcı, Şirket veya Şirket tarafından onaylanmış
              üçüncü kişiler üyeliğe ve ödemeye ilişkin işlemler veya banka
              entegrasyonunu ve ilgili güncellemeleri gerçekleştirmek için
              Kullanıcı’nınkredi kartı ve ödeme bilgilerini saklayabilecektir.
            </li>
          </ul>
        </p>
        <p>
          <b>Madde 5.</b>
          Fikri Mülkiyet Hakları
          <ul>
            <li>
              <b>5.1</b> Site ve Uygulama üzerindeki her türlü hak, mülkiyet ve
              menfaat Şirket’e aittir. İşbu Sözleşme kapsamında Kullanıcı’ya
              Site ve Uygulama’yı kullanmak üzere kişiye özel, dünya çapında,
              telifsiz, devredilemez ve münhasır olmayan lisans verilmektedir.
              Sözleşme ve Site’ye ilişkind iğer koşullardaki hiçbir hüküm Site
              ve Uygulama’ya ilişkin hakların ve menfaatlerin Kullanıcı’ya
              devredildiği şeklinde yorumlanamaz. Kullanıcı,işbu Sözleşme
              kapsamında Şirket’e Kullanıcı’nın Uygulama’ya erişimi,Uygulama’yı
              kullanması ve hizmetlerin sağlanmasına yönelik diğer amaçlarla,
              bilgilerinin ve İçerik’in kullanılması, kopyalanması, iletilmesi,
              saklanması ve yedeğinin alınması için kullanım hakkı
              tanımaktadır.Şirket, hizmetlerin sağlanması amacıyla İçerik’e
              ilişkin olarak üçüncü kişig eliştiricilere alt lisans verme
              hakkını haizdir.
            </li>
            <li>
              <b>5.2</b> Kullanıcı, hiçbir şekilde ve nedenle Site’yi veya
              Uygulama’yı kopyalama, değiştirme,çoğaltma, ters mühendisliğe tabi
              tutma, geri derleme ve sair şekillerde Site üzerindeki yazılımın
              kaynak koduna ulaşma, Site’den işleme eser oluşturmahakkına sahip
              değildir. Site’ye ilişkin tarayıcı ve içeriklerin herhangi bir
              şekilde değiştirilmesi, Şirket’in açık izni olmaksızın Site’ye
              veya Site’den link verilmesi kesinlikle yasaktır.
            </li>
            <li>
              <b>5.3</b> Kullanıcı, herhangi bir şekilde Şirket’in (veya
              bağlışirketlerinin) ticari unvanını, markasını, hizmet markasını,
              logosunu, alan adını vs. kullanmayacaktır.
            </li>
          </ul>
        </p>
        <p>
          <b>Madde 6.</b>
          Sorumluluğun Kısıtlanması
          <ul>
            <li>
              <b>6.1</b> Site kapsamındaki Uygulama, yazılım ve sair içerikler
              “OLDUĞU GİBİ” sunulmakta olup, bu kapsamda Şirket’in Uygulama,
              yazılım ve içeriğindoğruluğu,tamlığı ve güvenilirliği ile ilgili
              herhangi bir sorumluluk ya da taahhüdü
              bulunmamaktadır.Kullanıcı,Şirket’inayrıcaİçerik ve diğerKullanıcı
              verilerinin birbiriyle ilişkisine dair taahhütte bulunmadığını
              anlar ve kabul eder. ŞirketUygulama’nınkullanımının kesintisiz ve
              hatasızolduğunu taahhüt etmemektedir. Şirket,Uygulama’nın 7/24
              erişilebilir ve kullanılabilirolmasını hedeflemekle birlikte
              Uygulama’ya erişimisağlayan sistemlerin işlerliği ve
              erişilebilirliğineilişkin bir garanti vermemektedir.
              Kullanıcı,Uygulama’ya erişimin muhtelif zamanlarda
              engellenebileceğini ya da erişiminkesilebileceğikabul eder.
              Şirket, söz konusu engelleme veya kesintilerden hiçbir şekilde
              sorumlu değildir.
            </li>
            <li>
              <b>6.2</b> Kullanıcı, Site üzerinden Şirket’in kontrolünde olmayan
              başka internet sitelerine ve/veya portallara, dosyalara veya
              içeriklere link verilebileceğini ve bu tür linklerin yöneldiği
              internet sitesini veya işletenkişisini desteklemek amacıyla veya
              internet sitesi veya içerdiği bilgilere yönelik herhangi bir türde
              bir beyan veya garanti niteliğitaşımadığını, söz konusu linkler
              vasıtasıylaerişilen portallar, internet siteleri, dosyalar ve
              içerikler, hizmetler veya ürünler veya
              bunlarıniçeriğihakkındaŞirket’in herhangi bir
              sorumluluğuolmadığını kabul ve beyan eder.
            </li>
            <li>
              <b>6.3</b> Kullanıcı, Site üzerinden sunulan Uygulama ve
              Uygulama’lara erişim ve bunların kalitesinin büyük ölçüde ilgili
              İnternet Servis Sağlayıcısı’ndan temin edilen hizmetin kalitesine
              dayandığınıve söz konusu hizmet kalitesinden kaynaklı sorunlarda
              Şirket’in herhangi bir sorumluluğununbulunmadığını kabul eder.
            </li>
            <li>
              <b>6.4</b> Kullanıcı,yüklediği içerikler ile site ve
              uygulama’nınkullanımındanmünhasıran sorumludur. Kullanıcı, fikri
              mülkiyet ihlalleri, içerik, uygulama ve site’nin
              kullanımınailişkin olarak üçüncü kişilertarafından iletilebilecek
              her türlü iddia ve talepten (yargılamamasrafları ve
              avukatlıkücretleri de dahil olmak üzere) şirket’i beri kıldığını
              kabul eder.
            </li>
            <li>
              <b>6.5</b> Şirket, uygulanacak hukukun izin verdiği ölçüde, kar
              kaybı,şerefiye ve itibar kaybı, ikame ürün ve hizmet temini için
              yapılan harcama gibi kalemler de dahil ancak bunlarla
              sınırlıolmaksızın site’nin kullanımı neticesinde meydana gelen
              hiçbir doğrudan,dolaylı, özel, arızi,cezai zarardan sorumlu
              olmayacaktır. buna ek olarak şirket,zımni garanti, ticarete
              elverişlilik,belli bir amaca uygunluk da dahil ancak bunlarla
              sınırlı olmamak üzere, açık veya zımni hiç bir türlü garanti
              vermediğini de ayrıca beyan eder.
              Şirket’inişbusözleşmekapsamındakisorumluluğu her halükarda ilgili
              zararındoğduğu tarihe kadar kullanıcıtarafındanişbusözleşme’ye
              konu hizmetler kapsamındaödenmiş olan tutarla sınırlıolacaktır.
            </li>
          </ul>
        </p>
        <p>
          <b>Madde 7.</b>
          Sözleşme’ninYürürlüğü ve Feshi
          <ul>
            <li>
              <b>7.1</b> İşbuSözleşmeKullanıcıtarafından elektronik ortamda
              kabulü ile birlikte yürürlüğe girecek ve taraflardan herhangi biri
              tarafındanaşağıda belirtilen şekildefeshedilmediği sürece
              yürürlükte kalacaktır.
            </li>
            <li>
              <b>7.2</b> Taraflardan herhangi biri, diğer tarafça bildirilen
              elektronik posta adresine 1 (bir) hafta önceden yapacağıyazılı bir
              bildirimle işbuSözleşme’yidilediği zaman herhangi bir gerekçe
              göstermeksizin ve tazminat ödemeksizin feshedebilecektir.
            </li>
            <li>
              <b>7.3</b> Taraflar’dan birinin işbuSözleşme’den kaynaklanan
              yükümlülüklerini tam ve gereği gibi yerine getirmemesi ve diğer
              tarafça yapılacakyazılı bildirime karşın söz konusu
              aykırılığınverilen süre içerisinde giderilmemesi halinde bu
              Sözleşme bildirimi yapan tarafça feshedilebilecektir. Bahsi geçen
              aykırılığınKullanıcıtarafındangerçekleştirilmesi halinde
              Şirketaykırılık giderilene kadar Kullanıcı statüsünü askıya alma
              hakkına sahip olacaktır.Kullanıcı’nınyürürlükteki mevzuatı ihlal
              etmesi halinde ŞirketSözleşme’yi derhal geçerli olacak
              şekildehaklınedenle feshedebilecektir.
            </li>
            <li>
              <b>7.4</b> Sözleşme’nin feshi Taraflar’ın fesih tarihine kadar
              doğmuş olan hak ve yükümlülüklerini ortadan
              kaldırmayacaktır.Sözleşme’nin feshi ile birlikte Kullanıcı, o güne
              kadar doğmuş olan tüm ücret ve masraflardan sorumlu olup fesih
              tarihi itibariyle Site ve Uygulama’yıkullanamayacaktır. Ön ödemeli
              üyeliklerin feshi halinde Kullanıcı’ya para iadesi yapılmaz.
            </li>
            <li>
              <b>7.5</b> Kullanıcı’nınhesabının 3 (üç) ay boyunca pasif olması
              halinde ŞirketişbuSözleşme’yifeshedebilecektir.
            </li>
            <li>
              <b>7.6</b> Kullanıcıhesabının yasal nedenlerle engellenmişolmadığı
              ve Sözleşme’ninfeshedildiğihallerde Şirketİçerik’e 6 (altı) ay
              boyunca salt okunur erişimsağlayacaktır.
            </li>
            <li>
              <b>7.7</b> Şirket,işbuSözleşme yürürlükte olduğu müddetçe
              İçerik’iveritabanlarında saklama hakkınasahiptir. Kullanıcı’nın
              üyelik döneminin veya işbuSözleşme’nin sona ermesini takip eden 6
              (altı)ay içinde Kullanıcıİçerik’i ücret ödemeksizin alabilecektir.
              Şirket, bu sürenin sona ermesinden sonra iletilen söz konusu
              talepler için ücret talep edebilecektir. İlgili ücretler Uygulama
              kapsamında belirtilecektir.
            </li>
          </ul>
        </p>
        <p>
          <b>Madde 8.</b>
          Muhtelif Hükümler
          <ul>
            <li>
              <b>8.1</b> İşbuSözleşme’nin herhangi bir hükmünün veya sözleşmede
              yer alan herhangi bir ifadenin geçersizliği, yasaya aykırılığı ve
              uygulanamazlığı,Sözleşme’nin geri kalan hükümlerinin yürürlüğünü
              ve geçerliliğini etkilemeyecektir.
            </li>
            <li>
              <b>8.2</b> İşbuSözleşme ekleri ile bir bütündür. Sözleşme ile
              ekleri arasında herhangi bir çelişkiolması halinde, ilgili eklerde
              yer alan hükümler geçerli olacaktır.
            </li>
            <li>
              <b>8.3</b> Kullanıcı ile kayıt olurken bildirdikleri e-mail
              vasıtasıyla veya Site’de yer alan genel bilgilendirme
              aracılığıylailetişimkurulacaktır. E-mail ile
              yapılaniletişimyazılıiletişimin yerini tutar. E-mail adresini
              güncel tutmak ve Site’yi bilgilendirmeler için düzenli kontrol
              etmek Kullanıcı’nınsorumluluğundadır.
            </li>
            <li>
              <b>8.4</b> İşbuSözleşme ve eklerinden
              kaynaklıuyuşmazlıklardaİstanbul Merkez (Çağlayan)Mahkemeleri ve
              İcra Daireleri geçerli olacaktır.
            </li>
          </ul>
        </p>
        <p>
          <b>Madde 9.</b>
          Sözleşmenin Sonlanması, İptal ve İade
          <ul>
            <li>
              <b>9.1</b> Kullanıcı bu sözleşmede belirtilen şartları ihlal
              ederse, (“Şirket”) , kullanıcıyasağlananservisleri önceden haber
              vermeden sonlandırabilir ve sözleşmeyi tek taraflı olarak fesih
              edebilir. Bu durumda, kullanıcının önceden ödediği ücretlerin
              iadesi gerçekleştirilmez.
            </li>

            <li>
              <b>9.2</b> Yeni başlatılan veya süresi dolan servislerle ilgili
              ücretlerin kullanıcıtarafından ödenmemesi durumunda, (“Şirket”) ,
              servisleri ve sözleşmeyisonlandırabilir. Bu durumda,
              kullanıcınınönceden ödediği ücretlerin iadesi gerçekleştirilmez.
            </li>

            <li>
              <b>9.3</b> Kullanıcıdilediği anda, 5 işgünü önceden faks, posta
              ve/veya e-posta yolu ile haber vererek sözleşmeyisonlandırabilir.
              Bu durumda kullanıcının önceden ödediği ücretlerin iadesi
              gerçekleştirilmez. Ancak kullanıcısözleşme sonuna kadar ödemeyi
              taahhüt ettiği ve henüz ödemediği ücretleri ödemek durumundadır.
            </li>

            <li>
              <b>9.4</b> İade Garantisi (“Şirket”) hizmet kalitesine güveniyor
              ve müşterilerini memnun edebileceğineinanıyor. Bunun göstergesi
              olarak yeni servis alan kullanıcılarına sebep göstermeksizin
              satınalma tarihinden itibaren 7 gün koşulsuz tam ücret iade
              garantisi vermektedir. (“Şirket”) iade işlemininkesinleşmesini
              müteakip, 15 işgünündekullanıcısına iade tutarını ödemektedir.
              (“Şirket”) , bu garantiden faydalanan kullanıcılarına, yeni servis
              satışı yapmama hakkınısaklıtutar.
            </li>

            <li>
              <b>9.5</b> (“Şirket”) Servis
              KullanımSözleşmesineaykırıdavranışlardandolayı servisleri
              sonlandırılankullanıcılar iade garantisinden faydalanamaz.
            </li>
          </ul>
        </p>
      </Typography>
    </Modal>
  );
}

export default ContractModal;
