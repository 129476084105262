/** Dependencies */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Types */
import { ExpenseCategory } from '../../../../services/api/operations/expenseCategories/types';

/** Helpers */
import getActiveLanguage from '../../../../helpers/getActiveLanguage';

/** Components */
import ConfirmationModal from '../../../../components/ConfirmationModal';
import EmptyState from '../../../../components/EmptyState';
import Modal from '../../../../components/Modal';
import OrderedList from '../../../../components/OrderedList';
import ExpenseCategoryAddModal from './ExpenseCategoryAddModal';
import keyHasPermission from '../../../../helpers/keyHasPermission';
import useProfile from '../../../../hooks/useProfile';

interface Props {
  items: any;
  open: boolean;
  onClose: () => void;
  onItemEdit: (item: any) => void;
  onItemRemove: (id: string) => Promise<any>;
}

function ExpenseCategoryEditModal({
  items,
  open,
  onClose,
  onItemEdit,
  onItemRemove,
}: Props) {
  const { t } = useTranslation('user');
  const { meProfile } = useProfile();

  const [loading, setLoading] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(
    null,
  );
  const [expenseCategoryAddModal, setExpenseCategoryAddModal] = useState(false);
  const [expenseCategoryToEdit, setExpenseCategoryToEdit] =
    useState<ExpenseCategory | null>(null);

  const handleRemove = async () => {
    if (deleteConfirmation) {
      try {
        setLoading(true);
        await onItemRemove(deleteConfirmation);
        setDeleteConfirmation(null);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        title={t('user.addModal.edit')}
        open={open}
        onClose={onClose}
        positiveAction={t('user.editModal.close')}
        onPositiveClick={onClose}
        headerButton={keyHasPermission(
          'create-kitchen-group',
          meProfile?.permissions || [],
        )}
        headerButtonIconName="Add"
        headerButtonText={t('expense_form.add')}
        onHeaderButtonClick={() => setExpenseCategoryAddModal(true)}
      >
        {items.length ? (
          <OrderedList
            items={items.map((item: any) => ({
              ...item,
              name: `${getActiveLanguage(item?.translations)?.name}`,
            }))}
            loading={loading}
            sortButtons={false}
            onItemEdit={item => {
              // @ts-ignore
              setExpenseCategoryToEdit(item);
              setExpenseCategoryAddModal(true);
            }}
            onItemRemove={item => setDeleteConfirmation(item.id)}
            editButtons={keyHasPermission(
              'update-expense-category',
              meProfile?.permissions || [],
            )}
            removeButtons={keyHasPermission(
              'delete-expense-category',
              meProfile?.permissions || [],
            )}
          />
        ) : (
          <EmptyState message={t('user.addModal.empty')} />
        )}
      </Modal>
      <ConfirmationModal
        title={t('user.addModal.delete')}
        titleIconName="QuestionMarkOutlined"
        description={t('user.addModal.description')}
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        positiveAction={t('user.deleteConfirmation.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('user.deleteConfirmation.cancel')}
        onNegativeClick={() => setDeleteConfirmation(null)}
        loading={loading}
      />
      <ExpenseCategoryAddModal
        open={expenseCategoryAddModal}
        onClose={() => {
          setExpenseCategoryAddModal(false);
          setExpenseCategoryToEdit(null);
        }}
        item={expenseCategoryToEdit}
      />
    </>
  );
}

export default ExpenseCategoryEditModal;
