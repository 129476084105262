import { Box, TextField, TextFieldProps, Typography } from '@mui/material';
import React, { forwardRef } from 'react';
import { NumberFormatProps } from 'react-number-format';
import NumberMaskInput from './NumberMaskInput';
import styles from './styles';

interface Props {
  maskProps?: NumberFormatProps;
}

function FormNumberInput(
  { label, sx, maskProps, ...props }: Props & TextFieldProps,
  ref: TextFieldProps['ref'],
) {
  return (
    <Box
      sx={[styles.container, ...(sx ? (Array.isArray(sx) ? sx : [sx]) : [])]}
    >
      <Typography variant="subtitle1">{label}</Typography>
      <TextField
        ref={ref}
        variant="filled"
        hiddenLabel
        InputProps={{
          inputComponent: NumberMaskInput as any,
          inputProps: maskProps,
        }}
        fullWidth
        color="success"
        {...props}
      />
    </Box>
  );
}

export default forwardRef(FormNumberInput);
