import { Box, Grid, Input, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import styles from './styles';
import RouterLink from '../../components/RouterLink';
import useAuth from '../../hooks/useAuth';
import {
  AccessTokenStorage,
  BranchIdStorage,
} from '../../services/tokenStorage';
import Logo from '../../components/Logo';
import { ReactComponent as Promotion } from '../../assets/promotion/login-promotion.svg';
import Calculator from '../../components/Calculator';
import Icon from '../../components/Icon';

function Lock() {
  const { t } = useTranslation('auth');

  const { loginByPin } = useAuth();
  const location = useLocation();

  const [value, setValue] = useState<string>('');

  const token = AccessTokenStorage.getToken();
  const logoutByPin = BranchIdStorage.getToken();

  if (token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (!logoutByPin) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const handleKeyDown = (event: { key: any }) => {
    if (value.length === 4) {
      setValue(value);
    } else if (Number(event.key) || event.key === '0') {
      setValue(value + event.key);
    }

    if (event.key === 'Backspace') {
      setValue(value.length === 1 ? '' : value.slice(0, -1));
    }
  };

  const handleSubmit = async () => {
    try {
      await loginByPin(value);
      setValue('');
    } catch (error) {
      setValue('');
    }
  };

  useEffect(() => {
    if (value.length === 4) {
      handleSubmit();
    }
  }, [value]);

  return (
    <Box onKeyDown={handleKeyDown} tabIndex={0} sx={styles.container} autoFocus>
      <Box sx={styles.leftContainer}>
        <Box sx={styles.logo}>
          <Logo />
          <Typography sx={styles.logoSubtitle} variant="h6" textAlign="center">
            {t('privacy')}
            <br />
            {t('kitchen_title')}
          </Typography>
        </Box>
        <Box sx={styles.promotion}>
          <Promotion height="100%" width="100%" title="promotion-picture" />
        </Box>
      </Box>
      <Box sx={styles.rightContainer}>
        <Box sx={styles.formContainer}>
          <Typography
            variant="h5"
            fontWeight="bold"
            textAlign="center"
            sx={{ mb: 1 }}
          >
            {t('login.pinTitle')}
          </Typography>
          <Typography variant="subtitle1" textAlign="center">
            <Trans
              i18nKey="auth:login.pin_subtitle"
              components={[<RouterLink to="/login" />]}
            />
          </Typography>
          <br />
          <br />
          <Input
            autoFocus
            onKeyDown={handleKeyDown}
            disableUnderline
            sx={{
              color: 'white',
            }}
          />
          <Grid container sx={{ justifyContent: 'center' }}>
            <Grid
              item
              xs={3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              autoFocus
            >
              <Icon
                onKeyDown={handleKeyDown}
                fontSize="large"
                name={value.length > 0 ? 'CheckCircle' : 'Adjust'}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon
                fontSize="large"
                name={value.length > 1 ? 'CheckCircle' : 'Adjust'}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon
                fontSize="large"
                name={value.length > 2 ? 'CheckCircle' : 'Adjust'}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon
                fontSize="large"
                name={value.length > 3 ? 'CheckCircle' : 'Adjust'}
              />
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid>
            <Grid container item justifyContent="flex-end" xs={12}>
              <Calculator isLocked value={value} setValue={setValue} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default Lock;
