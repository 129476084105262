import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../services/api';
import arrayCatcher from '../../../../helpers/errorCatcher';

export const getSectionListThunk = createAsyncThunk(
  'section/list',
  async (params: { sales_channel_id?: string }, { rejectWithValue }) => {
    try {
      const response = await api.definitions.sections.list(
        params.sales_channel_id,
      );
      return response.data.data;
    } catch (err: any) {
      arrayCatcher(err?.code);
      return rejectWithValue(err);
    }
  },
);
