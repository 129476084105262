/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Components */
import FormInput from '../../../../../components/FormInput';
import Modal from '../../../../../components/Modal';

interface Props {
  open: boolean;
  onClose: () => void;
  originalDescription: string;
  onDescriptionChange: (value: string) => void;
  descriptionValue?: string | null;
}
function DescriptionModal({
  open,
  onClose,
  originalDescription,
  onDescriptionChange,
  descriptionValue,
}: Props) {
  const { t } = useTranslation('pos');

  const [description, setDescription] = useState('');

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (open) {
      setDescription(descriptionValue || originalDescription);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={t('orderModal.descriptionModal.title')}
      subtitle={t('orderModal.descriptionModal.subtitle')}
      positiveAction={t('orderModal.descriptionModal.confirm')}
      onPositiveClick={() => {
        onDescriptionChange(description);
        handleClose();
      }}
      negativeAction={t('orderModal.descriptionModal.cancel')}
      onNegativeClick={handleClose}
    >
      <FormInput
        placeholder={t('orderModal.descriptionModal.description')}
        value={description}
        onChange={e => setDescription(e.target.value)}
      />
    </Modal>
  );
}

export default DescriptionModal;
