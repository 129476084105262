import { Box, Paper, Stack, Divider } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from '../../../components/ConfirmationModal';
import ErrorState from '../../../components/ErrorState';
import List from '../../../components/List';
import LoadingState from '../../../components/LoadingState';
import UtilitiesBar, {
  Props as BarProps,
} from '../../../components/UtilitiesBar';
import useCategories from '../../../hooks/useCategories';
import { Category } from '../../../store/slices/definitionsSlice/categories/types';
import CategoryAddModal from './CategoryAddModal';
import CategoryEditModal from './CategoryEditModal';
import UnitEditModal from './UnitEditModal';
import ListHeader from './ListHeader';
import KitchenGroupEditModal from './KitchenGroupEditModal';
import ProductList from './ProductList';
import getActiveLanguage from '../../../helpers/getActiveLanguage';
import useProfile from '../../../hooks/useProfile';
import arrayKeyHasPermission from '../../../helpers/arrayKeyHasPermission';
import ProductEditModal from './ProductEditModal';

const MENU: BarProps['menu'] = [
  {
    id: 0,
    text: 'actions.units',
    startIconName: 'Edit',
    permissionKeys: [
      'view-unit-listing',
      'create-unit',
      'update-unit',
      'delete-unit',
    ],
  },
  {
    id: 1,
    text: 'actions.kitchenGroup',
    startIconName: 'Edit',
    permissionKeys: [
      'view-kitchen-group-listing',
      'create-kitchen-group',
      'update-kitchen-group',
      'delete-kitchen-group',
    ],
  },
  {
    id: 4,
    text: 'actions.bulkProduct',
    startIconName: 'Edit',
    permissionKeys: ['update-product'],
  },
  {
    id: 2,
    text: 'actions.products',
    startIconName: 'Edit',
    permissionKeys: ['create-product'],
  },
  {
    id: 3,
    text: 'actions.addProduct',
    startIconName: 'Add',
    permissionKeys: ['create-product'],
  },
];

function Categories() {
  const { t } = useTranslation('category');
  const navigate = useNavigate();
  const { meProfile } = useProfile();
  const [activeCategory, setActiveCategory] = useState<string | null>(null);

  const [categoryAddModal, setCategoryAddModal] = React.useState(false);
  const [categoryEditModal, setCategoryEditModal] = React.useState(false);
  const [categoryToEdit, setCategoryToEdit] = React.useState<Category | null>(
    null,
  );
  const [deleteCatConfirmation, setDeleteCatConfirmation] =
    React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const [unitEditModal, setUnitEditModal] = React.useState(false);
  const [productEditModal, setProductEditModal] = useState(false);

  const [kitchenGroupEditModal, setKitchenGroupEditModal] = useState(false);

  const {
    categories,
    categoryError,
    categoryLoading,
    fetchCategories,
    removeCategory,
    moveUpCategory,
    moveDownCategory,
  } = useCategories();

  const hasMoreVertPermission = arrayKeyHasPermission(
    ['update-category', 'delete-category'],
    meProfile?.permissions || [],
  );

  useEffect(() => {
    const result = fetchCategories();
    return () => {
      result.abort();
    };
  }, []);

  useEffect(() => {
    if (categories.length && !activeCategory) {
      const data = localStorage.getItem('category');
      if (data) {
        setActiveCategory(JSON.parse(data));
      } else {
        setActiveCategory(categories[0]?.id);
      }
    }
  }, [categories]);

  const handleCategory = (id: string) => {
    localStorage.setItem('category', JSON.stringify(id));
    setActiveCategory(id);
  };

  const handleAction = useCallback(
    (id: number) => {
      switch (id) {
        case 0: {
          setUnitEditModal(true);
          break;
        }
        case 1: {
          setKitchenGroupEditModal(true);
          break;
        }
        case 2: {
          setProductEditModal(true);
          break;
        }
        case 3: {
          navigate(`/definitions/product/${activeCategory}`);
          break;
        }
        case 4: {
          navigate('/bulk-product');
          break;
        }
        default:
          break;
      }
    },
    [activeCategory, categories],
  );

  const handleRemove = async () => {
    if (categoryToEdit) {
      setDeleting(true);
      await removeCategory(categoryToEdit.id);
      setDeleting(false);
      setCategoryToEdit(null);
      setDeleteCatConfirmation(false);
    }
  };

  const handleListAction = useCallback(
    (actionIndex: number, item: Pick<Category, 'id'>) => {
      const cat = categories.find(el => el.id === item.id);
      if (cat) {
        setCategoryToEdit(cat);
        switch (actionIndex) {
          case 0: {
            setCategoryAddModal(true);
            break;
          }
          case 1: {
            setDeleteCatConfirmation(true);
            break;
          }
          default:
            break;
        }
      }
    },
    [categories],
  );

  if (categoryLoading) {
    return <LoadingState />;
  }

  if (!categories && categoryError) {
    return (
      <ErrorState
        message={
          categoryError
            ? categoryError.message
            : t('common:unknownError' as any)
        }
      />
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('title')}
        leftIconName="Category"
        menu={MENU!
          .filter(item =>
            arrayKeyHasPermission(
              item?.permissionKeys || [],
              meProfile?.permissions || [],
            ),
          )
          .map(filteredItem => ({
            ...filteredItem,
            text: t(filteredItem.text as any),
          }))}
        onActionClick={handleAction}
      />
      <Stack
        sx={{ flex: 1 }}
        direction={{ md: 'row', xs: 'column' }}
        spacing={3}
      >
        <Paper sx={{ flex: 1, py: 2 }} elevation={3}>
          <ListHeader
            title={t('category.title')}
            onAddClick={() => setCategoryAddModal(true)}
            onEditClick={() => setCategoryEditModal(true)}
          />
          <Divider />
          <List
            items={categories.map(item => ({
              id: item.id,
              text: getActiveLanguage(item.translations).name,
            }))}
            activeItemId={activeCategory}
            onItemClick={categoryId => handleCategory(categoryId)}
            actions={[
              t('category.list.actions.edit'),
              t('category.list.actions.delete'),
            ]}
            onActionClick={handleListAction}
            hasMoreVertPermission={hasMoreVertPermission}
          />
        </Paper>
        <Paper
          sx={{ flex: 3, p: 2, overflow: 'auto', maxHeight: '600px' }}
          elevation={3}
        >
          <ProductList categoryId={activeCategory} />
        </Paper>
      </Stack>
      <CategoryAddModal
        open={categoryAddModal}
        item={categoryToEdit}
        onClose={() => {
          setCategoryAddModal(false);
          setCategoryToEdit(null);
        }}
        onConfirm={() => {
          setCategoryAddModal(false);
          setCategoryToEdit(null);
        }}
      />
      <CategoryEditModal
        items={categories.map(cat => ({
          ...cat,
          name: getActiveLanguage(cat.translations).name,
        }))}
        open={categoryEditModal}
        onClose={() => setCategoryEditModal(false)}
        onItemEdit={item => {
          const cat = categories.find(el => el.id === item.id);
          if (cat) {
            setCategoryToEdit(cat);
            setCategoryAddModal(true);
          }
        }}
        onItemRemove={removeCategory}
        onItemMoveUp={moveUpCategory}
        onItemMoveDown={moveDownCategory}
      />
      <UnitEditModal
        open={unitEditModal}
        onClose={() => setUnitEditModal(false)}
      />
      <ProductEditModal
        categoryId={activeCategory}
        open={productEditModal}
        onClose={() => setProductEditModal(false)}
      />
      <KitchenGroupEditModal
        open={kitchenGroupEditModal}
        onClose={() => setKitchenGroupEditModal(false)}
      />
      <ConfirmationModal
        title={t('category.deleteConfirmation.title')}
        titleIconName="QuestionMarkOutlined"
        description={t('category.deleteConfirmation.description')}
        open={deleteCatConfirmation}
        onClose={() => setDeleteCatConfirmation(false)}
        positiveAction={t('category.deleteConfirmation.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('category.deleteConfirmation.cancel')}
        onNegativeClick={() => setDeleteCatConfirmation(false)}
        loading={deleting}
      />
    </Box>
  );
}

export default Categories;
