import authSlice from './slices/authSlice';
import snackbarSlice from './slices/snackbarSlice';
import backdropSlice from './slices/backdropSlice';
import definitionsSlice from './slices/definitionsSlice';
import operationsSlice from './slices/operationsSlice';
import languageSlice from './slices/languageSlice';
import taxRatesSlice from './slices/taxRatesSlice';
import utilitiesSlice from './slices/utilitiesSlice';
import userOperationsSlice from './slices/userOperationsSlice';
import branchSlice from './slices/branchSlice';
import basketItemSlice from './slices/basketItemSlice';
import restaurantSettingsSlice from './slices/settingsSlice/restaurantSettings/restaurantSettingsSlice';
import printerSettingsSlice from './slices/settingsSlice/printerSettings/printerSettingsSlice';
import reportsSlice from './slices/reportsSlice';

const rootReducer = {
  auth: authSlice,
  snackbar: snackbarSlice,
  backDrop: backdropSlice,
  languages: languageSlice,
  branches: branchSlice,
  taxRates: taxRatesSlice,
  utilities: utilitiesSlice,
  basketItems: basketItemSlice,
  restaurantSettings: restaurantSettingsSlice,
  printerSettings: printerSettingsSlice,
  reports: reportsSlice,
  definitionsSections: definitionsSlice.sections,
  definitionsTables: definitionsSlice.tables,
  definitionsCustomers: definitionsSlice.customers,
  definitionsCustomerAddresses: definitionsSlice.customerAddresses,
  definitionsCategories: definitionsSlice.categories,
  definitionsUnits: definitionsSlice.units,
  definitionsKitchenGroups: definitionsSlice.kitchenGroups,
  definitionsPropertyGroups: definitionsSlice.propertyGroups,
  definitionsIngredientGroups: definitionsSlice.ingredientGroups,
  definitionsProducts: definitionsSlice.products,
  userOperationsUsers: userOperationsSlice.users,
  userOperationsRoles: userOperationsSlice.roles,
  operationsExpenses: operationsSlice.expenses,
  operationsExpenseCategories: operationsSlice.expenseCategories,
};

export default rootReducer;
