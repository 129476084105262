/** Dependencies */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

/** Styles */
import {
  Box,
  Grid,
  TableRow,
  TableCell,
  Paper,
  SelectChangeEvent,
} from '@mui/material';

/** Hooks */
import useKitchenGroups from '../../hooks/useKitchenGroups';
import useProfile from '../../hooks/useProfile';
import useProductsMenu from '../../hooks/useProductMenu';
import useTaxRates from '../../hooks/useTaxRates';

/** Helpers */
import getActiveLanguage from '../../helpers/getActiveLanguage';

/** Components */
import UtilitiesBar, { Props as BarProps } from '../../components/UtilitiesBar';
import ErrorState from '../../components/ErrorState';
import SearchBar from '../../components/SearchBar';
import FormNumberInput from '../../components/FormNumberInput';
import FormCheckbox from '../../components/FormCheckbox';
import FormSelect from '../../components/FormSelect';
import useCategories from '../../hooks/useCategories';
import SearchSelectBar from '../../components/SearchSelectBar';
import styles from './styles';
import { ProductAPI } from '../../services/api/definitions/products/types';
import LoadingState from '../../components/LoadingState';
import TableComponent from '../../components/TableComponent';
import { OptionsMemo, OptionsMemoWithName } from '../../helpers/optionMemo';

function BulkProduct() {
  const { t } = useTranslation('bulkProduct');
  const { meProfile } = useProfile();
  const { categories, fetchCategories } = useCategories();
  const { kitchenGroups, fetchKitchenGroups } = useKitchenGroups();
  const {
    allProducts,
    allProductLoading,
    allProductError,
    fetchAllProducts,
    updateBulkProduct,
  } = useProductsMenu();
  const navigation = useNavigate();
  const { taxRates, fetchTaxRates } = useTaxRates();

  const MENU: BarProps['menu'] = [
    {
      id: 0,
      // text: 'actions.Back',
      text: 'Geri',
      startIconName: 'Back',
      permissionKeys: [],
    },
    {
      id: 1,
      // text: 'actions.Save',
      text: 'Kaydet',
      startIconName: 'Save',
      permissionKeys: [],
    },
  ];

  const [searchValue, setSearchValue] = useState<{
    inputValue: string;
    isActive: boolean | null;
  }>({
    inputValue: '',
    isActive: null,
  });
  const [debouncedSearch, setDebouncedSearch] = useState({
    inputValue: '',
    isActive: true,
  });

  const [selectedProducts, setSelectedProducts] = useState<ProductAPI[]>([]);

  const taxRateGroupsOptions = useMemo(
    () =>
      taxRates.map(item => ({
        id: item.tax_rate,
        text: `%${item.tax_rate}`,
      })),
    [taxRates],
  );

  const [selectValue, setSelectValue] = useState<string[]>(['']);

  const categoryGroupsOptions = useMemo(
    () =>
      categories.map(item => ({
        id: item.id,
        text: getActiveLanguage(item.translations).name,
      })),
    [categories],
  );

  const kitchenGroupsOptions = useMemo(
    () =>
      kitchenGroups.map(item => ({
        id: item.id,
        text: item.name,
      })),
    [kitchenGroups],
  );

  const handleChange = (event: SelectChangeEvent<typeof selectValue>) => {
    const {
      target: { value },
    } = event;

    setSelectValue(typeof value === 'string' ? value.split(',') : value);
  };

  const debouncedAndFilteredSearch = async () => {
    try {
      const selectedValueId = selectValue.map(
        item =>
          // @ts-ignore
          categories?.find(
            el => item === getActiveLanguage(el?.translations)?.name,
          ).id,
      );

      if (selectValue) {
        await fetchAllProducts(searchValue.inputValue, selectedValueId, true);
      }
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  const handleSave = async () => {
    try {
      if (selectedProducts.length) {
        await updateBulkProduct({
          products: selectedProducts.map(item => ({
            id: item.id,
            category_id: item.category.id,
            kitchen_group_id: item.kitchen_group.id,
            tax_rate: item.tax_rate,
            is_active: item.is_active,
            is_required_property_selection: item.is_required_property_selection,
            product_units: item.units.map(el => ({
              unit_id: el.unit_id,
              amount: Number(el.amount.amount) / 100,
              cost: Number(el.cost?.amount) / 100,
              is_active: el.is_active,
              is_default: el.is_default,
            })),
          })),
        });
        navigation('/definitions/categories');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAction = (id: number) => {
    switch (id) {
      case 0:
        navigation('/definitions/categories');
        break;
      case 1:
        handleSave();
        break;
      default:
        break;
    }
  };

  const tableHead = [
    t('table.is_active'),
    t('table.product'),
    t('table.portion'),
    t('table.required_selection'),
    t('table.tax'),
    t('table.kitchen_group'),
  ];

  const tableBody = selectedProducts.map(row => (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
      <TableCell>
        <FormCheckbox
          value={row.is_active}
          onChange={() => {
            setSelectedProducts(
              selectedProducts.map(item =>
                item.id === row.id
                  ? { ...item, is_active: !item.is_active }
                  : item,
              ),
            );
          }}
        />
      </TableCell>
      <TableCell>{getActiveLanguage(row.translations).name}</TableCell>
      <TableCell>
        <Grid container>
          {row.units.map(
            item =>
              item.is_active && (
                <Grid item sm={2} md={3} p={1}>
                  <FormNumberInput
                    value={Number(item.amount.amount) / 100}
                    placeholder={getActiveLanguage(item.translations).name}
                    onChange={event => {
                      const value = Number(event.target.value);
                      setSelectedProducts(
                        selectedProducts.map(el =>
                          el.id === row.id
                            ? {
                                ...el,
                                units: el.units.map(unit =>
                                  unit.unit_id === item.unit_id
                                    ? {
                                        ...item,
                                        amount: {
                                          ...unit.amount,
                                          amount:
                                            value > 0
                                              ? (value * 100).toString()
                                              : '-',
                                        },
                                        is_active: true,
                                      }
                                    : unit,
                                ),
                              }
                            : el,
                        ),
                      );
                    }}
                    label={getActiveLanguage(item.translations).name}
                    maskProps={{
                      decimalScale: 2,
                      fixedDecimalScale: true,
                      suffix: ` ${meProfile?.currency?.symbol || '₺'}`,
                    }}
                  />
                </Grid>
              ),
          )}
        </Grid>
      </TableCell>
      <TableCell>
        <FormCheckbox
          value={row.is_required_property_selection}
          onChange={() => {
            setSelectedProducts(
              selectedProducts.map(item =>
                item.id === row.id
                  ? {
                      ...item,
                      is_required_property_selection:
                        !item.is_required_property_selection,
                    }
                  : item,
              ),
            );
          }}
        />
      </TableCell>
      <TableCell>
        <Grid p={1}>
          <FormSelect
            value={row.tax_rate}
            placeholder={`${row.tax_rate.toString()}%`}
            onChange={event => {
              setSelectedProducts(
                selectedProducts.map(item =>
                  item.id === row.id
                    ? {
                        ...item,
                        tax_rate: Number(event.target.value),
                      }
                    : item,
                ),
              );
            }}
            underline
            fullWidth
            options={taxRateGroupsOptions}
          />
        </Grid>
      </TableCell>
      <TableCell>
        <Grid xs={12}>
          <FormSelect
            value={row.kitchen_group.id}
            onChange={event => {
              setSelectedProducts(
                selectedProducts.map(item =>
                  item.id === row.id
                    ? {
                        ...item,
                        kitchen_group: {
                          id: (event.target.value as string).toString(),
                          name: '',
                        },
                      }
                    : item,
                ),
              );
            }}
            underline
            fullWidth
            options={kitchenGroupsOptions}
          />
        </Grid>
      </TableCell>
    </TableRow>
  ));

  useEffect(() => {
    debouncedAndFilteredSearch();
  }, [debouncedSearch.inputValue, selectValue]);

  useEffect(() => {
    setSelectedProducts(allProducts);
  }, [allProducts]);

  useEffect(() => {
    setSelectValue([categoryGroupsOptions[0]?.text]);
  }, [categories]);

  useEffect(() => {
    fetchKitchenGroups();
    fetchCategories();
    fetchTaxRates();
  }, []);

  if (allProductLoading) {
    return <LoadingState />;
  }

  if (!allProducts && allProductError) {
    return (
      <ErrorState
        message={
          allProductError
            ? allProductError.message
            : t('common:unknownError' as any)
        }
      />
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('title')}
        leftIconName="Category"
        menu={MENU}
        onActionClick={handleAction}
      />
      <Grid container>
        <Grid xs={8} sm={9}>
          <SearchBar
            placeholder={t('searchbar.placeholder')}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedSearch={setDebouncedSearch}
          />
        </Grid>
        <Grid xs={4} sm={3} sx={styles.kitchenFilterBarContainer}>
          <SearchSelectBar
            selectValue={selectValue}
            handleChange={handleChange}
            options={categoryGroupsOptions}
            content=""
            filterButton={false}
          />
        </Grid>
      </Grid>

      <Paper sx={{ width: '100%', p: 2 }}>
        <TableComponent
          tableHead={tableHead}
          tableBody={tableBody}
          tableContainerStyle={{ py: 1 }}
          tableStyle={{ minWidth: 1280 }}
          tableTopHeadStyle={{ backgroundColor: 'primary.light' }}
          count={1}
          page={1}
          showPagination={false}
        />
      </Paper>
    </Box>
  );
}

export default BulkProduct;
