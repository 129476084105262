import {
  Box,
  TextField,
  Typography,
  TextFieldProps,
  Grid,
  IconButton,
} from '@mui/material';
import React, { forwardRef } from 'react';
import { NumberFormatProps } from 'react-number-format';
import styles from './styles';
import Icon from '../Icon';

interface Props {
  disableUnderline?: boolean;
  maskProps?: NumberFormatProps;
  onIncrease: () => void;
  onDecrease: () => void;
}

function FormInputWithCounter(
  {
    label,
    sx,
    maskProps,
    disableUnderline = false,
    onIncrease,
    onDecrease,
    ...props
  }: Props & TextFieldProps,
  ref: TextFieldProps['ref'],
) {
  return (
    <Box
      sx={[styles.container, ...(sx ? (Array.isArray(sx) ? sx : [sx]) : [])]}
    >
      <Typography variant="subtitle1">{label}</Typography>
      <Grid
        container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          m: 1,
        }}
      >
        <Grid
          item
          xs={4}
          sx={{
            display: 'flex',
            alignItems: 'right',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton
            sx={{
              border: '1px solid #ef5350',
            }}
            color="error"
            onMouseDown={e => e.stopPropagation()}
            onClick={e => {
              e.stopPropagation();
              onDecrease();
            }}
          >
            <Icon name="Remove" />
          </IconButton>
        </Grid>
        <Grid item xs={4}>
          <TextField
            ref={ref}
            variant="filled"
            color="success"
            hiddenLabel
            InputProps={{
              disableUnderline,
              inputProps: {
                ...maskProps,
                min: 1,
                style: {
                  textAlign: 'center',
                  background: 'white',
                  textUnderlinePosition: 'none',
                  fontSize: '20px',
                },
              },
            }}
            fullWidth
            {...props}
          />
        </Grid>
        <Grid item xs={4}>
          <IconButton
            sx={{
              border: '1px solid #4caf50',
            }}
            color="success"
            onMouseDown={e => e.stopPropagation()}
            onClick={e => {
              e.stopPropagation();
              onIncrease();
            }}
          >
            <Icon name="Add" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default forwardRef(FormInputWithCounter);
