/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

/** Components */
import Icon from '../../../../../../../components/Icon';

/** Types */
import { BasketProduct } from '../../../../../../../models/Product';

/** Styles */
import styles from '../../styles';
import generalStyles from '../../../../../../../assets/stylesheets/generalStyles';
import getActiveLanguage from '../../../../../../../helpers/getActiveLanguage';
import { singlePriceFormatter } from '../../../../../../../helpers/moneyFormatter';

interface Props {
  list: BasketProduct[];
  handleAddPieceOrderClick: (id: string, itemTotalQuantity: number) => void;
  handleDecreasePieceOrderClick: (id: string) => void;
  currencyCode: string;
}

function UnPaidOrders({
  list,
  handleAddPieceOrderClick,
  handleDecreasePieceOrderClick,
  currencyCode,
}: Props) {
  const { t } = useTranslation('pos');

  const unPaidOrders = () => {
    const unPaidList = list.map(
      item =>
        item.count - (item.paid_quantity || 0) !== 0 &&
        !item.is_treat && (
          <>
            <br />
            <Divider />
            <br />
            <Stack direction="row">
              <Paper sx={generalStyles.orderCountContainer}>
                <Typography>
                  {item.selected_piece_orders
                    ? item.selected_piece_orders.remainingPiece
                    : item.count - (item.paid_quantity || 0)}
                </Typography>
              </Paper>
              <Stack
                sx={{ flex: 1, pl: 2, cursor: 'pointer' }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  {item.selected_piece_orders &&
                    item.selected_piece_orders.quantity > 0 && (
                      <Typography sx={styles.selectedQuantityInfo}>
                        {item.selected_piece_orders?.quantity}{' '}
                        {t('paymentModal.piece')}
                      </Typography>
                    )}
                  <Typography
                    sx={
                      item.selected_piece_orders &&
                      item.selected_piece_orders?.remainingPiece === 0
                        ? {
                            ...styles.title,
                            textDecoration: 'line-through',
                          }
                        : styles.title
                    }
                  >
                    {item?.product?.translations
                      ? getActiveLanguage(item?.product?.translations)?.name
                      : item?.translations
                      ? getActiveLanguage(item?.translations)?.name
                      : item.items[0].product_name}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={
                      item.selected_piece_orders &&
                      item.selected_piece_orders?.remainingPiece === 0
                        ? { textDecoration: 'line-through' }
                        : {}
                    }
                  >
                    {item.units &&
                      getActiveLanguage(item.units[0].translations).name}
                  </Typography>
                  <Typography sx={styles.properties} variant="subtitle2">
                    <List>
                      {item.items.length &&
                        item.items?.map(
                          itemItems =>
                            itemItems.property_group_items &&
                            itemItems.property_group_items.map(propItem => (
                              <ListItem sx={styles.priceInfo}>
                                {propItem.translations[0].name}
                                {propItem.additional_price.amount !== '0' &&
                                  ` + ${propItem.additional_price.formatted}`}
                              </ListItem>
                            )),
                        )}
                    </List>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    sx={
                      item.selected_piece_orders &&
                      item.selected_piece_orders?.remainingPiece === 0
                        ? { textDecoration: 'line-through', mr: 2 }
                        : { mr: 2 }
                    }
                  >
                    {item.selected_piece_orders
                      ? singlePriceFormatter(
                          item.total_amount.amount,
                          item.selected_piece_orders.remainingPiece /
                            item.count,
                          currencyCode,
                        )
                      : singlePriceFormatter(
                          item.total_amount.amount,
                          (item.count - (item.paid_quantity || 0)) / item.count,
                          currencyCode,
                        )}
                  </Typography>
                  <IconButton
                    sx={styles.icon}
                    onMouseDown={e => e.stopPropagation()}
                    onClick={() =>
                      handleAddPieceOrderClick(
                        item.id,
                        item.count - (item.paid_quantity || 0),
                      )
                    }
                  >
                    <Icon name="TouchApp" />
                  </IconButton>
                  {item.selected_piece_orders &&
                    item.selected_piece_orders.quantity > 0 && (
                      <IconButton
                        sx={styles.icon}
                        onMouseDown={e => e.stopPropagation()}
                        onClick={() => handleDecreasePieceOrderClick(item.id)}
                      >
                        <Icon name="KeyboardReturn" color="error" />
                      </IconButton>
                    )}
                </Box>
              </Stack>
            </Stack>
          </>
        ),
    );
    return unPaidList.filter(item => !!item);
  };
  return (
    <>
      {unPaidOrders().length > 0 && (
        <>
          <Stack
            sx={{
              textDecoration: 'underline 1.5px red',
              textUnderlinePosition: 'under',
              textAlign: 'center',
              alignItems: 'center',
            }}
          >
            {t('paymentModal.not_pay')}
          </Stack>
          <br />
        </>
      )}
      <Stack direction="column-reverse">{unPaidOrders()}</Stack>
    </>
  );
}

export default UnPaidOrders;
