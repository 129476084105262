import { Box, Grid, Paper } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RestaurantTable from '../../../components/RestaurantTable';
import UtilitiesBar, {
  Props as BarProps,
} from '../../../components/UtilitiesBar';
import SectionEditModal from './SectionEditModal';
import SectionAddModal from './SectionAddModal';
import TableEditModal from './TableEditModal';
import TableAddModal from './TableAddModal';
import AddBulkTableModal from './AddBulkTableModal';
import TabBar from '../../../components/TabBar';
import useSections from '../../../hooks/useSections';
import { SectionBase, TableBase, CountTable } from './types';
import useTables from '../../../hooks/useTables';
import EmptyState from '../../../components/EmptyState';
import ErrorState from '../../../components/ErrorState';
import LoadingState from '../../../components/LoadingState';
import useProfile from '../../../hooks/useProfile';
import arrayKeyHasPermission from '../../../helpers/arrayKeyHasPermission';

const MENU: BarProps['menu'] = [
  {
    id: 0,
    text: 'actions.addTable',
    startIconName: 'Add',
    permissionKeys: ['create-table', 'move-table-sequence'],
  },
  {
    id: 1,
    text: 'actions.editTables',
    startIconName: 'Edit',
    permissionKeys: ['update-table', 'delete-table', 'move-table-sequence'],
  },
  {
    id: 2,
    text: 'actions.addSection',
    startIconName: 'Add',
    permissionKeys: ['create-section'],
  },
  {
    id: 3,
    text: 'actions.editSections',
    startIconName: 'Edit',
    permissionKeys: ['update-section', 'delete-section', 'move-table-section'],
  },
  {
    id: 4,
    text: 'actions.addBulkTable',
    startIconName: 'BackupTable',
    permissionKeys: ['table-bulk-store'],
  },
];

function Tables() {
  const { t } = useTranslation('table');
  const { meProfile } = useProfile();
  const [activeSectionId, setActiveSectionId] = useState<string | null>(null);
  const [tableAddModal, setTableAddModal] = useState(false);
  const [tableEditModal, setTableEditModal] = useState(false);
  const [sectionAddModal, setSectionAddModal] = useState(false);
  const [sectionEditModal, setSectionEditModal] = useState(false);
  const [collectiveTableAddModal, setCollectiveTableAddModal] =
    React.useState(false);

  const [confirm, setConfirm] = React.useState(false);

  const [sectionToEdit, setSectionToEdit] = useState<SectionBase | null>(null);
  const [collectiveTableToEdit, setCollectiveTableToEdit] =
    useState<CountTable | null>(null);
  const [tableToEdit, setTableToEdit] = useState<TableBase | null>(null);

  const {
    sections,
    sectionError,
    sectionLoading,
    fetchSections,
    removeSection,
    moveUpSection,
    moveDownSection,
  } = useSections();

  const {
    tables,
    tableError,
    tableLoading,
    fetchTables,
    removeTable,
    moveUpTable,
    moveDownTable,
  } = useTables();

  useEffect(() => {
    const result = fetchSections();
    return () => {
      result.abort();
    };
  }, []);

  useEffect(() => {
    // set first section as active at first sections fetch
    if (sections.length && !activeSectionId) setActiveSectionId(sections[0].id);
    // set first section as active if current section got deleted
    if (sections.length && !sections.find(item => item.id === activeSectionId))
      setActiveSectionId(sections[0].id);
  }, [sections]);

  useEffect(() => {
    let result: null | { abort: () => void } = null;
    if (activeSectionId && confirm) {
      const sectionId = sections?.find(item => item.id === activeSectionId)?.id;
      result = sectionId ? fetchTables(sectionId) : null;
      setConfirm(false);
    }
  }, [confirm]);

  useEffect(() => {
    let result: null | { abort: () => void } = null;
    if (activeSectionId) {
      const sectionId = sections?.find(item => item.id === activeSectionId)?.id;
      result = sectionId ? fetchTables(sectionId) : null;
    }
    return () => {
      if (result) result.abort();
    };
  }, [activeSectionId]);

  const handleAction = useCallback((id: number) => {
    switch (id) {
      case 0:
        setTableAddModal(true);
        break;
      case 1:
        setTableEditModal(true);
        break;
      case 2:
        setSectionAddModal(true);
        break;
      case 3:
        setSectionEditModal(true);
        break;
      case 4:
        setCollectiveTableAddModal(true);
        break;
      default:
        break;
    }
  }, []);

  if (sectionLoading) {
    return <LoadingState />;
  }

  if (!sections && !tables && (sectionError || tableError)) {
    return (
      <ErrorState
        message={sectionError ? sectionError.message : tableError.message}
      />
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('title')}
        leftIconName="TableRestaurant"
        menu={MENU!
          .filter(item =>
            arrayKeyHasPermission(
              item?.permissionKeys || [],
              meProfile?.permissions || [],
            ),
          )
          .map(filteredItem => ({
            ...filteredItem,
            text: t(filteredItem.text as any),
          }))}
        onActionClick={handleAction}
      />

      <Paper sx={{ p: 3, flex: 1 }} elevation={3}>
        {arrayKeyHasPermission(
          ['view-section-listing'] || [],
          meProfile?.permissions || [],
        ) && sections?.length ? (
          <TabBar
            sx={{ mb: 3 }}
            tabs={sections}
            activeTab={activeSectionId}
            onClick={tab => setActiveSectionId(tab.id)}
          />
        ) : null}

        {tableLoading ? (
          <LoadingState />
        ) : arrayKeyHasPermission(
            ['view-table-listing'] || [],
            meProfile?.permissions || [],
          ) && tables.length ? (
          <Grid container spacing={3}>
            {tables.map((table, index) => (
              <Grid key={table.id || index} item xs={6} sm={4} md={3} lg={2}>
                <RestaurantTable
                  name={table.name}
                  onEditClick={() => {
                    setTableToEdit(table);
                    setTableAddModal(true);
                  }}
                  colorStatus={0}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <EmptyState message={t('table.empty')} />
        )}
      </Paper>
      <SectionAddModal
        open={sectionAddModal}
        item={sectionToEdit}
        onClose={() => {
          setSectionToEdit(null);
          setSectionAddModal(false);
        }}
        onConfirm={() => {
          setSectionToEdit(null);
          setSectionAddModal(false);
        }}
      />
      <AddBulkTableModal
        open={collectiveTableAddModal}
        sectionId={activeSectionId}
        item={collectiveTableToEdit}
        onClose={() => {
          setCollectiveTableToEdit(null);
          setCollectiveTableAddModal(false);
          setConfirm(false);
        }}
        onConfirm={() => {
          setSectionToEdit(null);
          setCollectiveTableAddModal(false);
          setConfirm(true);
        }}
      />
      <SectionEditModal
        items={sections}
        open={sectionEditModal}
        onClose={() => setSectionEditModal(false)}
        onItemEdit={item => {
          setSectionToEdit(item);
          setSectionAddModal(true);
        }}
        onItemRemove={removeSection}
        onItemMoveUp={moveUpSection}
        onItemMoveDown={moveDownSection}
      />
      <TableAddModal
        open={tableAddModal}
        sectionId={activeSectionId}
        item={tableToEdit}
        onClose={() => {
          setTableToEdit(null);
          setTableAddModal(false);
          setConfirm(false);
        }}
        onConfirm={() => {
          setTableToEdit(null);
          setTableAddModal(false);
          setConfirm(true);
          if (activeSectionId) fetchTables(activeSectionId);
        }}
      />
      <TableEditModal
        items={tables}
        open={tableEditModal}
        onClose={() => setTableEditModal(false)}
        onItemEdit={item => {
          setTableToEdit(item);
          setTableAddModal(true);
        }}
        onItemRemove={removeTable}
        onItemMoveUp={moveUpTable}
        onItemMoveDown={moveDownTable}
      />
    </Box>
  );
}

export default Tables;
