import api from '../../api';
import { ApiSuccessResponse } from '../../types';
import {
  ListResponse,
  ViewResponse,
  CreateResponse,
  UpdateResponse,
  DeleteResponse,
  ExpenseCategoryCreateParams,
  ExpenseCategoryUpdateParams,
} from './types';

type ListRes = ApiSuccessResponse<ListResponse>;
const list = () => api.get<ListRes>('/expense-categories');

type ViewRes = ApiSuccessResponse<ViewResponse>;
const view = (id: string) => api.get<ViewRes>(`/expense-categories/${id}`);

type CreatRes = ApiSuccessResponse<CreateResponse>;
const create = (params: ExpenseCategoryCreateParams) =>
  api.post<CreatRes>('/expense-categories', params);

type UpdateRes = ApiSuccessResponse<UpdateResponse>;
const update = (params: ExpenseCategoryUpdateParams) => {
  const { expenseCategoryId } = params;
  return api.put<UpdateRes>(`/expense-categories/${expenseCategoryId}`, params);
};

type DeleteRes = ApiSuccessResponse<DeleteResponse>;
const remove = (expenseCategoryId: string) =>
  api.delete<DeleteRes>(`/expense-categories/${expenseCategoryId}`);
export default {
  list,
  view,
  create,
  update,
  remove,
};
