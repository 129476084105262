/** Dependencies */
import React from 'react';
import { IconButton, Paper, Typography } from '@mui/material';

/** Styles */
import styles from '../styles';
import generalStyles from '../../../../../../../assets/stylesheets/generalStyles';

/** Components */
import {
  MenuProduct,
  Menus,
} from '../../../../../../../store/slices/basketItemSlice/types';
import Icon from '../../../../../../../components/Icon';

interface Props {
  item: MenuProduct;
  productMenu: Menus;
  handleRemove: (
    basketItem: MenuProduct,
    menuId: string,
    itemId: string,
  ) => void;
  handleDecrease: (itemId: string, menuId: string) => void;
  handleIncrease: (itemId: string, menuId: string) => void;
}

function MenuModalLeftSideMenuItemsCountButtons({
  item,
  productMenu,
  handleIncrease,
  handleDecrease,
  handleRemove,
}: Props) {
  return (
    <Paper sx={generalStyles.buttonContainer}>
      <IconButton
        sx={styles.icon}
        color="success"
        onMouseDown={e => e.stopPropagation()}
        onClick={e => {
          e.stopPropagation();
          handleIncrease(item?.itemId || '', productMenu.id);
        }}
      >
        <Icon name="Add" />
      </IconButton>
      <Typography>{item.count}</Typography>
      <IconButton
        sx={styles.icon}
        color="error"
        onMouseDown={e => e.stopPropagation()}
        onClick={e => {
          e.stopPropagation();
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          item.count === 1
            ? handleRemove(item, productMenu.id, item.itemId || '')
            : handleDecrease(item?.itemId || '', productMenu.id);
        }}
      >
        <Icon name={item.count === 1 ? 'Delete' : 'Remove'} />
      </IconButton>
    </Paper>
  );
}

export default MenuModalLeftSideMenuItemsCountButtons;
