import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/Modal';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
function GDPRModal({ open, onClose, onConfirm }: Props) {
  const { t } = useTranslation('auth');

  return (
    <Modal
      title={t('register.gdpr.confirmation_modal.title')}
      open={open}
      onClose={onClose}
      positiveAction={t('register.gdpr.confirmation_modal.confirm')}
      negativeAction={t('register.gdpr.confirmation_modal.cancel')}
      onPositiveClick={onConfirm}
      onNegativeClick={onClose}
    >
      <Typography>
        KİŞİSEL VERİLERİN KORUNMASINA İLİŞKİN AYDINLATMA METNİ
        <br />
        <br />
        Kitchrest A.Ş. olarak; kişisel verilerinizin gizliliği ve güvenliği en
        önemli önceliklerimiz arasındadır. Bu kapsamda 6698 sayılı Kişisel
        Verilerin Korunması Kanunu’nun (“KVKK”) 10. maddesinden doğan aydınlatma
        yükümlülüğümüzü yerine getirmek amacıyla kişisel verileriniz hakkında
        sizleri bilgilendirmek isteriz. Kişisel veri, kimliğinizi belirli ya da
        belirlenebilir kılan her türlü bilgi anlamına gelmektedir. Kitchrest
        tarafından işlenen kişisel verileriniz, bunların işlenme amaçları,
        aktarılabileceği alıcı grupları, toplanma yöntemi, hukuki sebebi ve söz
        konusu kişisel verilere ilişkin haklarınız aşağıda yer almaktadır.
        <br />
        <p>
          <b>Madde 1.</b> Kitchrest tarafından hangi kişisel verileriniz
          işlenmektedir? Kitchrest platformunda yer alan üyelik koşullarını
          kabul ederek web sitesinden veya mobil cihazlara kurulan uygulamadan
          giriş yapan ve platformda yer alan ürünleri takip eden ve/veya satın
          alan kişiler “Üye” olarak tanımlanmaktadır. Kitchrest platformunda üye
          olmanız halinde aşağıdaki kişisel verileriniz işlenebilmektedir.
          <br />
          <ul>
            <li>
              <b>1.1</b> Adınız, soyadınız,
            </li>
            <li>
              <b>1.2</b> İletişim Bilgileriniz: cep telefonu numaranız, fatura
              adresiniz, e-posta adresiniz,
            </li>
            <li>
              <b>1.3</b> Fatura bilgileriniz, talep ve şikayet bilgileriniz,
              Intercom kanalıyla yapılan konuşma içeriklerine yönelik bilgiler,
            </li>
            <li>
              <b>1.4</b> MAC adresi bilgisi, şifre ve parola bilgileri, çerez
              bilgileri,
            </li>
            <li>
              <b>1.5</b> İşitsel Kayıt Bilgileriniz: çağrı merkeziyle görüşmeniz
              halinde ses kaydınız.
            </li>
          </ul>
        </p>
        <br />
        <p>
          <b>Madde 2.</b> Kişisel verilerinizin işlenmesinin amaçları ve toplama
          yöntemleri nelerdir? Kimlik, iletişim ve müşteri işlem bilgileriniz;
          sözleşmesel ilişkimiz kapsamında bizzat sizlerden, mobil uygulama ya
          da internet sitesinden elektronik ortamda otomatik olarak aşağıdaki
          amaçlarla toplanmakta ve işlenmektedir.
          <br />
          <ul>
            <li>
              <b>2.1 </b> Sözleşmenin kurulması ve ifasına yönelik süreçlerin
              yürütülmesi.
            </li>
            <li>
              <b>2.2 </b> Finans ve muhasebe süreçlerinin yürütülmesi ve
              denetimi.
            </li>
            <li>
              <b>2.3 </b> Şirketin iş faaliyetlerinin yürütülmesi ve denetimi.
            </li>
            <li>
              <b>2.4 </b> Faturalandırma süreçlerinin yürütülmesi,
            </li>
            <li>
              <b>2.5 </b> İnternet sitesi/mobil uygulamalar üzerinden satın alım
              işlemlerinin gerçekleşmesi ve işlem yapanın kimlik bilgilerinin
              teyit edilmesi,
            </li>
            <li>
              <b>2.6 </b> Açık rızanızın bulunması halinde, çeşitli pazarlama ve
              reklam faaliyetlerinde kullanabilmesi, tercihlerinize uygun
              ürünlerin tarafınıza sunulması başta olmak üzere sizlere daha iyi
              bir alışveriş deneyiminin sağlanması ve memnuniyetinizin
              arttırılmasına yönelik faaliyetlerin yürütülmesi,
            </li>
            <li>
              <b>2.7 </b> Müşteri memnuniyetini artırmak, platformu kullanan
              müşterilerimizi tanıyabilmek ve müşteri çevresi analizinde
              kullanabilmek, Şirketimizin sunduğu ürün ve hizmetlerin
              geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin
              yürütülmesi,
            </li>
            <li>
              <b>2.8 </b> Stratejik analiz çalışmalarının yürütülmesi,
            </li>
            <li>
              <b>2.9 </b> İletişim faaliyetlerinin yürütülmesi,
            </li>
            <li>
              <b>2.10</b> İlgi alanlarınızı dikkate alarak ilgilenebileceğiniz
              ürünlerin sunulması,
            </li>
            <li>
              <b>2.11</b> Mesafeli satış sözleşmesi ve Tüketicinin Korunması
              Hakkında Kanun’un ilgili maddeleri tahtında platformumuz üzerinden
              akdedilen sözleşmelerin koşulları, güncel durumu ve güncellemeler
              ile ilgili iletişime geçilmesi ve gerekli bilgilendirmelerin
              yapılması,
            </li>
            <li>
              <b>2.12</b> Açık rızanızın bulunması halinde, Kitchrest nezdindeki
              gelişmeler, fırsatlar ve yenilikler hakkında tarafınıza bilgi
              verilmesi,
            </li>
            <li>
              <b>2.13</b> İnternet sitesi ve/veya mobil uygulamalardan alışveriş
              yapan müşterilerimizin tanınması, müşteri çevresi analizinde
              kullanılmasına yönelik faaliyetlerin yürütülmesi ve bu kapsamda
              anlaşmalı kuruluşlar aracılığıyla elektronik ortamda ve/veya
              fiziki ortamda anket çalışmaları yapılması,
            </li>
            <li>
              <b>2.14</b> Ürün ve hizmetlerimize ilişkin talep, şikayet ve
              önerilerinizin değerlendirilmesi,
            </li>
            <li>
              <b>2.15</b> Ürünlere yönelik bilgi almanızın sağlanması,
            </li>
            <li>
              <b>2.16</b> Mal ve hizmet satış sonrası destek hizmetlerinin
              yürütülmesi,
            </li>
            <li>
              <b>2.17</b> Bilgi güvenliği süreçlerinin yürütülmesi,
            </li>
            <li>
              <b>2.18</b> Kitchrest platformuna ilişkin olarak operasyonların
              güvenliğinin sağlanması,
            </li>
            <li>
              <b>2.19</b> Faaliyetlerin mevzuata uygun yürütülmesinin
              sağlanması,
            </li>
            <li>
              <b>2.20</b> Hukuk işlerinin takibi ve yürütülmesi,
            </li>
            <li>
              <b>2.21</b> Yetkili kişi, kamu kurum ve kuruluşlarına bilgi
              verilmesi.
            </li>
            <li>
              İşlem güvenliği bilgileriniz; sözleşmesel ilişkimiz kapsamında
              bizzat sizlerden, mobil uygulama ya da internet sitesinden
              elektronik ortamda otomatik olarak aşağıdaki amaçlarla toplanmakta
              ve işlenmektedir.
            </li>
            <li>
              <b>2.22</b> Sözleşmenin kurulması ve ifasına yönelik süreçlerin
              yürütülmesi,
            </li>
            <li>
              <b>2.23</b> Şirketin iş faaliyetlerinin yürütülmesi ve denetimi,
            </li>
            <li>
              <b>2.24</b> İnternet sitesi/mobil uygulamalar üzerinden satın alım
              işlemlerinin gerçekleşmesi ve işlem yapanın kimlik bilgilerinin
              teyit edilmesi,
            </li>
            <li>
              <b>2.25</b> Açık rızanızın bulunması halinde, çeşitli pazarlama ve
              reklam faaliyetlerinde kullanabilmesi, tercihlerinize uygun
              ürünlerin tarafınıza sunulması başta olmak üzere sizlere daha iyi
              bir alışveriş deneyiminin sağlanması ve memnuniyetinizin
              arttırılmasına yönelik faaliyetlerin yürütülmesi,
            </li>
            <li>
              <b>2.26</b> Müşteri memnuniyetini artırmak, platformdan alışveriş
              yapan müşterilerimizi tanıyabilmek ve müşteri çevresi analizinde
              kullanabilmek, Şirketimizin sunduğu ürün ve hizmetlerin
              geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin
              yürütülmesi,
            </li>
            <li>
              <b>2.27</b> Bilgi güvenliği süreçlerinin yürütülmesi,
            </li>
            <li>
              <b>2.28</b> Kitchrest platformuna ilişkin olarak operasyonların
              güvenliğinin sağlanması,
            </li>
            <li>
              <b>2.29</b> Faaliyetlerin mevzuata uygun yürütülmesinin
              sağlanması,
            </li>
            <li>
              <b>2.30</b> Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.
            </li>
            <li>
              İşitsel kayıt bilgileriniz; çağrı merkeziyle iletişime geçmeniz
              halinde aşağıdaki amaçlarla toplanmakta ve işlenmektedir.
            </li>
            <li>
              <b>2.31</b> Sözleşmenin kurulması ve ifasına yönelik süreçlerin
              yürütülmesi,
            </li>
            <li>
              <b>2.31</b> İletişim faaliyetlerinin yürütülmesi,
            </li>
            <li>
              <b>2.31</b> Kampanyaların yürütülmesi, tercihlerinize uygun
              ürünlerin tarafınıza sunulması başta olmak üzere sizlere daha iyi
              bir alışveriş deneyiminin sağlanması ve memnuniyetinizin
              arttırılmasına yönelik faaliyetlerin yürütülmesi,
            </li>
            <li>
              <b>2.31</b> Şirketimizin sunduğu ürün ve hizmetlerin
              geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin
              yürütülmesi,
            </li>
            <li>
              <b>2.31</b> İlgi alanlarınızı dikkate alarak ilgilenebileceğiniz
              ürünlerin tarafınıza sunulması,
            </li>
            <li>
              <b>2.31</b> Mesafeli satış sözleşmesi ve Tüketicinin Korunması
              Hakkında Kanun’un ilgili maddeleri tahtında platformumuz üzerinden
              akdedilen sözleşmelerin koşulları, güncel durumu ve güncellemeler
              ile ilgili iletişime geçilmesi ve gerekli bilgilendirmelerin
              yapılması,
            </li>
            <li>
              <b>2.31</b> Açık rızanızın bulunması halinde, Kitchrest nezdindeki
              gelişmeler, fırsatlar ve yenilikler hakkında tarafınıza bilgi
              verilmesi,
            </li>
            <li>
              <b>2.31</b> İnternet sitesi ve/veya mobil uygulamalardan alışveriş
              yapan müşterilerimizin tanınması, müşteri
            </li>
            <li>
              çevresi analizinde kullanılmasına yönelik faaliyetlerin
              yürütülmesi ve bu kapsamda anlaşmalı kuruluşlar aracılığıyla
              elektronik ortamda ve/veya fiziki ortamda anket çalışmaları
              yapılması,
            </li>
            <li>
              <b>2.32</b> Ürün ve hizmetlerimize ilişkin talep, şikayet ve
              önerilerinizin değerlendirilmesi,
            </li>
            <li>
              <b>2.32</b> Kitchrest platformuna ilişkin olarak operasyonların
              güvenliğinin sağlanması,
            </li>
            <li>
              <b>2.32</b> Faaliyetlerin mevzuata uygun yürütülmesinin
              sağlanması,
            </li>
            <li>
              <b>2.32</b> Hukuk işlerinin takibi ve yürütülmesi,
            </li>
            <li>
              <b>2.32</b> Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.
            </li>
          </ul>
        </p>
        <br />
        <p>
          <b>Madde 3.</b> Kişisel verilerinizin işlenmesinin hukuki sebebi
          nedir? Kitchrest “Kitchrest tarafından hangi kişisel verileriniz
          işlenmektedir?” başlığında yer alan kişisel verilerinizi yukarıda yer
          alan amaçlarla ve KVKK’nın 5., 8. ve 9. maddelerinde belirtilen ve
          aşağıda yer verilen hukuka uygunluk sebeplerine dayanılarak
          işlenmektedir.
          <br />
          <ul>
            <li>
              <b>3.1</b> 6563 Elektronik Ticaretin Düzenlenmesi Hakkında Kanun,
              6102 Sayılı Türk Ticaret Kanunu, 5237 Sayılı Türk Ceza Kanunu ve
              6502 sayılı Tüketicinin Korunması Hakkında Kanun başta olmak üzere
              Şirketimizin tabi olduğu mevzuatta açıkça öngörülmüş olması hukuki
              sebebine dayanarak; şirket platformunda yer alan operasyonların
              güvenliğinin sağlanması, bilgi güvenliği süreçlerinin yürütülmesi,
              faaliyetlerin mevzuata uygun yürütülmesinin sağlanmasına yönelik
              faaliyetler başta olmak üzere mevzuattan kaynaklanan
              yükümlülüklerimizin yerine getirilmesi,
            </li>
            <li>
              <b>3.2</b> Sözleşmenin kurulması veya ifasıyla doğrudan doğruya
              ilgili olması kaydıyla, kişisel verilerinizin işlenmesinin gerekli
              olması hukuki sebebine dayanarak; üyelik sözleşmesinin kurulması,
              mesafeli satış sözleşmesi gibi Tüketicinin Korunması Hakkında
              Kanun’un ilgili maddeleri tahtında platformumuz üzerinden
              akdedilen sözleşmelerin kurulması faaliyetlerinin yürütülmesi,
              akdettiğimiz sözleşmelerin ifasına yönelik faaliyetlerin
              yürütülmesi, satın alım işlemlerinizin gerçekleştirilmesine
              yönelik faaliyetlerin yürütülmesi, şirketin iş faaliyetlerinin
              yürütülmesi ve denetimi, teslimat süreçlerinin takibinin
              yapılması, ürün ve hizmetlerimize ilişkin talep, şikayet ve
              önerilerinizin değerlendirilmesi, finans ve muhasebe süreçlerinin
              yürütülmesi ve denetimi, iletişim faaliyetlerinin yürütülmesi,
            </li>
            <li>
              <b>3.3</b> Şirketimizin hukuki yükümlülüğünün yerine getirebilmesi
              için zorunlu olması hukuki sebebine dayanarak; Mesafeli
              Sözleşmeler Yönetmeliği ve Elektronik Ticarette Hizmet Sağlayıcı
              ve Aracı Hizmet Sağlayıcılar Hakkında Yönetmelik başta olmak üzere
              Şirketimizin tabii olduğu ikincil mevzuatta yer alan ve/veya
              yetkili merciiler tarafından yayınlanan karar, kılavuz ve
              rehberlerde belirtilen hukuki yükümlülüklerinin yerine
              getirilmesi, yetkili kişi, kurum ve kuruluşlara bilgi verilmesi,
              faaliyetlerin mevzuata uygun yürütülmesinin sağlanması, hukuk
              işlerinin takibi ve yürütülmesi, finans ve muhasebe işlerinin
              yürütülmesi,
            </li>
            <li>
              <b>3.4</b> Bir hakkın tesisi, kullanılması veya korunması için
              veri işlemenin zorunlu olması hukuki sebebine dayanarak; hukuk ve
              dava işlerinin yürütülmesi,
            </li>
            <li>
              <b>3.5</b> Temel hak ve özgürlüklerine zarar vermemek kaydıyla
              şirketimizin meşru menfaatleri için veri işlenmesinin zorunlu
              olması hukuki sebebine dayanarak; şirketimizin sunduğu ürün ve
              hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik
              faaliyetlerin yürütülmesi ve sizlere daha iyi bir alışveriş
              deneyiminin sağlanması,
            </li>
            <li>
              <b>3.6</b> Açık rızanızın bulunması hukuki sebebine dayanarak;
              platformda yer alan ürünlere yönelik sorularınızın ve/veya
              yorumlarınızın ad-soyad bilginiz ile birlikte yayınlanması,
              çeşitli pazarlama ve reklam faaliyetlerinde kullanabilmesi,
              tercihlerinize uygun ürünlerin tarafınıza sunulması başta olmak
              üzere sizlere daha iyi bir alışveriş deneyiminin sağlanması ve
              memnuniyetinizin arttırılmasına yönelik faaliyetlerin yürütülmesi,
              Kitchrest nezdindeki gelişmeler, fırsatlar ve yenilikler hakkında
              tarafınıza bilgi verilmesi, ticari elektronik iletiler
              gönderilmesi ve yurtdışına kişisel veri aktarımı yapılması.
            </li>
          </ul>
        </p>
        <br />
        <p>
          <b>Madde 4.</b>
          Kitchrest kişisel verilerinizi kimlere hangi sebeplerle aktarıyor?
          Şirketimiz, kişisel verilerinizi “bilme gereği” ve “kullanma gereği”
          ilkelerine uygun olarak, gerekli veri minimizasyonunu sağlayarak ve
          gerekli teknik ve idari güvenlik tedbirlerini alarak işlemeye özen
          göstermektedir. İş faaliyetlerinin yürütülmesi veya denetimi, iş
          sürekliliğinin sağlanması, dijital altyapıların işletilmesi farklı
          paydaşlarla sürekli veri akışını zaruri kıldığı için işlediğimiz
          kişisel verileri belirli amaçlarla üçüncü kişilere aktarmak
          durumundayız. Ayrıca, sözleşmesel ve kanuni yükümlülüklerimizi tam ve
          gereği gibi yerine getirebilmemiz için kişisel verilerinizin doğru ve
          güncel olması çok önemlidir. Bunun için de muhtelif iş ortaklarıyla ve
          hizmet sağlayıcılarla çalışmak durumundayız. Kişisel verileriniz,
          pazaryeri operasyonun yürütülmesi, süreçlerinize yönelik yardım ve
          destek taleplerinizin yönetilmesi, üyelerimizin deneyimini ve
          memnuniyetin arttırmaya yönelik faaliyetlerin yürütülmesi, şirketimiz
          nezdindeki gelişmeler, fırsatlar ve yenilikler hakkında tarafınıza
          bilgi verilmesi, şirketimizin sunduğu ürün ve hizmetlerin
          geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi,
          sizlere daha iyi bir alışveriş deneyiminin sağlanması ve
          memnuniyetinizin arttırılmasına yönelik faaliyetlerin yürütülmesi, iş
          sürekliliğinin sağlanması, bilgi güvenliğinin sağlanması, kanuni
          yükümlülüklerin yerine getirilmesi amaçları başta olmak üzere ve işbu
          aydınlatma metninin ikinci maddesinde belirtilen amaçlarla sınırlı
          olarak gerekli olduğu ölçüde: Şirketimizin tabi olduğu mevzuatta
          açıkça öngörülmüş olması ve hukuki yükümlülüğünün yerine getirebilmesi
          için zorunlu olması, sözleşmenin kurulması veya ifası için gerekli
          olması hukuki sebeplerine dayanarak;
          <br />
          <li>
            <b>4.1</b> Satın alım süreçlerinin yürütülmesi amacıyla ürünün
            satıcısı ve hizmet sağlayıcıyla,
          </li>
          <li>
            <b>4.2</b> Kargo, lojistik faaliyetlerin yürütülmesi ve teslimat
            süreçlerinin takibinin yapılması amacıyla sizin ve/veya ürünün adına
            teslim edileceği kişi bilgisi satın alınan ürünün satıcısıyla, kargo
            firmalarıyla ve özel kuryelerle,
          </li>
          <li>
            <b>4.3</b> Alternatif teslimat modeliyle ürünlerin teslimatının
            yapılabilmesi amacıyla anlaşmalı olunan iş ortaklarımızla ve hizmet
            sağlayıcılarla,
          </li>
          <li>
            <b>4.4</b> Faturalandırma süreçlerinin yürütülmesi amacıyla iş
            ortaklarımız, satın alınan ürünün satıcısı, danışmanlarımız ve
            hizmet sağlayıcılarımızla, bankalarla, mali müşavirlerimizle,
          </li>
          <li>
            <b>4.5</b> Mal ve hizmet satış ve satış sonrası destek hizmetlerinin
            sunulması için çağrı merkezi hizmeti sunan iş ortakları ve diğer
            hizmet sağlayıcılarıyla,
          </li>
          <li>
            <b>4.6</b> Hizmetlerin kalite kontrol, şikâyet yönetimi ve risk
            analizi alanında hizmet sunan iş ortakları ve hizmet
            sağlayıcılarıyla,
          </li>
          <li>
            <b>4.7</b> Finansal ve muhasebe süreçlerinin yönetimi, risklerin
            tespiti, değerlendirilmesi, dolandırıcılıkların önlenmesi için
            ilgili iş ortaklarımız, danışmanlarımız ve hizmet
            sağlayıcılarımızla, bankalarla, mali müşavirlerimizle,
          </li>
          <li>
            <b>4.8</b> Müşteriye e-faturasının elektronik olarak gönderilmesi
            için e-fatura iş ortağıyla; fiziki sözleşme veya fatura teslimatı
            yapılabilmesi amacıyla kargo ve kurye firmalarıyla, özel entegratör,
            bağımsız denetim, gümrük, mali müşavir/muhasebe hizmeti sunan iş
            ortaklarımızla,
          </li>
          <li>
            <b>4.9</b> Vergisel yükümlülüklerin yerine getirilmesi için vergi
            daireleriyle, vergi denetimleri sırasında fatura ve tahsilat
            makbuzlarının T.C. Hazine ve Maliye Bakanlığı yetkilileriyle,
          </li>
          <li>
            <b>4.10</b> İletilerin yönetilmesi için ileti yönetim sistemi (İYS)
            kayıt bağlamında Ticaret Bakanlığı ve yetkilendirdiği şirketle ve
            ileti gönderimi için yetkili ticari ileti altyapı sağlayıcıyla,
          </li>
          <li>
            <b>4.11</b> Bilişim altyapımızı sağlayan, işleten veya hizmet sunan
            iş ortaklarımızla ve hizmet sağlayıcılarımızla,
          </li>
          <li>
            <b>4.12</b> Risk yönetimi ve finansal raporlama süreçlerinin
            yürütülmesi alanında hizmet sunan iş ortaklarımızla,
          </li>
          <li>Açık rızanızın bulunması hukuki sebebine dayanarak;</li>
          <li>
            <b>4.13</b> Web sitesi kullanım tercihleri ve gezinti geçmişi,
            segmentasyon yapılması ve müşteri ile beğeni ve tercihleri
            doğrultusunda iletişime geçilmesi amacıyla ilgili hizmet sağlayıcı
            ve iş ortağıyla,
          </li>
          <li>
            <b>4.14</b> Profilleme, analiz, sunulan hizmetlerin
            özelleştirilmesi, pazarlama ve reklam amaçlarıyla bu alanda hizmet
            sunan iş ortaklarıyla ve hizmet sağlayıcılarla,
          </li>
          <li>
            <b>4.15</b> Pazarlama süreçlerinin yönetilmesi amacıyla ilgili iş
            ortaklarımızla,
            <br />
            Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin
            zorunlu olması, şirketimizin tabi olduğu mevzuatta açıkça öngörülmüş
            olması ve hukuki yükümlülüğünün yerine getirebilmesi için zorunlu
            olması hukuki sebeplerine dayanarak;
            <br />
          </li>
          <li>
            <b>4.16</b> Hukuki yükümlülüklerin yerine getirilmesi kapsamında
            avukatlar, denetçiler, adli bilişim uzmanları, siber güvenlik
            danışmanları, vergi danışmanları ile danışmanlık ve hizmet aldığımız
            diğer üçüncü kişilerle ve iş ortaklarıyla,
          </li>
          <li>
            <b>4.17</b> Düzenleyici ve denetleyici kurumlar ile mahkeme ve icra
            müdürlükleri gibi yetkili kamu kurum ve kuruluşlarıyla,
          </li>
          <li>
            <b>4.18</b> Kişisel verilerinizi talep etmeye yetkili olan diğer
            kamu kurum veya kuruluşlarıyla, yurtiçi ve/veya yurtdışında bulunan
            iştiraklerimizle, tedarikçilerimizle, iş ortaklarımızla, anlaşmalı
            olduğumuz bankalarla ve ürün veya hizmet aldığımız üçüncü kişilerle
            paylaşılabilmektedir.
          </li>
        </p>
        <br />
        <p>
          <b>Madde 5.</b> Kitchrest Verilerinizi Nasıl Koruyor? Kitchrest ile
          paylaşılan kişisel veriler, Kitchrest gözetimi ve kontrolü altındadır.
          Kitchrest, yürürlükteki ilgili mevzuat hükümleri gereğince bilginin
          gizliliğinin ve bütünlüğünün korunması amacıyla gerekli organizasyonu
          kurmak ve teknik önlemleri almak ve uyarlamak konusunda veri sorumlusu
          sıfatıyla sorumluluğu üstlenmiştir. Bu konudaki yükümlülüğümüzün
          bilincinde olarak;
          <br />
          <ul>
            <li>
              <b>5.1</b> Veri gizliliğini konu alan uluslararası ve ulusal
              teknik standartlara uygun surette periyodik aralıklarda sızma
              testleri yaptırılmaktadır.
            </li>
            <li>
              <b>5.2</b> Web sitesi, mobil site ve mobil uygulama aracılığıyla
              Kitchrest’e ilettiğiniz kişisel verileriniz SSL (Secure Sockets
              Layer) teknolojisi kullanılarak korunmaktadır.
            </li>
            <li>
              <b>5.3</b> Kişisel veri işleme faaliyetlerine ilişkin düzenli
              olarak risk analizleri yapılmakta ve risklerin azaltılması için
              aksiyonlar alınmaktadır.
            </li>
            <li>
              <b>5.4</b> Kişisel verilere yetkisiz erişimleri engellemek için
              erişim ve yetki kontrolleri uygulanmaktadır.
            </li>
            <li>
              <b>5.5</b> Bu kapsamda veri işleme politikalarımızı her zaman
              güncellediğimizi bilginize sunarız.
            </li>
          </ul>
        </p>
        <br />
        <p>
          <b>Madde 6.</b> Kişisel Verilerinizin Korunmasına Yönelik Haklarınız
          Bu Aydınlatma Metni’nin “Hak ve Talepleriniz İçin İletişim” bölümünde
          yer alan yöntemlerle Şirketimize başvurarak, 6.1 Kişisel verilerinizin
          işlenip işlenmediğini öğrenme,
          <br />
          <ul>
            <li>6.2 İşlenmişse buna ilişkin bilgi talep etme,</li>
            <li>
              6.3 Kişisel verilerinizin işlenme amacını ve bunların amacına
              uygun kullanılıp kullanılmadığını öğrenme,
            </li>
            <li>
              6.4 Yurt içinde veya yurt dışında aktarıldığı üçüncü kişileri
              bilme,
            </li>
            <li>
              6.5 Kişisel verilerin eksik veya yanlış işlenmiş olması halinde
              bunların düzeltilmesini isteme,
            </li>
            <li>
              6.6 KVKK’da öngörülen şartlar çerçevesinde kişisel verilerinizin
              silinmesini veya yok edilmesini isteme,
            </li>
            <li>
              6.7 Yukarıda belirtilen düzeltme, silinme ve yok edilme şeklindeki
              haklarınız uyarınca yapılan işlemlerin, kişisel verilerin
              aktarıldığı üçüncü kişilere bildirilmesini isteme,
            </li>
            <li>
              6.8 İşlenen kişisel verilerinizin münhasıran otomatik sistemler
              ile analiz edilmesi sureti ile aleyhinize bir sonucun ortaya
              çıkmasına itiraz etme,
            </li>
            <li>
              6.9 Kişisel verilerinizin ilgili mevzuata aykırı olarak işlenmesi
              sebebiyle zarara uğramanız hâlinde zararınızın giderilmesini talep
              etme haklarına sahipsiniz.
            </li>
          </ul>
        </p>
        <br />
        <p>
          <b>Madde 7.</b> Hak Ve Talepleriniz İçin İletişim Kişisel
          verilerinizle ilgili sorularınızı ve taleplerinizi, Veri Sorumlusuna
          Başvuru Usul ve Esasları hakkında Tebliğ’de belirtilen şartlara uygun
          düzenlenmiş dilekçeyle aşağıdaki yöntemlerle iletebilirsiniz.
          <br />
          <br />
          BAŞVURU YÖNTEMI
          <br />
          BAŞVURUNUN YAPILACAĞI ADRES
          <br />
          Şahsen Yazılı Başvuru
          <br />
          <br />
          Şirketimize daha önce bildirilen ve sistemde kayıtlı bulunan
          elektronik posta adresinizden
          <br />
          <ul>
            <li>
              <b>7.1</b> Şahsen Yazılı Başvuru” yoluna başvuracak İlgili
              Kişilerin kimliğini tevsik edici belgeleri ibraz etmesi
              zorunludur. Bu bağlamda adresine yapacağınız şahsen yazılı
              başvurularda başvuru formuyla birlikte kimlik fotokopisinin
              yalnızca ön yüzünün (kan grubu ve din hanesi gözükmeyecek şekilde)
              ibraz edilmesini rica ederiz.
            </li>
            <li>
              <b>7.2</b> Başvuru formunun tebligat zarfına veya e-postanın konu
              kısmına “Kişisel Verilerin Korunması Kanunu İlgili Kişi Talebi”
              yazılmasını rica ederiz. Kişisel veri sahipleri olarak,
              haklarınıza ilişkin taleplerinizi, Kitchrest’e iletmeniz durumunda
              Kitchrest talebinizi en geç 30 (otuz) gün içinde ücretsiz olarak
              sonuçlandıracaktır. Ancak, başvurunuza verilecek yanıtın mevzuat
              uyarınca bir maliyet gerektirmesi halinde Kitchrest tarafından
              Kişisel Verileri Koruma Kurulu tarafından belirlenen tarifedeki
              ücret talep edilebilecektir.
            </li>
          </ul>
        </p>
        <br />
        <p>
          <b>Madde 8.</b> Aydınlatma Metni Hakkında Kitchrest işbu Kişisel
          Verilerin Korunması Hakkında Açıklama metnini yürürlükteki mevzuatta
          yapılabilecek değişiklikler çerçevesinde her zaman güncelleme hakkını
          saklı tutar. Güncellenme Tarihi: 25.11.2022
        </p>
      </Typography>
    </Modal>
  );
}

export default GDPRModal;
