import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import { getExpenseListThunk } from '../../store/slices/operationsSlice/expenses';
import {
  ExpenseCreateParams,
  ExpenseUpdateParams,
} from '../../services/api/operations/expenses/types';
import arrayCatcher from '../../helpers/errorCatcher';
import { isApiError } from '../../services/api/types';
import api from '../../services/api';
import {
  addExpenseSlice,
  removeExpenseSlice,
  updateExpenseSlice,
} from '../../store/slices/operationsSlice/expenses/expensesSlice';

const useExpenses = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const expenses = useAppSelector(state => state.operationsExpenses.expenses);
  const meta = useAppSelector(state => state.operationsExpenses.meta);
  const expensesMeta = useAppSelector(
    state => state.operationsExpenses.expensesMeta,
  );

  const expenseLoading = useAppSelector(
    state => state.operationsExpenses.loading,
  );

  const expenseError = useAppSelector(state => state.operationsExpenses.error);

  const fetchExpenses = (
    page?: number,
    date_range?: string,
    expense_category_id?: string[],
    day_id?: string,
    per_page?: number,
  ) => {
    try {
      dispatch(
        getExpenseListThunk({
          page,
          date_range,
          expense_category_id,
          day_id,
          per_page,
        }),
      );
    } catch (err: any) {
      arrayCatcher(err?.code);
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      if (err?.code === 1003) {
        navigate('/');
      }
    }
  };

  const addExpense = async (params: ExpenseCreateParams) => {
    try {
      const response = await api.operations.expenses.create(params);
      const result = response.data.data;
      dispatch(addExpenseSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const updateExpense = async (params: ExpenseUpdateParams) => {
    try {
      const response = await api.operations.expenses.update(params);
      const result = response.data.data;
      dispatch(updateExpenseSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };
  const removeExpense = async (id: string) => {
    try {
      const response = await api.operations.expenses.remove(id);
      const result = response.data.data;
      dispatch(removeExpenseSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  return {
    expenses,
    meta,
    expensesMeta,
    expenseLoading,
    expenseError,
    fetchExpenses,
    addExpense,
    updateExpense,
    removeExpense,
  };
};
export default useExpenses;
