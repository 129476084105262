import { Box, Typography, ButtonGroup, IconButton } from '@mui/material';
import React from 'react';
import Icon from '../../../components/Icon';

interface Props {
  title: string;
  onAddClick: () => void;
  addButton?: boolean;
}

function GroupListHeader({ title, onAddClick, addButton }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        px: 2,
        pb: 1,
      }}
    >
      <Typography textAlign="center" textOverflow="ellipsis" variant="h6">
        {title}
      </Typography>
      {addButton && (
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <IconButton aria-label="Add" color="primary" onClick={onAddClick}>
            <Icon name="Add" />
          </IconButton>
        </ButtonGroup>
      )}
    </Box>
  );
}

export default GroupListHeader;
