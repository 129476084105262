import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type RegisterFormValues = {
  firstName: string;
  lastName: string;
  phone?: string;
  phoneNumberCountry?: string;
  email: string;
  password: string;
  confirmPassword: string;
  contract: boolean;
  gdpr: boolean;
};

const schema: yup.SchemaOf<RegisterFormValues> = yup.object({
  firstName: yup.string().required('register.first_fame.required'),
  lastName: yup.string().required('register.last_name.required'),
  phone: yup.string(),
  phoneNumberCountry: yup.string(),
  email: yup
    .string()
    .required('register.email.required')
    .email('register.email.invalid'),
  password: yup
    .string()
    .required('register.password.required')
    .min(8, 'register.password.min'),
  confirmPassword: yup
    .string()
    .required('register.confirm_password.required')
    .oneOf([yup.ref('password'), null], 'register.confirm_password.not_match'),
  contract: yup.boolean().required().oneOf([true]),
  gdpr: yup.boolean().required().oneOf([true]),
});

export const defaultValues: RegisterFormValues = {
  firstName: '',
  lastName: '',
  phone: '',
  phoneNumberCountry: 'TR',
  email: '',
  password: '',
  confirmPassword: '',
  contract: false,
  gdpr: false,
};

export const validationSchema = yupResolver(schema);
