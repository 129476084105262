import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type ProductInfoFormValues = {
  name: { lang: string; text: string }[];
  tax_rate: number;
  product_code: string;
  is_menu: boolean;
  is_parent: boolean;
  is_active: boolean;
  is_required_property_selection: boolean;
  show_on_qr_menu: boolean;
  category_id: string;
  kitchen_group_id: string;
  image?: string;
  image_url?: string;
};

const schema = yup.object({
  name: yup
    .array(
      yup.object().shape({
        lang: yup.string(),
        text: yup.string().required('wizard.form.name.required'),
      }),
    )
    .min(1),
  tax_rate: yup.number().required('wizard.form.taxRate.required'),
  category_id: yup.string().required('wizard.form.category.required'),
  kitchen_group_id: yup.string().required('wizard.form.kitchenGroup.required'),
  product_code: yup.string().default('').max(13).nullable(),
});

export const validationSchema = yupResolver(schema);

export const defaultValues = (
  catId: string,
  kitchenId: string,
): ProductInfoFormValues => ({
  name: [{ lang: 'tr', text: '' }],
  product_code: '',
  is_active: true,
  is_menu: false,
  is_parent: false,
  is_required_property_selection: false,
  show_on_qr_menu: true,
  category_id: catId,
  kitchen_group_id: kitchenId,
  tax_rate: 0,
  image: '',
  image_url: '',
});
