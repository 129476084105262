export const rgbToHex = (r: string, g: string, b: string) => {
  const toHex = (value: string) => {
    const hex = parseInt(value, 10).toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };

  const hexRed = toHex(r);
  const hexGreen = toHex(g);
  const hexBlue = toHex(b);

  return `#${hexRed}${hexGreen}${hexBlue}`;
};

export const convertRGBtoHex = (rgbColor: string) => {
  const rgbValues = rgbColor.match(/\d+/g);
  if (rgbValues && rgbValues.length === 3) {
    return rgbToHex(rgbValues[0], rgbValues[1], rgbValues[2]);
  }
  return rgbColor;
};

export const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});
