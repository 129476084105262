/** Dependencies */
import React, { useEffect } from 'react';
import { Box, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

/** Styles */
import styles from '../styles';

/** helpers */
import getActiveLanguage from '../../../../../../../helpers/getActiveLanguage';

/** Components */
import Icon from '../../../../../../../components/Icon';

/** Types */
import { Menu, MenuItem, SelectedMenuItem } from '../types';
import MenuModalRightSideCategories from './MenuModalRightSideCategories';
import MenuModalRightSideProducts from './MenuModalRightSideProducts';

interface Props {
  menus: Menu[];
  handleAddMenuItem: (item: MenuItem, selectedItem: SelectedMenuItem) => void;
  menuItemsCountLength: (id: string, selectableQuantity: number) => number;
  selectedItem: SelectedMenuItem;
  setSelectedItem: (
    value:
      | ((prevState: SelectedMenuItem) => SelectedMenuItem)
      | SelectedMenuItem,
  ) => void;
}

function MenuModalRightSide({
  menus,
  handleAddMenuItem,
  menuItemsCountLength,
  selectedItem,
  setSelectedItem,
}: Props) {
  useEffect(() => {
    if (menus.length) {
      setSelectedItem({
        selectedItem: menus[0]?.items,
        selectedItemSelectableQuantity: menus[0]?.selectable_quantity,
        selectedMenuId: menus[0]?.id,
      });
    }
  }, [menus]);

  const { t } = useTranslation('pos');

  if (!menus.length) {
    return <div>{t('menu_right.no_menu')}</div>;
  }

  return (
    <Paper
      style={{ overflow: 'auto' }}
      sx={styles.menuModalRightPaper}
      elevation={3}
    >
      {t('menu_right.categories')}
      <Divider />
      <MenuModalRightSideCategories
        menus={menus}
        menuItemsCountLength={menuItemsCountLength}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />

      {t('menu_right.menu_product')}
      <Divider />
      <MenuModalRightSideProducts
        handleAddMenuItem={handleAddMenuItem}
        selectedItem={selectedItem}
      />
    </Paper>
  );
}

export default MenuModalRightSide;
