import api from '../../api';
import { ApiSuccessResponse } from '../../types';
import {
  CreateResponse,
  ListResponse,
  ViewResponse,
  UpdateResponse,
  DeleteResponse,
} from './types';

const PAGING_LIMIT = 100;

type ListRes = ApiSuccessResponse<ListResponse>;

const list = () =>
  api.get<ListRes>('/units', {
    params: {
      per_page: PAGING_LIMIT,
    },
  });

type ViewRes = ApiSuccessResponse<ViewResponse>;
const view = (id: string) => api.get<ViewRes>(`/units/${id}`);

type CreatRes = ApiSuccessResponse<CreateResponse>;
const create = (name: { [key: string]: string }) =>
  api.post<CreatRes>('/units', {
    name,
  });

type UpdateRes = ApiSuccessResponse<UpdateResponse>;
const update = (params: { id: string; name: { [key: string]: string } }) =>
  api.put<UpdateRes>(`/units/${params.id}`, {
    id: params.id,
    name: params.name,
  });

type DeleteRes = ApiSuccessResponse<DeleteResponse>;
const remove = (id: string) => api.delete<DeleteRes>(`/units/${id}`);

const moveUnitUp = (id: string) =>
  api.post<void>(`/units/${id}/move-sequence-up`);

const moveUnitDown = (id: string) =>
  api.post<void>(`/units/${id}/move-sequence-down`);

export default { list, view, create, update, remove, moveUnitDown, moveUnitUp };
