/** Dependencies */
import React from 'react';

/** Styles */
import { Grid } from '@mui/material';
import { FormDataType } from './types';
import useSettings from '../../hooks/useSettings';

interface Props {
  formData: FormDataType;
  screenType: string;
}

function GeneralSettingsForm({ formData, screenType }: Props) {
  const { branchSettings } = useSettings();

  return (
    <>
      <Grid
        sx={{ color: formData.text_color, height: '18vh', minHeight: '18vh' }}
      >
        {formData.is_shown_logo &&
          (branchSettings?.branch?.logo_url ? (
            <Grid>
              <img
                src={branchSettings?.branch?.logo_url}
                alt=""
                width="100px"
                height="100px"
              />
            </Grid>
          ) : (
            // Logo yoksa isim yazdır
            <Grid sx={{ fontSize: formData.font_size, fontWeight: '700' }}>
              {branchSettings?.branch?.name}
            </Grid>
          ))}
      </Grid>
      <Grid sx={{ height: screenType === 'settings' ? '28vh' : '42vh' }} />
      <Grid
        sx={{
          color: formData.text_color,
          height: '28vh',
          minHeight: '28vh',
          overflow: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          flexDirection: 'column',
        }}
      >
        <Grid sx={{ fontSize: formData.font_size, fontWeight: '700' }}>
          {formData.name}
          <br />
          {formData.caption}
        </Grid>
      </Grid>
    </>
  );
}

export default GeneralSettingsForm;
