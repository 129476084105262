import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../store';
import {
  getCustomerListThunk,
  getListPaymentThunk,
  getCustomerThunk,
} from '../../store/slices/definitionsSlice/customers';
import { isApiError } from '../../services/api/types';
import { CustomerBase } from '../../pages/definitions/Customers/types';
import errorReporting from '../../helpers/errorReporting';
import api from '../../services/api';
import {
  addCustomerSlice,
  addGetPaidSlice,
  removeCustomerPaidSlice,
  removeCustomerSlice,
  toggleActiveSingleSlice,
  toggleActiveSlice,
  toggleOpenAccountSingleSlice,
  updateCustomerPaidSlice,
  updateCustomerSlice,
} from '../../store/slices/definitionsSlice/customers/customersSlice';

const useCustomers = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');

  const customers = useAppSelector(
    state => state.definitionsCustomers.customers,
  );
  const customerPaid = useAppSelector(
    state => state.definitionsCustomers.customerPaid,
  );
  const meta = useAppSelector(state => state.definitionsCustomers.meta);
  const paymentMeta = useAppSelector(
    state => state.definitionsCustomers.paymentMeta,
  );
  const customer = useAppSelector(state => state.definitionsCustomers.customer);

  const customerLoading = useAppSelector(
    state => state.definitionsCustomers.loading,
  );
  const customerPaymentLoading = useAppSelector(
    state => state.definitionsCustomers.paymentLoading,
  );
  const customerError = useAppSelector(
    state => state.definitionsCustomers.error,
  );

  const fetchCustomers = (params: {
    search: string;
    is_active?: boolean | null;
    first_name: string;
    is_open_account?: boolean | null;
    page: number;
    per_page?: number | null;
  }) => dispatch(getCustomerListThunk(params));

  const fetchPaymentList = (params: { page: number; id: string }) =>
    dispatch(getListPaymentThunk(params));

  const getCustomer = (id: string) => dispatch(getCustomerThunk(id));

  const addCustomer = async (params: CustomerBase) => {
    try {
      const response = await api.definitions.customers.create(
        params.first_name,
        params.last_name,
        params.phone_number,
        params.phone_number_country,
        params.is_open_account,
        params.chargeable_amount,
      );
      const result = response.data.data;
      dispatch(addCustomerSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const addGetPaid = async (params: {
    customer_id: string;
    amount: string;
    payment_type_id: string;
  }) => {
    try {
      const response = await api.definitions.customers.payment(
        params.customer_id,
        params.amount,
        params.payment_type_id,
      );
      const result = response.data.data;
      dispatch(addGetPaidSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const updateGetPaid = async (params: {
    paid_id: string;
    customer_id: string;
    amount: string;
    payment_type_id: string;
  }) => {
    try {
      const response = await api.definitions.customers.updatePaid(
        params.paid_id,
        params.customer_id,
        params.amount,
        params.payment_type_id,
      );
      const result = response.data.data;
      dispatch(updateCustomerPaidSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const removeCustomerPaid = async (paid_id: string, customer_id: string) => {
    try {
      const response = await api.definitions.customers.paidRemove(
        paid_id,
        customer_id,
      );
      const result = response.data.data;
      dispatch(removeCustomerPaidSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const updateCustomer = async (params: CustomerBase) => {
    try {
      const response = await api.definitions.customers.update(
        params.id,
        params.first_name,
        params.last_name,
        params.phone_number,
        params.is_active,
        params.phone_number_country,
        params.is_open_account,
        params.chargeable_amount,
      );
      const result = response.data.data;
      dispatch(updateCustomerSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const toggleActive = async (id: string) => {
    try {
      const result = await api.definitions.customers.toggleActive(id);
      dispatch(toggleActiveSlice({ id }));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
      errorReporting(err);
      throw err;
    }
  };

  const toggleActiveSingle = async (id: string) => {
    try {
      const result = await api.definitions.customers.toggleActive(id);
      dispatch(toggleActiveSingleSlice());
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
      errorReporting(err);
      throw err;
    }
  };
  const toggleOpenAccountSingle = async (id: string) => {
    try {
      const result = await api.definitions.customers.toggleOpenActive(id);
      dispatch(toggleOpenAccountSingleSlice());
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
      errorReporting(err);
      throw err;
    }
  };

  const removeCustomer = async (id: string) => {
    try {
      const response = await api.definitions.customers.remove(id);
      const result = response.data.data;
      dispatch(removeCustomerSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
      errorReporting(err);
      throw err;
    }
  };

  return {
    customer,
    customers,
    customerPaid,
    meta,
    paymentMeta,
    customerLoading,
    customerPaymentLoading,
    customerError,
    fetchPaymentList,
    fetchCustomers,
    getCustomer,
    addCustomer,
    addGetPaid,
    updateGetPaid,
    updateCustomer,
    toggleActive,
    toggleActiveSingle,
    toggleOpenAccountSingle,
    removeCustomer,
    removeCustomerPaid,
  };
};
export default useCustomers;
