import {
  Box,
  Grid,
  Paper,
  PaperProps,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import Icon, { IconName } from '../Icon';
import IconWithContainer from '../IconWithContainer';
import MobileMenuButton from './MobileMenuButton';
import MobileMenu from './MobileMenu';
import useReports from '../../hooks/useReports';
import ConfirmationModal from '../ConfirmationModal';
import useProfile from '../../hooks/useProfile';

export interface Props {
  containerStyle?: PaperProps['sx'];
  title: string;
  subtitle?: string;
  leftIconName?: IconName;
  menu?: {
    id: number;
    text: string;
    startIconName?: IconName;
    endIconName?: IconName;
    permissionKeys?: string[];
  }[];
  onActionClick?: (id: number) => void;
  imageUrl?: string;
}

function UtilitiesBar({
  containerStyle,
  title,
  subtitle,
  leftIconName,
  menu,
  onActionClick,
  imageUrl,
}: Props) {
  const matchesXs = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  const { t } = useTranslation('dashboard');
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const { resetAccount, loading } = useReports();
  const { meProfile } = useProfile();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<HTMLElement | null>(null);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleResetAccount = async () => {
    setDeleteConfirmation(false);
    await resetAccount();
  };

  return (
    <>
      <Paper
        sx={[
          {
            height: 80,
            py: 2,
            px: 3,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
          ...(containerStyle
            ? Array.isArray(containerStyle)
              ? containerStyle
              : [containerStyle]
            : []),
        ]}
        elevation={1}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              {leftIconName && (
                <IconWithContainer
                  containerStyle={{ mr: 1 }}
                  name={leftIconName}
                />
              )}
              {imageUrl && (
                <img
                  style={{ marginRight: '5px' }}
                  src={imageUrl}
                  alt=""
                  width="40px"
                  height="40px"
                />
              )}
              <Grid>
                <Typography variant="h5">{title}</Typography>
                {subtitle && (
                  <Typography
                    sx={{ fontWeight: '400', mt: 1, fontSize: '12px' }}
                    variant="subtitle2"
                  >
                    {subtitle}
                  </Typography>
                )}
              </Grid>
            </Box>

            {window.location.pathname === '/Settings/restaurant' &&
              meProfile?.activeBranch?.billing_type === 'demo' &&
              meProfile?.activeRole?.name === 'owner' && (
                <Grid item xs={5} display="flex" justifyContent="flex-end">
                  <Button
                    sx={{ fontSize: '12px', borderRadius: '8px' }}
                    size="large"
                    variant="contained"
                    onClick={() => setDeleteConfirmation(true)}
                  >
                    {t('resetAccount')}
                  </Button>
                </Grid>
              )}
          </Box>
        </Box>
        <Box sx={{ display: 'flex' }}>
          {menu &&
            (!matchesXs ? (
              menu.map(item => (
                <Button
                  sx={{ padding: '20px' }}
                  key={item.text}
                  variant="text"
                  onClick={() => onActionClick && onActionClick(item.id)}
                  startIcon={
                    item.startIconName && <Icon name={item.startIconName} />
                  }
                  endIcon={item.endIconName && <Icon name={item.endIconName} />}
                >
                  {item.text}
                </Button>
              ))
            ) : (
              <MobileMenuButton onClick={handleMobileMenuOpen} />
            ))}
        </Box>
      </Paper>
      <MobileMenu
        anchor={mobileMoreAnchorEl}
        onClose={handleMobileMenuClose}
        menu={menu}
        onActionClick={onActionClick}
      />
      <ConfirmationModal
        title={t('deleteConfirmation.title')}
        titleIconName="QuestionMarkOutlined"
        description={t('deleteConfirmation.description')}
        open={deleteConfirmation}
        onClose={() => setDeleteConfirmation(false)}
        positiveAction={t('deleteConfirmation.confirm')}
        onPositiveClick={handleResetAccount}
        negativeAction={t('deleteConfirmation.cancel')}
        onNegativeClick={() => setDeleteConfirmation(false)}
        loading={loading}
        description1={t('deleteConfirmation.description_1')}
      />
    </>
  );
}

export default UtilitiesBar;
