import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from '../../store';
import { getUnitListThunk } from '../../store/slices/definitionsSlice/units';
import { isApiError } from '../../services/api/types';
import api from '../../services/api';
import {
  addUnitSlice,
  moveDownSlice,
  moveUpSlice,
  removeUnitSlice,
  updateUnitSlice,
} from '../../store/slices/definitionsSlice/units/unitsSlice';

const useUnits = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const units = useAppSelector(state => state.definitionsUnits.units);
  const unitLoading = useAppSelector(state => state.definitionsUnits.loading);

  const unitError = useAppSelector(state => state.definitionsUnits.error);

  const fetchUnits = () => dispatch(getUnitListThunk());

  const addUnit = async (name: { [key: string]: string }) => {
    try {
      const response = await api.definitions.units.create(name);
      const result = response.data.data;
      dispatch(addUnitSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const updateUnit = async ({
    id,
    name,
  }: {
    id: string;
    name: { [key: string]: string };
  }) => {
    try {
      const response = await api.definitions.units.update({ id, name });
      const result = response.data.data;
      dispatch(updateUnitSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };
  const removeUnit = async (id: string) => {
    try {
      const response = await api.definitions.units.remove(id);
      const result = response.data.data;
      dispatch(removeUnitSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const productUnitMoveDown = async (id: string) => {
    try {
      const response = await api.definitions.units.moveUnitDown(id);
      const result = response.data;
      dispatch(moveDownSlice({ id }));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const productUnitMoveUp = async (id: string) => {
    try {
      const response = await api.definitions.units.moveUnitUp(id);
      const result = response.data;
      dispatch(moveUpSlice({ id }));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  return {
    units,
    unitLoading,
    unitError,
    fetchUnits,
    addUnit,
    updateUnit,
    removeUnit,
    productUnitMoveUp,
    productUnitMoveDown,
  };
};
export default useUnits;
