import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginFormValues } from '../../pages/Login/types';
import { RegisterFormValues } from '../../pages/Register/types';
import {
  LAST_REGISTRATION_STEP,
  ResetValues,
} from '../../services/api/auth/types';
import { isApiError } from '../../services/api/types';
import { useAppDispatch } from '../../store';
import {
  loginThunk,
  registerFirstStepThunk,
  logoutThunk,
  logoutWithPinThunk,
  loginByPinThunk,
} from '../../store/slices/authSlice';
import useProfile from '../useProfile';
import {
  BranchIdStorage,
  expiredAccountStorage,
  salesChannelNameStorage,
  salesChannelStorage,
} from '../../services/tokenStorage';
import errorReporting from '../../helpers/errorReporting';
import api from '../../services/api';

function useAuth() {
  const navigate = useNavigate();
  const location = useLocation();
  const { meProfile } = useProfile();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');

  const from = location.state?.from?.pathname || '/';

  const login = async (params: LoginFormValues) => {
    try {
      const response = await dispatch(loginThunk(params));
      const { registrationStep } = await unwrapResult(response);
      // Go to company-register if registration is not completed
      if (!registrationStep || registrationStep < LAST_REGISTRATION_STEP) {
        navigate('/company-register');
      } else {
        navigate(from, { replace: true });
      }
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      errorReporting(err);
    }
  };

  const loginByPin = async (pin: string) => {
    try {
      const response = await dispatch(loginByPinThunk(pin));
      await unwrapResult(response);

      enqueueSnackbar('Başarılı bir şekilde giriş yaptınız.', {
        variant: 'success',
        autoHideDuration: 3000,
      });

      navigate(from, { replace: true });
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      errorReporting(err);
    }
  };

  const registerFirstStep = async (params: RegisterFormValues) => {
    try {
      const response = await dispatch(registerFirstStepThunk(params));
      await unwrapResult(response);
      navigate('/company-register', { replace: true });
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      errorReporting(err);
    }
  };

  const logout = async () => {
    try {
      const response = await dispatch(logoutThunk());
      unwrapResult(response);
      expiredAccountStorage.removeToken();
      salesChannelStorage.removeToken();
      salesChannelNameStorage.removeToken();
      navigate('/login', { replace: true });
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      errorReporting(err);
    }
  };

  const logoutWithPin = async () => {
    try {
      const response = await dispatch(logoutWithPinThunk());
      unwrapResult(response);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      meProfile?.activeBranch?.id &&
        BranchIdStorage.setToken(meProfile?.activeBranch?.id);
      salesChannelStorage.removeToken();
      salesChannelNameStorage.removeToken();
      navigate('/lock', { replace: true });
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      errorReporting(err);
    }
  };

  const forgotPassword = async (email: string) => {
    await api.auth.forgotPassword(email);
  };

  const checkResetPassword = async (token: string) => {
    await api.auth.checkResetPassword(token);
  };

  const resetPassword = async (params: ResetValues) => {
    try {
      await api.auth.resetPassword(params);
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      errorReporting(err);
    }
  };

  return {
    login,
    loginByPin,
    registerFirstStep,
    logout,
    logoutWithPin,
    forgotPassword,
    checkResetPassword,
    resetPassword,
  };
}

export default useAuth;
