/** Dependencies */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { matchIsValidTel } from 'mui-tel-input';

/** Types */
import { CustomerBase } from '../../../../../pages/definitions/Customers/types';

/** Components */

import FormInput from '../../../../../components/FormInput';
import FormPhoneInput from '../../../../../components/FormPhoneInput';
import FormSwitch from '../../../../../components/FormSwitch';
import useCustomers from '../../../../../hooks/useCustomers';
import FormNumberInput from '../../../../../components/FormNumberInput';
import LoadingState from '../../../../../components/LoadingState';
import useProfile from '../../../../../hooks/useProfile';

interface Props {
  item?: CustomerBase | null;
  control: Control<CustomerBase, Object>;
  value: string;
  handleChange(newValue: any, info: any): void;
}

function Form({ item, control, value, handleChange }: Props) {
  const { t } = useTranslation('customer');
  const { customerLoading } = useCustomers();
  const { meProfile } = useProfile();

  const [openAccountInput, setOpenAccountInput] = useState<boolean>(false);

  const openAccountHandle = () => {
    if (openAccountInput === true) {
      setOpenAccountInput(false);
    } else {
      setOpenAccountInput(true);
    }
  };
  return (
    <>
      <Controller
        name="first_name"
        // @ts-ignore
        control={control}
        render={({ field, fieldState }) => (
          <FormInput
            label={t('customer.addModal.form.first_name.label')}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            {...field}
          />
        )}
      />
      <Controller
        name="last_name"
        // @ts-ignore
        control={control}
        render={({ field, fieldState }) => (
          <FormInput
            label={t('customer.addModal.form.last_name.label')}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            {...field}
          />
        )}
      />
      <Controller
        name="phone_number"
        // @ts-ignore
        control={control}
        rules={{ validate: matchIsValidTel }}
        render={({ field }) => (
          <FormPhoneInput
            label={t('customer.addModal.form.phone_number.label')}
            phoneValue={value}
            handleChange={handleChange}
            {...field}
          />
        )}
      />
      {item?.id && (
        <Controller
          name="is_active"
          // @ts-ignore
          control={control}
          defaultValue={false}
          render={({ field, fieldState }) => (
            <FormSwitch
              label={t('customer.addModal.form.is_active.label')}
              error={fieldState.invalid}
              {...field}
            />
          )}
        />
      )}
      <Controller
        name="is_open_account"
        // @ts-ignore
        control={control}
        defaultValue={false}
        render={({ field, fieldState }) => (
          <FormSwitch
            onClick={() => {
              openAccountHandle();
            }}
            label={t('customer.addModal.form.first_name.openAccount')}
            error={fieldState.invalid}
            {...field}
          />
        )}
      />
      {openAccountInput &&
        (customerLoading ? (
          <LoadingState />
        ) : item?.chargeable_amount ? (
          <Controller
            name="chargeable_amount"
            // @ts-ignore
            control={control}
            render={({ field, fieldState }) => (
              <FormNumberInput
                label={t('customer.addModal.form.first_name.openAccountLimit')}
                placeholder={`0,00 ${meProfile?.currency?.code || 'TRY'}`}
                maskProps={{
                  decimalScale: 2,
                  fixedDecimalScale: true,
                  suffix: ` ${meProfile?.currency?.code || 'TRY'}`,
                }}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
        ) : (
          <Controller
            name="chargeable_amount"
            // @ts-ignore
            control={control}
            render={({ field, fieldState }) => (
              <FormNumberInput
                label={t('customer.addModal.form.first_name.openAccountLimit')}
                placeholder={`0,00 ${meProfile?.currency?.code || 'TRY'}`}
                maskProps={{
                  decimalScale: 2,
                  fixedDecimalScale: true,
                  suffix: ` ${meProfile?.currency?.code || 'TRY'}`,
                }}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
        ))}
    </>
  );
}

export default Form;
