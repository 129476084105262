/** Dependencies */
import React from 'react';
import { Button, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

/** Components */
import Modal from '../../../../../components/Modal';

/** Hooks */
import useSettings from '../../../../../hooks/useSettings';
import styles from '../styles';
import { NameTranslation } from '../../../../../models/General';

interface Props {
  open: boolean;
  onClose: () => void;
  handleSelect: (
    payment_type_id: number,
    translation: NameTranslation[],
  ) => void;
  unSubmittedPackage: () => void;
}

function PackagePaymentModal({
  open,
  onClose,
  handleSelect,
  unSubmittedPackage,
}: Props) {
  const { meProfile } = useSettings();

  const { t } = useTranslation('pos');

  const handleAddPaymentClick = async (
    id: number,
    translation: NameTranslation[],
  ) => {
    handleSelect(id, translation);
    onClose();
  };

  const handleClose = () => {
    onClose();
    unSubmittedPackage();
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      title={t('paymentModal.select_pay')}
    >
      <div>
        <Stack
          sx={styles.packagePaymentStack}
          direction={{ md: 'row', xs: 'column' }}
          spacing={3}
        >
          <Grid container item>
            <Grid
              sx={{ mt: 3 }}
              container
              item
              justifyContent="flex-start"
              xs={12}
            >
              {meProfile?.paymentTypes?.map(
                item =>
                  item.is_active && (
                    <Grid sx={{ p: 1 }} item xs={6}>
                      <Button
                        color="inherit"
                        fullWidth
                        sx={styles.paymentTypeButton}
                        onClick={() =>
                          handleAddPaymentClick(item.id, item.translations)
                        }
                      >
                        <Grid justifyContent="center">
                          <Grid>
                            <img
                              src={item.logo_url}
                              alt=""
                              width="40px"
                              height="40px"
                            />
                          </Grid>
                          <Grid>{item.name}</Grid>
                        </Grid>
                      </Button>
                    </Grid>
                  ),
              )}
            </Grid>
          </Grid>
        </Stack>
      </div>
    </Modal>
  );
}

export default PackagePaymentModal;
