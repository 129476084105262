import api from '../../api';
import { ApiSuccessResponse } from '../../types';
import {
  ListResponse,
  ViewResponse,
  UpdateRolePermissionsTypeResponse,
} from './types';

type ListRes = ApiSuccessResponse<ListResponse>;
const list = () =>
  api.get<ListRes>('/roles', {
    params: {
      sort: 'created_at',
    },
  });

type ViewRes = ApiSuccessResponse<ViewResponse>;
const view = (id: string) =>
  api.get<ViewRes>(`/roles/${id}`, {
    params: {
      sort: 'created_at',
    },
  });
const viewRole = (id: string) => api.get<ViewRes>(`/roles/${id}`);

type UpdateRolePermissionsTypeRes =
  ApiSuccessResponse<UpdateRolePermissionsTypeResponse>;
const update = (params: UpdateRolePermissionsTypeResponse) => {
  const data = new URLSearchParams();
  params.rolePermissions.forEach(item =>
    data.append(
      `permissions[${item.id}][is_active]`,
      JSON.stringify(item.active ? 1 : 0),
    ),
  );

  return api.put<UpdateRolePermissionsTypeRes>(
    `/roles/${params.role_id}`,
    data,
  );
};

export default {
  list,
  view,
  viewRole,
  update,
};
