import { Box, TextField, Typography, TextFieldProps } from '@mui/material';
import React, { forwardRef } from 'react';
import styles from './styles';

interface Props {
  disableUnderline?: boolean;
}

function FormDatePicker(
  { label, sx, disableUnderline = false, ...props }: Props & TextFieldProps,
  ref: TextFieldProps['ref'],
) {
  return (
    <Box
      sx={[styles.container, ...(sx ? (Array.isArray(sx) ? sx : [sx]) : [])]}
    >
      <Typography variant="subtitle1">{label}</Typography>
      <TextField
        ref={ref}
        type="date"
        variant="filled"
        color="success"
        hiddenLabel
        InputProps={{ disableUnderline }}
        fullWidth
        {...props}
      />
    </Box>
  );
}

export default forwardRef(FormDatePicker);
