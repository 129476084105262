import * as React from 'react';

function EmptySvg(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={48}
      height={48}
      viewBox="0 0 512 512"
      {...props}
    >
      <path d="M493.297 159.693c-12.477-30.878-31.231-59.828-56.199-84.792-24.965-24.969-53.917-43.723-84.795-56.2C321.421 6.22 288.611 0 255.816 0c-32.747 0-65.495 6.249-96.311 18.744-30.813 12.491-59.693 31.244-84.603 56.158-24.915 24.911-43.668 53.792-56.158 84.607C6.249 190.324 0 223.072 0 255.822c0 32.794 6.222 65.602 18.701 96.485 12.477 30.877 31.231 59.828 56.2 84.792 24.964 24.967 53.914 43.722 84.792 56.199 30.882 12.48 63.69 18.701 96.484 18.703 32.748 0 65.497-6.249 96.315-18.743 30.814-12.49 59.695-31.242 84.607-56.158 24.915-24.912 43.668-53.793 56.158-84.608 12.494-30.817 18.743-63.565 18.744-96.315-.001-32.794-6.223-65.602-18.704-96.484zM461.611 339.66c-10.821 26.683-27.018 51.648-48.659 73.292-21.643 21.64-46.608 37.837-73.291 48.659-26.679 10.818-55.078 16.241-83.484 16.241-28.477 0-56.947-5.406-83.688-16.214-26.744-10.813-51.76-27.008-73.441-48.685-21.678-21.683-37.874-46.698-48.685-73.443-10.808-26.741-16.214-55.212-16.213-83.689-.001-28.405 5.423-56.802 16.24-83.482 10.821-26.683 27.018-51.648 48.659-73.291 21.643-21.64 46.607-37.837 73.289-48.659 26.678-10.818 55.075-16.242 83.48-16.242 28.478 0 56.95 5.405 83.691 16.213 26.745 10.811 51.762 27.007 73.445 48.686 21.678 21.682 37.873 46.697 48.685 73.441 10.808 26.741 16.214 55.211 16.214 83.688-.001 28.407-5.424 56.805-16.242 83.485z" />
      <path d="m279.627 256.001 82.693-82.693c6.525-6.525 6.525-17.102 0-23.627-6.524-6.524-17.102-6.524-23.627 0L256 232.375l-82.693-82.693c-6.525-6.524-17.102-6.524-23.627 0-6.524 6.524-6.524 17.102 0 23.627l82.693 82.693-82.693 82.693c-6.524 6.523-6.524 17.102 0 23.627 6.525 6.524 17.102 6.524 23.627 0L256 279.628l82.693 82.693c6.525 6.524 17.102 6.524 23.627 0 6.525-6.524 6.525-17.102 0-23.627l-82.693-82.693z" />
    </svg>
  );
}
export default EmptySvg;
