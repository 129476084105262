/** Dependencies */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, UseFormWatch } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';

/** Components */
import FormInput from '../../../../components/FormInput';
import FormSelect from '../../../../components/FormSelect';

/** Types */
import { PrinterAddAndUpdateFormValues } from '../types';

/** Hooks */
import useKitchenGroups from '../../../../hooks/useKitchenGroups';
import { OptionsMemoWithName } from '../../../../helpers/optionMemo';

interface Props {
  control: Control<PrinterAddAndUpdateFormValues, Object>;
  watch: UseFormWatch<PrinterAddAndUpdateFormValues>;
  activeLanguage: string;
}

function Form({ control, watch, activeLanguage }: Props) {
  const { t } = useTranslation('printerSettings');
  const { kitchenGroups, fetchKitchenGroups } = useKitchenGroups();

  useEffect(() => {
    fetchKitchenGroups();
  }, []);

  const charPerLineOptions =
    activeLanguage === 'tr'
      ? [
          { id: 80, text: '3 inç (80mm)' },
          { id: 58, text: '2 inç (58mm)' },
        ]
      : [
          { id: 80, text: '3 inches (80mm)' },
          { id: 58, text: '2 inches (58mm)' },
        ];

  const fontSizeOptions =
    activeLanguage === 'tr'
      ? [
          { id: 'small', text: 'Küçük' },
          { id: 'medium', text: 'Orta' },
          { id: 'large', text: 'Büyük' },
        ]
      : [
          { id: 'small', text: 'Small' },
          { id: 'medium', text: 'Medium' },
          { id: 'large', text: 'Large' },
        ];

  const connectionTypeOptions = [
    // { id: 'windows', text: 'Windows' },
    // { id: 'cups', text: 'Cups' },
    { id: 'network', text: 'Network' },
  ];

  const capabilityProfileOptions = [
    { id: 'default', text: 'Default' },
    { id: 'simple', text: 'Simple' },
    { id: 'SP2000', text: 'SP2000' },
    { id: 'TEP-200M', text: 'TEP-200M' },
    { id: 'P822D', text: 'P822D' },
  ];

  return (
    <>
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <FormInput
            label={t('printer.addModal.form.name.label')}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            {...field}
          />
        )}
      />
      <Controller
        name="connection_type"
        control={control}
        render={({ field, fieldState }) => (
          <FormSelect
            label={t('printer.addModal.form.connection_type.label')}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            underline
            options={connectionTypeOptions}
            {...field}
          />
        )}
      />
      <Controller
        name="capability_profile"
        control={control}
        render={({ field, fieldState }) => (
          <FormSelect
            label={t('printer.addModal.form.capability_profile.label')}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            underline
            options={capabilityProfileOptions}
            {...field}
          />
        )}
      />
      <Controller
        name="char_per_line"
        control={control}
        render={({ field, fieldState }) => (
          <FormSelect
            label={t('printer.addModal.form.char_per_line.label')}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            underline
            options={charPerLineOptions}
            {...field}
          />
        )}
      />
      {watch().connection_type === 'network' && (
        <>
          <Controller
            name="ip_address"
            control={control}
            render={({ field, fieldState }) => (
              <FormInput
                label={t('printer.addModal.form.ip_address.label')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
          <Controller
            name="port"
            control={control}
            render={({ field, fieldState }) => (
              <FormInput
                label={t('printer.addModal.form.port.label')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
        </>
      )}
      {(watch().connection_type === 'windows' ||
        watch().connection_type === 'cups') && (
        <Controller
          name="path"
          control={control}
          render={({ field, fieldState }) => (
            <FormInput
              label={t('printer.addModal.form.path.label')}
              error={fieldState.invalid}
              helperText={
                fieldState.error
                  ? t(fieldState.error.message as any)
                  : undefined
              }
              {...field}
            />
          )}
        />
      )}
      <Controller
        name="font_size"
        control={control}
        render={({ field, fieldState }) => (
          <FormSelect
            label={t('printer.addModal.form.font_size.label')}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            underline
            options={fontSizeOptions}
            {...field}
          />
        )}
      />
      <Controller
        name="footer_description"
        control={control}
        render={({ field, fieldState }) => (
          <FormInput
            label={t('printer.addModal.form.footer_description.label')}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            {...field}
          />
        )}
      />
      <Controller
        name="kitchen_group_ids"
        control={control}
        render={({ field, fieldState }) => (
          <FormSelect
            label={t('printer.addModal.form.kitchen_group_ids.label')}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            multiple
            underline
            options={OptionsMemoWithName(kitchenGroups)}
            {...field}
          />
        )}
      />
    </>
  );
}

export default Form;
