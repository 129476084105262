import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import styles from './styles';
import RouterLink from '../../components/RouterLink';
import Form from './Form';
import { useAppSelector } from '../../store';
import useAuth from '../../hooks/useAuth';
import { AccessTokenStorage } from '../../services/tokenStorage';
import Logo from '../../components/Logo';
import { ReactComponent as Promotion } from '../../assets/promotion/login-promotion.svg';

function Login() {
  const { t } = useTranslation('auth');
  const { login } = useAuth();
  const location = useLocation();
  const isLoading = useAppSelector(state => state.auth.login.loading);

  const token = AccessTokenStorage.getToken();
  if (token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftContainer}>
        <Box sx={styles.logo}>
          <Logo />
          <Typography sx={styles.logoSubtitle} variant="h6" textAlign="center">
            {t('privacy')}
            <br />
            {t('kitchen_title')}
          </Typography>
        </Box>
        <Box sx={styles.promotion}>
          <Promotion height="100%" width="100%" title="promotion-picture" />
        </Box>
      </Box>
      <Box sx={styles.rightContainer}>
        <Box sx={styles.formContainer}>
          <Typography
            variant="h5"
            fontWeight="bold"
            textAlign="center"
            sx={{ mb: 1 }}
          >
            {t('login.title')}
          </Typography>
          <Typography variant="subtitle1" textAlign="center">
            <Trans
              i18nKey="auth:login.subtitle"
              components={[<RouterLink to="/register" />]}
            />
          </Typography>
          <br />
          <Form
            onValidSubmit={async data => {
              login(data);
            }}
            submitting={isLoading}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
