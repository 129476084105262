/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

/** Styles */
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
} from '@mui/material';

/** Components */
import Icon from '../../../components/Icon';
import ConfirmationModal from '../../../components/ConfirmationModal';
import EmptyState from '../../../components/EmptyState';

/** Hooks */
import useCustomerAddress from '../../../hooks/useCustomerAddress';
import { isApiError } from '../../../services/api/types';
import keyHasPermission from '../../../helpers/keyHasPermission';
import useProfile from '../../../hooks/useProfile';
import errorReporting from '../../../helpers/errorReporting';
import LoadingState from '../../../components/LoadingState';
import TableComponent from '../../../components/TableComponent';

export default function CustomerAddressTable() {
  const { meProfile } = useProfile();
  const { t } = useTranslation('customerAddress');
  const { enqueueSnackbar } = useSnackbar();
  const {
    customerAddresses,
    removeCustomerAddress,
    setDefaultCustomerAddress,
    customerAddressLoading,
  } = useCustomerAddress();

  const [loadingAddresses, setLoadingAddresses] = useState<boolean>(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    address_id: '',
    customer_id: '',
  });

  const hasUpdatePermission = keyHasPermission(
    'update-customer-address',
    meProfile?.permissions || [],
  );

  const hasDeletePermission = keyHasPermission(
    'delete-customer-address',
    meProfile?.permissions || [],
  );

  const hasListPermission = keyHasPermission(
    'view-customer-address-listing',
    meProfile?.permissions || [],
  );

  const handleRemove = async () => {
    setLoadingAddresses(true);
    try {
      await removeCustomerAddress(
        deleteConfirmation.address_id,
        deleteConfirmation.customer_id,
      );
      setDeleteConfirmation({
        address_id: '',
        customer_id: '',
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
        },
      );
      errorReporting(err);
    }
    setLoadingAddresses(false);
  };

  const handleSetDefaultAddress = async (
    address_id: string,
    customer_id: string,
    is_default: boolean,
  ) => {
    if (is_default) {
      enqueueSnackbar(t('customerAddress.isDefault'), {
        variant: 'error',
      });
    } else {
      try {
        setLoadingAddresses(true);
        await setDefaultCustomerAddress(address_id, customer_id);
      } catch (err) {
        enqueueSnackbar(
          isApiError(err) ? err.message : t('common:unknownError' as any),
          {
            variant: 'error',
          },
        );
        errorReporting(err);
      } finally {
        setLoadingAddresses(false);
      }
    }
  };

  const tableHead = [
    t('customerAddress.addressesTable.addressName'),
    t('customerAddress.addressesTable.address'),
    t('customerAddress.addressesTable.addressNote'),
    t('customerAddress.addressesTable.isDefault'),
  ];

  const tableBody =
    // @ts-ignore
    customerAddresses.map(customerAddress => (
      <TableRow
        key={customerAddress.id}
        sx={{
          '&:last-child td, &:last-child th': {
            border: 0,
          },
        }}
      >
        <TableCell component="th" scope="row">
          {customerAddress.name}
        </TableCell>
        <TableCell>{customerAddress.address}</TableCell>
        <TableCell>{customerAddress.note}</TableCell>
        <TableCell
          onClick={() => {
            if (customerAddress.customer && hasUpdatePermission) {
              handleSetDefaultAddress(
                customerAddress.id,
                customerAddress.customer.id,
                customerAddress.is_default,
              );
            }
          }}
        >
          <Switch
            color="success"
            checked={customerAddress.is_default}
            disabled={!hasUpdatePermission}
          />
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="Add"
            color="error"
            onClick={() => {
              if (customerAddress.customer && hasDeletePermission) {
                setDeleteConfirmation({
                  address_id: customerAddress.id,
                  customer_id: customerAddress.customer.id,
                });
              }
            }}
            disabled={!hasDeletePermission}
          >
            <Icon name="Delete" />
          </IconButton>
        </TableCell>
      </TableRow>
    ));

  const renderEmpty = () => (
    <EmptyState
      message={
        !hasListPermission
          ? t('customerAddress.permission')
          : t('customerAddress.empty')
      }
    />
  );

  return (
    <div>
      <div>
        {customerAddressLoading ? (
          <LoadingState />
        ) : !customerAddresses.length || !hasListPermission ? (
          renderEmpty()
        ) : (
          <TableComponent
            tableHead={tableHead}
            tableBody={tableBody}
            count={1}
            page={1}
            showPagination={false}
            component={Paper}
          />
        )}
      </div>

      <ConfirmationModal
        title={t('customerAddress.deleteConfirmation.title')}
        titleIconName="QuestionMarkOutlined"
        description={t('customerAddress.deleteConfirmation.description')}
        open={!!deleteConfirmation.address_id}
        onClose={() =>
          setDeleteConfirmation({
            address_id: '',
            customer_id: '',
          })
        }
        positiveAction={t('customerAddress.deleteConfirmation.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('customerAddress.deleteConfirmation.cancel')}
        onNegativeClick={() =>
          setDeleteConfirmation({
            address_id: '',
            customer_id: '',
          })
        }
        loading={loadingAddresses}
      />
    </div>
  );
}
