import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import IconButtonWithTooltip from '../../../../../components/IconButtonWithTooltip';
import LoadingState from '../../../../../components/LoadingState';
import useProducts from '../../../../../hooks/useProducts';
import { useAppSelector } from '../../../../../store';
import { IngredientOrderModal } from './IngredientOrderModal';
import IngredientItemList from './IngredientItemList';
import getActiveLanguage from '../../../../../helpers/getActiveLanguage';
import errorReporting from '../../../../../helpers/errorReporting';

interface Props {
  loading: boolean;
  productId?: string;
}
function IngredientItemsContainer({ loading, productId }: Props) {
  const [orderModal, setOrderModal] = useState(false);
  const [itemIdLoading, setItemIdLoading] = useState<string | null>(null);
  const [groupIdToEdit, setGroupIdToEdit] = useState<string | null>(null);

  const ProductInProcess = useAppSelector(
    state => state.definitionsProducts.ProductInProcess,
  );

  const {
    productIngredientItemMoveUp,
    productIngredientItemMoveDown,
    productIngredientItemUpdate,
    loading: itemsLoading,
  } = useProducts();

  const modalGroupItems = () => {
    if (ProductInProcess?.ingredient_groups) {
      const targetGroup = ProductInProcess.ingredient_groups.find(
        group => group.id === groupIdToEdit,
      );
      if (targetGroup) {
        const targetItems = Object.values(targetGroup.items);
        return targetItems.map(item => ({
          ...item,
          name: getActiveLanguage(item.translations).name,
        }));
      }
    }
    return null;
  };

  if (
    !ProductInProcess?.ingredient_groups ||
    ProductInProcess?.ingredient_groups.length < 1
  )
    return null;
  if (loading) return <LoadingState />;

  return (
    <Box sx={{ mt: 4 }}>
      {ProductInProcess.ingredient_groups.map(group => (
        <Box key={group.id} sx={{ mb: 4 }}>
          <Stack sx={{ mb: 2 }} direction="row" alignItems="center">
            <Typography variant="h6">
              {getActiveLanguage(group.translations).name}
            </Typography>
            <IconButtonWithTooltip
              sx={{ ml: 2.5 }}
              size="small"
              iconName="Edit"
              tip="Edit"
              tipPlacement="right"
              color="primary"
              onClick={() => {
                setGroupIdToEdit(group.id);
                setOrderModal(true);
              }}
            />
          </Stack>
          <IngredientItemList
            list={Object.values(group.items)} // Todo: remove Object.values when api fixed
            loadingItemID={itemIdLoading}
            onItemClick={async item => {
              try {
                if (productId) {
                  setItemIdLoading(item.id);
                  await productIngredientItemUpdate({
                    productId,
                    ingredientItemId: item.id,
                    isActive: !item.is_active, // toggle active status
                  });
                  setItemIdLoading(null);
                }
              } catch (err) {
                setItemIdLoading(null);
                errorReporting(err);
              }
            }}
          />
        </Box>
      ))}

      <IngredientOrderModal
        items={modalGroupItems()}
        open={orderModal}
        loading={itemsLoading}
        onClose={() => {
          setOrderModal(false);
          setGroupIdToEdit(null);
        }}
        onItemMoveUp={id => {
          if (productId) productIngredientItemMoveUp(productId, id);
        }}
        onItemMoveDown={id => {
          if (productId) productIngredientItemMoveDown(productId, id);
        }}
      />
    </Box>
  );
}

export default IngredientItemsContainer;
