/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

/** Components */
import UtilitiesBar, {
  Props as BarProps,
} from '../../../components/UtilitiesBar';
import useUsers from '../../../hooks/useUsers';
import ErrorState from '../../../components/ErrorState';
import LoadingState from '../../../components/LoadingState';
import UsersTable from './UsersTable';
import UserAddModal from './Modals/UserAddModal';
import useProfile from '../../../hooks/useProfile';
import arrayKeyHasPermission from '../../../helpers/arrayKeyHasPermission';

const MENU: BarProps['menu'] = [
  {
    id: 0,
    text: 'actions.addUser',
    startIconName: 'Add',
    permissionKeys: ['create-user'],
  },
];
function Users() {
  const { t } = useTranslation('user');
  const { meProfile } = useProfile();
  const { users, userError, userLoading, fetchUsers, removeUser } = useUsers();

  const [userAddModal, setUserAddModal] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);

  useEffect(() => {
    fetchUsers({ page: 1 });
  }, []);

  if (!users && userError) {
    return (
      <ErrorState
        message={
          userError ? userError.message : t('common:unknownError' as any)
        }
      />
    );
  }

  const handleAction = (id: number) => {
    switch (id) {
      case 0:
        setUserAddModal(true);
        break;
      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('title')}
        leftIconName="Customer"
        menu={MENU!
          .filter(item =>
            arrayKeyHasPermission(
              item?.permissionKeys || [],
              meProfile?.permissions || [],
            ),
          )
          .map(filteredItem => ({
            ...filteredItem,
            text: t(filteredItem.text as any),
          }))}
        onActionClick={handleAction}
      />
      {userLoading ? (
        <LoadingState />
      ) : (
        <UsersTable
          onItemEdit={item => {
            setUserToEdit(item);
            setUserAddModal(true);
          }}
          onItemRemove={removeUser}
        />
      )}
      <UserAddModal
        open={userAddModal}
        onClose={() => {
          setUserToEdit(null);
          setUserAddModal(false);
        }}
        item={userToEdit}
      />
    </Box>
  );
}

export default Users;
