import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUnitListThunk } from './thunks';

import { InitialState } from './types';
import arraySwap from '../../../../helpers/arraySwap';
import { IdAndNameTranslation } from '../../../../models/General';

const initialState: InitialState = { loading: false, units: [] };

const unitsSlice = createSlice({
  name: 'unitsSlice',
  initialState,
  reducers: {
    addUnitSlice: (state, action: PayloadAction<IdAndNameTranslation>) => ({
      ...state,
      units: [...state.units, action.payload],
    }),
    updateUnitSlice: (state, action: PayloadAction<IdAndNameTranslation>) => ({
      ...state,
      units: state.units.map(item =>
        item.id === action.payload.id ? action.payload : item,
      ),
    }),
    removeUnitSlice: (
      state,
      action: PayloadAction<Pick<IdAndNameTranslation, 'id'>>,
    ) => ({
      ...state,
      units: state.units.filter(item => item.id !== action.payload.id),
    }),
    moveUpSlice: (
      state,
      action: PayloadAction<Pick<IdAndNameTranslation, 'id'>>,
    ) => {
      const targetIndex = state.units.findIndex(
        item => item.id === action.payload.id,
      );
      return {
        ...state,
        units: arraySwap(state.units, targetIndex, targetIndex - 1),
      };
    },
    moveDownSlice: (
      state,
      action: PayloadAction<Pick<IdAndNameTranslation, 'id'>>,
    ) => {
      const targetIndex = state.units.findIndex(
        item => item.id === action.payload.id,
      );
      return {
        ...state,
        units: arraySwap(state.units, targetIndex, targetIndex + 1),
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getUnitListThunk.pending, state => ({
        ...state,
        loading: true,
      }))
      .addCase(getUnitListThunk.fulfilled, (state, action) => ({
        ...state,
        units: action.payload,
        loading: false,
      }))
      .addCase(getUnitListThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }));
  },
});

export const {
  addUnitSlice,
  updateUnitSlice,
  removeUnitSlice,
  moveUpSlice,
  moveDownSlice,
} = unitsSlice.actions;

export default unitsSlice.reducer;
