import {
  LoginByPinFormValues,
  LoginFormValues,
} from '../../../pages/Login/types';
import { RegisterFormValues } from '../../../pages/Register/types';
import api from '../api';
import {
  LoginResponse,
  RegisterFirstStepResponse,
  LogoutResponse,
  MeResponse,
  RegisterSecondStepResponse,
  RegisterSecondStepParams,
  RegisterThirdStepResponse,
  RegisterThirdStepParams,
  ResetValues,
} from './types';
import { ApiSuccessResponse } from '../types';
import i18n from '../../i18n';

type LoginRes = ApiSuccessResponse<LoginResponse>;
const login = async (params: LoginFormValues) =>
  api.post<LoginRes>('/login', {
    username: params.email,
    password: params.password,
  });

type LoginByPinRes = ApiSuccessResponse<LoginResponse>;
const loginByPin = async (params: LoginByPinFormValues) =>
  api.post<LoginByPinRes>('/login-by-pin', {
    pin: params.pin,
    branch_id: params.branch_id,
  });

type LogoutRes = ApiSuccessResponse<LogoutResponse>;
const logout = async () => api.post<LogoutRes>('/logout');

type RegisterFirstStepRes = ApiSuccessResponse<RegisterFirstStepResponse>;

const registerFirstStep = async (params: RegisterFormValues) =>
  api.post<RegisterFirstStepRes>('/register', {
    first_name: params.firstName,
    last_name: params.lastName,
    phone_number: params.phone,
    phone_number_country: params.phoneNumberCountry,
    email: params.email,
    password: params.password,
    password_confirmation: params.confirmPassword,
    is_read_contract: params.contract,
    is_read_gdpr: params.gdpr,
    language: i18n.language.slice(0, 2),
  });

type RegisterSecondStepRes = ApiSuccessResponse<RegisterSecondStepResponse>;

const registerSecondStep = async ({
  name,
  paymentTypes,
}: RegisterSecondStepParams) => {
  const data = new FormData();
  data.append('name', name);
  paymentTypes.forEach(item =>
    data.append(
      `payment_types[${item.id}][is_active]`,
      JSON.stringify(item.active ? 1 : 0),
    ),
  );

  return api.post<RegisterSecondStepRes>('/register/second-step', data);
};

type RegisterThirdStepRes = ApiSuccessResponse<RegisterThirdStepResponse>;

const registerThirdStep = async ({
  currencyId,
  startTime,
  workingTypeIds,
  businessTypeId,
  timezone,
}: RegisterThirdStepParams) => {
  const data = new FormData();
  data.append('start_time', startTime);
  data.append('currency_id', currencyId);
  data.append('business_type_id', businessTypeId);
  workingTypeIds.forEach((typeId, index) => {
    data.append(`working_types[${index}]`, typeId);
  });
  data.append('timezone', timezone);

  return api.post<RegisterThirdStepRes>('/register/third-step', data);
};

const verifyOtp = async (code: string) =>
  api.post('/verify-otp', {
    code,
  });

const resendOtp = async () => api.post('/resend-otp');

type MeRes = ApiSuccessResponse<MeResponse>;
const me = async () => api.get<MeRes>('/me');

type MeWithPaymentTypesRes = ApiSuccessResponse<MeResponse>;
const meWithPaymentTypes = async () =>
  api.get<MeWithPaymentTypesRes>('/me?paymentTypes');

const forgotPassword = async (email: string) =>
  api.post('/forgot-password', { email });

const checkResetPassword = async (token: string) =>
  api.post('/check-reset-password', { token });

const resetPassword = async (params: ResetValues) =>
  api.post('/reset-password', params);

export default {
  login,
  loginByPin,
  registerFirstStep,
  logout,
  me,
  meWithPaymentTypes,
  registerSecondStep,
  registerThirdStep,
  verifyOtp,
  resendOtp,
  forgotPassword,
  checkResetPassword,
  resetPassword,
};
