/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/** Styles */
import { Switch, Paper, TableCell, TableRow, IconButton } from '@mui/material';

/** Components */
import EmptyState from '../../../components/EmptyState';
import Icon from '../../../components/Icon';

/** Hooks */
import useCustomers from '../../../hooks/useCustomers';
import ConfirmationModal from '../../../components/ConfirmationModal';
import keyHasPermission from '../../../helpers/keyHasPermission';
import arrayKeyHasPermission from '../../../helpers/arrayKeyHasPermission';
import useProfile from '../../../hooks/useProfile';
import errorReporting from '../../../helpers/errorReporting';
import TableComponent from '../../../components/TableComponent';

interface Props {
  onItemEdit: (item: any) => void;
  onItemRemove: (id: string) => Promise<any>;
  page: number;
  setPage: (value: ((prevState: number) => number) | number) => void;
}

function CustomersTable({ onItemEdit, onItemRemove, page, setPage }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation('customer');
  const { customers, meta, toggleActive, fetchCustomers } = useCustomers();
  const { meProfile } = useProfile();

  const [loadingCustomer, setLoadingCustomer] = useState<boolean>(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(
    null,
  );

  // todo: handleChange de bir çok yerde kullanılıyor, generic yap
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (value !== page) {
      setPage(value);
      fetchCustomers({
        search: '',
        is_active: null,
        first_name: 'first_name',
        page: value,
      });
    }
  };

  useEffect(() => {
    // @ts-ignore
    setPage(meta.current_page);
  }, [meta]);

  const handleRemove = async () => {
    if (deleteConfirmation) {
      try {
        setLoadingCustomer(true);
        await onItemRemove(deleteConfirmation);
        setDeleteConfirmation(null);
        setLoadingCustomer(false);
      } catch (err: any) {
        setLoadingCustomer(false);
        errorReporting(err);
      }
    }
  };

  const handleToggleActive = async (id: string) => {
    try {
      setLoadingCustomer(true);
      await toggleActive(id);
    } catch (err: any) {
      errorReporting(err);
    } finally {
      setLoadingCustomer(false);
    }
  };

  const hasUpdatePermission = keyHasPermission(
    'update-customer',
    meProfile?.permissions || [],
  );

  const hasClickTableButtonPermission = arrayKeyHasPermission(
    ['view-customer-detail'],
    meProfile?.permissions || [],
  );

  const hasDeletePermission = keyHasPermission(
    'delete-customer',
    meProfile?.permissions || [],
  );

  const hasListPermission = keyHasPermission(
    'view-customer-listing',
    meProfile?.permissions || [],
  );

  const tableHead = [
    t('customer.customersTable.no'),
    t('customer.customersTable.firstName'),
    t('customer.customersTable.lastName'),
    t('customer.customersTable.phoneNumber'),
    t('customer.customersTable.isActive'),
    t('customer.customersTable.operations'),
  ];

  const tableBody = customers.map((row, index) => (
    <TableRow
      key={row.id}
      onClick={() => {
        if (hasClickTableButtonPermission) {
          navigate(`/definitions/customer-details/${row.id}`);
        }
      }}
    >
      <TableCell style={{ textAlign: 'center' }} component="th" scope="row">
        {
          // @ts-ignore
          `#${meta.per_page * (page - 1) + (index + 1)}`
        }
      </TableCell>
      <TableCell style={{ textAlign: 'center' }} component="th" scope="row">
        {row.first_name}
      </TableCell>
      <TableCell style={{ textAlign: 'center' }} component="th" scope="row">
        {row.last_name}
      </TableCell>
      <TableCell style={{ textAlign: 'center' }}>
        {row.phone_number || '-'}
      </TableCell>
      <TableCell
        style={{ textAlign: 'center' }}
        onClick={event => {
          event.stopPropagation();
          if (hasUpdatePermission) {
            handleToggleActive(row.id);
          }
        }}
      >
        <Switch
          checked={!!row.is_active}
          disabled={loadingCustomer || !hasUpdatePermission}
        />
      </TableCell>
      <TableCell style={{ textAlign: 'center' }}>
        <IconButton
          onClick={event => {
            event.stopPropagation();
            if (hasUpdatePermission) {
              onItemEdit(row);
            }
          }}
          aria-label="Add"
          color="primary"
          disabled={!hasUpdatePermission}
        >
          <Icon name="Edit" />
        </IconButton>
        <IconButton
          onClick={event => {
            event.stopPropagation();
            if (hasDeletePermission) {
              setDeleteConfirmation(row.id);
            }
          }}
          aria-label="Add"
          color="error"
          disabled={!hasDeletePermission}
        >
          <Icon name="Delete" />
        </IconButton>
      </TableCell>
    </TableRow>
  ));

  const renderEmpty = () => (
    <EmptyState
      message={
        !hasListPermission ? t('customer.permission') : t('customer.empty')
      }
    />
  );

  useEffect(() => {
    // @ts-ignore
    setPage(meta.current_page);
  }, [meta]);

  return (
    <Paper sx={{ width: '100%', p: 2 }}>
      {!customers.length || !hasListPermission ? (
        renderEmpty()
      ) : (
        <TableComponent
          tableHead={tableHead}
          tableBody={tableBody}
          showPagination
          page={page}
          handleChange={handleChange}
          tableStyle={{ minWidth: 650 }}
          tableHeadStyle={{ textAlign: 'center' }}
          tableBodyStyle={{
            cursor: hasClickTableButtonPermission ? 'pointer' : 'default',
          }}
          // @ts-ignore
          count={Math.ceil(meta.total / meta.per_page)}
        />
      )}
      <ConfirmationModal
        title={t('customer.deleteConfirmation.title')}
        titleIconName="QuestionMarkOutlined"
        description={t('customer.deleteConfirmation.description')}
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        positiveAction={t('customer.deleteConfirmation.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('customer.deleteConfirmation.cancel')}
        onNegativeClick={() => setDeleteConfirmation(null)}
      />
    </Paper>
  );
}

export default CustomersTable;
