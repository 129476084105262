import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import FormInput from '../../../../../components/FormInput';
import Modal from '../../../../../components/Modal';
import FormCheckbox from '../../../../../components/FormCheckbox';
import usePosOrder from '../../../../../hooks/usePosOrder';

interface Props {
  open: boolean;
  onClose: () => void;
  orderId: string;
  onBackClick: () => void;
  handleFilterSubmit?: () => void;
}
function CancelModal({
  open,
  onClose,
  orderId,
  onBackClick,
  handleFilterSubmit,
}: Props) {
  const { t } = useTranslation('pos');
  const { cancelTable } = usePosOrder();
  const { enqueueSnackbar } = useSnackbar();

  const [cancelInputDescription, setCancelInputDescription] = useState('');
  const [cancelInputCheckBox, setCancelInputCheckBox] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleDelete = async () => {
    try {
      if (!cancelInputDescription) {
        enqueueSnackbar(t('orderModal.cancelModal.warning_message'), {
          variant: 'warning',
        });
      } else {
        handleClose();
        await cancelTable(
          orderId,
          cancelInputDescription,
          cancelInputCheckBox ? 1 : 0,
        );
        onBackClick();
        enqueueSnackbar(t('orderModal.cancelModal.success_message'), {
          variant: 'success',
        });
        if (handleFilterSubmit) {
          handleFilterSubmit();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={t('orderModal.cancelModal.title')}
      subtitle={t('orderModal.cancelModal.subtitle')}
      positiveAction={t('orderModal.cancelModal.confirm')}
      onPositiveClick={async () => {
        await handleDelete();
      }}
      negativeAction={t('orderModal.cancelModal.cancel')}
      onNegativeClick={handleClose}
    >
      <FormInput
        value={cancelInputDescription}
        onChange={e => setCancelInputDescription(e.target.value)}
        label={t('orderModal.cancelModal.description')}
      />
    </Modal>
  );
}

export default CancelModal;
