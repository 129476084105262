import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../services/api';
import { BulkProductAPI } from '../../../../services/api/definitions/products/types';
import { RootState } from '../../../store';
import { addProductSlice, updateProductSlice } from './productsSlice';

export const getProductThunk = createAsyncThunk(
  'product/get',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.definitions.products.view(id);
      const result = response.data.data;
      dispatch(addProductSlice(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getProductListThunk = createAsyncThunk(
  'product/list',
  async (
    params: {
      category_id: string;
      is_parent?: boolean;
      is_pos?: boolean;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.definitions.products.list(
        params.category_id,
        params.is_parent,
        params.is_pos,
      );
      const result = response.data.data;
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getAllProductListThunk = createAsyncThunk(
  'product/allList',
  async (
    params: {
      name: string | '';
      category_id: string[] | null;
      is_bulk_update: boolean | null;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.definitions.products.listAll(
        params.name,
        params.category_id,
        params.is_bulk_update,
      );
      const result = response.data.data;
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateBulkProductsThunk = createAsyncThunk(
  'product/bulk-update',
  async (params: BulkProductAPI, { rejectWithValue }) => {
    try {
      const response = await api.definitions.products.bulkProductsUpdate(
        params,
      );
      const result = response.data.data;
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

// property groups thunks
export const updateProductPropertyGroupsThunk = createAsyncThunk(
  'product/property/group/update',
  async (
    { productId, groupIds }: { productId: string; groupIds: string[] },
    { rejectWithValue, getState, dispatch },
  ) => {
    try {
      const state = getState() as RootState;
      const existingGroups =
        state.definitionsProducts.ProductInProcess?.property_groups;
      let groupsToSet: string[] = [];
      let groupsToUnset: string[] = [];

      // if existing empty set all incoming
      if (!existingGroups?.length && groupIds.length) {
        groupsToSet = groupIds;
      }
      // if existing not empty and incoming is empty unset all existing
      if (existingGroups?.length && !groupIds.length) {
        groupsToUnset = existingGroups.map(eg => eg.id);
      }

      // if existing and incoming not empty do diff
      if (existingGroups?.length && groupIds.length) {
        // Find groups that don't exist already
        groupsToSet = groupIds.filter(
          id => !existingGroups.find(eg => eg.id === id),
        );

        // Find groups that exists but removed
        groupsToUnset = existingGroups
          .filter(eg => !groupIds.includes(eg.id))
          .map(eg => eg.id);
      }

      const requests = [
        ...groupsToSet.map(
          groupId => () =>
            api.definitions.products.setPropertyGroup(productId, groupId),
        ),
        ...groupsToUnset.map(
          groupId => () =>
            api.definitions.products.unsetPropertyGroup(productId, groupId),
        ),
      ];

      await Promise.all(requests.map(func => func()));

      const response = await api.definitions.products.view(productId);
      const result = response.data.data;
      dispatch(updateProductSlice(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

// Ingredient groups thunks
export const updateProductIngredientGroupsThunk = createAsyncThunk(
  'product/ingredient/group/update',
  async (
    { productId, groupIds }: { productId: string; groupIds: string[] },
    { rejectWithValue, getState, dispatch },
  ) => {
    try {
      const state = getState() as RootState;
      const existingGroups =
        state.definitionsProducts.ProductInProcess?.ingredient_groups;
      let groupsToSet: string[] = [];
      let groupsToUnset: string[] = [];

      // if existing empty set all incoming
      if (!existingGroups?.length && groupIds.length) {
        groupsToSet = groupIds;
      }
      // if existing not empty and incoming is empty unset all existing
      if (existingGroups?.length && !groupIds.length) {
        groupsToUnset = existingGroups.map(eg => eg.id);
      }

      // if existing and incoming not empty do diff
      if (existingGroups?.length && groupIds.length) {
        // Find groups that don't exist already
        groupsToSet = groupIds.filter(
          id => !existingGroups.find(eg => eg.id === id),
        );

        // Find groups that exists but removed
        groupsToUnset = existingGroups
          .filter(eg => !groupIds.includes(eg.id))
          .map(eg => eg.id);
      }

      const requests = [
        ...groupsToSet.map(
          groupId => () =>
            api.definitions.products.setIngredientGroup(productId, groupId),
        ),
        ...groupsToUnset.map(
          groupId => () =>
            api.definitions.products.unsetIngredientGroup(productId, groupId),
        ),
      ];
      await Promise.all(requests.map(func => func()));

      const response = await api.definitions.products.view(productId);
      const result = response.data.data;
      dispatch(updateProductSlice(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
