/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

/** Styles */
import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography,
} from '@mui/material';

/** Components */
import UtilitiesBar from '../../components/UtilitiesBar';
import FormSelect from '../../components/FormSelect';
import Button from '../../components/Button';
import useSettings from '../../hooks/useSettings';
import { QrMenuTemplates } from '../../store/slices/settingsSlice/restaurantSettings/types';
import EmptyState from '../../components/EmptyState';
import FormInput from '../../components/FormInput';
import FormNumberInput from '../../components/FormNumberInput';
import keyHasPermission from '../../helpers/keyHasPermission';

function ServiceChargeSettings() {
  const { t } = useTranslation('serviceChargeSettings');

  const { meProfile, serviceCharge, getServiceCharge, updateServiceCharge } =
    useSettings();

  const [serviceChargeName, setServiceChargeName] = useState(t('defaultName'));
  const [serviceChargePrice, setServiceChargePrice] = useState('');
  const [serviceChargeType, setServiceChargeType] = useState<
    'amount' | 'percent'
  >('amount');
  const [selected, setSelected] = useState(true);
  useState<QrMenuTemplates | null>(null);

  const hasViewServiceCharge = keyHasPermission(
    'view-service-charge',
    meProfile?.permissions || [],
  );

  const hasUpdateServiceCharge = keyHasPermission(
    'update-service-charge',
    meProfile?.permissions || [],
  );

  const handleUpdate = async () => {
    await updateServiceCharge({
      name: serviceChargeName,
      is_automatic: selected,
      amount: Number(serviceChargePrice) * 100,
      type: serviceChargeType,
    });
  };

  useEffect(() => {
    if (serviceCharge) {
      setServiceChargeName(serviceCharge?.name);
      setServiceChargePrice(
        (Number(serviceCharge?.amount.amount) / 100).toString(),
      );
      setSelected(serviceCharge?.is_automatic);
    }
    // @ts-ignore
    setServiceChargeType(serviceCharge?.type || 'percent');
  }, [serviceCharge]);

  useEffect(() => {
    getServiceCharge();
  }, []);

  if (!serviceCharge) {
    return <EmptyState message={t('empty')} />;
  }

  if (!hasViewServiceCharge || !hasUpdateServiceCharge) {
    return <EmptyState message={t('none_permission')} />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('title')}
        leftIconName="QrCode"
      />
      <Box>
        <Paper sx={{ p: 3, flex: 1, height: '100%' }} elevation={3}>
          <Typography variant="h5" mb={3}>
            {t('label')}
          </Typography>
          <Grid container>
            <Grid item xs={12} mb={1}>
              <FormInput
                label={t('service_charge_name')}
                value={serviceChargeName}
                onChange={e => {
                  setServiceChargeName(e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} mb={1}>
              <FormSelect
                label={t('service_charge_type')}
                value={serviceChargeType}
                onChange={e => {
                  // @ts-ignore
                  setServiceChargeType(e?.target?.value);
                }}
                underline
                fullWidth
                options={[
                  {
                    id: 'amount',
                    text: t('amount'),
                  },
                  {
                    id: 'percent',
                    text: t('percent'),
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} mb={1}>
              <FormNumberInput
                label={t('service_charge_price')}
                value={serviceChargePrice}
                onChange={e => {
                  if (Number(e.target.value) < 0) {
                    setServiceChargePrice(
                      (Number(e.target.value) * -1).toString(),
                    );
                  } else {
                    setServiceChargePrice(e.target.value);
                  }
                }}
                maskProps={
                  serviceChargeType === 'amount'
                    ? {
                        decimalScale: 2,
                        fixedDecimalScale: true,
                        suffix: ` ${meProfile?.currency?.code || 'TRY'}`,
                      }
                    : {
                        fixedDecimalScale: false,
                        suffix: `%`,
                      }
                }
                fullWidth
              />
            </Grid>
          </Grid>
          <FormControlLabel
            sx={{ mb: 2 }}
            control={
              <Switch
                color="success"
                checked={selected}
                onChange={() => setSelected(!selected)}
              />
            }
            label={t('auto_select')}
          />
          <br />
          <Grid container justifyContent="left">
            <Button onClick={handleUpdate} variant="contained" size="large">
              {t('actions.update')}
            </Button>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
}

export default ServiceChargeSettings;
