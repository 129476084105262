import { SxProps, Theme } from '@mui/material';

const HEIGHT = 500;
const styles: Record<string, SxProps<Theme>> = {
  container: {
    py: 5,
  },
  paper: {
    minHeight: HEIGHT,
    my: 4,
    px: 4,
    py: 3,
  },
  vStack: {
    minHeight: HEIGHT,
    pt: 3,
  },
  fieldsContainer: {
    py: 2,
  },
};

export default styles;
