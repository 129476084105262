/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

/** Styles */
import { Paper, Stack } from '@mui/material';

/** Components */
import UtilitiesBar, {
  Props as BarProps,
} from '../../../components/UtilitiesBar';
import PermissionList from './PermissionList';
import useRoles from '../../../hooks/useRoles';

const MENU: BarProps['menu'] = [
  { id: 0, text: 'role.details.back', startIconName: 'Back' },
];

function PermissionDetailsWizard() {
  const { t } = useTranslation('role');
  const navigate = useNavigate();
  const { role } = useRoles();

  return (
    <div>
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={`${role.name} ${t('title')}`}
        leftIconName="SupervisorAccount"
        menu={MENU!.map(item => ({ ...item, text: t(item.text as any) }))}
        onActionClick={() => navigate('/user-operations/role-list')}
      />
      <Stack>
        <Paper sx={{ p: 2 }}>
          <PermissionList />
        </Paper>
      </Stack>
    </div>
  );
}

export default PermissionDetailsWizard;
