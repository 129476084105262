/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import {
  Box,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table,
  Switch,
} from '@mui/material';
import TableCell from '@mui/material/TableCell';

/** Components */
import Button from '../../../../components/Button';
import LoadingState from '../../../../components/LoadingState';

/** Types */
import { PaymentType } from './types';

/** Helpers */
import getActiveLanguage from '../../../../helpers/getActiveLanguage';

/** Hooks */
import useSettings from '../../../../hooks/useSettings';
import EmptyState from '../../../../components/EmptyState';
import keyHasPermission from '../../../../helpers/keyHasPermission';

function PaymentTypesForm() {
  const { t } = useTranslation('restaurantSettings');
  const {
    updatePaymentTypesSettings,
    meProfile,
    branchSettings,
    branchSettingsLoading,
    fetchGeneralSettings,
  } = useSettings();

  const [updatePaymentTypes, setUpdatePaymentTypes] = useState<PaymentType[]>(
    [],
  );
  const [loading, setLoading] = useState(false);

  useEffect(
    () =>
      // @ts-ignore
      setUpdatePaymentTypes(branchSettings?.payment_types || []),
    [branchSettings],
  );

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const updatePaymentTypesForBE = updatePaymentTypes.map(item => ({
        id: item.id,
        active: item.is_active,
      }));

      await updatePaymentTypesSettings({
        paymentTypes: updatePaymentTypesForBE,
        active_branch_id: meProfile?.activeBranch?.id || '',
      });

      await fetchGeneralSettings();

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (id: string) => {
    setUpdatePaymentTypes(
      updatePaymentTypes.map(updatedItem =>
        updatedItem.id === id
          ? { ...updatedItem, is_active: !updatedItem.is_active }
          : updatedItem,
      ),
    );
  };

  if (branchSettingsLoading || loading) {
    return <LoadingState />;
  }

  const hasViewPaymentTypes = keyHasPermission(
    'view-payment-types',
    meProfile?.permissions || [],
  );

  const hasUpdatePaymentTypes = keyHasPermission(
    'update-payment-types',
    meProfile?.permissions || [],
  );

  if (!hasViewPaymentTypes || updatePaymentTypes.length === 0) {
    return (
      <EmptyState
        message={
          !hasViewPaymentTypes
            ? t('payment_type.no_authority')
            : t('payment_type.no_payment')
        }
      />
    );
  }

  return (
    <Box>
      <Box sx={{ mt: 1 }}>
        <TableContainer>
          <Table sx={{ '& td': { border: 0 } }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ cursor: 'pointer' }}>
              {updatePaymentTypes.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {getActiveLanguage(row.translations).name}
                  </TableCell>
                  <TableCell
                    align="right"
                    onClick={() =>
                      hasUpdatePaymentTypes ? handleClick(row.id) : () => {}
                    }
                    component="th"
                    scope="row"
                  >
                    <Switch
                      checked={row.is_active}
                      disabled={branchSettingsLoading || !hasUpdatePaymentTypes}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <br />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
        <Button
          size="large"
          onClick={() => (hasUpdatePaymentTypes ? handleUpdate() : () => {})}
          disabled={!hasUpdatePaymentTypes}
        >
          {t('payment_type.update')}
        </Button>
      </Box>
    </Box>
  );
}

export default PaymentTypesForm;
