import { Chip, CircularProgress, Grid } from '@mui/material';
import React from 'react';
import getActiveLanguage from '../../../../../helpers/getActiveLanguage';
import { IngredientItemWithActive } from '../../../../../services/api/definitions/ingredientGroups/types';

interface Props {
  list: IngredientItemWithActive[];
  loadingItemID: string | null;
  onItemClick: (item: IngredientItemWithActive) => void;
}
function IngredientItemList({ list, loadingItemID, onItemClick }: Props) {
  return (
    <Grid container spacing={2}>
      {list.map(item => (
        <Grid item key={item.id}>
          <Chip
            icon={
              item.id === loadingItemID ? (
                <CircularProgress size={14} />
              ) : undefined
            }
            label={getActiveLanguage(item.translations).name}
            onClick={() => onItemClick(item)}
            color={item.is_active ? 'info' : 'default'}
            variant={item.is_active ? 'filled' : 'outlined'}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default IngredientItemList;
