import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type ProductPropertyItemFormValues = {
  price: number;
  isActive: boolean;
};

const schema = yup.object({
  price: yup
    .number()
    .typeError('properties.editModal.form.price.shouldBeNumber')
    .required('properties.editModal.form.price.required'),
  isActive: yup.boolean().required('properties.editModal.form.active.required'),
});

export const validationSchema = yupResolver(schema);
