import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../services/api';
import {
  ChangePassword,
  UpdateProfile,
} from '../../../services/api/profile/types';

export const updateProfileThunk = createAsyncThunk(
  'profile/update',
  async (params: UpdateProfile, { rejectWithValue }) => {
    try {
      return await api.profile.updateProfile(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const changePasswordThunk = createAsyncThunk(
  'profile/changePassword',
  async (params: ChangePassword, { rejectWithValue }) => {
    try {
      return await api.profile.changePassword(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const setLanguageThunk = createAsyncThunk(
  'profile/update',
  async (language: string, { rejectWithValue }) => {
    try {
      const result = await api.profile.setLanguage(language);
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
