/** Dependencies */
import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

/** Styles */
import styles from '../../styles';

/** Helpers */
import getActiveLanguage from '../../../../helpers/getActiveLanguage';

/** Models */
import { NameTranslation } from '../../../../models/General';

interface Props {
  item: any;
}

function NonMenuItemContent({ item }: Props) {
  const { t } = useTranslation('kitchen');

  return (
    <>
      {item.property_group_items.length ? (
        <Grid
          container
          justifyContent="flex-start"
          sx={styles.kitchenTypography}
        >
          <Grid sx={{ color: 'green', mr: 1 }}>{t('order.properties')}:</Grid>
          <Grid sx={{ color: 'black' }}>
            {' '}
            {item.property_group_items.map(
              (
                propItem: {
                  translations: NameTranslation[];
                },
                index: number,
                row: any[],
              ) =>
                index + 1 === row.length
                  ? ` ${getActiveLanguage(propItem.translations).name}`
                  : ` ${getActiveLanguage(propItem.translations).name},`,
            )}
          </Grid>
        </Grid>
      ) : (
        ''
      )}
      {item.ingredient_group_items.length ? (
        <Grid
          container
          justifyContent="flex-start"
          sx={styles.kitchenTypography}
        >
          <Grid sx={{ color: 'red', mr: 1 }}>{t('order.extracted')} :</Grid>
          <Grid sx={{ color: 'black' }}>
            {' '}
            {item.ingredient_group_items.map(
              (
                ingItem: {
                  translations: NameTranslation[];
                },
                index: number,
                row: any[],
              ) =>
                index + 1 === row.length
                  ? ` ${getActiveLanguage(ingItem.translations).name}`
                  : ` ${getActiveLanguage(ingItem.translations).name},`,
            )}
          </Grid>
        </Grid>
      ) : (
        ''
      )}
    </>
  );
}

export default NonMenuItemContent;
