/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

/** Components */
import EmptyState from '../../../components/EmptyState';

/** Hooks */
import useBranches from '../../../hooks/useBranches';
import useProfile from '../../../hooks/useProfile';
import keyHasPermission from '../../../helpers/keyHasPermission';

function CategorySalesTable() {
  const { t } = useTranslation('categorySales');
  const { meProfile } = useProfile();

  const { salesByCategories } = useBranches();

  const hasViewPermission = keyHasPermission(
    'view-daily-report',
    meProfile?.permissions || [],
  );
  const renderEmpty = () => (
    <EmptyState
      message={
        !hasViewPermission
          ? 'Bu sayfayı görmeye yetkiniz yok!'
          : t('reports.empty')
      }
    />
  );

  return (
    <Paper sx={{ width: '100%', p: 2 }}>
      {!hasViewPermission || !salesByCategories ? (
        renderEmpty()
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {t('reports.reportsTable.category')}
                </TableCell>
                <TableCell align="center">
                  {t('reports.reportsTable.quantity')}
                </TableCell>
                <TableCell align="center">
                  {t('reports.reportsTable.amount')} (
                  {meProfile?.currency?.symbol})
                </TableCell>
                <TableCell align="center">
                  {t('reports.reportsTable.rate')} (%)
                </TableCell>
              </TableRow>
            </TableHead>
            {salesByCategories?.categories.map(row => (
              <TableBody>
                <TableRow key={row.category.id}>
                  <TableCell align="center">{row.category.name}</TableCell>
                  <TableCell align="center">
                    {row.total_sales_quantity}
                  </TableCell>
                  <TableCell align="center">
                    {row.total_sales_amount.formatted}
                  </TableCell>
                  <TableCell align="center">{row.rate}</TableCell>
                </TableRow>
              </TableBody>
            ))}
            <TableBody>
              <TableRow style={{ background: '#f0f0f0' }}>
                <TableCell style={{ fontWeight: '700' }} align="center">
                  {t('reports.reportsTable.total')}
                </TableCell>
                <TableCell style={{ fontWeight: '700' }} align="center">
                  {salesByCategories?.total_quantity}
                </TableCell>
                <TableCell style={{ fontWeight: '700' }} align="center">
                  {salesByCategories?.total_amount.formatted}
                </TableCell>
                <TableCell style={{ fontWeight: '700' }} align="center">
                  {salesByCategories?.rate}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}

export default CategorySalesTable;
