import { Divider, List } from '@mui/material';
import React from 'react';

import OrderedListItem from './OrderedListItem';

type ItemBase = {
  id: string;
  name: string;
  sales_channel?: { id: string; name: string };
};

interface Props<Item extends ItemBase> {
  items: Item[];
  onItemEdit?: (item: Item) => void;
  onItemRemove?: (item: Item) => void;
  onItemUp?: (item: Item) => void;
  onItemDown?: (item: Item) => void;
  maxWidth?: number;
  loading?: boolean;
  sortButtons?: boolean;
  dense?: boolean;
  removeButtons?: boolean;
  nonDisabledRemove?: boolean;
  editButtons?: boolean;
}
function OrderedList<Item extends ItemBase>({
  items = [],
  onItemEdit,
  onItemRemove,
  onItemUp,
  onItemDown,
  maxWidth,
  loading,
  nonDisabledRemove,
  sortButtons = true,
  removeButtons = true,
  editButtons = true,
  dense = true,
}: Props<Item>) {
  return (
    <List
      sx={{ width: '100%', bgcolor: 'background.paper', maxWidth }}
      dense={dense}
    >
      {items.map((item, index) => (
        <div key={item.id}>
          <OrderedListItem
            text={item.name}
            channel={item?.sales_channel?.name}
            loading={loading}
            disableUp={index === 0}
            disableDown={index === items.length - 1}
            disableRemove={!nonDisabledRemove && items.length < 2}
            onEdit={() => onItemEdit && onItemEdit(item)}
            onRemove={() => onItemRemove && onItemRemove(item)}
            onUp={() => onItemUp && onItemUp(item)}
            onDown={() => onItemDown && onItemDown(item)}
            sortButtons={sortButtons}
            removeButtons={removeButtons}
            editButtons={editButtons}
          />
          {index !== items.length - 1 && <Divider variant="middle" />}
        </div>
      ))}
    </List>
  );
}

export default OrderedList;
