const styles = {
  container: {},
};

export const borderRadiusMixin = (radius: number) => ({
  borderRadius: radius,
  '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
    borderRadius: radius,
  },
});

export default styles;
