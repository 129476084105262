/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

/** Styles */
import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography,
} from '@mui/material';

/** Components */
import UtilitiesBar from '../../components/UtilitiesBar';
import FormSelect from '../../components/FormSelect';
import Button from '../../components/Button';
import useSettings from '../../hooks/useSettings';
import { QrMenuTemplates } from '../../store/slices/settingsSlice/restaurantSettings/types';
import EmptyState from '../../components/EmptyState';
import FormInput from '../../components/FormInput';
import FormNumberInput from '../../components/FormNumberInput';
import keyHasPermission from '../../helpers/keyHasPermission';

function CouvertSettings() {
  const { t } = useTranslation('couvertSettings');
  const { getCouvert, couvert, updateCouvert, meProfile } = useSettings();

  const [couvertName, setCouvertName] = useState(t('default_name'));
  const [couvertPrice, setCouvertPrice] = useState('');
  const [selected, setSelected] = useState(true);
  useState<QrMenuTemplates | null>(null);

  const hasViewCouvert = keyHasPermission(
    'view-couvert',
    meProfile?.permissions || [],
  );

  const hasUpdateCouvert = keyHasPermission(
    'update-couvert',
    meProfile?.permissions || [],
  );

  const handleUpdate = async () => {
    await updateCouvert({
      amount: Number(couvertPrice) * 100,
      name: couvertName,
      is_automatic: selected,
      type: 'amount',
    });
  };

  useEffect(() => {
    if (couvert) {
      setCouvertName(couvert?.name);
      setCouvertPrice((Number(couvert?.amount.amount) / 100).toString());
      setSelected(couvert?.is_automatic);
    }
  }, [couvert]);

  useEffect(() => {
    getCouvert();
  }, []);

  if (!couvert) {
    return <EmptyState message={t('empty')} />;
  }

  if (!hasViewCouvert || !hasUpdateCouvert) {
    return <EmptyState message={t('none_permission')} />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('title')}
        leftIconName="QrCode"
      />
      <Box>
        <Paper sx={{ p: 3, flex: 1, height: '100%' }} elevation={3}>
          <Typography variant="h5" mb={3}>
            {t('label')}
          </Typography>
          <Grid container>
            <Grid item xs={12} mb={1}>
              <FormInput
                label={t('couvert_name')}
                value={couvertName}
                onChange={e => {
                  setCouvertName(e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} mb={1}>
              <FormSelect
                label={t('couvert_type')}
                value="amount"
                onChange={event => {}}
                underline
                fullWidth
                disabled
                options={[
                  {
                    id: 'amount',
                    text: t('amount'),
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} mb={1}>
              <FormNumberInput
                label={t('couvert_price')}
                value={couvertPrice}
                onChange={e => {
                  if (Number(e.target.value) < 0) {
                    setCouvertPrice((Number(e.target.value) * -1).toString());
                  } else {
                    setCouvertPrice(e.target.value);
                  }
                }}
                maskProps={{
                  decimalScale: 2,
                  fixedDecimalScale: true,
                  suffix: ` ${meProfile?.currency?.code || 'TRY'}`,
                }}
                placeholder={`0,00 ${meProfile?.currency?.code || 'TRY'}`}
                fullWidth
              />
            </Grid>
          </Grid>
          <FormControlLabel
            sx={{ mb: 2 }}
            control={
              <Switch
                color="success"
                checked={selected}
                onChange={() => setSelected(!selected)}
              />
            }
            label={t('auto_select')}
          />
          <br />
          <Grid container justifyContent="left">
            <Button onClick={handleUpdate} variant="contained" size="large">
              {t('actions.update')}
            </Button>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
}

export default CouvertSettings;
