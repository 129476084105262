import React, { useEffect, useState } from 'react';

interface LazyImageProps {
  src: string;
  width?: string;
}

function LazyImage({ src, width }: LazyImageProps) {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    const image = new Image();
    image.src = src;

    image.onload = () => {
      setImageSrc(src);
    };
  }, [src]);

  return <img src={imageSrc || ''} alt="" loading="lazy" width={width} />;
}

export default LazyImage;
