import { DrawerProps } from '@mui/material/Drawer';
import React from 'react';
import { Outlet } from 'react-router-dom';
import Layout from '../../components/Layout';
import AuthRequired from '../../Navigation/AuthRequired';

interface Props {
  variant: DrawerProps['variant'];
}
function Root({ variant }: Props) {
  return (
    <AuthRequired>
      <Layout variant={variant}>
        <Outlet />
      </Layout>
    </AuthRequired>
  );
}

export default Root;
