/** Dependencies */
import React, { useEffect, useState } from 'react';

/** Styles */
import { Grid } from '@mui/material';

/** Components */
import GeneralSettingsForm from './GeneralSettingsForm';
import CustomerDisplayDesignForm from './CustomerDisplayDesignForm';
import { FormDataType } from './types';
import useSettings from '../../hooks/useSettings';

function Form() {
  const { displayScreenData, fetchDisplayScreen, fetchGeneralSettings } =
    useSettings();
  const [formData, setFormData] = useState<FormDataType>({
    is_shown_logo: false,
    name: '',
    caption: '',
    font_size: 14,
    product_font_size: 14,
    theme_color: '#232323',
    text_color: '#662326',
    is_active: true,
    working_types: [],
  });

  useEffect(() => {
    fetchDisplayScreen();
  }, []);

  useEffect(() => {
    if (displayScreenData) {
      setFormData({
        is_shown_logo: displayScreenData?.is_shown_logo || false,
        name: displayScreenData?.name || '',
        caption: displayScreenData?.caption || '',
        font_size: displayScreenData?.font_size || 14,
        product_font_size: displayScreenData?.product_font_size || 14,
        theme_color: displayScreenData?.theme_color || '',
        text_color: displayScreenData?.text_color || '',
        is_active: displayScreenData?.is_active || false,
        working_types: displayScreenData.working_types.map(item => item.id),
      });
    }
  }, [displayScreenData]);

  return (
    <Grid container>
      <Grid
        item
        md={12}
        lg={5}
        sx={{
          py: 2,
          px: 5,
          backgroundColor: 'white',
          height: { md: '90vh', lg: '84vh' },
        }}
      >
        <GeneralSettingsForm
          displayFormData={formData}
          setFormData={setFormData}
        />
      </Grid>
      <Grid item md={12} lg={7}>
        <CustomerDisplayDesignForm formData={formData} screenType="settings" />
      </Grid>
    </Grid>
  );
}

export default Form;
