import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import api from '../../services/api';
import { CallerIdBase } from '../../services/api/definitions/callerIdRecords/types';
import { isApiError } from '../../services/api/types';

const useCallerId = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');

  const [records, setRecords] = useState<CallerIdBase[]>([]);
  const [meta, setMeta] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const fetchCallerIdRecords = async (params: {
    search: string;
    page: number;
    // phone_number?: string | null,
    // line_no?: string | null,
    // device_no?: string | null,
    // created_at?: string | null,
    // called_at?: string | null,
    per_page?: number | null;
  }) => {
    try {
      setLoading(true);
      const response = await api.definitions.callerIdRecords.list(
        params.search,
        params.page,
        // params.phone_number,
        // params.created_at,
        // params.called_at,
        params.per_page,
      );

      const result = response.data.data;

      setRecords(result);
      setMeta(response.data.meta);
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      setRecords([]);
      setMeta(null);
    } finally {
      setLoading(false);
    }
  };

  return {
    records,
    meta,
    loading,
    fetchCallerIdRecords,
  };
};
export default useCallerId;
