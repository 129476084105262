import { Box, Button, ButtonProps, Grid, Typography } from '@mui/material';
import React from 'react';
import Icon, { IconName } from '../../../../components/Icon';

interface Props extends ButtonProps {
  iconName: IconName;
  text: string;
  selected?: boolean;
}
function MenuButton({ iconName, text, selected, ...rest }: Props) {
  return (
    <Button fullWidth color="success" sx={{ borderRadius: 0, mb: 1 }} {...rest}>
      <Box>
        <Icon
          name={iconName}
          fontSize="medium"
          sx={{ color: selected ? '#F1C40F' : 'white' }}
        />
        <Grid container justifyContent="space-between">
          <Typography
            variant="subtitle2"
            sx={{
              color: selected ? '#F1C40F' : 'white',
              fontSize: '0.6rem',
              width: '60px',
              alignItems: 'center',
              textAlign: 'center',
              justifyContent: 'center',
              display: 'flex',
              whiteSpace: 'pre-line',
            }}
          >
            <Grid item>{text}</Grid>
          </Typography>
        </Grid>
      </Box>
    </Button>
  );
}

export default MenuButton;
