import { IdAndName, NameTranslation } from '../../../models/General';

export interface LoginResponse {
  accessToken: string;
  full_Name: string;
  registrationStep: number;
}
export interface RegisterFirstStepResponse {
  accessToken: string;
}

export interface RegisterSecondStepResponse {
  registrationStep: number;
}
export interface RegisterSecondStepParams {
  name: string;
  paymentTypes: { id: string; active: boolean }[];
}

export interface RegisterThirdStepResponse {
  registrationStep: number;
}
export interface RegisterThirdStepParams {
  startTime: string;
  currencyId: string;
  workingTypeIds: string[];
  businessTypeId: string;
  timezone: string;
}
export interface LogoutResponse {
  logoutAt: string;
}

export interface Currency {
  code: string;
  id: number;
  name: string;
  symbol: string;
}

export interface MeResponse {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  language: string;
  lastLoginAt: string | null;
  lastLogoutAt: string | null;
  company: IdAndName | null;
  activeBranch: Branch | null;
  activeRole: Role | null;
  permissions: Permission[] | null;
  paymentTypes: PaymentType[] | null;
  pin: number;
  currency?: Currency;
  workingTypes?: [{ id: number; name: string }] | null;
  branches?: {
    id: string;
    name: string;
    is_active: boolean;
  }[];
  installed_apps: {
    id: string;
    name: string;
    slug: string;
    is_shown_on_sidebar: boolean;
    icon: any;
    redirect_url: string;
    sequence: number;
    logo: string;
  }[];
  is_caller_id_active: boolean;
}

export type Role = {
  id: string;
  name: 'owner' | 'manager' | 'user';
};

export type Branch = {
  id: string;
  name: string;
  day_id?: string;
  day: {
    id: string;
    start_date: string;
    end_date?: string;
  };
  billing_type: string;
  active_until: string;
  start_time: string;
};

export type Permission = {
  id: string;
  name: PermissionTypes;
  slug: string;
};

type PaymentType = {
  id: number;
  is_default: boolean;
  is_active: boolean;
  translations: NameTranslation[];
  logo_url: string;
  name: string;
};

export interface ResetValues {
  token: string;
  password: string;
  password_confirmation: string;
}

export type PermissionTypes =
  | 'MANAGE_GENERAL_SETTINGS_DEFINITIONS'
  | 'MANAGE_COMPANY_OPERATIONS'
  | 'MANAGE_BRANCH_OPERATIONS'
  | 'MANAGE_SECTION_AND_TABLE_OPERATIONS'
  | 'MANAGE_CATEGORY_AND_PRODUCT_OPERATIONS'
  | 'MANAGE_USER_OPERATIONS'
  | 'MANAGE_AUTHORIZATION_OPERATIONS'
  | 'MANAGE_STOCK_OPERATIONS'
  | 'VIEW_STOCK_QUANTITIES'
  | 'MANAGE_PAYMENT_OPERATIONS'
  | 'ORDER_OPERATIONS'
  | 'REMOVE_PRODUCT_FROM_ORDER'
  | 'APPLY_GENERAL_DISCOUNT_AT_CHECKOUT'
  | 'GIVE_ANY_PRODUCT_IN_THE_ORDER_AS_A_GIFT'
  | 'GET_ORDER_PAYMENT'
  | 'CANCEL_ORDER'
  | 'TAKE_PACKAGE_ORDER'
  | 'MAKE_DIRECT_ORDER'
  | 'MOVE_ANY_PRODUCT_FROM_ORDER'
  | 'VIEW_CLOSED_OR_CANCELED_ORDER'
  | 'TRANSFER_TOTAL_AMOUNT_TO_OPEN_ACCOUNT'
  | 'CHANGE_ORDER_BRANCH'
  | 'CHANGE_UNIT_PRICE_IN_ORDER'
  | 'MANAGE_KITCHEN_OPERATIONS'
  | 'MANAGE_DELIVERY_OPERATIONS'
  | 'VIEW_REPORTS'
  | 'MANAGE_END_OF_DAY_OPERATIONS'
  | 'MANAGE_EXPENSE_OPERATIONS';

export const LAST_REGISTRATION_STEP = 4;
