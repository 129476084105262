/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';
import currency from 'currency.js';
import { format } from 'date-fns';

/** Styles */
import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FormDataType } from './types';
import useProfile from '../../hooks/useProfile';
import { DisplayProduct } from '../../models/Product';
import getActiveLanguage from '../../helpers/getActiveLanguage';
import {
  priceFormatter,
  singlePriceFormatter,
} from '../../helpers/moneyFormatter';
import logoImage from '../../assets/logo.png';
import styles from '../../apps/Pos/Sections/OrderModal/LeftColumn/Footer/styles';
import Button from '../../components/Button';

interface Props {
  formData: FormDataType;
  list?: DisplayProduct;
  screenType: string;
}

function GeneralSettingsForm({ formData, list, screenType }: Props) {
  const { t } = useTranslation('pos');
  const { meProfile } = useProfile();

  const currencyCode = meProfile?.currency?.code || 'TRY';

  return (
    <Box>
      <Box>
        <Grid
          sx={{
            height: screenType === 'settings' ? '3vh' : '5vh',
            backgroundColor: 'white',
            mb: 1,
          }}
          container
          justifyContent="space-between"
          p={2}
        >
          <Grid sx={{ fontSize: formData.product_font_size }} item xs={4}>
            {list?.tableName || 'Masa 7'}
          </Grid>
          <Grid
            item
            xs={4}
            color="gray"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            {format(
              new Date(list?.created_at || Date.now()),
              'dd.MM.yyyy HH:mm',
            ) || '20.10.2020 18:23'}
          </Grid>
          <Grid
            item
            xs={4}
            justifyContent="end"
            alignItems="end"
            textAlign="end"
          >
            <img src={logoImage} alt="site-logo" height="28" width="140" />
          </Grid>
        </Grid>
        <Grid
          sx={{
            height: screenType === 'settings' ? '57vh' : '70vh',
          }}
        >
          <TableContainer
            sx={{
              maxHeight:
                screenType === 'settings'
                  ? '57vh'
                  : {
                      xs: '50vh',
                      sm: '55vh',
                      md: '58vh',
                      lg: '63vh',
                      xl: '65vh',
                    },
              width: '100%',
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: 6,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'white',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'gray',
                borderRadius: 2,
              },
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  maxHeight:
                    screenType === 'settings'
                      ? '57vh'
                      : {
                          xs: '40vh',
                          sm: '45vh',
                          md: '50vh',
                          lg: '63vh',
                          xl: '65vh',
                        },
                }}
              >
                {list?.tableName ? (
                  list?.orders?.map(item => (
                    <TableRow>
                      <TableCell sx={{ fontSize: formData.product_font_size }}>
                        {`${item.product_name ? item.quantity : item.count} x ${
                          item.has_special_product
                            ? item.items[0].product_name
                            : item.product_name
                            ? item.product_name
                            : item.product
                            ? getActiveLanguage(item?.product?.translations)
                                ?.name
                            : getActiveLanguage(item?.translations)?.name || ''
                        }`}
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontSize: { xs: 12, sm: 11, lg: 14 },
                            color: 'gray',
                          }}
                        >
                          {item.active_unit
                            ? getActiveLanguage(item?.active_unit?.translations)
                                ?.name
                            : item.has_special_product || item.product_name
                            ? ''
                            : getActiveLanguage(
                                // @ts-ignore
                                item?.units?.find(u => u?.is_default)
                                  ?.translations,
                              )?.name}
                        </Typography>
                      </TableCell>
                      <TableCell />
                      <TableCell align="right">
                        <Typography
                          sx={{
                            fontSize: formData.product_font_size,
                            textDecoration: item.is_treat
                              ? 'line-through'
                              : 'none',
                          }}
                        >
                          {item.total_amount
                            ? item.total_amount.formatted
                            : item.total_price
                            ? singlePriceFormatter(
                                (item.total_price * 100).toString(),
                                item.count,
                                currencyCode,
                              )
                            : item.price
                            ? singlePriceFormatter(
                                item.price.toString(),
                                item.quantity || 1,
                                currencyCode,
                              )
                            : item.active_unit
                            ? singlePriceFormatter(
                                item?.active_unit?.amount?.amount,
                                item.count,
                                currencyCode,
                              )
                            : item.has_special_product
                            ? item.items[0].additional_price.formatted
                            : singlePriceFormatter(
                                item?.units?.find(u => u.is_default)?.amount
                                  ?.amount,
                                item.count,
                                currencyCode,
                              )}
                        </Typography>
                        {item.is_treat && (
                          <Grid>
                            <Button
                              variant="contained"
                              sx={{
                                borderRadius: '20px',
                                width: '50px',
                                height: '24px',
                                fontSize: '10px',
                                p: 0,
                                minWidth: '10px',
                              }}
                              color="error"
                              size="small"
                            >
                              {t('orderModal.treat')}
                            </Button>
                          </Grid>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <>
                    <TableRow>
                      <TableCell sx={{ fontSize: formData.product_font_size }}>
                        1 x Çay
                      </TableCell>
                      <TableCell />
                      <TableCell align="right">
                        <Typography
                          sx={{
                            fontSize: formData.product_font_size,
                          }}
                        >
                          ₺15,00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontSize: formData.product_font_size }}>
                        2 x Türk Kahvesi
                      </TableCell>
                      <TableCell />
                      <TableCell align="right">
                        <Typography
                          sx={{
                            fontSize: formData.product_font_size,
                          }}
                        >
                          ₺90,00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontSize: formData.product_font_size }}>
                        1 x Tiramisu
                      </TableCell>
                      <TableCell />
                      <TableCell align="right">
                        <Typography
                          sx={{
                            fontSize: formData.product_font_size,
                          }}
                        >
                          ₺75,00
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
      <Box
        sx={{
          height: '20vh',
          backgroundColor: 'white',
          width: '100%',
          fontSize: formData.product_font_size,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            width: '100%',
            borderTop: '1px solid gray',
            p: 2,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <Typography
                variant="subtitle2"
                sx={{
                  ...styles.typography,
                  fontSize: formData.product_font_size,
                }}
              >
                {t('orderModal.basket.discount')}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  ...styles.typography,
                  fontSize: formData.product_font_size,
                }}
              >
                {t('orderModal.basket.treat')}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  ...styles.typography,
                  fontSize: formData.product_font_size,
                }}
              >
                {t('orderModal.basket.paid')}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  ...styles.typography,
                  fontSize: formData.product_font_size,
                }}
              >
                {t('orderModal.basket.tax')}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  ...styles.typography,
                  fontSize: formData.product_font_size,
                }}
              >
                {t('orderModal.basket.totalPrice')}:
              </Typography>
            </Box>
            <Box textAlign="end">
              <Typography
                sx={{ fontSize: formData.product_font_size }}
                variant="subtitle2"
              >
                {priceFormatter(
                  Number(
                    currency(list?.totalPrices?.discountTotal || 0).divide(100),
                  ),
                  currencyCode,
                ) || '₺0,00'}
              </Typography>
              <Typography
                sx={{ fontSize: formData.product_font_size }}
                variant="subtitle2"
              >
                {priceFormatter(
                  Number(
                    currency(list?.totalPrices?.treatTotal || 0).divide(100),
                  ),
                  currencyCode,
                ) || '₺0,00'}
              </Typography>
              <Typography
                sx={{ fontSize: formData.product_font_size }}
                variant="subtitle2"
              >
                {priceFormatter(
                  Number(currency(list?.totalPrices?.paidTotal || 0)),
                  currencyCode,
                ) || '₺0,00'}
              </Typography>
              <Typography
                sx={{ fontSize: formData.product_font_size }}
                variant="subtitle2"
              >
                {priceFormatter(
                  Number(
                    currency(list?.totalPrices?.taxTotal || 0).divide(100),
                  ),
                  currencyCode,
                ) || '₺0,00'}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ fontSize: formData.product_font_size }}
              >
                {priceFormatter(
                  list?.totalPrices?.totalPrice || 0,
                  currencyCode,
                ) || '₺0,00'}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default GeneralSettingsForm;
