import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PropertyGroup, PropertyItem } from '../../../../models/Property';
import { getPropertyGroupsListThunk } from './thunks';

import { InitialState } from './types';

const initialState: InitialState = { loading: false, propertyGroups: [] };

const propertyGroupsSlice = createSlice({
  name: 'propertyGroupsSlice',
  initialState,
  reducers: {
    addPropertyGroupsSlice: (state, action: PayloadAction<PropertyGroup>) => ({
      ...state,
      propertyGroups: [...state.propertyGroups, action.payload],
    }),
    updatePropertyGroupsSlice: (
      state,
      action: PayloadAction<PropertyGroup>,
    ) => ({
      ...state,
      propertyGroups: state.propertyGroups.map(item =>
        item.id === action.payload.id ? action.payload : item,
      ),
    }),
    removePropertyGroupsSlice: (
      state,
      action: PayloadAction<Pick<PropertyGroup, 'id'>>,
    ) => ({
      ...state,
      propertyGroups: state.propertyGroups.filter(
        item => item.id !== action.payload.id,
      ),
    }),
    addPropertyItemSlice: (
      state,
      {
        payload: { groupId, ...item },
      }: PayloadAction<PropertyItem & { groupId: string }>,
    ) => {
      const targetGroup = state.propertyGroups.findIndex(
        el => el.id === groupId,
      );
      state.propertyGroups[targetGroup].items.push(item);
    },
    updatePropertyItemSlice: (
      state,
      {
        payload: { groupId, ...item },
      }: PayloadAction<PropertyItem & { groupId: string }>,
    ) => {
      const group = state.propertyGroups.find(el => el.id === groupId);
      if (group) {
        const targetGroup = state.propertyGroups.findIndex(
          el => el.id === groupId,
        );
        const targetItem = state.propertyGroups[targetGroup].items.findIndex(
          el => el.id === item.id,
        );
        state.propertyGroups[targetGroup].items[targetItem] = item;
      }
      return state;
    },
    removePropertyItemSlice: (
      state,
      {
        payload: { groupId, id },
      }: PayloadAction<{ id: string; groupId: string }>,
    ) => {
      const targetGroup = state.propertyGroups.findIndex(
        el => el.id === groupId,
      );
      const targetItem = state.propertyGroups[targetGroup].items.findIndex(
        el => el.id === id,
      );
      state.propertyGroups[targetGroup].items.splice(targetItem, 1);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getPropertyGroupsListThunk.pending, state => ({
        ...state,
        loading: true,
      }))
      .addCase(getPropertyGroupsListThunk.fulfilled, (state, action) => ({
        ...state,
        propertyGroups: action.payload,
        loading: false,
      }))
      .addCase(getPropertyGroupsListThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }));
  },
});

export const {
  addPropertyGroupsSlice,
  updatePropertyGroupsSlice,
  removePropertyGroupsSlice,
  addPropertyItemSlice,
  updatePropertyItemSlice,
  removePropertyItemSlice,
} = propertyGroupsSlice.actions;

export default propertyGroupsSlice.reducer;
