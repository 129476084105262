import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { Product } from '../../models/Product';
import api from '../../services/api';
import { isApiError } from '../../services/api/types';

const usePosSearch = () => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();

  const [query, setQuery] = useState('');
  const [error, setError] = useState<unknown>(null);
  const [searching, setSearching] = useState(false);
  const [data, setData] = useState<Product[]>([]);

  const debouncedSearch = useCallback(
    AwesomeDebouncePromise(api.definitions.products.search, 500),
    [],
  );

  const search = async (q: string) => {
    try {
      setSearching(true);
      setQuery(q);
      if (q) {
        const result = await debouncedSearch(q);
        if (result) {
          setData(result.data.data);
        }
      }
    } catch (err) {
      setError(err);
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } finally {
      setSearching(false);
    }
  };

  useEffect(() => {
    if (query) search(query);
  }, [query]);

  return { query, search, searching, error, data };
};
export default usePosSearch;
