import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../../components/ConfirmationModal';
import EmptyState from '../../../components/EmptyState';
import ErrorState from '../../../components/ErrorState';
import LoadingState from '../../../components/LoadingState';
import Modal from '../../../components/Modal';
import OrderedList from '../../../components/OrderedList';
import getActiveLanguage from '../../../helpers/getActiveLanguage';
import keyHasPermission from '../../../helpers/keyHasPermission';
import useProfile from '../../../hooks/useProfile';
import useProducts from '../../../hooks/useProducts';
import { IdAndNameTranslation } from '../../../models/General';

interface Props {
  categoryId?: string | null;

  open: boolean;
  onClose: () => void;
}

function ProductEditModal({ open, onClose, categoryId }: Props) {
  const { t } = useTranslation('category');

  const {
    products,
    productLoading,
    productError,
    removeProduct,
    productMoveUp,
    productMoveDown,
  } = useProducts();
  const { meProfile } = useProfile();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(
    null,
  ); // item id

  const handleMoveUp = async (item: IdAndNameTranslation) => {
    setLoading(true);
    await productMoveUp(item.id);
    setLoading(false);
  };

  const handleMoveDown = async (item: IdAndNameTranslation) => {
    setLoading(true);
    await productMoveDown(item.id);
    setLoading(false);
  };

  const handleRemove = async () => {
    if (deleteConfirmation) {
      setLoading(true);
      await removeProduct(deleteConfirmation);
      setLoading(false);
      setDeleteConfirmation(null);
    }
  };

  const renderLoading = () => <LoadingState />;
  const renderError = () => (
    <ErrorState
      message={
        productError ? productError.message : t('common:unknownError' as any)
      }
    />
  );
  const renderEmpty = () => <EmptyState message={t('product.empty')} />;
  const renderList = () => (
    <OrderedList
      items={products.map(item => ({
        ...item,
        name: `${
          getActiveLanguage(item.translations).name[0].toUpperCase() +
          getActiveLanguage(item.translations).name.substring(1)
        } `,
      }))}
      loading={loading}
      onItemEdit={p => navigate(`/definitions/product/${categoryId}/${p.id}`)}
      onItemUp={handleMoveUp}
      onItemDown={handleMoveDown}
      onItemRemove={item => setDeleteConfirmation(item.id)}
      dense={false}
      editButtons={keyHasPermission(
        'update-product',
        meProfile?.permissions || [],
      )}
      removeButtons={keyHasPermission(
        'delete-product',
        meProfile?.permissions || [],
      )}
    />
  );

  return (
    <>
      <Modal
        title={t('product.title')}
        open={open}
        onClose={onClose}
        positiveAction={t('product.close')}
        onPositiveClick={onClose}
      >
        {productLoading
          ? renderLoading()
          : productError
          ? renderError()
          : products.length
          ? renderList()
          : renderEmpty()}
      </Modal>
      <ConfirmationModal
        title={t('product.deleteConfirmation.title')}
        titleIconName="QuestionMarkOutlined"
        description={t('product.deleteConfirmation.description')}
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        positiveAction={t('product.deleteConfirmation.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('product.deleteConfirmation.cancel')}
        onNegativeClick={() => setDeleteConfirmation(null)}
        loading={loading}
      />
    </>
  );
}

export default ProductEditModal;
