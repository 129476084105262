export const stepKeys: string[] = [
  'wizard.steps.info',
  'wizard.steps.pricing',
  'wizard.steps.property',
  'wizard.steps.ingredient',
  'wizard.steps.menu',
  'wizard.steps.group',
];

export {};
