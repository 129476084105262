/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { matchIsValidTel } from 'mui-tel-input';

/** Styles */
import { Box, Grid } from '@mui/material';
import styles from '../../Register/styles';

/** Components */
import FormInput from '../../../components/FormInput';
import Button from '../../../components/Button';
import FormSelect from '../../../components/FormSelect';
import FormPhoneInput from '../../../components/FormPhoneInput';

/** Hooks */
import useProfile from '../../../hooks/useProfile';

/** Types */
import {
  changeUserInfoDefaultValues,
  ChangeUserInfoFormValues,
  validationChangeUserInfoSchema,
} from './types';
import { useAppSelector } from '../../../store';
import { OptionsMemoWithName } from '../../../helpers/optionMemo';

function ChangeUserInfoForm() {
  const { t } = useTranslation('auth');
  const { i18n } = useTranslation('layout');

  const { updateProfile, meProfile } = useProfile();

  const [loading, setLoading] = useState(false);
  const languages = useAppSelector(state => state.languages.list);

  const [value, setValue] = useState('');
  const [info, setInfo] = useState<any>(null);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleChange = (newValue: any, info: any) => {
    setValue(newValue);
    setInfo(info);
  };

  const initialValues = {
    first_name: meProfile?.firstName || '',
    last_name: meProfile?.lastName || '',
    phone_number: meProfile?.phoneNumber || '',
    email: meProfile?.email || '',
    pin: meProfile?.pin.toString() || '',
    language: meProfile?.language || 'tr',
  };

  const { handleSubmit, control, reset } = useForm<ChangeUserInfoFormValues>({
    defaultValues: initialValues || changeUserInfoDefaultValues,
    resolver: validationChangeUserInfoSchema,
  });

  useEffect(() => {
    reset(initialValues);
    setValue(meProfile?.phoneNumber || '');
  }, [meProfile]);

  const handleUpdateClick = () => {
    handleSubmit(async updateValues => {
      setLoading(true);
      try {
        await updateProfile({
          ...updateValues,
          phone_number_country: info?.countryCode || 'TR',
          phone_number: info?.nationalNumber || meProfile?.phoneNumber,
        });
        i18n.changeLanguage(updateValues.language);
        reset(updateValues);
        // eslint-disable-next-line no-empty
      } catch (err) {
      } finally {
        setLoading(false);
      }
    })();
  };

  return (
    <Box
      sx={{
        py: 10,
        px: 10,
        paddingX: {
          xxs: 0,
          xs: 0,
          sm: 0,
          md: 3,
          lg: 15,
          xl: 20,
        },
      }}
    >
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Controller
              name="first_name"
              // @ts-ignore
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  sx={styles.field}
                  label={t('profile.update_profile.first_name.label')}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="last_name"
              // @ts-ignore
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  sx={styles.field}
                  label={t('profile.update_profile.last_name.label')}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="email"
              // @ts-ignore
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  sx={styles.field}
                  label={t('profile.update_profile.email.label')}
                  autoComplete="username"
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="phone_number"
              // @ts-ignore
              control={control}
              rules={{ validate: matchIsValidTel }}
              render={({ field }) => (
                <FormPhoneInput
                  label={t('profile.update_profile.phone.label')}
                  phoneValue={value}
                  handleChange={handleChange}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="pin"
              // @ts-ignore
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  sx={styles.field}
                  label={t('profile.update_profile.pin.label')}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="language"
              // @ts-ignore
              control={control}
              render={({ field, fieldState }) => (
                <FormSelect
                  label={t('profile.update_profile.language.label')}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  underline
                  options={OptionsMemoWithName(languages)}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container justifyContent="right">
        <Button
          onClick={handleUpdateClick}
          sx={styles.submit}
          variant="contained"
          size="medium"
        >
          {t('profile.update_profile.submit')}
        </Button>
      </Grid>
    </Box>
  );
}

export default ChangeUserInfoForm;
