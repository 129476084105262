import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import getActiveLanguage from '../../../../../../helpers/getActiveLanguage';
import { Product } from '../../../../../../models/Product';
import OrderProductCard from './OrderProductCard';
import { priceFormatter } from '../../../../../../helpers/moneyFormatter';
import EmptyState from '../../../../../../components/EmptyState';

interface Props {
  list: Product[];
  onIncrease: (productId: string, itemId: string, menuId: string) => void;
  setOnSelectionModal: React.Dispatch<
    React.SetStateAction<{ isSelected: boolean; id: string }>
  >;
  setOnSelectionMenuModal: React.Dispatch<
    React.SetStateAction<{ isSelected: boolean; id: string }>
  >;
  currencyCode: string;
}

function ProductGrid({
  list,
  onIncrease,
  setOnSelectionModal,
  setOnSelectionMenuModal,
  currencyCode,
}: Props) {
  const { t } = useTranslation('pos');

  return (
    <Grid container spacing={1}>
      {!list.length ? (
        <EmptyState message={t('orderModal.emptyProduct')} />
      ) : (
        list.map(product => (
          <Grid item key={product.id} xs={4} sm={4} md={3} lg={2}>
            <OrderProductCard
              title={getActiveLanguage(product.translations).name}
              price={priceFormatter(
                Number(
                  product?.units?.find(item => item.is_default)?.amount
                    ?.amount || '0',
                ) / 100 || 0,
                currencyCode,
              )}
              propertyGroups={product.property_groups || []}
              requiredSelection={product.is_required_property_selection}
              onIncrease={onIncrease}
              itemId={product.id}
              isMenu={product.is_menu}
              isParent={product.is_parent || false}
              setOnSelectionModal={setOnSelectionModal}
              setOnSelectionMenuModal={setOnSelectionMenuModal}
              childProducts={product.children}
              currencyCode={currencyCode}
            />
          </Grid>
        ))
      )}
    </Grid>
  );
}

export default ProductGrid;
