/** Dependencies */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getCustomerListThunk,
  getCustomerThunk,
  getListPaymentThunk,
} from './thunks';

/** Types */
import { InitialState, PaymentList, CustomerBase } from './types';

const initialState: InitialState = {
  customer: null,
  meta: [],
  paymentMeta: [],
  loading: false,
  paymentLoading: false,
  customers: [],
  customerPaid: [],
  GetPaid: [],
};

const customerSlice = createSlice({
  name: 'customerSlice',
  initialState,
  reducers: {
    addCustomerSlice: (state, action: PayloadAction<CustomerBase>) => ({
      ...state,
      customers: [...state.customers, action.payload],
    }),
    addGetPaidSlice: (state, action: PayloadAction<any>) => ({
      ...state,
      customerPaid: [...state.customerPaid, action.payload],
    }),
    updateCustomerSlice: (state, action: PayloadAction<CustomerBase>) => ({
      ...state,
      customers: state.customers.map(item =>
        item.id === action.payload.id ? action.payload : item,
      ),
    }),
    updateCustomerPaidSlice: (state, action: PayloadAction<PaymentList>) => ({
      ...state,
      customerPaid: state.customerPaid.map(item =>
        item.id === action.payload.id ? action.payload : item,
      ),
    }),
    toggleActiveSlice: (state, action: PayloadAction<{ id: string }>) => ({
      ...state,
      customers: state.customers.map(item =>
        item.id === action.payload.id
          ? { ...item, is_active: !item.is_active }
          : item,
      ),
    }),
    toggleOpenActiveSlice: (state, action: PayloadAction<{ id: string }>) => ({
      ...state,
      customers: state.customers.map(item =>
        item.id === action.payload.id
          ? { ...item, is_open_account: !item.is_open_account }
          : item,
      ),
    }),
    toggleActiveSingleSlice: state => ({
      ...state,
      customer: state.customer
        ? { ...state.customer, is_active: !state.customer.is_active }
        : null,
    }),
    toggleOpenAccountSingleSlice: state => ({
      ...state,
      customer: state.customer
        ? {
            ...state.customer,
            is_open_account: !state.customer.is_open_account,
          }
        : null,
    }),
    removeCustomerSlice: (
      state,
      action: PayloadAction<Pick<CustomerBase, 'id'>>,
    ) => ({
      ...state,
      customers: state.customers.filter(item => item.id !== action.payload.id),
    }),
    removeCustomerPaidSlice: (state, action) => ({
      ...state,
      customerPaid: state.customerPaid.filter(
        item => item.id !== action.payload.id,
      ),
    }),
  },
  extraReducers: builder => {
    builder
      .addCase(getListPaymentThunk.pending, state => ({
        ...state,
        paymentLoading: true,
      }))
      .addCase(getListPaymentThunk.fulfilled, (state, action) => ({
        ...state,
        customerPaid: action.payload.data,
        paymentMeta: action.payload.meta,
        paymentLoading: false,
      }))
      .addCase(getListPaymentThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        paymentLoading: false,
      }))
      .addCase(getCustomerListThunk.pending, state => ({
        ...state,
        loading: true,
      }))
      .addCase(getCustomerListThunk.fulfilled, (state, action) => ({
        ...state,
        customers: action.payload.data,
        meta: action.payload.meta,
        loading: false,
      }))
      .addCase(getCustomerListThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }))
      .addCase(getCustomerThunk.pending, state => ({
        ...state,
        loading: true,
      }))
      .addCase(getCustomerThunk.fulfilled, (state, action) => ({
        ...state,
        customer: action.payload,
        loading: false,
      }))
      .addCase(getCustomerThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }));
  },
});

export const {
  addCustomerSlice,
  updateCustomerSlice,
  updateCustomerPaidSlice,
  toggleActiveSlice,
  toggleActiveSingleSlice,
  toggleOpenActiveSlice,
  toggleOpenAccountSingleSlice,
  removeCustomerSlice,
  addGetPaidSlice,
  removeCustomerPaidSlice,
} = customerSlice.actions;

export default customerSlice.reducer;
