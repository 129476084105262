import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/Modal';
import FormInputWithCounter from '../../../../../components/FormInputWithCounter';

interface Props {
  open: boolean;
  onClose: () => void;
  originalCount: number;
  onCountChange: (value: number) => void;
  guestCount?: number | null;
  isSelfOrder: boolean;
}
function CouvertModal({
  open,
  onClose,
  originalCount,
  onCountChange,
  guestCount,
  isSelfOrder,
}: Props) {
  const { t } = useTranslation('pos');

  const [count, setCount] = useState(0);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (open) {
      setCount(originalCount || guestCount || 1);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={t('orderModal.couvertModal.title')}
      positiveAction={t('orderModal.couvertModal.confirm')}
      onPositiveClick={() => {
        onCountChange(count);
        handleClose();
      }}
      negativeAction={t('orderModal.couvertModal.cancel')}
      onNegativeClick={handleClose}
    >
      <FormInputWithCounter
        onIncrease={() => setCount(count + 1)}
        onDecrease={() => {
          if (count > 1) {
            setCount(count - 1);
          }
        }}
        value={count}
        onChange={event =>
          setCount(
            parseInt(
              Number(event.target.value) < 1 ? '1' : event.target.value,
              10,
            ),
          )
        }
        disableUnderline
      />
    </Modal>
  );
}

export default CouvertModal;
