import React from 'react';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../../../../components/Modal';
import styles from '../styles';
import { Product } from '../../../../../../../../models/Product';
import getActiveLanguage from '../../../../../../../../helpers/getActiveLanguage';
import { priceFormatter } from '../../../../../../../../helpers/moneyFormatter';

interface Props {
  open: boolean;
  onClose: () => void;
  childProducts: Product[];
  currencyCode: string;
  onIncrease: (productId: string, itemId: string, menuId: string) => void;
  setOnSelectionModal: React.Dispatch<
    React.SetStateAction<{ isSelected: boolean; id: string }>
  >;
  setOnSelectionMenuModal: React.Dispatch<
    React.SetStateAction<{ isSelected: boolean; id: string }>
  >;
  title: string;
}
function OrderChildProductModal({
  open,
  onClose,
  childProducts,
  currencyCode,
  onIncrease,
  setOnSelectionModal,
  setOnSelectionMenuModal,
  title,
}: Props) {
  const { t } = useTranslation('pos');

  const handleClose = () => {
    onClose();
  };

  const handleSelect = (
    itemId: string,
    requiredSelection: boolean,
    isMenu: boolean,
  ) => {
    const uniqTime = Date.now().toString();
    onIncrease(itemId, '', uniqTime);
    if (isMenu) {
      setOnSelectionMenuModal({ isSelected: true, id: uniqTime });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      requiredSelection &&
        setOnSelectionModal({ isSelected: true, id: uniqTime });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${t('orderModal.childProductModal.title')} (${title})`}
      subtitle={t('orderModal.childProductModal.subtitle')}
      negativeAction={t('orderModal.childProductModal.cancel')}
      onNegativeClick={handleClose}
    >
      <Grid container spacing={2}>
        {childProducts.map(product => (
          <Grid item key={product.id} xs={4} sm={4} md={3} lg={3}>
            <Paper sx={styles.container}>
              <Stack
                direction="row"
                sx={{ height: '100%' }}
                onClick={() =>
                  handleSelect(
                    product.id,
                    product.is_required_property_selection,
                    product.is_menu,
                  )
                }
              >
                <Stack sx={styles.textContainer} justifyContent="space-between">
                  <Typography sx={[styles.title, styles.nonSelectable] as any}>
                    {getActiveLanguage(product.translations).name}
                  </Typography>
                  <Typography sx={styles.nonSelectable} variant="caption">
                    {priceFormatter(
                      Number(
                        product?.units?.find(item => item.is_default)?.amount
                          ?.amount || '0',
                      ) / 100 || 0,
                      currencyCode,
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Modal>
  );
}

export default OrderChildProductModal;
