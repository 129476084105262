import { Box, Container, Paper, Tab, Tabs } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { LAST_REGISTRATION_STEP } from '../../services/api/auth/types';
import { isApiError } from '../../services/api/types';
import { registrationStepStorage } from '../../services/tokenStorage';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  meWithPaymentTypesThunk,
  registerSecondStepThunk,
  registerThirdStepThunk,
  resendOtpThunk,
  verifyOtpThunk,
} from '../../store/slices/authSlice';
import SecondStepForm, {
  RegisterSecondStepFormValidValues,
} from './forms/SecondStepForm';
import ThirdStepForm, {
  RegisterThirdStepFormValidValues,
} from './forms/ThirdStepForm';
import styles from './styles';
import TabPanel from './TabPanel';
import errorReporting from '../../helpers/errorReporting';
import useUtilities from '../../hooks/useUtilities';
import OTPModal from './forms/ThirdStepForm/OTPModal';

function CompanyRegister() {
  const { t } = useTranslation('companyRegister');
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(state => state.auth.companyRegistration);
  const profile = useAppSelector(state => state.auth.profile);
  const location = useLocation();
  const navigate = useNavigate();
  const { fetchPaymentTypes } = useUtilities();
  const { enqueueSnackbar } = useSnackbar();
  const registrationStep = registrationStepStorage.getToken();

  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(0);

  const steps = useMemo(
    () => [t('steps.secondStep'), t('steps.thirdStep')],
    [],
  );

  useEffect(() => {
    dispatch(meWithPaymentTypesThunk());
    fetchPaymentTypes();
  }, []);

  const handleNext = () => {
    if (active < steps.length - 1) setActive(active + 1);
  };

  const handlePrev = () => {
    if (active > 0) setActive(active - 1);
  };

  const handleCompleted = () => {
    navigate('/');
  };

  const submitSecondStep = useCallback(
    async (data: RegisterSecondStepFormValidValues) => {
      try {
        if (!profile?.company?.name) {
          await dispatch(registerSecondStepThunk(data)).unwrap();
        }
        handleNext();
      } catch (err) {
        enqueueSnackbar(
          isApiError(err) ? err.message : t('common:unknownError' as any),
          {
            variant: 'error',
            autoHideDuration: 3000,
          },
        );
        errorReporting(err);
      }
    },
    [profile],
  );

  const submitThirdStep = useCallback(
    async (data: RegisterThirdStepFormValidValues) => {
      try {
        if (registrationStep && parseInt(registrationStep, 10) < 3) {
          await dispatch(registerThirdStepThunk(data)).unwrap();
          setOpen(true);
        }
      } catch (err) {
        enqueueSnackbar(
          isApiError(err) ? err.message : t('common:unknownError' as any),
          {
            variant: 'error',
            autoHideDuration: 3000,
          },
        );
        errorReporting(err);
        setOpen(false);
      }
    },
    [],
  );

  const submitVerify = useCallback(async (code: string) => {
    try {
      await dispatch(verifyOtpThunk(code)).unwrap();
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
      });
      handleCompleted();
      setTimeout(() => window.location.reload(), 500);
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
    }
  }, []);

  const resendOtp = useCallback(async () => {
    try {
      await dispatch(resendOtpThunk()).unwrap();
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
    }
  }, []);

  const registerStep = registrationStepStorage.getToken();
  if (registerStep && parseInt(registerStep, 10) === LAST_REGISTRATION_STEP) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  useEffect(() => {
    if (registerStep && parseInt(registerStep, 10) === 3) setOpen(true);
  }, [registerStep, location, profile]);
  return (
    <Container maxWidth="md" sx={styles.container}>
      <Paper sx={styles.paper} elevation={3}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={active}
            onChange={(_, value) => setActive(value)}
            variant="fullWidth"
            centered
          >
            {steps.map((step, index) => (
              <Tab
                key={step}
                label={step}
                disabled={index === 1 && !profile?.company}
              />
            ))}
          </Tabs>
        </Box>
        <TabPanel value={active} index={0}>
          <SecondStepForm
            onPrevClick={handlePrev}
            onSubmitClick={async data => {
              await submitSecondStep(data);
            }}
            isFirst={active === 0}
            isLast={active === steps.length - 1}
            loading={loading}
            initialValues={{
              name: profile?.company?.name || '',
              paymentTypes:
                profile?.paymentTypes?.map(type => type.is_active) || [],
            }}
          />
        </TabPanel>
        <TabPanel value={active} index={1}>
          <ThirdStepForm
            onPrevClick={handlePrev}
            onSubmitClick={async data => {
              await submitThirdStep(data);
              handleNext();
            }}
            onOtpSubmitClick={async (code: string) => {
              await submitVerify(code);
              handleNext();
            }}
            isFirst={active === 0}
            isLast={active === steps.length - 1}
            loading={loading}
            initialValues={null}
            userPhone={profile?.phoneNumber || ''}
            open={open}
            setOpen={setOpen}
          />
        </TabPanel>
      </Paper>
      <OTPModal
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={async (code: string) => {
          if (code.length === 6) {
            await submitVerify(code);
            handleNext();
          }
        }}
        onResend={async () => {
          await resendOtp();
        }}
        userPhone={profile?.phoneNumber || ''}
      />
    </Container>
  );
}

export default CompanyRegister;
