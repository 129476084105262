import { SxProps, Theme } from '@mui/material';

const styles: Record<string, SxProps<Theme>> = {
  title: { fontSize: { xs: 14, sm: 13, lg: 16 }, textTransform: 'capitalize' },
  menuItemTitle: { fontSize: 12, textTransform: 'capitalize' },
  menuItemSubtitle: { fontSize: 10, textTransform: 'capitalize' },
  properties: { fontSize: 10, textTransform: 'capitalize' },
  priceInfo: {
    fontWeight: '200',
    padding: '0 8px 0 0',
    color: 'ff0000c1',
    fontSize: { xs: 12, sm: 10, lg: 12 },
  },
  priceInfoUnderline: {
    fontWeight: '200',
    padding: '0 8px 0 0',
    color: 'ff0000c1',
    fontSize: { xs: 12, sm: 10, lg: 12 },
    textDecoration: 'line-through',
  },
};

export default styles;
