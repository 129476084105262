import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import Button from '../../components/Button';
import FormInput from '../../components/FormInput';
import FormPasswordInput from '../../components/FormPasswordInput';
import styles from './styles';
import { emailValidationSchema, passwordValidationSchema } from './types';
import RouterLink from '../../components/RouterLink';

interface Props {
  onValidSubmit: (data: any) => void;
  submitting: boolean;
  token: string;
}
function Form({ onValidSubmit, submitting, token }: Props) {
  const { t } = useTranslation('auth');
  const { handleSubmit, control } = useForm({
    defaultValues: { email: '', password: '', rePassword: '' },
    resolver: token ? passwordValidationSchema : emailValidationSchema,
  });

  const onSubmit = handleSubmit(onValidSubmit);

  return (
    <form onSubmit={onSubmit}>
      {!token && (
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <FormInput
              label={t('forgot.email.label')}
              autoComplete="username"
              error={fieldState.invalid}
              helperText={
                fieldState.error
                  ? t(fieldState.error.message as any)
                  : undefined
              }
              {...field}
            />
          )}
        />
      )}

      {token && (
        <>
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState }) => (
              <FormPasswordInput
                label={t('forgot.password.label')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
          <Controller
            name="rePassword"
            control={control}
            render={({ field, fieldState }) => (
              <FormPasswordInput
                label={t('forgot.rePassword.label')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
        </>
      )}
      <Grid sx={{ cursor: 'pointer', fontSize: 'inherit' }} textAlign="end">
        <Trans
          i18nKey="auth:forgot.subtitle"
          components={[<RouterLink to="/login" />]}
        />
      </Grid>
      <Button type="submit" sx={styles.submit} loading={submitting} fullWidth>
        {token ? t('forgot.submitReset') : t('forgot.submit')}
      </Button>
    </form>
  );
}

export default Form;
