/** Dependencies */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import currency from 'currency.js';
import { Divider, Stack } from '@mui/material';

/** Components */
import EmptyState from '../../../../../../components/EmptyState';
import BasketProductItem from './BasketProductItem';
import SpecialOrderItem from './SpecialOrderItem';

/** Helpers */
import getActiveLanguage from '../../../../../../helpers/getActiveLanguage';

/** Types */
import { BasketProduct } from '../../../../../../models/Product';
import { ActiveBasketProps } from './types';
import { SpecialOrder } from '../../types';

/** Hooks */
import useBasketItems from '../../../../../../hooks/useBasketItems/useBasketItems';

interface Props {
  list: BasketProduct[];
  specialOrderList: SpecialOrder[];
  onIncrease: (productId: string, itemId: string) => void;
  onDecrease: (id: string) => void;
  onDelete: (id: string) => void;
  onAction: (id: string) => void;
  onSelectionModal: { isSelected: boolean; id: string };
  setOnSelectionModal: React.Dispatch<
    React.SetStateAction<{ isSelected: boolean; id: string }>
  >;
  onSelectionMenuModal: { isSelected: boolean; id: string };
  setOnSelectionMenuModal: React.Dispatch<
    React.SetStateAction<{ isSelected: boolean; id: string }>
  >;
  setSpecialOrders: (
    value: ((prevState: SpecialOrder[]) => SpecialOrder[]) | SpecialOrder[],
  ) => void;
  currencyCode: string;
  onRemove: (removedPrice: number, orderId: string) => void;
  isAvailableToOrder: boolean;
  onTreat: () => void;
}
function BasketList({
  list,
  specialOrderList,
  onIncrease,
  onDecrease,
  onDelete,
  onAction,
  onSelectionModal,
  setOnSelectionModal,
  onSelectionMenuModal,
  setOnSelectionMenuModal,
  setSpecialOrders,
  currencyCode,
  onRemove,
  isAvailableToOrder,
  onTreat,
}: Props) {
  const { selectBasketItems } = useBasketItems();
  const { t } = useTranslation('pos');

  const [activeBasketProps, setActiveBasketProps] = useState<ActiveBasketProps>(
    {
      activeSinglePropertyId: '',
      activeMultiplePropertyIds: [],

      activeSinglePropertyName: {
        name: '',
        price: 0,
      },
      activeMultiplePropertyNames: [],

      activeIngredientIds: [],

      activeUnitId: '',
    },
  );

  const handleSelection = (itemId: string) => {
    if (itemId) {
      selectBasketItems(itemId, activeBasketProps);
    }
  };

  const handleSpecialOrderAction = (itemId: string, description: string) => {
    setSpecialOrders(
      specialOrderList.map(item =>
        item.itemId === itemId
          ? {
              ...item,
              description,
            }
          : item,
      ),
    );
  };

  const handleSpecialOrderRemove = (itemId: string) => {
    setSpecialOrders(specialOrderList.filter(item => item.itemId !== itemId));
  };

  const handleSpecialOrderIncrease = (itemId: string) => {
    setSpecialOrders(
      specialOrderList.map(item =>
        item.itemId === itemId
          ? { ...item, quantity: item.quantity + 1 }
          : item,
      ),
    );
  };

  const handleSpecialOrderDecrease = (itemId: string) => {
    setSpecialOrders(
      specialOrderList.map(item =>
        item.itemId === itemId
          ? { ...item, quantity: item.quantity - 1 }
          : item,
      ),
    );
  };

  const handleSpecialOrderTreat = (itemId: string, selectedCount: number) => {
    let newTreatItem;
    const selectedItem = specialOrderList.find(item => item.itemId === itemId);
    if (selectedItem?.quantity !== selectedCount) {
      newTreatItem = {
        ...selectedItem,
        quantity: selectedCount,
        is_treat: true,
        itemId: Date.now().toString(),
      };
    }

    const newItems = specialOrderList.map(item =>
      item.itemId === itemId
        ? item.quantity === selectedCount
          ? { ...item, is_treat: true }
          : {
              ...item,
              is_treat: false,
              quantity: item.quantity - selectedCount,
            }
        : item,
    );

    const totalItems = newTreatItem ? [...newItems, newTreatItem] : newItems;

    // @ts-ignore
    setSpecialOrders(totalItems);
  };

  const handleSpecialOrderUnTreat = (itemId: string, selectedCount: number) => {
    let newTreatItem;
    const selectedItem = specialOrderList.find(item => item.itemId === itemId);
    if (selectedItem?.quantity !== selectedCount) {
      newTreatItem = {
        ...selectedItem,
        quantity: selectedCount,
        is_treat: false,
        itemId: Date.now().toString(),
      };
    }

    const newItems = specialOrderList.map(item =>
      item.itemId === itemId
        ? item.quantity === selectedCount
          ? { ...item, is_treat: false }
          : {
              ...item,
              is_treat: true,
              quantity: item.quantity - selectedCount,
            }
        : item,
    );

    const totalItems = newTreatItem ? [...newItems, newTreatItem] : newItems;

    // @ts-ignore
    setSpecialOrders(totalItems);
  };

  return (
    <Stack
      direction="column-reverse"
      gap={{ xs: 1, md: 2 }}
      divider={<Divider />}
    >
      {list.length
        ? list.map(item => (
            <BasketProductItem
              key={item.itemId}
              createdName={`${item?.created_by?.full_name || ''}`}
              itemId={item.id}
              isTreat={item.is_treat}
              onTreat={onTreat}
              selectedItemId={item.itemId}
              isAvailableToOrder={isAvailableToOrder}
              description={item.description}
              name={
                item.has_special_product
                  ? item?.items[0]?.product_name || ''
                  : item.is_menu && item.is_submitted_order
                  ? getActiveLanguage(item?.product?.translations).name
                  : `${getActiveLanguage(item.translations).name} 
                  ${
                    item?.items && item?.items[0]?.product?.parent?.translations
                      ? `(${
                          item?.items
                            ? getActiveLanguage(
                                item?.items[0]?.product?.parent?.translations,
                              )?.name
                            : ''
                        })`
                      : ''
                  }`
              }
              // statusId={item.status_id}
              count={item.count}
              createDate={item.created_at}
              isSubmittedOrder={item.is_submitted_order || false}
              totalPrice={
                item.total_price ||
                Number(currency(item?.total_amount?.amount).divide(100))
              }
              // Functions
              onIncrease={() => onIncrease(item.id, item.itemId || '')}
              onDecrease={() => onDecrease(item.itemId || '')}
              onDelete={() => onDelete(item.itemId || '')}
              onAction={() => onAction(item.id)}
              onSelection={() => handleSelection(item.itemId || '')}
              onRemove={onRemove}
              // Units
              unit={
                item.has_special_product
                  ? null
                  : item.active_unit
                  ? item.active_unit
                  : item.units.find(unit => unit.is_default)
              }
              units={item?.units?.filter(unit => unit.is_active)}
              // Properties
              requiredSelection={item.is_required_property_selection}
              propertyGroupItems={item.property_group_items}
              ingredientGroupItems={item.ingredient_group_items}
              singlePropertyId={item?.active_single_property_id || ''}
              multiplePropertyId={item?.active_multiple_property_id || []}
              propertyNames={item?.active_multiple_property_names || []}
              singlePropertyName={
                item?.active_single_property_name || { name: '', price: 0 }
              }
              isActiveProperties={
                item?.property_groups?.length
                  ? item?.property_groups?.some(
                      someItem =>
                        someItem.items.filter(propItem => propItem.is_active)
                          .length,
                    )
                  : false
              }
              // Ingredients
              isActiveIngredients={
                item?.ingredient_groups?.length
                  ? item?.ingredient_groups?.some(
                      someItem =>
                        someItem.items.filter(
                          ingredientItem => ingredientItem.is_active,
                        ).length,
                    )
                  : false
              }
              ingredientGroups={item?.ingredient_groups || []}
              nonIngredientId={item?.non_ingredient_item_id || []}
              // Selection Modal
              onSelectionModal={onSelectionModal}
              setOnSelectionModal={setOnSelectionModal}
              onSelectionMenuModal={onSelectionMenuModal}
              setOnSelectionMenuModal={setOnSelectionMenuModal}
              // Menu
              isMenu={item?.is_menu}
              productMenus={item?.product_menus || []}
              menuItems={item?.items || []}
              // basket
              activeBasketProps={activeBasketProps}
              setActiveBasketProps={setActiveBasketProps}
              currencyCode={currencyCode}
            />
          ))
        : !specialOrderList.length && (
            // todo: add to localization
            <EmptyState message={t('basketList.empty')} />
          )}
      {specialOrderList.length &&
        specialOrderList.map(item => (
          <SpecialOrderItem
            name={item.product_name}
            count={item.quantity}
            onIncrease={() => handleSpecialOrderIncrease(item.itemId || '')}
            onDecrease={() => handleSpecialOrderDecrease(item.itemId || '')}
            onDelete={() => handleSpecialOrderRemove(item.itemId || '')}
            onAction={(id: string, description: string) =>
              handleSpecialOrderAction(id, description)
            }
            totalPrice={item.price}
            currencyCode={currencyCode}
            selectedItemId={item.itemId}
            isTreat={item.is_treat}
            handleSpecialOrderTreat={handleSpecialOrderTreat}
            handleSpecialOrderUnTreat={handleSpecialOrderUnTreat}
            description={item.description || ''}
          />
        ))}
    </Stack>
  );
}

export default BasketList;
