/** Dependencies */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

/** Styles */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from '@mui/material';

/** Hooks */
import useCallerId from '../../../hooks/useCallerId';
import { CallerIdBase } from '../../../services/api/definitions/callerIdRecords/types';

interface Props {
  page: number;
  setPage: (value: ((prevState: number) => number) | number) => void;
  records: CallerIdBase[];
  meta: any;
}

function RecordsTable({ page, setPage, records, meta }: Props) {
  const { t } = useTranslation('callerId');
  const { fetchCallerIdRecords } = useCallerId();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (value !== page) {
      setPage(value);
      fetchCallerIdRecords({
        search: '',
        page: value,
      });
    }
  };

  useEffect(() => {
    // @ts-ignore
    setPage(meta?.current_page);
  }, [meta]);

  useEffect(() => {
    // @ts-ignore
    setPage(meta?.current_page);
  }, [meta]);

  return (
    <Paper sx={{ width: '100%', p: 2 }}>
      {
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('call.callTable.phoneNumber')}</TableCell>
                <TableCell>{t('call.callTable.name')}</TableCell>
                <TableCell>{t('call.callTable.date')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.phone_number}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row?.customer?.first_name} {row?.customer?.last_name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {format(new Date(row?.called_at), 'dd.MM.yyyy HH:mm')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            sx={{ margin: '10px' }}
            // @ts-ignore
            count={Math.ceil((meta?.total || 0) / (meta?.per_page || 0))}
            page={page}
            onChange={handleChange}
          />
        </TableContainer>
      }
    </Paper>
  );
}

export default RecordsTable;
