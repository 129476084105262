/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

/** Styles */
import {
  Button,
  Grid,
  Paper,
  Stack,
  styled,
  TableCell,
  TableRow,
  Typography,
  useTheme,
  Slider,
} from '@mui/material';
import styles from './styles';

/** helpers */
import getActiveLanguage from '../../helpers/getActiveLanguage';

/** Components */
import InfoCard from '../../components/Card/InfoCard';
import useReports from '../../hooks/useReports';
import useProfile from '../../hooks/useProfile';
import EmptyState from '../../components/EmptyState';
import keyHasPermission from '../../helpers/keyHasPermission';
import { expiredAccountStorage } from '../../services/tokenStorage';
import TableComponent from '../../components/TableComponent';

function Dashboard() {
  const { t } = useTranslation('dashboard');
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const {
    dashboardReports,
    paymentTypeReports,
    mostPopular,
    fetchMostPopular,
    fetchDashboardReports,
    fetchPaymentTypeReports,
  } = useReports();
  const { meProfile } = useProfile();

  const activeUntil = new Date(meProfile?.activeBranch?.active_until || '');
  const remainingDay = Math.floor(
    (activeUntil.getTime() - Date.now()) / (1000 * 60 * 60 * 24),
  );

  const [slotMostPopular, setSlotMostPopular] = useState('day');
  const [slotPaymentType, setSlotPaymentType] = useState('day');

  const dateForWeek = new Date(Date.now());
  dateForWeek.setDate(dateForWeek.getDate() - 7);

  const dateForMonth = new Date(Date.now());
  dateForMonth.setDate(dateForMonth.getDate() - 30);

  const initialStartDateForWeek = format(dateForWeek, 'yyyy-MM-dd');
  const initialStartDateForMonth = format(dateForMonth, 'yyyy-MM-dd');
  const initialEndDate = format(new Date(Date.now()), 'yyyy-MM-dd');

  const hasShowInfoCard = keyHasPermission(
    'restaurant-dashboard',
    meProfile?.permissions || [],
  );

  const token = expiredAccountStorage.getToken();

  const handleClickMostPopular = async (slot: string) => {
    setSlotMostPopular(slot);

    if (slot === 'day') {
      if (meProfile?.activeBranch?.day_id) {
        await fetchMostPopular(
          meProfile?.activeBranch?.day_id || '',
          1,
          undefined,
          undefined,
          undefined,
          10,
        );
      } else {
        enqueueSnackbar(t('dayStatus'), {
          variant: 'warning',
          autoHideDuration: 3000,
        });
      }
    }
    if (slot === 'week') {
      await fetchMostPopular(
        undefined,
        1,
        undefined,
        initialStartDateForWeek,
        initialEndDate,
        10,
      );
    }
    if (slot === 'month') {
      await fetchMostPopular(
        undefined,
        1,
        undefined,
        initialStartDateForMonth,
        initialEndDate,
        10,
      );
    }
  };

  const handleClickPaymentType = async (slot: string) => {
    setSlotPaymentType(slot);

    if (slot === 'day') {
      if (meProfile?.activeBranch?.day_id) {
        await fetchPaymentTypeReports(
          undefined,
          undefined,
          meProfile?.activeBranch?.day_id || '',
        );
      } else {
        enqueueSnackbar(t('dayStatus'), {
          variant: 'warning',
          autoHideDuration: 3000,
        });
      }
    }
    if (slot === 'week') {
      await fetchPaymentTypeReports(initialStartDateForWeek, initialEndDate);
    }
    if (slot === 'month') {
      await fetchPaymentTypeReports(initialStartDateForMonth, initialEndDate);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  useEffect(() => {
    fetchDashboardReports();
  }, []);

  useEffect(() => {
    if (meProfile?.activeRole?.name && !token) {
      if (hasShowInfoCard && meProfile?.activeBranch?.day_id) {
        fetchMostPopular(
          meProfile?.activeBranch?.day_id,
          1,
          undefined,
          undefined,
          undefined,
          10,
        );

        fetchPaymentTypeReports(
          undefined,
          undefined,
          meProfile?.activeBranch?.day_id,
        );
      }
    }
  }, [hasShowInfoCard, meProfile]);

  const mostPopularTableHead = [
    t('mostPopularTable.name'),
    t('mostPopularTable.quantity'),
    t('mostPopularTable.unit'),
    t('mostPopularTable.unitPrice'),
    t('mostPopularTable.cost'),
    t('mostPopularTable.totalAmount'),
  ];

  const mostPopularTableBody = mostPopular.map((row, index) => (
    <TableRow key={row?.product?.id ? row.product.id : index}>
      <TableCell>
        {row?.product?.id
          ? getActiveLanguage(row.product?.translations)?.name
          : row.product}
      </TableCell>
      <TableCell>{row?.total_quantity}</TableCell>
      <TableCell>{row?.unit?.translations[0]?.name}</TableCell>
      <TableCell>{row?.unit_price?.formatted}</TableCell>
      <TableCell>{row?.cost?.formatted}</TableCell>
      <TableCell>{row?.total_amount?.formatted}</TableCell>
    </TableRow>
  ));

  const paymentTypeTableHead = [
    t('paymentTypeTable.paymentType'),
    t('paymentTypeTable.totalAmount'),
  ];

  const paymentTypeTableBody = paymentTypeReports.map((row, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <TableRow key={index}>
      <TableCell>
        {getActiveLanguage(row.payment_type.translations).name}
      </TableCell>
      <TableCell>{row.total_amount.formatted}</TableCell>
    </TableRow>
  ));

  if (token) {
    return (
      <div>
        Üyeliğinizin süresi dolmuştur. Kitchrest’i kullanmaya devam edebilmek
        için ödeme yönteminizi belirlemeniz gerekmektedir. Ayrıntılı bilgi için
        0 (544) 622 01 25 numarasını arayabilir veya{' '}
        <a style={{ marginRight: '2px' }} href="mailto:destek@kitchrest.com">
          destek@kitchrest.com
        </a>
        adresine e-posta gönderebilirsiniz.
      </div>
    );
  }

  return (
    <div>
      <Stack>
        {meProfile?.activeRole?.name === 'owner' &&
          meProfile?.activeBranch?.billing_type === 'demo' && (
            <Grid
              container
              flexDirection="row"
              display="flex"
              justifyContent="flex-start"
              m={1}
            >
              {remainingDay < 30 && (
                <Grid item xs={7}>
                  <Grid container>
                    <Grid xs={6} fontWeight={500}>
                      {t('remainingText', { remainingDay })}
                    </Grid>
                    <Grid xs={6}>
                      <Slider
                        sx={{
                          '&.Mui-disabled': {
                            color: '#26acf8',
                          },
                          '& .MuiSlider-thumb': {
                            height: 0,
                            width: 0,
                          },
                        }}
                        value={remainingDay}
                        min={1}
                        step={1}
                        max={30}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}

        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
          {/* row 1 */}
          <Grid item xs={12} sx={{ mb: -2.25 }}>
            <Typography variant="h5" />
          </Grid>
          <br />
          <InfoCard
            title={t('tableDensity')}
            count={`${dashboardReports.active_order_transaction_count}/${dashboardReports.total_table_count}`}
            extra="20.000"
            rate={
              `${
                (
                  (dashboardReports.active_order_transaction_count /
                    dashboardReports.total_table_count) *
                    100 || 0
                ).toFixed(2) || 0
              }%` || '0'
            }
            isShowTrend
          />
          <InfoCard
            title={t('guestCount')}
            count={dashboardReports.guest_count.toString()}
            extra="20.000"
          />
          {hasShowInfoCard && (
            <>
              <InfoCard
                title={t('totalOrderAmount')}
                count={dashboardReports.total_order_amount?.formatted}
                extra="20.000"
                // isShowTrend
              />
              <InfoCard
                title={t('activeTotalAmount')}
                count={dashboardReports.active_order_amount?.formatted}
                extra="20.000"
                // isShowTrend
              />
              <InfoCard
                title={t('totalExpenseAmount')}
                count={dashboardReports.total_expense_amount?.formatted}
                extra="20.000"
                // isShowTrend
              />
              <InfoCard
                title={t('totalProductCostAmount')}
                count={dashboardReports.total_cost_amount?.formatted}
                extra="20.000"
                // isShowTrend
              />
              <InfoCard
                title={t('orderTransactionCount')}
                count={dashboardReports.order_transaction_count?.toString()}
                extra="20.000"
              />
            </>
          )}
        </Grid>

        {hasShowInfoCard && (
          <Grid
            container
            rowSpacing={4.5}
            columnSpacing={2.75}
            paddingTop="48px"
          >
            <Grid item xs={12} md={7} lg={8}>
              <Item
                sx={{
                  ...styles.gridItem,
                  borderColor: theme.palette.divider,
                  boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
                  '& pre': {
                    m: 0,
                    p: '16px !important',
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '0.75rem',
                  },
                }}
              >
                <Grid mb={2}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Grid container justifyContent="left">
                        <Typography variant="h5">
                          <Grid item sx={{ textAlign: 'start' }}>
                            {t('mostPopularTable.title')}
                          </Grid>
                          <Grid
                            item
                            sx={{ fontSize: '12px', textAlign: 'start' }}
                          >
                            {slotMostPopular === 'day'
                              ? `(${format(
                                  new Date(
                                    meProfile?.activeBranch?.day?.start_date ||
                                      Date.now(),
                                  ),
                                  'dd.MM.yyyy HH:mm',
                                )} - ${format(
                                  new Date(Date.now()),
                                  'dd.MM.yyyy HH:mm',
                                )})`
                              : slotMostPopular === 'week'
                              ? `(${format(
                                  dateForWeek,
                                  'dd.MM.yyyy HH:mm',
                                )} - ${format(
                                  new Date(Date.now()),
                                  'dd.MM.yyyy HH:mm',
                                )})`
                              : `(${format(
                                  dateForMonth,
                                  'dd.MM.yyyy HH:mm',
                                )} - ${format(
                                  new Date(Date.now()),
                                  'dd.MM.yyyy HH:mm',
                                )})`}
                          </Grid>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Stack direction="row" alignItems="center" spacing={0}>
                        <Button
                          size="small"
                          onClick={() => {
                            handleClickMostPopular('day');
                          }}
                          color={
                            slotMostPopular === 'day' ? 'primary' : 'error'
                          }
                          variant={
                            slotMostPopular === 'day' ? 'outlined' : 'text'
                          }
                        >
                          {t('mostPopularTable.buttons.day')}
                        </Button>
                        <Button
                          size="small"
                          onClick={() => {
                            handleClickMostPopular('week');
                          }}
                          color={
                            slotMostPopular === 'week' ? 'primary' : 'error'
                          }
                          variant={
                            slotMostPopular === 'week' ? 'outlined' : 'text'
                          }
                        >
                          {t('mostPopularTable.buttons.week')}
                        </Button>
                        <Button
                          size="small"
                          onClick={() => {
                            handleClickMostPopular('month');
                          }}
                          color={
                            slotMostPopular === 'month' ? 'primary' : 'error'
                          }
                          variant={
                            slotMostPopular === 'month' ? 'outlined' : 'text'
                          }
                        >
                          {t('mostPopularTable.buttons.month')}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                {!mostPopular.length || !meProfile?.activeBranch?.day_id ? (
                  <EmptyState message={t('empty')} />
                ) : (
                  <TableComponent
                    tableHead={mostPopularTableHead}
                    tableBody={mostPopularTableBody}
                    tableStyle={{ minWidth: 650 }}
                    count={1}
                    page={1}
                    showPagination={false}
                  />
                )}
              </Item>
            </Grid>
            <Grid item xs={12} md={5} lg={4}>
              <Item
                sx={{
                  ...styles.gridItem,
                  borderColor: theme.palette.divider,
                  boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
                  '& pre': {
                    m: 0,
                    p: '16px !important',
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '0.75rem',
                  },
                }}
              >
                <Grid mb={2}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item sx={{ textAlign: 'start' }}>
                      <Typography variant="h5">
                        {t('paymentTypeTable.title')}
                        <Grid
                          item
                          sx={{ fontSize: '12px', textAlign: 'start' }}
                        >
                          {slotPaymentType === 'day'
                            ? `(${format(
                                new Date(
                                  meProfile?.activeBranch?.day?.start_date ||
                                    Date.now(),
                                ),
                                'dd.MM.yyyy HH:mm',
                              )} - ${format(
                                new Date(Date.now()),
                                'dd.MM.yyyy HH:mm',
                              )})`
                            : slotPaymentType === 'week'
                            ? `(${format(
                                dateForWeek,
                                'dd.MM.yyyy HH:mm',
                              )} - ${format(
                                new Date(Date.now()),
                                'dd.MM.yyyy HH:mm',
                              )})`
                            : `(${format(
                                dateForMonth,
                                'dd.MM.yyyy HH:mm',
                              )} - ${format(
                                new Date(Date.now()),
                                'dd.MM.yyyy HH:mm',
                              )})`}
                        </Grid>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Stack direction="row" alignItems="center" spacing={0}>
                        <Button
                          size="small"
                          onClick={() => {
                            handleClickPaymentType('day');
                          }}
                          color={
                            slotPaymentType === 'day' ? 'primary' : 'error'
                          }
                          variant={
                            slotPaymentType === 'day' ? 'outlined' : 'text'
                          }
                        >
                          {t('paymentTypeTable.buttons.day')}
                        </Button>
                        <Button
                          size="small"
                          onClick={() => {
                            handleClickPaymentType('week');
                          }}
                          color={
                            slotPaymentType === 'week' ? 'primary' : 'error'
                          }
                          variant={
                            slotPaymentType === 'week' ? 'outlined' : 'text'
                          }
                        >
                          {t('paymentTypeTable.buttons.week')}
                        </Button>
                        <Button
                          size="small"
                          onClick={() => {
                            handleClickPaymentType('month');
                          }}
                          color={
                            slotPaymentType === 'month' ? 'primary' : 'error'
                          }
                          variant={
                            slotPaymentType === 'month' ? 'outlined' : 'text'
                          }
                        >
                          {t('paymentTypeTable.buttons.month')}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                {!paymentTypeReports.length ||
                !meProfile?.activeBranch?.day_id ? (
                  <EmptyState message={t('empty')} />
                ) : (
                  <TableComponent
                    tableHead={paymentTypeTableHead}
                    tableBody={paymentTypeTableBody}
                    count={1}
                    page={1}
                    showPagination={false}
                  />
                )}
              </Item>
            </Grid>
          </Grid>
        )}
      </Stack>
    </div>
  );
}

export default Dashboard;
