import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import arraySwap from '../../../../helpers/arraySwap';
import { getSectionListThunk } from './thunks';

import { InitialState, Section } from './types';

const initialState: InitialState = { loading: false, sections: [] };

const sectionsSlice = createSlice({
  name: 'sectionsSlice',
  initialState,
  reducers: {
    addSectionSlice: (state, action: PayloadAction<Section>) => ({
      ...state,
      sections: [...state.sections, action.payload],
    }),
    updateSectionSlice: (state, action: PayloadAction<Section>) => ({
      ...state,
      sections: state.sections.map(item =>
        item.id === action.payload.id ? action.payload : item,
      ),
    }),
    removeSectionSlice: (
      state,
      action: PayloadAction<Pick<Section, 'id'>>,
    ) => ({
      ...state,
      sections: state.sections.filter(item => item.id !== action.payload.id),
    }),
    moveUpSlice: (state, action: PayloadAction<Pick<Section, 'id'>>) => {
      const targetIndex = state.sections.findIndex(
        item => item.id === action.payload.id,
      );
      return {
        ...state,
        sections: arraySwap(state.sections, targetIndex, targetIndex - 1),
      };
    },
    moveDownSlice: (state, action: PayloadAction<Pick<Section, 'id'>>) => {
      const targetIndex = state.sections.findIndex(
        item => item.id === action.payload.id,
      );
      return {
        ...state,
        sections: arraySwap(state.sections, targetIndex, targetIndex + 1),
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getSectionListThunk.pending, state => ({
        ...state,
        loading: true,
      }))
      .addCase(getSectionListThunk.fulfilled, (state, action) => ({
        ...state,
        sections: action.payload,
        loading: false,
      }))
      .addCase(getSectionListThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }));
  },
});

export const {
  addSectionSlice,
  updateSectionSlice,
  removeSectionSlice,
  moveUpSlice,
  moveDownSlice,
} = sectionsSlice.actions;

export default sectionsSlice.reducer;
