/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Divider,
  List,
  ListItem,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

/** Components */

/** Types */
import { BasketProduct } from '../../../../../../../models/Product';

/** Styles */
import styles from '../../styles';
import generalStyles from '../../../../../../../assets/stylesheets/generalStyles';
import getActiveLanguage from '../../../../../../../helpers/getActiveLanguage';
import { singlePriceFormatter } from '../../../../../../../helpers/moneyFormatter';

interface Props {
  list: BasketProduct[];
  currencyCode: string;
}

function SelectedOrders({ list, currencyCode }: Props) {
  const { t } = useTranslation('pos');

  const selectedOrders = () => {
    const selectedList = list.map(
      item =>
        item.selected_orders_count && (
          <>
            <br />
            <Divider />
            <br />
            <Stack direction="row">
              <Paper sx={generalStyles.orderCountContainer}>
                <Typography>{item.selected_orders_count}</Typography>
              </Paper>
              <Stack
                sx={{ flex: 1, pl: 2, cursor: 'pointer' }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Typography sx={styles.selectedQuantityInfo}>
                    {item.selected_orders_count} {t('paymentModal.piece')}
                  </Typography>
                  <Typography
                    sx={{ ...styles.title, textDecoration: 'line-through' }}
                  >
                    {item?.product?.translations
                      ? getActiveLanguage(item?.product?.translations)?.name
                      : item?.translations
                      ? getActiveLanguage(item?.translations)?.name
                      : item.items[0].product_name}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ ...styles.title, textDecoration: 'line-through' }}
                  >
                    {item.units &&
                      getActiveLanguage(item.units[0].translations).name}
                  </Typography>
                  <Typography sx={styles.properties} variant="subtitle2">
                    <List>
                      {item.items.length &&
                        item.items?.map(
                          itemItems =>
                            itemItems.property_group_items &&
                            itemItems.property_group_items.map(propItem => (
                              <ListItem sx={styles.priceInfo}>
                                {propItem.translations[0].name}
                                {propItem.additional_price.amount !== '0' &&
                                  ` + ${propItem.additional_price.formatted}`}
                              </ListItem>
                            )),
                        )}
                    </List>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    sx={{ ...styles.title, textDecoration: 'line-through' }}
                  >
                    {singlePriceFormatter(
                      item.total_amount.amount,
                      item.paid_quantity / item.count,
                      currencyCode,
                    )}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </>
        ),
    );
    return selectedList.filter(item => !!item);
  };

  return (
    <>
      {selectedOrders().length > 0 && (
        <>
          <Stack
            sx={{
              textDecoration: 'underline 1.5px red',
              textUnderlinePosition: 'under',
              textAlign: 'center',
              alignItems: 'center',
            }}
          >
            {t('paymentModal.select')}
          </Stack>
          <br />
        </>
      )}
      <Stack direction="column-reverse">{selectedOrders()}</Stack>
    </>
  );
}

export default SelectedOrders;
