import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type RegisterSecondStepFormValues = {
  name: string;
  paymentTypes: boolean[];
};

export type RegisterSecondStepFormValidValues = {
  name: string;
  paymentTypes: { id: string; active: boolean }[];
};

const schema = yup.object({
  name: yup.string().required('company.name.required'),
  paymentTypes: yup
    .array(yup.boolean().required().default(false))
    .required('company.paymentType.required'),
});

export const defaultValues: RegisterSecondStepFormValues = {
  name: '',
  paymentTypes: [],
};
export const validationSchema = yupResolver(schema);
