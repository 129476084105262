import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../services/api';

import {
  getPaymentTypes,
  getBusinessTypes,
  getCurrencies,
  getWorkingTypes,
} from './utilitiesSlice';

export const getPaymentTypesThunk = createAsyncThunk(
  'utilities/paymentTypes',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.utilities.paymentTypes();
      const result = response.data.data;
      dispatch(getPaymentTypes(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getBusinessTypesThunk = createAsyncThunk(
  'utilities/businessTypes',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.utilities.businessTypes();
      const result = response.data.data;
      dispatch(getBusinessTypes(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getWorkingTypesThunk = createAsyncThunk(
  'utilities/workingTypes',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.utilities.workingTypes();
      const result = response.data.data;
      dispatch(getWorkingTypes(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getCurrenciesThunk = createAsyncThunk(
  'utilities/currencies',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.utilities.currencies();
      const result = response.data.data;
      dispatch(getCurrencies(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
