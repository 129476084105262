import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import {
  CreateAndUpdateUser,
  CreateFlow,
  Flow,
  NotificationUser,
  SuspiciousTransaction,
  UpdateFlow,
} from '../../services/api/operations/fraud/types';
import { isApiError } from '../../services/api/types';
import arrayCatcher from '../../helpers/errorCatcher';

const useFlow = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [flowList, setFlowList] = useState<Flow[]>([]);
  const [flowListMeta, setFlowListMeta] = useState<Flow[]>([]);
  const [flow, setFlow] = useState<Flow | null>(null);
  const [flowMeta, setFlowMeta] = useState<Flow | null>(null);
  const [userResponse, setUserResponse] = useState<NotificationUser | null>(
    null,
  );
  const [suspiciousTransactionList, setSuspiciousTransactionList] = useState<
    SuspiciousTransaction[]
  >([]);
  const [suspiciousTransactionListMeta, setSuspiciousTransactionListMeta] =
    useState([]);

  const fetchFraudFlow = async (page: number) => {
    try {
      const response = await api.operations.fraud.flowList(page);
      setFlowList(response.data.data);
      setFlowListMeta(response.data.meta);
    } catch (err: any) {
      arrayCatcher(err?.code);
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      if (err?.code === 1003) {
        navigate('/');
      }
    }
  };

  const fetchFraudFlowById = async (id: string) => {
    const response = await api.operations.fraud.getFlowById(id);
    setFlow(response.data.data);
    setFlowMeta(response.data.meta);
  };

  const fetchSuspiciousTransaction = async (page: number) => {
    const response = await api.operations.fraud.suspiciousTransactionList(page);
    setSuspiciousTransactionList(response.data.data);
    setSuspiciousTransactionListMeta(response.data.meta);
  };

  const createFlow = async (params: CreateFlow) => {
    await api.operations.fraud.createFlow(params);
  };

  const updateFlow = async (params: UpdateFlow) => {
    await api.operations.fraud.updateFlow(params);
  };

  const createUser = async (params: CreateAndUpdateUser) => {
    await api.operations.fraud.createUser(params);
  };

  const updateUser = async (params: CreateAndUpdateUser) => {
    await api.operations.fraud.updateUser(params);
  };

  const deleteUser = async (flowId: string, notificationUserId: string) => {
    await api.operations.fraud.deleteUser(flowId, notificationUserId);
  };

  return {
    flowList,
    flowListMeta,
    flow,
    flowMeta,
    suspiciousTransactionList,
    suspiciousTransactionListMeta,
    fetchFraudFlow,
    fetchFraudFlowById,
    fetchSuspiciousTransaction,
    createFlow,
    updateFlow,
    createUser,
    updateUser,
    deleteUser,
    userResponse,
  };
};
export default useFlow;
