/** Dependencies */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

/** Styles */
import { Paper, Stack, Box } from '@mui/material';

/** Components */
import UtilitiesBar, {
  Props as BarProps,
} from '../../../components/UtilitiesBar';
import CustomerInfo from './CustomerInfo';
import CustomerAddressAddModal from './Modals/CustomerAddressAddModal';
import useProfile from '../../../hooks/useProfile';
import useCustomers from '../../../hooks/useCustomers';
import GetPaidModal from './Modals/GetPaidModal';
import arrayKeyHasPermission from '../../../helpers/arrayKeyHasPermission';
import CustomerDetailsTable from './CustomerDetailsTable';

const MENU: BarProps['menu'] = [
  {
    id: 0,
    text: 'actions.Back',
    startIconName: 'Back',
    permissionKeys: [],
  },
  {
    id: 1,
    text: 'actions.addCustomerAddress',
    startIconName: 'Add',
    permissionKeys: ['create-customer-address'],
  },
  {
    id: 2,
    text: 'actions.payment',
    startIconName: 'Payment',
    permissionKeys: ['create-customer-payment'],
  },
];
const MENU2: BarProps['menu'] = [
  {
    id: 0,
    text: 'actions.Back',
    startIconName: 'Back',
    permissionKeys: [],
  },
  {
    id: 1,
    text: 'actions.addCustomerAddress',
    startIconName: 'Add',
    permissionKeys: ['create-customer-address'],
  },
];

function CustomerDetailsWizard() {
  const { t } = useTranslation('customerAddress');
  const navigate = useNavigate();

  const { meProfile } = useProfile();
  const { customer } = useCustomers();

  const [customerAddAddressModal, setCustomerAddAddressModal] = useState(false);
  const [customerGetPaidModal, setCustomerGetPaidModal] = useState(false);
  const [customerBalance, setCustomerBalance] = useState(false);
  const [isOpenAccount, setIsOpenAccount] = useState<boolean>(false);

  const handleAction = (id: number) => {
    switch (id) {
      case 0:
        navigate('/definitions/customers');
        break;
      case 1:
        setCustomerAddAddressModal(true);
        break;
      case 2:
        setCustomerGetPaidModal(true);
        break;
      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('title')}
        leftIconName="Customer"
        menu={(isOpenAccount ? MENU! : MENU2!)
          .filter(item =>
            arrayKeyHasPermission(
              item?.permissionKeys || [],
              meProfile?.permissions || [],
            ),
          )
          .map(item => ({ ...item, text: t(item.text as any) }))}
        onActionClick={handleAction}
      />
      <Stack
        sx={{ flex: 1 }}
        direction={{ md: 'row', xs: 'column' }}
        spacing={3}
      >
        <Paper sx={{ flex: 3, p: 2 }} elevation={3}>
          <CustomerInfo
            customerBalance={customerBalance}
            customerBalanceFalse={() => setCustomerBalance(false)}
            onCloseOpenAccount={() => setIsOpenAccount(false)}
            onOpenAccount={() => setIsOpenAccount(true)}
          />
        </Paper>
        <Paper sx={{ flex: 5, p: 2 }} elevation={3}>
          <CustomerDetailsTable
            customerBalance={() => setCustomerBalance(true)}
          />
        </Paper>
      </Stack>

      {customer && (
        <GetPaidModal
          open={customerGetPaidModal}
          customerId={customer.id}
          customerBalance={() => setCustomerBalance(true)}
          onClose={() => {
            setCustomerGetPaidModal(false);
          }}
        />
      )}
      {customer && (
        <CustomerAddressAddModal
          open={customerAddAddressModal}
          customerId={customer.id}
          onClose={() => {
            setCustomerAddAddressModal(false);
          }}
        />
      )}
    </Box>
  );
}

export default CustomerDetailsWizard;
