/** Dependencies */
import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

/** Components */
import Modal from '../../../../../../components/Modal';
import MenuModalLeftSide from './MenuModalLeftSide/MenuModalLeftSide';
import MenuModalRightSide from './MenuModalRightSide/MenuModalRightSide';
import LoadingState from '../../../../../../components/LoadingState';

/** Types */
import {
  MenuProduct,
  Menus,
} from '../../../../../../store/slices/basketItemSlice/types';
import useBasketItems from '../../../../../../hooks/useBasketItems/useBasketItems';
import { SelectedMenuItem } from './types';
import { ActiveBasketProps } from '../../LeftColumn/BasketList/types';
import getActiveLanguage from '../../../../../../helpers/getActiveLanguage';
import { NameTranslation } from '../../../../../../models/General';

interface Props {
  open: boolean;
  onClose: () => void;
  productInProcessDetailsMenus: any;
  onDelete: () => void;
  productMenuItems: Menus[];
  basketItemId?: string;
  onSelectionMenuModal: { isSelected: boolean; id: string };
  activeBasketProps: ActiveBasketProps;
  setActiveBasketProps: React.Dispatch<React.SetStateAction<ActiveBasketProps>>;
  selectedMenuItem: MenuProduct | undefined;
  setSelectedMenuItem: React.Dispatch<
    React.SetStateAction<MenuProduct | undefined>
  >;
  onMenuOnSelectionModal: { isSelected: boolean; id: string };
  setOnMenuOnSelectionModal: (
    value:
      | ((prevState: { isSelected: boolean; id: string }) => {
          isSelected: boolean;
          id: string;
        })
      | { isSelected: boolean; id: string },
  ) => void;
  currencyCode: string;
  menuName: string;
}

function MenuModal({
  open,
  onClose,
  productInProcessDetailsMenus,
  productMenuItems,
  basketItemId,
  onDelete,
  onSelectionMenuModal,
  activeBasketProps,
  setActiveBasketProps,
  selectedMenuItem,
  setSelectedMenuItem,
  onMenuOnSelectionModal,
  setOnMenuOnSelectionModal,
  currencyCode,
  menuName,
}: Props) {
  const { t } = useTranslation('pos');
  const { enqueueSnackbar } = useSnackbar();
  const {
    addItemsForMenu,
    addItemForMenu,
    increaseCountForMenu,
    selectMenuBasketItemsForBasketItem,
    productMenus,
  } = useBasketItems();

  const [selectedItem, setSelectedItem] = useState<SelectedMenuItem>({
    selectedItem: [],
    selectedItemSelectableQuantity: 0,
    selectedMenuId: '',
  });

  const matchesLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  useEffect(() => {
    if (open) {
      addItemsForMenu(productMenuItems);
    }
  }, [open]);

  const quantityWarning = (message: string) => {
    enqueueSnackbar(message, {
      variant: 'warning',
    });
  };

  const menuItemsCountLength = (id: string, selectableQuantity: number) => {
    const arrayPrice =
      productMenus
        ?.find(item => item.id === id)
        ?.menu_items.map(menuItem => menuItem.count) || [];
    let arrayTotal = 0;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arrayPrice.length; i++) {
      arrayTotal += Number(arrayPrice[i]);
    }

    return selectableQuantity - arrayTotal;
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleAddMenuItem = (item: any, selectedItem: SelectedMenuItem) => {
    const countsLength = menuItemsCountLength(
      selectedItem.selectedMenuId,
      selectedItem.selectedItemSelectableQuantity,
    );
    if (countsLength === 0) {
      quantityWarning(t('menuModel.not_selection'));
    } else {
      addItemForMenu(item, selectedItem.selectedMenuId);
    }
  };

  const handleIncrease = (itemId: string, menuId: string) => {
    const countsLength = menuItemsCountLength(
      selectedItem.selectedMenuId,
      selectedItem.selectedItemSelectableQuantity,
    );
    if (countsLength === 0) {
      quantityWarning(t('menuModel.not_selection'));
    } else {
      increaseCountForMenu(itemId, menuId);
    }
  };

  const handleMenuSelection = () => {
    let arrayTotal = 0;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < productInProcessDetailsMenus.length; i++) {
      arrayTotal += menuItemsCountLength(
        productInProcessDetailsMenus[i].id,
        productInProcessDetailsMenus[i].selectable_quantity,
      );
    }

    if (basketItemId) {
      if (arrayTotal > 0) {
        quantityWarning(t('menuModel.missing_choice'));
      } else {
        selectMenuBasketItemsForBasketItem(basketItemId);
        onClose();
      }
    }
  };

  return (
    <Modal
      maxWidth="xl"
      fullScreen={matchesLg}
      open={open}
      // @ts-ignore
      onClose={(_: any, reason: string) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      title={menuName}
      positiveAction={t('selectionModal.buttons.confirm')}
      subtitle={t('menuModel.selections_area')}
      onPositiveClick={() => {
        handleMenuSelection();
      }}
      negativeAction={t('selectionModal.buttons.cancel')}
      onNegativeClick={() => {
        onClose();
        if (onSelectionMenuModal.isSelected) {
          onDelete();
        }
      }}
      disableEscapeKeyDown
    >
      {!productInProcessDetailsMenus && open ? (
        <LoadingState />
      ) : (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <MenuModalLeftSide
                handleIncrease={handleIncrease}
                menuNames={
                  (productInProcessDetailsMenus &&
                    productInProcessDetailsMenus.map(
                      (menuItem: {
                        translations: NameTranslation[];
                        id: string;
                      }) => ({
                        name: getActiveLanguage(menuItem.translations).name,
                        id: menuItem.id,
                      }),
                    )) ||
                  []
                }
                activeBasketProps={activeBasketProps}
                setActiveBasketProps={setActiveBasketProps}
                selectedItem={selectedMenuItem}
                setSelectedItem={setSelectedMenuItem}
                onMenuOnSelectionModal={onMenuOnSelectionModal}
                setOnMenuOnSelectionModal={setOnMenuOnSelectionModal}
                currencyCode={currencyCode}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <MenuModalRightSide
                menus={productInProcessDetailsMenus || []}
                handleAddMenuItem={handleAddMenuItem}
                menuItemsCountLength={menuItemsCountLength}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </Modal>
  );
}

export default MenuModal;
