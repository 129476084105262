/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

/** Types */
import { Control } from 'react-hook-form/dist/types/form';
import FormInput from '../../../../components/FormInput';
import { CustomerAddressBase } from '../types';

interface Props {
  control: Control<CustomerAddressBase, Object>;
}

function AddModalForm({ control }: Props) {
  const { t } = useTranslation('customerAddress');

  return (
    <>
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <FormInput
            label={t('customerAddress.addModal.form.name.label')}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            {...field}
          />
        )}
      />
      <Controller
        name="address"
        control={control}
        render={({ field, fieldState }) => (
          <FormInput
            label={t('customerAddress.addModal.form.address.label')}
            multiline
            rows={5}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            {...field}
          />
        )}
      />
      <Controller
        name="note"
        control={control}
        render={({ field, fieldState }) => (
          <FormInput
            label={t('customerAddress.addModal.form.note.label')}
            multiline
            rows={5}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            {...field}
          />
        )}
      />
    </>
  );
}

export default AddModalForm;
