import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getKitchenGroupListThunk } from './thunks';

import { InitialState } from './types';
import { IdAndName } from '../../../../models/General';

const initialState: InitialState = { loading: false, kitchenGroups: [] };

const kitchenGroupsSlice = createSlice({
  name: 'kitchenGroupsSlice',
  initialState,
  reducers: {
    addKitchenGroupSlice: (state, action: PayloadAction<IdAndName>) => ({
      ...state,
      kitchenGroups: [...state.kitchenGroups, action.payload],
    }),
    updateKitchenGroupSlice: (state, action: PayloadAction<IdAndName>) => ({
      ...state,
      kitchenGroups: state.kitchenGroups.map(item =>
        item.id === action.payload.id ? action.payload : item,
      ),
    }),
    removeKitchenGroupSlice: (
      state,
      action: PayloadAction<Pick<IdAndName, 'id'>>,
    ) => ({
      ...state,
      kitchenGroups: state.kitchenGroups.filter(
        item => item.id !== action.payload.id,
      ),
    }),
  },
  extraReducers: builder => {
    builder
      .addCase(getKitchenGroupListThunk.pending, state => ({
        ...state,
        loading: true,
      }))
      .addCase(getKitchenGroupListThunk.fulfilled, (state, action) => ({
        ...state,
        kitchenGroups: action.payload,
        loading: false,
      }))
      .addCase(getKitchenGroupListThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }));
  },
});

export const {
  addKitchenGroupSlice,
  updateKitchenGroupSlice,
  removeKitchenGroupSlice,
} = kitchenGroupsSlice.actions;

export default kitchenGroupsSlice.reducer;
