import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { isApiError } from '../../services/api/types';
import { useAppDispatch, useAppSelector } from '../../store';
import { getKitchenGroupListThunk } from '../../store/slices/definitionsSlice/kitchenGroups';
import api from '../../services/api';
import {
  addKitchenGroupSlice,
  removeKitchenGroupSlice,
  updateKitchenGroupSlice,
} from '../../store/slices/definitionsSlice/kitchenGroups/kitchenGroupsSlice';

const useKitchenGroups = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const kitchenGroups = useAppSelector(
    state => state.definitionsKitchenGroups.kitchenGroups,
  );
  const kitchenGroupLoading = useAppSelector(
    state => state.definitionsKitchenGroups.loading,
  );

  const kitchenGroupError = useAppSelector(
    state => state.definitionsKitchenGroups.error,
  );

  const fetchKitchenGroups = () => {
    try {
      dispatch(getKitchenGroupListThunk());
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      if (err.code === 1003) {
        navigate('/');
      }
      throw err;
    }
  };

  const addKitchenGroups = async (name: string) => {
    try {
      const response = await api.definitions.kitchenGroups.create(name);
      const result = response.data.data;
      dispatch(addKitchenGroupSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const updateKitchenGroups = async ({
    id,
    name,
  }: {
    id: string;
    name: string;
  }) => {
    try {
      const response = await api.definitions.kitchenGroups.update({ id, name });
      const result = response.data.data;
      dispatch(updateKitchenGroupSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const removeKitchenGroups = async (id: string) => {
    try {
      const response = await api.definitions.kitchenGroups.remove(id);
      const result = response.data.data;
      dispatch(removeKitchenGroupSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  return {
    kitchenGroups,
    kitchenGroupLoading,
    kitchenGroupError,
    fetchKitchenGroups,
    addKitchenGroups,
    updateKitchenGroups,
    removeKitchenGroups,
  };
};
export default useKitchenGroups;
