import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import Modal from '../../../../components/Modal';
import generalStyles from '../../../../assets/stylesheets/generalStyles';
import { singlePriceFormatter } from '../../../../helpers/moneyFormatter';
import { PosTransaction } from '../../../../models/Pos';
import { GroupedDataColumnType } from '../../../../services/api/orders/types';

interface Props {
  open: boolean;
  onClose: () => void;
  isShowLastOrders: string;
  item?: GroupedDataColumnType;
  lastOrderTransactions: PosTransaction[] | null;
}

function LastTransactionsModal({
  open,
  onClose,
  isShowLastOrders,
  item,
  lastOrderTransactions,
}: Props) {
  const { t } = useTranslation('orders');
  const handleClose = () => {
    onClose();
  };

  const totalAmountCalculator = (
    lastOrders: PosTransaction[],
    currency: string,
  ) => {
    let totalPrice = 0;

    const itemsTotalPrice = lastOrders?.map(item2 =>
      Number(item2.total_amount.amount),
    );

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < itemsTotalPrice?.length; i++) {
      totalPrice += Number(itemsTotalPrice[i]);
    }

    return (
      singlePriceFormatter(totalPrice.toString(), 1, currency || 'TRY') ||
      '₺0,00'
    );
  };

  const totalDiscountCalculator = (
    lastOrders: PosTransaction[],
    currency: string,
  ) => {
    let totalPrice = 0;

    const itemsTotalPrice = lastOrders?.map(item2 =>
      Number(item2.total_discount.amount),
    );

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < itemsTotalPrice?.length; i++) {
      totalPrice += Number(itemsTotalPrice[i]);
    }

    return (
      singlePriceFormatter(totalPrice.toString(), 1, currency || 'TRY') ||
      '₺0,00'
    );
  };

  return (
    <Modal
      maxWidth="sm"
      open={open}
      onClose={() => handleClose()}
      title={t('order.past_transactions_modal.title')}
      negativeAction={t('order.deliverer_modal.close')}
      onNegativeClick={() => handleClose()}
    >
      {item && (
        <Grid>
          {item.customer && isShowLastOrders === item.id && (
            <Grid mb={2} mt={2} container justifyContent="space-between">
              <Grid
                sx={{
                  ...generalStyles.generalDisplay,
                  fontSize: '15px',
                  border: '1px solid #f3f3f3',
                  textAlign: 'center',
                  borderRadius: '5px',
                }}
                p={2}
                item
              >
                {lastOrderTransactions?.length || 0}
                <br />
                {t('order.total')}
                <br />
                {t('order.order_count')}
              </Grid>
              <Grid
                sx={{
                  ...generalStyles.generalDisplay,
                  fontSize: '15px',
                  textAlign: 'center',
                  border: '1px solid #f3f3f3',
                  borderRadius: '5px',
                }}
                p={2}
                item
              >
                {lastOrderTransactions &&
                  totalAmountCalculator(
                    lastOrderTransactions,
                    item.total_amount.currency,
                  )}
                <br />
                {t('order.total')}
                <br />
                {t('order.order_amount')}
              </Grid>
              <Grid
                sx={{
                  ...generalStyles.generalDisplay,
                  fontSize: '15px',
                  textAlign: 'center',
                  border: '1px solid #f3f3f3',
                  borderRadius: '5px',
                }}
                p={2}
                item
              >
                {lastOrderTransactions &&
                  totalDiscountCalculator(
                    lastOrderTransactions,
                    item.total_amount.currency,
                  )}
                <br />
                {t('order.total')}
                <br />
                {t('order.discount_amount')}
              </Grid>
            </Grid>
          )}
          {item.customer && lastOrderTransactions?.length === 0 && (
            <Grid fontSize={15}>{t('order.no_past_transaction')}</Grid>
          )}
          {!item.customer && isShowLastOrders === item.id && (
            <Grid fontSize={15}>{t('order.no_customer')}</Grid>
          )}
        </Grid>
      )}
    </Modal>
  );
}

export default LastTransactionsModal;
