/** Dependencies */
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

/** Types */
import {
  IngredientGroupItems,
  MenuItems,
  PropertyGroupItems,
  PropertyName,
  Unit,
} from '../../../../../../../models/Product';
import { ActiveBasketProps } from '../types';
import {
  MenuProduct,
  Menus,
} from '../../../../../../../store/slices/basketItemSlice/types';

/** Styles */
import generalStyles from '../../../../../../../assets/stylesheets/generalStyles';

/** Components */
import OnSelectionModal from '../../../Modals/OnSelectionModal';
import MenuModal from '../../../Modals/MenuModal';
import ConfirmationModal from '../../../../../../../components/ConfirmationModal';
import ProductItemCounter from './Components/ProductItemCounter';
import ProductItemNameAndPropertyInfo from './Components/ProductItemNameAndPropertyInfo';
import ProductItemPriceInfo from './Components/ProductItemPriceInfo';
import ProductItemButtons from './Components/ProductItemButtons';
import ProductItemMenu from './Components/ProductItemMenu';

/** Hooks */
import useProducts from '../../../../../../../hooks/useProducts';
import usePosOrder from '../../../../../../../hooks/usePosOrder';
import useProfile from '../../../../../../../hooks/useProfile';

/** Helpers */
import keyHasPermission from '../../../../../../../helpers/keyHasPermission';
import GiftModal from '../../../GiftModal';
import useBasketItems from '../../../../../../../hooks/useBasketItems/useBasketItems';
import ProductNoteModal from '../../../ProductNoteModal';
import RemoveItemModal from '../../../RemoveItemModal';

interface Props {
  activeBasketProps: ActiveBasketProps;
  setActiveBasketProps: React.Dispatch<React.SetStateAction<ActiveBasketProps>>;
  name: string;
  unit?: Unit | null;
  units?: Unit[];
  count: number;
  onIncrease: () => void;
  onDecrease: () => void;
  onDelete: () => void;
  onAction: () => void;
  onSelection: () => void;
  createDate: string | Date | undefined;
  isSubmittedOrder: boolean;
  itemId?: string;
  selectedItemId?: string;
  singlePropertyId: string;
  multiplePropertyId: string[];
  nonIngredientId: string[];
  propertyNames: PropertyName[];
  singlePropertyName: PropertyName;
  propertyGroupItems?: PropertyGroupItems[];
  ingredientGroupItems?: IngredientGroupItems[];
  onSelectionModal: { isSelected: boolean; id: string };
  setOnSelectionModal: React.Dispatch<
    React.SetStateAction<{ isSelected: boolean; id: string }>
  >;
  onSelectionMenuModal: { isSelected: boolean; id: string };
  setOnSelectionMenuModal: React.Dispatch<
    React.SetStateAction<{ isSelected: boolean; id: string }>
  >;
  requiredSelection: boolean;
  totalPrice?: number;
  isMenu: boolean;
  productMenus: Menus[];
  menuItems: MenuItems[];
  isActiveProperties: boolean;
  isActiveIngredients: boolean;
  currencyCode: string;
  ingredientGroups: any[];
  onRemove: (removedPrice: number, orderId: string) => void;
  isAvailableToOrder: boolean;
  isTreat: boolean;
  onTreat: () => void;
  description?: string;
  createdName: string;
}

function BasketProductItem({
  activeBasketProps,
  setActiveBasketProps,
  name,
  unit,
  units,
  count,
  onIncrease,
  onDecrease,
  onDelete,
  onAction,
  onSelection,
  createDate,
  isSubmittedOrder,
  itemId,
  selectedItemId,
  singlePropertyId,
  multiplePropertyId,
  propertyNames,
  singlePropertyName,
  propertyGroupItems,
  nonIngredientId,
  onSelectionModal,
  setOnSelectionModal,
  requiredSelection,
  totalPrice,
  isMenu,
  productMenus,
  menuItems,
  onSelectionMenuModal,
  setOnSelectionMenuModal,
  isActiveProperties,
  isActiveIngredients,
  currencyCode,
  ingredientGroups,
  ingredientGroupItems,
  onRemove,
  isAvailableToOrder,
  isTreat,
  onTreat,
  description,
  createdName,
}: Props) {
  const {
    setProductInProgress,
    resetProductInProgress,
    productInProcessDetails,
  } = useProducts();
  const { setOrderGift, removeOrderGift } = usePosOrder();
  const { meProfile } = useProfile();
  const { t } = useTranslation('pos');
  const { removeOrderItem } = usePosOrder();
  const { enqueueSnackbar } = useSnackbar();
  const { addItemTreatForBasket, removeItemTreatForBasket } = useBasketItems();
  const [openNote, setOpenNote] = useState(false);

  const [onSelectionModalForBasket, setOnSelectionModalForBasket] = useState({
    isSelected: false,
    id: '',
  });
  const [onMenuModalForBasket, setOnSelectionMenuModalForBasket] = useState({
    isSelected: false,
    id: '',
  });

  const [onMenuOnSelectionModal, setOnMenuOnSelectionModal] = useState({
    isSelected: false,
    id: '',
  });

  const [selectedItem, setSelectedItem] = useState<MenuProduct>();
  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(
    null,
  );
  const [deleteConfirmationForTreat, setDeleteConfirmationForTreat] = useState<
    string | null
  >(null);
  const [openGiftModal, setOpenGiftModal] = useState(false);
  const [openRemoveItemModal, setOpenRemoveItemModal] = useState(false);
  const [treatType, setTreatType] = useState('');
  const [noteType, setNoteType] = useState('order');
  const [orderDescription, setOrderDescription] = useState('');
  const [menuItemId, setMenuItemId] = useState('');

  const handleRemove = async (selectedCount: number) => {
    if (itemId && isAvailableToOrder) {
      await removeOrderItem(itemId, selectedCount);
      onRemove(totalPrice || 0, itemId);

      setOpenRemoveItemModal(false);
    } else {
      enqueueSnackbar(t('orderModal.statusSubmitError'), {
        variant: 'warning',
      });
    }
  };

  const handleUnTreat = async (selectedCount: number) => {
    if (itemId) {
      if (isSubmittedOrder) {
        await removeOrderGift(itemId, selectedCount);
        onTreat();
      } else {
        removeItemTreatForBasket(selectedItemId || itemId || '', selectedCount);
      }
    }
  };

  const handleTreatOrder = async (selectedCount: number, type: string) => {
    if (type === 'treat') {
      if (itemId) {
        if (isSubmittedOrder) {
          await setOrderGift(itemId, selectedCount);
          onTreat();
        } else {
          addItemTreatForBasket(selectedItemId || itemId, selectedCount);
        }

        setOpenGiftModal(false);
        setTreatType('');
      }
    } else {
      handleUnTreat(selectedCount);
    }
  };

  const handleOnMenuSelection = (item: MenuProduct) => {
    if (item.property_groups.length > 0 || item.ingredient_groups.length > 0) {
      setSelectedItem(item);
      setActiveBasketProps({
        activeIngredientIds: item.non_ingredient_item_id || [],
        activeUnitId: '',
        activeSinglePropertyId: item.active_single_property_id || '',
        activeMultiplePropertyIds: item.active_multiple_property_id || [],
        activeSinglePropertyName: item.active_single_property_name || {
          name: '',
          price: 0,
        },
        activeMultiplePropertyNames: item.active_multiple_property_names || [],
      });
      setOnSelectionMenuModalForBasket({
        isSelected: true,
        id: selectedItemId || '',
      });
      setOnMenuOnSelectionModal({
        ...onMenuOnSelectionModal,
        isSelected: true,
      });
    }
  };

  const handleOnSelection = () => {
    if (isMenu) {
      setOnSelectionMenuModalForBasket({
        isSelected: true,
        id: selectedItemId || '',
      });
    } else {
      setOnSelectionModalForBasket({
        isSelected: true,
        id: selectedItemId || '',
      });
    }
  };

  useEffect(() => {
    if (
      (selectedItemId === onSelectionModal.id && onSelectionModal.isSelected) ||
      (selectedItemId === onSelectionModalForBasket.id &&
        onSelectionModalForBasket.isSelected) ||
      onMenuModalForBasket.isSelected ||
      (selectedItemId === onSelectionMenuModal.id &&
        onSelectionMenuModal.isSelected) ||
      (selectedItemId === onMenuModalForBasket.id &&
        onMenuModalForBasket.isSelected) ||
      onMenuModalForBasket.isSelected
    ) {
      setProductInProgress(itemId || '');
    }
    return () => {
      resetProductInProgress();
    };
  }, [
    onSelectionModal,
    onSelectionModalForBasket,
    onSelectionMenuModal,
    onMenuModalForBasket,
  ]);

  const hasShowDeleteButton = keyHasPermission(
    'remove-product-from-order',
    meProfile?.permissions || [],
  );

  return (
    <Box>
      <Box>
        <Box sx={generalStyles.date}>
          {createDate && isSubmittedOrder
            ? format(new Date(createDate.toString()), 'HH:mm')
            : ''}
        </Box>
        <Stack direction="row" sx={!isSubmittedOrder ? { color: 'red' } : {}}>
          <ProductItemCounter
            isSubmittedOrder={isSubmittedOrder}
            count={count}
            onIncrease={onIncrease}
            onDecrease={onDecrease}
            onDelete={onDelete}
          />

          <Stack
            sx={generalStyles.generalFlex}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              !isSubmittedOrder &&
                !isMenu &&
                ((units && units?.length > 1) ||
                  productInProcessDetails?.property_groups ||
                  productInProcessDetails?.ingredient_groups) &&
                handleOnSelection();
            }}
          >
            <ProductItemNameAndPropertyInfo
              isSubmittedOrder={isSubmittedOrder}
              name={name}
              isMenu={isMenu}
              singlePropertyName={singlePropertyName}
              propertyNames={propertyNames}
              nonIngredientId={nonIngredientId}
              ingredientGroups={ingredientGroups}
              propertyGroupItems={propertyGroupItems}
              ingredientGroupItems={ingredientGroupItems}
              unit={unit}
              createdName={createdName}
            />

            <Box sx={generalStyles.generalDisplay}>
              <ProductItemPriceInfo
                isSubmittedOrder={isSubmittedOrder}
                count={count}
                currencyCode={currencyCode}
                totalPrice={totalPrice}
                unit={unit}
                isTreat={isTreat}
              />

              <ProductItemButtons
                isSubmittedOrder={isSubmittedOrder}
                isActiveProperties={isActiveProperties}
                isActiveIngredients={isActiveIngredients}
                isMenu={isMenu}
                handleOnSelection={handleOnSelection}
                setOnSelectionMenuModalForBasket={
                  setOnSelectionMenuModalForBasket
                }
                onDelete={onDelete}
                hasShowDeleteButton={hasShowDeleteButton}
                setDeleteConfirmation={setDeleteConfirmation}
                setOpenRemoveItemModal={setOpenRemoveItemModal}
                itemId={itemId}
                selectedItemId={selectedItemId}
                onGiftSelect={(type: string) => {
                  setOpenGiftModal(true);
                  setTreatType(type);
                  setOpenNote(false);
                }}
                isTreat={isTreat}
                totalPrice={totalPrice}
                currencyCode={currencyCode}
                unit={unit}
                count={count}
                treatText={t('basketList.treat')}
                setOpenNote={setOpenNote}
                setNoteType={setNoteType}
              />
            </Box>
          </Stack>
        </Stack>
      </Box>
      {description && (
        <Box
          sx={{
            mt: 1,
            fontSize: '13px',
            color: !isSubmittedOrder ? '#FF0000' : '',
          }}
        >
          {t('note')}: {description}
        </Box>
      )}
      {isMenu && (
        <ProductItemMenu
          isSubmittedOrder={isSubmittedOrder}
          productMenus={productMenus}
          handleOnMenuSelection={handleOnMenuSelection}
          onAction={onAction}
          menuItems={menuItems}
          setNoteType={setNoteType}
          setOpenNote={setOpenNote}
          setDescription={setOrderDescription}
          setMenuItemId={setMenuItemId}
        />
      )}

      {selectedItemId ===
        (onSelectionModal.id || onSelectionModalForBasket.id) && (
        <OnSelectionModal
          key={itemId}
          name={name}
          open={
            onSelectionModalForBasket.isSelected || onSelectionModal.isSelected
          }
          requiredSelection={requiredSelection}
          productInProcessDetails={productInProcessDetails}
          // Functions
          onSelection={onSelection}
          onDelete={onDelete}
          onClose={() => {
            setOnSelectionModal({ isSelected: false, id: '' });
            setOnSelectionModalForBasket({ isSelected: false, id: '' });
          }}
          // Units
          unit={unit || null}
          units={units || []}
          // Properties
          propertyNames={propertyNames}
          singlePropertyId={singlePropertyId}
          singlePropertyName={singlePropertyName}
          multiplePropertyId={multiplePropertyId}
          // Ingredients
          nonIngredientId={nonIngredientId}
          // basket
          activeBasketProps={activeBasketProps}
          setActiveBasketProps={setActiveBasketProps}
        />
      )}

      {selectedItemId ===
        (onSelectionMenuModal.id || onMenuModalForBasket.id) && (
        <MenuModal
          open={
            onMenuModalForBasket.isSelected || onSelectionMenuModal.isSelected
          }
          onClose={() => {
            setOnSelectionMenuModal({ isSelected: false, id: '' });
            setOnSelectionMenuModalForBasket({ isSelected: false, id: '' });
          }}
          basketItemId={selectedItemId}
          productInProcessDetailsMenus={productInProcessDetails?.menus}
          onDelete={onDelete}
          productMenuItems={productMenus}
          onSelectionMenuModal={onSelectionMenuModal}
          activeBasketProps={activeBasketProps}
          setActiveBasketProps={setActiveBasketProps}
          selectedMenuItem={selectedItem}
          setSelectedMenuItem={setSelectedItem}
          onMenuOnSelectionModal={onMenuOnSelectionModal}
          setOnMenuOnSelectionModal={setOnMenuOnSelectionModal}
          currencyCode={currencyCode}
          menuName={`${name} (${unit?.amount ? unit?.amount?.formatted : ''})`}
        />
      )}
      <GiftModal
        open={openGiftModal}
        onClose={() => {
          setOpenGiftModal(false);
          setTreatType('');
        }}
        type={treatType}
        totalCount={count}
        handleTreatOrder={handleTreatOrder}
      />
      <RemoveItemModal
        open={openRemoveItemModal}
        onClose={() => {
          setOpenRemoveItemModal(false);
        }}
        totalCount={count}
        handleRemove={handleRemove}
      />
      <ProductNoteModal
        open={openNote}
        onClose={() => setOpenNote(false)}
        originalDescription={
          noteType === 'menuOrder' ? orderDescription : description || ''
        }
        itemId={selectedItemId || ''}
        onGiftSelect={() => {
          setOpenGiftModal(true);
          setTreatType('treat');
          setOpenNote(false);
        }}
        isTreat={isTreat}
        type={noteType}
        menuItemId={menuItemId}
      />
    </Box>
  );
}

export default BasketProductItem;
