import { Chip, CircularProgress, Grid } from '@mui/material';
import React from 'react';
import Icon from '../../../../../components/Icon';
import getActiveLanguage from '../../../../../helpers/getActiveLanguage';
import { PropertyItemWithActive } from '../../../../../services/api/definitions/propertyGroups/types';

interface Props {
  list: PropertyItemWithActive[];
  loadingItemID: string | null;
  onItemEdit: (item: PropertyItemWithActive) => void;
  onItemClick: (item: PropertyItemWithActive) => void;
}
function PropertyItemList({
  list,
  loadingItemID,
  onItemEdit,
  onItemClick,
}: Props) {
  return (
    <Grid container spacing={2}>
      {list.map(item => (
        <Grid item key={item.id}>
          <Chip
            icon={
              item.id === loadingItemID ? (
                <CircularProgress size={14} />
              ) : undefined
            }
            label={`${getActiveLanguage(item.translations).name} ${
              item.additional_price.formatted
            }`}
            onClick={() => onItemClick(item)}
            deleteIcon={<Icon name="Edit" />}
            onDelete={() => onItemEdit(item)}
            color={item.is_active ? 'info' : 'default'}
            variant={item.is_active ? 'filled' : 'outlined'}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default PropertyItemList;
