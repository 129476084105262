import api from '../../api';
import { ApiSuccessResponse } from '../../types';
import {
  CreateResponse,
  ListResponse,
  ViewResponse,
  DeleteResponse,
  SetDefaultResponse,
} from './types';
import { CustomerAddressBase } from '../../../../pages/definitions/CustomerDetails/types';

type ListRes = ApiSuccessResponse<ListResponse>;
const list = (customer_id: string) =>
  api.get<ListRes>(`/customers/${customer_id}/addresses`);

type ViewRes = ApiSuccessResponse<ViewResponse>;
const view = (address_id: string, customer_id: string) =>
  api.get<ViewRes>(`/customers/${address_id}/addresses/${customer_id}`);

type CreatRes = ApiSuccessResponse<CreateResponse>;
const create = (params: CustomerAddressBase) =>
  api.post<CreatRes>(`/customers/${params.id}/addresses`, params);

type SetDefaultRes = ApiSuccessResponse<SetDefaultResponse>;
const setDefault = (address_id: string, customer_id: string) =>
  api.post<SetDefaultRes>(
    `/customers/${customer_id}/set-default-address/${address_id}`,
  );

type DeleteRes = ApiSuccessResponse<DeleteResponse>;
const remove = (address_id: string, customer_id: string) =>
  api.delete<DeleteRes>(`/customers/${address_id}/addresses/${customer_id}`);

export default { list, view, create, remove, setDefault };
