import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../services/api';
import arrayCatcher from '../../../../helpers/errorCatcher';
import {
  getFilterBranchSlice,
  getQrMenuTemplatesSlice,
} from './restaurantSettingsSlice';
import { QrMenuUpdate } from '../../../../services/api/settings/restauranSettings/types';

export const getBranchSettingsThunk = createAsyncThunk(
  'generalSettings/get',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.settings.generalSettings.view(id);
      return response.data.data;
    } catch (err: any) {
      arrayCatcher(err?.code);
      return rejectWithValue(err);
    }
  },
);

export const getCouvertThunk = createAsyncThunk(
  'couvert/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.settings.generalSettings.getCouvert();
      return response.data.data;
    } catch (err: any) {
      arrayCatcher(err?.code);
      return rejectWithValue(err);
    }
  },
);

export const getServiceChargeThunk = createAsyncThunk(
  'service-charge/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.settings.generalSettings.getServiceCharge();
      return response.data.data;
    } catch (err: any) {
      arrayCatcher(err?.code);
      return rejectWithValue(err);
    }
  },
);

export const getChannelSalesThunk = createAsyncThunk(
  'channelSales/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.settings.generalSettings.getChannelSales();
      return response.data.data;
    } catch (err: any) {
      arrayCatcher(err?.code);
      return rejectWithValue(err);
    }
  },
);

export const getBranchFilterSettingsThunk = createAsyncThunk(
  'generalSettingsIsOpen/get',
  async (is_pos: boolean, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.settings.generalSettings.viewIsOpen(is_pos);
      const result = response.data.data;
      dispatch(getFilterBranchSlice(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getQrMenuTemplatesThunk = createAsyncThunk(
  'qr-menu-templates',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.settings.generalSettings.qrMenuTemplates();
      const result = response.data.data;
      dispatch(getQrMenuTemplatesSlice(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const setQrMenuTemplatesThunk = createAsyncThunk(
  'qr-menu-templates',
  async (params: { id: string; params: QrMenuUpdate }, { rejectWithValue }) => {
    try {
      const response = await api.settings.generalSettings.setQrMenuTemplates(
        params.id,
        params.params,
      );
      const result = response.data.data;
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
