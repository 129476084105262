import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import 'yup-phone';

export type LoginFormValues = {
  email: string;
  password: string;
};

export type LoginByPinFormValues = {
  pin: string;
  branch_id: string;
};

const schema: yup.SchemaOf<LoginFormValues> = yup.object({
  email: yup.string().required('login.email.required'),
  password: yup
    .string()
    .required('login.password.required')
    .min(8, 'login.password.min'),
});

export const validationSchema = yupResolver(schema);
