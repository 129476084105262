/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

/** Styles */
import { Box, Grid, Paper, useTheme } from '@mui/material';

/** Components */
import UtilitiesBar from '../../components/UtilitiesBar';
import useBranches from '../../hooks/useBranches';
import { EmptySvg } from '../../components/svg';
import DetailModal from './DetailModal';
import { BranchApps } from '../../services/api/utilities/types';

function Apps() {
  const { t } = useTranslation('apps');
  const theme = useTheme();
  const { getApps, branchApps } = useBranches();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [slug, setSlug] = useState('');
  const [images, setImages] = useState<string[]>(['']);

  const data: BranchApps[] = branchApps;
  const groupedData: Record<string, BranchApps[]> = {};

  data.forEach(item => {
    const groupKey = item.category;

    if (!groupedData[groupKey]) {
      groupedData[groupKey] = [];
    }

    groupedData[groupKey].push(item);
  });

  // eslint-disable-next-line react/function-component-definition,react/no-unstable-nested-components
  const GroupComponent: React.FC<GroupComponentProps> = ({ groupData }) => (
    <>
      <Grid container>
        <Grid sx={{ fontSize: '20px', fontWeight: '500', color: '#162a4ce6' }}>
          {groupData[0].category}
        </Grid>
        <span
          style={{
            display: 'inline-block',
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            backgroundColor: 'rgb(39,140,61)',
            color: 'white',
            textAlign: 'center',
            lineHeight: '20px',
            fontSize: '14px',
            marginLeft: '7px',
            marginTop: '5px',
          }}
        >
          {groupData.length}
        </span>
      </Grid>
      <br />
      <Grid container spacing={2}>
        {groupData.map(item => (
          <Grid
            sx={{
              cursor:
                item?.subscription_info?.is_installed && !item?.redirect_url
                  ? ''
                  : 'pointer',
            }}
            onClick={() => {
              if (!item?.subscription_info?.is_installed) {
                setDescription(item.description);
                setRedirectUrl(item.redirect_url);
                setSlug(item.slug);
                setImages(item.screenshot_urls);
                setOpen(true);
              } else if (item.redirect_url) {
                navigate(`${item.redirect_url}`);
              }
            }}
            key={item.id}
            item
            xs={12}
            sm={6}
            md={3}
            lg={2}
          >
            <Grid
              sx={{
                padding: 2,
                border: '1px solid',
                borderRadius: 1,
                backgroundColor: 'white',
                borderColor: theme.palette.divider,
                '& pre': {
                  m: 0,
                  p: '16px !important',
                  fontFamily: theme.typography.fontFamily,
                  fontSize: '0.75rem',
                },
                height: '100%',
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={3}
                  sm={4}
                  md={5}
                  lg={4}
                  alignItems="center"
                  display="flex"
                  style={{ width: '68px', height: '68px' }}
                >
                  {item.logo_url ? (
                    <img
                      style={{ width: '100%', height: '100%' }}
                      src={item.logo_url}
                      alt=""
                    />
                  ) : (
                    <EmptySvg />
                  )}
                </Grid>
                <Grid item xs={9} sm={8} md={7} lg={8}>
                  <Grid container>
                    <Grid
                      sx={{ fontSize: '13px', fontWeight: '500' }}
                      xs={12}
                      item
                    >
                      <Grid>{item.name}</Grid>
                    </Grid>
                    <Grid alignItems="flex-start" xs={12} item sx={{ mb: 1 }}>
                      <Grid
                        sx={{
                          textAlign: 'center',
                          display: 'inline-block',
                          alignItems: 'center',
                          fontSize: '11px',
                          lineHeight: '14px',
                          backgroundColor: item?.subscription_info?.is_installed
                            ? '#162a4c80'
                            : 'rgb(39,140,61)',
                          backgroundSize: 'auto 100%',
                          color: 'white',
                          borderRadius: '10px',
                          padding: '1px 5px',
                        }}
                      >
                        <Grid>
                          {item?.subscription_info?.is_installed
                            ? t('installed')
                            : t('install')}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <br />
    </>
  );

  // eslint-disable-next-line react/function-component-definition,react/no-unstable-nested-components
  const GroupedComponent: React.FC = () => (
    <div>
      <Paper sx={{ p: 3, flex: 1 }} elevation={3}>
        {Object.keys(groupedData).map(groupKey => (
          <GroupComponent key={groupKey} groupData={groupedData[groupKey]} />
        ))}
      </Paper>
    </div>
  );

  interface GroupComponentProps {
    groupData: BranchApps[];
  }

  useEffect(() => {
    getApps();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('apps')}
        leftIconName="Apps"
      />
      <GroupedComponent />
      <DetailModal
        open={open}
        onClose={() => setOpen(false)}
        images={images}
        description={description}
        slug={slug}
        redirectUrl={redirectUrl}
      />
    </Box>
  );
}

export default Apps;
