/** Dependencies */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPrinterListThunk, getPrinterThunk } from './thunks';

/** Types */
import { InitialState } from './types';
import { Printer } from '../../../../services/api/settings/printerSettings/types';

const initialState: InitialState = {
  printer: null,
  loading: false,
  printers: [],
  meta: [],
};

const printerSlice = createSlice({
  name: 'printerSlice',
  initialState,
  reducers: {
    addPrinterSlice: (state, action: PayloadAction<Printer>) => ({
      ...state,
      printers: [...state.printers, action.payload],
    }),
    updatePrinterSlice: (state, action: PayloadAction<Printer>) => ({
      ...state,
      printers: state.printers.map(item =>
        item.id === action.payload.id ? action.payload : item,
      ),
    }),
    removePrinterSlice: (
      state,
      action: PayloadAction<Pick<Printer, 'id'>>,
    ) => ({
      ...state,
      printers: state.printers.filter(item => item.id !== action.payload.id),
    }),
  },
  extraReducers: builder => {
    builder
      .addCase(getPrinterListThunk.pending, state => ({
        ...state,
        loading: true,
      }))
      .addCase(getPrinterListThunk.fulfilled, (state, action) => ({
        ...state,
        printers: action.payload.data,
        meta: action.payload.meta,
        loading: false,
      }))
      .addCase(getPrinterListThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }))
      .addCase(getPrinterThunk.pending, state => ({
        ...state,
        loading: true,
      }))
      .addCase(getPrinterThunk.fulfilled, (state, action) => ({
        ...state,
        printer: action.payload,
        loading: false,
      }))
      .addCase(getPrinterThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }));
  },
});

export const { addPrinterSlice, updatePrinterSlice, removePrinterSlice } =
  printerSlice.actions;

export default printerSlice.reducer;
