/** Dependencies */
import React, { useEffect, useMemo, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

/** Components */
import Modal from '../../../../components/Modal';
import UserForm from './UserForm';

/** Types */
import {
  addValidationSchema,
  OptionType,
  updateValidationSchema,
  UserBase,
} from '../types';
import { isApiError } from '../../../../services/api/types';

/** Hooks */
import useUsers from '../../../../hooks/useUsers';
import useRoles from '../../../../hooks/useRoles';
import useSections from '../../../../hooks/useSections';
import useBranches from '../../../../hooks/useBranches';
import useSettings from '../../../../hooks/useSettings';

interface Props {
  open: boolean;
  onClose: () => void;
  item: UserBase | null;
}

function UserAddModal({ open, onClose, item }: Props) {
  const { t, i18n } = useTranslation('user');
  const { addUser, updateUser } = useUsers();
  const { enqueueSnackbar } = useSnackbar();

  const { roles, fetchRoles } = useRoles();
  const { meProfile, fetchMe } = useSettings();
  const { sections, fetchSections } = useSections();
  const { fetchBranches } = useBranches();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [info, setInfo] = useState<any>(null);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleChange = (newValue: any, info: any) => {
    setValue(newValue);
    setInfo(info);
  };

  const OptionsMemo = (optionName: any[]) =>
    useMemo(
      () =>
        optionName.map((option: OptionType) => ({
          id: option.id,
          text: option.name.toLocaleUpperCase(
            i18n?.language === 'en' ? 'en-EN' : 'tr-TR',
          ),
        })),
      [optionName],
    );

  const selectedRoleId = roles[0]?.id;
  const initialValues = {
    first_name: item?.first_name || '',
    last_name: item?.last_name || '',
    phone_number: item?.phone_number || '',
    email: item?.email || '',
    password: item?.password,
    pin: item?.pin,
    role_id:
      item?.branches?.find(branch => branch.id === meProfile?.activeBranch?.id)
        ?.role.id ||
      selectedRoleId ||
      '',
    section_ids:
      item?.sections?.map(section => section.id) || [sections[0]?.id] || [],
    is_caller_id_user: item?.is_caller_id_user || false,
  };
  const { handleSubmit, control, reset } = useForm<UserBase>({
    defaultValues: initialValues,
    resolver: item ? updateValidationSchema : addValidationSchema,
  });

  useEffect(() => {
    if (open) {
      fetchSections();
      fetchRoles();
      fetchBranches();
    }

    reset(initialValues);
    if (item) {
      setValue(item.phone_number);
    } else {
      setValue('');
    }
  }, [open]);
  useEffect(() => {
    reset(initialValues);
  }, [selectedRoleId]);

  const handleConfirm = () => {
    try {
      setLoading(true);
      handleSubmit(async values => {
        try {
          if (item) {
            const result = await updateUser({
              ...values,
              userId: item.id,
              phone_number_country:
                info?.countryCode || item.phone_number_country,
              phone_number:
                value.replace(/\s/g, '') ||
                info?.nationalNumber ||
                item.phone_number,
            });

            unwrapResult(result);
          } else {
            const result = await addUser({
              ...values,
              password: values.password || null,
              phone_number_country: info?.countryCode,
              phone_number: info?.nationalNumber,
            });
            unwrapResult(result);
          }

          if (
            meProfile?.installed_apps.find(app => app.slug === 'caller-id') &&
            values.email === meProfile?.email
          ) {
            fetchMe();
          }

          enqueueSnackbar(t('user.success'), {
            variant: 'success',
            style: { whiteSpace: 'pre-line' },
          });
          onClose();
        } catch (err) {
          enqueueSnackbar(
            isApiError(err) ? err.message : t('common:unknownError' as any),
            {
              variant: 'error',
              style: { whiteSpace: 'pre-line' },
            },
          );
          throw err;
        }
      })();
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={item ? t('user.editModal.title') : t('user.addModal.title')}
      titleIconName={item ? 'Edit' : 'Add'}
      open={open}
      onClose={onClose}
      loading={loading}
      negativeAction={t('user.addModal.cancel')}
      positiveAction={item ? t('user.addModal.edit') : t('user.addModal.add')}
      onPositiveClick={handleConfirm}
      onNegativeClick={onClose}
    >
      <UserForm
        control={control}
        roleOptions={OptionsMemo(roles)}
        sectionOptions={OptionsMemo(sections)}
        item={item}
        value={value}
        handleChange={handleChange}
      />
    </Modal>
  );
}

export default UserAddModal;
