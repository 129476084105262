import Definitions from './Definitions';
import Categories from './Categories';
import Customers from './Customers';
import Ingredients from './Ingredients';
import Properties from './Properties';
import Tables from './Tables';

export { Categories, Customers, Ingredients, Properties, Tables };

export default Definitions;
