import { Box, BoxProps, SvgIconProps } from '@mui/material';
import React from 'react';
import Icon, { IconName } from '../Icon';

type Shape = 'round' | 'circle' | 'square';
type Theme = 'primary' | 'secondary' | 'danger' | 'warning' | 'info';

interface Props extends SvgIconProps {
  containerStyle?: BoxProps['sx'];
  name: IconName;
  size?: number;
  iconSize?: number;
  shape?: Shape;
  theme?: Theme;
}

const mapShape = (shape: Shape) => {
  switch (shape) {
    case 'circle':
      return '50%';
    case 'round':
      return 3;
    default:
      return 1;
  }
};

function IconWithContainer({
  containerStyle,
  name,
  size = 50,
  iconSize = 25,
  shape = 'round',
  theme,
  ...rest
}: Props) {
  return (
    <Box
      sx={[
        {
          width: size,
          height: size,
          borderRadius: mapShape(shape),
          backgroundColor: 'neutral.light',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        ...(containerStyle
          ? Array.isArray(containerStyle)
            ? containerStyle
            : [containerStyle]
          : []),
      ]}
    >
      <Icon name={name} sx={{ fontSize: iconSize }} {...rest} />
    </Box>
  );
}

export default IconWithContainer;
