/** Dependencies */
import React from 'react';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

/** Components */
import StopWatch from '../../../../components/Timer/StopWatch';
import ItemInfo from './ItemInfo';
import ItemCheckButton from './ItemCheckButton';

interface Props {
  item: any;
  orders: any;
  orderItemGroup: any;
  handleOrderItemUpdate: (status: number, item_id: string) => void;
}

function NonMenuItemContent({
  item,
  orders,
  orderItemGroup,
  handleOrderItemUpdate,
}: Props) {
  const { t } = useTranslation('kitchen');

  return (
    <>
      <Stack mt={2} mb={2}>
        <Stack direction="row">
          <Box>
            <Typography sx={{ color: 'rgb(222, 143, 0)' }} variant="subtitle2">
              <Grid container>{t('order.ready')}</Grid>
            </Typography>
            <StopWatch
              duration={
                item.created_at ||
                orders.created_at ||
                orderItemGroup.created_at
              }
              backgroundColor="red"
              color="white"
            />
          </Box>
          <Stack
            sx={{ flex: 1, pl: 2, cursor: 'pointer' }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <ItemInfo
              item={item}
              orders={orders}
              orderItemGroup={orderItemGroup}
            />
            <ItemCheckButton
              item={item}
              handleOrderItemUpdate={handleOrderItemUpdate}
            />
          </Stack>
        </Stack>
        {orders.description && (
          <Box sx={{ color: '#FF0000', marginTop: '5px' }}>
            {t('note')}: {orders.description}
          </Box>
        )}
      </Stack>
      <Divider />
    </>
  );
}

export default NonMenuItemContent;
