import { SxProps, Theme } from '@mui/material';

const styles: Record<string, SxProps<Theme>> = {
  container: {
    width: { xs: '100%', sm: 700 },
    height: { xs: '100vh', sm: '100%' },
  },
  typography: { flex: 1, color: 'text.secondary' },
  submit: { fontWeight: '500' },
  submitPadding: { px: { xs: 2, sm: 1, md: 2 }, pb: { xs: 2, sm: 1, md: 2 } },
  discountPadding: { pb: { xs: 2, sm: 1, md: 2 } },
  discountBg: { backgroundColor: 'rgba(0,0,0,.05)' },
};

export default styles;
