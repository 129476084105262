/** Dependencies */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import { Box, IconButton, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import TodayIcon from '@mui/icons-material/Today';
import EventIcon from '@mui/icons-material/Event';
import HttpsIcon from '@mui/icons-material/Https';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import EventNoteIcon from '@mui/icons-material/EventNote';

/** Components */
import AccountMenu from './AccountMenu';
import useAuth from '../../hooks/useAuth';
import useProfile from '../../hooks/useProfile';
import useBranches from '../../hooks/useBranches';
import ConfirmationModal from '../ConfirmationModal';
import keyHasPermission from '../../helpers/keyHasPermission';
import ExpenseAddModal from '../../pages/Operations/ExpenseManagement/Modals/ExpenseAddModal';
import AccountLanguage from './AccountLanguage';
import HelpCenter from './HelpCenter';

interface Props {
  getExpenseModalData: () => void;
  onClose: () => void;
  expenseAddModal: boolean;
  token: string | null;
}

function TopBarIcons({
  getExpenseModalData,
  onClose,
  expenseAddModal,
  token,
}: Props) {
  const { t } = useTranslation('layout');

  const { logoutWithPin } = useAuth();
  const { meProfile } = useProfile();
  const { updateBranchesStartDate, updateBranchesEndDate } = useBranches();

  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(
    null,
  );

  const hasEndOfDayPermission = keyHasPermission(
    'end-of-day',
    meProfile?.permissions || [],
  );

  const hasCreateExpensePermission = keyHasPermission(
    'create-expense',
    meProfile?.permissions || [],
  );

  return (
    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
      {hasCreateExpensePermission && (
        <Tooltip title={t('topbar.fastExpense')}>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            sx={{ color: grey[700], borderRadius: 0 }}
            onClick={() => getExpenseModalData()}
            disabled={Boolean(token)}
          >
            <TrendingDownIcon />
          </IconButton>
        </Tooltip>
      )}
      {hasEndOfDayPermission &&
        (meProfile?.activeBranch?.day_id === null ? (
          <Tooltip title={t('topbar.start_Day')}>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              sx={{ color: grey[700], borderRadius: 0 }}
              onClick={() => setDeleteConfirmation('start-date')}
              disabled={Boolean(token)}
            >
              <TodayIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={t('topbar.end_Day')}>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              sx={{ color: grey[700], borderRadius: 0 }}
              onClick={() => setDeleteConfirmation('end-date')}
              disabled={Boolean(token)}
            >
              <EventIcon />
            </IconButton>
          </Tooltip>
        ))}
      <Tooltip title={t('topbar.lock')}>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          sx={{ color: grey[700], borderRadius: 0 }}
          onClick={logoutWithPin}
          disabled={Boolean(token)}
        >
          <HttpsIcon />
        </IconButton>
      </Tooltip>
      {meProfile?.installed_apps?.find(
        item => item.slug === 'customer-display-screen',
      ) && (
        <Tooltip title={t('topbar.display')}>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            sx={{ color: grey[700], borderRadius: 0 }}
            onClick={() => {
              window.open(
                '/customer-display',
                '_blank',
                'rel=noopener noreferrer',
              );
            }}
            disabled={Boolean(token)}
          >
            <EventNoteIcon />
          </IconButton>
        </Tooltip>
      )}
      <HelpCenter />
      <AccountLanguage />
      <AccountMenu />
      <ConfirmationModal
        title={
          deleteConfirmation === 'start-date'
            ? t('topbar.confirmation.startDayTitle')
            : t('topbar.confirmation.endDayTitle')
        }
        titleIconName="QuestionMarkOutlined"
        description={
          deleteConfirmation === 'start-date'
            ? t('topbar.confirmation.startDaySubtitle')
            : t('topbar.confirmation.endDaySubtitle')
        }
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        positiveAction={t('topbar.confirmation.confirm')}
        onPositiveClick={() => {
          if (deleteConfirmation === 'start-date') {
            updateBranchesStartDate(meProfile?.activeBranch?.id || '');
          }
          if (deleteConfirmation === 'end-date') {
            updateBranchesEndDate(meProfile?.activeBranch?.id || '');
          }
          setDeleteConfirmation(null);
        }}
        negativeAction={t('topbar.confirmation.cancel')}
        onNegativeClick={() => setDeleteConfirmation(null)}
      />
      <ExpenseAddModal open={expenseAddModal} onClose={onClose} item={null} />
    </Box>
  );
}

export default TopBarIcons;
