import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import api from '../../services/api';
import { isApiError } from '../../services/api/types';
import {
  IngredientSettingsData,
  ProductSettingsData,
  PropertySettingsData,
  TrendyolSettingsData,
  UpdateTrendyolSettingsRes,
} from '../../services/api/integrations/types';

const useIntegrations = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');

  const [settingsList, setSettingsList] = useState<TrendyolSettingsData | null>(
    null,
  );

  const [settingsProductList, setSettingsProductList] = useState<
    ProductSettingsData[]
  >([]);
  const [metaProductList, setMetaProductList] = useState<any>(null);

  const [settingsIngredientList, setSettingsIngredientList] = useState<
    IngredientSettingsData[]
  >([]);
  const [metaIngredientList, setMetaIngredientList] = useState<any>(null);

  const [settingsPropertyList, setSettingsPropertyList] = useState<
    PropertySettingsData[]
  >([]);
  const [metaPropertyList, setMetaPropertyList] = useState<any>(null);

  const [loading, setLoading] = useState(false);

  const fetchIntegrationSettings = async (slug: string) => {
    try {
      setLoading(true);
      const response = await api.integrations.settingsList(slug);

      const result = response.data.data;

      // @ts-ignore
      setSettingsList(result);
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      setSettingsList(null);
    } finally {
      setLoading(false);
    }
  };

  const fetchSyncList = async (slug: string) => {
    try {
      setLoading(true);
      const response = await api.integrations.syncList(slug);

      const result = response.data.data;

      // @ts-ignore
      setSettingsList(result);

      enqueueSnackbar(response.data.message || t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      setSettingsList(null);
    } finally {
      setLoading(false);
    }
  };

  const fetchProductSettings = async (
    slug: string,
    page: number,
    search: string,
    sort: string,
  ) => {
    try {
      setLoading(true);
      const response = await api.integrations.integrationProduct(
        slug,
        page,
        search,
        sort,
      );

      const result = response.data.data;

      // @ts-ignore
      setSettingsProductList(result);
      setMetaProductList(response.data.meta);
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      setSettingsProductList([]);
      setMetaProductList(null);
    } finally {
      setLoading(false);
    }
  };

  const fetchIngredientsSettings = async (
    slug: string,
    page: number,
    search: string,
    sort: string,
  ) => {
    try {
      setLoading(true);
      const response = await api.integrations.integrationIngredient(
        slug,
        page,
        search,
        sort,
      );

      const result = response.data.data;

      // @ts-ignore
      setSettingsIngredientList(result);
      setMetaIngredientList(response.data.meta);
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      setSettingsIngredientList([]);
      setMetaIngredientList(null);
    } finally {
      setLoading(false);
    }
  };

  const fetchPropertySettings = async (
    slug: string,
    page: number,
    search: string,
    sort: string,
  ) => {
    try {
      setLoading(true);
      const response = await api.integrations.integrationProperty(
        slug,
        page,
        search,
        sort,
      );

      const result = response.data.data;

      // @ts-ignore
      setSettingsPropertyList(result);
      setMetaPropertyList(response.data.meta);
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      setSettingsPropertyList([]);
      setMetaPropertyList(null);
    } finally {
      setLoading(false);
    }
  };

  const updateIntegrationSettings = async (
    data: UpdateTrendyolSettingsRes,
    slug: string,
  ) => {
    try {
      setLoading(true);
      const response = await api.integrations.updateSettings(data, slug);

      const result = response.data.data;

      // @ts-ignore
      setSettingsList(result);

      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      setSettingsList(null);
    } finally {
      setLoading(false);
    }
  };

  const matchIntegrationProduct = async (
    slug: string,
    id: string,
    kitchrest_product_id: string,
  ) => {
    try {
      setLoading(true);
      const result = await api.integrations.integrationProductMatch(
        slug,
        id,
        kitchrest_product_id,
      );
      enqueueSnackbar(result.data.message || t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const unmatchIntegrationProduct = async (slug: string, id: string) => {
    try {
      setLoading(true);
      const result = await api.integrations.integrationProductUnMatch(slug, id);
      enqueueSnackbar(result.data.message || t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const matchIntegrationIngredient = async (
    slug: string,
    id: string,
    kitchrest_product_id: string,
  ) => {
    try {
      setLoading(true);
      const result = await api.integrations.integrationIngredientMatch(
        slug,
        id,
        kitchrest_product_id,
      );

      enqueueSnackbar(result.data.message || t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const unmatchIntegrationIngredient = async (slug: string, id: string) => {
    try {
      setLoading(true);
      const result = await api.integrations.integrationIngredientUnMatch(
        slug,
        id,
      );
      enqueueSnackbar(result.data.message || t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const matchIntegrationProperty = async (
    slug: string,
    id: string,
    kitchrest_product_id: string,
  ) => {
    try {
      setLoading(true);
      const result = await api.integrations.integrationPropertiesMatch(
        slug,
        id,
        kitchrest_product_id,
      );
      enqueueSnackbar(result.data.message || t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const unmatchIntegrationProperty = async (slug: string, id: string) => {
    try {
      setLoading(true);
      const result = await api.integrations.integrationPropertiesUnMatch(
        slug,
        id,
      );
      enqueueSnackbar(result.data.message || t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    settingsList,
    settingsProductList,
    settingsIngredientList,
    settingsPropertyList,
    setSettingsProductList,
    loading,
    fetchIntegrationSettings,
    fetchProductSettings,
    fetchIngredientsSettings,
    fetchPropertySettings,
    updateIntegrationSettings,
    matchIntegrationProduct,
    unmatchIntegrationProduct,
    matchIntegrationIngredient,
    unmatchIntegrationIngredient,
    matchIntegrationProperty,
    unmatchIntegrationProperty,
    metaProductList,
    metaIngredientList,
    metaPropertyList,
    fetchSyncList,
  };
};
export default useIntegrations;
