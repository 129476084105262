/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

/** Components */
import EmptyState from '../../../components/EmptyState';
import Icon from '../../../components/Icon';

/** Types */
import LoadingState from '../../../components/LoadingState';
import useProfile from '../../../hooks/useProfile';
import keyHasPermission from '../../../helpers/keyHasPermission';
import {
  AppPaymentItem,
  PaymentHistory,
} from '../../../services/api/utilities/types';
import PaymentItemModal from './PaymentItemModal';

interface Props {
  paymentHistories: PaymentHistory[];
}

function PaymentHistoryTable({ paymentHistories }: Props) {
  const { t } = useTranslation('paymentHistory');
  const { meProfile } = useProfile();
  const [loading, setLoading] = useState(false);
  const [paymentItems, setPaymentItems] = useState<AppPaymentItem[]>([]);
  const [open, setOpen] = useState(false);

  const hasListPermission = keyHasPermission(
    'view-payment-history-listing',
    meProfile?.permissions || [],
  );

  const renderEmpty = () => (
    <EmptyState
      message={
        !hasListPermission
          ? 'Bu sayfayı görmek için yetkiniz yok!'
          : t('payment.empty')
      }
    />
  );

  return (
    <Paper sx={{ width: '100%', p: 2 }}>
      {!hasListPermission || !paymentHistories.length ? (
        renderEmpty()
      ) : (
        <TableContainer>
          {loading ? (
            <LoadingState />
          ) : (
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('payment.paymentTable.status')}</TableCell>
                  <TableCell>
                    {t('payment.paymentTable.payment_date')}
                  </TableCell>
                  <TableCell>{t('payment.paymentTable.kdv')}</TableCell>
                  <TableCell>{t('payment.paymentTable.description')}</TableCell>
                  <TableCell>
                    {t('payment.paymentTable.total_amount')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ cursor: 'pointer' }}>
                {paymentHistories?.map(row => (
                  <TableRow key={row.id}>
                    <TableCell
                      sx={{ color: 'blue' }}
                      component="th"
                      scope="row"
                      onClick={() => {
                        setPaymentItems(row?.app_payment_items || []);
                        setOpen(true);
                      }}
                    >
                      {row?.status}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row?.payment_date || '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row?.total_tax_amount?.formatted}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row?.apps || '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row?.total_amount?.formatted}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      )}
      <PaymentItemModal
        open={open}
        onClose={() => setOpen(false)}
        paymentItems={paymentItems}
      />
    </Paper>
  );
}

export default PaymentHistoryTable;
