import React from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { grey } from '@mui/material/colors';

interface Props {
  onClick: () => void;
}

function DrawerButton({ onClick }: Props) {
  return (
    <IconButton
      size="large"
      edge="start"
      aria-label="open drawer"
      onClick={onClick}
      sx={{ mr: 2, color: grey[700] }}
    >
      <MenuIcon color="inherit" />
    </IconButton>
  );
}

export default DrawerButton;
