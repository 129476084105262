import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import ConfirmationModal from '../../../../../components/ConfirmationModal';
import EmptyState from '../../../../../components/EmptyState';
import ErrorState from '../../../../../components/ErrorState';
import Icon from '../../../../../components/Icon';
import LoadingState from '../../../../../components/LoadingState';
import useProductsMenu from '../../../../../hooks/useProductMenu';
import { ProductAPI } from '../../../../../services/api/definitions/products/types';
import FormButtons from '../FormButtons';
import MenuAddModal from './MenuAddModal';
import MenuItemsModal from './MenuItemsModal';
import MenuRow from './MenuRow';
import errorReporting from '../../../../../helpers/errorReporting';

/** helpers */
import getActiveLanguage from '../../../../../helpers/getActiveLanguage';

interface Props {
  onValidSubmit?: () => void;
  onPrevClick: () => void;
  productId?: string;
  menus?: ProductAPI['menus'];
}
function MenuForm({
  onPrevClick,
  productId,
  menus = [],
  onValidSubmit,
}: Props) {
  const { t } = useTranslation('product');
  const [menuAddModal, setMenuAddModal] = useState(false);
  const [menuItemAddModal, setMenuItemAddModal] = useState(false);
  const [deleteMenuConfirmation, setDeleteMenuConfirmation] = useState(false);
  const [menuToEdit, setMenuToEdit] = useState<string | null>(null);
  const [menuToDelete, setMenuToDelete] = useState<string | null>(null);

  const {
    addProductMenuItem,
    updateProductMenuItem,
    removeProductMenuItem,
    loading,
    allProducts,
    allProductLoading,
    allProductError,
    removeProductMenu,
    fetchAllProducts,
  } = useProductsMenu();

  const handleRemove = async () => {
    try {
      if (menuToDelete && productId) {
        await removeProductMenu({ productId, id: menuToDelete });
        setMenuToDelete(null);
        setDeleteMenuConfirmation(false);
      }
    } catch (err) {
      errorReporting(err);
    }
  };

  if (allProductLoading) {
    return <LoadingState />;
  }

  if (allProductError) {
    return <ErrorState message={allProductError.message} />;
  }

  const findInitialMenu = (menu: any) => ({
    name: [
      {
        lang: menu?.translations
          ? getActiveLanguage(menu?.translations)?.lang
          : 'tr',
        text: menu?.translations
          ? getActiveLanguage(menu?.translations)?.name
          : '',
      },
    ],
    quantity: menu?.selectable_quantity || 0,
  });

  useEffect(() => {
    fetchAllProducts('', null, null);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '100%',
        }}
      >
        <Box sx={{ p: 4 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              mb: 1,
            }}
          >
            <Button
              startIcon={<Icon name="Add" />}
              onClick={() => setMenuAddModal(true)}
              variant="outlined"
            >
              {t('menu.addMenu')}
            </Button>
          </Box>
          {!menus.length ? <EmptyState message={t('menu.empty')} /> : null}
          {menus.length ? (
            <TableContainer>
              <Table>
                <TableHead sx={{ backgroundColor: 'primary.light' }}>
                  <TableRow>
                    <TableCell sx={{ width: 80 }} />
                    <TableCell>{t('menu.columns.name')}</TableCell>
                    <TableCell sx={{ width: 150 }}>
                      {t('menu.columns.quantity')}
                    </TableCell>
                    <TableCell sx={{ width: 80 }}>
                      {t('menu.columns.action')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {menus.map(row => (
                    <MenuRow
                      key={row.id}
                      id={row.id}
                      name={getActiveLanguage(row.translations).name}
                      quantity={row.selectable_quantity}
                      items={row.items}
                      products={allProducts}
                      onAddItems={id => {
                        setMenuToEdit(id);
                        setMenuItemAddModal(true);
                      }}
                      onEdit={id => {
                        setMenuToEdit(id);
                        setMenuAddModal(true);
                      }}
                      onDelete={id => {
                        setMenuToDelete(id);
                        setDeleteMenuConfirmation(true);
                      }}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </Box>

        <FormButtons
          onPrevClick={onPrevClick}
          onNextClick={onValidSubmit}
          isLast
        />
      </Box>
      <MenuAddModal
        open={menuAddModal}
        onClose={() => {
          setMenuAddModal(false);
          setMenuToEdit(null);
        }}
        productId={productId}
        productMenuId={menus?.find(item => item.id === menuToEdit)?.id}
        initialValues={
          findInitialMenu(menus?.find(item => item.id === menuToEdit)) ||
          undefined
        }
      />
      <MenuItemsModal
        open={menuItemAddModal}
        onClose={() => {
          setMenuItemAddModal(false);
          setMenuToEdit(null);
        }}
        menuId={menuToEdit}
        menuItems={menus.find(m => m.id === menuToEdit)?.items}
        mainProductId={productId}
        addProductMenuItem={addProductMenuItem}
        updateProductMenuItem={updateProductMenuItem}
        removeProductMenuItem={removeProductMenuItem}
        loading={loading}
        allProducts={allProducts.filter(item => !item.is_menu)}
      />
      <ConfirmationModal
        title={t('menu.deleteConfirmation.title')}
        titleIconName="QuestionMarkOutlined"
        description={t('menu.deleteConfirmation.description')}
        open={deleteMenuConfirmation}
        onClose={() => {
          setDeleteMenuConfirmation(false);
          setMenuToDelete(null);
        }}
        positiveAction={t('menu.deleteConfirmation.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('menu.deleteConfirmation.cancel')}
        onNegativeClick={() => {
          setDeleteMenuConfirmation(false);
          setMenuToDelete(null);
        }}
        loading={loading}
      />
    </>
  );
}
export default MenuForm;
