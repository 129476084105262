/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

/** Components */
import Modal from '../../../../components/Modal';
import AddModalForm from './AddModalForm';

/** Services */
import { isApiError } from '../../../../services/api/types';

/** Hooks */
import useCustomerAddress from '../../../../hooks/useCustomerAddress';

/** Types */
import { CustomerAddressBase, validationSchema } from '../types';
import errorReporting from '../../../../helpers/errorReporting';

interface Props {
  open: boolean;
  onClose: () => void;
  customerId: string;
}

function CustomerAddressAddModal({ open, onClose, customerId }: Props) {
  const { t } = useTranslation('customerAddress');
  const { enqueueSnackbar } = useSnackbar();
  const { addCustomerAddress } = useCustomerAddress();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    id: customerId,
    name: '',
    address: '',
    is_default: false,
    note: '',
  };

  const { handleSubmit, control, reset } = useForm<CustomerAddressBase>({
    defaultValues: initialValues,
    resolver: validationSchema,
  });

  useEffect(() => reset(initialValues), [open]);

  const handleConfirm = () => {
    handleSubmit(async values => {
      if (customerId) {
        try {
          setLoading(true);
          await addCustomerAddress({
            ...values,
          });
          onClose();
        } catch (error: any) {
          enqueueSnackbar(
            isApiError(error) ? error.message : t('common:unknownError' as any),
            {
              variant: 'error',
              autoHideDuration: 3000,
            },
          );
          errorReporting(error);
        } finally {
          setLoading(false);
        }
      }
    })();
  };

  return (
    <Modal
      title={t('customerAddress.addModal.title')}
      titleIconName="Add"
      open={open}
      onPositiveClick={handleConfirm}
      onNegativeClick={onClose}
      positiveAction={t('customerAddress.addModal.confirm')}
      negativeAction={t('customerAddress.addModal.cancel')}
      onClose={onClose}
      loading={loading}
    >
      <AddModalForm control={control} />
    </Modal>
  );
}

export default CustomerAddressAddModal;
