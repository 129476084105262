import { Permission } from '../services/api/auth/types';

function arrayKeyHasPermission(key: string[], permissions?: Permission[]) {
  if (key.length === 0) {
    return true;
  }

  // @ts-ignore
  return Boolean(permissions?.find(item => key.includes(item.slug)));
}

export default arrayKeyHasPermission;
