/** Dependencies */
import React from 'react';
import { Grid, Typography, Box } from '@mui/material';

/** Components */
import Icon from '../../../../components/Icon';
import NonMenuItemPropertyAndIngredientInfo from './ItemPropertyAndIngredientInfo';

/** Styles */
import styles from '../../styles';

/** Helpers */
import getActiveLanguage from '../../../../helpers/getActiveLanguage';

interface Props {
  item: any;
  orders: any;
  orderItemGroup: any;
}

function NonMenuItemContent({ item, orders, orderItemGroup }: Props) {
  return (
    <Box>
      <Typography color="gray" variant="subtitle2">
        <Grid container>
          <Icon fontSize="small" name="Customer" />{' '}
          {orderItemGroup.created_by.full_name}
        </Grid>
      </Typography>
      <Typography sx={styles.title}>
        {`${
          orders.has_special_product
            ? item.quantity
            : item.quantity * orders.quantity
        }
                              ${
                                item?.product_unit &&
                                item?.product_unit[0]?.unit?.translations
                                  ? getActiveLanguage(
                                      item?.product_unit[0].unit.translations,
                                    ).name
                                  : ''
                              }                        
                               - ${
                                 orders.has_special_product
                                   ? item.product_name
                                   : item?.product.translations
                                   ? getActiveLanguage(
                                       item?.product.translations,
                                     ).name
                                   : ''
                               }`}
      </Typography>
      <NonMenuItemPropertyAndIngredientInfo item={item} />
    </Box>
  );
}

export default NonMenuItemContent;
