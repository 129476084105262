/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import { Box } from '@mui/material';

/** Components */
import UtilitiesBar from '../../../components/UtilitiesBar';
import RecordsTable from './RecordsTable';
import LoadingState from '../../../components/LoadingState';
import ErrorState from '../../../components/ErrorState';
import SearchBar from '../../../components/SearchBar';

/** Hooks */
import useCallerId from '../../../hooks/useCallerId';

function Records() {
  const { t } = useTranslation('callerId');
  const { fetchCallerIdRecords, records, loading, meta } = useCallerId();

  const [page, setPage] = useState(1);

  const [searchValue, setSearchValue] = useState<{
    inputValue: string;
    isActive: boolean | null;
  }>({
    inputValue: '',
    isActive: null,
  });
  const [debouncedSearch, setDebouncedSearch] = useState({
    inputValue: '',
    isActive: true,
  });

  const debouncedAndFilteredSearch = async () => {
    try {
      await fetchCallerIdRecords({
        search: searchValue.inputValue,
        page: 1,
      });
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  useEffect(() => {
    debouncedAndFilteredSearch();
  }, [debouncedSearch.inputValue]);

  if (!records) {
    return <ErrorState message={t('common:unknownError' as any)} />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('title')}
        // @ts-ignore
        subtitle={`${t('subtitle')}: ${meta?.total ? meta?.total : 0}`}
        leftIconName="PhoneInTalk"
      />
      <SearchBar
        placeholder={t('searchbar.placeholder')}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setDebouncedSearch={setDebouncedSearch}
      />
      {loading ? (
        <LoadingState />
      ) : (
        <RecordsTable
          page={page}
          setPage={setPage}
          records={records}
          meta={meta}
        />
      )}
    </Box>
  );
}

export default Records;
