/** Dependencies */
import React, { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import { matchIsValidTel } from 'mui-tel-input';

/** Types */
import {
  CustomerBase,
  validationSchema,
} from '../../../../../pages/definitions/Customers/types';

/** Components */
import Modal from '../../../../../components/Modal';
import FormInput from '../../../../../components/FormInput';
import FormPhoneInput from '../../../../../components/FormPhoneInput';
import FormSwitch from '../../../../../components/FormSwitch';
import useCustomers from '../../../../../hooks/useCustomers';
import { isApiError } from '../../../../../services/api/types';
import FormNumberInput from '../../../../../components/FormNumberInput';
import useProfile from '../../../../../hooks/useProfile';

interface Props {
  open: boolean;
  onClose: () => void;
}

function Form({ open, onClose }: Props) {
  const { t } = useTranslation('pos');
  const { enqueueSnackbar } = useSnackbar();
  const { addCustomer } = useCustomers();
  const { meProfile } = useProfile();

  const initialValues = {
    id: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    is_active: false,
    phone_number_country: 'TR',
    is_open_account: false,
  };

  const { handleSubmit, control, reset } = useForm<CustomerBase>({
    defaultValues: initialValues,
    resolver: validationSchema,
  });

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [info, setInfo] = useState<any>(null);
  const [openAccountInput, setOpenAccountInput] = useState<boolean>(false);

  const handleChange = (newValue: any, newinfo: any) => {
    setValue(newValue);
    setInfo(newinfo);
  };
  useEffect(() => {
    reset(initialValues);
  }, [open]);

  const handleConfirm = () => {
    try {
      setLoading(true);
      handleSubmit(async values => {
        try {
          await addCustomer({
            ...values,
            chargeable_amount: (values.chargeable_amount || 0) * 100,
            phone_number_country: info?.countryCode,
            phone_number: info?.nationalNumber,
          });
          onClose();
        } catch (err) {
          enqueueSnackbar(
            isApiError(err) ? err.message : t('common:unknownError' as any),
            {
              variant: 'error',
              style: { whiteSpace: 'pre-line' },
            },
          );
          throw err;
        }
      })();
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const openAccountHandler = () => {
    if (openAccountInput) {
      setOpenAccountInput(false);
    } else {
      setOpenAccountInput(true);
    }
  };

  return (
    <Modal
      title={t('orderModal.customerModal.addModal.title')}
      titleIconName="Add"
      open={open}
      onClose={onClose}
      loading={loading}
      negativeAction={t('orderModal.customerModal.addModal.cancel')}
      positiveAction={t('orderModal.customerModal.addModal.add')}
      onPositiveClick={handleConfirm}
      onNegativeClick={onClose}
    >
      <Controller
        name="first_name"
        // @ts-ignore
        control={control}
        render={({ field, fieldState }) => (
          <FormInput
            label={t('orderModal.customerModal.addModal.form.first_name.label')}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            {...field}
          />
        )}
      />
      <Controller
        name="last_name"
        // @ts-ignore
        control={control}
        render={({ field, fieldState }) => (
          <FormInput
            label={t('orderModal.customerModal.addModal.form.last_name.label')}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            {...field}
          />
        )}
      />
      <Controller
        name="phone_number"
        // @ts-ignore
        control={control}
        rules={{ validate: matchIsValidTel }}
        render={({ field }) => (
          <FormPhoneInput
            label={t(
              'orderModal.customerModal.addModal.form.phone_number.label',
            )}
            phoneValue={value}
            handleChange={handleChange}
            {...field}
          />
        )}
      />
      <Controller
        name="is_open_account"
        // @ts-ignore
        control={control}
        defaultValue={false}
        render={({ field, fieldState }) => (
          <FormSwitch
            onClick={() => {
              openAccountHandler();
            }}
            label={t('paymentModal.openAccount')}
            error={fieldState.invalid}
            {...field}
          />
        )}
      />
      {openAccountInput && (
        <Controller
          name="chargeable_amount"
          // @ts-ignore
          control={control}
          render={({ field, fieldState }) => (
            <FormNumberInput
              label={t(
                'orderModal.customerModal.addModal.form.isOpenAccount.openAccountLimit',
              )}
              placeholder={`0,00 ${meProfile?.currency?.code || 'TRY'}`}
              maskProps={{
                decimalScale: 2,
                fixedDecimalScale: true,
                suffix: ` ${meProfile?.currency?.code || 'TRY'}`,
              }}
              error={fieldState.invalid}
              helperText={
                fieldState.error
                  ? t(fieldState.error.message as any)
                  : undefined
              }
              {...field}
            />
          )}
        />
      )}
    </Modal>
  );
}

export default Form;
