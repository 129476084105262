/** Dependencies */
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NameTranslation } from '../../../../models/General';

export type GeneralSettingFromValues = {
  name: string;
  start_time: string;
  working_types: number[];
  currency_id: number;
  timezone: string;
};

export type PaymentType = {
  id: string;
  is_active: boolean;
  translations: NameTranslation[];
};

const generalSettingsSchema: yup.SchemaOf<GeneralSettingFromValues> =
  yup.object({
    name: yup.string().required('generalSettings.name.required'),
    start_time: yup.string().required('generalSettings.name.required'),
    working_types: yup
      .array(yup.number().required())
      .required('generalSettings.name.required'),
    currency_id: yup.number().required('generalSettings.name.required'),
    timezone: yup.string().required('generalSettings.name.required'),
  });

export const validationGeneralSettingsSchema = yupResolver(
  generalSettingsSchema,
);
