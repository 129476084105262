import { SxProps, Theme } from '@mui/material';

const styles: Record<string, SxProps<Theme>> = {
  gridItem: {
    padding: 3,
    border: '1px solid',
    borderRadius: 2,
    backgroundColor: 'white',
    minHeight: 700,
  },
};

export default styles;
