/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

/** Styles */
import { Divider, Tabs, Grid, Tab } from '@mui/material';

/** Components */
import CustomerTransactionTable from './CustomerTransactionTable';
import CustomerPaymentTable from './CustomerPaymentTable';
import CustomerAddressTable from './CustomerAdressTable';

/** Hooks */
import useCustomerAddress from '../../../hooks/useCustomerAddress';

interface Props {
  customerBalance: () => void;
}

export default function CustomerDetailsTable({ customerBalance }: Props) {
  const { t } = useTranslation('customerAddress');
  const { fetchCustomerAddresses } = useCustomerAddress();

  const { customerId } = useParams();

  const [selectActive, setSelectActive] = useState(0);

  const [activeStep, setActiveStep] = useState(0);

  const status = [
    {
      id: 0,
      text: t('customerAddress.title'),
    },
    {
      id: 1,
      text: t('customerAddress.addCustomerOrders.title'),
    },
    {
      id: 2,
      text: t('customerAddress.addCustomerPaid.title'),
    },
  ];

  useEffect(() => {
    if (customerId) {
      fetchCustomerAddresses(customerId);
    }
  }, []);

  return (
    <div>
      <Grid
        item
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tabs
          value={activeStep}
          onChange={(_, value) => {
            setActiveStep(value);
          }}
          variant="scrollable"
          centered
          scrollButtons="auto"
        >
          {status.map(item => (
            <Tab
              key={item.id}
              onClick={() => setSelectActive(item.id)}
              label={item.text}
            />
          ))}
        </Tabs>
        <Divider />
      </Grid>
      <Grid container sx={{ minHeight: '100%' }}>
        <Grid item xs>
          <div>
            {selectActive === 0 && <CustomerAddressTable />}

            {selectActive === 1 && (
              <CustomerTransactionTable selectActive={selectActive} />
            )}

            {selectActive === 2 && (
              <CustomerPaymentTable
                selectActive={selectActive}
                customerBalance={() => customerBalance()}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
