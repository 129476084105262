import { Grid, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormInputSelect from '../../../components/FormInputSelect';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';
import arrayHasDuplicates from '../../../helpers/arrayHasDuplicates';
import useIngredientGroups from '../../../hooks/useIngredientGroups';
import { NameTranslation } from '../../../models/General';
import { IngredientGroup } from '../../../models/Ingredient';
import { useAppSelector } from '../../../store';
import errorReporting from '../../../helpers/errorReporting';
import { OptionsLanguageMemo } from '../../../helpers/optionMemo';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  item?: IngredientGroup | null;
}

function IngredientGroupAddModal({ open, onClose, onConfirm, item }: Props) {
  const { t } = useTranslation('ingredient');
  const { enqueueSnackbar } = useSnackbar();

  const { addIngredientGroups, updateIngredientGroups, loading } =
    useIngredientGroups();

  const { list } = useAppSelector(state => state.languages);

  const options = OptionsLanguageMemo(list);

  const initialValue: NameTranslation[] = [
    {
      id: Math.random().toString(),
      name: '',
      lang: options[0].id,
    },
  ];

  const [names, setNames] = useState<NameTranslation[]>(initialValue);

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (item) {
      setNames(item.translations);
    }
    return () => {
      setNames(initialValue);
    };
  }, [item]);

  useEffect(() => {
    names.forEach(element => {
      if (!element.lang) {
        const temp = names.map(el =>
          el.id === element.id ? { ...element, lang: options[0].id } : el,
        );
        setNames(temp);
      }
    });
  }, []);

  const handleClose = () => {
    setIsSubmitted(false);
    setNames(initialValue);
    onClose();
  };

  const handleAddItem = () => {
    let suggestedLang = options[0].id;
    options.forEach(opt => {
      if (names.find(a => a.lang !== opt.id)) {
        suggestedLang = opt.id;
      }
    });
    setNames([
      ...names,
      {
        id: Math.random().toString(),
        name: '',
        lang: suggestedLang,
      },
    ]);
  };

  const handleConfirm = async () => {
    if (!names.some(el => !el.name || !el.lang)) {
      try {
        if (arrayHasDuplicates(names, 'lang')) {
          enqueueSnackbar(t('group.addModal.messages.duplicateLanguage'), {
            variant: 'error',
            autoHideDuration: 3000,
          });
          return;
        }
        const constructedName: Record<string, string> = {};
        names.forEach(({ lang: langId, name }) => {
          constructedName[langId] = name;
        });

        if (!item?.id) {
          await addIngredientGroups(constructedName);
        } else {
          await updateIngredientGroups({
            id: item.id,
            name: constructedName,
          });
        }

        if (onConfirm) onConfirm();
        setNames(initialValue);
      } catch (err) {
        errorReporting(err);
      }
    } else {
      setIsSubmitted(true);
    }
  };

  return (
    <Modal
      title={t('group.addModal.title', {
        context: item ? 'edit' : 'default',
      })}
      titleIconName={item ? 'Edit' : 'Add'}
      open={open}
      onClose={handleClose}
      positiveAction={t('group.addModal.confirm')}
      negativeAction={t('group.addModal.cancel')}
      onPositiveClick={handleConfirm}
      onNegativeClick={handleClose}
      loading={loading}
    >
      {names.map((element, index) => {
        const invalid = isSubmitted && (!element.name || !element.lang);
        return (
          <Grid container key={element.id}>
            <Grid item xs={10}>
              <FormInputSelect
                label={t('group.addModal.form.name.label')}
                error={invalid}
                helperText={invalid && t('group.addModal.form.name.required')}
                value={element.name}
                onChange={event => {
                  const temp = names.map(el =>
                    el.id === element.id
                      ? { ...element, name: event.target.value }
                      : el,
                  );
                  setNames(temp);
                }}
                options={options}
                selectValue={element.lang}
                onSelectValueChange={id => {
                  const selectedLanguage = options.find(lang => lang.id === id);
                  if (selectedLanguage) {
                    const temp = names.map(el =>
                      el.id === element.id
                        ? { ...element, lang: selectedLanguage.id }
                        : el,
                    );
                    setNames(temp);
                  }
                }}
              />
            </Grid>
            <Grid item sx={{ pt: 4 }}>
              {/* // Show only delete button if input is not the last one */}
              {index === names.length - 1 ? (
                <>
                  {/* // Show delete button near to add button if we have more than one input only */}
                  {names.length > 1 && (
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        setNames(names.filter(el => el.id !== element.id))
                      }
                      color="error"
                    >
                      <Icon name="Delete" />
                    </IconButton>
                  )}
                  {/* // Show add button only if category is less than available languages */}
                  {names.length < options.length && (
                    <IconButton
                      aria-label="add"
                      onClick={handleAddItem}
                      color="success"
                    >
                      <Icon name="Add" />
                    </IconButton>
                  )}
                </>
              ) : (
                <IconButton
                  aria-label="delete"
                  onClick={() =>
                    setNames(names.filter(el => el.id !== element.id))
                  }
                  color="error"
                >
                  <Icon name="Delete" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        );
      })}
    </Modal>
  );
}

export default IngredientGroupAddModal;
