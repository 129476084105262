import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/Modal';
import OrderedList from '../../../../../components/OrderedList';
import { IngredientItemWithActive } from '../../../../../services/api/definitions/ingredientGroups/types';

interface Item extends IngredientItemWithActive {
  name: string;
}

interface Props {
  items: Item[] | null;
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onItemMoveUp: (id: string) => void;
  onItemMoveDown: (id: string) => void;
}

function IngredientOrderModal({
  items,
  open,
  loading,
  onClose,
  onItemMoveUp,
  onItemMoveDown,
}: Props) {
  const { t } = useTranslation('product');

  if (!items) return null;

  return (
    <Modal
      title={t('ingredients.orderModal.title')}
      open={open}
      onClose={onClose}
      positiveAction={t('ingredients.orderModal.close')}
      onPositiveClick={onClose}
    >
      <OrderedList
        items={items}
        loading={loading}
        onItemUp={item => onItemMoveUp(item.id)}
        onItemDown={item => onItemMoveDown(item.id)}
        removeButtons={false}
        editButtons={false}
      />
    </Modal>
  );
}

export { IngredientOrderModal };
