/** Dependencies */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Hooks */
import { Grid } from '@mui/material';
import Modal from '../../../components/Modal';

interface Props {
  open: boolean;
  onClose: () => void;
  item: string | null;
}

function TextModal({ open, onClose, item }: Props) {
  const { t } = useTranslation('operationReports');

  return (
    <Modal
      title={t('textModal.explanation')}
      open={open}
      onClose={onClose}
      negativeAction={t('textModal.close')}
      onNegativeClick={onClose}
    >
      <Grid item xs={12} md={12} style={{ wordBreak: 'break-word' }}>
        {item}
      </Grid>
    </Modal>
  );
}

export default TextModal;
