import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isApiError } from '../../services/api/types';
import { useAppDispatch, useAppSelector } from '../../store';
import { getTaxRatesThunk } from '../../store/slices/taxRatesSlice';
import errorReporting from '../../helpers/errorReporting';

const useTaxRates = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const taxRates = useAppSelector(state => state.taxRates.list);

  const [taxRatesLoading, setTaxRatesLoading] = useState(false);

  const fetchTaxRates = async () => {
    try {
      setTaxRatesLoading(true);
      const response = await dispatch(getTaxRatesThunk());
      unwrapResult(response);
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err;
    } finally {
      setTaxRatesLoading(false);
    }
  };

  useEffect(() => {
    if (!taxRates.length) {
      fetchTaxRates();
    }
  }, []);

  return {
    taxRates,
    taxRatesLoading,
    fetchTaxRates,
  };
};
export default useTaxRates;
