import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type RegisterThirdStepFormValues = {
  startTime: string;
  currencyId: string;
  businessTypeId: string;
  workingTypes: boolean[];
  timezone: string;
};

export type RegisterThirdStepFormValidValues = {
  startTime: string;
  currencyId: string;
  businessTypeId: string;
  workingTypeIds: string[];
  timezone: string;
};

const schema = yup.object({
  startTime: yup.string().required('thirdStep.startTime.required'),
  currencyId: yup.string().required('thirdStep.currency.required'),
  businessTypeId: yup.string().required('thirdStep.businessType.required'),
  workingTypes: yup
    .array(yup.boolean().default(false))
    .required('thirdStep.workingType.required')
    .test({
      name: 'at-least-one-true',
      message: 'thirdStep.workingType.min',
      test: val => val?.some(value => value) || false,
    }),
  timezone: yup.string().required('thirdStep.businessType.required'),
});

export const defaultValues: RegisterThirdStepFormValues = {
  startTime: '09:00',
  currencyId: '1',
  businessTypeId: '1',
  workingTypes: [true],
  timezone: 'EUROPE_ISTANBUL',
};

export const validationSchema = yupResolver(schema);
