import { NameTranslation } from '../models/General';
import { languageLocalStorage } from '../services/tokenStorage';

const getActiveLanguage = (translation: NameTranslation[]): NameTranslation => {
  const localLanguage = languageLocalStorage.getToken();
  switch (localLanguage) {
    case 'tr':
      {
        const activeTranslation = translation.find(item => item.lang === 'tr');
        if (activeTranslation) return activeTranslation;
      }
      break;
    case 'en':
      {
        const activeTranslation = translation.find(item => item.lang === 'en');
        if (activeTranslation) return activeTranslation;
      }
      break;
    case 'en-US':
      {
        const activeTranslation = translation.find(item => item.lang === 'en');
        if (activeTranslation) return activeTranslation;
      }
      break;
    default:
      return translation[0];
  }
  return translation[0];
};

export default getActiveLanguage;
