import { Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormSelect from '../../../../../../components/FormSelect';
import FormSelectSearchable from '../../../../../../components/FormSelectSearchable';
import FormNumberInput from '../../../../../../components/FormNumberInput';
import FormInput from '../../../../../../components/FormInput';

import Modal from '../../../../../../components/Modal';

import {
  defaultValues,
  MenuAddItemFormValues,
  validationSchema,
} from './types';
import Button from '../../../../../../components/Button';

import {
  ProductAPI,
  ProductMenuAddItemParams,
  ProductMenuItem,
  ProductMenuUpdateItemParams,
} from '../../../../../../services/api/definitions/products/types';
import ProductCard from '../../../../../../components/ProductCard';
import ConfirmationModal from '../../../../../../components/ConfirmationModal';
import getActiveLanguage from '../../../../../../helpers/getActiveLanguage';
import useProfile from '../../../../../../hooks/useProfile';
import errorReporting from '../../../../../../helpers/errorReporting';
import useProductsMenu from '../../../../../../hooks/useProductMenu';

interface Props {
  open: boolean;
  onClose: () => void;
  menuItems?: ProductMenuItem[];
  menuId: string | null;
  mainProductId?: string;
  allProducts: ProductAPI[];
  addProductMenuItem: (params: ProductMenuAddItemParams) => Promise<null>;
  updateProductMenuItem: (params: ProductMenuUpdateItemParams) => Promise<null>;
  removeProductMenuItem: (params: {
    productId: string;
    id: string;
  }) => Promise<null>;
  loading: boolean;
}

function MenuItemsModal({
  open,
  onClose,
  menuItems,
  menuId,
  mainProductId,
  addProductMenuItem,
  updateProductMenuItem,
  removeProductMenuItem,
  loading,
  allProducts,
}: Props) {
  const { t } = useTranslation('product');
  const { meProfile } = useProfile();
  const { fetchAllProducts } = useProductsMenu();

  useEffect(() => {
    if (!allProducts.length) fetchAllProducts('', null, null);
  }, [open]);

  const [itemToEdit, setItemToEdit] = useState<string | null>(null);
  const [itemToDelete, setItemToDelete] = useState<string | null>(null);

  const initialProduct = allProducts
    .filter(
      item => !menuItems?.find(menuItem => menuItem.product_id === item.id),
    )
    .map(p => ({
      id: p.id,
      text: getActiveLanguage(p.translations).name,
      group: getActiveLanguage(p.category.translations).name,
      unit_id: p?.units?.find(item => item.is_default)?.id || '',
    }))
    .sort((a, b) => a.group.localeCompare(b.group))[0];

  const initialValues: MenuAddItemFormValues = {
    productId: initialProduct?.id || '',
    productUnitId: initialProduct?.unit_id || '',
    quantity: '1',
    price: 0,
  };

  const { handleSubmit, control, reset, watch } =
    useForm<MenuAddItemFormValues>({
      defaultValues: initialValues || defaultValues,
      resolver: validationSchema,
    });

  const resetForm = () => {
    reset(defaultValues);
    setItemToEdit(null);
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleConfirm = async () => {
    await handleSubmit(async values => {
      try {
        if (menuId && mainProductId) {
          if (itemToEdit) {
            await updateProductMenuItem({
              menuItemId: itemToEdit,
              productId: mainProductId,
              menuProductId: values.productId,
              additionalPrice: values.price,
              quantity: Number(values.quantity),
              unitId: values.productUnitId,
            });
          } else {
            await addProductMenuItem({
              menuId,
              productId: mainProductId,
              additionalPrice: values.price,
              menuProductId: values.productId,
              quantity: Number(values.quantity),
              unitId: values.productUnitId,
            });
          }
          resetForm();
        }
      } catch (err) {
        errorReporting(err);
      }
    })();
  };

  const handleRemove = () => {
    try {
      if (mainProductId && itemToDelete) {
        removeProductMenuItem({
          productId: mainProductId,
          id: itemToDelete,
        });
        setItemToDelete(null);
      }
    } catch (err) {
      errorReporting(err);
    }
  };

  const selectedProductId = watch('productId');
  const selectedProduct = allProducts.find(p => p.id === selectedProductId);

  useEffect(() => {
    if (open)
      reset({
        productId: selectedProduct?.id || '',
        productUnitId:
          selectedProduct?.units?.find(item => item.is_default)?.id || '',
        quantity: '1',
        price: 0,
      });
  }, [selectedProduct]);

  useEffect(() => {
    if (open) reset(initialValues);
  }, [allProducts]);

  return (
    <>
      <Modal
        title={t('menu.itemsModal.title')}
        titleIconName="Add"
        open={open}
        onClose={handleClose}
        positiveAction={t('menu.itemsModal.close')}
        onPositiveClick={handleClose}
        negativeAction={t('menu.itemsModal.reset')}
        onNegativeClick={() => resetForm()}
        maxWidth="md"
      >
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={6} md={3}>
            <Controller
              name="productId"
              control={control}
              render={({ field, fieldState }) => (
                <FormSelectSearchable
                  label={t('menu.itemsModal.form.product.label')}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  options={allProducts
                    .map(p => ({
                      id: p.id,
                      text: getActiveLanguage(p.translations).name,
                      group: getActiveLanguage(p.category.translations).name,
                    }))
                    .sort((a, b) => a.group.localeCompare(b.group))}
                  groupBy={option => option.group || ''}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <Controller
              name="productUnitId"
              control={control}
              render={({ field, fieldState }) => (
                <FormSelect
                  label={t('menu.itemsModal.form.unit.label')}
                  error={fieldState.invalid}
                  underline
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  options={
                    selectedProduct
                      ? selectedProduct.units
                          .filter(u => u.is_active)
                          .map(u => ({
                            id: u.id,
                            text: getActiveLanguage(u.translations).name,
                          }))
                      : []
                  }
                  defaultValue={selectedProduct?.units?.find(
                    item => item.is_default,
                  )}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <Controller
              name="quantity"
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  label={t('menu.itemsModal.form.quantity.label')}
                  type="number"
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Controller
              name="price"
              control={control}
              render={({ field, fieldState }) => (
                <FormNumberInput
                  label={t('menu.itemsModal.form.price.label')}
                  maskProps={{
                    decimalScale: 2,
                    fixedDecimalScale: true,
                    suffix: ` ${meProfile?.currency?.code || 'TRY'}`,
                  }}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={1}>
            <Button
              sx={{
                alignSelf: 'flex-end',
                mb: { xs: '10px', md: '14px' },
                mt: { md: '30px' },
              }}
              onClick={handleConfirm}
              loading={loading}
            >
              {itemToEdit
                ? t('menu.itemsModal.edit')
                : t('menu.itemsModal.add')}
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ mb: 2 }} />
        {!menuItems?.length ? (
          <Typography variant="subtitle2">{t('menu.emptyItems')}</Typography>
        ) : null}

        <Grid container spacing={2}>
          {menuItems
            ?.slice(0)
            .sort((a, b) => {
              const item1 = new Date(a.created_at);
              const item2 = new Date(b.created_at);
              return item1.getTime() - item2.getTime();
            })
            .map(item => {
              const product = allProducts.find(p => p.id === item.product_id);

              const itemName = product
                ? ` ${item.quantity} X ${
                    getActiveLanguage(product.translations).name
                  }`
                : 'unknown';

              return (
                <Grid item key={item.id} xs={6} sm={4} md={3} lg={3}>
                  <ProductCard
                    key={item.id}
                    name={itemName}
                    defaultUnit={{
                      name: getActiveLanguage(item.product_unit.translations)
                        .name,
                      price: item.additional_price.formatted,
                    }}
                    selected={item.id === itemToEdit}
                    onDelete={() => setItemToDelete(item.id)}
                    onClick={() => {
                      if (item.id === itemToEdit) {
                        resetForm();
                      } else {
                        setItemToEdit(item.id);
                        reset({
                          price: parseFloat(item.additional_price.amount) / 100,
                          productId: item.product_id,
                          productUnitId: item.product_unit.id,
                          quantity: item.quantity.toString(),
                        });
                      }
                    }}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Modal>
      <ConfirmationModal
        title={t('menu.deleteConfirmation.title')}
        titleIconName="QuestionMarkOutlined"
        description={t('menu.deleteConfirmation.description')}
        open={!!itemToDelete}
        onClose={() => {
          setItemToDelete(null);
        }}
        positiveAction={t('menu.deleteConfirmation.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('menu.deleteConfirmation.cancel')}
        onNegativeClick={() => {
          setItemToDelete(null);
        }}
        loading={loading}
      />
    </>
  );
}

export default MenuItemsModal;
