import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../services/api';

export const getKitchenGroupListThunk = createAsyncThunk(
  'KitchenGroup/list',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.definitions.kitchenGroups.list();
      const result = response.data.data;
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
