import React from 'react';
import { Grid } from '@mui/material';

interface Props {
  time: number;
  backgroundColor: string;
  color: string;
  notShowHour?: boolean;
}

export default function Timer({
  time,
  backgroundColor,
  color,
  notShowHour,
}: Props) {
  return (
    <Grid
      // @ts-ignore
      sx={{ backgroundColor, textAlign: 'center', color }}
      className="timer"
    >
      {!notShowHour && (
        <span className="digits">
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {`0${Math.floor((time / (60000 * 60)) % 24)}`.slice(-2)}:
        </span>
      )}
      <span className="digits">
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {`0${Math.floor((time / 60000) % 60)}`.slice(-2)}:
      </span>
      <span className="digits">
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {`0${Math.floor((time / 1000) % 60)}`.slice(-2)}
      </span>
    </Grid>
  );
}
