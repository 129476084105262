/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Divider,
  Grid,
  ListItemText,
  ListSubheader,
  MenuItem as MenuItemComponent,
  Select,
  Tab,
  Tabs,
} from '@mui/material';

interface Props {
  selectActive: number;
  setSelectActive: (value: ((prevState: number) => number) | number) => void;
  setPage: (value: ((prevState: number) => number) | number) => void;
  statusIds: number[];
  setStatusIds: (value: ((prevState: number[]) => number[]) | number[]) => void;
}

function OrdersTableHeader({
  selectActive,
  setSelectActive,
  setPage,
  statusIds,
  setStatusIds,
}: Props) {
  const { t } = useTranslation('orders');

  const sortOptions = [
    { id: 1, text: t('order.placed') },
    { id: 2, text: t('order.preparing') },
    { id: 3, text: t('order.ready') },
    { id: 5, text: t('order.picked_up') },
  ];

  const status = [
    {
      id: 'active',
      text: t('order.status.active'),
    },
    {
      id: 'completed',
      text: t('order.status.completed'),
    },
    {
      id: 'canceled',
      text: t('order.status.canceled'),
    },
  ];

  const getStatusText = (status_id: number) => {
    return status_id === 1
      ? t('order.placed')
      : status_id === 2
      ? t('order.preparing')
      : status_id === 3
      ? t('order.ready')
      : status_id === 5
      ? t('order.picked_up')
      : status_id === 16
      ? t('order.completed')
      : t('order.cancelled');
  };

  return (
    <>
      <Grid
        p={2}
        container
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Grid item>
          <Tabs
            value={selectActive}
            onChange={(_, value) => {
              setPage(1);
              if (value !== 0) {
                setStatusIds([]);
              }
              setSelectActive(value);
            }}
            variant="scrollable"
            centered
            scrollButtons="auto"
          >
            {status.map(item => (
              <Tab key={item.id} label={item.text} />
            ))}
          </Tabs>
        </Grid>
        {selectActive === 0 && (
          <Grid item>
            <Select
              variant="filled"
              SelectDisplayProps={{
                style: { paddingTop: 10, paddingBottom: 10 },
              }}
              sx={{
                borderRadius: '20px',
                background: 'rgba(183,183,183,0.96)',
                color: 'white',
                '.MuiSvgIcon-root ': {
                  color: 'white !important',
                },
                '& [aria-expanded=true]': {
                  background: 'rgba(183,183,183,0.96)',
                  borderRadius: '20px',
                  height: '15px',
                },
                '& [aria-expanded=false]': {
                  background: 'rgba(183,183,183,0.96)',
                  borderRadius: '20px',
                  height: '15px',
                },
                '& .MuiSelect-select .notranslate::after': {
                  content: `"${t('order.filter.status_sub_header')}"`,
                  opacity: 0.8,
                  height: '15px',
                },
                height: '35px',
                width: '100%',
                fontSize: '13px',
                marginTop: '8px',
              }}
              displayEmpty
              disableUnderline
              value={statusIds}
              placeholder="xxxx"
              renderValue={selected =>
                selected.length
                  ? selected.map(
                      (item, index) =>
                        `${getStatusText(item)}${
                          index + 1 < selected.length ? ', ' : ''
                        } `,
                    )
                  : t('order.filter.status_sub_header')
              }
              multiple
            >
              <ListSubheader sx={{ alignItems: 'center', textAlign: 'center' }}>
                {t('order.filter.status_sub_header')}
              </ListSubheader>
              <Divider />
              {sortOptions.map(group => (
                <MenuItemComponent
                  onClick={() => {
                    if (statusIds.includes(group.id)) {
                      setStatusIds(statusIds.filter(item => item !== group.id));
                    } else {
                      setStatusIds([...statusIds, group.id]);
                    }
                  }}
                  key={group.text}
                  value={group.text}
                >
                  <Checkbox checked={statusIds.includes(group.id)} />
                  <ListItemText primary={group.text} />
                </MenuItemComponent>
              ))}
            </Select>
          </Grid>
        )}
      </Grid>
      <br />
      <br />
    </>
  );
}

export default OrdersTableHeader;
