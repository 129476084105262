import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/Modal';
import useAuth from '../../../../hooks/useAuth';
import { OtpIcon } from '../../../../components/svg';
import LoadingState from '../../../../components/LoadingState';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (code: string) => void;
  onResend: () => void;
  userPhone: string;
}
function GDPRModal({ open, onClose, onConfirm, onResend, userPhone }: Props) {
  const { t } = useTranslation('companyRegister');
  const { logout } = useAuth();
  const [value, setValue] = useState<string>('');

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  const handleComplete = () => {
    // fetch('...')
  };

  if (!userPhone) {
    return <LoadingState />;
  }

  return (
    <Modal
      open={open}
      onClose={() => {}}
      title={t('buttons.title')}
      positiveAction={t('buttons.complete')}
      extraActionOne={t('buttons.resend')}
      negativeAction={t('buttons.logout')}
      onExtraActionOneClick={() => onResend()}
      onNegativeClick={() => logout()}
      onPositiveClick={() => onConfirm(value)}
      disableEscapeKeyDown
    >
      <Typography variant="h5" fontWeight="bold" textAlign="center">
        <OtpIcon />
      </Typography>
      <Typography
        variant="subtitle1"
        fontWeight="bold"
        textAlign="center"
        sx={{ mb: 2, color: 'gray' }}
      >
        {t('otp.title', { userPhone })}
      </Typography>
      <br />
      <MuiOtpInput
        value={value}
        onChange={handleChange}
        onComplete={handleComplete}
        length={6}
        validateChar={() => true}
        textTransform={undefined}
        sx={{
          display: 'flex',
          gap: { xs: 0, sm: '30px' },
        }}
      />
      <br />
    </Modal>
  );
}

export default GDPRModal;
