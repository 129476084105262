/** Dependencies */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/** Styles */
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { grey } from '@mui/material/colors';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import AccountCircle from '@mui/icons-material/AccountCircle';
import TodayIcon from '@mui/icons-material/Today';
import EventIcon from '@mui/icons-material/Event';
import HttpsIcon from '@mui/icons-material/Https';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import EventNoteIcon from '@mui/icons-material/EventNote';

/** Hooks */
import useAuth from '../../hooks/useAuth';
import useProfile from '../../hooks/useProfile';
import useBranches from '../../hooks/useBranches';

/** Components */
import ConfirmationModal from '../ConfirmationModal';
import ExpenseAddModal from '../../pages/Operations/ExpenseManagement/Modals/ExpenseAddModal';

/** Helpers */
import keyHasPermission from '../../helpers/keyHasPermission';

interface Props {
  anchor: null | HTMLElement;
  onClose: () => void;
  getExpenseModalData: () => void;
  onExpenseClose: () => void;
  expenseAddModal: boolean;
  token: string | null;
}
function MobileMenu({
  anchor,
  onClose,
  getExpenseModalData,
  onExpenseClose,
  expenseAddModal,
  token,
}: Props) {
  const { t } = useTranslation('layout');
  const { logout, logoutWithPin } = useAuth();
  const { meProfile } = useProfile();
  const { updateBranchesStartDate, updateBranchesEndDate } = useBranches();
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/profile');
    onClose();
  };

  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(
    null,
  );

  const hasEndOfDayPermission = keyHasPermission(
    'end-of-day',
    meProfile?.permissions || [],
  );

  const hasCreateExpensePermission = keyHasPermission(
    'create-expense',
    meProfile?.permissions || [],
  );

  return (
    <Box>
      <Menu
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchor)}
        onClose={onClose}
      >
        {hasCreateExpensePermission && (
          <MenuItem onClick={() => getExpenseModalData()}>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              sx={{ color: grey[700] }}
              disabled={Boolean(token)}
            >
              <TrendingDownIcon />
            </IconButton>
            <p>{t('topbar.fastExpense')}</p>
          </MenuItem>
        )}
        {hasEndOfDayPermission && (
          <MenuItem
            onClick={() =>
              setDeleteConfirmation(
                meProfile?.activeBranch?.day_id === null
                  ? 'start-date'
                  : 'end-date',
              )
            }
          >
            {meProfile?.activeBranch?.day_id === null ? (
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                sx={{ color: grey[700] }}
                disabled={Boolean(token)}
              >
                <TodayIcon />
              </IconButton>
            ) : (
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                sx={{ color: grey[700] }}
                disabled={Boolean(token)}
              >
                <EventIcon />
              </IconButton>
            )}
            <p>
              {meProfile?.activeBranch?.day_id === null
                ? t('topbar.start_Day')
                : t('topbar.end_Day')}
            </p>
          </MenuItem>
        )}
        {meProfile?.installed_apps?.find(
          item => item.slug === 'customer-display-screen',
        ) && (
          <MenuItem
            onClick={() => {
              window.open(
                '/customer-display',
                '_blank',
                'rel=noopener noreferrer',
              );
            }}
          >
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              sx={{ color: grey[700] }}
              disabled={Boolean(token)}
            >
              <EventNoteIcon />
            </IconButton>
            <p>{t('topbar.display')}</p>
          </MenuItem>
        )}
        <MenuItem onClick={logoutWithPin}>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            sx={{ color: grey[700] }}
            disabled={Boolean(token)}
          >
            <HttpsIcon />
          </IconButton>
          <p>{t('topbar.lock')}</p>
        </MenuItem>
        <MenuItem onClick={logoutWithPin}>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            sx={{ color: grey[700] }}
            disabled={Boolean(token)}
          >
            <HttpsIcon />
          </IconButton>
          <p>{t('topbar.lock')}</p>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <IconButton
            size="large"
            aria-label="profile"
            sx={{ color: grey[700] }}
            disabled={Boolean(token)}
          >
            <AccountCircle />
          </IconButton>
          <p>{t('topbar.profile')}</p>
        </MenuItem>
        <MenuItem
          disabled={Boolean(token)}
          style={{ marginLeft: '14px' }}
          onClick={() => logout()}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t('topbar.logout')}
        </MenuItem>
      </Menu>

      <ConfirmationModal
        title={
          deleteConfirmation === 'start-date'
            ? t('topbar.confirmation.startDayTitle')
            : t('topbar.confirmation.endDayTitle')
        }
        titleIconName="QuestionMarkOutlined"
        description={
          deleteConfirmation === 'start-date'
            ? t('topbar.confirmation.startDaySubtitle')
            : t('topbar.confirmation.endDaySubtitle')
        }
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        positiveAction={t('topbar.confirmation.confirm')}
        onPositiveClick={() => {
          if (deleteConfirmation === 'start-date') {
            updateBranchesStartDate(meProfile?.activeBranch?.id || '');
          }
          if (deleteConfirmation === 'end-date') {
            updateBranchesEndDate(meProfile?.activeBranch?.id || '');
          }
          setDeleteConfirmation(null);
        }}
        negativeAction={t('topbar.confirmation.cancel')}
        onNegativeClick={() => setDeleteConfirmation(null)}
      />
      <ExpenseAddModal
        open={expenseAddModal}
        onClose={onExpenseClose}
        item={null}
      />
    </Box>
  );
}

export default MobileMenu;
