import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Tab, Tabs } from '@mui/material';
import { a11yProps } from '../../helpers/rgbToHex';
import TabPanel from '../CompanyRegister/TabPanel';
import OnlineFoodOrderSettings from './OnlineFoodOrderSettings';
import InfoSettings from './InfoSettings';
import keyHasPermission from '../../helpers/keyHasPermission';
import useSettings from '../../hooks/useSettings';

function OnlineFoodOrder() {
  const { t } = useTranslation('apps');
  const { meProfile } = useSettings();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const hasViewOnlineFoodDelivery = keyHasPermission(
    'view-online-food-delivery',
    meProfile?.permissions || [],
  );

  const hasUpdateOnlineFoodDelivery = keyHasPermission(
    'update-online-food-delivery',
    meProfile?.permissions || [],
  );

  return (
    <div>
      {hasViewOnlineFoodDelivery && hasUpdateOnlineFoodDelivery && (
        <Paper sx={{ p: 3, flex: 1 }} elevation={3}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label={t('online_food_order.tab1')} {...a11yProps(0)} />
              <Tab label={t('online_food_order.tab2')} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <OnlineFoodOrderSettings />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <InfoSettings />
          </TabPanel>
        </Paper>
      )}
    </div>
  );
}

export default OnlineFoodOrder;
