import { Box } from '@mui/material';
import React from 'react';

interface Props {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel({ value, index, children }: Props) {
  return value === index ? <Box>{children}</Box> : null;
}

export default TabPanel;
