import { PosTransaction } from '../../../models/Pos';
import api from '../api';

import { ApiSuccessResponse } from '../types';
import {
  OrderTransactionDiscountParams,
  PackageOrderParams,
  SpecialOrderParams,
  TableOrderParams,
} from './types';
import { Payment } from '../../../apps/Pos/Sections/OrderModal/PaymentModal/types';

type TableOrderRes = ApiSuccessResponse<{ id: string }>;
const tableOrder = async (params: Omit<TableOrderParams, 'type'>) =>
  api.post<TableOrderRes>('/orders', { type: 1, ...params });

type RemoveOrderRes = ApiSuccessResponse<{ message: string }>;
const removeOrderItem = async (order_id: string, quantity: number) =>
  api.post<RemoveOrderRes>(`/orders/destroy`, {
    order_id,
    quantity,
  });

type GuestCountRes = ApiSuccessResponse<{ message: string }>;
const setGuestCount = (
  table_id: string,
  guest_count: number,
  order_transaction_id: string,
  type: number,
  is_automatic_couvert: boolean,
) =>
  api.post<GuestCountRes>(`/order-transactions/set-guest-count`, {
    guest_count,
    table_id,
    order_transaction_id,
    type,
    is_automatic_couvert,
  });

const setServiceCharge = (
  table_id: string,
  is_automatic_service_charge: boolean,
) =>
  api.post<GuestCountRes>(`/order-transactions/service-charge`, {
    table_id,
    is_automatic_service_charge,
  });

type TableNameRes = ApiSuccessResponse<{ message: string }>;
const setTableName = (tableId: string, table_name: string) =>
  api.post<TableNameRes>(`/order-transactions/${tableId}/set-table-name`, {
    table_name,
  });

const setDeliverer = (orderTransactionId: string, delivererId: string) =>
  api.post<TableNameRes>(
    `/order-transactions/${orderTransactionId}/set-deliverer`,
    {
      deliverer_id: delivererId,
    },
  );

type OrderDescriptionRes = ApiSuccessResponse<{ message: string }>;
const setOrderDescription = (
  description: string,
  table_id: string,
  order_transaction_id: string,
  type: number,
) =>
  api.post<OrderDescriptionRes>(`/order-transactions/set-description`, {
    description,
    table_id,
    order_transaction_id,
    type,
  });

type OrderCustomerRes = ApiSuccessResponse<{ message: string }>;
const setOrderCustomer = (
  table_id: string,
  order_transaction_id: string,
  type: number,
  customer_id?: string,
  customer_address_id?: string,
) =>
  api.post<OrderCustomerRes>(`/order-transactions/set-customer`, {
    table_id,
    order_transaction_id,
    type,
    customer_id,
    customer_address_id,
  });

type OrderDetailsRes = ApiSuccessResponse<PosTransaction>;
const getOrderDetails = (tableId: string) =>
  api.get<OrderDetailsRes>(
    `/order-transactions/${tableId}/active-order-transaction`,
  );

const getOrderTransaction = (orderId: string) =>
  api.get<OrderDetailsRes>(`/order-transactions/${orderId}/order-transaction`);

type ChangeTableRes = ApiSuccessResponse<{ message: string }>;
const changeTable = (from: string, to: string) =>
  api.post<ChangeTableRes>(`/change-table`, { from, to });

type MoveTableTableRes = ApiSuccessResponse<{ message: string }>;
const moveTableOrder = (from: string, to: string) =>
  api.post<MoveTableTableRes>(`/move-table-order`, { from, to });

type SplitTableRes = ApiSuccessResponse<{ message: string }>;
const splitTable = (table_id: string) =>
  api.post<SplitTableRes>(`/split-table`, { table_id });

type MergeTablesRes = ApiSuccessResponse<{ message: string }>;
const mergeTables = (main_table_id: string, table_ids: string[]) =>
  api.post<MergeTablesRes>(`/merge-tables`, { main_table_id, table_ids });

type SpecialOrderRes = ApiSuccessResponse<{ message: string }>;
const addSpecialOrder = (params: SpecialOrderParams) =>
  api.post<SpecialOrderRes>(`/orders/special-order`, params);

type OrderTransactionDiscountRes = ApiSuccessResponse<{ message: string }>;
const addOrderTransactionDiscount = (params: OrderTransactionDiscountParams) =>
  api.post<OrderTransactionDiscountRes>(`/order-transaction-discounts`, params);

type CancelTableRes = ApiSuccessResponse<{
  tableId: string;
  cancellation_description: string;
  is_excluded_report: Number;
}>;
const cancelTable = (
  tableId: string,
  cancellation_description: string,
  is_excluded_report: Number,
) =>
  api.post<CancelTableRes>(`order-transactions/${tableId}/cancel`, {
    cancellation_description,
    is_excluded_report,
  });

const removeOrderDiscount = async (transactionId: string) =>
  api.delete(`/order-transactions/${transactionId}/remove-discount`);

type OrderTransactionPaymentsRes = ApiSuccessResponse<{ message: string }>;
const orderTransactionPayments = (params: Payment) =>
  api.post<OrderTransactionPaymentsRes>(`/order-transaction-payments`, params);

type OrderTransactionPaymentRemoveRes = ApiSuccessResponse<{ message: string }>;
const orderTransactionPaymentRemove = (id: string) =>
  api.delete<OrderTransactionPaymentRemoveRes>(
    `/order-transaction-payments/${id}`,
  );

const getInactiveOrderTransactions = (table_id: string, day_id: string) =>
  api.get('/order-transactions', {
    params: {
      is_active: 0,
      filter: {
        table_id,
        day_id,
      },
      sort: '-ended_at',
    },
  });
const getOpenAccountTransactions = (customer_id: string, page: number) =>
  api.get('/order-transaction-payments', {
    params: {
      filter: {
        customer_id,
      },
      page,
    },
  });

type OrderActiveRes = ApiSuccessResponse<{ message: string }>;
const setOrderActive = (orderTransactionId: string) =>
  api.post<OrderActiveRes>(
    `/order-transactions/${orderTransactionId}/set-active`,
  );

type OrderGiftRes = ApiSuccessResponse<{ message: string }>;
const setOrderGift = (order_id: string, quantity: number) =>
  api.post<OrderGiftRes>(`/orders/treat`, {
    order_id,
    quantity,
  });

type RemoveOrderGiftRes = ApiSuccessResponse<{ message: string }>;
const removeOrderGift = (order_id: string, quantity: number) =>
  api.post<RemoveOrderGiftRes>(`/orders/undo-treat`, {
    order_id,
    quantity,
  });

export default {
  tableOrder,
  setGuestCount,
  setTableName,
  setOrderCustomer,
  setOrderDescription,
  getOrderDetails,
  getOrderTransaction,
  changeTable,
  moveTableOrder,
  splitTable,
  mergeTables,
  removeOrderItem,
  cancelTable,
  addSpecialOrder,
  addOrderTransactionDiscount,
  removeOrderDiscount,
  orderTransactionPayments,
  orderTransactionPaymentRemove,
  getInactiveOrderTransactions,
  getOpenAccountTransactions,
  setOrderActive,
  setDeliverer,
  setOrderGift,
  removeOrderGift,
  setServiceCharge,
};
