import { Grid, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormNumberInput from '../../../components/FormNumberInput';
import FormInputSelect from '../../../components/FormInputSelect';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';
import arrayHasDuplicates from '../../../helpers/arrayHasDuplicates';
import usePropertyGroups from '../../../hooks/usePropertyGroups';
import { NameTranslation } from '../../../models/General';
import { PropertyItem } from '../../../models/Property';
import { useAppSelector } from '../../../store';
import errorReporting from '../../../helpers/errorReporting';
import { OptionsLanguageMemo } from '../../../helpers/optionMemo';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  item?: Omit<PropertyItem, 'property_group'> | null;
  groupId: string | undefined;
}

function PropertyItemAddModal({
  open,
  onClose,
  onConfirm,
  item,
  groupId,
}: Props) {
  const { t } = useTranslation('property');
  const { enqueueSnackbar } = useSnackbar();

  const { addPropertyItem, updatePropertyItem, loading } = usePropertyGroups();

  const { list } = useAppSelector(state => state.languages);

  const options = OptionsLanguageMemo(list);

  const initialValue: NameTranslation[] = [
    {
      id: Math.random().toString(),
      name: '',
      lang: options[0].id,
    },
  ];

  const [names, setNames] = useState<NameTranslation[]>(initialValue);

  const [price, setPrice] = useState<string>('');

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (item) {
      setNames(item.translations);
      setPrice((parseInt(item.additional_price.amount, 10) / 100).toString());
    }
    return () => {
      setNames(initialValue);
      setPrice('');
    };
  }, [item]);

  useEffect(() => {
    names.forEach(element => {
      if (!element.lang) {
        const temp = names.map(el =>
          el.id === element.id ? { ...element, lang: options[0].id } : el,
        );
        setNames(temp);
      }
    });
  }, []);

  const handleClose = () => {
    setIsSubmitted(false);
    setNames(initialValue);
    setPrice('');
    onClose();
  };

  const handleAddItem = () => {
    let suggestedLang = options[0].id;
    options.forEach(opt => {
      if (names.find(a => a.lang !== opt.id)) {
        suggestedLang = opt.id;
      }
    });
    setNames([
      ...names,
      {
        id: Math.random().toString(),
        name: '',
        lang: suggestedLang,
      },
    ]);
  };

  const handleConfirm = async () => {
    if (!names.some(el => !el.name || !el.lang) && price) {
      try {
        if (arrayHasDuplicates(names, 'lang')) {
          enqueueSnackbar(t('items.addModal.messages.duplicateLanguage'), {
            variant: 'error',
            autoHideDuration: 3000,
          });
          return;
        }

        const constructedName: Record<string, string> = {};

        names.forEach(({ lang: langId, name }) => {
          constructedName[langId] = name;
        });
        if (groupId) {
          if (!item?.id) {
            await addPropertyItem(
              groupId,
              constructedName,
              parseFloat(price) * 100, // api handles float values
            );
          } else {
            await updatePropertyItem({
              groupId,
              id: item.id,
              name: constructedName,
              additional_price: parseFloat(price) * 100, // api handles float values
            });
          }
        }
        if (onConfirm) onConfirm();
        setIsSubmitted(false);
        setNames(initialValue);
        setPrice('');
      } catch (err) {
        errorReporting(err);
      }
    } else {
      setIsSubmitted(true);
    }
  };

  return (
    <Modal
      title={t('items.addModal.title', {
        context: item ? 'edit' : 'default',
      })}
      titleIconName={item ? 'Edit' : 'Add'}
      open={open}
      onClose={handleClose}
      positiveAction={t('items.addModal.confirm')}
      negativeAction={t('items.addModal.cancel')}
      onPositiveClick={handleConfirm}
      onNegativeClick={handleClose}
      loading={loading}
    >
      {names.map((element, index) => {
        const isNameInvalid = isSubmitted && (!element.name || !element.lang);

        return (
          <Grid container key={element.id}>
            <Grid item xs={10}>
              <FormInputSelect
                label={t('items.addModal.form.name.label')}
                error={isNameInvalid}
                helperText={
                  isNameInvalid && t('items.addModal.form.name.required')
                }
                value={element.name}
                onChange={event => {
                  const temp = names.map(el =>
                    el.id === element.id
                      ? { ...element, name: event.target.value }
                      : el,
                  );
                  setNames(temp);
                }}
                options={options}
                selectValue={element.lang}
                onSelectValueChange={id => {
                  const selectedLanguage = options.find(lang => lang.id === id);
                  if (selectedLanguage) {
                    const temp = names.map(el =>
                      el.id === element.id
                        ? { ...element, lang: selectedLanguage.id }
                        : el,
                    );
                    setNames(temp);
                  }
                }}
              />
            </Grid>
            <Grid item sx={{ pt: 4 }}>
              {/* // Show only delete button if input is not the last one */}
              {index === names.length - 1 ? (
                <>
                  {/* // Show delete button near to add button if we have more than one input only */}
                  {names.length > 1 && (
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        setNames(names.filter(el => el.id !== element.id))
                      }
                      color="error"
                    >
                      <Icon name="Delete" />
                    </IconButton>
                  )}
                  {/* // Show add button only if category is less than available languages */}
                  {names.length < options.length && (
                    <IconButton
                      aria-label="add"
                      onClick={handleAddItem}
                      color="success"
                    >
                      <Icon name="Add" />
                    </IconButton>
                  )}
                </>
              ) : (
                <IconButton
                  aria-label="delete"
                  onClick={() =>
                    setNames(names.filter(el => el.id !== element.id))
                  }
                  color="error"
                >
                  <Icon name="Delete" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        );
      })}
      <FormNumberInput
        value={price}
        placeholder="0,00"
        onChange={e => setPrice(e.target.value)}
        label={t('items.addModal.form.price.label')}
        maskProps={{ decimalScale: 2, fixedDecimalScale: true, suffix: ' TL' }}
        error={isSubmitted && !price}
        helperText={
          isSubmitted && !price && t('items.addModal.form.price.required')
        }
      />
    </Modal>
  );
}

export default PropertyItemAddModal;
