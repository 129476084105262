/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import { Box } from '@mui/material';

/** Components */
import UtilitiesBar, {
  Props as BarProps,
} from '../../../components/UtilitiesBar';
import Form from './Forms/Form';
import useIntegrations from '../../../hooks/useIntegrations';
import LoadingState from '../../../components/LoadingState';
import useProfile from '../../../hooks/useProfile';

function TrendyolSettings() {
  const { t } = useTranslation('integrations');
  const {
    fetchSyncList,
    fetchIngredientsSettings,
    fetchPropertySettings,
    fetchProductSettings,
    loading,
  } = useIntegrations();
  const { meProfile } = useProfile();

  const [value, setValue] = useState(0);

  const MENU: BarProps['menu'] = [
    {
      id: 0,
      text: 'Tümünü Güncelle',
      startIconName: 'Cached',
      permissionKeys: [],
    },
  ];

  const handleReset = async () => {
    try {
      await fetchSyncList('trendyol');

      if (value === 1) {
        await fetchProductSettings('trendyol', 1, '', '');
      } else if (value === 2) {
        await fetchPropertySettings('trendyol', 1, '', '');
      } else if (value === 3) {
        await fetchIngredientsSettings('trendyol', 1, '', '');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAction = (id: number) => {
    switch (id) {
      case 0:
        handleReset();
        break;
      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('title')}
        imageUrl={
          meProfile?.installed_apps?.find(item => item.slug === 'trendyol')
            ?.logo || ''
        }
        menu={MENU}
        onActionClick={handleAction}
      />

      {loading ? <LoadingState /> : <Form value={value} setValue={setValue} />}
    </Box>
  );
}

export default TrendyolSettings;
