/** Dependencies */
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Paper, Stack } from '@mui/material';

/** Components */
import LoadingState from '../../components/LoadingState';
import EmptyState from '../../components/EmptyState';

/** Hooks */
import useKitchenOrder from '../../hooks/useKitchenOrder';
import useKitchenGroups from '../../hooks/useKitchenGroups';

/** Styles */
import KitchenFilterBar from './components/KitchenFilterBar';
import OrderItemCard from './components/OrderItemCard';
import styles from './styles';
import { OptionsMemoWithName } from '../../helpers/optionMemo';

function Kitchen() {
  const {
    getOrderItemGroups,
    orderItemGroupsDetails,
    updateOrderItemGroup,
    updateOrderItem,
    changeOrderItemGroupState,
    orderItemGroupsDetailsLoading,
  } = useKitchenOrder();
  const { t } = useTranslation('kitchen');

  const { kitchenGroups } = useKitchenGroups();

  const [searchValue, setSearchValue] = useState<{
    inputValue: string;
    isActive: boolean | null;
  }>({
    inputValue: '',
    isActive: true,
  });

  const [debouncedSearch, setDebouncedSearch] = useState({
    inputValue: '',
    isActive: true,
  });

  const [selectValue, setSelectValue] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const kitchenGroupsOptions = useMemo(
    () =>
      kitchenGroups.map(item => ({
        id: item.id,
        text: item.name,
      })),
    [kitchenGroups],
  );

  const debouncedAndFilteredSearch = async () => {
    // @ts-ignore
    const kitchenGroupsIds: string[] =
      selectValue
        .map(
          item =>
            kitchenGroupsOptions?.find(kitchenItem => kitchenItem.text === item)
              ?.id,
        )
        .filter(filteredKitchenItems => !!filteredKitchenItems) || [];

    try {
      await getOrderItemGroups(
        debouncedSearch.inputValue,
        true,
        kitchenGroupsIds.length ? kitchenGroupsIds : null,
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleOrderItemUpdate = async (status: number, item_id: string) => {
    try {
      await updateOrderItem(status, item_id);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const handleOrderItemGroupUpdate = async (
    status: number,
    checkNumber: string,
  ) => {
    try {
      await updateOrderItemGroup(status, checkNumber);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const handleItemGroupsChangeState = async (checkNumber: string) => {
    try {
      await changeOrderItemGroupState(checkNumber);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  return (
    <Paper sx={styles.kitchenPaper}>
      <KitchenFilterBar
        selectValue={selectValue}
        setSelectValue={setSelectValue}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        debouncedSearch={debouncedSearch}
        setDebouncedSearch={setDebouncedSearch}
        debouncedAndFilteredSearch={debouncedAndFilteredSearch}
      />
      {loading || orderItemGroupsDetailsLoading ? (
        <LoadingState />
      ) : (
        <Stack>
          <Grid container spacing={0}>
            {!orderItemGroupsDetails?.length ? (
              <EmptyState message={t('order.no_order')} />
            ) : (
              orderItemGroupsDetails?.map(orderItemGroup => (
                <OrderItemCard
                  orderItemGroup={orderItemGroup}
                  handleOrderItemUpdate={handleOrderItemUpdate}
                  handleOrderItemGroupUpdate={handleOrderItemGroupUpdate}
                  handleItemGroupsChangeState={handleItemGroupsChangeState}
                />
              ))
            )}
          </Grid>
        </Stack>
      )}
    </Paper>
  );
}

export default Kitchen;
