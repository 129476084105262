/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { matchIsValidTel } from 'mui-tel-input';

/** Types */
import { CustomerBase } from '../types';

/** Components */
import FormInput from '../../../../components/FormInput';
import FormSwitch from '../../../../components/FormSwitch';
import FormPhoneInput from '../../../../components/FormPhoneInput';
import LoadingState from '../../../../components/LoadingState';
import FormNumberInput from '../../../../components/FormNumberInput';

/** Hooks */
import useCustomers from '../../../../hooks/useCustomers';
import useSettings from '../../../../hooks/useSettings';

interface Props {
  item?: CustomerBase | null;
  control: Control<CustomerBase, Object>;
  value: string;
  handleChange(newValue: any, info: any): void;
}

function Form({ item, control, value, handleChange }: Props) {
  const { t } = useTranslation('customer');
  const { customerLoading, getCustomer } = useCustomers();
  const { meProfile } = useSettings();
  const [openInput, setOpenInput] = useState<boolean>(false);

  useEffect(() => {
    if (item) {
      getCustomer(item.id);

      if (item?.is_open_account === true) {
        setOpenInput(true);
      }
    } else {
      setOpenInput(false);
    }
  }, [item]);

  useEffect(() => {
    if (!item) {
      setOpenInput(false);
    }
  }, []);

  const openHandle = () => {
    if (openInput) {
      setOpenInput(false);
    } else {
      setOpenInput(true);
    }
  };
  return (
    <>
      <Controller
        name="first_name"
        // @ts-ignore
        control={control}
        render={({ field, fieldState }) => (
          <FormInput
            label={t('customer.addModal.form.first_name.label')}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            {...field}
          />
        )}
      />
      <Controller
        name="last_name"
        // @ts-ignore
        control={control}
        render={({ field, fieldState }) => (
          <FormInput
            label={t('customer.addModal.form.last_name.label')}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            {...field}
          />
        )}
      />
      <Controller
        name="phone_number"
        // @ts-ignore
        control={control}
        rules={{ validate: matchIsValidTel }}
        render={({ field }) => (
          <FormPhoneInput
            label={t('customer.addModal.form.phone_number.label')}
            phoneValue={value}
            handleChange={handleChange}
            {...field}
          />
        )}
      />

      <Controller
        name="is_open_account"
        // @ts-ignore
        control={control}
        defaultValue={false}
        render={({ field, fieldState }) => (
          <FormSwitch
            onClick={() => {
              openHandle();
            }}
            label={t('customer.addModal.form.first_name.openAccount')}
            error={fieldState.invalid}
            {...field}
          />
        )}
      />

      {openInput &&
        (customerLoading ? (
          <LoadingState />
        ) : (
          <Controller
            name="chargeable_amount"
            // @ts-ignore
            control={control}
            render={({ field, fieldState }) => (
              <FormNumberInput
                label={t('customer.addModal.form.first_name.openAccountLimit')}
                placeholder={`0,00 ${meProfile?.currency?.code || 'TRY'}`}
                maskProps={{
                  decimalScale: 2,
                  fixedDecimalScale: true,
                  suffix: ` ${meProfile?.currency?.code || 'TRY'}`,
                }}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
        ))}
    </>
  );
}

export default Form;
