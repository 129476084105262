import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Checkbox,
  IconButton,
  keyframes,
  Radio,
  Typography,
} from '@mui/material';
import Button from '../Button';
import Icon from '../Icon';
import styles from './styles';
import TableSettingsModal from './TableSettingsModal';
import usePosOrder from '../../hooks/usePosOrder';
import { BasketProduct } from '../../models/Product';
import PaymentModal from '../../apps/Pos/Sections/OrderModal/PaymentModal';
import { TotalPrices } from '../../apps/Pos/Sections/OrderModal';
import FastPaymentModal from '../../apps/Pos/Sections/OrderModal/FastPaymentModal';
import useProfile from '../../hooks/useProfile';
import arrayKeyHasPermission from '../../helpers/arrayKeyHasPermission';
import useTables from '../../hooks/useTables';
import SetActiveTransactionModal from '../../apps/Pos/Sections/OrderModal/SetActiveTransactionModal';
import FullRestaurantTable from './FullRestaurantTable';
import EmptyRestaurantTable from './EmptyRestaurantTable';

interface Props {
  name: string;
  color?: string;
  totalPrice?: string;
  paidPrice?: string;
  onEditClick?: () => void;
  onClick?: () => void;
  full?: boolean;
  tableId?: string;
  createdBy?: string;
  createdAt?: string;
  setSelectedTableId?: any;
  selectedTableId?: string;
  setIsChangeable?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMoveOrder?: React.Dispatch<React.SetStateAction<boolean>>;
  isChangeable?: boolean;
  isMoveOrder?: boolean;
  setIsMergeable?: React.Dispatch<React.SetStateAction<boolean>>;
  isMergeable?: boolean;
  isMerged?: boolean;
  checked?: boolean;
  handleSplitTables?: () => void;
  tableActiveOrderTransactionsId?: string;
  waiterName?: string;
  tableName?: string;
  totalPriceAmount?: number;
  orderTransactionId?: string;
  discountPrice?: number;
  orderTableId?: string;
  paidAmount?: string;
  currencyCode?: string;
  sectionId?: string;
  colorStatus: number;
}
function RestaurantTable({
  name,
  color,
  totalPrice,
  paidPrice,
  createdBy,
  createdAt,
  full,
  onClick,
  onEditClick,
  setIsChangeable,
  setIsMergeable,
  isChangeable,
  isMergeable,
  tableId,
  orderTableId,
  setSelectedTableId,
  selectedTableId,
  isMerged,
  setIsMoveOrder,
  isMoveOrder,
  checked,
  handleSplitTables,
  tableActiveOrderTransactionsId,
  waiterName,
  tableName,
  totalPriceAmount,
  orderTransactionId,
  discountPrice,
  paidAmount,
  currencyCode,
  sectionId,
  colorStatus,
}: Props) {
  const blink = keyframes`
      from { color: green; }
      to { color: red }
`;
  const { addOrderTransactionPayment, getOrderDetails, orderDetails } =
    usePosOrder();
  const { meProfile } = useProfile();
  const { enqueueSnackbar } = useSnackbar();
  const { fetchTables } = useTables();
  const { t } = useTranslation('pos');

  const [open, setOpen] = useState<boolean>(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [fastPaymentModal, setFastPaymentModal] = useState(false);
  const [activeTransactionModal, setActiveTransactionModal] = useState(false);
  const [totalPrices, setTotalPrices] = useState<TotalPrices>({
    totalPrice: 0,
    taxTotal: 0,
    grossTotal: 0,
    paidTotal: 0,
    discountTotal: 0,
    treatTotal: 0,
    couvert: 0,
    serviceCharge: 0,
  });

  const handleBackClick = () => {
    if (sectionId) {
      fetchTables(sectionId);
    }
    setFastPaymentModal(false);
    setActiveTransactionModal(false);
    setPaymentModal(false);
    setOpen(false);
  };

  const handleCloseOrder = async () => {
    try {
      await addOrderTransactionPayment({
        order_transaction_id: tableActiveOrderTransactionsId || '',
        payment_action: 2,
        payments: [
          {
            amount: 0,
            payment_type_id: 1,
            remainder_amount: 0,
          },
        ],
      });

      enqueueSnackbar(t('sideMenu.orderClose'), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });

      handleBackClick();
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const handleActionClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      if (full) {
        setOpen(true);
      } else if (onEditClick) {
        onEditClick();
      }
      if (setSelectedTableId) {
        setSelectedTableId(tableId);
      }
    },
    [full],
  );

  const hasShowMorevert = arrayKeyHasPermission(
    [
      'get-order-payment',
      'move-table-order',
      'merge-tables',
      'split-table',
      'change-table',
      'cancel-order-transaction',
    ],
    meProfile?.permissions || [],
  );

  const hasTableMoreVertButtonPermission = arrayKeyHasPermission(
    [
      'get-order-payment',
      'move-table-order',
      'merge-tables',
      'split-table',
      'change-table',
      'cancel-order-transaction',
    ],
    meProfile?.permissions || [],
  );

  const restaurantButtonColor =
    full && totalPrice === paidPrice && Number(paidAmount) && colorStatus === 3
      ? 'success'
      : colorStatus === 3
      ? 'success'
      : 'error';

  const restaurantButtonStyle =
    totalPrice === paidPrice && Number(paidAmount) > 0 && colorStatus === 3
      ? [
          styles.container as any,
          full && styles.fullPaid,
          !onClick && styles.disabled,
        ]
      : colorStatus === 3
      ? [
          styles.container as any,
          full && styles.fullPaid,
          !onClick && styles.disabled,
        ]
      : [
          styles.container as any,
          full && styles.full,
          !onClick && styles.disabled,
        ];

  useEffect(() => {
    if (orderTableId && open) {
      getOrderDetails(orderTableId);

      setTotalPrices({
        ...totalPrices,
        totalPrice: totalPriceAmount || 0,
        discountTotal: discountPrice || 0,
      });
    }
  }, [open]);

  return (
    <Box>
      <Button
        disabled={full && (isMergeable || isChangeable)}
        variant="outlined"
        color={restaurantButtonColor}
        sx={restaurantButtonStyle}
        onClick={onClick}
        disableRipple={!onClick}
      >
        {/* Edit Icon */}
        {(onEditClick || full) &&
        !isChangeable &&
        !isMergeable &&
        !isMoveOrder &&
        hasShowMorevert ? (
          <Box sx={styles.rightTopCorner}>
            <IconButton
              sx={styles.button}
              color="info"
              onMouseDown={e => e.stopPropagation()}
              onClick={handleActionClick}
            >
              {hasTableMoreVertButtonPermission && (
                <Icon name={full ? 'MoreVert' : 'Edit'} />
              )}
            </IconButton>
          </Box>
        ) : null}

        {/* Merged Icon */}
        {isMerged || (color && orderTransactionId) ? (
          <Box sx={styles.rightBottomCorner}>
            <IconButton sx={styles.button}>
              <Icon sx={{ color }} name="AttachFile" />
            </IconButton>
          </Box>
        ) : null}

        {/* Changed Table Button (Radio) */}
        {(!full && isChangeable) ||
        (full && isMoveOrder && selectedTableId !== tableId) ? (
          <Box sx={styles.rightTopCorner}>
            <Radio
              sx={{ animation: `${blink} 1s linear infinite` }}
              checked={checked}
            />
          </Box>
        ) : null}

        {/* Mergeble Table Button (Checkbox) */}
        {!full && isMergeable ? (
          <Box sx={styles.rightTopCorner}>
            <Checkbox
              sx={{ animation: `${blink} 1s linear infinite` }}
              checked={checked}
            />
          </Box>
        ) : null}
        {!full &&
        !onEditClick &&
        !isMergeable &&
        !isMoveOrder &&
        !isChangeable ? (
          <EmptyRestaurantTable
            setActiveTransactionModal={setActiveTransactionModal}
            hasTableMoreVertButtonPermission={hasTableMoreVertButtonPermission}
          />
        ) : null}

        {full ? (
          <FullRestaurantTable
            totalPrice={totalPrice}
            paidPrice={paidPrice}
            createdBy={createdBy}
            createdAt={createdAt}
            full={full}
            tableName={tableName}
            paidAmount={paidAmount}
          />
        ) : (
          <Typography color="gray">{name}</Typography>
        )}
      </Button>
      <TableSettingsModal
        name={name}
        open={open}
        setOpen={setOpen}
        onClose={() => setOpen(false)}
        onOpenPaymentModal={() => {
          setOpen(false);
          setPaymentModal(true);
        }}
        onOpenFastPaymentModal={() => {
          setOpen(false);
          setFastPaymentModal(true);
        }}
        setIsMergeable={setIsMergeable}
        setIsChangeable={setIsChangeable}
        setIsMoveOrder={setIsMoveOrder}
        isMerged={isMerged || false}
        handleSplitTables={handleSplitTables}
        tableActiveOrderTransactionsId={tableActiveOrderTransactionsId || ''}
        isFullPaid={
          (totalPrice === paidPrice && Number(paidAmount) > 0) || false
        }
        handleCloseOrder={handleCloseOrder}
        handleBackClick={handleBackClick}
        tableId={orderTransactionId || ''}
      />
      <PaymentModal
        open={paymentModal}
        onClose={() => setPaymentModal(false)}
        // @ts-ignore
        list={
          orderDetails?.orders?.map((order: any) => ({
            id: order.id,
            created_by: order.created_by,
            is_submitted_order: true,
            is_active: true,
            is_menu: order.is_menu,
            is_required_property_selection: true,
            menus: [],
            payments: order.payments,
            has_special_product: order?.has_special_product || false,
            gross_amount: order?.gross_amount,
            unit_price: order?.unit_price,
            product: order.product,
            items: order.items,
            category: order?.items[0]?.category || {},
            count: order.quantity,
            kitchen_group: order?.items[0]?.category?.translations[0] || {},
            product_code: '620910011150',
            sequence: 4,
            tax_rate: 18,
            created_at: order.created_at,
            translations: order.items[0].product.translations,
            paid_quantity: order.paid_quantity,
            paid_amount: order.paid_amount,
            total_amount: order.total_amount,
            units: order?.items[0]?.product_unit?.map((item: any) => ({
              ...item,
              translations: item.unit.translations,
              is_default: true,
            })),
            property_group_items: order.items[0].property_group_items.map(
              (item: any) => ({
                ...item,
                product_property_group_item_id:
                  item.product_property_group_item_id,
                translations: item.translations,
                additional_price: item.additional_price,
                is_default: true,
              }),
            ),
            ingredient_group_items: order.items[0].ingredient_group_items.map(
              (item: any) => ({
                ...item,
                product_ingredient_group_item_id:
                  item.product_ingredient_group_item_id,
                translations: item.translations,
                additional_price: item.additional_price,
                is_default: true,
              }),
            ),
          })) || []
        }
        discountTotal={totalPrices.discountTotal || 0}
        tableId={orderTransactionId || ''}
        totalPrice={totalPrices.totalPrice || 0}
        waiterName={waiterName || ''}
        tableName={tableName || ''}
        onBackClick={() => handleBackClick()}
        removeDiscount={() => {
          setTotalPrices({
            ...totalPrices,
            totalPrice:
              totalPrices.totalPrice + totalPrices.discountTotal / 100,
            discountTotal: 0,
          });
        }}
        paidPayments={orderDetails?.payments || []}
        currencyCode={currencyCode || ''}
        isPackageOrder={false}
        isSelfOrder={false}
        selectedOrderTypeId={orderDetails?.preset_payment_type?.id || 0}
      />
      <FastPaymentModal
        open={fastPaymentModal}
        onClose={() => setFastPaymentModal(false)}
        totalPrice={totalPrices.totalPrice || 0}
        tableId={orderDetails?.id || ''}
        onBackClick={handleBackClick}
        currencyCode={currencyCode || ''}
        isSelfOrder={false}
        isSelfCreate={false}
      />
      <SetActiveTransactionModal
        open={activeTransactionModal}
        onClose={() => setActiveTransactionModal(false)}
        tableId={tableId || ''}
        onBackClick={() => handleBackClick()}
        tableName={name}
      />
    </Box>
  );
}

export default RestaurantTable;
