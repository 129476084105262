/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import { Grid } from '@mui/material';
import styles from '../../styles';

/** Components */
import MenuItemContent from './MenuItemContent';
import NonMenuItemContent from './NonMenuItemContent';

interface Props {
  orderItemGroup: any;
  handleOrderItemUpdate: (status: number, item_id: string) => void;
  handleItemGroupsChangeState: (checkNumber: string) => void;
}

function OrderItemCardContent({
  orderItemGroup,
  handleOrderItemUpdate,
  handleItemGroupsChangeState,
}: Props) {
  const { t } = useTranslation('kitchen');

  return (
    <>
      <Grid sx={styles.cardContent}>
        {orderItemGroup.orders.map((orders: any) =>
          orders.is_menu ? (
            <MenuItemContent
              orders={orders}
              orderItemGroup={orderItemGroup}
              handleOrderItemUpdate={handleOrderItemUpdate}
            />
          ) : (
            orders.items.map((item: any) => (
              <NonMenuItemContent
                item={item}
                orders={orders}
                orderItemGroup={orderItemGroup}
                handleOrderItemUpdate={handleOrderItemUpdate}
              />
            ))
          ),
        )}
      </Grid>
      {orderItemGroup.order_group_status === 3 && (
        <>
          <br />
          <Grid
            onClick={() => handleItemGroupsChangeState(orderItemGroup.id)}
            sx={styles.remove}
          >
            {t('order.remove')}
          </Grid>
        </>
      )}
    </>
  );
}

export default OrderItemCardContent;
