/** Dependencies */
import { Paper, Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import Pusher, { Channel } from 'pusher-js';

/** Components */
import EmptyState from '../../../../components/EmptyState';
import ErrorState from '../../../../components/ErrorState';
import LoadingState from '../../../../components/LoadingState';
import RestaurantTable from '../../../../components/RestaurantTable';
import TabBar from '../../../../components/TabBar';

/** Hooks */
import useSections from '../../../../hooks/useSections';
import useTables from '../../../../hooks/useTables';
import usePosOrder from '../../../../hooks/usePosOrder';
import useProfile from '../../../../hooks/useProfile';

/** Helpers */
import elapseTime from '../../../../helpers/elapsedTime';
import { priceFormatter } from '../../../../helpers/moneyFormatter';
import keyHasPermission from '../../../../helpers/keyHasPermission';

/** Styles */
import styles from '../styles';
import SelectSalesChannelModal from '../Modals/SelectSalesChannelModal';
// @ts-ignore
import {
  salesChannelNameStorage,
  salesChannelStorage,
} from '../../../../services/tokenStorage';
import useSettings from '../../../../hooks/useSettings';
import SectionSplitButtons from './SectionsSplitButtons';

function Sections() {
  const { t, i18n } = useTranslation('table');
  const navigate = useNavigate();

  let pusher: Pusher | null = null;
  let channel: Channel | null = null;

  const { changeTable, mergeTables, moveTableOrder, splitTable } =
    usePosOrder();
  const [activeSectionId, setActiveSectionId] = useState<string | null>(null);
  const [isChangeable, setIsChangeable] = useState<boolean>(false);
  const [isMoveOrder, setIsMoveOrder] = useState<boolean>(false);
  const [isMergeable, setIsMergeable] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [changeTablesIds, setChangeTablesId] = useState<string>('');
  const [mergeTablesIds, setMergeTablesId] = useState<string[]>([]);
  const [selectedTableId, setSelectedTableId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [salesChannelId, setSalesChannelId] = useState('');
  const [salesChannelName, setSalesChannelName] = useState('');

  const { sections, sectionError, sectionLoading, fetchSections } =
    useSections();
  const { channelSales, fetchChannelSales } = useSettings();

  const {
    tables,
    updateTableWithPusher,
    tableError,
    tableLoading,
    fetchTables,
  } = useTables();
  const { meProfile } = useProfile();

  useEffect(() => {
    if (channelSales.length) {
      setSalesChannelId(salesChannelStorage?.getToken() || '');
      setSalesChannelName(salesChannelNameStorage?.getToken() || '');
      const hasToken = Boolean(salesChannelStorage?.getToken());
      const isTokenVerify = channelSales.find(
        item => item.id === salesChannelStorage?.getToken() || '',
      );

      if ((!hasToken || !isTokenVerify) && channelSales.length > 1) {
        setOpen(true);
      } else if (channelSales.length === 1) {
        salesChannelStorage.setToken(channelSales[0].id);
        salesChannelNameStorage.setToken(channelSales[0].name);
        setSalesChannelId(channelSales[0].id);
        setSalesChannelName(channelSales[0].name);
        setOpen(false);
      }
    }
  }, [channelSales]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (salesChannelId.length) {
      const result = fetchSections(salesChannelId);
      return () => {
        result.abort();
      };
    }
  }, [salesChannelId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (selectedTableId === '' && submitted && salesChannelId.length) {
      const result = fetchSections(salesChannelId);
      return () => {
        result.abort();
      };
    }
  }, [selectedTableId]);

  useEffect(() => {
    // set first section as active at first sections fetch
    if (sections.length)
      setActiveSectionId(tables[0]?.section?.id || sections[0].id);
    // set first section as active if current section got deleted
    if (sections.length && !sections.find(item => item.id === activeSectionId))
      setActiveSectionId(sections[0].id);
  }, [sections]);

  useEffect(() => {
    let result: null | { abort: () => void } = null;
    if (activeSectionId && !submitted) {
      const sectionId = sections?.find(item => item.id === activeSectionId)?.id;
      result = sectionId ? fetchTables(sectionId) : null;
    }
    setSubmitted(false);
    return () => {
      if (result) result.abort();
    };
  }, [activeSectionId, submitted]);

  useEffect(() => {
    fetchChannelSales();
  }, []);

  const messageEventHandler = async (data: any) => {
    const result = tables.map(table =>
      table.id === data.id
        ? {
            ...table,
            children: data?.children || null,
            parent: data?.parent || null,
            activeOrderTransactions: data?.order_transaction?.total_amount
              ?.formatted
              ? {
                  ...table.activeOrderTransactions,
                  ...data.order_transaction,
                  total_amount: data.order_transaction.total_amount,
                  paid_amount: data.order_transaction.paid_amount,
                }
              : null,
          }
        : data?.children?.find((x: { id: string }) => x?.id === table?.id)
        ? {
            ...table,
            parent: {
              activeOrderTransactions: data.order_transaction,
              order_transaction_id: data.order_transaction.id,
              section: table?.section,
            },
          }
        : table,
    );
    // @ts-ignore
    await updateTableWithPusher(result);
  };

  const messageEventHandlerChangeTable = async (data: any) => {
    const result = tables.map(table =>
      table.id === data?.from?.id
        ? {
            ...table,
            activeOrderTransactions: null,
            parent: null,
            children: null,
            color: '',
          }
        : table.id === data?.to?.id
        ? {
            ...table,
            children: data?.to?.children || null,
            activeOrderTransactions: data?.to?.order_transaction?.total_amount
              ?.formatted
              ? {
                  ...table.activeOrderTransactions,
                  ...data?.to?.order_transaction,
                  total_amount: data?.to?.order_transaction.total_amount,
                  paid_amount: data?.to?.order_transaction.paid_amount,
                }
              : null,
          }
        : table,
    );
    // @ts-ignore
    await updateTableWithPusher(result);
  };

  const messageEventHandlerSplitTable = async (data: any) => {
    const result = tables.map(table =>
      table.id === data?.mainTable?.id
        ? {
            ...table,
            color: !data?.children && !data.parent ? '' : table.color,
            children: data?.mainTable.children || null,
            parent: data?.mainTable.parent || null,
            activeOrderTransactions: data?.mainTable.order_transaction
              ?.total_amount?.formatted
              ? {
                  ...table.activeOrderTransactions,
                  ...data?.mainTable.order_transaction,
                  total_amount: data?.mainTable.order_transaction.total_amount,
                  paid_amount: data?.mainTable.order_transaction.paid_amount,
                }
              : null,
          }
        : table.id === data?.splitTable?.id
        ? {
            ...table,
            color: !data?.children && !data.parent ? '' : table.color,
            children: data?.splitTable.children || null,
            parent: data?.splitTable.parent || null,
            activeOrderTransactions: data?.splitTable.order_transaction
              ?.total_amount?.formatted
              ? {
                  ...table.activeOrderTransactions,
                  ...data?.splitTable.order_transaction,
                  total_amount: data?.splitTable.order_transaction.total_amount,
                  paid_amount: data?.splitTable.order_transaction.paid_amount,
                }
              : null,
          }
        : table,
    );
    // @ts-ignore
    await updateTableWithPusher(result);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const appKey = process.env.REACT_APP_PUSHER_APP_KEY || '';

    // @ts-ignore
    pusher = new Pusher(appKey, {
      cluster: 'eu',
    });

    channel = pusher.subscribe(`table-order-${activeSectionId}`);

    channel.bind('table-order-event', messageEventHandler);
    channel.bind('table-changed-event', messageEventHandlerChangeTable);
    channel.bind('table-split-event', messageEventHandlerSplitTable);

    return () => {
      if (channel) {
        channel.unbind('table-order-event', messageEventHandler);
        channel.unbind('table-changed-event', messageEventHandlerChangeTable);
        channel.unbind('table-split-event', messageEventHandlerSplitTable);
        // @ts-ignore
        pusher.unsubscribe(`table-order-${activeSectionId}`);
      }
      if (pusher) {
        pusher.disconnect();
      }
    };
  }, [activeSectionId, tables]);

  const handleSplitTables = async () => {
    try {
      await splitTable(selectedTableId);
      setSubmitted(true);
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (isChangeable) {
        await changeTable(selectedTableId, changeTablesIds);
      }
      if (isMergeable) {
        await mergeTables(selectedTableId, mergeTablesIds);
      }
      if (isMoveOrder) {
        await moveTableOrder(selectedTableId, changeTablesIds);
      }
      // eslint-disable-next-line no-empty
    } catch (err) {
    } finally {
      setMergeTablesId([]);
      setSubmitted(true);
      setLoading(false);
    }
  };

  if (sectionLoading || tableLoading || loading) {
    return <LoadingState />;
  }

  if (!sections && !tables && (sectionError || tableError)) {
    return (
      <ErrorState
        message={sectionError ? sectionError.message : tableError.message}
      />
    );
  }

  const hasShowSections = keyHasPermission(
    'view-section-listing',
    meProfile?.permissions || [],
  );

  return hasShowSections ? (
    <>
      <Paper sx={styles.sectionsPaper} elevation={3}>
        <Stack>
          {sections?.length ? (
            <TabBar
              sx={{ mb: 3 }}
              tabs={sections}
              activeTab={activeSectionId || sections[0].id}
              onClick={tab => setActiveSectionId(tab.id)}
            />
          ) : null}

          {sections?.length && tables.length ? (
            <Grid container spacing={1}>
              {tables.map(table => (
                <Grid key={table.id} item xs={12} sm={3} md={2} lg={2}>
                  <RestaurantTable
                    sectionId={table?.section?.id}
                    tableActiveOrderTransactionsId={
                      table?.activeOrderTransactions?.id ||
                      table?.parent?.order_transaction_id
                    }
                    currencyCode={
                      table?.activeOrderTransactions?.total_amount?.currency ||
                      table?.parent?.activeOrderTransactions?.total_amount
                        ?.currency
                    }
                    waiterName={`${
                      (
                        table?.activeOrderTransactions ||
                        table?.parent?.activeOrderTransactions
                      )?.created_by?.first_name
                    } ${
                      (
                        table?.activeOrderTransactions ||
                        table?.parent?.activeOrderTransactions
                      )?.created_by?.last_name
                    }`}
                    tableName={
                      table?.activeOrderTransactions?.table?.name ||
                      `${table?.parent?.activeOrderTransactions?.table?.name} ${
                        table?.section?.name !== table?.parent?.section?.name
                          ? `(${table?.parent?.section?.name})`
                          : ''
                      }`
                    }
                    handleSplitTables={handleSplitTables}
                    setIsChangeable={setIsChangeable}
                    setIsMoveOrder={setIsMoveOrder}
                    setSelectedTableId={setSelectedTableId}
                    selectedTableId={selectedTableId}
                    setIsMergeable={setIsMergeable}
                    isChangeable={isChangeable}
                    isMoveOrder={isMoveOrder}
                    isMergeable={isMergeable}
                    isMerged={!!table.parent || !!table.children?.length}
                    tableId={table.id}
                    orderTableId={
                      table.activeOrderTransactions
                        ? table.id
                        : table.parent
                        ? table.parent.id
                        : ''
                    }
                    orderTransactionId={
                      (
                        table?.activeOrderTransactions ||
                        table?.parent?.activeOrderTransactions
                      )?.id || ''
                    }
                    checked={
                      table.id === changeTablesIds ||
                      Boolean(mergeTablesIds.find(item => item === table.id))
                    }
                    color={
                      table?.color ||
                      tables?.find(
                        tableItem => tableItem?.id === table?.parent?.id,
                      )?.color ||
                      tables?.find(
                        tableItem =>
                          tableItem?.id === table?.parent?.order_transaction_id,
                      )?.color
                    }
                    name={
                      table?.activeOrderTransactions?.table?.name || table?.name
                    }
                    createdAt={
                      table?.activeOrderTransactions
                        ? elapseTime(
                            table?.activeOrderTransactions?.created_at || '',
                            i18n.language || 'tr',
                          )
                        : elapseTime(
                            table?.parent?.activeOrderTransactions
                              ?.created_at || '',
                            i18n.language || 'tr',
                          )
                    }
                    totalPrice={
                      table?.activeOrderTransactions
                        ? priceFormatter(
                            (Number(
                              table?.activeOrderTransactions?.total_amount
                                ?.amount || 0,
                            ) -
                              Number(
                                table?.activeOrderTransactions?.total_discount
                                  ?.amount || 0,
                              )) /
                              100,
                            table?.activeOrderTransactions?.total_amount
                              ?.currency,
                          )
                        : priceFormatter(
                            (Number(
                              table?.parent?.activeOrderTransactions
                                ?.total_amount?.amount || 0,
                            ) -
                              Number(
                                table?.parent?.activeOrderTransactions
                                  ?.total_discount?.amount || 0,
                              )) /
                              100,
                            table?.parent?.activeOrderTransactions?.total_amount
                              ?.currency || 0,
                          )
                    }
                    totalPriceAmount={
                      (Number(
                        (
                          table?.activeOrderTransactions ||
                          table?.parent?.activeOrderTransactions
                        )?.total_amount?.amount,
                      ) -
                        Number(
                          (
                            table?.activeOrderTransactions ||
                            table?.parent?.activeOrderTransactions
                          )?.total_discount?.amount,
                        ) -
                        Number(
                          (
                            table?.activeOrderTransactions ||
                            table?.parent?.activeOrderTransactions
                          )?.paid_amount?.amount,
                        )) /
                        100 || 0
                    }
                    paidPrice={
                      table?.activeOrderTransactions
                        ? table?.activeOrderTransactions?.paid_amount?.formatted
                        : table?.parent?.activeOrderTransactions?.paid_amount
                            ?.formatted
                    }
                    paidAmount={
                      table?.activeOrderTransactions
                        ? table?.activeOrderTransactions?.paid_amount?.amount
                        : table?.parent?.activeOrderTransactions?.paid_amount
                            ?.amount
                    }
                    discountPrice={
                      table?.activeOrderTransactions
                        ? Number(
                            table?.activeOrderTransactions?.total_discount
                              ?.amount || 0,
                          )
                        : Number(
                            table?.parent?.activeOrderTransactions
                              ?.total_discount?.amount,
                          )
                    }
                    createdBy={
                      table?.activeOrderTransactions
                        ? `${
                            table?.activeOrderTransactions?.created_by
                              ?.first_name || ''
                          } ${
                            table?.activeOrderTransactions?.created_by
                              ?.last_name || ''
                          }`
                        : `${table?.parent?.activeOrderTransactions?.created_by?.first_name} ${table?.parent?.activeOrderTransactions?.created_by?.last_name}`
                    }
                    onClick={() => {
                      if (isMergeable) {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        mergeTablesIds?.find(item => item === table.id)
                          ? setMergeTablesId(
                              mergeTablesIds.filter(item => item !== table.id),
                            )
                          : setMergeTablesId([...mergeTablesIds, table.id]);
                      } else if (isChangeable) {
                        setChangeTablesId(table.id);
                      } else if (isMoveOrder) {
                        setChangeTablesId(table.id);
                      } else {
                        navigate(
                          `order/${table.parent ? table.parent.id : table.id}`,
                          {
                            state: {
                              tableName: table.parent
                                ? table.parent.name
                                : table.name,
                              full: table.parent
                                ? true
                                : !!table.activeOrderTransactions,
                            },
                          },
                        );
                      }

                      if (table.activeOrderTransactions && !isMoveOrder) {
                        setSelectedTableId(table.id);
                      }
                    }}
                    full={!!table.activeOrderTransactions || !!table.parent}
                    colorStatus={
                      table?.activeOrderTransactions?.color_status?.id || 0
                    }
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <EmptyState message={t('table.empty')} />
          )}
        </Stack>
      </Paper>
      {isChangeable || isMergeable || isMoveOrder ? (
        <SectionSplitButtons
          handleSubmit={handleSubmit}
          setIsChangeable={setIsChangeable}
          setIsMoveOrder={setIsMoveOrder}
          setIsMergeable={setIsMergeable}
        />
      ) : null}
      <SelectSalesChannelModal
        open={open}
        onClose={() => setOpen(false)}
        setSalesChannelId={setSalesChannelId}
        setSalesChannelName={setSalesChannelName}
      />
    </>
  ) : (
    <EmptyState message={t('empty')} />
  );
}

export default Sections;
