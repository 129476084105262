import React, { useState, useEffect } from 'react';
import Timer from './Timer';

interface Props {
  duration: any;
  backgroundColor: string;
  color: string;
  notShowHour?: boolean;
}

function StopWatch({ duration, backgroundColor, color, notShowHour }: Props) {
  const [isActive, setIsActive] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  // eslint-disable-next-line react/destructuring-assignment
  const [time, setTime] = useState(
    Date.now() - Date.parse(duration.duration || duration),
  );

  useEffect(() => {
    let interval: number | NodeJS.Timer | null | undefined = null;

    if (isActive && !isPaused) {
      interval = setInterval(() => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        setTime((time: number) => time + 1000);
      }, 1000);
    } else {
      // @ts-ignore
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval as NodeJS.Timeout);
    };
  }, [isActive, isPaused]);

  return (
    <div className="stop-watch">
      <Timer
        time={time}
        backgroundColor={backgroundColor || 'white'}
        color={color || 'black'}
        notShowHour={notShowHour}
      />
    </div>
  );
}

export default StopWatch;
