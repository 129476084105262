import { Box, TableRow, TableCell, Stack, ListItemButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormCheckbox from '../../../../../components/FormCheckbox';
import FormNumberInput from '../../../../../components/FormNumberInput';
import FormSwitch from '../../../../../components/FormSwitch';
import useProducts from '../../../../../hooks/useProducts';
import { isApiError } from '../../../../../services/api/types';
import FormButtons from '../FormButtons';

import {
  defaultValues,
  ProductPricingFormValues,
  validationSchema,
  defaultUnitValidation,
} from './types';
import useProfile from '../../../../../hooks/useProfile';
import errorReporting from '../../../../../helpers/errorReporting';
import Icon from '../../../../../components/Icon';
import TableComponent from '../../../../../components/TableComponent';

interface Props {
  productId?: string;
  onValidSubmit: (data: ProductPricingFormValues) => void;
  onPrevClick: () => void;
}

function PricingForm({ productId, onValidSubmit, onPrevClick }: Props) {
  const { t } = useTranslation('product');
  const { enqueueSnackbar } = useSnackbar();

  const {
    productUnitUpdate,
    productUnitMoveDown,
    productUnitMoveUp,
    loading,
    productInProcessDetails,
    setProductInProgress,
  } = useProducts();
  const { meProfile } = useProfile();

  const { handleSubmit, control } = useForm<ProductPricingFormValues>({
    defaultValues,
    resolver: validationSchema,
  });

  const { fields, replace, update } = useFieldArray({
    control,
    name: 'units',
  });

  const unitValues = useWatch({
    name: 'units',
    control,
  });

  const onSubmit = handleSubmit(async values => {
    try {
      if (defaultUnitValidation(values)) {
        if (productId) {
          const mappedValues = values.units.map(val => ({
            ...val,
            id: val.unitInnerId,
          }));
          await productUnitUpdate({ productId, units: mappedValues });
          onValidSubmit(values);
          setProductInProgress(productId);
        }
      } else {
        enqueueSnackbar(t('pricing.unitDefaultInvalid'), {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (err) {
      errorReporting(err);
    }
  });

  const onSubmitForSort = (id: string) =>
    handleSubmit(async values => {
      const isActive = unitValues.find(item => item.isActive);
      const isDefault = unitValues.find(item => item.isDefault);

      const unitItem = unitValues.find(item => item?.unitInnerId === id);
      const fieldItem = fields.find(item => item?.unitInnerId === id);

      if (isDefault && isActive) {
        try {
          if (defaultUnitValidation(values)) {
            if (
              productId &&
              (Number(unitItem?.amount) !== Number(fieldItem?.amount) ||
                Number(unitItem?.cost) !== Number(fieldItem?.cost) ||
                unitItem?.isActive !== fieldItem?.isActive ||
                unitItem?.isDefault !== fieldItem?.isDefault)
            ) {
              const mappedValues = values.units.map(val => ({
                ...val,
                id: val.unitInnerId,
              }));
              await productUnitUpdate({ productId, units: mappedValues });
            }
          } else {
            enqueueSnackbar(t('pricing.unitDefaultInvalid'), {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
        } catch (err) {
          enqueueSnackbar(
            isApiError(err) ? err.message : t('common:unknownError' as any),
            {
              variant: 'error',
              autoHideDuration: 3000,
              style: { whiteSpace: 'pre-line' },
            },
          );
          errorReporting(err);
        }
      }
    })();

  const onUp = (id: string) => {
    const isDefault = unitValues.find(item => item.isDefault);
    const isActive = unitValues.find(item => item.isActive);

    if (productId) {
      if (!isDefault || !isActive) {
        enqueueSnackbar(t('pricing.unitDefaultSortInvalid'), {
          variant: 'warning',
        });
      } else {
        productUnitMoveUp(productId, id);
        onSubmitForSort(id);
      }
    }
  };

  const onDown = (id: string) => {
    const isDefault = unitValues.find(item => item.isDefault);
    const isActive = unitValues.find(item => item.isActive);

    if (productId) {
      if (!isDefault || !isActive) {
        enqueueSnackbar(t('pricing.unitDefaultSortInvalid'), {
          variant: 'warning',
        });
      } else {
        productUnitMoveDown(productId, id);
        onSubmitForSort(id);
      }
    }
  };

  useEffect(() => {
    // Fill array with product details
    if (productInProcessDetails?.units?.length) {
      const activeUnits = productInProcessDetails?.units?.find(
        item => item.isActive,
      );

      if (activeUnits) {
        replace(productInProcessDetails?.units);
      } else {
        replace(
          productInProcessDetails?.units.map((item, index) =>
            index === 0
              ? {
                  ...item,
                  isActive: !item?.isActive,
                  isDefault: !item?.isDefault,
                }
              : item,
          ),
        );
      }
    }
  }, [productInProcessDetails?.units]);

  useEffect(() => {
    // toggle default for non active units
    unitValues.forEach((u, i) => {
      if (!u.isActive && u.isDefault) {
        update(i, { ...u, isDefault: false });
      }
    });
  }, [unitValues]);

  const tableHead = [
    t('pricing.sort'),
    t('pricing.active'),
    t('pricing.name'),
    t('pricing.price'),
    t('pricing.cost'),
    t('pricing.default'),
  ];

  const tableBody = fields.map((row, index) => (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
      <TableCell>
        <Stack direction="column" sx={{ mr: 0.3 }} spacing={0.3}>
          <ListItemButton
            onClick={() => onUp(row.unitInnerId)}
            disabled={index === 0}
          >
            <Icon name="KeyboardArrowUp" />
          </ListItemButton>
          <ListItemButton
            onClick={() => onDown(row.unitInnerId)}
            disabled={index === fields.length - 1}
          >
            <Icon name="KeyboardArrowDown" />
          </ListItemButton>
        </Stack>
      </TableCell>
      <TableCell padding="checkbox">
        <Controller
          name={`units.${index}.isActive`}
          control={control}
          render={({ field, fieldState }) => (
            <FormCheckbox
              sx={{ ml: 1.5 }}
              error={fieldState.invalid}
              {...field}
            />
          )}
        />
      </TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell>
        <Controller
          name={`units.${index}.amount`}
          control={control}
          render={({ field, fieldState }) => (
            <FormNumberInput
              sx={{ mb: 0, width: '100%', minWidth: '120px' }}
              maskProps={{
                decimalScale: 2,
                fixedDecimalScale: true,
                suffix: ` ${meProfile?.currency?.code || 'TRY'}`,
              }}
              error={fieldState.invalid}
              helperText={
                fieldState.error
                  ? t(fieldState.error.message as any)
                  : undefined
              }
              disabled={!unitValues?.[index]?.isActive}
              {...field}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          name={`units.${index}.cost`}
          control={control}
          render={({ field, fieldState }) => (
            <FormNumberInput
              sx={{ mb: 0, minWidth: '120px' }}
              maskProps={{
                decimalScale: 2,
                fixedDecimalScale: true,
                suffix: ` ${meProfile?.currency?.code || 'TRY'}`,
              }}
              error={fieldState.invalid}
              helperText={
                fieldState.error
                  ? t(fieldState.error.message as any)
                  : undefined
              }
              disabled={!unitValues?.[index]?.isActive}
              {...field}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <Controller
          name={`units.${index}.isDefault`}
          control={control}
          render={({ field, fieldState }) => (
            <FormSwitch
              disabled={!unitValues?.[index]?.isActive}
              error={fieldState.invalid}
              {...field}
            />
          )}
        />
      </TableCell>
    </TableRow>
  ));

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100%',
      }}
    >
      <TableComponent
        tableHead={tableHead}
        tableBody={tableBody}
        tableContainerStyle={{ py: 4 }}
        tableStyle={{ minWidth: 500 }}
        tableTopHeadStyle={{ backgroundColor: 'primary.light' }}
        count={1}
        page={1}
        showPagination={false}
      />
      <FormButtons onPrevClick={onPrevClick} loading={loading} />
    </Box>
  );
}

export default PricingForm;
