import React, { forwardRef } from 'react';
import { Badge, IconButton as Base, IconButtonProps } from '@mui/material';
import Icon, { IconName } from '../Icon';
import { borderRadiusMixin } from './styles';

interface Props extends IconButtonProps {
  iconName: IconName;
  radius?: number;
  badgeDot?: boolean;
  guestCount?: number;
}
function IconButton(
  { iconName, radius, sx, badgeDot, guestCount, ...props }: Props,
  ref: IconButtonProps['ref'],
) {
  return (
    <Base
      sx={[
        radius !== undefined && (borderRadiusMixin(radius) as any),
        ...(sx ? (Array.isArray(sx) ? sx : [sx]) : []),
      ]}
      {...props}
      ref={ref}
    >
      <Badge
        color={iconName === 'LocalOffer' ? 'error' : 'primary'}
        variant={badgeDot ? 'dot' : undefined}
        badgeContent={guestCount}
      >
        <Icon name={iconName} />
      </Badge>
    </Base>
  );
}

export default forwardRef(IconButton);
