import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type ProductPricingFormValues = {
  units: {
    unitId: string;
    unitInnerId: string;
    name: string;
    amount: number;
    cost: number;
    isDefault: boolean;
    isActive: boolean;
  }[];
};

const schema = yup.object({
  units: yup.array(
    yup.object().shape({
      unitId: yup.string().required(),
      unitInnerId: yup.string().required(),
      amount: yup.number().default(0),
      cost: yup.number().default(0),
      isDefault: yup.boolean().default(false),
      isActive: yup.boolean().default(false),
    }),
  ),
});

export const validationSchema = yupResolver(schema);

export const defaultValues: ProductPricingFormValues = {
  units: [],
};

export const defaultUnitValidation = ({
  units,
}: ProductPricingFormValues): boolean => {
  // only one default unit in selected units is allowed
  const selectedUnits = units.filter(unit => unit.isActive);
  if (selectedUnits.length > 0) {
    const defaultCount = selectedUnits.filter(unit => unit.isDefault).length;
    if (defaultCount !== 1) return false;
  }
  return true;
};
