import { SxProps, Theme } from '@mui/material';

const styles: Record<string, SxProps<Theme>> = {
  button: {
    fontWeight: 'bold',
    backgroundColor: 'white',
    border: '0.1px solid #DEDEDE',
    px: 1,
  },
  calculatorButton: {
    height: '70px',
    backgroundColor: 'white',
    border: '0.1px solid #DEDEDE',
    fontSize: '1rem',
  },
  paymentTypeButton: {
    height: '100px',
    backgroundColor: 'white',
    border: '0.1px solid #DEDEDE',
    fontSize: '1rem',
  },
  selectedQuantityInfo: {
    fontSize: 12,
    textTransform: 'capitalize',
    color: 'grey',
    opacity: '.6',
  },
  title: { fontSize: 16, textTransform: 'capitalize' },
  menuItemTitle: { fontSize: 12, textTransform: 'capitalize' },
  menuItemSubtitle: { fontSize: 10, textTransform: 'capitalize' },
  properties: { fontSize: 10, textTransform: 'capitalize' },
  priceInfo: { fontWeight: '200', padding: '0 8px 0 0', color: 'ff0000c1' },
  orderListWidgetPaper: {
    boxShadow:
      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
    p: 1,
    backgroundColor: 'white',
    paddingBottom: {
      xs: '10px',
      sm: 0,
    },
    mr: 3,
    width: { xs: '320px', sm: '500px' },
    minWidth: { xs: '320px', sm: '500px' },
    mb: '10px',
  },
  orderCardPaper: {
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: 2,
    },
  },
  cardIcon: {
    borderRadius: '50%',
    width: '75px',
    height: '75px',
  },
  selectStyle: {
    width: '100%',
    height: { xs: '56px', sm: '64px' },
    border: '1 px solid white',
    boxShadow:
      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    backgroundColor: 'white',
    '&:hover': {
      '&& fieldset': {
        border: 'none',
      },
    },
    '.MuiOutlinedInput-notchedOutline': { border: 'none' },
  },
};

export default styles;
