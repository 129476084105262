/** Dependencies */
import { Box, Paper, IconButton, Typography, Stack } from '@mui/material';
import React, { useState } from 'react';

/** Components */
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../../../components/Icon';

/** Styles */
import styles from './styles';
import generalStyles from '../../../../../../../assets/stylesheets/generalStyles';

/** Helpers */
import { singlePriceFormatter } from '../../../../../../../helpers/moneyFormatter';
import GiftModal from '../../../GiftModal';
import ConfirmationModal from '../../../../../../../components/ConfirmationModal';
import Button from '../../../../../../../components/Button';
import ProductNoteModal from '../../../ProductNoteModal';

interface Props {
  name: string;
  count: number;
  onIncrease: () => void;
  onDecrease: () => void;
  onDelete: () => void;
  onAction: (id: string, description: string) => void;
  totalPrice: number;
  currencyCode: string;
  selectedItemId: string;
  isTreat: boolean;
  handleSpecialOrderTreat: (itemId: string, selectedCount: number) => void;
  handleSpecialOrderUnTreat: (itemId: string, selectedCount: number) => void;
  description: string;
}

function SpecialOrderItem({
  name,
  count,
  onIncrease,
  onDecrease,
  onDelete,
  onAction,
  totalPrice,
  currencyCode,
  selectedItemId,
  isTreat,
  handleSpecialOrderTreat,
  handleSpecialOrderUnTreat,
  description,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('pos');
  const [openNote, setOpenNote] = useState(false);

  const [deleteConfirmationForTreat, setDeleteConfirmationForTreat] = useState<
    string | null
  >(null);
  const [openGiftModal, setOpenGiftModal] = useState(false);
  const [treatType, setTreatType] = useState('');

  const handleUnTreat = async (selectedCount: number) => {
    if (selectedItemId) {
      handleSpecialOrderUnTreat(selectedItemId, selectedCount);

      enqueueSnackbar(t('basketList.delete_gift_order'), {
        variant: 'success',
      });
    }
  };

  const handleTreatOrder = async (selectedCount: number, type: string) => {
    if (type === 'treat') {
      handleSpecialOrderTreat(selectedItemId, selectedCount);
      enqueueSnackbar(t('basketList.gift_order'), {
        variant: 'success',
      });
    } else {
      handleUnTreat(selectedCount);
    }

    setOpenGiftModal(false);
    setTreatType('');
  };

  return (
    <Box>
      <Stack direction="row" sx={{ color: 'red' }}>
        <Paper sx={generalStyles.buttonContainer}>
          <IconButton
            sx={styles.icon}
            color="success"
            onMouseDown={e => e.stopPropagation()}
            onClick={e => {
              e.stopPropagation();
              onIncrease();
            }}
          >
            <Icon name="Add" />
          </IconButton>
          <Typography>{count}</Typography>
          <IconButton
            sx={styles.icon}
            color="error"
            onMouseDown={e => e.stopPropagation()}
            onClick={e => {
              e.stopPropagation();
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              count === 1 ? onDelete() : onDecrease();
            }}
          >
            <Icon name={count === 1 ? 'Delete' : 'Remove'} />
          </IconButton>
        </Paper>
        <Stack
          sx={generalStyles.generalFlex}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography sx={styles.title}>{name}</Typography>
          </Box>
          <Box sx={generalStyles.generalDisplay}>
            <Typography sx={{ mr: 5 }}>
              {!isTreat &&
                singlePriceFormatter(
                  totalPrice.toString(),
                  count,
                  currencyCode,
                )}
            </Typography>
            {isTreat && (
              <Box sx={{ marginBottom: '20px' }}>
                <Typography
                  sx={{
                    fontSize: { xs: 14, sm: 13, lg: 16 },
                    textDecoration: isTreat ? 'line-through' : '',
                  }}
                >
                  {singlePriceFormatter(
                    totalPrice.toString(),
                    count,
                    currencyCode,
                  )}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: '20px',
                    width: '50px',
                    height: '24px',
                    fontSize: '10px',
                    p: 0,
                    minWidth: '10px',
                  }}
                  color="error"
                  size="small"
                  onClick={e => {
                    e.stopPropagation();
                    setOpenGiftModal(true);
                    setTreatType('untreat');
                  }}
                >
                  {t('basketList.treat')}
                </Button>
              </Box>
            )}
            <IconButton
              sx={styles.icon}
              color="error"
              onMouseDown={e => e.stopPropagation()}
              onClick={e => {
                e.stopPropagation();
                onDelete();
              }}
            >
              <Icon name="Delete" />
            </IconButton>
            <IconButton
              sx={styles.icon}
              color="error"
              onMouseDown={e => e.stopPropagation()}
              onClick={e => {
                e.stopPropagation();
                setOpenNote(true);
              }}
            >
              <Icon name="MoreVert" />
            </IconButton>
          </Box>
        </Stack>
      </Stack>
      {description && (
        <Box sx={{ mt: 1, fontSize: '13px', color: '#FF0000' }}>
          {t('note')}: {description}
        </Box>
      )}
      <GiftModal
        open={openGiftModal}
        onClose={() => setOpenGiftModal(false)}
        type={treatType}
        totalCount={count}
        handleTreatOrder={handleTreatOrder}
      />
      <ProductNoteModal
        open={openNote}
        onClose={() => setOpenNote(false)}
        originalDescription={description}
        itemId={selectedItemId}
        onGiftSelect={() => {
          setOpenGiftModal(true);
          setTreatType('treat');
          setOpenNote(false);
        }}
        isTreat={isTreat}
        type="specialOrder"
        onAction={onAction}
        menuItemId=""
      />
    </Box>
  );
}

export default SpecialOrderItem;
