import { Grid, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';

interface Props {
  isFirst?: boolean;
  isLast?: boolean;
  loading?: boolean;
  onPrevClick?: () => void;
  onNextClick?: () => void;
}
function FormButtons({
  onPrevClick,
  onNextClick,
  isFirst,
  isLast,
  loading,
}: Props) {
  const { t } = useTranslation('product');
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={1}
      mt={2}
      justifyContent="space-between"
    >
      <Button onClick={onPrevClick} disabled={isFirst}>
        {t('wizard.buttons.prev')}
      </Button>
      <Button
        type="submit"
        variant="contained"
        loading={loading}
        onClick={onNextClick}
      >
        {isLast ? t('wizard.buttons.complete') : t('wizard.buttons.next')}
      </Button>
    </Stack>
  );
}

export default FormButtons;
