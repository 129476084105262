import { Box, Chip, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import FormSelect from '../../../../../components/FormSelect';
import useProducts from '../../../../../hooks/useProducts';
import useIngredientGroups from '../../../../../hooks/useIngredientGroups';
import FormButtons from '../FormButtons';
import IngredientItemsContainer from './IngredientItemsContainer';
import {
  defaultValues,
  ProductIngredientFormValues,
  validationSchema,
} from './types';
import getActiveLanguage from '../../../../../helpers/getActiveLanguage';
import LoadingState from '../../../../../components/LoadingState';

interface Props {
  onValidSubmit: (data: ProductIngredientFormValues) => void;
  onPrevClick: () => void;
  initialValues?: { groupIds: string[] };
  isLast: boolean;
}
function IngredientForm({
  onValidSubmit,
  onPrevClick,
  initialValues,
  isLast,
}: Props) {
  const { t } = useTranslation('product');

  const { handleSubmit, control, watch, reset } =
    useForm<ProductIngredientFormValues>({
      defaultValues: initialValues || defaultValues(),
      resolver: validationSchema,
    });

  useEffect(() => reset(initialValues), [initialValues]);

  const onSubmit = handleSubmit(onValidSubmit);

  const groupIds = watch('groupIds');

  const { ingredientGroups, fetchIngredientGroups, loading } =
    useIngredientGroups();

  const { productId } = useParams();
  const { updateProductIngredientGroups, groupsLoading } = useProducts();

  useEffect(() => {
    if (!ingredientGroups.length) fetchIngredientGroups();
  }, [ingredientGroups]);

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100%',
      }}
    >
      <Box sx={{ py: 4 }}>
        <Grid container>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Controller
              name="groupIds"
              control={control}
              render={({ field, fieldState }) => (
                <FormSelect
                  label={t('ingredients.group.label')}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  options={ingredientGroups.map(p => ({
                    id: p.id,
                    text: getActiveLanguage(p.translations).name,
                  }))}
                  multiple
                  onClose={() => {
                    if (productId) {
                      updateProductIngredientGroups({ productId, groupIds });
                    }
                  }}
                  renderValue={selected => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {Array.isArray(selected)
                        ? selected
                            .map(id => ingredientGroups.find(g => g.id === id))
                            .map(value => (
                              <Chip
                                key={value?.id}
                                label={
                                  value
                                    ? getActiveLanguage(value.translations).name
                                    : ''
                                }
                              />
                            ))
                        : undefined}
                    </Box>
                  )}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={2} />
        </Grid>
        {loading || groupsLoading ? (
          <LoadingState />
        ) : (
          <IngredientItemsContainer
            loading={groupsLoading}
            productId={productId}
          />
        )}
      </Box>

      <FormButtons onPrevClick={onPrevClick} isLast={isLast} />
    </Box>
  );
}
export default IngredientForm;
