import { useMemo } from 'react';
import getActiveLanguage from './getActiveLanguage';

interface OptionType {
  id: string;
  translations: any[];
  name: string;
}

interface OptionTypeWithName {
  id: string;
  name: string;
}

interface OptionCurrencyType {
  id: string;
  code: any;
}

interface OptionLanguageType {
  id: any;
  name: string;
  slug: string;
}

export const OptionsMemo = (optionName: any[]) =>
  useMemo(
    () =>
      optionName?.map((option: OptionType) => ({
        id: option?.id,
        text: getActiveLanguage(option?.translations)?.name,
      })),
    [optionName],
  );

export const OptionsMemoWithName = (optionName: any[]) =>
  useMemo(
    () =>
      optionName?.map((option: OptionTypeWithName) => ({
        id: option?.id,
        text: option?.name,
      })),
    [optionName],
  );

export const OptionsCurrenciesMemo = (optionName: any[]) =>
  useMemo(
    () =>
      optionName?.map((option: OptionCurrencyType) => ({
        id: option?.id,
        text: option?.code?.toLocaleUpperCase('tr-TR'),
      })),
    [optionName],
  );

export const OptionsLanguageMemo = (optionName: any[]) =>
  useMemo(
    () =>
      optionName?.map((option: OptionLanguageType) => ({
        id: option?.slug,
        text: option?.slug?.toUpperCase(),
      })),
    [optionName],
  );
