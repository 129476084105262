/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Input } from '@mui/material';

/** Components */
import Button from '../../../../../../../components/Button';

/** Styles */
import { priceFormatter } from '../../../../../../../helpers/moneyFormatter';
import keyHasPermission from '../../../../../../../helpers/keyHasPermission';
import useProfile from '../../../../../../../hooks/useProfile';

interface Props {
  discountTotal: number;
  tableId: string;
  value: string;
  currencyCode: string;
  setDeleteConfirmationForDiscount: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  handleKeyDown: (event: { key: any }) => void;
}

function PaymentDiscount({
  discountTotal,
  tableId,
  value,
  currencyCode,
  setDeleteConfirmationForDiscount,
  handleKeyDown,
}: Props) {
  const { t } = useTranslation('pos');
  const { meProfile } = useProfile();

  const hasRemoveDiscountButton = keyHasPermission(
    'remove-discount',
    meProfile?.permissions || [],
  );

  const detectMobile = () =>
    window.innerWidth <= 1200 && window.innerHeight <= 800;

  return (
    <Grid container xs={12} justifyContent="space-between">
      {discountTotal > 0 && (
        <Grid container>
          <Grid
            sx={{
              color: '#26acf8',
              fontSize: 14,
              padding: '20px 1px 0px 0px',
            }}
          >
            {t('modalMidSide.discount')} :
          </Grid>
          <Grid>
            <Grid p={1}>
              {priceFormatter(discountTotal / 100, currencyCode)}
              {hasRemoveDiscountButton && (
                <Button
                  variant="text"
                  color="error"
                  onClick={() => {
                    setDeleteConfirmationForDiscount(tableId || 'self');
                  }}
                >
                  {t('modalMidSide.delete')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid sx={discountTotal ? { mt: '0px' } : { mt: '0px' }} container>
        <Grid
          sx={{
            color: '#26acf8',
            fontSize: 14,
            padding: '13px 1px 0px 0px',
          }}
        >
          {t('modalMidSide.pay')} :
        </Grid>
        <Grid p={1}>
          <Input
            id="active-element"
            value={priceFormatter(Number(value), currencyCode)}
            disableUnderline
            sx={{ caretColor: 'transparent' }}
            autoFocus={!detectMobile()}
            onKeyDown={handleKeyDown}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PaymentDiscount;
