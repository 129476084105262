/** Dependencies */
import React from 'react';
import {
  Box,
  ButtonGroup,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import Icon from '../../../components/Icon';

interface Props {
  title: string;
  onAddClick?: () => void;
  tooltipTitle?: string;
  addButton?: boolean;
}

function CustomerDetailsHeader({
  title,
  onAddClick,
  tooltipTitle,
  addButton,
}: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        px: 2,
        pb: 1,
      }}
    >
      <Typography textAlign="center" textOverflow="ellipsis" variant="h6">
        {title}
      </Typography>
      {tooltipTitle && addButton && (
        <Tooltip title={tooltipTitle || ''}>
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <IconButton aria-label="Edit" color="primary" onClick={onAddClick}>
              <Icon name="Edit" />
            </IconButton>
          </ButtonGroup>
        </Tooltip>
      )}
    </Box>
  );
}

export default CustomerDetailsHeader;
