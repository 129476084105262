import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import Button from '../Button';
import Icon, { IconName } from '../Icon';

interface Props {
  open: boolean;
  onClose: () => void;
  title?: string;
  titleIconName?: IconName;
  description?: string;
  description1?: string;
  children?: React.ReactNode;
  positiveAction?: string;
  negativeAction?: string;
  onPositiveClick?: () => void;
  onNegativeClick?: () => void;
  loading?: boolean;
  confirmButtonColor?:
    | 'inherit'
    | 'error'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning';
  cancelButtonColor?:
    | 'inherit'
    | 'error'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning';
  confirmButtonVariant?: 'text' | 'outlined' | 'contained';
  cancelButtonVariant?: 'text' | 'outlined' | 'contained';
}
function ConfirmationModal({
  open,
  onClose,
  title,
  titleIconName,
  description,
  positiveAction,
  negativeAction,
  onPositiveClick,
  onNegativeClick,
  loading,
  children,
  confirmButtonColor,
  cancelButtonColor,
  confirmButtonVariant,
  cancelButtonVariant,
  description1,
}: Props): React.JSX.Element {
  return (
    <Dialog open={open} onClose={onClose}>
      {title && (
        <DialogTitle
          sx={{ display: 'flex', alignItems: 'center' }}
          color="primary.dark"
        >
          {titleIconName && <Icon name={titleIconName} sx={{ mr: 1 }} />}
          {title}
        </DialogTitle>
      )}
      <DialogContent>
        {description && (
          <DialogContentText>
            {description} <br /> {description1}{' '}
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      <DialogActions>
        {negativeAction && (
          <Button
            variant={cancelButtonVariant || 'text'}
            color={cancelButtonColor || 'error'}
            onClick={onNegativeClick}
          >
            {negativeAction}
          </Button>
        )}
        {positiveAction && (
          <Button
            variant={confirmButtonVariant || 'contained'}
            color={confirmButtonColor || 'primary'}
            onClick={onPositiveClick}
            loading={loading}
          >
            {positiveAction}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationModal;
