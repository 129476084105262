/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import {
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';

/** Components */
import EmptyState from '../../../components/EmptyState';
import Icon from '../../../components/Icon';

/** Types */
import { Printer } from '../../../services/api/settings/printerSettings/types';
import ConfirmationModal from '../../../components/ConfirmationModal';
import LoadingState from '../../../components/LoadingState';
import useSettings from '../../../hooks/useSettings';
import useProfile from '../../../hooks/useProfile';
import keyHasPermission from '../../../helpers/keyHasPermission';

interface Props {
  printers: Printer[];
  onItemEdit: (item: any) => void;
  onItemRemove: (id: string) => Promise<any>;
}

function PrintersTable({ printers, onItemEdit, onItemRemove }: Props) {
  const { t } = useTranslation('printerSettings');
  const { meProfile } = useProfile();
  const { printersMeta, fetchPrinters } = useSettings();

  const [loading, setLoading] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(
    null,
  );
  const [page, setPage] = useState(1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (value !== page) {
      setPage(value);
      fetchPrinters({
        page: value,
      });
    }
  };

  const handleRemove = async () => {
    if (deleteConfirmation) {
      try {
        setLoading(true);
        await onItemRemove(deleteConfirmation);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      } finally {
        setDeleteConfirmation(null);
      }
    }
  };

  const hasListPermission = keyHasPermission(
    'view-printer-listing',
    meProfile?.permissions || [],
  );

  const hasUpdatePermission = keyHasPermission(
    'update-printer',
    meProfile?.permissions || [],
  );

  const hasDeletePermission = keyHasPermission(
    'delete-printer',
    meProfile?.permissions || [],
  );

  const renderEmpty = () => (
    <EmptyState
      message={
        !hasListPermission
          ? 'Bu sayfayı görmek için yetkiniz yok!'
          : t('printer.empty')
      }
    />
  );

  useEffect(() => {
    // @ts-ignore
    setPage(printersMeta.current_page);
  }, [printersMeta]);

  return (
    <Paper sx={{ width: '100%', p: 2 }}>
      {!hasListPermission || !printers.length ? (
        renderEmpty()
      ) : (
        <TableContainer>
          {loading ? (
            <LoadingState />
          ) : (
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('printer.printersTable.printer_name')}
                  </TableCell>
                  <TableCell>
                    {t('printer.printersTable.printer_type')}
                  </TableCell>
                  <TableCell>
                    {t('printer.printersTable.capability_profile')}
                  </TableCell>
                  <TableCell>
                    {t('printer.printersTable.char_per_line')}
                  </TableCell>
                  {/* <TableCell>{t('printer.printersTable.path')}</TableCell> */}
                  <TableCell>{t('printer.printersTable.ip_address')}</TableCell>
                  <TableCell>{t('printer.printersTable.port')}</TableCell>
                  <TableCell>
                    {t('printer.printersTable.kitchen_groups')}
                  </TableCell>
                  <TableCell>{t('printer.printersTable.operations')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ cursor: 'pointer' }}>
                {printers.map(row => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.connection_type}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.capability_profile}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.char_per_line}
                    </TableCell>
                    {/* <TableCell component="th" scope="row"> */}
                    {/*  {row.path} */}
                    {/* </TableCell> */}
                    <TableCell component="th" scope="row">
                      {row.ip_address}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.port}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {(row?.kitchen_groups?.length > 1
                        ? // eslint-disable-next-line @typescript-eslint/no-shadow
                          row?.kitchen_groups?.map((item, index, row) =>
                            index + 1 === row.length
                              ? `${item.name}`
                              : `${item.name}, `,
                          )
                        : row?.kitchen_groups?.map(item => item.name)) || '-'}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={event => {
                          if (hasUpdatePermission) {
                            onItemEdit(row);
                          }
                        }}
                        aria-label="Add"
                        color="primary"
                        disabled={!hasUpdatePermission}
                      >
                        <Icon name="Edit" />
                      </IconButton>
                      <IconButton
                        onClick={event => {
                          if (hasDeletePermission) {
                            setDeleteConfirmation(row.id);
                          }
                        }}
                        aria-label="Add"
                        color="error"
                        disabled={!hasDeletePermission}
                      >
                        <Icon name="Delete" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <Pagination
            sx={{ margin: '10px' }}
            count={Math.ceil(
              // @ts-ignore
              printersMeta.total / printersMeta.per_page,
            )}
            page={page}
            onChange={handleChange}
          />
        </TableContainer>
      )}
      <ConfirmationModal
        title={t('printer.deleteConfirmation.title')}
        titleIconName="QuestionMarkOutlined"
        description={t('printer.deleteConfirmation.description')}
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        positiveAction={t('printer.deleteConfirmation.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('printer.deleteConfirmation.cancel')}
        onNegativeClick={() => setDeleteConfirmation(null)}
      />
    </Paper>
  );
}

export default PrintersTable;
