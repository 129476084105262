/** Dependencies */
import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

/** Components */
import FormInput from '../../../../../components/FormInput';
import Modal from '../../../../../components/Modal';
import useBasketItems from '../../../../../hooks/useBasketItems/useBasketItems';

interface Props {
  open: boolean;
  onClose: () => void;
  originalDescription: string;
  descriptionValue?: string | null;
  itemId: string;
  onGiftSelect: () => void;
  isTreat: boolean;
  type: string;
  onAction?: (id: string, description: string) => void;
  menuItemId: string;
}
function ProductNoteModal({
  open,
  onClose,
  originalDescription,
  descriptionValue,
  itemId,
  onGiftSelect,
  isTreat,
  type,
  onAction,
  menuItemId,
}: Props) {
  const { t } = useTranslation('pos');
  const { addItemDescriptionForBasket } = useBasketItems();
  const [description, setDescription] = useState('');

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    if (type === 'order') {
      addItemDescriptionForBasket(itemId, description);
    } else if (type === 'specialOrder' && onAction) {
      onAction(itemId, description);
    } else if (type === 'menuOrder') {
      addItemDescriptionForBasket(itemId, description, menuItemId);
    }
  };

  useEffect(() => {
    if (open) {
      setDescription(descriptionValue || originalDescription);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={t('orderModal.productNoteModal.title')}
      subtitle={t('orderModal.productNoteModal.subtitle')}
      positiveAction={t('orderModal.productNoteModal.confirm')}
      onPositiveClick={() => {
        handleSubmit();
        handleClose();
      }}
      negativeAction={t('orderModal.productNoteModal.cancel')}
      onNegativeClick={handleClose}
    >
      <Grid mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Button
              variant="contained"
              fullWidth
              color="inherit"
              sx={{ height: '45px' }}
              onClick={onGiftSelect}
              disabled={isTreat || Boolean(menuItemId)}
            >
              {t('orderModal.productNoteModal.treat')}
            </Button>
          </Grid>
          <Grid item xs={8}>
            <FormInput
              placeholder={t('orderModal.productNoteModal.description')}
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default ProductNoteModal;
