import api from '../api';
import {
  IngredientSettings,
  ProductSettings,
  PropertySettings,
  TrendyolSettings,
  UpdateTrendyolSettingsRes,
} from './types';

const settingsList = (slug: string) =>
  api.get<TrendyolSettings>(`/integrations/${slug}/settings`);

const syncList = (slug: string) =>
  api.get<TrendyolSettings>(`/integrations/${slug}/sync-items`);

const updateSettings = (data: UpdateTrendyolSettingsRes, slug: string) =>
  api.post<TrendyolSettings>(`/integrations/${slug}/settings`, data);

const integrationProduct = (
  slug: string,
  page: number,
  name: string,
  sort: string,
) =>
  api.get<ProductSettings>(`/integrations/${slug}/products`, {
    params: {
      page,
      filter: {
        name,
      },
      sort,
    },
  });

const integrationIngredient = (
  slug: string,
  page: number,
  name: string,
  sort: string,
) =>
  api.get<IngredientSettings>(`/integrations/${slug}/ingredients`, {
    params: {
      page,
      filter: {
        name,
      },
      sort,
    },
  });
const integrationProperty = (
  slug: string,
  page: number,
  name: string,
  sort: string,
) =>
  api.get<PropertySettings>(`/integrations/${slug}/properties`, {
    params: {
      page,
      filter: {
        name,
      },
      sort,
    },
  });

const integrationProductMatch = (
  slug: string,
  id: string,
  kitchrest_product_id: string,
) =>
  api.post(`/integrations/${slug}/products/${id}/match`, {
    kitchrest_product_id,
  });

const integrationProductUnMatch = (slug: string, id: string) =>
  api.post(`/integrations/${slug}/products/${id}/unmatch`);

const integrationIngredientMatch = (
  slug: string,
  id: string,
  kitchrest_ingredient_id: string,
) =>
  api.post(`/integrations/${slug}/ingredients/${id}/match`, {
    kitchrest_ingredient_id,
  });

const integrationIngredientUnMatch = (slug: string, id: string) =>
  api.post(`/integrations/${slug}/ingredients/${id}/unmatch`);

const integrationPropertiesMatch = (
  slug: string,
  id: string,
  kitchrest_property_id: string,
) =>
  api.post(`/integrations/${slug}/properties/${id}/match`, {
    kitchrest_property_id,
  });

const integrationPropertiesUnMatch = (slug: string, id: string) =>
  api.post(`/integrations/${slug}/properties/${id}/unmatch`);

export default {
  settingsList,
  syncList,
  updateSettings,
  integrationProduct,
  integrationIngredient,
  integrationProperty,
  integrationProductMatch,
  integrationProductUnMatch,
  integrationIngredientMatch,
  integrationIngredientUnMatch,
  integrationPropertiesMatch,
  integrationPropertiesUnMatch,
};
