/** Dependencies */
import React, { useEffect } from 'react';
import { Button, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

/** Components */
import Modal from '../../../../components/Modal';

/** Hooks */
import useSettings from '../../../../hooks/useSettings';
import styles from '../styles';
import {
  salesChannelNameStorage,
  salesChannelStorage,
} from '../../../../services/tokenStorage';
import useSections from '../../../../hooks/useSections';

interface Props {
  open: boolean;
  onClose: () => void;
  setSalesChannelId?: (value: ((prevState: string) => string) | string) => void;
  setSalesChannelName?: React.Dispatch<React.SetStateAction<string>>;
}

function SelectSalesChannelModal({
  open,
  onClose,
  setSalesChannelId,
  setSalesChannelName,
}: Props) {
  const { channelSales, fetchChannelSales } = useSettings();
  const { fetchSections } = useSections();

  const tokenId = salesChannelStorage.getToken();

  const { t } = useTranslation('pos');

  const handleSelectSalesChannel = async (id: string, name: string) => {
    const salesToken = salesChannelStorage.getToken();
    const salesName = salesChannelNameStorage.getToken();
    salesChannelStorage.setToken(id);
    salesChannelNameStorage.setToken(name);
    if (salesToken) {
      await fetchSections(id);
    }
    if (setSalesChannelId && setSalesChannelName) {
      setSalesChannelId(id || salesToken || '');
      setSalesChannelName(name || salesName || '');
    }
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (open && !channelSales.length) {
      fetchChannelSales();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      title={t('paymentModal.sales_channel')}
      disableEscapeKeyDown
    >
      <div>
        <Stack
          sx={styles.packagePaymentStack}
          direction={{ md: 'row', xs: 'column' }}
          spacing={3}
        >
          <Grid container item>
            <Grid
              sx={{ mt: 1, maxHeight: '500px', overflow: 'auto' }}
              container
              item
              justifyContent="flex-start"
              xs={12}
            >
              {channelSales?.map(item => (
                <Grid sx={{ padding: '5px' }} item xs={12}>
                  <Button
                    color="inherit"
                    fullWidth
                    sx={
                      tokenId === item.id
                        ? [
                            styles.paymentTypeButton as any,
                            { backgroundColor: '#66bb6a' },
                          ]
                        : (styles.paymentTypeButton as any)
                    }
                    onClick={() => handleSelectSalesChannel(item.id, item.name)}
                  >
                    <Grid justifyContent="center">
                      <Grid>{item.name}</Grid>
                    </Grid>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Stack>
      </div>
    </Modal>
  );
}

export default SelectSalesChannelModal;
