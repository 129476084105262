import { SxProps, Theme } from '@mui/material';

const styles: Record<string, SxProps<Theme>> = {
  cardContainer: {
    borderRadius: '4px',
    padding: {
      xs: 0,
      sm: '10px',
    },
    paddingBottom: {
      xs: '10px',
      sm: 0,
    },
    height: '427px',
    mb: '10px',
  },
  cardShadow: {
    boxShadow:
      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
    p: 1,
    height: '100%',
  },
  cardContent: {
    maxHeight: '330px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: 2,
    },
  },
  remove: {
    cursor: 'pointer',
    fontSize: '12px',
    color: 'red',
    ':hover': { color: 'green' },
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: { fontSize: 16, textTransform: 'capitalize' },
  kitchenPaper: {
    p: 2,
    backgroundColor: '#fafafa',
  },
  kitchenFilterBarContainer: {
    width: '100%',
    height: { xs: '56px', sm: '64px' },
    border: '1 px solid white',
    boxShadow:
      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    backgroundColor: 'white',
  },
  displayContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  kitchenTypography: {
    fontSize: '12px',
    color: 'gray',
  },
};

export default styles;
