import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../components/FormInput';
import Modal from '../../../components/Modal';
import useTables from '../../../hooks/useTables';
import { isApiError } from '../../../services/api/types';
import { TableBase } from './types';
import errorReporting from '../../../helpers/errorReporting';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm?: (name: string) => void;
  sectionId: string | null;
  item?: TableBase | null;
}

function TableAddModal({ open, onClose, onConfirm, sectionId, item }: Props) {
  const { t } = useTranslation('table');
  const { enqueueSnackbar } = useSnackbar();
  const { addTable, updateTable } = useTables();

  const [name, setName] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (item) {
      setName(item.name);
    }
    return () => {
      setName('');
    };
  }, [item]);

  const handleConfirm = async () => {
    if (name && sectionId) {
      try {
        setLoading(true);

        const result = !item?.id
          ? await addTable(name, sectionId)
          : await updateTable({ id: item.id, name, sectionId });

        setName('');
        if (onConfirm) onConfirm(name);
        onClose();
      } catch (err) {
        enqueueSnackbar(
          isApiError(err) ? err.message : t('common:unknownError' as any),
          {
            variant: 'error',
            autoHideDuration: 3000,
          },
        );
        errorReporting(err);
      } finally {
        setLoading(false);
      }
    } else if (!sectionId) {
      enqueueSnackbar(t('emptySection'), {
        variant: 'warning',
      });
    } else {
      setIsSubmitted(true);
    }
  };

  const invalid = !name && isSubmitted;

  return (
    <Modal
      title={item ? t('table.editModal.title') : t('table.addModal.title')}
      titleIconName="Add"
      open={open}
      onClose={onClose}
      positiveAction={t('table.addModal.confirm')}
      negativeAction={t('table.addModal.cancel')}
      onPositiveClick={handleConfirm}
      onNegativeClick={onClose}
      loading={loading}
    >
      <FormInput
        label={t('table.addModal.form.name.label')}
        error={invalid}
        helperText={invalid && t('table.addModal.form.name.required')}
        value={name}
        onChange={event => setName(event.target.value)}
        onKeyPress={event => event.key === 'Enter' && handleConfirm()}
      />
    </Modal>
  );
}

export default TableAddModal;
