import { Box, Paper, Stack, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import ConfirmationModal from '../../../components/ConfirmationModal';
import keyHasPermission from '../../../helpers/keyHasPermission';
import ErrorState from '../../../components/ErrorState';
import Icon from '../../../components/Icon';
import List from '../../../components/List';
import LoadingState from '../../../components/LoadingState';
import UtilitiesBar from '../../../components/UtilitiesBar';

import useIngredientGroups from '../../../hooks/useIngredientGroups';
import { IngredientGroup, IngredientItem } from '../../../models/Ingredient';

import GroupListHeader from './GroupListHeader';
import ItemsList from './ItemsList';
import IngredientGroupAddModal from './IngredientGroupAddModal';
import IngredientItemAddModal from './IngredientItemAddModal';
import getActiveLanguage from '../../../helpers/getActiveLanguage';
import useProfile from '../../../hooks/useProfile';
import arrayKeyHasPermission from '../../../helpers/arrayKeyHasPermission';
import EmptyState from '../../../components/EmptyState';
import errorReporting from '../../../helpers/errorReporting';

function Properties() {
  const { t } = useTranslation('ingredient');
  const { meProfile } = useProfile();

  const [activeGroup, setActiveGroup] = useState<IngredientGroup | null>(null);

  const [groupAddModal, setGroupAddModal] = React.useState(false);
  const [groupToEdit, setGroupToEdit] = React.useState<IngredientGroup | null>(
    null,
  );

  const [itemAddModal, setItemAddModal] = React.useState(false);
  const [itemToEdit, setItemToEdit] = React.useState<Omit<
    IngredientItem,
    'ingredient_group'
  > | null>(null);

  const [deleteConfirmation, setDeleteConfirmation] = useState<
    'group' | 'item' | null
  >(null);

  const {
    ingredientGroups,
    ingredientItems,
    ingredientGroupsError,
    ingredientGroupsLoading,
    fetchIngredientGroups,
    removeIngredientGroups,
    removeIngredientItem,
    deleting,
  } = useIngredientGroups();

  const hasCreatePermission = keyHasPermission(
    'create-ingredient-group',
    meProfile?.permissions || [],
  );

  const hasCreateItemPermission = keyHasPermission(
    'create-ingredient-group-item',
    meProfile?.permissions || [],
  );

  const hasMoreVertPermission = arrayKeyHasPermission(
    ['update-ingredient-group', 'delete-ingredient-group'],
    meProfile?.permissions || [],
  );

  const hasViewPermission = keyHasPermission(
    'view-ingredient-group-item-listing',
    meProfile?.permissions || [],
  );

  const hasMoreVertItemPermission = arrayKeyHasPermission(
    ['update-ingredient-group-item', 'delete-ingredient-group-item'],
    meProfile?.permissions || [],
  );

  const renderEmpty = () => <EmptyState message={t('empty')} />;

  useEffect(() => {
    const result = fetchIngredientGroups();
    return () => {
      result.abort();
    };
  }, []);

  useEffect(() => {
    if (ingredientGroups && !activeGroup) {
      setActiveGroup(ingredientGroups[0]);
    }
  }, [ingredientGroups]);

  if (ingredientGroupsLoading) {
    return <LoadingState />;
  }

  if (!ingredientGroups.length && ingredientGroupsError) {
    return (
      <ErrorState
        message={
          ingredientGroupsError
            ? ingredientGroupsError.message
            : t('common:unknownError' as any)
        }
      />
    );
  }

  const handleRemove = async () => {
    try {
      if (deleteConfirmation === 'group' && groupToEdit) {
        await removeIngredientGroups(groupToEdit.id);
        setGroupToEdit(null);
      }
      if (deleteConfirmation === 'item' && activeGroup && itemToEdit) {
        await removeIngredientItem(activeGroup.id, itemToEdit.id);
        setGroupToEdit(null);
      }
      // eslint-disable-next-line no-empty
    } catch (err) {
      errorReporting(err);
    } finally {
      setDeleteConfirmation(null);
      setItemToEdit(null);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('title')}
        leftIconName="Category"
      />
      <Stack
        sx={{ flex: 1 }}
        direction={{ md: 'row', xs: 'column' }}
        spacing={3}
      >
        <Paper sx={{ flex: 1, py: 2 }} elevation={3}>
          <GroupListHeader
            title={t('group.title')}
            onAddClick={() => setGroupAddModal(true)}
            addButton={hasCreatePermission}
          />
          <Divider />
          <List<IngredientGroup & { text: string }>
            items={ingredientGroups.map(item => ({
              ...item,
              text: item.translations?.[0].name || '',
            }))}
            activeItemId={activeGroup?.id || null}
            onItemClick={groupId => {
              const selectedGroup = ingredientGroups.find(
                el => el.id === groupId,
              );
              if (selectedGroup) setActiveGroup(selectedGroup);
            }}
            actions={[
              t('group.list.actions.edit'),
              t('group.list.actions.delete'),
            ]}
            hasMoreVertPermission={hasMoreVertPermission}
            onActionClick={(action, item) => {
              setGroupToEdit(item);
              switch (action) {
                case 0:
                  setGroupAddModal(true);
                  break;
                case 1:
                  setDeleteConfirmation('group');
                  break;
                default:
                  break;
              }
            }}
          />
        </Paper>
        <Paper
          sx={{
            flex: 3,
            p: 2,
          }}
          elevation={3}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              {activeGroup
                ? t('items.title', {
                    name: getActiveLanguage(activeGroup.translations).name,
                  })
                : ''}
            </Typography>
            {hasCreateItemPermission && (
              <Button
                startIcon={<Icon name="Add" />}
                sx={{ textTransform: 'none', fontSize: '12px' }}
                onClick={() => setItemAddModal(true)}
              >
                {t('items.addButton')}
              </Button>
            )}
          </Stack>
          {!hasViewPermission ? (
            renderEmpty()
          ) : (
            <>
              <Divider sx={{ mt: 1, mb: 3 }} />
              <ItemsList
                items={ingredientItems(activeGroup?.id)}
                onDelete={item => {
                  setItemToEdit(item);
                  setDeleteConfirmation('item');
                }}
                onEdit={item => {
                  setItemToEdit(item);
                  setItemAddModal(true);
                }}
                hasMoreVertItemPermission={hasMoreVertItemPermission}
              />
            </>
          )}
        </Paper>
      </Stack>
      <IngredientGroupAddModal
        open={groupAddModal}
        item={groupToEdit}
        onClose={() => {
          setGroupAddModal(false);
          setGroupToEdit(null);
        }}
        onConfirm={() => {
          setGroupAddModal(false);
          setGroupToEdit(null);
        }}
      />
      <IngredientItemAddModal
        open={itemAddModal}
        item={itemToEdit}
        groupId={activeGroup?.id}
        onClose={() => {
          setItemAddModal(false);
          setItemToEdit(null);
        }}
        onConfirm={() => {
          setItemAddModal(false);
          setItemToEdit(null);
        }}
      />
      <ConfirmationModal
        title={t('deleteConfirmation.title', {
          context: deleteConfirmation,
        })}
        titleIconName="QuestionMarkOutlined"
        description={t('deleteConfirmation.description', {
          context: deleteConfirmation,
        })}
        open={deleteConfirmation !== null}
        onClose={() => setDeleteConfirmation(null)}
        positiveAction={t('deleteConfirmation.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('deleteConfirmation.cancel')}
        onNegativeClick={() => setDeleteConfirmation(null)}
        loading={deleting}
      />
    </Box>
  );
}

export default Properties;
