/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

/** Styles */
import {
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';

/** Components */
import EmptyState from '../../../components/EmptyState';
import Icon from '../../../components/Icon';

/** Hooks */
import useUsers from '../../../hooks/useUsers';
import useProfile from '../../../hooks/useProfile';
import ConfirmationModal from '../../../components/ConfirmationModal';
import keyHasPermission from '../../../helpers/keyHasPermission';
import TableComponent from '../../../components/TableComponent';

interface Props {
  onItemEdit: (item: any) => void;
  onItemRemove: (id: string) => Promise<any>;
}

function UsersTable({ onItemEdit, onItemRemove }: Props) {
  const { t } = useTranslation('user');
  const { users, meta, fetchUsers } = useUsers();
  const { meProfile } = useProfile();

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(
    null,
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (value !== page) {
      setPage(value);
      fetchUsers({ page: value });
    }
  };

  const handleRemove = async () => {
    if (deleteConfirmation) {
      try {
        setLoading(true);
        await onItemRemove(deleteConfirmation);
        setDeleteConfirmation(null);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const hasListPermission = keyHasPermission(
    'view-user-listing',
    meProfile?.permissions || [],
  );

  const hasUpdatePermission = keyHasPermission(
    'update-user',
    meProfile?.permissions || [],
  );

  const hasDeletePermission = keyHasPermission(
    'delete-user',
    meProfile?.permissions || [],
  );

  const tableHead = [
    `${t('user.usersTable.firstName')} ${t('user.usersTable.lastName')}`,
    t('user.usersTable.phoneNumber'),
    t('user.usersTable.email'),
    t('user.usersTable.pin'),
    t('user.usersTable.role'),
    t('user.usersTable.date'),
    t('user.usersTable.sections'),
    t('user.usersTable.operations'),
  ];

  const tableBody = users.map(row => (
    <TableRow key={row.id}>
      <TableCell component="th" scope="row">
        {row.first_name} {row.last_name}
      </TableCell>
      <TableCell>{row.phone_number}</TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell>{row.pin}</TableCell>
      <TableCell>
        {row.branches
          ? row.branches.find(item => item.id === meProfile?.activeBranch?.id)
              ?.role?.name
          : ''}
      </TableCell>
      <TableCell>
        {row.last_login_at
          ? format(new Date(row.last_login_at), 'dd.MM.yyyy HH:mm')
          : '-'}{' '}
        /{' '}
        {row.last_logout_at
          ? format(new Date(row.last_logout_at), 'dd.MM.yyyy HH:mm')
          : '-'}
      </TableCell>
      <TableCell component="th" scope="row">
        {(row?.sections?.length > 1
          ? // eslint-disable-next-line @typescript-eslint/no-shadow
            row?.sections?.map((item, index, row) =>
              index + 1 === row.length ? `${item.name}` : `${item.name}, `,
            )
          : row?.sections?.map(item => item.name)) || '-'}
      </TableCell>
      <TableCell>
        <IconButton
          onClick={event => {
            if (hasUpdatePermission) {
              onItemEdit(row);
            }
          }}
          aria-label="Add"
          color="primary"
          disabled={!hasUpdatePermission}
        >
          <Icon name="Edit" />
        </IconButton>
        <IconButton
          onClick={event => {
            if (hasDeletePermission) {
              setDeleteConfirmation(row.id);
            }
          }}
          aria-label="Add"
          color="error"
          disabled={!hasDeletePermission}
        >
          <Icon name="Delete" />
        </IconButton>
      </TableCell>
    </TableRow>
  ));

  const renderEmpty = () => (
    <EmptyState
      message={
        !hasListPermission ? t('user_table.no_authority') : t('user.empty')
      }
    />
  );

  useEffect(() => {
    // @ts-ignore
    setPage(meta.current_page);
  }, [meta]);

  return (
    <Paper sx={{ width: '100%', p: 2 }}>
      {!hasListPermission || !users.length ? (
        renderEmpty()
      ) : (
        <TableComponent
          tableHead={tableHead}
          tableBody={tableBody}
          showPagination
          page={page}
          tableStyle={{ minWidth: 650 }}
          handleChange={handleChange}
          // @ts-ignore
          count={Math.ceil(meta.total / meta.per_page)}
        />
      )}
      <ConfirmationModal
        title={t('user_table.delete_user')}
        titleIconName="QuestionMarkOutlined"
        description={t('user_table.sure_delete')}
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        positiveAction={t('user_table.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('user_table.cancel')}
        onNegativeClick={() => setDeleteConfirmation(null)}
      />
    </Paper>
  );
}

export default UsersTable;
