/** Dependencies */
import React from 'react';

/** Styles */
import { Box, Menu, MenuItem } from '@mui/material';

/** Components */
import Icon, { IconName } from '../Icon';
import Button from '../Button';

interface Props {
  anchor: null | HTMLElement;
  onClose: () => void;
  menu?:
    | {
        id: number;
        text: string;
        startIconName?: IconName | undefined;
        endIconName?: IconName | undefined;
      }[]
    | undefined;
  onActionClick?: (id: number) => void;
}
function MobileMenu({ anchor, onClose, menu, onActionClick }: Props) {
  return (
    <Box>
      <Menu
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchor)}
        onClose={onClose}
      >
        {menu &&
          menu.map(item => (
            <MenuItem
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                onActionClick && onActionClick(item.id);
                onClose();
              }}
            >
              <Button
                key={item.text}
                variant="text"
                onClick={() => onActionClick && onActionClick(item.id)}
                startIcon={
                  item.startIconName && <Icon name={item.startIconName} />
                }
                endIcon={item.endIconName && <Icon name={item.endIconName} />}
              >
                {item.text}
              </Button>
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
}

export default MobileMenu;
