/** Dependencies */
import React from 'react';
import {
  Box,
  IconButton,
  Stack,
  Typography,
  List,
  ListItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

/** Styles */
import styles from '../styles';
import generalStyles from '../../../../../../../../assets/stylesheets/generalStyles';

/** Components */
import Icon from '../../../../../../../../components/Icon';

/** Helpers */
import getActiveLanguage from '../../../../../../../../helpers/getActiveLanguage';

/** Types */
import {
  MenuProduct,
  Menus,
} from '../../../../../../../../store/slices/basketItemSlice/types';
import { MenuItems } from '../../../../../../../../models/Product';

interface Props {
  isSubmittedOrder: boolean;
  productMenus: Menus[];
  handleOnMenuSelection: (item: MenuProduct) => void;
  onAction: () => void;
  menuItems: MenuItems[];
  setOpenNote: React.Dispatch<React.SetStateAction<boolean>>;
  setNoteType: React.Dispatch<React.SetStateAction<string>>;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  setMenuItemId: React.Dispatch<React.SetStateAction<string>>;
}

function ProductItemCounter({
  isSubmittedOrder,
  productMenus,
  handleOnMenuSelection,
  onAction,
  menuItems,
  setOpenNote,
  setNoteType,
  setDescription,
  setMenuItemId,
}: Props) {
  const { t } = useTranslation('pos');

  const isActiveProperties = (propertyGroups: any) =>
    propertyGroups.length
      ? propertyGroups.some(
          (item: any) =>
            item.items.filter((propItem: any) => propItem.is_active).length,
        )
      : false;

  const isActiveIngredients = (ingredientGroups: any) =>
    ingredientGroups.length
      ? ingredientGroups.some(
          (item: any) =>
            item.items.filter((propItem: any) => propItem.is_active).length,
        )
      : false;

  return (
    <Box>
      {!isSubmittedOrder &&
        productMenus.length > 0 &&
        productMenus.map(menuItem =>
          menuItem.menu_items.map(item => (
            <>
              <Box>
                <Stack direction="row" sx={{ color: 'red', mt: 3, ml: 4 }}>
                  <Icon
                    sx={{ mt: 1, color: 'rgba(211,47,47,0.44)' }}
                    name="SubdirectoryArrowRight"
                  />
                  <Stack
                    sx={generalStyles.generalFlex}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box>
                      <Typography sx={styles.menuItemTitle}>
                        {getActiveLanguage(item.product_translations).name} x
                        {item.count}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={styles.menuItemSubtitle}
                      >
                        {item.product_unit
                          ? getActiveLanguage(item?.product_unit?.translations)
                              .name
                          : ''}
                      </Typography>
                      {!isSubmittedOrder && (
                        <Typography sx={styles.properties} variant="subtitle2">
                          {item?.active_multiple_property_names &&
                            item?.active_multiple_property_names?.length >
                              0 && (
                              <List>
                                {item?.active_multiple_property_names.map(
                                  propItem => (
                                    <ListItem sx={styles.priceInfo}>
                                      {propItem.name}
                                    </ListItem>
                                  ),
                                )}
                              </List>
                            )}
                          {item?.active_single_property_name && (
                            <List>
                              <ListItem sx={styles.priceInfo}>
                                {item?.active_single_property_name.name}
                              </ListItem>
                            </List>
                          )}
                          <List>
                            {item?.non_ingredient_item_id?.length
                              ? item?.non_ingredient_item_id?.map(ingItem => (
                                  <ListItem sx={styles.priceInfoUnderline}>
                                    {
                                      item.ingredient_groups[0]?.items?.find(
                                        (ing: { id: string }) =>
                                          ing.id === ingItem,
                                      )?.translations[0].name
                                    }
                                  </ListItem>
                                ))
                              : ''}
                          </List>
                        </Typography>
                      )}
                    </Box>
                    <Box sx={generalStyles.generalDisplay}>
                      {!isSubmittedOrder &&
                        (isActiveProperties(item.property_groups) ||
                          isActiveIngredients(item.ingredient_groups)) && (
                          <IconButton
                            sx={{ ...generalStyles.icon, mr: 2 }}
                            onMouseDown={e => e.stopPropagation()}
                            onClick={e => {
                              e.stopPropagation();
                              handleOnMenuSelection(item);
                            }}
                          >
                            <Icon name="List" />
                          </IconButton>
                        )}
                      <IconButton
                        sx={generalStyles.icon}
                        color="error"
                        onMouseDown={e => e.stopPropagation()}
                        onClick={e => {
                          e.stopPropagation();
                          setOpenNote(true);
                          setNoteType('menuOrder');
                          setDescription(item.description || '');
                          setMenuItemId(item.itemId || '');
                        }}
                      >
                        <Icon name="MoreVert" />
                      </IconButton>
                    </Box>
                  </Stack>
                </Stack>
              </Box>
              {item.description && (
                <Box sx={{ ml: 4, fontSize: '12px', color: '#FF0000' }}>
                  {t('note')}: {item.description}
                </Box>
              )}
            </>
          )),
        )}

      {isSubmittedOrder &&
        menuItems.map(item => (
          <Box>
            <Stack direction="row" sx={{ mt: 3, ml: 4 }}>
              <Icon
                sx={{ mt: 1, color: 'rgba(70,70,70,0.44)' }}
                name="SubdirectoryArrowRight"
              />
              <Stack
                sx={generalStyles.generalFlex}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Typography sx={styles.menuItemTitle}>
                    {getActiveLanguage(item.product.translations).name}{' '}
                    {`x${item.quantity}`}
                  </Typography>
                  <Typography variant="subtitle2" sx={styles.menuItemSubtitle}>
                    {item.product_unit
                      ? getActiveLanguage(
                          item?.product_unit[0].unit.translations,
                        ).name
                      : ''}
                  </Typography>
                  <Typography sx={styles.properties} variant="subtitle2">
                    <List>
                      {item.property_group_items.length > 0 &&
                        item.property_group_items?.map(propItem => (
                          <ListItem sx={styles.priceInfo}>
                            {getActiveLanguage(propItem.translations).name}
                            {propItem.additional_price.amount !== '0' &&
                              ` + ${propItem.additional_price.formatted}`}
                          </ListItem>
                        ))}
                    </List>
                    <List>
                      {item.ingredient_group_items &&
                        item.ingredient_group_items.length > 0 &&
                        item.ingredient_group_items?.map(ingItem => (
                          <ListItem sx={styles.priceInfoUnderline}>
                            {ingItem.translations[0].name}
                          </ListItem>
                        ))}
                    </List>
                  </Typography>
                </Box>
              </Stack>
            </Stack>
            {item.description && (
              <Box sx={{ ml: 4, fontSize: '12px' }}>
                {t('note')}: {item.description}
              </Box>
            )}
          </Box>
        ))}
    </Box>
  );
}

export default ProductItemCounter;
