import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import {
  PackageOrderParams,
  SelfOrderParams,
} from '../../services/api/pos/types';
import { isApiError } from '../../services/api/types';
import {
  GroupedData,
  GroupedDataColumnType,
} from '../../services/api/orders/types';
import { PosTransaction } from '../../models/Pos';
import arrayCatcher from '../../helpers/errorCatcher';

const useOrders = () => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [orderSubmitting, setOrderSubmitting] = useState(false);

  const [groupedOrdersLoading, setGroupedOrdersLoading] = useState(false);
  const [groupedOrderDetailsLoading, setGroupedOrderDetailsLoading] =
    useState(false);
  const [groupedOrders, setGroupedOrders] = useState<
    GroupedDataColumnType[] | null
  >(null);
  const [lastOrderTransactions, setLastOrderTransactions] = useState<
    PosTransaction[] | null
  >(null);
  const [groupedOrderDetails, setGroupedOrderDetails] =
    useState<PosTransaction | null>(null);
  const [groupedOrdersError, setGroupedOrdersError] = useState<null | Error>(
    null,
  );
  const [groupedOrderDetailsError, setGroupedOrderDetailsError] =
    useState<null | Error>(null);
  const [meta, setMeta] = useState<any>(null);

  const createPackageOrder = async (
    params: Omit<PackageOrderParams, 'type'>,
  ) => {
    try {
      setOrderSubmitting(true);
      // create order
      const result =
        params.orders.length || params.special_orders?.length
          ? await api.orders.packageOrder(params)
          : null;
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
      throw err; // pass error down to prevent modal fom closing
    } finally {
      setOrderSubmitting(false);
    }
  };

  const createSelfOrder = async (params: Omit<SelfOrderParams, 'type'>) => {
    try {
      setOrderSubmitting(true);
      setGroupedOrderDetailsLoading(true);
      // create order
      await api.orders.selfOrder(params);
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
      });
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
      throw err; // pass error down to prevent modal fom closing
    } finally {
      setOrderSubmitting(false);
      setGroupedOrderDetailsLoading(false);
    }
  };

  const getGroupedOrders = async (
    day_id: string,
    isActive: boolean,
    isCompleted: boolean,
    isCanceled: boolean,
    page: number,
    status_id: number[] | null,
  ) => {
    try {
      setGroupedOrdersLoading(true);

      const result = await api.orders.getGroupedOrders(
        day_id,
        isActive,
        isCompleted,
        isCanceled,
        page,
        status_id,
      );
      setGroupedOrders(result.data.data);
      setMeta(result.data.meta);
    } catch (err: any) {
      arrayCatcher(err?.code);
      setGroupedOrdersError(err as Error);
    } finally {
      setGroupedOrdersLoading(false);
    }
  };

  const setGroupedOrdersWithPusher = async (
    data: any,
    selectActive: number,
  ) => {
    try {
      let formattedData;

      if (selectActive === 0) {
        if (data.status === 16 || data.status === 7) {
          formattedData = groupedOrders?.filter(item => item.id !== data.id);
        } else if (groupedOrders?.find(item => item.id === data.id)) {
          formattedData = groupedOrders?.map(item =>
            item?.id === data?.id
              ? {
                  ...item,
                  created_at: data?.created_at,
                  customer: data?.customer,
                  deliverer: data?.deliverer,
                  ended_at: data?.ended_at,
                  id: data?.id,
                  integrator: data?.integrator,
                  is_paid: data?.is_paid,
                  paid_amount: data?.paid_amount,
                  payments: data?.payments,
                  preset_payment_type: data?.preset_payment_type,
                  status: data?.status,
                  status_id: data?.status_id,
                  total_amount: data?.total_amount,
                  total_discount: data?.total_discount,
                  transaction_number: data?.transaction_number,
                  type: data?.type,
                  type_id: data?.type_id,
                }
              : item,
          );
        } else {
          formattedData =
            groupedOrders && groupedOrders?.length > 0
              ? [...groupedOrders, data]
              : [data];
        }
      } else {
        formattedData =
          groupedOrders && groupedOrders?.length > 0
            ? [...groupedOrders, data]
            : [data];
      }

      // @ts-ignore
      setGroupedOrders(formattedData);
    } catch (err: any) {
      setGroupedOrdersError(err as Error);
    }
  };

  const getOrderLastTransactions = async (customer_id: string) => {
    try {
      const result = await api.orders.getOrdersLastTransactions(customer_id);
      // @ts-ignore
      setLastOrderTransactions(result.data.data);
    } catch (err) {
      // setGroupedOrdersError(err as Error);
    }
  };

  const getGroupedOrderDetails = async (tableId: string) => {
    try {
      setGroupedOrderDetailsLoading(true);
      const result = await api.orders.getGroupedOrderDetails(tableId);
      setGroupedOrderDetails(result.data.data);
    } catch (err) {
      setGroupedOrderDetailsError(err as Error);
    } finally {
      setGroupedOrderDetailsLoading(false);
    }
  };

  const setOrderStatus = async (tableId: string, status_id: number) => {
    try {
      await api.orders.setStatus(tableId, status_id);
      enqueueSnackbar(t('common:successStatus' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
      });
    } catch (err) {
      setGroupedOrdersError(err as Error);
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    }
  };

  return {
    createPackageOrder,
    createSelfOrder,
    getGroupedOrders,
    getGroupedOrderDetails,
    getOrderLastTransactions,
    setOrderStatus,
    orderSubmitting,
    groupedOrders,
    lastOrderTransactions,
    groupedOrderDetails,
    groupedOrdersLoading,
    groupedOrderDetailsLoading,
    groupedOrdersError,
    groupedOrderDetailsError,
    meta,
    setGroupedOrdersWithPusher,
  };
};
export default useOrders;
