import { SxProps, Theme } from '@mui/material';

const generalStyles: Record<string, SxProps<Theme>> = {
  container: {},
  generalDisplay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  generalFlex: {
    flex: 1,
    pl: 2,
    cursor: 'pointer',
  },
  buttonContainer: {
    width: 40,
    height: '100%',
    boxSizing: 'content-box',
    p: 0.2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  orderCountContainer: {
    width: 40,
    height: '100%',
    p: 0.2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#f5f5f5',
  },
  icon: {
    borderRadius: 1,
    '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
      borderRadius: 1,
    },
    p: 0,
  },
  date: { textAlign: 'center', color: 'rgba(0,0,0,.5)', fontSize: '11px' },
};

export default generalStyles;
