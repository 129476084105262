/** Dependencies */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import { Box } from '@mui/material';

/** Components */
import UtilitiesBar, {
  Props as BarProps,
} from '../../../components/UtilitiesBar';
import Form from './Forms/Form';
import SalesChannelsEditModal from './Modals/SalesChannelsEditModal';

function RestaurantSettings() {
  const { t } = useTranslation('printerSettings');
  const [openSalesModal, setOpenSalesModal] = useState<boolean>(false);

  const MENU: BarProps['menu'] = [
    { id: 0, text: t('edit_sales_channel'), startIconName: 'Add' },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        menu={MENU!.map(item => ({ ...item, text: item.text as any }))}
        title={t('restaurant')}
        leftIconName="Settings"
        onActionClick={() => setOpenSalesModal(true)}
      />

      <Form />
      <SalesChannelsEditModal
        open={openSalesModal}
        onClose={() => setOpenSalesModal(false)}
      />
    </Box>
  );
}

export default RestaurantSettings;
