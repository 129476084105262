import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../components/FormInput';
import Modal from '../../../components/Modal';
import useSections from '../../../hooks/useSections';
import { isApiError } from '../../../services/api/types';
import { SectionBase } from './types';
import errorReporting from '../../../helpers/errorReporting';
import FormSelect from '../../../components/FormSelect';
import useSettings from '../../../hooks/useSettings';
import { OptionsMemoWithName } from '../../../helpers/optionMemo';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm?: (name: string) => void;
  item?: SectionBase | null;
}

function SectionAddModal({ open, onClose, onConfirm, item }: Props) {
  const { t } = useTranslation('table');
  const { enqueueSnackbar } = useSnackbar();
  const { addSection, updateSection } = useSections();
  const { channelSales, fetchChannelSales } = useSettings();

  const [name, setName] = useState('');
  const [salesChannel, setSalesChannel] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    if (name) {
      try {
        setLoading(true);

        const result = !item?.id
          ? await addSection(name, salesChannel)
          : await updateSection({
              id: item.id,
              name,
              sales_channel_id: salesChannel,
            });

        setName('');
        if (onConfirm) onConfirm(name);
      } catch (err) {
        enqueueSnackbar(
          isApiError(err) ? err.message : t('common:unknownError' as any),
          {
            variant: 'error',
            autoHideDuration: 3000,
          },
        );
        errorReporting(err);
      } finally {
        setLoading(false);
      }
    } else {
      setIsSubmitted(true);
    }
  };

  useEffect(() => {
    if (item) {
      setName(item.name);
      setSalesChannel(item?.sales_channel?.id || '');
    }
    return () => {
      setName('');
      setSalesChannel('');
    };
  }, [item]);

  useEffect(() => {
    if (channelSales && !item) {
      setSalesChannel(channelSales[0]?.id || '');
    }
  }, [channelSales]);

  useEffect(() => {
    if (open) {
      fetchChannelSales();
    }
  }, [open]);

  const invalid = !name && isSubmitted;

  return (
    <Modal
      title={item ? t('section.editModal.title') : t('section.add_modal.title')}
      titleIconName="Add"
      open={open}
      onClose={onClose}
      positiveAction={t('section.add_modal.confirm')}
      negativeAction={t('section.add_modal.cancel')}
      onPositiveClick={handleConfirm}
      onNegativeClick={onClose}
      loading={loading}
    >
      <FormInput
        label={t('section.add_modal.form.name.label')}
        error={invalid}
        helperText={invalid && t('section.add_modal.form.name.required')}
        value={name}
        onChange={event => setName(event.target.value)}
        disableUnderline
      />
      <FormSelect
        label={t('section.add_modal.form.channel.label')}
        error={invalid}
        value={salesChannel}
        onChange={event => setSalesChannel(event.target.value as string)}
        options={OptionsMemoWithName(channelSales)}
      />
    </Modal>
  );
}

export default SectionAddModal;
