const styles = {
  button: {
    textTransform: 'none',
    border: '2px solid #e4e4e487',
    borderRadius: '15px',
    color: 'rgba(0,0,0,0.64)',
    marginBottom: '15px',
    marginRight: '10px',
  },
  checked: {
    border: '1px solid #241468',
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#e0e0e0',
      border: '1px solid #241468',
    },
  },
  nonIngredient: {
    border: '1px solid #ff0000c1',
    backgroundColor: '#e0e0e0',
    textDecorationLine: 'line-through',
    color: '#ff0000c1',
  },
  onSelectionDisplay: {
    display: 'flex',
    alignItems: 'center',
  },
  onSelectionMinHeight: {
    minHeight: '0',
  },
  accordionPadding: {
    padding: '0px 16px 0px',
  },
  accordionIcon: { fontSize: '0.8rem', color: 'black' },
  onSelectionTypography: { fontSize: '10px', ml: 1 },
} as any;

export default styles;
