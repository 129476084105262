import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Form from './Form';
import Modal from '../../../../../../components/Modal';
import {
  defaultValues,
  SpecialOrderFormValues,
  validationSchema,
} from './types';
import LoadingState from '../../../../../../components/LoadingState';
import { SpecialOrder } from '../../types';

interface Props {
  open: boolean;
  onClose: () => void;
  specialOrders: SpecialOrder[];
  setSpecialOrders: React.Dispatch<React.SetStateAction<SpecialOrder[]>>;
}
function SpecialOrderModal({
  open,
  onClose,
  specialOrders,
  setSpecialOrders,
}: Props) {
  const { t } = useTranslation('pos');
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control, reset } = useForm<SpecialOrderFormValues>({
    defaultValues,
    resolver: validationSchema,
  });

  const [loading, setLoading] = useState(false);

  const handleConfirm = () => {
    // eslint-disable-next-line consistent-return
    handleSubmit(async values => {
      try {
        setLoading(true);
        if (Number(values.price) < 0) {
          return enqueueSnackbar('Fiyat negatif girilemez!', {
            variant: 'error',
          });
        }

        setSpecialOrders([
          ...specialOrders,
          {
            ...values,
            itemId: Date.now().toString(),
            price: Number(values.price === '0,00' ? '0' : values.price) * 100,
            is_treat: false,
          },
        ]);

        enqueueSnackbar('Başarılı', {
          variant: 'success',
        });
        reset(defaultValues);
        onClose();
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        setLoading(false);
      }
    })();
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  if (loading) {
    return <LoadingState />;
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={t('orderModal.specialOrderModal.title')}
      positiveAction={t('orderModal.specialOrderModal.confirm')}
      onPositiveClick={() => {
        handleConfirm();
      }}
      negativeAction={t('orderModal.specialOrderModal.cancel')}
      onNegativeClick={handleClose}
    >
      <Form control={control} />
    </Modal>
  );
}

export default SpecialOrderModal;
