import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../services/api';
import { addUser, updateUser, removeUser } from './usersSlice';
import {
  UserCreateParams,
  UserUpdateParams,
} from '../../../../services/api/userOperations/users/types';
import arrayCatcher from '../../../../helpers/errorCatcher';

export const createUserThunk = createAsyncThunk(
  'user/create',
  async (params: UserCreateParams, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.userOperations.users.create(params);
      const result = response.data.data;
      dispatch(
        addUser({ ...result, is_caller_id_user: params.is_caller_id_user }),
      );
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateUserThunk = createAsyncThunk(
  'user/update',
  async (params: UserUpdateParams, { rejectWithValue, dispatch }) => {
    try {
      const { userId, ...rest } = params;
      const response = await api.userOperations.users.update({
        ...rest,
        userId,
      });
      const result = response.data.data;
      dispatch(updateUser(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const changeBranchThunk = createAsyncThunk(
  'user/change-branch',
  async (branch_id: string, { rejectWithValue }) => {
    try {
      const response = await api.userOperations.users.changeBranch(branch_id);
      const result = response.data.data;
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const removeUserThunk = createAsyncThunk(
  'user/remove',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.userOperations.users.remove(id);
      const result = response.data.data;
      dispatch(removeUser(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getUsersListThunk = createAsyncThunk(
  'user/list',
  async (params: { page: number; role?: string }, { rejectWithValue }) => {
    try {
      const response = await api.userOperations.users.list(
        params.page,
        params?.role,
      );
      const result = response.data;
      return result;
    } catch (err: any) {
      arrayCatcher(err?.code);
      return rejectWithValue(err);
    }
  },
);

export const getUserThunk = createAsyncThunk(
  'user/get',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.userOperations.users.view(id);
      const result = response.data.data;
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
