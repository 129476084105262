/** Dependencies */
import React from 'react';
import { Typography } from '@mui/material';

/** Helpers */
import { singlePriceFormatter } from '../../../../../../../../helpers/moneyFormatter';

/** Types */
import { Unit } from '../../../../../../../../models/Product';

interface Props {
  isSubmittedOrder: boolean;
  count: number;
  totalPrice?: number;
  currencyCode: string;
  unit?: Unit | null;
  isTreat: boolean;
}

function ProductItemCounter({
  isSubmittedOrder,
  count,
  totalPrice,
  currencyCode,
  unit,
  isTreat,
}: Props) {
  return (
    <Typography
      sx={
        !isSubmittedOrder
          ? {
              mr: 5,
              fontSize: { xs: 14, sm: 13, lg: 16 },
              textDecoration: isTreat ? 'line-through' : '',
            }
          : {
              mr: 2,
              fontSize: { xs: 14, sm: 13, lg: 16 },
              textDecoration: isTreat ? 'line-through' : '',
            }
      }
    >
      {!isTreat &&
        (isSubmittedOrder
          ? totalPrice
            ? singlePriceFormatter(
                (totalPrice * 100).toString(),
                1,
                currencyCode,
              )
            : singlePriceFormatter(unit?.amount.amount, count, currencyCode)
          : totalPrice
          ? singlePriceFormatter(
              (totalPrice * 100).toString(),
              count,
              currencyCode,
            )
          : singlePriceFormatter(unit?.amount.amount, count, currencyCode))}
    </Typography>
  );
}

export default ProductItemCounter;
