import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Pagination,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import useFlow from '../../../../hooks/useFlow';
import EmptyState from '../../../../components/EmptyState';
import { SuspiciousTransaction } from '../../../../services/api/operations/fraud/types';
import SuspiciousTransactionModal from '../Modals/SuspiciousTransactionModal';

export function SuspiciousTransactions() {
  const {
    fetchSuspiciousTransaction,
    suspiciousTransactionList,
    suspiciousTransactionListMeta,
  } = useFlow();
  const { t } = useTranslation('frauds');

  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [transaction, setTransaction] = useState<SuspiciousTransaction | null>(
    null,
  );

  const renderEmpty = () => <EmptyState message="Veri Bulunamadı" />;

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(15),
      border: '1px solid #dadde9',
    },
  }));

  const handleChange = async (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
    await fetchSuspiciousTransaction(value);
  };

  useEffect(() => {
    fetchSuspiciousTransaction(page);
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      {!suspiciousTransactionList.length ? (
        renderEmpty()
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('suspiciousTransactions.table.date')}</TableCell>
                <TableCell>{t('suspiciousTransactions.table.name')}</TableCell>
                <TableCell>{t('suspiciousTransactions.table.check')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {suspiciousTransactionList.map(row => (
                <TableRow key={row.id}>
                  <TableCell>
                    {format(new Date(row.created_at), 'dd.MM.yyyy HH:mm')}
                  </TableCell>
                  <TableCell>
                    <HtmlTooltip
                      placement="bottom-start"
                      title={
                        <Box>
                          <Box fontWeight={700}>
                            {t('suspiciousTransactions.table.params')}
                          </Box>
                          <Divider />
                          <Box mt={1}>
                            {row.triggers.map(item => (
                              <Box>
                                {`${item.action_type.label}: ${
                                  item.amount?.formatted ||
                                  `%${item.percentage}`
                                }`}
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      }
                      arrow
                      sx={{ fontSize: '14px' }}
                      color="warning"
                    >
                      <span>{row.fraud_flow.name}</span>
                    </HtmlTooltip>
                  </TableCell>
                  <TableCell
                    sx={{ cursor: 'pointer', color: '#2fdf2f' }}
                    onClick={() => {
                      setTransaction(row);
                      setOpen(true);
                    }}
                  >
                    #{row.order_transaction.transaction_number}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            sx={{ margin: '10px' }}
            count={Math.ceil(
              // @ts-ignore
              suspiciousTransactionListMeta.total /
                // @ts-ignore
                suspiciousTransactionListMeta.per_page,
            )}
            page={page}
            onChange={handleChange}
          />
        </TableContainer>
      )}
      <SuspiciousTransactionModal
        open={open}
        onClose={() => {
          setTransaction(null);
          setOpen(false);
        }}
        item={transaction}
      />
    </Box>
  );
}
