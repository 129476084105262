import auth from './auth';
import definitions from './definitions';
import utilities from './utilities';
import profile from './profile';
import pos from './pos';
import kitchen from './kitchen';
import userOperations from './userOperations';
import settings from './settings';
import operations from './operations';
import reports from './reports';
import orders from './orders';
import integrations from './integrations';

export default {
  auth,
  definitions,
  utilities,
  profile,
  pos,
  kitchen,
  userOperations,
  settings,
  operations,
  reports,
  orders,
  integrations,
};
