/** Dependencies */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/** Types */
import { InitialState, User } from './types';

/** Thunks */
import { getUsersListThunk, getUserThunk } from './thunks';

const initialState: InitialState = {
  user: null,
  loading: false,
  users: [],
  meta: [],
};

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    addUser: (state, action: PayloadAction<User>) => ({
      ...state,
      users: [...state.users, action.payload],
    }),
    updateUser: (state, action: PayloadAction<User>) => ({
      ...state,
      users: state.users.map(item =>
        item.id === action.payload.id ? action.payload : item,
      ),
    }),
    removeUser: (state, action: PayloadAction<Pick<User, 'id'>>) => ({
      ...state,
      users: state.users.filter(item => item.id !== action.payload.id),
    }),
  },
  extraReducers: builder => {
    builder
      .addCase(getUsersListThunk.pending, state => ({
        ...state,
        loading: true,
      }))
      .addCase(getUsersListThunk.fulfilled, (state, action) => ({
        ...state,
        users: action.payload.data,
        meta: action.payload.meta,
        loading: false,
      }))
      .addCase(getUsersListThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }))
      .addCase(getUserThunk.pending, state => ({
        ...state,
        loading: true,
      }))
      .addCase(getUserThunk.fulfilled, (state, action) => ({
        ...state,
        user: action.payload,
        loading: false,
      }))
      .addCase(getUserThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }));
  },
});

export const { addUser, updateUser, removeUser } = userSlice.actions;

export default userSlice.reducer;
