import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExtraParams } from '../../apps/Pos/Sections/OrderModal';
import { PosTransaction } from '../../models/Pos';
import api from '../../services/api';
import {
  OrderTransactionDiscountParams,
  SpecialOrderParams,
  TableOrderParams,
} from '../../services/api/pos/types';
import { isApiError } from '../../services/api/types';
import { Payment } from '../../apps/Pos/Sections/OrderModal/PaymentModal/types';
import { useAppSelector, useAppDispatch } from '../../store';

const usePosSearch = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();

  const [submitting, setSubmitting] = useState(false);
  const [extraParamsId, setExtraParamsId] = useState('');
  const [orderDetailsLoading, setOrderDetailsLoading] = useState(false);
  const [customerDetailsLoading, setCustomerDetailsLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState<PosTransaction | null>(null);
  const [inActiveOrderDetails, setInActiveOrderDetails] = useState<
    PosTransaction[] | null
  >(null);
  const [openAccountDetails, setOpenAccountDetails] = useState<
    PosTransaction[] | null
  >(null);
  const [openAccountMeta, setOpenAccountMeta] = useState<any[]>();
  const [orderDetailsError, setOrderDetailsError] = useState<null | Error>(
    null,
  );

  const createTableOrder = async (
    params: Omit<TableOrderParams, 'type'>,
    extraParams: ExtraParams,
  ) => {
    try {
      setSubmitting(true);
      // create order
      const result =
        params.orders.length || params.special_orders?.length
          ? await api.pos.tableOrder(params)
          : null;
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
      throw err; // pass error down to prevent modal fom closing
    } finally {
      setSubmitting(false);
    }
  };

  const changeGuestCount = async (
    tableId: string,
    guestsCount: number,
    order_transaction_id: string,
    type: number,
    is_automatic_couvert: boolean,
  ) => {
    try {
      const result = await api.pos.setGuestCount(
        tableId,
        guestsCount,
        order_transaction_id,
        type,
        is_automatic_couvert,
      );
      // @ts-ignore
      setExtraParamsId(result.data.data.id);
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const changeServiceCharge = async (
    order_transaction_id: string,
    is_automatic_service_charge: boolean,
  ) => {
    try {
      const result = await api.pos.setServiceCharge(
        order_transaction_id,
        is_automatic_service_charge,
      );
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const changeDescription = async (
    description: string,
    tableId: string,
    order_transaction_id: string,
    type: number,
  ) => {
    try {
      const result = await api.pos.setOrderDescription(
        description,
        tableId,
        order_transaction_id,
        type,
      );
      // @ts-ignore
      setExtraParamsId(result.data.data.id);
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const changeTableName = async (tableId: string, tableName: string) => {
    try {
      await api.pos.setTableName(tableId, tableName);
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const setDeliverer = async (
    orderTransactionId: string,
    delivererId: string,
  ) => {
    try {
      await api.pos.setDeliverer(orderTransactionId, delivererId);
      enqueueSnackbar(t('successDeliverer'), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const changeOrderCustomer = async (
    tableId: string,
    order_transaction_id: string,
    type: number,
    customerId?: string,
    customer_address_id?: string,
  ) => {
    try {
      await api.pos.setOrderCustomer(
        tableId,
        order_transaction_id,
        type,
        customerId,
        customer_address_id,
      );
      if (customerId) {
        enqueueSnackbar(t('success'), {
          variant: 'success',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        });
      }
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const getOrderDetails = async (tableId: string) => {
    try {
      setOrderDetailsLoading(true);

      const result = await api.pos.getOrderDetails(tableId);
      setOrderDetails(result.data.data);
    } catch (err) {
      setOrderDetailsError(err as Error);
    } finally {
      setOrderDetailsLoading(false);
    }
  };

  const getOrderTransaction = async (orderId: string) => {
    try {
      setOrderDetailsLoading(true);

      const result = await api.pos.getOrderTransaction(orderId);
      setOrderDetails(result.data.data);
    } catch (err) {
      setOrderDetailsError(err as Error);
    } finally {
      setOrderDetailsLoading(false);
    }
  };

  const changeTable = async (from: string, to: string) => {
    try {
      await api.pos.changeTable(from, to);
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const moveTableOrder = async (from: string, to: string) => {
    try {
      await api.pos.moveTableOrder(from, to);
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const splitTable = async (table_id: string) => {
    try {
      await api.pos.splitTable(table_id);
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const mergeTables = async (main_table_id: string, table_ids: string[]) => {
    try {
      await api.pos.mergeTables(main_table_id, table_ids);
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const addSpecialOrder = async (params: SpecialOrderParams) => {
    try {
      await api.pos.addSpecialOrder(params);
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const addOrderTransactionDiscount = async (
    params: OrderTransactionDiscountParams,
  ) => {
    try {
      await api.pos.addOrderTransactionDiscount(params);
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const removeOrderItem = async (orderId: string, quantity: number) => {
    try {
      await api.pos.removeOrderItem(orderId, quantity);

      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const cancelTable = async (
    tableId: string,
    cancellation_description: string,
    is_excluded_report: Number,
  ) => {
    try {
      await api.pos.cancelTable(
        tableId,
        cancellation_description,
        is_excluded_report,
      );
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const removeOrderDiscount = async (transactionId: string) => {
    try {
      await api.pos.removeOrderDiscount(transactionId);
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const addOrderTransactionPayment = async (params: Payment) => {
    try {
      await api.pos.orderTransactionPayments(params);
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const setOrderGift = async (order_id: string, count: number) => {
    try {
      await api.pos.setOrderGift(order_id, count);

      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const removeOrderGift = async (order_id: string, quantity: number) => {
    try {
      await api.pos.removeOrderGift(order_id, quantity);
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const removeOrderTransactionPayment = async (id: string) => {
    try {
      await api.pos.orderTransactionPaymentRemove(id);
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  const getInactiveOrderTransactionDetails = async (
    tableId: string,
    day_id: string,
  ) => {
    try {
      setOrderDetailsLoading(true);

      const result = await api.pos.getInactiveOrderTransactions(
        tableId,
        day_id,
      );
      setInActiveOrderDetails(result.data.data);
    } catch (err) {
      setOrderDetailsError(err as Error);
    } finally {
      setOrderDetailsLoading(false);
    }
  };
  const getOpenAccountTransactionsDetails = async (
    customer_id: string,
    page: number,
  ) => {
    try {
      setCustomerDetailsLoading(true);

      const result = await api.pos.getOpenAccountTransactions(
        customer_id,
        page,
      );
      setOpenAccountDetails(result.data.data);
      setOpenAccountMeta(result.data.meta);
    } catch (err) {
      setOrderDetailsError(err as Error);
    } finally {
      setCustomerDetailsLoading(false);
    }
  };

  const changeOrderTransactionActive = async (orderTransactionId: string) => {
    try {
      await api.pos.setOrderActive(orderTransactionId);
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    }
  };

  return {
    submitting,
    createTableOrder,
    getOrderDetails,
    getOrderTransaction,
    inActiveOrderDetails,
    openAccountDetails,
    openAccountMeta,
    orderDetails,
    orderDetailsLoading,
    customerDetailsLoading,
    orderDetailsError,
    extraParamsId,
    changeTable,
    moveTableOrder,
    splitTable,
    mergeTables,
    addSpecialOrder,
    addOrderTransactionDiscount,
    removeOrderItem,
    removeOrderDiscount,
    cancelTable,
    addOrderTransactionPayment,
    removeOrderTransactionPayment,
    changeGuestCount,
    changeDescription,
    changeTableName,
    changeOrderCustomer,
    changeOrderTransactionActive,
    getInactiveOrderTransactionDetails,
    setDeliverer,
    getOpenAccountTransactionsDetails,
    setOrderGift,
    removeOrderGift,
    changeServiceCharge,
  };
};
export default usePosSearch;
