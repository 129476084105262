const styles = {
  container: {
    mb: 2,
  },
  textField: {
    borderRightRadius: null,
  },
  selectField: {
    minWidth: 100,
    borderLeftRadius: null,
  },
};

export default styles;
