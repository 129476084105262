import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type ProductIngredientFormValues = {
  groupIds: string;
};

const schema = yup.object({
  groupIds: yup.string(),
});

export const validationSchema = yupResolver(schema);

export const defaultValues = (): ProductIngredientFormValues => ({
  groupIds: '',
});
