/** Dependencies */
import React, { useCallback, useEffect } from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  AccordionSummaryProps,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

/** Styles */
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import styles from './styles';

/** helpers */
import getActiveLanguage from '../../../../../../helpers/getActiveLanguage';

/** Components */
import Modal from '../../../../../../components/Modal';
import Button from '../../../../../../components/Button';

/** Types */
import { PropertyName, Unit } from '../../../../../../models/Product';
import { IngredientGroup, Item, PropertyGroup } from '../../types';
import { ActiveBasketProps } from '../../LeftColumn/BasketList/types';
import { NameTranslation } from '../../../../../../models/General';

interface Props {
  name: string;
  open: boolean;
  onClose: () => void;
  onSelection: () => void;
  units: Unit[];
  unit: Unit | null;
  productInProcessDetails: any;
  singlePropertyId: string;
  multiplePropertyId: string[];
  propertyNames: PropertyName[];
  singlePropertyName: PropertyName;
  nonIngredientId: string[];
  requiredSelection: boolean;
  onDelete: () => void;
  activeBasketProps: ActiveBasketProps;
  setActiveBasketProps: React.Dispatch<React.SetStateAction<ActiveBasketProps>>;
}

function OnSelectionModal({
  name,
  open,
  onClose,
  onSelection,
  units,
  unit,
  productInProcessDetails,
  singlePropertyId,
  multiplePropertyId,
  propertyNames,
  singlePropertyName,
  nonIngredientId,
  requiredSelection,
  onDelete,
  activeBasketProps,
  setActiveBasketProps,
}: Props) {
  const { t } = useTranslation('pos');
  const { enqueueSnackbar } = useSnackbar();

  const MuiAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={styles.accordionIcon} />}
      {...props}
    />
  ))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
    '& .MuiAccordionSummary': {
      marginLeft: theme.spacing(1),
    },
  }));

  useEffect(() => {
    if (open) {
      setActiveBasketProps({
        activeIngredientIds: nonIngredientId || [],
        activeMultiplePropertyIds: multiplePropertyId || [],
        activeSinglePropertyId: singlePropertyId || '',
        activeSinglePropertyName: {
          name: singlePropertyName?.name || '',
          price: singlePropertyName?.price || 0,
        },
        activeMultiplePropertyNames: propertyNames || [],
        activeUnitId: unit?.id || '',
      });
    }
  }, [open]);

  const propertyButtonStyle = useCallback(
    (isMultiple: boolean, itemId: string) => {
      if (isMultiple) {
        return activeBasketProps.activeMultiplePropertyIds.find(
          activeMultipleId => activeMultipleId === itemId,
        )
          ? [styles.button, styles.checked]
          : styles.button;
      }
      return activeBasketProps.activeSinglePropertyId === itemId
        ? [styles.button, styles.checked]
        : styles.button;
    },
    [activeBasketProps],
  );

  const propertyButtonClick = useCallback(
    (isMultiple: boolean, itemId: string, item: Item) => {
      if (isMultiple) {
        if (
          activeBasketProps.activeMultiplePropertyIds.find(
            activeItemId => activeItemId === itemId,
          )
        ) {
          setActiveBasketProps({
            ...activeBasketProps,
            activeMultiplePropertyIds:
              activeBasketProps.activeMultiplePropertyIds.filter(
                filteredId => filteredId !== itemId,
              ),
            activeMultiplePropertyNames:
              activeBasketProps.activeMultiplePropertyNames.filter(
                filteredProp =>
                  filteredProp.name !==
                  `${getActiveLanguage(item.translations).name} ${
                    item.additional_price.formatted !== '₺0,00'
                      ? `+${item.additional_price.formatted}`
                      : ''
                  }`,
              ),
          });
        } else {
          setActiveBasketProps({
            ...activeBasketProps,
            activeMultiplePropertyIds: [
              ...activeBasketProps.activeMultiplePropertyIds,
              itemId,
            ],
            activeMultiplePropertyNames: [
              ...activeBasketProps.activeMultiplePropertyNames,
              {
                name: `${getActiveLanguage(item.translations).name} ${
                  item.additional_price.formatted !== '₺0,00'
                    ? `+${item.additional_price.formatted}`
                    : ''
                }`,
                price: Number(item.additional_price.amount) / 100,
              },
            ],
          });
        }
      } else if (
        activeBasketProps.activeSinglePropertyName?.name ===
        `${getActiveLanguage(item.translations).name} ${
          item.additional_price.formatted !== '₺0,00'
            ? `+${item.additional_price.formatted}`
            : ''
        }`
      ) {
        setActiveBasketProps({
          ...activeBasketProps,
          activeSinglePropertyName: {
            name: '',
            price: 0,
          },
          activeSinglePropertyId: '',
        });
      } else {
        setActiveBasketProps({
          ...activeBasketProps,
          activeSinglePropertyName: {
            name: `${getActiveLanguage(item.translations).name} ${
              item.additional_price.formatted !== '₺0,00'
                ? `+${item.additional_price.formatted}`
                : ''
            }`,
            price: Number(item.additional_price.amount) / 100,
          },
          activeSinglePropertyId: itemId,
        });
      }
    },
    [activeBasketProps],
  );

  const ingredientButtonStyle = useCallback(
    (itemId: string) =>
      activeBasketProps.activeIngredientIds.find(
        activeMultipleId => activeMultipleId === itemId,
      )
        ? [styles.button, styles.nonIngredient]
        : styles.button,
    [activeBasketProps.activeIngredientIds],
  );
  const ingredientButtonClick = useCallback(
    (itemId: string) =>
      activeBasketProps.activeIngredientIds.find(
        activeItemId => activeItemId === itemId,
      )
        ? setActiveBasketProps({
            ...activeBasketProps,
            activeIngredientIds: activeBasketProps.activeIngredientIds.filter(
              filteredId => filteredId !== itemId,
            ),
          })
        : setActiveBasketProps({
            ...activeBasketProps,
            activeIngredientIds: [
              ...activeBasketProps.activeIngredientIds,
              itemId,
            ],
          }),
    [activeBasketProps],
  );

  const requiredPropertyMessage = () => {
    enqueueSnackbar(t('selectionModal.property_selection_required'), {
      variant: 'warning',
    });
  };

  const isActiveProperties =
    productInProcessDetails && productInProcessDetails.property_groups.length
      ? productInProcessDetails.property_groups.some(
          (item: any) =>
            item.items.filter((propItem: any) => propItem.is_active).length,
        )
      : false;
  const isActiveIngredients =
    productInProcessDetails && productInProcessDetails.ingredient_groups.length
      ? productInProcessDetails.ingredient_groups.some(
          (item: any) =>
            item.items.filter((ingredientItem: any) => ingredientItem.is_active)
              .length,
        )
      : false;

  const handleNegativeClick = () => {
    if (requiredSelection) {
      onDelete();
    }
    onClose();
    setActiveBasketProps({
      ...activeBasketProps,
      activeUnitId: '',
    });
  };

  const handlePositiveClick = () => {
    if (
      requiredSelection &&
      isActiveProperties &&
      activeBasketProps.activeSinglePropertyId === '' &&
      activeBasketProps.activeMultiplePropertyIds.length === 0
    ) {
      requiredPropertyMessage();
    } else {
      onClose();
    }
    onSelection();
  };

  return (
    <Modal
      maxWidth="md"
      open={open}
      // @ts-ignore
      onClose={(_: any, reason: string) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      title={name}
      subtitle={t('selectionModal.subtitle')}
      positiveAction={t('selectionModal.buttons.confirm')}
      negativeAction={t('selectionModal.buttons.cancel')}
      onPositiveClick={handlePositiveClick}
      onNegativeClick={handleNegativeClick}
      disableEscapeKeyDown
    >
      <div>
        {units.length > 1 && (
          <Accordion defaultExpanded elevation={0}>
            <MuiAccordionSummary>
              <Box sx={styles.onSelectionDisplay}>
                {t('selectionModal.portions')}
              </Box>
            </MuiAccordionSummary>
            <AccordionDetails>
              {units.map(unitItem => (
                <Button
                  sx={
                    activeBasketProps.activeUnitId === unitItem.id
                      ? [styles.button, styles.checked]
                      : styles.button
                  }
                  variant="outlined"
                  // todo: buralar değiştirilecek
                  onClick={() =>
                    setActiveBasketProps({
                      ...activeBasketProps,
                      activeUnitId: unitItem.id,
                    })
                  }
                >
                  {getActiveLanguage(unitItem.translations).name}{' '}
                  {unitItem.amount.formatted}
                </Button>
              ))}
            </AccordionDetails>
          </Accordion>
        )}
      </div>

      {isActiveProperties > 0 && isActiveIngredients > 0 && (
        <hr color="lightgrey" />
      )}
      {isActiveProperties > 0 && (
        <div>
          <Accordion defaultExpanded elevation={0}>
            <MuiAccordionSummary sx={styles.onSelectionMinHeight}>
              <Box sx={styles.onSelectionDisplay}>
                {t('selectionModal.properties')}
              </Box>
            </MuiAccordionSummary>
            {productInProcessDetails?.property_groups?.map(
              (product: PropertyGroup) =>
                product.items.find(item => item.is_active) && (
                  <AccordionDetails sx={styles.accordionPadding}>
                    <Accordion defaultExpanded elevation={0}>
                      <MuiAccordionSummary sx={styles.onSelectionMinHeight}>
                        <Box
                          sx={{
                            ...styles.onSelectionDisplay,
                            borderBottom: '1px solid black',
                          }}
                        >
                          {getActiveLanguage(product.translations).name}{' '}
                          <Typography sx={styles.onSelectionTypography}>
                            {product.is_multiple
                              ? ` (${t('selectionModal.multi_select')})`
                              : ` (${t('selectionModal.single_select')})`}
                          </Typography>
                        </Box>
                      </MuiAccordionSummary>
                      <AccordionDetails sx={styles.accordionPadding}>
                        {product?.items?.map(
                          (item: Item) =>
                            item?.is_active && (
                              <Button
                                sx={propertyButtonStyle(
                                  product.is_multiple,
                                  item.id,
                                )}
                                onClick={() =>
                                  propertyButtonClick(
                                    product.is_multiple,
                                    item.id,
                                    item,
                                  )
                                }
                                variant="outlined"
                              >
                                {getActiveLanguage(item.translations).name}
                                {item.additional_price.formatted !==
                                  '₺0,00' && (
                                  <Typography
                                    sx={{
                                      fontSize: '10px',
                                      marginLeft: '7px',
                                      color: '#ff0000c1',
                                    }}
                                  >
                                    {`+${item.additional_price.formatted}`}
                                  </Typography>
                                )}
                              </Button>
                            ),
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </AccordionDetails>
                ),
            )}
          </Accordion>
        </div>
      )}
      {isActiveIngredients > 0 && (
        <div>
          <Accordion defaultExpanded elevation={0}>
            <MuiAccordionSummary sx={styles.onSelectionMinHeight}>
              <Box sx={styles.onSelectionDisplay}>
                {t('selectionModal.ingredients')}
              </Box>
            </MuiAccordionSummary>
            {productInProcessDetails?.ingredient_groups?.map(
              (product: IngredientGroup) =>
                product.items.find(item => item.is_active) && (
                  <AccordionDetails sx={styles.accordionPadding}>
                    <Accordion defaultExpanded elevation={0}>
                      <MuiAccordionSummary sx={styles.onSelectionMinHeight}>
                        <Box
                          sx={{
                            ...styles.onSelectionDisplay,
                            borderBottom: '1px solid black',
                          }}
                        >
                          {getActiveLanguage(product.translations).name}
                        </Box>
                      </MuiAccordionSummary>
                      <AccordionDetails sx={styles.accordionPadding}>
                        {product?.items?.map(
                          (item: {
                            translations: NameTranslation[];
                            is_active: boolean;
                            id: string;
                          }) =>
                            item?.is_active && (
                              <Button
                                sx={ingredientButtonStyle(item.id)}
                                variant="outlined"
                                onClick={() => ingredientButtonClick(item.id)}
                              >
                                {getActiveLanguage(item.translations).name}
                              </Button>
                            ),
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </AccordionDetails>
                ),
            )}
          </Accordion>
        </div>
      )}
    </Modal>
  );
}

export default OnSelectionModal;
