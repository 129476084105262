import { Box, Collapse, List, Stack, Divider } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import MenuItemComponent from './MenuItemComp';
import items from './items';
import { DRAWER_WIDTH } from './SideBar';
import useProfile from '../../hooks/useProfile';
import arrayKeyHasPermission from '../../helpers/arrayKeyHasPermission';
import { MenuItem } from './types';
import { IconName } from '../Icon';

interface Props {
  onDrawerToggle: () => void;
  onLogout: () => void;
  drawerExpanded: boolean;
  onLinkPress: (link: string) => void;
  token: string | null;
}
function SideBarList({
  onDrawerToggle,
  onLogout,
  drawerExpanded,
  onLinkPress,
  token,
}: Props) {
  const { t } = useTranslation('layout');
  const { pathname } = useLocation();
  const { meProfile } = useProfile();
  const [expandedItems, setExpandedItems] = useState<string>('');
  const [expandedItemsChild, setExpandedItemsChild] = useState<string>('');
  const [appsItems, setAppsItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    if (meProfile?.installed_apps?.length) {
      const apps = meProfile?.installed_apps;

      const mappedApps: MenuItem[] = apps
        .filter(item => item.is_shown_on_sidebar)
        .sort(item => item.sequence)
        .map(item => ({
          key: item.slug,
          permissionKey: [],
          icon: (item.icon as IconName) || 'ShoppingBasket',
          link: item.redirect_url,
          name: item.name,
        }));

      setAppsItems([...items, ...mappedApps]);
    } else {
      setAppsItems([...items]);
    }
  }, [meProfile]);

  return (
    <Stack justifyContent="space-between" sx={{ height: '100%' }}>
      <Box sx={{ width: DRAWER_WIDTH }} role="presentation">
        <List sx={{ pt: 0 }}>
          {/* eslint-disable-next-line array-callback-return */}
          {appsItems.map(
            ({ key, permissionKey, icon, link, children, name }) => {
              if (
                arrayKeyHasPermission(
                  permissionKey || [],
                  meProfile?.permissions || [],
                )
              ) {
                if (children) {
                  return (
                    <Fragment key={key}>
                      <MenuItemComponent
                        title={t(`sidebar.${key}` as any)}
                        iconName={icon}
                        popUpDisabled={drawerExpanded}
                        rightIconName={
                          expandedItems === key ? 'ExpandLess' : 'ExpandMore'
                        }
                        onClick={() => {
                          if (!drawerExpanded) onDrawerToggle();
                          setExpandedItems(expandedItems === key ? '' : key);
                        }}
                        disabled={Boolean(token)}
                      />
                      <Collapse
                        in={drawerExpanded && expandedItems === key}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {children.map(child => {
                            if (
                              arrayKeyHasPermission(
                                child.permissionKey || [],
                                meProfile?.permissions || [],
                              )
                            ) {
                              if (child.children) {
                                return (
                                  <Fragment key={child.key}>
                                    <MenuItemComponent
                                      sx={{ pl: 4 }}
                                      title={t(`sidebar.${child.key}` as any)}
                                      iconName={child.icon}
                                      popUpDisabled
                                      rightIconName={
                                        expandedItemsChild === child.key
                                          ? 'ExpandLess'
                                          : 'ExpandMore'
                                      }
                                      onClick={() => {
                                        if (!drawerExpanded) onDrawerToggle();
                                        setExpandedItemsChild(
                                          expandedItemsChild === child.key
                                            ? ''
                                            : child.key,
                                        );
                                      }}
                                      disabled={Boolean(token)}
                                    />
                                    <Collapse
                                      in={expandedItemsChild === child.key}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <List component="div" disablePadding>
                                        {child.children.map(child2 => (
                                          <MenuItemComponent
                                            sx={{ pl: 6 }}
                                            key={child2.key}
                                            title={t(
                                              `sidebar.${child2.key}` as any,
                                            )}
                                            iconName={child2.icon}
                                            onClick={() => {
                                              if (child2.link)
                                                onLinkPress(child2.link);
                                              onDrawerToggle();
                                            }}
                                            selected={pathname === child2.link}
                                            popUpDisabled
                                            disabled={Boolean(token)}
                                          />
                                        ))}
                                      </List>
                                    </Collapse>
                                  </Fragment>
                                );
                              }
                              return (
                                <MenuItemComponent
                                  sx={{ pl: 4 }}
                                  key={child.key}
                                  title={t(`sidebar.${child.key}` as any)}
                                  iconName={child.icon}
                                  onClick={() => {
                                    if (child.link) onLinkPress(child.link);
                                    onDrawerToggle();
                                  }}
                                  selected={pathname === child.link}
                                  popUpDisabled
                                  disabled={Boolean(token)}
                                />
                              );
                            }
                            return null;
                          })}
                        </List>
                      </Collapse>
                    </Fragment>
                  );
                }
                // non nested items
                return (
                  <Fragment key={key}>
                    {key === 'pos' && (
                      <Box sx={{ my: 2 }}>
                        <Divider />
                      </Box>
                    )}
                    <MenuItemComponent
                      title={name || t(`sidebar.${key}` as any)}
                      iconName={icon}
                      onClick={() => {
                        if (link) onLinkPress(link);
                        if (drawerExpanded) onDrawerToggle();
                      }}
                      selected={pathname === link}
                      popUpDisabled={drawerExpanded}
                      disabled={Boolean(token)}
                    />
                    {key === 'dashboard' && (
                      <Box sx={{ my: 2 }}>
                        <Divider />
                      </Box>
                    )}
                  </Fragment>
                );
              }
              return null;
            },
          )}
        </List>
      </Box>
    </Stack>
  );
}

export default SideBarList;
