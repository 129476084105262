/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Menu, MenuItem } from '@mui/material';

/** Components */
import Calculator from '../../../../../../../components/Calculator';
import Button from '../../../../../../../components/Button';

/** Styles */
import styles from '../../styles';

interface Props {
  allPrice: number;
  value: string;
  setValue: (value: ((prevState: string) => string) | string) => void;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
  open: boolean;
  handleOneOverN: (num: number) => void;
  anchorEl: HTMLElement | null;
  setDivided: (value: ((prevState: any[]) => any[]) | any[]) => void;
  clearList: () => void;
}

function PaymentNumpad({
  allPrice,
  value,
  setValue,
  handleClick,
  open,
  handleClose,
  handleOneOverN,
  anchorEl,
  setDivided,
  clearList,
}: Props) {
  const { t } = useTranslation('pos');
  const nums = [1, 2, 3, 4, 5, 6];

  return (
    <Grid container justifyContent="space-between">
      <Grid container item justifyContent="flex-end" xs={9}>
        <Calculator
          value={value}
          setValue={setValue}
          totalPrice={allPrice.toString()}
          setDivided={setDivided}
          clearList={clearList}
        />
      </Grid>
      <Grid container item xs={3}>
        <Grid item xs={12}>
          <Button
            color="inherit"
            fullWidth
            sx={styles.calculatorButton}
            onClick={() => {
              setValue(allPrice.toString());
              setDivided([]);
              clearList();
            }}
          >
            {t('modalMidSide.all')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="inherit"
            fullWidth
            sx={styles.calculatorButton}
            onClick={handleClick}
          >
            1/n
          </Button>
          <Menu
            anchorEl={anchorEl}
            id="1/n-menu"
            open={open}
            PaperProps={{
              elevation: 3,
              sx: {
                overflow: 'visible',
                width: 200,
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              },
            }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            onClose={handleClose}
            onClick={handleClose}
          >
            {nums.map(num => (
              <MenuItem onClick={() => handleOneOverN(num)}>{num}</MenuItem>
            ))}
          </Menu>
        </Grid>
        <Grid item xs={12}>
          <Button color="inherit" fullWidth sx={styles.calculatorButton}>
            {' '}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button color="inherit" fullWidth sx={styles.calculatorButton}>
            {' '}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PaymentNumpad;
