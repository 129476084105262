/** Dependencies */
import React, { useEffect, useState } from 'react';
import { Button, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

/** Components */
import Modal from '../../../../components/Modal';

/** Hooks */
import styles from '../styles';
import Icon from '../../../../components/Icon';
import DelivererModal from '../modals/DelivererModal';

interface Props {
  open: boolean;
  onClose: () => void;
  tableId: string;
  type: string;
  handleSetStatus: (
    table_id: string,
    status_id: number,
    isPickedUp?: boolean,
  ) => void;
  typeId: number;
  isFullPaid: boolean;
}

function UpdateStatusModal({
  open,
  onClose,
  tableId,
  type,
  handleSetStatus,
  typeId,
  isFullPaid,
}: Props) {
  const { t } = useTranslation('orders');

  const handleClose = () => {
    onClose();
  };

  const [delivererModal, setDelivererModal] = useState(false);

  useEffect(() => {
    // if (open) {
    // }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      title={t('order.settings.update_status')}
      headerButtonIconName="Close"
      headerButton
      onHeaderButtonClick={onClose}
      headerButtonText={t('order.settings.close')}
      headerButtonIconNameErrorColor="error"
    >
      <div>
        <Stack
          sx={{ flex: 1, '&:focus': { outline: 'none' }, outline: 'none' }}
          direction={{ md: 'row', xs: 'column' }}
          spacing={3}
        >
          <Grid container item>
            <Grid
              sx={{ mt: 3 }}
              container
              item
              justifyContent="flex-start"
              xs={12}
            >
              {type !== 'placed' && (
                <Grid sx={{ p: 1 }} item xs={6}>
                  <Button
                    color="inherit"
                    fullWidth
                    sx={styles.paymentTypeButton}
                    onClick={() => {
                      handleSetStatus(tableId, 1);
                      handleClose();
                    }}
                  >
                    <Grid justifyContent="center">
                      <Grid mr={1} justifyContent="center">
                        <Icon
                          fontSize="large"
                          sx={{ color: '#fbc02d' }}
                          name="ContactPhone"
                        />
                      </Grid>
                      <Grid>{t('order.placed')}</Grid>
                    </Grid>
                  </Button>
                </Grid>
              )}
              {type !== 'preparing' && (
                <Grid sx={{ p: 1 }} item xs={6}>
                  <Button
                    color="inherit"
                    fullWidth
                    sx={styles.paymentTypeButton}
                    onClick={() => {
                      handleSetStatus(tableId, 2);
                      handleClose();
                    }}
                  >
                    <Grid justifyContent="center">
                      <Grid mr={1} justifyContent="center">
                        <Icon
                          fontSize="large"
                          sx={{ color: '#43a047' }}
                          name="AccessTime"
                        />
                      </Grid>
                      <Grid>{t('order.preparing')}</Grid>
                    </Grid>
                  </Button>
                </Grid>
              )}
              {type !== 'ready' && (
                <Grid sx={{ p: 1 }} item xs={6}>
                  <Button
                    color="inherit"
                    fullWidth
                    sx={styles.paymentTypeButton}
                    onClick={() => {
                      handleSetStatus(tableId, 3);
                      handleClose();
                    }}
                  >
                    <Grid justifyContent="center">
                      <Grid mr={1} justifyContent="center">
                        <Icon
                          fontSize="large"
                          sx={{ color: '#0288d1' }}
                          name="CheckCircleOutline"
                        />
                      </Grid>
                      <Grid>{t('order.ready')}</Grid>
                    </Grid>
                  </Button>
                </Grid>
              )}
              {type !== 'picked_up' && typeId === 3 && (
                <Grid sx={{ p: 1 }} item xs={6}>
                  <Button
                    color="inherit"
                    fullWidth
                    sx={styles.paymentTypeButton}
                    onClick={() => {
                      setDelivererModal(true);
                    }}
                  >
                    <Grid justifyContent="center">
                      <Grid mr={1} justifyContent="center">
                        <Icon
                          fontSize="large"
                          sx={{ color: '#0288d1' }}
                          name="TwoWheeler"
                        />
                      </Grid>
                      <Grid>{t('order.picked_up')}</Grid>
                    </Grid>
                  </Button>
                </Grid>
              )}
              {type !== 'completed' && isFullPaid && (
                <Grid sx={{ p: 1 }} item xs={6}>
                  <Button
                    color="inherit"
                    fullWidth
                    sx={styles.paymentTypeButton}
                    onClick={() => {
                      handleSetStatus(tableId, 16);
                      handleClose();
                    }}
                  >
                    <Grid justifyContent="center">
                      <Grid mr={1} justifyContent="center">
                        <Icon
                          fontSize="large"
                          sx={{ color: '#303f9f' }}
                          name="RoomService"
                        />
                      </Grid>
                      <Grid>{t('order.completed')}</Grid>
                    </Grid>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Stack>
      </div>
      <DelivererModal
        open={delivererModal}
        onClose={() => {
          setDelivererModal(false);
          handleClose();
        }}
        orderId={tableId}
        handleSetStatus={handleSetStatus}
      />
    </Modal>
  );
}

export default UpdateStatusModal;
