import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialState, Language } from './types';

const initialState: InitialState = {
  list: [
    {
      id: 1,
      name: 'Türkçe',
      slug: 'tr',
    },
    {
      id: 2,
      name: 'English',
      slug: 'en',
    },
  ],
};

const languageSlice = createSlice({
  name: 'languageSlice',
  initialState,
  reducers: {
    getLanguages: (_, action: PayloadAction<Language[]>) => ({
      list: action.payload,
    }),
  },
});

export const { getLanguages } = languageSlice.actions;

export default languageSlice.reducer;
