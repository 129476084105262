import { Box, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import IconButtonWithTooltip from '../IconButtonWithTooltip';
import useProfile from '../../hooks/useProfile';
import keyHasPermission from '../../helpers/keyHasPermission';

interface Props {
  name: string;
  price?: string;
  onClick?: () => void;
  onDelete?: () => void;
  active?: boolean;
  selected?: boolean;
  defaultUnit?: {
    name: string;
    price: string;
  };
}
function ProductCard({
  name,
  price,
  active = true,
  selected = false,
  onClick,
  onDelete,
  defaultUnit,
}: Props) {
  const { meProfile } = useProfile();

  const hasRemovePermission = keyHasPermission(
    'delete-product',
    meProfile?.permissions || [],
  );

  const hasUpdatePermission = keyHasPermission(
    'update-product',
    meProfile?.permissions || [],
  );

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: active
          ? selected
            ? 'warning.light'
            : 'primary.light'
          : 'neutral.light',
        borderRadius: 2,
        position: 'relative',
        cursor: hasUpdatePermission ? 'pointer' : 'default',
        height: 140,
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 2,
        textAlign: 'center',
      }}
      onClick={onClick}
    >
      {onDelete && hasRemovePermission && (
        <Box sx={{ position: 'absolute', top: 6, right: 6 }}>
          <IconButtonWithTooltip
            iconName="Delete"
            color="error"
            onClick={e => {
              e.stopPropagation();
              onDelete();
            }}
          />
        </Box>
      )}

      <Stack
        sx={{ height: '100%' }}
        justifyContent="space-between"
        divider={<Divider />}
      >
        <Box sx={{ m: 'auto' }}>
          <Typography
            fontWeight={500}
            sx={{
              color: active ? 'info.main' : 'neutral.dark',
              textTransform: 'capitalize',
              mt: 3,
            }}
          >
            {name}
          </Typography>
          <Typography
            fontWeight={500}
            sx={{
              color: active ? 'info.main' : 'neutral.dark',
              textTransform: 'capitalize',
              mt: 3,
            }}
          >
            {price}
          </Typography>
        </Box>

        {defaultUnit ? (
          <Stack
            direction="row"
            sx={{ width: '100%' }}
            justifyContent="space-evenly"
            divider={<Divider orientation="vertical" />}
          >
            <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
              {defaultUnit.name}
            </Typography>
            <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
              {defaultUnit.price}
            </Typography>
          </Stack>
        ) : null}
      </Stack>
    </Box>
  );
}

export default ProductCard;
