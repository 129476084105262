import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isApiError } from '../../services/api/types';
import { useAppDispatch, useAppSelector } from '../../store';
import { getIngredientGroupsListThunk } from '../../store/slices/definitionsSlice/ingredientGroups';
import errorReporting from '../../helpers/errorReporting';
import api from '../../services/api';
import {
  addIngredientGroupsSlice,
  addIngredientItemSlice,
  removeIngredientGroupsSlice,
  removeIngredientItemSlice,
  updateIngredientGroupsSlice,
  updateIngredientItemSlice,
} from '../../store/slices/definitionsSlice/ingredientGroups/ingredientGroupsSlice';

const useIngredientGroups = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const ingredientGroups = useAppSelector(
    state => state.definitionsIngredientGroups.ingredientGroups,
  );
  const ingredientGroupsLoading = useAppSelector(
    state => state.definitionsIngredientGroups.loading,
  );

  const ingredientGroupsError = useAppSelector(
    state => state.definitionsIngredientGroups.error,
  );

  const fetchIngredientGroups = () => dispatch(getIngredientGroupsListThunk());

  const addIngredientGroups = async (name: { [key: string]: string }) => {
    try {
      setLoading(true);
      const response = await api.definitions.ingredientGroups.create(name);
      const result = response.data.data;
      dispatch(addIngredientGroupsSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const updateIngredientGroups = async ({
    id,
    name,
  }: {
    id: string;
    name: { [key: string]: string };
  }) => {
    try {
      setLoading(true);
      const response = await api.definitions.ingredientGroups.update({
        id,
        name,
      });
      const result = response.data.data;
      dispatch(updateIngredientGroupsSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const removeIngredientGroups = async (id: string) => {
    try {
      setDeleting(true);
      const response = await api.definitions.ingredientGroups.remove(id);
      const result = response.data.data;
      dispatch(removeIngredientGroupsSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err;
    } finally {
      setDeleting(false);
    }
  };

  const addIngredientItem = async (
    groupId: string,
    name: { [key: string]: string },
  ) => {
    try {
      setLoading(true);
      const response = await api.definitions.ingredientGroups.itemCreate({
        groupId,
        name,
      });
      const result = response.data.data;
      dispatch(addIngredientItemSlice({ ...result, groupId }));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const updateIngredientItem = async ({
    groupId,
    id,
    name,
  }: {
    groupId: string;
    id: string;
    name: { [key: string]: string };
  }) => {
    try {
      setLoading(true);
      const response = await api.definitions.ingredientGroups.itemUpdate({
        id,
        groupId,
        name,
      });
      const result = response.data.data;
      dispatch(updateIngredientItemSlice({ ...result, groupId }));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const removeIngredientItem = async (groupId: string, id: string) => {
    try {
      setDeleting(true);
      const response = await api.definitions.ingredientGroups.itemRemove(
        groupId,
        id,
      );
      const result = response.data.data;
      dispatch(removeIngredientItemSlice({ ...result, groupId }));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err;
    } finally {
      setDeleting(false);
    }
  };

  const ingredientItems = (groupId: string | undefined) => {
    if (groupId) {
      return ingredientGroups.find(group => group.id === groupId)?.items || [];
    }
    return [];
  };

  return {
    ingredientGroups,
    ingredientItems,
    ingredientGroupsLoading,
    ingredientGroupsError,
    fetchIngredientGroups,
    addIngredientGroups,
    updateIngredientGroups,
    removeIngredientGroups,
    addIngredientItem,
    updateIngredientItem,
    removeIngredientItem,
    deleting,
    loading,
  };
};
export default useIngredientGroups;
