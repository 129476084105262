import api from '../../api';

const getDailyReport = async (dayId: string) =>
  api.get(`/reports/${dayId}/daily-report`);

const getMostPopularProductsOfDay = async (
  day_id?: string,
  page?: number,
  sort?: string,
  start_date?: string | null,
  end_date?: string | null,
  per_page?: number,
) =>
  api.get(`/reports/most-popular-products-of-day/`, {
    params: {
      start_date,
      end_date,
      page,
      day_id,
      sort,
      per_page,
    },
  });

const getDashboardReports = async () =>
  api.get('/reports/restaurant-dashboard');

const getPaymentTypeReports = async (
  start_date?: string | null,
  end_date?: string | null,
  day_id?: string | null,
) =>
  api.get(`/reports/payment-type-report`, {
    params: {
      start_date,
      end_date,
      day_id,
    },
  });

const getOperationLogs = async (
  day_id?: string | null,
  type?: number | null,
  page?: number,
  per_page?: number,
) =>
  api.get('reports/operation-logs', {
    params: {
      per_page,
      page,
      filter: {
        day_id,
        type,
      },
    },
  });

const resetAccount = async () => api.get('reset-demo');

export default {
  getDailyReport,
  getMostPopularProductsOfDay,
  getDashboardReports,
  getPaymentTypeReports,
  getOperationLogs,
  resetAccount,
};
