import sections from './sections';
import tables from './tables';
import categories from './categories';
import units from './units';
import kitchenGroups from './kitchenGroups';
import propertyGroups from './propertyGroups';
import ingredientGroups from './ingredientGroups';
import products from './products';
import customers from './customers';
import customerAddresses from './customerAdresses';
import callerIdRecords from './callerIdRecords';

export default {
  sections,
  tables,
  categories,
  units,
  kitchenGroups,
  propertyGroups,
  ingredientGroups,
  products,
  customers,
  customerAddresses,
  callerIdRecords,
};
