/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import { Box } from '@mui/material';

/** Hooks */
import useCustomers from '../../../hooks/useCustomers';

/** Components */
import UtilitiesBar, {
  Props as BarProps,
} from '../../../components/UtilitiesBar';
import CustomersTable from './CustomersTable';
import LoadingState from '../../../components/LoadingState';
import ErrorState from '../../../components/ErrorState';
import SearchBar from '../../../components/SearchBar';

/** Types */
import { CustomerBase } from './types';

/** Modals */
import CustomerAddModal from './Modals/CustomerAddModal';
import useProfile from '../../../hooks/useProfile';
import arrayKeyHasPermission from '../../../helpers/arrayKeyHasPermission';

const MENU: BarProps['menu'] = [
  {
    id: 0,
    text: 'actions.addCustomer',
    startIconName: 'Add',
    permissionKeys: ['create-customer'],
  },
];

function Customers() {
  const { t } = useTranslation('customer');
  const { meProfile } = useProfile();

  const {
    meta,
    customers,
    customerError,
    customerLoading,
    removeCustomer,
    fetchCustomers,
  } = useCustomers();

  const [page, setPage] = useState(1);
  const [customerAddModal, setCustomerAddModal] = useState(false);
  const [customerToEdit, setCustomerToEdit] = useState<CustomerBase | null>(
    null,
  );
  const [searchValue, setSearchValue] = useState<{
    inputValue: string;
    isActive: boolean | null;
  }>({
    inputValue: '',
    isActive: null,
  });
  const [debouncedSearch, setDebouncedSearch] = useState({
    inputValue: '',
    isActive: true,
  });

  const debouncedAndFilteredSearch = async () => {
    try {
      await fetchCustomers({
        search: searchValue.inputValue,
        is_active: searchValue.isActive,
        first_name: 'first_name',
        page: 1,
      });
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  useEffect(() => {
    debouncedAndFilteredSearch();
  }, [debouncedSearch.inputValue]);

  if (!customers && customerError) {
    return (
      <ErrorState
        message={
          customerError
            ? customerError.message
            : t('common:unknownError' as any)
        }
      />
    );
  }

  const handleAction = (id: number) => {
    switch (id) {
      case 0:
        setCustomerAddModal(true);
        break;
      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('title')}
        // @ts-ignore
        subtitle={`${t('subtitle')}: ${meta?.total || 0}`}
        leftIconName="Customer"
        // @ts-ignore
        menu={MENU!
          .filter(item =>
            arrayKeyHasPermission(
              item?.permissionKeys || [],
              meProfile?.permissions || [],
            ),
          )
          .map(filteredItem => ({
            ...filteredItem,
            text: t(filteredItem.text as any),
          }))}
        onActionClick={handleAction}
      />
      <SearchBar
        placeholder={t('searchbar.placeholder')}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setDebouncedSearch={setDebouncedSearch}
      />
      {customerLoading ? (
        <LoadingState />
      ) : (
        <CustomersTable
          onItemEdit={item => {
            setCustomerToEdit(item);
            setCustomerAddModal(true);
          }}
          onItemRemove={removeCustomer}
          page={page}
          setPage={setPage}
        />
      )}
      <CustomerAddModal
        open={customerAddModal}
        item={customerToEdit}
        onClose={() => {
          setCustomerToEdit(null);
          setCustomerAddModal(false);
        }}
        onConfirm={() => {
          setCustomerToEdit(null);
          setCustomerAddModal(false);
          debouncedAndFilteredSearch();
        }}
      />
    </Box>
  );
}

export default Customers;
