/** Dependencies */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/** Types */
import { InitialState, SingleRole } from './types';
import { IdAndName } from '../../../../models/General';

const initialState: InitialState = {
  list: [],
  role: {
    id: '',
    name: '',
    permission_groups: [],
  },
};

const roleSlice = createSlice({
  name: 'roleSlice',
  initialState,
  reducers: {
    getRoles: (state, action: PayloadAction<IdAndName[]>) => ({
      ...state,
      list: action.payload,
    }),
    getRole: (state, action: PayloadAction<SingleRole>) => ({
      ...state,
      role: action.payload,
    }),
    updateRoles: (state, action) => ({
      ...state,
      list: action.payload,
    }),
  },
});

export const { getRoles, getRole, updateRoles } = roleSlice.actions;

export default roleSlice.reducer;
