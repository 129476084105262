/** Dependencies */
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type ExpenseBase = {
  id: string;
  description: string;
  expense_category_id: string;
  date: string;
  currency?: string;
  amount?: number | null;
};

export type OptionsType = {
  id: string;
  text: string;
};

const addFormSchema = yup.object({
  description: yup.string().required('user.addModal.form.first_name.required'),
  date: yup.string().required('user.addModal.form.first_name.required'),
  currency: yup.string().required('user.addModal.form.first_name.required'),
  amount: yup
    .number()
    .min(0, 'Negatif değer girilemez')
    .typeError('user.addModal.form.first_name.required')
    .required('user.addModal.form.first_name.required'),
  expense_category_id: yup
    .string()
    .required('user.addModal.form.role.required'),
});

const updateFormSchema = yup.object({
  description: yup.string(),
  date: yup.string().required('user.addModal.form.first_name.required'),
  currency: yup.string().required('user.addModal.form.first_name.required'),
  amount: yup.number().required('user.addModal.form.first_name.required'),
  expense_category_id: yup
    .string()
    .required('user.addModal.form.role.required'),
});

export const addValidationSchema = yupResolver(addFormSchema);
export const updateValidationSchema = yupResolver(updateFormSchema);
