/** Dependencies */
import React, { useEffect } from 'react';

/** Styles */
import { Grid, Paper } from '@mui/material';
import { FormDataType } from './types';
import useSettings from '../../hooks/useSettings';
import { DisplayProduct } from '../../models/Product';
import CustomerDisplayDesignFormLeftSide from './CustomerDisplayDesignFormLeftSide';
import CustomerDisplayDesignFormRightSide from './CustomerDisplayDesignFormRightSide';

interface Props {
  formData: FormDataType;
  list?: DisplayProduct;
  screenType: string;
  displayScreenData?: FormDataType;
}

function GeneralSettingsForm({
  formData,
  list,
  screenType,
  displayScreenData,
}: Props) {
  const { fetchGeneralSettings, fetchMe, meProfile } = useSettings();

  useEffect(() => {
    fetchGeneralSettings();
    if (screenType === 'display') {
      fetchMe();
    }
  }, [screenType]);

  useEffect(() => {
    fetchGeneralSettings();
  }, [meProfile]);

  return (
    <Paper
      sx={{
        width: '100%',
        maxHeight: '100%',
      }}
    >
      <Grid container>
        <Grid
          sx={{
            backgroundColor: '#a20101',
            height: '2vh',
            width: '100%',
          }}
        />
        {(screenType === 'display' &&
          (!list?.tableName ||
            list.tableName === '' ||
            !displayScreenData?.working_types.find(
              item => item.id === list?.tableType,
            ))) ||
        (!displayScreenData?.is_active && screenType === 'display') ? (
          <Grid
            item
            xs={12}
            sx={{
              backgroundColor: formData.theme_color,
              height: '96vh',
              width: '100%',
              textAlign: 'center',
              padding: '40px',
            }}
          >
            <CustomerDisplayDesignFormLeftSide
              formData={formData}
              screenType={screenType}
            />
          </Grid>
        ) : (
          <>
            <Grid
              item
              xs={5}
              sx={{
                backgroundColor: formData.theme_color,
                height: screenType === 'settings' ? '80vh' : '96vh',
                width: '100%',
                textAlign: 'center',
                padding: '40px',
              }}
            >
              <CustomerDisplayDesignFormLeftSide
                formData={formData}
                screenType={screenType}
              />
            </Grid>
            <Grid
              item
              xs={7}
              sx={{
                width: '100%',
                height: screenType === 'settings' ? '80vh' : '96vh',
              }}
              justifyContent="space-between"
              display="flex"
              flexDirection="column"
            >
              <CustomerDisplayDesignFormRightSide
                formData={formData}
                list={list}
                screenType={screenType}
              />
            </Grid>
          </>
        )}

        <Grid
          sx={{
            backgroundColor: '#a20101',
            height: '2vh',
            width: '100%',
          }}
        />
      </Grid>
    </Paper>
  );
}

export default GeneralSettingsForm;
