import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isApiError } from '../../services/api/types';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getAppsThunk,
  getBranchesThunk,
  getDaysThunk,
  getPaymentHistoryThunk,
  getSalesByCategoriesThunk,
  getSalesByProductsThunk,
  getSalesBySectionsThunk,
  getSalesByUsersThunk,
  installAppThunk,
  updateBranchesEndDateThunk,
  updateBranchesStartDateThunk,
} from '../../store/slices/branchSlice';
import arrayCatcher from '../../helpers/errorCatcher';

const useBranches = () => {
  const dispatch = useAppDispatch();
  const branches = useAppSelector(state => state.branches.list);
  const branchApps = useAppSelector(state => state.branches.apps);
  const paymentHistories = useAppSelector(
    state => state.branches.paymentHistories,
  );
  const days = useAppSelector(state => state.branches.days);
  const daysMeta = useAppSelector(state => state.branches.meta);
  const salesByUsers = useAppSelector(state => state.branches.salesByUsers);
  const salesByProducts = useAppSelector(
    state => state.branches.salesByProducts,
  );
  const salesBySections = useAppSelector(
    state => state.branches.salesBySections,
  );
  const salesByCategories = useAppSelector(
    state => state.branches.salesByCategories,
  );
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const fetchBranches = async () => {
    try {
      setLoading(true);
      await dispatch(getBranchesThunk()).unwrap();
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchDays = async (
    page?: number,
    start_date?: string,
    end_date?: string,
  ) => {
    try {
      setLoading(true);
      await dispatch(getDaysThunk({ page, start_date, end_date })).unwrap();
    } catch (err: any) {
      arrayCatcher(err?.code);
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchSalesByUsers = async (
    page?: number,
    start_date?: string,
    end_date?: string,
  ) => {
    try {
      setLoading(true);
      await dispatch(
        getSalesByUsersThunk({ page, start_date, end_date }),
      ).unwrap();
    } catch (err: any) {
      arrayCatcher(err?.code);
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchSalesByProducts = async (
    page?: number,
    start_date?: string,
    end_date?: string,
  ) => {
    try {
      setLoading(true);
      await dispatch(
        getSalesByProductsThunk({ page, start_date, end_date }),
      ).unwrap();
    } catch (err: any) {
      arrayCatcher(err?.code);
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchSalesBySections = async (
    page?: number,
    start_date?: string,
    end_date?: string,
  ) => {
    try {
      setLoading(true);
      await dispatch(
        getSalesBySectionsThunk({ page, start_date, end_date }),
      ).unwrap();
    } catch (err: any) {
      arrayCatcher(err?.code);
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchSalesByCategories = async (
    page?: number,
    start_date?: string,
    end_date?: string,
  ) => {
    try {
      setLoading(true);
      await dispatch(
        getSalesByCategoriesThunk({ page, start_date, end_date }),
      ).unwrap();
    } catch (err: any) {
      arrayCatcher(err?.code);
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const updateBranchesStartDate = async (branchId: string) => {
    try {
      setLoading(true);
      await dispatch(updateBranchesStartDateThunk(branchId)).unwrap();
      window.location.reload();
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const updateBranchesEndDate = async (branchId: string) => {
    try {
      setLoading(true);
      await dispatch(updateBranchesEndDateThunk(branchId)).unwrap();
      window.location.reload();
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const getApps = async () => {
    try {
      setLoading(true);
      await dispatch(getAppsThunk()).unwrap();
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const installApp = async (slug: string) => {
    try {
      setLoading(true);
      await dispatch(installAppThunk(slug)).unwrap();
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const getPaymentHistories = async () => {
    try {
      setLoading(true);
      await dispatch(getPaymentHistoryThunk()).unwrap();
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    branches,
    days,
    salesByUsers,
    salesByProducts,
    salesBySections,
    salesByCategories,
    daysMeta,
    loading,
    fetchBranches,
    fetchSalesByUsers,
    fetchSalesByProducts,
    fetchSalesBySections,
    fetchSalesByCategories,
    fetchDays,
    updateBranchesStartDate,
    updateBranchesEndDate,
    getApps,
    installApp,
    getPaymentHistories,
    branchApps,
    paymentHistories,
  };
};
export default useBranches;
