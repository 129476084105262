import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialState } from './types';
import {
  Dashboard,
  EndOfDay,
  PaymentTypeReport,
} from '../../../services/api/reports/endOfDays/types';

const initialState: InitialState = {
  dailyReports: {
    day_start_date: '',
    day_end_date: '',
    order_transaction_count: 0,
    guest_count: 0,
    total_order_amount: {
      amount: '',
      currency: '',
      formatted: '',
    },
    total_cost_amount: {
      amount: '',
      currency: '',
      formatted: '',
    },
    total_expense_amount: {
      amount: '',
      currency: '',
      formatted: '',
    },
  },
  mostPopular: [],
  mostPopularMeta: [],
  dashboardReports: {
    order_transaction_count: 0,
    active_order_transaction_count: 0,
    total_table_count: 0,
    guest_count: 0,
    total_order_amount: {
      amount: '',
      currency: '',
      formatted: '',
    },
    active_order_amount: {
      amount: '',
      currency: '',
      formatted: '',
    },
    total_cost_amount: {
      amount: '',
      currency: '',
      formatted: '',
    },
    total_expense_amount: {
      amount: '',
      currency: '',
      formatted: '',
    },
  },
  paymentTypeReports: [],
  logs: [],
  meta: [],
};

const reportsSlice = createSlice({
  name: 'reportsSlice',
  initialState,
  reducers: {
    getDailyReports: (state, action: PayloadAction<EndOfDay>) => ({
      ...state,
      dailyReports: action.payload,
    }),
    getMostPopular: (state, action) => ({
      ...state,
      mostPopular: action.payload.data,
      mostPopularMeta: action.payload.meta,
    }),
    getDashboardReports: (state, action: PayloadAction<Dashboard>) => ({
      ...state,
      dashboardReports: action.payload,
    }),
    getPaymentTypeReports: (
      state,
      action: PayloadAction<PaymentTypeReport[]>,
    ) => ({
      ...state,
      paymentTypeReports: action.payload,
    }),
    getOperationLogs: (state, action: PayloadAction<any>) => ({
      ...state,
      logs: action.payload.data,
      meta: action.payload.meta,
    }),
  },
});

export const {
  getDailyReports,
  getMostPopular,
  getDashboardReports,
  getPaymentTypeReports,
  getOperationLogs,
} = reportsSlice.actions;

export default reportsSlice.reducer;
