import { Box, Chip, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EmptyState from '../../../components/EmptyState';
import getActiveLanguage from '../../../helpers/getActiveLanguage';
import { PropertyItem } from '../../../models/Property';

type Item = Omit<PropertyItem, 'property_group'>;

interface Props {
  items: Item[];
  onDelete: (item: Item) => void;
  onEdit: (item: Item) => void;
  hasMoreVertItemPermission: boolean;
}

function ItemsList({
  items,
  onDelete,
  onEdit,
  hasMoreVertItemPermission,
}: Props) {
  const { t } = useTranslation('property');

  return (
    <Box>
      {items.length ? (
        <Grid container spacing={1.5}>
          {items.map(item => (
            <Grid item key={item.id}>
              <Chip
                disabled={!hasMoreVertItemPermission}
                color="info"
                label={`${getActiveLanguage(item.translations).name} ${
                  item.additional_price?.formatted || ''
                }`}
                onDelete={() => onDelete(item)}
                onClick={() => onEdit(item)}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <EmptyState message={t('items.empty')} />
      )}
    </Box>
  );
}

export default ItemsList;
