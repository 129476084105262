/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';

/** Styles */
import { IconButton, TableCell, TableRow, Paper } from '@mui/material';

/** Components */
import Icon from '../../../components/Icon';
import ConfirmationModal from '../../../components/ConfirmationModal';
import EmptyState from '../../../components/EmptyState';

/** Hooks */
import { isApiError } from '../../../services/api/types';
import keyHasPermission from '../../../helpers/keyHasPermission';
import useProfile from '../../../hooks/useProfile';
import errorReporting from '../../../helpers/errorReporting';
import useCustomers from '../../../hooks/useCustomers';
import GetPaidModal from './Modals/GetPaidModal';
import getActiveLanguage from '../../../helpers/getActiveLanguage';
import { PaymentList } from '../../../services/api/definitions/customers/types';
import LoadingState from '../../../components/LoadingState';
import TableComponent from '../../../components/TableComponent';

interface Props {
  selectActive: number;
  customerBalance: () => void;
}

export default function CustomerPaymentTable({
  selectActive,
  customerBalance,
}: Props) {
  const { meProfile } = useProfile();
  const { t } = useTranslation('customerAddress');
  const { enqueueSnackbar } = useSnackbar();
  const {
    fetchPaymentList,
    customerPaymentLoading,
    removeCustomerPaid,
    customerPaid,
    paymentMeta,
  } = useCustomers();

  const { customerId } = useParams();

  const [loadingPaid, setLoadingPaid] = useState<boolean>(false);

  const [deletePaid, setDeletePaid] = useState({
    paid_id: '',
    customer_id: '',
  });
  const [page, setPage] = useState(1);

  const [customerToEdit, setCustomerToEdit] = useState<PaymentList | null>(
    null,
  );
  const [customerAddPaid, setCustomerAddPaid] = useState<boolean>(false);

  const handlePaidListChange = async (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    if (value !== page && customerId) {
      setPage(value);
      await fetchPaymentList({
        id: customerId,
        page: value,
      });
    }
  };

  useEffect(() => {
    // @ts-ignore
    setPage(paymentMeta.current_page);
  }, [paymentMeta]);

  useEffect(() => {
    if (customerId) {
      if (selectActive === 2) {
        fetchPaymentList({ page: 1, id: customerId });
      }
    }
  }, [selectActive]);

  const handlePaidRemove = async () => {
    setLoadingPaid(true);
    try {
      await removeCustomerPaid(deletePaid.paid_id, deletePaid.customer_id);
      setDeletePaid({
        paid_id: '',
        customer_id: '',
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
        },
      );
      errorReporting(err);
    }
    if (customerBalance) {
      customerBalance();
    }
    setLoadingPaid(false);
  };

  const hasListPermission = keyHasPermission(
    'view-customer-payment',
    meProfile?.permissions || [],
  );
  const hasRemovePermission = keyHasPermission(
    'delete-customer-payment',
    meProfile?.permissions || [],
  );
  const hasUpdatePermission = keyHasPermission(
    'update-customer-payment',
    meProfile?.permissions || [],
  );

  const tableHead = [
    t('customerAddress.addCustomerPaid.accountField'),
    t('customerAddress.addCustomerPaid.paymentType'),
    t('customerAddress.addCustomerPaid.paymentDate'),
    t('customerAddress.addCustomerPaid.amountPaid'),
    t('customerAddress.addCustomerPaid.operations'),
  ];

  const tableBody = customerPaid?.map(paid => (
    <TableRow
      key={paid.id}
      sx={{
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }}
    >
      <TableCell component="th" scope="row">
        {paid?.created_by?.first_name} {paid?.created_by?.last_name}
      </TableCell>
      <TableCell>
        {getActiveLanguage(paid?.payment_type?.translations)?.name}
      </TableCell>
      <TableCell>
        {' '}
        {format(new Date(paid.created_at), 'dd.MM.yyyy HH:mm')}
      </TableCell>
      <TableCell>{paid.amount.formatted}</TableCell>
      <TableCell>
        <IconButton
          onClick={() => {
            if (hasUpdatePermission) {
              setCustomerToEdit(paid);
              setCustomerAddPaid(true);
            }
          }}
          aria-label="Add"
          color="primary"
          disabled={!hasUpdatePermission}
        >
          <Icon name="Edit" />
        </IconButton>
        <IconButton
          onClick={() => {
            if (paid.customer && hasRemovePermission) {
              setDeletePaid({
                paid_id: paid.id,
                customer_id: paid.customer.id,
              });
            }
          }}
          aria-label="Add"
          color="error"
          disabled={!hasRemovePermission}
        >
          <Icon name="Delete" />
        </IconButton>
      </TableCell>
    </TableRow>
  ));

  return (
    <div>
      <div>
        {customerPaymentLoading ? (
          <LoadingState />
        ) : !customerPaid.length || !hasListPermission ? (
          <EmptyState
            message={
              !hasListPermission
                ? t('customerAddress.permission')
                : t('customerAddress.addCustomerPaid.empty')
            }
          />
        ) : (
          <TableComponent
            component={Paper}
            tableHead={tableHead}
            tableBody={tableBody}
            showPagination
            page={page}
            handleChange={handlePaidListChange}
            // @ts-ignore
            count={Math.ceil(paymentMeta.total / paymentMeta.per_page)}
          />
        )}
      </div>

      {customerId && (
        <GetPaidModal
          open={customerAddPaid}
          customerId={customerId}
          itemAmount={customerToEdit}
          customerBalance={customerBalance}
          onClose={() => {
            setCustomerAddPaid(false);
            setCustomerToEdit(null);
          }}
        />
      )}

      <ConfirmationModal
        title={t('customerAddress.deleteConfirmation.paidTitle')}
        titleIconName="QuestionMarkOutlined"
        description={t('customerAddress.deleteConfirmation.descriptionPaid')}
        open={!!deletePaid.paid_id}
        onClose={() =>
          setDeletePaid({
            paid_id: '',
            customer_id: '',
          })
        }
        positiveAction={t('customerAddress.deleteConfirmation.confirm')}
        onPositiveClick={handlePaidRemove}
        negativeAction={t('customerAddress.deleteConfirmation.cancel')}
        onNegativeClick={() =>
          setDeletePaid({
            paid_id: '',
            customer_id: '',
          })
        }
        loading={loadingPaid}
      />
    </div>
  );
}
