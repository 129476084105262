import api from '../../api';
import { ApiSuccessResponse } from '../../types';
import {
  CreateResponse,
  ListResponse,
  ViewResponse,
  UpdateResponse,
  DeleteResponse,
  ProductCreateParams,
  ProductUpdateParams,
  SetPropertyResponse,
  UpdateProductPropertyItemResponse,
  UnsetPropertyResponse,
  UpdateProductPropertyItemParams,
  SetIngredientResponse,
  UpdateProductIngredientItemParams,
  ProductUpdateUnitParams,
  ProductAddMenuParams,
  ProductUpdateMenuParams,
  ProductMenuAddItemParams,
  ProductMenuUpdateItemParams,
  SetGroupResponse,
  BulkProductUpdateResponse,
  BulkProductAPI,
} from './types';

const PAGING_LIMIT = 100;

// product endpoints
type ListRes = ApiSuccessResponse<ListResponse>;
const listAll = (
  name: string | '',
  category_id: string[] | null,
  is_bulk_update: boolean | null,
) =>
  api.get<ListRes>('/products', {
    params: {
      per_page: PAGING_LIMIT,
      filter: {
        is_parent: false,
        name,
        category_id,
        is_bulk_update,
      },
    },
  });

type BulkProductUpdateRes = ApiSuccessResponse<BulkProductUpdateResponse>;
const bulkProductsUpdate = (params: BulkProductAPI) =>
  api.post<BulkProductUpdateRes>('/products/bulk-update', params);

const list = (category_id: string, is_parent?: boolean, is_pos?: boolean) =>
  api.get<ListRes>('/products', {
    params: {
      per_page: 500,
      filter: {
        category_id,
        is_parent,
        is_pos,
      },
    },
  });

const search = (name: string) =>
  api.get<ListRes>('/products', {
    params: {
      per_page: PAGING_LIMIT,
      filter: {
        name,
      },
    },
  });

type ViewRes = ApiSuccessResponse<ViewResponse>;
const view = (id: string) => api.get<ViewRes>(`/products/${id}`);

type CreatRes = ApiSuccessResponse<CreateResponse>;
const create = (params: ProductCreateParams) => {
  const formData = new FormData();

  params.name.forEach(i => {
    formData.append(`name[${i.lang}]`, i.text);
  });
  formData.append('image', params.image);
  formData.append('category_id', params.category_id);
  formData.append('kitchen_group_id', params.kitchen_group_id);
  formData.append('tax_rate', params.tax_rate.toString());
  formData.append('product_code', params.product_code);
  formData.append('is_menu', params.is_menu ? '1' : '0');
  formData.append('is_parent', params.is_parent ? '1' : '0');
  formData.append('is_active', params.is_active ? '1' : '0');
  formData.append(
    'is_required_property_selection',
    params.is_required_property_selection ? '1' : '0',
  );
  formData.append('show_on_qr_menu', params.show_on_qr_menu ? '1' : '0');

  return api.post<CreatRes>('/products', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const updateImage = (id: string, type: string, image: any) => {
  const formData = new FormData();
  formData.append('image', image);

  return api.post(`/images/${id}/${type}`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

type DeleteRes = ApiSuccessResponse<DeleteResponse>;
const removeImage = (id: string, type: string) =>
  api.delete<DeleteRes>(`/images/${id}/${type}`);

type UpdateRes = ApiSuccessResponse<UpdateResponse>;
const update = (params: ProductUpdateParams) => {
  const { productId, name: rawName, ...rest } = params;
  const name: Record<string, string> = {};

  rawName.forEach(i => {
    name[i.lang] = i.text;
  });

  return api.put<UpdateRes>(`/products/${productId}`, { ...rest, name });
};

const remove = (id: string) => api.delete<DeleteRes>(`/products/${id}`);

type SetChildRes = ApiSuccessResponse<SetGroupResponse>;
const setChildren = (parent_id: string, child_ids: string[]) =>
  api.post<SetChildRes>(`/products/${parent_id}/set-children`, {
    parent_id,
    child_ids,
  });

const moveUp = (id: string) =>
  api.post<void>(`/products/${id}/move-sequence-up`);

const moveDown = (id: string) =>
  api.post<void>(`/products/${id}/move-sequence-down`);

const moveUnitUp = (id: string) =>
  api.post<void>(`/products/${id}/product-unit/move-sequence-up`);

const moveUnitDown = (id: string) =>
  api.post<void>(`/products/${id}/product-unit/move-sequence-down`);

// unit endpoints

type UpdateUnitRes = ApiSuccessResponse<ViewResponse>;
const updateUnit = (productId: string, params: ProductUpdateUnitParams) => {
  const unitList = params.map(unit => ({
    unit_id: unit.unitId,
    amount: unit.amount, // no need to multiply with 100
    cost: unit.cost, // no need to multiply with 100
    is_default: unit.isActive ? unit.isDefault : false, // don't allow default for non active units
    is_active: unit.isActive,
  }));
  return api.post<UpdateUnitRes>(`/products/${productId}/set-unit`, {
    product_units: unitList,
  });
};

// property endpoints
type SetPropertyGroupRes = ApiSuccessResponse<SetPropertyResponse>;
const setPropertyGroup = (productId: string, propertyGroupId: string) =>
  api.post<SetPropertyGroupRes>(
    `/products/${productId}/set-property-group/${propertyGroupId}`,
  );

type UnsetPropertyGroupRes = ApiSuccessResponse<UnsetPropertyResponse>;
const unsetPropertyGroup = (productId: string, propertyGroupId: string) =>
  api.post<UnsetPropertyGroupRes>(
    `/products/${productId}/unset-property-group/${propertyGroupId}`,
  );

type UpdateProductPropertyItemRes =
  ApiSuccessResponse<UpdateProductPropertyItemResponse>;
const updateProductPropertyItem = (params: UpdateProductPropertyItemParams) => {
  const { productId, propertyItemId, price, isActive } = params;
  return api.patch<UpdateProductPropertyItemRes>(
    `/products/${productId}/product-property-items/${propertyItemId}`,
    {
      additional_price: price * 100,
      is_active: isActive,
    },
  );
};

const movePropertyItemUp = (id: string) =>
  api.post<void>(`/products/${id}/product-property-items/move-sequence-up`);

const movePropertyItemDown = (id: string) =>
  api.post<void>(`/products/${id}/product-property-items/move-sequence-down`);

// Ingredient endpoints
type SetIngredientGroupRes = ApiSuccessResponse<SetIngredientResponse>;
const setIngredientGroup = (productId: string, ingredientGroupId: string) =>
  api.post<SetIngredientGroupRes>(
    `/products/${productId}/set-ingredient-group/${ingredientGroupId}`,
  );

type UnsetIngredientGroupRes = ApiSuccessResponse<SetIngredientResponse>;
const unsetIngredientGroup = (productId: string, ingredientGroupId: string) =>
  api.post<UnsetIngredientGroupRes>(
    `/products/${productId}/unset-ingredient-group/${ingredientGroupId}`,
  );

type UpdateProductIngredientItemRes =
  ApiSuccessResponse<UpdateProductPropertyItemResponse>;
const updateProductIngredientItem = (
  params: UpdateProductIngredientItemParams,
) => {
  const { productId, ingredientItemId, isActive } = params;
  return api.patch<UpdateProductIngredientItemRes>(
    `/products/${productId}/product-ingredient-items/${ingredientItemId}`,
    {
      is_active: isActive,
    },
  );
};

const moveIngredientItemUp = (id: string) =>
  api.post<void>(`/products/${id}/product-ingredient-items/move-sequence-up`);

const moveIngredientItemDown = (id: string) =>
  api.post<void>(`/products/${id}/product-ingredient-items/move-sequence-down`);

// Menu endpoints
type ProductAddMenuRes = ApiSuccessResponse<void>;
const productAddMenu = (params: ProductAddMenuParams) => {
  const name: Record<string, string> = {};
  params.name.forEach(i => {
    name[i.lang] = i.text;
  });
  return api.post<ProductAddMenuRes>('/product-menus', {
    product_id: params.productId,
    name,
    selectable_quantity: params.selectableQuantity,
  });
};

type ProductUpdateMenuRes = ApiSuccessResponse<void>;
const productUpdateMenu = (params: ProductUpdateMenuParams) => {
  const name: Record<string, string> = {};
  params.name.forEach(i => {
    name[i.lang] = i.text;
  });
  return api.put<ProductUpdateMenuRes>(
    `/product-menus/${params.productMenuId}`,
    {
      name,
      selectable_quantity: params.selectableQuantity,
    },
  );
};

type RemoveProductMenuRes = ApiSuccessResponse<void>;
const productRemoveMenu = (id: string) =>
  api.delete<RemoveProductMenuRes>(`/product-menus/${id}`);

type ProductMenuAddItemRes = ApiSuccessResponse<void>;
const productMenuAddItem = (params: ProductMenuAddItemParams) =>
  api.post<ProductMenuAddItemRes>(
    `/product-menus/${params.menuId}/set-product-menu-item`,
    {
      product_id: params.menuProductId,
      product_unit_id: params.unitId,
      quantity: params.quantity,
      additional_price: params.additionalPrice * 100,
    },
  );

type ProductMenuUpdateItemRes = ApiSuccessResponse<void>;
const productMenuUpdateItem = (params: ProductMenuUpdateItemParams) =>
  api.put<ProductMenuUpdateItemRes>(
    `/product-menus/update-product-menu-item/${params.menuItemId}`,
    {
      product_id: params.menuProductId,
      product_unit_id: params.unitId,
      quantity: params.quantity,
      additional_price: params.additionalPrice * 100,
    },
  );

type RemoveProductMenuItemRes = ApiSuccessResponse<void>;
const productRemoveItemMenu = (id: string) =>
  api.delete<RemoveProductMenuItemRes>(
    `/product-menus/unset-product-menu-item/${id}`,
  );

export default {
  listAll,
  list,
  search,
  view,
  create,
  update,
  remove,
  moveUp,
  moveDown,
  moveUnitUp,
  moveUnitDown,
  updateUnit,
  setPropertyGroup,
  unsetPropertyGroup,
  updateProductPropertyItem,
  movePropertyItemUp,
  movePropertyItemDown,
  setIngredientGroup,
  unsetIngredientGroup,
  updateProductIngredientItem,
  moveIngredientItemUp,
  moveIngredientItemDown,
  productAddMenu,
  productUpdateMenu,
  productRemoveMenu,
  productMenuAddItem,
  productMenuUpdateItem,
  productRemoveItemMenu,
  setChildren,
  bulkProductsUpdate,
  updateImage,
  removeImage,
};
