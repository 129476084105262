/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import {
  Box,
  TableContainer,
  TableSortLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
} from '@mui/material';

/** Components */
import LoadingState from '../../../../components/LoadingState';

/** Hooks */
import useIntegrations from '../../../../hooks/useIntegrations';
import getActiveLanguage from '../../../../helpers/getActiveLanguage';
import FormSelectSearchable from '../../../../components/FormSelectSearchable';
import useIngredientGroups from '../../../../hooks/useIngredientGroups';
import SearchBar from '../../../../components/SearchBar';

function IngredientsForm() {
  const { t } = useTranslation('integrations');
  const {
    fetchIngredientsSettings,
    settingsIngredientList,
    matchIntegrationIngredient,
    unmatchIntegrationIngredient,
    metaIngredientList,
    loading,
  } = useIntegrations();
  const { fetchIngredientGroups, ingredientGroups } = useIngredientGroups();

  const [sort, setSort] = useState('');
  const [isSorted, setIsSorted] = useState({
    name: '',
    price: '',
  });
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState<{
    inputValue: string;
    isActive: boolean | null;
  }>({
    inputValue: '',
    isActive: null,
  });
  const [debouncedSearch, setDebouncedSearch] = useState({
    inputValue: '',
    isActive: true,
  });
  const formattedIngredients = ingredientGroups.map(item => {
    const itemsArray = item.items.map(item2 => ({
      id: item2.id,
      name: item2.translations,
      category_name: item.translations,
    }));

    return {
      items: itemsArray,
    };
  });

  const debouncedAndFilteredSearch = async () => {
    try {
      fetchIngredientsSettings('trendyol', 1, searchValue.inputValue, sort);
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (value !== page) {
      setPage(value);
      fetchIngredientsSettings('trendyol', value, searchValue.inputValue, sort);
    }
  };

  const handleClick = async (rowId: string, productId: string) => {
    if (productId) {
      await matchIntegrationIngredient('trendyol', rowId, productId);
    } else {
      await unmatchIntegrationIngredient('trendyol', rowId);
    }

    fetchIngredientsSettings('trendyol', page, searchValue.inputValue, sort);
  };

  const handleSort = async (type: string, sortType: string) => {
    let sortValue = '';

    if (type === 'name') {
      setIsSorted({
        ...isSorted,
        name: sortType,
      });
      sortValue = `${sortType},${isSorted.price}`;
    } else {
      setIsSorted({
        ...isSorted,
        price: sortType,
      });
      sortValue = `${isSorted.name},${sortType}`;
    }

    sortValue = sortValue.replace(/^,+|,+$/g, '');
    sortValue = sortValue.replace(/,+/g, ',');

    setSort(sortValue);

    fetchIngredientsSettings(
      'trendyol',
      page,
      searchValue.inputValue,
      sortValue,
    );
  };

  useEffect(() => {
    fetchIngredientGroups();
  }, []);

  useEffect(() => {
    debouncedAndFilteredSearch();
  }, [debouncedSearch.inputValue]);

  if (loading) {
    return <LoadingState />;
  }

  return (
    <Box sx={{ py: 3, px: 1 }}>
      <SearchBar
        placeholder={t('placeholder')}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setDebouncedSearch={setDebouncedSearch}
      />
      <TableContainer sx={{ py: 1 }}>
        <Table sx={{ minWidth: 1280 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: 'primary.light' }}>
            <TableRow>
              <TableCell
                onClick={() => {
                  handleSort(
                    'name',
                    isSorted.name === ''
                      ? 'name'
                      : isSorted.name === 'name'
                      ? '-name'
                      : '',
                  );
                }}
              >
                <TableSortLabel
                  active={isSorted.name.length > 0}
                  direction={isSorted.name === 'name' ? 'asc' : 'desc'}
                >
                  {t('tables.integrationTable.integration_ingredient_name')}
                </TableSortLabel>
              </TableCell>
              <TableCell
                onClick={() => {
                  handleSort(
                    'price',
                    isSorted.price === ''
                      ? 'price'
                      : isSorted.price === 'price'
                      ? '-price'
                      : '',
                  );
                }}
              >
                <TableSortLabel
                  active={isSorted.price.length > 0}
                  direction={isSorted.price === 'price' ? 'asc' : 'desc'}
                >
                  {t('tables.integrationTable.amount')}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                {t('tables.integrationTable.kitchrest_property_name')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {settingsIngredientList?.map(tableRow => (
              <TableRow>
                <TableCell>{tableRow.name}</TableCell>
                <TableCell>{tableRow.price.formatted}</TableCell>
                <TableCell>
                  <FormSelectSearchable
                    label=""
                    onChange={e => handleClick(tableRow.id, e)}
                    value={tableRow?.ingredient_group_item?.id || ''}
                    options={formattedIngredients
                      .flatMap(obj => obj.items)
                      .map(p => ({
                        id: p.id,
                        text: getActiveLanguage(p.name).name,
                        group: getActiveLanguage(p.category_name).name,
                      }))
                      .sort((a, b) => a.group.localeCompare(b.group))}
                    groupBy={option => option.group || ''}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          sx={{ margin: '10px' }}
          // @ts-ignore
          count={Math.ceil(
            (metaIngredientList?.total || 1) /
              (metaIngredientList?.per_page || 1),
          )}
          page={page}
          onChange={handleChange}
        />
      </TableContainer>
    </Box>
  );
}

export default IngredientsForm;
