import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import EmptyState from '../../../../components/EmptyState';
import Modal from '../../../../components/Modal';
import OrderedList from '../../../../components/OrderedList';
import keyHasPermission from '../../../../helpers/keyHasPermission';
import useProfile from '../../../../hooks/useProfile';
import useSettings from '../../../../hooks/useSettings';
import AddSalesChannelModal from './AddSalesChannelModal';
import { IdAndName } from '../../../../models/General';

interface Props {
  open: boolean;
  onClose: () => void;
}

function SalesChannelsEditModal({ open, onClose }: Props) {
  const { t } = useTranslation('table');
  const { channelSales, fetchChannelSales, deleteChannelSales } = useSettings();
  const { meProfile } = useProfile();

  useEffect(() => {
    if (open) fetchChannelSales();
  }, [open]);

  const [loading, setLoading] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(
    null,
  ); // item id

  const [salesChannelAddModal, setSalesChannelAddModal] = React.useState(false);
  const [salesChannelToEdit, setSalesChannelToEdit] =
    React.useState<IdAndName | null>(null);

  const hasViewSalesChannelListing = keyHasPermission(
    'view-sales-channel-listing',
    meProfile?.permissions || [],
  );

  const hasUpdateSalesChannel = keyHasPermission(
    'update-sales-channel',
    meProfile?.permissions || [],
  );

  const hasDeleteSalesChannel = keyHasPermission(
    'delete-sales-channel',
    meProfile?.permissions || [],
  );

  const handleRemove = async () => {
    if (deleteConfirmation) {
      setLoading(true);
      await deleteChannelSales(deleteConfirmation);
      await fetchChannelSales();
      setLoading(false);
      setDeleteConfirmation(null);
    }
  };

  const renderEmpty = () => (
    <EmptyState
      message={
        hasViewSalesChannelListing
          ? t('section.add_modal.salesChannelEditTitle')
          : t('none_permission')
      }
    />
  );
  const renderList = () => (
    <OrderedList
      items={channelSales}
      loading={loading}
      onItemEdit={item => {
        setSalesChannelToEdit(item);
        setSalesChannelAddModal(true);
      }}
      onItemRemove={item => setDeleteConfirmation(item.id)}
      sortButtons={false}
      dense={false}
      editButtons={hasUpdateSalesChannel}
      removeButtons={hasDeleteSalesChannel}
    />
  );

  return (
    <>
      <Modal
        title={t('section.add_modal.salesChannelEditTitle')}
        open={open}
        onClose={onClose}
        positiveAction={t('section.add_modal.close')}
        onPositiveClick={onClose}
        headerButton={keyHasPermission(
          'create-sales-channel',
          meProfile?.permissions || [],
        )}
        headerButtonIconName="Add"
        headerButtonText={t('section.add_modal.add')}
        onHeaderButtonClick={() => setSalesChannelAddModal(true)}
      >
        {channelSales.length && hasViewSalesChannelListing
          ? renderList()
          : renderEmpty()}
      </Modal>
      <ConfirmationModal
        title={t('section.add_modal.deleteConfirmation.title')}
        titleIconName="QuestionMarkOutlined"
        description={t('section.add_modal.deleteConfirmation.description')}
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        positiveAction={t('section.add_modal.deleteConfirmation.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('section.add_modal.deleteConfirmation.cancel')}
        onNegativeClick={() => setDeleteConfirmation(null)}
        loading={loading}
      />
      <AddSalesChannelModal
        open={salesChannelAddModal}
        item={salesChannelToEdit}
        onClose={() => {
          setSalesChannelAddModal(false);
          setSalesChannelToEdit(null);
        }}
      />
    </>
  );
}

export default SalesChannelsEditModal;
