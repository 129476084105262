/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Grid, Avatar, Tooltip } from '@mui/material';

/** Components */
import Icon from '../../../../../../../components/Icon';

/** Styles */
import { Payments } from '../../types';

/** Helpers */
import getActiveLanguage from '../../../../../../../helpers/getActiveLanguage';
import { priceFormatter } from '../../../../../../../helpers/moneyFormatter';
import keyHasPermission from '../../../../../../../helpers/keyHasPermission';

/** Hooks */
import useProfile from '../../../../../../../hooks/useProfile';
import useCustomers from '../../../../../../../hooks/useCustomers';

interface Props {
  paymentList: Payments[];
  handleRemovePaymentListItem: (id: string) => void;
  paidPayments: any[];
  currencyCode: string;
  setDeleteConfirmation: React.Dispatch<React.SetStateAction<string | null>>;
}

function PaidPaymentList({
  paymentList,
  handleRemovePaymentListItem,
  paidPayments,
  currencyCode,
  setDeleteConfirmation,
}: Props) {
  const { t } = useTranslation('pos');
  const { meProfile } = useProfile();
  const { customers } = useCustomers();

  const hasRemoveOrderPaymentButton = keyHasPermission(
    'remove-order-payment',
    meProfile?.permissions || [],
  );

  function stringToColor(string: string) {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value1 = (hash >> (i * 8)) & 0xff;
      color += `00${value1.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  return (
    <Grid
      sx={{
        minHeight: { sm: '180px', md: '120px', lg: '190px' },
        maxHeight: { sm: '180px', md: '120px', lg: '190px' },
        overflow: 'auto',
      }}
    >
      {paidPayments.map(paidItem => (
        <Grid style={{ maxHeight: '200px', overflow: 'auto' }}>
          <Grid sx={{ p: 2, mt: 1 }} container justifyContent="space-between">
            {hasRemoveOrderPaymentButton &&
              (paidItem?.customer?.id ? (
                <Grid>
                  <Tooltip
                    title={`${paidItem?.customer?.first_name?.toUpperCase()} ${paidItem?.customer?.last_name?.toUpperCase()} `}
                    arrow
                  >
                    <Avatar
                      style={{
                        width: '23px',
                        height: '23px',
                        marginBottom: '5px',
                        marginLeft: '4px',
                        cursor: 'pointer',
                        fontSize: '12px',
                      }}
                      {...stringAvatar(
                        `${paidItem?.customer?.first_name?.toUpperCase()} ${paidItem?.customer?.last_name?.toUpperCase()}`,
                      )}
                    />
                  </Tooltip>
                </Grid>
              ) : (
                ''
              ))}
            <Grid>
              {getActiveLanguage(paidItem?.payment_type?.translations).name}
            </Grid>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Grid sx={{ mr: 2, fontSize: '12px' }}>
                {t('modalMidSide.amount')} :{' '}
                {priceFormatter(
                  (paidItem?.amount?.amount || 0) / 100,
                  currencyCode,
                )}
              </Grid>
              {paidItem?.remainder_amount.amount !== '0' && (
                <Grid sx={{ mr: 2, fontSize: '12px', color: 'red' }}>
                  {t('modalMidSide.change')}{' '}
                  {priceFormatter(
                    (paidItem?.remainder_amount?.amount || 0) / 100,
                    currencyCode,
                  )}
                </Grid>
              )}
              <Grid>
                {hasRemoveOrderPaymentButton && (
                  <Icon
                    color="error"
                    name="Delete"
                    onClick={async () => {
                      setDeleteConfirmation(paidItem?.id);
                    }}
                    cursor="pointer"
                  />
                )}
              </Grid>
              <Grid>
                {hasRemoveOrderPaymentButton && (
                  <Tooltip
                    title={`${paidItem?.created_by?.full_name || ''}`}
                    arrow
                  >
                    <Avatar
                      style={{
                        width: '23px',
                        height: '23px',
                        marginBottom: '5px',
                        marginLeft: '4px',
                        cursor: 'pointer',
                        fontSize: '12px',
                      }}
                      {...stringAvatar(
                        `${paidItem?.created_by?.full_name || ''}`,
                      )}
                    />
                  </Tooltip>
                )}
              </Grid>
            </Box>
          </Grid>
          <Divider />
        </Grid>
      ))}
      {paymentList.map(paymentListItem => (
        <Grid style={{ maxHeight: '200px', overflow: 'auto' }}>
          <Grid sx={{ p: 2, mt: 1 }} container justifyContent="space-between">
            {customers
              ?.filter(
                customer => customer?.id === paymentListItem?.customer_id,
              )
              .map(account => (
                <Grid>
                  <Tooltip
                    title={`${account?.first_name?.toUpperCase()} ${account?.last_name?.toUpperCase()} `}
                    arrow
                  >
                    <Avatar
                      style={{
                        width: '23px',
                        height: '23px',
                        marginBottom: '5px',
                        marginLeft: '4px',
                        cursor: 'pointer',
                        fontSize: '12px',
                      }}
                      {...stringAvatar(
                        `${account?.first_name.toUpperCase()} ${account?.last_name.toUpperCase()}`,
                      )}
                    />
                  </Tooltip>
                </Grid>
              ))}
            <Grid>
              {meProfile?.paymentTypes?.map(
                payment =>
                  payment.id === paymentListItem.payment_type_id &&
                  payment.name,
              )}
            </Grid>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Grid sx={{ mr: 2, fontSize: '12px' }}>
                {t('modalMidSide.amount')} :{' '}
                {priceFormatter(paymentListItem.amount, currencyCode)}
              </Grid>
              {paymentListItem.more_money &&
                !paymentListItem.more_money.toString().includes('e') && (
                  <Grid sx={{ mr: 2, fontSize: '12px', color: 'red' }}>
                    {t('modalMidSide.change')}:{' '}
                    {priceFormatter(
                      paymentListItem?.more_money || 0,
                      currencyCode,
                    )}
                  </Grid>
                )}
              <Grid>
                <Icon
                  color="error"
                  name="Delete"
                  onClick={() =>
                    handleRemovePaymentListItem(paymentListItem.id || '')
                  }
                  cursor="pointer"
                />
              </Grid>
              <Grid>
                <Tooltip
                  title={`${meProfile?.firstName.toUpperCase()} ${meProfile?.lastName.toUpperCase()} `}
                  arrow
                >
                  <Avatar
                    style={{
                      width: '23px',
                      height: '23px',
                      marginBottom: '5px',
                      marginLeft: '4px',
                      cursor: 'pointer',
                      fontSize: '12px',
                    }}
                    {...stringAvatar(
                      `${meProfile?.firstName.toUpperCase()} ${meProfile?.lastName.toUpperCase()} `,
                    )}
                  />
                </Tooltip>
              </Grid>
            </Box>
          </Grid>
          <Divider />
        </Grid>
      ))}
    </Grid>
  );
}

export default PaidPaymentList;
