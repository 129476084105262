import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type ProductPropertyFormValues = {
  groupIds: string[];
};

const schema = yup.object({
  groupIds: yup.array(yup.string()),
});

export const validationSchema = yupResolver(schema);

export const defaultValues = (): ProductPropertyFormValues => ({
  groupIds: [],
});
