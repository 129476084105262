/** Dependencies */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

/** Components */
import UtilitiesBar from '../../../components/UtilitiesBar';
import ErrorState from '../../../components/ErrorState';
import LoadingState from '../../../components/LoadingState';
import RolesTable from './RolesTable';
import useRoles from '../../../hooks/useRoles';

function Permissions() {
  const { t } = useTranslation('role');
  const { fetchRoles, loading, roles } = useRoles();

  useEffect(() => {
    fetchRoles();
  }, []);

  if (!roles) {
    return <ErrorState message={t('common:unknownError' as any)} />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('roles')}
        leftIconName="SupervisorAccount"
      />
      {loading ? <LoadingState /> : <RolesTable />}
    </Box>
  );
}

export default Permissions;
