/** Dependencies */
import React from 'react';

/** Styles */
import {
  Divider,
  Select,
  MenuItem,
  Button,
  Checkbox,
  ListItemText,
  ListSubheader,
  SelectChangeEvent,
} from '@mui/material';

interface Props {
  selectValue: string[];
  handleChange: (event: SelectChangeEvent<string[]>) => void;
  content: string;
  options: { id: string | number; text: string }[];
  subheader?: string;
  handleFilterSubmit?: () => void;
  filterText?: string;
  filterButton: boolean;
}

function SearchSelectBar({
  selectValue,
  handleChange,
  content,
  options,
  subheader,
  handleFilterSubmit,
  filterText,
  filterButton,
}: Props) {
  return (
    <Select
      multiple
      value={selectValue}
      onChange={handleChange}
      placeholder="xxxx"
      renderValue={selected => selected.join(', ')}
      sx={{
        width: '100%',
        height: '64px',
        border: 'none',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'white',
        },
        '& .MuiSelect-select .notranslate::after': {
          content: `"${content}"`,
          opacity: 0.42,
        },
        '&:hover': {
          '&& fieldset': {
            border: 'none',
          },
        },
        '.MuiOutlinedInput-notchedOutline': { border: 'none' },
      }}
    >
      {subheader && (
        <>
          <ListSubheader sx={{ alignItems: 'center', textAlign: 'center' }}>
            {subheader}
          </ListSubheader>
          <Divider />
        </>
      )}
      {options.map(group => (
        <MenuItem key={group.id} value={group.text}>
          <Checkbox checked={selectValue.indexOf(group.text) > -1} />
          <ListItemText primary={group.text} />
        </MenuItem>
      ))}
      {filterButton && (
        <div>
          <Divider />
          <Button
            variant="text"
            sx={{ width: '100%', fontSize: '16px', mt: 1 }}
            onClick={() => handleFilterSubmit && handleFilterSubmit()}
          >
            {filterText}
          </Button>
        </div>
      )}
    </Select>
  );
}

export default SearchSelectBar;
