import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../../components/ConfirmationModal';
import EmptyState from '../../../components/EmptyState';
import ErrorState from '../../../components/ErrorState';
import LoadingState from '../../../components/LoadingState';
import Modal from '../../../components/Modal';
import OrderedList from '../../../components/OrderedList';
import useKitchenGroups from '../../../hooks/useKitchenGroups';
import KitchenGroupAddModal from './kitchenGroupAddModal';
import keyHasPermission from '../../../helpers/keyHasPermission';
import useProfile from '../../../hooks/useProfile';
import { IdAndName } from '../../../models/General';

interface Props {
  open: boolean;
  onClose: () => void;
}

function KitchenGroupEditModal({ open, onClose }: Props) {
  const { t } = useTranslation('category');
  const {
    kitchenGroups,
    kitchenGroupLoading,
    kitchenGroupError,
    fetchKitchenGroups,
    removeKitchenGroups,
  } = useKitchenGroups();
  const { meProfile } = useProfile();

  useEffect(() => {
    if (open) fetchKitchenGroups();
  }, [open]);

  const [loading, setLoading] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(
    null,
  ); // item id

  const [kitchenGroupAddModal, setKitchenGroupAddModal] = React.useState(false);
  const [kitchenGroupToEdit, setKitchenGroupToEdit] =
    React.useState<IdAndName | null>(null);

  const handleRemove = async () => {
    if (deleteConfirmation) {
      setLoading(true);
      await removeKitchenGroups(deleteConfirmation);
      setLoading(false);
      setDeleteConfirmation(null);
    }
  };

  const renderLoading = () => <LoadingState />;
  const renderError = () => (
    <ErrorState
      message={
        kitchenGroupError
          ? kitchenGroupError.message
          : t('common:unknownError' as any)
      }
    />
  );
  const renderEmpty = () => (
    <EmptyState message={t('kitchenGroup.editModal.empty')} />
  );
  const renderList = () => (
    <OrderedList
      items={kitchenGroups}
      loading={loading}
      onItemEdit={item => {
        setKitchenGroupToEdit(item);
        setKitchenGroupAddModal(true);
      }}
      onItemRemove={item => setDeleteConfirmation(item.id)}
      sortButtons={false}
      dense={false}
      editButtons={keyHasPermission(
        'update-kitchen-group',
        meProfile?.permissions || [],
      )}
      removeButtons={keyHasPermission(
        'delete-kitchen-group',
        meProfile?.permissions || [],
      )}
    />
  );

  return (
    <>
      <Modal
        title={t('kitchenGroup.editModal.title')}
        open={open}
        onClose={onClose}
        positiveAction={t('kitchenGroup.editModal.close')}
        onPositiveClick={onClose}
        headerButton={keyHasPermission(
          'create-kitchen-group',
          meProfile?.permissions || [],
        )}
        headerButtonIconName="Add"
        headerButtonText={t('kitchenGroup.editModal.addButton')}
        onHeaderButtonClick={() => setKitchenGroupAddModal(true)}
      >
        {kitchenGroupLoading
          ? renderLoading()
          : kitchenGroupError
          ? renderError()
          : kitchenGroups.length
          ? renderList()
          : renderEmpty()}
      </Modal>
      <ConfirmationModal
        title={t('kitchenGroup.deleteConfirmation.title')}
        titleIconName="QuestionMarkOutlined"
        description={t('kitchenGroup.deleteConfirmation.description')}
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        positiveAction={t('kitchenGroup.deleteConfirmation.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('kitchenGroup.deleteConfirmation.cancel')}
        onNegativeClick={() => setDeleteConfirmation(null)}
        loading={loading}
      />
      <KitchenGroupAddModal
        open={kitchenGroupAddModal}
        item={kitchenGroupToEdit}
        onClose={() => {
          setKitchenGroupAddModal(false);
          setKitchenGroupToEdit(null);
        }}
        onConfirm={() => {
          setKitchenGroupAddModal(false);
          setKitchenGroupToEdit(null);
        }}
      />
    </>
  );
}

export default KitchenGroupEditModal;
