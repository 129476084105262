/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

/** Styles */
import {
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

/** Components */
import EmptyState from '../../../components/EmptyState';

/** Hooks */
import useBranches from '../../../hooks/useBranches';
import useProfile from '../../../hooks/useProfile';
import keyHasPermission from '../../../helpers/keyHasPermission';
import TableComponent from '../../../components/TableComponent';

interface Props {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

function EndOfDayTable({ page, setPage }: Props) {
  const { t } = useTranslation('endOfDays');
  const { meProfile } = useProfile();

  const navigate = useNavigate();
  const { days, daysMeta, fetchDays } = useBranches();

  const hasViewPermission = keyHasPermission(
    'view-daily-report',
    meProfile?.permissions || [],
  );

  const renderEmpty = () => (
    <EmptyState
      message={
        !hasViewPermission
          ? 'Bu sayfayı görmeye yetkiniz yok!'
          : 'Herhangi bir gün sonu raporu bulunamadı!'
      }
    />
  );

  const handleChange = async (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    if (value !== page) {
      setPage(value);
      await fetchDays(value);
    }
  };

  const tableHead = [
    t('endOfDays.endOfDaysTable.startDate'),
    t('endOfDays.endOfDaysTable.endDate'),
    t('endOfDays.endOfDaysTable.details'),
    t('endOfDays.endOfDaysTable.logs'),
  ];

  const tableBody = days.map(row => (
    <TableRow key={row.id}>
      <TableCell align="center">
        {row.start_date
          ? format(new Date(row.start_date), 'dd.MM.yyyy HH:mm')
          : '-'}
      </TableCell>
      <TableCell align="center">
        {row.end_date
          ? format(new Date(row.end_date), 'dd.MM.yyyy HH:mm')
          : '-'}
      </TableCell>
      <TableCell align="center">
        <Button
          onClick={event => {
            if (hasViewPermission) {
              navigate(`/reports/end-of-day-details/${row.id}`);
            }
          }}
          color="error"
          disabled={!hasViewPermission}
        >
          {t('endOfDays.endOfDaysTable.detailsButton')}
        </Button>
      </TableCell>
      <TableCell align="center">
        <Button
          onClick={event => {
            if (hasViewPermission) {
              navigate(`/reports/operation-logs-details/${row.id}`);
            }
          }}
          color="error"
          disabled={!hasViewPermission}
        >
          {t('endOfDays.endOfDaysTable.logsButton')}
        </Button>
      </TableCell>
    </TableRow>
  ));

  return (
    <Paper sx={{ width: '100%', p: 2 }}>
      {!hasViewPermission || !days.length ? (
        renderEmpty()
      ) : (
        <TableComponent
          tableHead={tableHead}
          tableBody={tableBody}
          showPagination
          page={page}
          tableStyle={{ minWidth: 650 }}
          handleChange={handleChange}
          // @ts-ignore
          count={Math.ceil(daysMeta.total / daysMeta.per_page)}
        />
      )}
    </Paper>
  );
}

export default EndOfDayTable;
