import { MenuItem } from './types';

const items: MenuItem[] = [
  {
    key: 'dashboard',
    icon: 'Home',
    link: '/',
    permissionKey: ['view-section-listing'],
  },
  {
    key: 'definitions.title',
    icon: 'AssignmentTurnedIn',
    permissionKey: [
      'view-section-listing',
      'create-section',
      'update-section',
      'delete-section',
      'move-section-sequence',
      'view-table-listing',
      'create-table',
      'update-table',
      'delete-table',
      'move-table-sequence',
      'view-unit-listing',
      'create-unit',
      'update-unit',
      'delete-unit',
      'view-kitchen-group-listing',
      'create-kitchen-group',
      'update-kitchen-group',
      'delete-kitchen-group',
    ],

    children: [
      {
        key: 'definitions.tableSection',
        icon: 'ViewQuilt',
        link: '/definitions/tables',
        permissionKey: [
          'view-section-listing',
          'create-section',
          'update-section',
          'delete-section',
          'move-section-sequence',
          'view-table-listing',
          'create-table',
          'update-table',
          'delete-table',
          'move-table-sequence',
        ],
      },
      {
        key: 'definitions.categoriesProducts',
        icon: 'RestaurantMenu',
        link: '/definitions/categories',
        permissionKey: [
          'view-category-listing',
          'create-category',
          'update-category',
          'delete-category',
          'move-category-sequence',
          'view-product-listing',
          'create-product',
          'update-product',
          'delete-product',
          'move-product-sequence',
        ],
      },
      {
        key: 'definitions.customers',
        icon: 'AssignmentInd',
        link: '/definitions/customers',
        permissionKey: [
          'view-customer-listing',
          'view-customer-detail',
          'create-customer',
          'update-customer',
          'delete-customer',
          'view-customer-address-listing',
          'create-customer-address',
          'update-customer-address',
          'delete-customer-address',
        ],
      },
      {
        key: 'definitions.properties',
        icon: 'ViewList',
        link: '/definitions/properties',
        permissionKey: [
          'view-property-group-listing',
          'create-property-group',
          'update-property-group',
          'delete-property-group',
          'view-property-group-item-listing',
          'create-property-group-item',
          'update-property-group-item',
          'delete-property-group-item',
        ],
      },
      {
        key: 'definitions.ingredients',
        icon: 'Category',
        link: '/definitions/ingredients',
        permissionKey: [
          'view-ingredient-group-listing',
          'create-ingredient-group',
          'update-ingredient-group',
          'delete-ingredient-group',
          'view-ingredient-group-item-listing',
          'create-ingredient-group-item',
          'update-ingredient-group-item',
          'delete-ingredient-group-item',
        ],
      },
    ],
  },
  {
    key: 'operations.title',
    icon: 'Layers',
    permissionKey: [
      'view-expense-listing',
      'create-expense',
      'update-expense',
      'delete-expense',
      'view-expense-category-listing',
      'create-expense-category',
      'update-expense-category',
      'delete-expense-category',
    ],
    children: [
      {
        key: 'operations.expense',
        icon: 'TrendingDown',
        link: '/operations/expense',
        permissionKey: [
          'view-expense-listing',
          'create-expense',
          'update-expense',
          'delete-expense',
          'view-expense-category-listing',
          'create-expense-category',
          'update-expense-category',
          'delete-expense-category',
        ],
      },
      // {
      //   key: 'definitions.callerIdRecords',
      //   icon: 'PhoneInTalk',
      //   link: '/operations/caller-id-records',
      //   permissionKey: ['view-caller-id-record-listing'],
      // },
    ],
  },
  {
    key: 'reports.reports',
    icon: 'PieChart',
    permissionKey: [],
    children: [
      {
        key: 'reports.title',
        icon: 'CreditCard',
        permissionKey: [],
        children: [
          {
            key: 'reports.endOfDay',
            icon: 'MonetizationOn',
            link: '/reports/end-of-day',
            permissionKey: ['view-daily-report'],
          },
          {
            key: 'reports.waiterSales',
            icon: 'Person',
            link: '/reports/waiter-sales',
            permissionKey: ['view-daily-report'],
          },
        ],
      },
      {
        key: 'productSalesReport.title',
        icon: 'Assessment',
        permissionKey: ['view-daily-report'],
        children: [
          {
            key: 'productSalesReport.byRegion',
            icon: 'SouthAmerica',
            link: '/reports/section-sales',
            permissionKey: ['view-daily-report'],
          },
          {
            key: 'productSalesReport.byCategory',
            icon: 'Category',
            link: '/reports/category-sales',
            permissionKey: ['view-daily-report'],
          },
          {
            key: 'productSalesReport.byProduct',
            icon: 'QrCode',
            link: '/reports/product-sales',
            permissionKey: ['view-daily-report'],
          },
        ],
      },
    ],
  },
  {
    key: 'userOperations.title',
    icon: 'People',
    permissionKey: [
      'view-user-listing',
      'view-user-detail',
      'create-user',
      'update-user',
      'delete-user',
      'view-role-listing',
      'view-role-detail',
      'update-role',
    ],
    children: [
      {
        key: 'userOperations.users',
        icon: 'People',
        link: '/user-operations/users',
        permissionKey: [
          'view-user-listing',
          'view-user-detail',
          'create-user',
          'update-user',
          'delete-user',
        ],
      },
      {
        key: 'userOperations.permissions',
        icon: 'VerifiedUser',
        link: '/user-operations/role-list',
        permissionKey: ['view-role-listing', 'view-role-detail', 'update-role'],
      },
    ],
  },
  {
    key: 'apps',
    icon: 'Apps',
    link: '/apps',
    permissionKey: ['view-app-listing'],
  },
  {
    key: 'Settings.title',
    icon: 'Settings',
    permissionKey: [
      'view-general-settings',
      'update-general-settings',
      'view-payment-types',
      'update-payment-types',
      'view-printer-listing',
      'view-printer-detail',
      'create-printer',
      'update-printer',
      'delete-printer',
    ],
    children: [
      {
        key: 'Settings.restaurant',
        icon: 'Restaurant',
        link: '/Settings/restaurant',
        permissionKey: [
          'view-general-settings',
          'update-general-settings',
          'view-payment-types',
          'update-payment-types',
        ],
      },
      {
        key: 'Settings.payment_history',
        icon: 'Payment',
        link: '/Settings/payment-history',
        permissionKey: ['view-payment-history-listing'],
      },
    ],
  },
];
export default items;
