/** Dependencies */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getBranchSettingsThunk, getChannelSalesThunk } from './thunks';

/** Types */
import { Couvert, InitialState, QrMenuTemplates, ServiceCharge } from './types';
import {
  BranchData,
  PaymentFilterType,
} from '../../../../services/api/settings/restauranSettings/types';
import { IdAndName } from '../../../../models/General';

const initialState: InitialState = {
  branchSettings: null,
  loading: false,
  branchFilterSettings: [],
  channelSales: [],
  qrMenuTemplates: null,
  couvert: null,
  serviceCharge: null,
};

const restaurantSettingsSlice = createSlice({
  name: 'restaurantSettingsSlice',
  initialState,
  reducers: {
    updateGeneralSettingsSlice: (state, action: PayloadAction<BranchData>) => ({
      ...state,
      branchSettings: action.payload,
    }),
    updateCouvertDataSlice: (state, action: PayloadAction<Couvert>) => ({
      ...state,
      couvert: action.payload,
    }),
    updateServiceChargeDataSlice: (
      state,
      action: PayloadAction<ServiceCharge>,
    ) => ({
      ...state,
      serviceCharge: action.payload,
    }),
    getFilterBranchSlice: (
      state,
      action: PayloadAction<PaymentFilterType[]>,
    ) => ({
      ...state,
      branchFilterSettings: action.payload,
    }),
    getQrMenuTemplatesSlice: (
      state,
      action: PayloadAction<QrMenuTemplates>,
    ) => ({
      ...state,
      qrMenuTemplates: action.payload,
    }),
    removeSalesChannelSlice: (
      state,
      action: PayloadAction<Pick<IdAndName, 'id'>>,
    ) => ({
      ...state,
      customers: state.channelSales.filter(
        item => item.id !== action.payload.id,
      ),
    }),
  },
  extraReducers: builder => {
    builder
      .addCase(getBranchSettingsThunk.pending, state => ({
        ...state,
        loading: true,
      }))
      .addCase(getBranchSettingsThunk.fulfilled, (state, action) => ({
        ...state,
        branchSettings: action.payload,
        loading: false,
      }))
      .addCase(getBranchSettingsThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }))
      .addCase(getChannelSalesThunk.pending, state => ({
        ...state,
        loading: true,
      }))
      .addCase(getChannelSalesThunk.fulfilled, (state, action) => ({
        ...state,
        channelSales: action.payload,
        loading: false,
      }))
      .addCase(getChannelSalesThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }));
  },
});

export const {
  updateGeneralSettingsSlice,
  getFilterBranchSlice,
  getQrMenuTemplatesSlice,
  removeSalesChannelSlice,
  updateCouvertDataSlice,
  updateServiceChargeDataSlice,
} = restaurantSettingsSlice.actions;

export default restaurantSettingsSlice.reducer;
