/** Dependencies */
import React, { useEffect, useState } from 'react';

/** Styles */
import { Grid, Stack } from '@mui/material';

/** Components */
import CustomerDisplayDesignForm from './CustomerDisplayDesignForm';
import { FormDataType } from './types';
import useSettings from '../../hooks/useSettings';
import { DisplayProduct } from '../../models/Product';

function Form() {
  const [list, setList] = useState<DisplayProduct>();

  const channel = new BroadcastChannel('channel');
  channel.onmessage = (event: any) => {
    setList(event.data);
  };

  const { displayScreenData, fetchDisplayScreen, fetchGeneralSettings } =
    useSettings();
  const [formData, setFormData] = useState<FormDataType>({
    is_shown_logo: false,
    name: '',
    caption: '',
    font_size: 14,
    product_font_size: 14,
    theme_color: '#232323',
    text_color: '#662326',
    is_active: true,
    working_types: [],
  });

  useEffect(() => {
    fetchDisplayScreen();
    fetchGeneralSettings();
  }, []);

  useEffect(() => {
    if (displayScreenData) {
      setFormData({
        is_shown_logo: displayScreenData?.is_shown_logo || false,
        name: displayScreenData?.name || '',
        caption: displayScreenData?.caption || '',
        font_size: displayScreenData?.font_size || 14,
        product_font_size: displayScreenData?.product_font_size || 14,
        theme_color: displayScreenData?.theme_color || '',
        text_color: displayScreenData?.text_color || '',
        is_active: displayScreenData?.is_active || false,
        working_types: [],
      });
    }
  }, [displayScreenData]);

  return (
    <Stack>
      <CustomerDisplayDesignForm
        formData={formData}
        list={list}
        screenType="display"
        displayScreenData={displayScreenData || formData}
      />
    </Stack>
  );
}

export default Form;
