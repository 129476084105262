/** Dependencies */
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type GeneralSettingFromValues = {
  domain_name: string;
  categories: string[];
  payment_types: number[];
  sales_channel_id: string;
  facebook_url: string;
  instagram_url: string;
  twitter_url: string;
  address: string;
  phone_number: string;
};

export type InfoFormValues = {
  type: string;
  lang: string;
  payment_types: number[];
  sales_channel_id: string;
  facebook_url: string;
  instagram_url: string;
  twitter_url: string;
  address: string;
  phone_number: string;
};

const onlineFoodOrderSettingsSchema: yup.SchemaOf<GeneralSettingFromValues> =
  yup.object({
    domain_name: yup.string().required('Zorunludur!'),
    categories: yup.array(yup.string().required()).required('Zorunludur!'),
    payment_types: yup.array(yup.number().required()).required('Zorunludur!'),
    sales_channel_id: yup.string().required('Zorunludur!'),
    facebook_url: yup.string().required('Zorunludur!'),
    instagram_url: yup.string().required('Zorunludur!'),
    twitter_url: yup.string().required('Zorunludur!'),
    address: yup.string().required('Zorunludur!'),
    phone_number: yup.string().required('Zorunludur!'),
  });

export const validationOnlineFoodOrderSettingsSchema = yupResolver(
  onlineFoodOrderSettingsSchema,
);
