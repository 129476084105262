import React from 'react';
import { Grid } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Slider, { CustomArrowProps } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Modal from '../../components/Modal';
import useBranches from '../../hooks/useBranches';
import useSettings from '../../hooks/useSettings';

interface Props {
  open: boolean;
  onClose: () => void;
  images: string[];
  description: string;
  slug: string;
  redirectUrl: string;
}
function DetailModal({
  open,
  onClose,
  images,
  description,
  slug,
  redirectUrl,
}: Props) {
  const { t } = useTranslation('pos');
  const { installApp } = useBranches();
  const { fetchMe } = useSettings();
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
  };

  const handleInstall = async () => {
    try {
      await installApp(slug);
      await fetchMe();
      navigate(redirectUrl);
      onClose();
    } catch (err: any) {
      console.log(err);
    }
  };

  // eslint-disable-next-line react/function-component-definition,react/no-unstable-nested-components
  const CustomNextArrow: React.FC<CustomArrowProps> = ({
    className,
    style,
    onClick,
  }) => (
    <ChevronRight
      className={className}
      style={{ ...style, display: 'block', color: 'black' }}
      onClick={onClick}
    />
  );

  // eslint-disable-next-line react/function-component-definition,react/no-unstable-nested-components
  const CustomPrevArrow: React.FC<CustomArrowProps> = ({
    className,
    style,
    onClick,
  }) => (
    <ChevronLeft
      className={className}
      style={{ ...style, display: 'block', color: 'black' }}
      onClick={onClick}
    />
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title=""
      negativeAction={t('orderModal.cancelModal.cancel')}
      positiveAction={t('orderModal.cancelModal.install')}
      onNegativeClick={handleClose}
      onPositiveClick={handleInstall}
      maxWidth="xs"
    >
      <Slider {...settings}>
        {images?.map((image, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div style={{ width: '400px', height: '400px' }} key={index}>
            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
            <img
              style={{ width: '400px', height: '400px' }}
              src={image}
              alt={`Image ${index}`}
            />
          </div>
        ))}
      </Slider>
      <br />
      <br />
      <Grid sx={{ textAlign: 'center' }}>{description}</Grid>
    </Modal>
  );
}

export default DetailModal;
