import api from '../../api';
import { ApiSuccessResponse } from '../../types';
import {
  CreateResponse,
  ListResponse,
  ViewResponse,
  UpdateResponse,
  DeleteResponse,
} from './types';

const PAGING_LIMIT = 100;

type ListRes = ApiSuccessResponse<ListResponse>;
const list = (sales_channel_id?: string) =>
  api.get<ListRes>(`/sections?per_page=${PAGING_LIMIT}`, {
    params: {
      filter: {
        sales_channel_id,
      },
    },
  });

type ViewRes = ApiSuccessResponse<ViewResponse>;
const view = (id: string) => api.get<ViewRes>(`/sections/${id}`);

type CreatRes = ApiSuccessResponse<CreateResponse>;
const create = (name: string, sales_channel_id: string) =>
  api.post<CreatRes>('/sections', { name, sales_channel_id });

type UpdateRes = ApiSuccessResponse<UpdateResponse>;
const update = (id: string, name: string, sales_channel_id: string) =>
  api.put<UpdateRes>(`/sections/${id}`, { name, sales_channel_id });

type DeleteRes = ApiSuccessResponse<DeleteResponse>;
const remove = (id: string) => api.delete<DeleteRes>(`/sections/${id}`);

const moveUp = (id: string) =>
  api.post<void>(`/sections/${id}/move-sequence-up`);

const moveDown = (id: string) =>
  api.post<void>(`/sections/${id}/move-sequence-down`);

export default { list, view, create, update, remove, moveUp, moveDown };
