/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

/** Components */
import { Box, Grid, useMediaQuery } from '@mui/material';
import Modal from '../../../../components/Modal';

/** Types */
import {
  Flow,
  NotificationUser,
  Trigger2,
} from '../../../../services/api/operations/fraud/types';

/** Hooks */
import FormInput from '../../../../components/FormInput';
import Icon from '../../../../components/Icon';
import { ParameterForm } from './ParameterForm';
import { PersonForm } from './PersonForm';
import useFlow from '../../../../hooks/useFlow';
import { isApiError } from '../../../../services/api/types';

interface Props {
  open: boolean;
  onClose: () => void;
  onSaved: () => void;
  item: Flow | null;
}

function SuspiciousTransactionModal({ open, onClose, onSaved, item }: Props) {
  const { t } = useTranslation('frauds');
  const { enqueueSnackbar } = useSnackbar();
  const matchesSm = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const { createFlow, updateFlow, deleteUser, fetchFraudFlowById, flow } =
    useFlow();
  const [value, setValue] = useState('');
  const [isShowParameter, setIsShowParameter] = useState<boolean>(false);
  const [isShowPerson, setIsShowPerson] = useState<boolean>(false);
  const [isCreated, setIsCreated] = useState<boolean>(false);

  const [triggers, setTriggers] = useState<Trigger2[] | null>([
    {
      action_type: 'discount',
      percentage: null,
      amount: null,
    },
    {
      action_type: 'cancel',
      percentage: null,
      amount: null,
    },
    {
      action_type: 'complimentary',
      percentage: null,
      amount: null,
    },
    {
      action_type: 'return',
      percentage: null,
      amount: null,
    },
  ]);
  const [person, setPerson] = useState<NotificationUser | null>(null);
  const [notificationUsers, setNotificationUsers] = useState<
    NotificationUser[] | null
  >(null);

  const handleSubmit = async () => {
    try {
      const params = {
        name: value,
        triggers:
          triggers?.map(trigger => ({
            ...trigger,
            amount:
              Number(trigger?.amount) > 0 ? Number(trigger.amount) * 100 : null,
            percentage:
              Number(trigger?.percentage) > 0 ? trigger.percentage : null,
          })) || [],
        notification_users: notificationUsers || [],
      };
      if (!value) {
        enqueueSnackbar(t('flows.addModal.snackbarMessages.emptyName'), {
          variant: 'warning',
          style: { whiteSpace: 'pre-line' },
        });
      } else if (
        triggers?.find(trigger => Number(trigger?.percentage) > 1000)
      ) {
        enqueueSnackbar(t('flows.addModal.snackbarMessages.moreDiscount'), {
          variant: 'warning',
          style: { whiteSpace: 'pre-line' },
        });
      } else if (item) {
        const updateParams = {
          id: item.id,
          name: params.name,
          triggers: params.triggers,
        };
        await updateFlow(updateParams);
      } else {
        await createFlow(params);
      }
      setIsShowPerson(false);
      setIsShowParameter(false);
      onSaved();
      onClose();
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    }
  };

  const handleRemoveUser = async (
    phone_number: string,
    flowId?: string,
    notificationUserId?: string,
  ) => {
    try {
      const filteredUsers =
        notificationUsers?.filter(
          user => user?.phone_number !== phone_number,
        ) || [];
      setNotificationUsers(filteredUsers);

      if (flowId && notificationUserId) {
        await deleteUser(flowId, notificationUserId);
      }
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    }
  };

  const getFlow = async () => {
    if (isCreated && item?.id) {
      await fetchFraudFlowById(item?.id);
      setIsCreated(false);
    }
  };

  useEffect(() => {
    if (item) {
      const newTriggers = [
        {
          action_type: 'discount',
          percentage:
            item?.triggers?.find(x => x?.action_type.value === 'discount')
              ?.percentage || null,
          amount:
            Number(
              item?.triggers?.find(x => x?.action_type.value === 'discount')
                ?.amount?.amount,
            ) / 100 || null,
        },
        {
          action_type: 'cancel',
          percentage:
            item?.triggers?.find(x => x?.action_type.value === 'cancel')
              ?.percentage || null,
          amount:
            Number(
              item?.triggers?.find(x => x?.action_type.value === 'cancel')
                ?.amount?.amount,
            ) / 100 || null,
        },
        {
          action_type: 'complimentary',
          percentage:
            item?.triggers?.find(x => x?.action_type.value === 'complimentary')
              ?.percentage || null,
          amount:
            Number(
              item?.triggers?.find(
                x => x?.action_type.value === 'complimentary',
              )?.amount?.amount,
            ) / 100 || null,
        },
        {
          action_type: 'return',
          percentage:
            item?.triggers?.find(x => x?.action_type.value === 'return')
              ?.percentage || null,
          amount:
            Number(
              item?.triggers?.find(x => x?.action_type.value === 'return')
                ?.amount?.amount,
            ) / 100 || null,
        },
      ];
      setTriggers(newTriggers);
      setValue(item.name);
      if (item.notification_users) {
        setNotificationUsers(item.notification_users);
      }
    }
  }, [item]);

  useEffect(() => {
    getFlow();
  }, [isCreated]);

  useEffect(() => {
    setNotificationUsers(flow?.notification_users || []);
  }, [flow]);

  return (
    <Modal
      title={
        item ? t('flows.addModal.editTitle') : t('flows.addModal.editTitle')
      }
      open={open}
      onClose={onClose}
      negativeAction={t('flows.addModal.buttons.close')}
      onNegativeClick={onClose}
      positiveAction={
        item
          ? t('flows.addModal.buttons.edit')
          : t('flows.addModal.buttons.add')
      }
      onPositiveClick={() => handleSubmit()}
      positionRight
      fullScreen={matchesSm}
      maxWidth={isShowParameter ? 'md' : isShowPerson ? 'md' : 'sm'}
    >
      <Box>
        <Grid container justifyContent="space-between">
          <Grid xs={isShowParameter || isShowPerson ? 6 : 12}>
            <Grid>
              <FormInput
                value={value}
                onChange={e => setValue(e.target.value)}
                label={t('flows.addModal.forms.name')}
              />
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              sx={{
                cursor: 'pointer',
                border: '1px solid #e3e3e7',
                width: '100%',
                height: '62px',
                padding: '15px 12px',
                background: isShowParameter ? '#1ecf72' : '#f5f8fa',
                color: isShowParameter ? 'white' : 'black',
                marginBottom: '16px',
                borderRadius: '10px',
              }}
              onClick={() => {
                if (!isShowParameter) {
                  setIsShowPerson(false);
                }
                setIsShowParameter(!isShowParameter);
              }}
            >
              <Grid xs={6}>{t('flows.addModal.forms.params')}</Grid>
              <Grid xs={1}>
                <Icon name={isShowParameter ? 'ArrowBack' : 'ArrowForward'} />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              sx={{
                cursor: 'pointer',
                border: '1px solid #e3e3e7',
                width: '100%',
                height: '62px',
                padding: '15px 12px',
                background: isShowPerson ? '#1ecf72' : '#f5f8fa',
                color: isShowPerson ? 'white' : 'black',
                borderRadius: notificationUsers?.length
                  ? '10px 10px 0 0'
                  : '10px',
              }}
              onClick={() => {
                if (!isShowPerson) {
                  setIsShowParameter(false);
                }
                setIsShowPerson(!isShowPerson);
                setPerson(null);
              }}
            >
              <Grid xs={6}>{t('flows.addModal.forms.person')}</Grid>
              <Grid xs={1}>
                <Icon name={isShowPerson ? 'ArrowBack' : 'ArrowForward'} />
              </Grid>
            </Grid>
            {notificationUsers?.length
              ? notificationUsers.map(user => (
                  <Grid
                    container
                    justifyContent="space-between"
                    sx={{
                      border: '1px solid #e3e3e7',
                      width: '100%',
                      height: '62px',
                      padding: '15px 12px',
                      background: 'white',
                      color: isShowPerson ? 'white' : 'black',
                      // borderRadius: '10px',
                    }}
                  >
                    <Grid>
                      <Grid
                        sx={{
                          fontWeight: '550',
                          fontSize: '15px',
                          color: '#000000a3',
                        }}
                      >
                        {user.full_name}
                      </Grid>
                      <Grid sx={{ fontSize: '13px', color: 'black' }}>
                        {user.phone_number}
                      </Grid>
                    </Grid>
                    <Grid>
                      <Icon
                        onClick={() => {
                          setPerson(user);
                          setIsShowPerson(true);
                        }}
                        color="success"
                        name="Edit"
                        cursor="pointer"
                      />
                      <Icon
                        color="error"
                        name="Delete"
                        cursor="pointer"
                        onClick={() =>
                          handleRemoveUser(
                            user.phone_number,
                            item?.id,
                            user?.id,
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                ))
              : false}
          </Grid>
          {(isShowParameter || isShowPerson) && (
            <Grid
              xs={1}
              sx={{
                height: '82vh',
                backgroundColor: '#cecdcd',
                maxWidth: '0.1% !important',
              }}
            >
              {' '}
            </Grid>
          )}
          {isShowParameter && (
            <ParameterForm triggers={triggers} setTriggers={setTriggers} />
          )}
          {isShowPerson && (
            <PersonForm
              notificationUsers={notificationUsers}
              setNotificationUsers={setNotificationUsers}
              onClose={() => {
                setPerson(null);
                setIsShowPerson(false);
              }}
              item={person}
              id={item?.id}
              created={() => setIsCreated(true)}
            />
          )}
        </Grid>
      </Box>
    </Modal>
  );
}

export default SuspiciousTransactionModal;
