import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import currency from 'currency.js';
import { Dialog, Stack } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useCategories from '../../../../hooks/useCategories';
import useProducts from '../../../../hooks/useProducts';
import { BasketProduct } from '../../../../models/Product';
import LeftColumn from './LeftColumn';
import styles from './styles';
import ContentColumn from './ContentColumn';
import usePosSearch from '../../../../hooks/usePosSearch';
import GuestsModal from './GuestsModal';
import DescriptionModal from './DescriptionModal';
import CustomerModal from './CustomerModal';
import usePosOrder from '../../../../hooks/usePosOrder';
import { ExtraParams, LocationState, SpecialOrder, TotalPrices } from './types';
import useBasketItems from '../../../../hooks/useBasketItems/useBasketItems';
import CancelModal from './CancelModal';
import SpecialOrderModal from './SpecialOrderModal/SpecialOrderModal';
import DiscountModal from './DiscountModal';
import PaymentModal from './PaymentModal';
import ErrorState from '../../../../components/ErrorState';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import FastPaymentModal from './FastPaymentModal';
import useProfile from '../../../../hooks/useProfile';

/** helpers */
import getActiveLanguage from '../../../../helpers/getActiveLanguage';
import useCustomers from '../../../../hooks/useCustomers';
import PackagePaymentModal from './PackagePaymentModal';
import useOrders from '../../../../hooks/useOrders';
import { SelfPayment } from './PaymentModal/types';
import useSettings from '../../../../hooks/useSettings';
import CouvertModal from './CouvertModal';
import { NameTranslation } from '../../../../models/General';
import { salesChannelStorage } from '../../../../services/tokenStorage';

const initialExtraParams: ExtraParams = {
  tableName: '',
  guestsCount: 1,
  customerId: '',
  note: '',
  transactionId: '',
  tableId: '',
};

function OrderModal() {
  const { customers } = useCustomers();

  const navigate = useNavigate();
  const { id } = useParams();
  const {
    createTableOrder,
    submitting,
    getOrderDetails,
    orderDetails,
    orderDetailsError,
    orderDetailsLoading,
    extraParamsId,
    changeGuestCount,
    changeDescription,
    changeTableName,
    changeOrderCustomer,
    changeServiceCharge,
  } = usePosOrder();
  const {
    createPackageOrder,
    createSelfOrder,
    getGroupedOrderDetails,
    groupedOrderDetails,
    groupedOrderDetailsLoading,
    groupedOrderDetailsError,
    orderSubmitting,
  } = useOrders();

  const { t } = useTranslation('pos');
  const { enqueueSnackbar } = useSnackbar();

  const {
    addItemForBasket,
    removeItemForBasket,
    increaseCountForBasket,
    decreaseCountForBasket,
    basketItems,
    clearItemsForBasket,
  } = useBasketItems();
  const { meProfile } = useProfile();
  const { getCouvert, getServiceCharge, serviceChargeData, couvertData } =
    useSettings();

  const { state, pathname } = useLocation();
  const {
    tableName: originalTableName,
    full,
    callerCustomer,
    callerAddress,
  } = state as LocationState;
  const isPackageOrder = pathname.includes('package');
  const isSelfOrder = pathname.includes('self');

  if (!state && !isPackageOrder) {
    return <ErrorState posRedirect message={t('orderModal.empty')} />;
  }

  const channel = new BroadcastChannel('channel');

  window.addEventListener('beforeunload', () => {
    channel.postMessage({
      tableData: '',
      created_at: '',
      orders: [],
      totalPrices: null,
    });
  });

  const [onSelectionModal, setOnSelectionModal] = useState({
    isSelected: false,
    id: '',
  });
  const [onSelectionMenuModal, setOnSelectionMenuModal] = useState({
    isSelected: false,
    id: '',
  });
  const [selectedCustomer, setSelectedCustomer] = useState<{
    first_name?: string;
    last_name?: string;
  }>({ first_name: '', last_name: '' });

  const [activeCategoryId, setActiveCategoryId] = useState<string | null>(null);
  const [specialOrders, setSpecialOrders] = useState<SpecialOrder[]>([]);
  const [customerModalId, setCustomerModalId] = useState('');

  const [extraParams, setExtraParams] =
    useState<ExtraParams>(initialExtraParams);

  const [descriptionModal, setDescriptionModal] = useState(false);
  const [guestsModal, setGuestsModal] = useState(false);
  const [couvertModal, setCouvertModal] = useState(false);
  const [isAutoCouvert, setIsAutoCouvert] = useState(false);
  const [isAutoServiceCharge, setIsAutoServiceCharge] = useState(false);
  const [customerModal, setCustomerModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [specialOrderModal, setSpecialOrderModal] = useState(false);
  const [discountModal, setDiscountModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [packagePaymentModal, setPackagePaymentModal] = useState(false);
  const [submittedPackage, setSubmittedPackage] = useState(false);
  const [fastPaymentModal, setFastPaymentModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(
    null,
  );

  const [mappedOrders, setMappedOrders] = useState<BasketProduct[]>([]);

  const [totalPrices, setTotalPrices] = useState<TotalPrices>({
    totalPrice: 0,
    taxTotal: 0,
    grossTotal: 0,
    paidTotal: 0,
    discountTotal: 0,
    treatTotal: 0,
    couvert: 0,
    serviceCharge: 0,
  });

  const [packageTableItems, setPackageTableItems] = useState<{
    payment: { payment_type_id: number; translation: NameTranslation[] } | null;
    discount: { discount_type: number; value: number } | null;
    customer_id: string;
    customer_address_id: string;
  }>({
    payment: null,
    discount: null,
    customer_id: '',
    customer_address_id: '',
  });
  const [salesChannelId, setSalesChannelId] = useState('');

  const { fetchCategories, categories, categoryError, categoryLoading } =
    useCategories();
  useEffect(() => {
    const result = fetchCategories();
    setSalesChannelId(salesChannelStorage?.getToken() || '');
    return () => {
      result.abort();
    };
  }, []);

  const handleGetOrderDetails = async () => {
    if (id) {
      await getOrderDetails(id);
    }
  };

  useEffect(() => {
    if (id && full && !isPackageOrder && !isSelfOrder) {
      handleGetOrderDetails();
    }

    if (id && (isPackageOrder || isSelfOrder)) {
      getGroupedOrderDetails(id);
    }
  }, []);

  useEffect(() => {
    if (
      meProfile?.installed_apps?.find(item => item.slug === 'couvert') &&
      !full &&
      !isPackageOrder &&
      !isSelfOrder
    ) {
      getCouvert();
    }
    if (
      meProfile?.installed_apps?.find(item => item.slug === 'service-charge') &&
      !isPackageOrder &&
      !isSelfOrder
    ) {
      getServiceCharge();
    }
  }, [meProfile]);

  // set first category as active category
  useEffect(() => {
    if (categories.length && !activeCategoryId) {
      setActiveCategoryId(categories[0].id);
    }
  }, [categories]);

  useEffect(() => {
    navigate('', {
      state: {
        tableName: originalTableName,
        full: true,
      },
    });
  }, [extraParams]);

  const { products, productLoading, productError, fetchProducts } =
    useProducts();

  // fetch products whenever category changes
  useEffect(() => {
    if (activeCategoryId) {
      fetchProducts(activeCategoryId, undefined, true);
    }
  }, [activeCategoryId]);

  useEffect(() => {
    if (
      // @ts-ignore
      (!orderDetails || orderDetails === []) &&
      // !full &&
      !isPackageOrder &&
      !isSelfOrder
    ) {
      if (couvertData) setIsAutoCouvert(couvertData?.is_automatic);
      if (serviceChargeData)
        setIsAutoServiceCharge(serviceChargeData?.is_automatic);
    }

    if (!orderDetails) {
      if (couvertData && couvertData?.is_automatic) {
        setTotalPrices({
          ...totalPrices,
          totalPrice: (Number(couvertData?.amount.amount) || 0) / 100,
          couvert: Number(couvertData?.amount.amount) || 0,
        });
      }
    }
  }, [couvertData, serviceChargeData]);

  useEffect(() => {
    setTotalPrices({
      totalPrice: Number(
        currency(
          (orderDetails || groupedOrderDetails)?.total_amount?.amount || 0,
        )
          .divide(100)
          .subtract(
            currency(
              (orderDetails || groupedOrderDetails)?.total_discount?.amount ||
                0,
            ).divide(100),
          ),
      ),
      taxTotal:
        Number((orderDetails || groupedOrderDetails)?.tax_amount?.amount) || 0,
      grossTotal:
        Number((orderDetails || groupedOrderDetails)?.gross_amount?.amount) ||
        0,
      paidTotal:
        Number(
          currency(
            (orderDetails || groupedOrderDetails)?.paid_amount?.amount || 0,
          ).divide(100),
        ) || 0,
      discountTotal:
        Number((orderDetails || groupedOrderDetails)?.total_discount?.amount) ||
        0,
      treatTotal:
        Number((orderDetails || groupedOrderDetails)?.treat_amount?.amount) ||
        0,
      couvert: Number(orderDetails?.couvert_amount?.amount) || 0,
      serviceCharge:
        orderDetails?.is_automatic_service_charge ||
        (serviceChargeData && serviceChargeData?.is_automatic)
          ? serviceChargeData && serviceChargeData?.type === 'amount'
            ? Number(orderDetails?.service_charge_amount?.amount)
            : Number(
                currency(orderDetails?.total_amount?.amount || 0)
                  .divide(100)
                  .subtract(
                    currency(orderDetails?.total_discount?.amount || 0).divide(
                      100,
                    ),
                  )
                  .subtract(
                    currency(orderDetails?.couvert_amount?.amount || 0).divide(
                      100,
                    ),
                  )
                  .subtract(
                    currency(
                      orderDetails?.service_charge_amount?.amount || 0,
                    ).divide(100),
                  ),
              ) *
              (Number(serviceChargeData?.amount?.amount || 0) / 100)
          : 0,
    });
    setExtraParams({
      tableName: (orderDetails || groupedOrderDetails)?.table?.name || '',
      guestsCount: (orderDetails || groupedOrderDetails)?.guest_count || 1,
      customerId: (orderDetails || groupedOrderDetails)?.customer?.id || '',
      note: (orderDetails || groupedOrderDetails)?.description || '',
      transactionId: '',
      tableId: (orderDetails || groupedOrderDetails)?.table?.id || '',
    });

    setPackageTableItems({
      payment: groupedOrderDetails?.preset_payment_type
        ? {
            payment_type_id: groupedOrderDetails?.preset_payment_type?.id,
            translation: groupedOrderDetails?.preset_payment_type?.translations,
          }
        : null,
      discount: null,
      customer_id: groupedOrderDetails?.customer?.id || '',
      customer_address_id: groupedOrderDetails?.customer_address?.id || '',
    });

    // @ts-ignore
    const mappedOrdersData: BasketProduct[] =
      (orderDetails || groupedOrderDetails)?.orders?.map(order => ({
        id: order.id,
        is_treat: order.is_treat,
        is_submitted_order: true,
        created_by: order.created_by,
        is_active: true,
        is_menu: order.is_menu,
        is_required_property_selection: true,
        menus: [],
        payments: order.payments,
        has_special_product: order?.has_special_product || false,
        gross_amount: order?.gross_amount,
        unit_price: order?.unit_price,
        product: order.product,
        items: order.items,
        category: order?.items[0]?.category || {},
        count: order.quantity,
        kitchen_group: order?.items[0]?.category
          ? getActiveLanguage(order?.items[0]?.category?.translations)
          : { id: '', lang: 'tr', name: '' },
        product_code: '620910011150',
        sequence: 4,
        tax_rate: 18,
        created_at: order.created_at,
        translations: order.items[0].product.translations,
        paid_quantity: order.paid_quantity,
        paid_amount: order.paid_amount,
        total_amount: order.total_amount,
        units: order?.items[0]?.product_unit?.map(item => ({
          ...item,
          translations: item.unit.translations,
          is_default: true,
        })),
        property_group_items: order.items[0].property_group_items.map(item => ({
          ...item,
          product_property_group_item_id: item.product_property_group_item_id,
          translations: item.translations,
          additional_price: item.additional_price,
          is_default: true,
        })),
        ingredient_group_items: order.items[0].ingredient_group_items.map(
          item => ({
            ...item,
            product_ingredient_group_item_id:
              item.product_ingredient_group_item_id,
            translations: item.translations,
            additional_price: item.additional_price,
            is_default: true,
          }),
        ),
        description: order.description,
      })) || [];

    setMappedOrders(mappedOrdersData);
    if (orderDetails && !isPackageOrder && !isSelfOrder) {
      setIsAutoCouvert(orderDetails?.is_automatic_couvert);
      setIsAutoServiceCharge(orderDetails?.is_automatic_service_charge);
    }
  }, [orderDetails, groupedOrderDetails]);

  useEffect(() => {
    if (callerCustomer) {
      setPackageTableItems({
        payment: null,
        discount: null,
        customer_id: callerCustomer,
        customer_address_id: callerAddress || '',
      });
    }
  }, [callerCustomer, callerAddress]);

  useEffect(() => {
    let basketTotal = 0;
    let treatTotal = 0;
    let specialBasketTotal = 0;
    let specialBasketTreatTotal = 0;

    const basketItemWithoutTreats = basketItems.filter(item => !item.is_treat);
    const basketItemWithTreats = basketItems.filter(item => item.is_treat);

    const totalBasketPrice = basketItemWithoutTreats.map(item =>
      item.total_price || item.total_price === 0
        ? currency(item.total_price).multiply(item.count)
        : Number(
            currency(
              item?.units?.find(unit => unit.is_default)?.amount.amount || 0,
            )
              .divide(100)
              .multiply(item.count),
          ),
    );

    const totalBasketTreatPrice = basketItemWithTreats.map(item =>
      item.total_price || item.total_price === 0
        ? currency(item.total_price).multiply(item.count)
        : Number(
            currency(
              item?.units?.find(unit => unit.is_default)?.amount.amount || 0,
            )
              .divide(100)
              .multiply(item.count),
          ),
    );

    const totalSpecialOrderPrice = specialOrders
      .filter(item => !item.is_treat)
      .map(item =>
        Number(currency(item.price).divide(100).multiply(item.quantity)),
      );

    const totalSpecialOrderTreatPrice =
      specialOrders
        .filter(item => item.is_treat)
        .map(item =>
          Number(currency(item.price).divide(100).multiply(item.quantity)),
        ) || 0;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < totalBasketPrice.length; i++) {
      basketTotal += Number(totalBasketPrice[i]);
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < totalBasketTreatPrice.length; i++) {
      treatTotal += Number(totalBasketTreatPrice[i]);
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < totalSpecialOrderPrice.length; i++) {
      specialBasketTotal += Number(totalSpecialOrderPrice[i]);
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < totalSpecialOrderTreatPrice.length; i++) {
      specialBasketTreatTotal += Number(totalSpecialOrderTreatPrice[i]);
    }

    // eslint-disable-next-line consistent-return
    const serviceChargeTotal = () => {
      if (orderDetails && !orderDetails?.is_automatic_service_charge) {
        return 0;
      }

      if (orderDetails) {
        if (serviceChargeData && serviceChargeData.type === 'percent') {
          return (
            (Number(
              currency(orderDetails?.total_amount?.amount || 0)
                .divide(100)
                .subtract(
                  currency(
                    Number(orderDetails?.couvert_amount?.amount) || 0,
                  ).divide(100),
                )
                .subtract(
                  currency(
                    Number(orderDetails?.service_charge_amount?.amount) || 0,
                  ).divide(100),
                )
                .add(basketTotal)
                .add(specialBasketTotal),
            ) *
              Number(serviceChargeData?.amount?.amount)) /
            100
          );
        }
      }

      if (!orderDetails && !isAutoServiceCharge) {
        return 0;
      }

      if (serviceChargeData && serviceChargeData.type === 'percent') {
        return (
          (Number(
            currency(basketTotal)
              .add(specialBasketTotal)
              .subtract(currency(totalPrices.discountTotal || 0).divide(100))
              .subtract(
                currency(
                  Number(orderDetails?.service_charge_amount?.amount) || 0,
                ).divide(100),
              ),
          ) *
            Number(serviceChargeData?.amount?.amount || 0)) /
          100
        );
      }

      return (
        Number(serviceChargeData?.amount?.amount) ||
        Number(orderDetails?.service_charge_amount.amount) ||
        0
      );
    };

    setTotalPrices({
      totalPrice: Number(
        currency(
          (orderDetails || groupedOrderDetails)?.total_amount?.amount || 0,
        )
          .divide(100)
          .add(basketTotal)
          .add(specialBasketTotal)
          .add(serviceChargeTotal() / 100)
          .subtract(
            currency(
              Number(
                (orderDetails || groupedOrderDetails)?.total_discount?.amount,
              ) ||
                totalPrices.discountTotal ||
                0,
            ).divide(100),
          )
          .subtract(
            currency(
              Number(
                (orderDetails || groupedOrderDetails)?.service_charge_amount
                  ?.amount,
              ) || 0,
            ).divide(100),
          ),
      ),
      taxTotal:
        Number((orderDetails || groupedOrderDetails)?.tax_amount?.amount) || 0,
      grossTotal:
        Number((orderDetails || groupedOrderDetails)?.gross_amount?.amount) ||
        0,
      paidTotal:
        Number(
          currency(
            (orderDetails || groupedOrderDetails)?.paid_amount?.amount || 0,
          ).divide(100),
        ) || 0,
      discountTotal:
        Number((orderDetails || groupedOrderDetails)?.total_discount?.amount) ||
        totalPrices.discountTotal ||
        0,
      treatTotal: Number(
        currency(
          (orderDetails || groupedOrderDetails)?.treat_amount?.amount || 0,
        )
          .add(treatTotal * 100)
          .add(specialBasketTreatTotal * 100),
      ),
      couvert:
        Number(orderDetails?.couvert_amount?.amount) ||
        totalPrices.couvert ||
        0,
      serviceCharge: serviceChargeTotal(),
    });
  }, [basketItems, specialOrders, orderDetails]);

  useEffect(() => {
    if (
      // @ts-ignore
      (!orderDetails || orderDetails === []) &&
      // !full &&
      !isPackageOrder &&
      !isSelfOrder
    ) {
      if (couvertData?.is_automatic) {
        setCouvertModal(true);
      }
    }
  }, [orderDetails, couvertData]);

  const { data: searchResult, query, search, searching } = usePosSearch();

  const handleIncrease = (
    productId: string,
    itemId: string,
    menuId?: string,
  ) => {
    const product = query
      ? searchResult.find(item => item.id === productId)
      : products.find(item => item.id === productId) ||
        products
          .find(
            p =>
              p.children &&
              p.children.find(
                (child: { id: string }) => child.id === productId,
              ),
          )
          ?.children?.find((c: { id: string }) => c.id === productId);

    if (itemId) {
      increaseCountForBasket(itemId);
    } else if (product && !itemId && menuId) {
      addItemForBasket(product, menuId);
    }
  };

  const handleRemove = (productId: string) => {
    removeItemForBasket(productId);
  };

  const handleDecrease = (productId: string) => {
    const productInBasketIndex = basketItems.findIndex(
      item => item.itemId === productId,
    );

    if (productInBasketIndex > -1) {
      decreaseCountForBasket(productId);
    }
  };

  const handleBackClick = () => {
    if (isPackageOrder || isSelfOrder) {
      navigate('/app/pos/orders');
    } else {
      navigate(`/app/pos?${orderDetails?.table?.section?.id || ''}`);
    }
    clearItemsForBasket();
    channel.postMessage({
      tableData: '',
      created_at: '',
      orders: [],
      totalPrices: null,
    });
  };

  const orders =
    basketItems.map(item => ({
      description: item.description || '',
      is_treat: item.is_treat || false,
      product_id: item.id,
      product_unit_id:
        item.active_unit?.id ||
        item.units.find(unit => unit.is_default)?.id ||
        item?.units[0]?.id ||
        '',
      quantity: item.count,
      product_ingredient_group_item_ids: item.non_ingredient_item_id || [],
      product_property_group_item_ids: item.active_single_property_id
        ? item.active_multiple_property_id
          ? [
              ...item.active_multiple_property_id,
              item.active_single_property_id,
            ]
          : [item.active_single_property_id]
        : item?.active_multiple_property_id || [],
      product_menus:
        item?.product_menus?.map(productItem => ({
          product_menu_id: productItem.id,
          product_menu_items: productItem.menu_items.map(menuItem => ({
            description: menuItem.description || '',
            product_menu_item_id: menuItem.id,
            product_ingredient_group_item_ids:
              menuItem.non_ingredient_item_id || [],
            product_property_group_item_ids: menuItem.active_single_property_id
              ? menuItem.active_multiple_property_id
                ? [
                    ...menuItem.active_multiple_property_id,
                    menuItem.active_single_property_id,
                  ]
                : [menuItem.active_single_property_id]
              : menuItem?.active_multiple_property_id || [],
            quantity: menuItem.count,
          })),
        })) || [],
    })) || [];

  const isAvailableToOrder =
    (orderDetails || groupedOrderDetails)?.status_id !== 5 &&
    (orderDetails || groupedOrderDetails)?.status_id !== 7 &&
    (orderDetails || groupedOrderDetails)?.status_id !== 16;

  const isAvailableToOrderNonPickedUp =
    (orderDetails || groupedOrderDetails)?.status_id !== 7 &&
    (orderDetails || groupedOrderDetails)?.status_id !== 16;

  const handleSubmit = async () => {
    if (id) {
      try {
        if (
          !basketItems.length &&
          !specialOrders.length &&
          isAvailableToOrder
        ) {
          handleBackClick();
        } else if (isAvailableToOrder) {
          await createTableOrder(
            {
              table_id: id,
              customer_id: extraParams.customerId,
              orders,
              special_orders: specialOrders.length ? specialOrders : null,
            },
            { ...extraParams, transactionId: orderDetails?.id || '' },
          );
          handleBackClick();
        } else {
          enqueueSnackbar(t('orderModal.statusSubmitError'), {
            variant: 'warning',
          });
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  };

  const handlePackageSubmit = async () => {
    try {
      if (!basketItems.length && !specialOrders.length && isAvailableToOrder) {
        handleBackClick();
      } else if (!packageTableItems.payment && !id) {
        setSubmittedPackage(true);
        setPackagePaymentModal(true);
      } else if (
        (!packageTableItems.customer_id.length ||
          !packageTableItems.customer_address_id.length) &&
        !id
      ) {
        setSubmittedPackage(true);
        setCustomerModal(true);
      } else if (isAvailableToOrder) {
        await createPackageOrder({
          payment_type_id: packageTableItems?.payment?.payment_type_id || 0,
          discount: packageTableItems?.discount || null,
          customer_address_id: packageTableItems?.customer_address_id,
          customer_id: packageTableItems?.customer_id,
          orders,
          special_orders: specialOrders.length ? specialOrders : null,
          transaction_id: id || extraParamsId || '',
          sales_channel_id: salesChannelId || '',
        });
        navigate(`/app/pos/orders`);
        clearItemsForBasket();
        setPackageTableItems({
          payment: null,
          discount: null,
          customer_id: '',
          customer_address_id: '',
        });
      } else {
        enqueueSnackbar(t('orderModal.statusSubmitError'), {
          variant: 'warning',
        });
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const handleSelfSubmit = async (selfPayment: SelfPayment | null) => {
    try {
      if (!basketItems.length && !specialOrders.length && isAvailableToOrder) {
        // enqueueSnackbar(t('orderModal.emptyPackageBasket'), {
        //   variant: 'warning',
        // });
        // setSubmittedPackage(false);
        handleBackClick();
      } else if (isAvailableToOrder) {
        await createSelfOrder({
          payment: selfPayment,
          discount: packageTableItems?.discount || null,
          customer_address_id: packageTableItems?.customer_address_id,
          customer_id: packageTableItems?.customer_id,
          orders,
          special_orders: specialOrders.length ? specialOrders : null,
          // @ts-ignore
          transaction_id: id || extraParamsId || '',
          sales_channel_id: salesChannelId || '',
        });
        setSelectedCustomer({ first_name: '', last_name: '' });
        setSpecialOrders([]);
        setExtraParams({ ...initialExtraParams, customerId: '' });
        setMappedOrders([]);
        setTotalPrices({
          totalPrice: 0,
          taxTotal: 0,
          grossTotal: 0,
          paidTotal: 0,
          discountTotal: 0,
          treatTotal: 0,
          couvert: 0,
          serviceCharge: 0,
        });
        setPackageTableItems({
          payment: null,
          discount: null,
          customer_id: '',
          customer_address_id: '',
        });
        clearItemsForBasket();
        setCustomerModalId('');
      } else {
        enqueueSnackbar(t('orderModal.statusSubmitError'), {
          variant: 'warning',
        });
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  // eslint-disable-next-line consistent-return
  const handlePaymentClick = () => {
    if (!isAvailableToOrderNonPickedUp) {
      enqueueSnackbar(t('orderModal.statusPaymentError'), {
        variant: 'warning',
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (basketItems.length || specialOrders.length) > 0 && (!isSelfOrder || id)
        ? setDeleteConfirmation('okey')
        : !(basketItems.length || specialOrders.length) && isSelfOrder && !id
        ? enqueueSnackbar(t('orderModal.emptyPackageBasket'), {
            variant: 'warning',
          })
        : setPaymentModal(true);
    }
  };

  const handleFastPaymentClick = () => {
    if (!isAvailableToOrderNonPickedUp) {
      enqueueSnackbar(t('orderModal.statusPaymentError'), {
        variant: 'warning',
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !(basketItems.length || specialOrders.length) && isSelfOrder && !id
        ? enqueueSnackbar(t('orderModal.emptyPackageBasket'), {
            variant: 'warning',
          })
        : setFastPaymentModal(true);
    }
  };

  const settedCustomerId = (orderDetails || groupedOrderDetails)?.customer?.id;
  const settedCustomerAddressId = (orderDetails || groupedOrderDetails)
    ?.customer_address?.id;

  if (categoryError) {
    return <ErrorState message="Category Error" />;
  }

  if (productError) {
    return <ErrorState message="Product Error" />;
  }

  const handleServiceCharge = async (isAuto: boolean) => {
    if (isAuto !== isAutoServiceCharge) {
      await changeServiceCharge(id || '', isAuto);
      if (id) {
        await getOrderDetails(id);
      }
    }
  };

  const handleCountChange = async (
    guestsCount: number,
    autoCouvert: boolean,
  ) => {
    await changeGuestCount(
      (!(isPackageOrder || isSelfOrder) &&
        (orderDetails || groupedOrderDetails)?.table?.id) ||
        id ||
        '',
      guestsCount,
      (orderDetails || groupedOrderDetails)?.id || id || extraParamsId || '',
      isSelfOrder ? 2 : isPackageOrder ? 3 : 1,
      autoCouvert,
    );
    if (id && !isSelfOrder && !isPackageOrder) {
      await getOrderDetails(id);
    }
  };

  useEffect(() => {
    const selectedId = customers?.find(
      item => item?.id === extraParams.customerId,
    );
    setSelectedCustomer({
      first_name: selectedId?.first_name,
      last_name: selectedId?.last_name,
    });
  }, [extraParams]);

  useEffect(() => {
    if (
      packageTableItems.customer_id.length &&
      packageTableItems.payment &&
      (basketItems.length > 0 || specialOrders.length) &&
      submittedPackage
    ) {
      handlePackageSubmit();
    }
  }, [packageTableItems.customer_id, packageTableItems.payment]);

  useEffect(() => {
    channel.postMessage({
      tableName:
        !isPackageOrder && !isSelfOrder
          ? (orderDetails || groupedOrderDetails)?.table?.name ||
            extraParams.tableName ||
            originalTableName
          : isPackageOrder
          ? t('orderModal.packageOrderName')
          : t('orderModal.selfOrderName') || '',
      created_at:
        (orderDetails || groupedOrderDetails)?.created_at || Date.now(),
      orders: [...specialOrders, ...basketItems, ...mappedOrders],
      tableType: isPackageOrder ? 3 : isSelfOrder ? 2 : 1,
      totalPrices,
    });
  }, [mappedOrders, basketItems, totalPrices]);

  return (
    <Dialog transitionDuration={0} open fullScreen onClose={() => navigate(-1)}>
      <Stack sx={styles.container} direction={{ xs: 'column', sm: 'row' }}>
        <LeftColumn
          handleCountChange={handleCountChange}
          selectedCustomer={selectedCustomer}
          list={[...mappedOrders, ...basketItems]}
          isAvailableToOrder={isAvailableToOrder}
          specialOrderList={specialOrders}
          setSpecialOrders={setSpecialOrders}
          originalTableName={originalTableName}
          tableName={
            (orderDetails || groupedOrderDetails)?.table?.name ||
            extraParams.tableName
          }
          colorStatus={orderDetails?.color_status}
          onTableNameChange={tableName => {
            if (tableName !== '' && tableName !== extraParams.tableName) {
              changeTableName(
                (orderDetails || groupedOrderDetails)?.table?.id || id || '',
                tableName,
              );
              setExtraParams(prev => ({ ...prev, tableName }));
            }
          }}
          onBackClick={handleBackClick}
          onIncrease={(productId: string, itemId: string) =>
            handleIncrease(productId, itemId)
          }
          onDiscountPress={() => {
            if (!isAvailableToOrderNonPickedUp) {
              enqueueSnackbar(t('orderModal.statusDiscountError'), {
                variant: 'warning',
              });
            } else {
              setDiscountModal(true);
            }
          }}
          onPaymentPress={handlePaymentClick}
          onFastPaymentPress={handleFastPaymentClick}
          onPackagePaymentPress={() => setPackagePaymentModal(true)}
          onDecrease={productId => handleDecrease(productId)}
          onDelete={productId => handleRemove(productId)}
          onAction={productId => console.log('onAction', productId)}
          onCustomerAdd={() => setCustomerModal(true)}
          submitting={submitting || orderSubmitting}
          totalPrices={totalPrices}
          onSubmit={handleSubmit}
          onPackageSubmit={() => {
            setSubmittedPackage(true);
            handlePackageSubmit();
          }}
          onSelfSubmit={handleSelfSubmit}
          loading={orderDetailsLoading || groupedOrderDetailsLoading}
          error={orderDetailsError || groupedOrderDetailsError}
          extraParams={extraParams}
          onSelectionModal={onSelectionModal}
          setOnSelectionModal={setOnSelectionModal}
          onSelectionMenuModal={onSelectionMenuModal}
          setOnSelectionMenuModal={setOnSelectionMenuModal}
          isActiveTransaction={!!mappedOrders.length}
          currencyCode={
            (mappedOrders.length
              ? (orderDetails || groupedOrderDetails)?.total_amount?.currency
              : meProfile?.currency?.code) || ''
          }
          onRemove={(removedPrice: number, orderId: string) => {
            const isTreat = mappedOrders.find(
              item => item.id === orderId,
            )?.is_treat;

            setTotalPrices({
              ...totalPrices,
              totalPrice: totalPrices.totalPrice - removedPrice,
              treatTotal: isTreat
                ? totalPrices.treatTotal - removedPrice * 100
                : totalPrices.treatTotal,
            });
            if (id && full && !isPackageOrder && !isSelfOrder) {
              getOrderDetails(id);
            }
            if (id && (isPackageOrder || isSelfOrder)) {
              getGroupedOrderDetails(id);
            }
          }}
          onTreat={() => {
            if (id && full && !isPackageOrder && !isSelfOrder) {
              getOrderDetails(id);
            }
            if (id && (isPackageOrder || isSelfOrder)) {
              getGroupedOrderDetails(id);
            }
          }}
          isPackageOrder={isPackageOrder || false}
          isSelfOrder={isSelfOrder || false}
          selectedPaymentName={
            packageTableItems.payment?.translation
              ? getActiveLanguage(packageTableItems.payment?.translation).name
              : ''
          }
          onServiceChargePress={handleServiceCharge}
          isAutoCouvert={isAutoCouvert}
          isAutoServiceCharge={isAutoServiceCharge}
        />
        <ContentColumn
          categories={categories}
          activeCategory={activeCategoryId}
          onCategoryChange={categoryId => setActiveCategoryId(categoryId)}
          products={query ? searchResult : products}
          onIncrease={(productId: string, itemId: string, menuId: string) => {
            handleIncrease(productId, itemId, menuId);
          }}
          onDescriptionPress={() => setDescriptionModal(true)}
          onGuestsPress={() => setGuestsModal(true)}
          onCouvertPress={() => setCouvertModal(true)}
          onServiceChargePress={handleServiceCharge}
          onCancelPress={() => setCancelModal(true)}
          onSpecialOrderPress={() => setSpecialOrderModal(true)}
          query={query}
          onQueryChange={search}
          loading={categoryLoading || productLoading || searching}
          extraParams={extraParams}
          setOnSelectionModal={setOnSelectionModal}
          setOnSelectionMenuModal={setOnSelectionMenuModal}
          orderId={(orderDetails || groupedOrderDetails)?.id || ''}
          currencyCode={
            (mappedOrders.length
              ? (orderDetails || groupedOrderDetails)?.total_amount?.currency
              : meProfile?.currency?.code) || ''
          }
          isPackageOrder={isPackageOrder || false}
          isSelfOrder={isSelfOrder || false}
        />
      </Stack>
      <GuestsModal
        guestCount={(orderDetails || groupedOrderDetails)?.guest_count}
        open={guestsModal}
        onClose={() => setGuestsModal(false)}
        originalCount={extraParams.guestsCount}
        onCountChange={guestsCount => {
          handleCountChange(guestsCount, isAutoCouvert);
          setExtraParams(prev => ({ ...prev, guestsCount }));
        }}
        isSelfOrder={isSelfOrder}
      />
      <CouvertModal
        guestCount={(orderDetails || groupedOrderDetails)?.guest_count}
        open={couvertModal}
        onClose={() => setCouvertModal(false)}
        originalCount={extraParams.guestsCount}
        onCountChange={guestsCount => {
          handleCountChange(guestsCount, true);
          setExtraParams(prev => ({ ...prev, guestsCount }));
        }}
        isSelfOrder={isSelfOrder}
      />
      <DescriptionModal
        open={descriptionModal}
        descriptionValue={(orderDetails || groupedOrderDetails)?.description}
        onClose={() => setDescriptionModal(false)}
        originalDescription={extraParams.note}
        onDescriptionChange={note => {
          if (note !== extraParams.note) {
            changeDescription(
              note,
              isSelfOrder || isPackageOrder
                ? ''
                : (orderDetails || groupedOrderDetails)?.table?.id || id || '',
              (orderDetails || groupedOrderDetails)?.id ||
                id ||
                extraParamsId ||
                '',
              isSelfOrder ? 2 : isPackageOrder ? 3 : 1,
            );
            setExtraParams(prev => ({ ...prev, note }));
          }
        }}
      />
      <SpecialOrderModal
        open={specialOrderModal}
        onClose={() => setSpecialOrderModal(false)}
        specialOrders={specialOrders}
        setSpecialOrders={setSpecialOrders}
      />
      <CustomerModal
        open={customerModal}
        settedCustomerId={settedCustomerId || packageTableItems.customer_id}
        settedCustomerAddressId={
          settedCustomerAddressId || packageTableItems.customer_address_id
        }
        onClose={() => setCustomerModal(false)}
        unSubmittedPackage={() => setSubmittedPackage(false)}
        originalCustomerId={extraParams.customerId}
        onCustomerIdChange={(
          customerId?: string,
          customerAddressId?: string,
        ) => {
          if (isPackageOrder && customerId && customerAddressId && !id) {
            setPackageTableItems({
              ...packageTableItems,
              customer_id: customerId,
              customer_address_id: customerAddressId,
            });
          } else if (isSelfOrder && !id) {
            setPackageTableItems({
              ...packageTableItems,
              customer_id: customerId || '',
              customer_address_id: customerAddressId || '',
            });
          } else if ((!isPackageOrder && !isSelfOrder && !id) || id) {
            changeOrderCustomer(
              isSelfOrder || isPackageOrder
                ? ''
                : (orderDetails || groupedOrderDetails)?.table?.id || id || '',
              (orderDetails || groupedOrderDetails)?.id ||
                id ||
                extraParamsId ||
                '',
              isSelfOrder ? 2 : isPackageOrder ? 3 : 1,
              customerId,
              customerAddressId,
            );
          }
          setExtraParams(prev => ({ ...prev, customerId: customerId || '' }));
        }}
        isTableOrder={
          orderDetails?.type_id === 1 || (!isPackageOrder && !isSelfOrder)
        }
        isPackageOrder={isPackageOrder}
        customerId={customerModalId}
        setCustomerId={setCustomerModalId}
      />
      <CancelModal
        open={cancelModal}
        onClose={() => setCancelModal(false)}
        orderId={(orderDetails || groupedOrderDetails)?.id || ''}
        onBackClick={handleBackClick}
      />
      <PaymentModal
        open={paymentModal}
        onClose={() => setPaymentModal(false)}
        list={isSelfOrder ? basketItems : [...mappedOrders]}
        discountTotal={totalPrices.discountTotal}
        removeDiscount={() =>
          setTotalPrices({
            ...totalPrices,
            totalPrice:
              totalPrices.totalPrice + totalPrices.discountTotal / 100,
            discountTotal: 0,
          })
        }
        totalPrice={Number(
          currency(totalPrices?.totalPrice).subtract(totalPrices?.paidTotal),
        )}
        tableId={(orderDetails || groupedOrderDetails)?.id || ''}
        waiterName={`${
          (orderDetails || groupedOrderDetails)?.created_by?.first_name
        } ${(orderDetails || groupedOrderDetails)?.created_by?.last_name}`}
        tableName={(orderDetails || groupedOrderDetails)?.table?.name || ''}
        onBackClick={handleBackClick}
        paidPayments={(orderDetails || groupedOrderDetails)?.payments}
        currencyCode={
          (orderDetails || groupedOrderDetails)?.total_amount?.currency || ''
        }
        isPackageOrder={isPackageOrder}
        handleSelfSubmit={handleSelfSubmit}
        isSelfOrder={isSelfOrder}
        selectedOrderTypeId={
          (orderDetails || groupedOrderDetails)?.preset_payment_type?.id || 0
        }
      />
      <FastPaymentModal
        open={fastPaymentModal}
        onClose={() => setFastPaymentModal(false)}
        totalPrice={Number(
          currency(totalPrices?.totalPrice).subtract(totalPrices?.paidTotal),
        )}
        tableId={(orderDetails || groupedOrderDetails)?.id || ''}
        onBackClick={handleBackClick}
        currencyCode={
          (orderDetails || groupedOrderDetails)?.total_amount?.currency || ''
        }
        handleSelfSubmit={handleSelfSubmit}
        isSelfOrder={isSelfOrder}
        isSelfCreate={isSelfOrder && !id}
      />
      <PackagePaymentModal
        open={packagePaymentModal}
        onClose={() => setPackagePaymentModal(false)}
        unSubmittedPackage={() => setSubmittedPackage(false)}
        handleSelect={(
          payment_type_id: number,
          translation: NameTranslation[],
        ) => {
          setPackageTableItems({
            ...packageTableItems,
            payment: { payment_type_id, translation },
          });
          if (submittedPackage && !packageTableItems.customer_id)
            setCustomerModal(true);
        }}
      />
      <DiscountModal
        open={discountModal}
        onClose={() => setDiscountModal(false)}
        tableId={(orderDetails || groupedOrderDetails)?.id || ''}
        discountTotal={totalPrices.discountTotal}
        priceTotal={totalPrices.totalPrice + totalPrices.discountTotal / 100}
        purePrice={
          Number((orderDetails || groupedOrderDetails)?.total_amount?.amount) /
            100 -
          totalPrices.couvert / 100 -
          totalPrices.serviceCharge / 100
        }
        currencyCode={
          (orderDetails || groupedOrderDetails)?.total_amount?.currency || ''
        }
        isPackageOrder={isPackageOrder}
        isSelfOrder={isSelfOrder}
        onRemove={() =>
          setTotalPrices({
            ...totalPrices,
            totalPrice:
              totalPrices.totalPrice + totalPrices.discountTotal / 100,
            discountTotal: 0,
          })
        }
        onTableSelect={(discount_type: number, value: number) => {
          setPackageTableItems({
            ...packageTableItems,
            discount:
              discount_type > 0 ? { discount_type, value: value * 100 } : null,
          });
          setTotalPrices({
            ...totalPrices,
            totalPrice:
              Number(totalPrices.totalPrice) +
              Number(totalPrices.discountTotal / 100) -
              (discount_type === 1
                ? (value * totalPrices.totalPrice) / 100
                : value),
            discountTotal:
              discount_type === 1
                ? value * totalPrices.totalPrice
                : value * 100,
          });
        }}
        onSubmit={(price: number) => {
          setTotalPrices({
            ...totalPrices,
            totalPrice:
              Number(totalPrices.totalPrice) +
              Number(totalPrices.discountTotal / 100) -
              price,
            discountTotal: price * 100,
          });
        }}
        id={id || ''}
      />
      <ConfirmationModal
        title={t('orderModal.payment_screen')}
        titleIconName="QuestionMarkOutlined"
        description={t('orderModal.unsaved_screen')}
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        positiveAction={t('orderModal.guestsModal.confirm')}
        onPositiveClick={() => {
          setPaymentModal(true);
          setDeleteConfirmation(null);
          setSpecialOrders([]);
          clearItemsForBasket();
        }}
        negativeAction={t('orderModal.guestsModal.cancel')}
        onNegativeClick={() => setDeleteConfirmation(null)}
      />
    </Dialog>
  );
}

export default OrderModal;
