const elapseTime = (duration: string, countryCode: string) => {
  const totalElapsed = Date.now() - Date.parse(duration);

  const seconds: string | number = Math.floor((totalElapsed / 1000) % 60);
  const minutes: string | number = Math.floor(
    (totalElapsed / (1000 * 60)) % 60,
  );
  const hours: string | number = Math.floor(
    (totalElapsed / (1000 * 60 * 60)) % 24,
  );
  const days: string | number = Math.floor(
    (totalElapsed / (1000 * 60 * 60 * 24)) % 365,
  );

  const secondsString =
    days === 0 && hours === 0 && minutes === 0 && seconds < 60
      ? `${countryCode === 'tr' ? 'az önce' : 'just now'}`
      : `${seconds} ${countryCode === 'tr' ? 'saniye' : 'seconds'}`;
  const minutesString =
    days === 0 && minutes > 0
      ? `${minutes} ${
          countryCode === 'tr' ? 'dakika' : minutes === 1 ? 'minute' : 'minutes'
        }`
      : '';
  const daysString =
    days > 0
      ? `${days} ${countryCode === 'tr' ? 'gün' : days === 1 ? 'day' : 'days'}`
      : '';
  const hoursString =
    hours > 0
      ? `${hours} ${
          countryCode === 'tr' ? 'saat' : hours === 1 ? 'hour' : 'hours'
        }`
      : '';

  return `${daysString} ${hoursString} ${minutesString} ${
    days === 0 && hours === 0 ? secondsString : ''
  }`;
};

export default elapseTime;
