/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';

/** Components */
import { Box, Grid } from '@mui/material';
import Modal from '../../../../components/Modal';

/** Hooks */
import { SuspiciousTransaction } from '../../../../services/api/operations/fraud/types';
import getActiveLanguage from '../../../../helpers/getActiveLanguage';
import { NameTranslation } from '../../../../models/General';
import Button from '../../../../components/Button';

interface Props {
  open: boolean;
  onClose: () => void;
  item: SuspiciousTransaction | null;
}

function SuspiciousTransactionModal({ open, onClose, item }: Props) {
  const { t } = useTranslation('frauds');

  return (
    <Modal
      title={`#${item?.order_transaction.transaction_number.toString() || ''} - 
      ${
        item?.order_transaction.type_id === 1
          ? item?.order_transaction?.table?.name
          : item?.order_transaction.type
      }`}
      open={open}
      onClose={onClose}
      negativeAction={t('suspiciousTransactions.modal.close')}
      onNegativeClick={onClose}
      positionRight
      chip={{
        color: !item?.order_transaction?.ended_at ? '#CECE5A' : '#C70039',
        title: !item?.order_transaction?.ended_at
          ? t('suspiciousTransactions.modal.opened')
          : t('suspiciousTransactions.modal.closed'),
      }}
    >
      <Box>
        {item?.order_transaction?.ordersWithTrashed?.map(order => (
          <Box
            sx={{
              background: '#fff',
              border: '1px solid #e3e3e7',
              padding: '15px 10px',
            }}
          >
            <Grid mb={1} container justifyContent="space-between">
              <Grid xs={9}>
                <span style={{ fontSize: '13px', color: 'gray' }}>
                  {order.quantity}x
                </span>{' '}
                {order.product
                  ? order?.product?.translations
                    ? getActiveLanguage(order?.product?.translations)?.name
                    : ''
                  : order.has_special_product
                  ? order?.items[0]?.product_name
                  : order?.items[0]?.product?.translations
                  ? getActiveLanguage(order?.items[0]?.product?.translations)
                      ?.name
                  : ''}
              </Grid>
              <Grid>
                <Grid
                  sx={{
                    textDecoration:
                      order.is_treat || order.deleted_at
                        ? 'line-through'
                        : 'none',
                  }}
                >
                  {order?.total_amount.formatted}
                </Grid>
                {order.is_treat && (
                  <Grid>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: '20px',
                        width: '50px',
                        height: '24px',
                        fontSize: '10px',
                        p: 0,
                        minWidth: '10px',
                      }}
                      color="error"
                      size="small"
                    >
                      {t('suspiciousTransactions.treat')}
                    </Button>
                  </Grid>
                )}
                {order.deleted_at && (
                  <Grid>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: '20px',
                        width: '50px',
                        height: '24px',
                        fontSize: '10px',
                        p: 0,
                        minWidth: '10px',
                      }}
                      color="error"
                      size="small"
                    >
                      {t('suspiciousTransactions.deleted')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
        ))}
        <Box
          sx={{
            background: '#f6f6f7',
            border: '1px solid #e3e3e7',
            borderRadius: '0 0 10px 10px',
            padding: '15px 10px',
          }}
        >
          <Grid mb={1} container justifyContent="space-between">
            <Grid>{t('suspiciousTransactions.modal.totalAmount')}</Grid>
            <Grid>
              {item?.order_transaction?.total_amount_after_discount.formatted}
            </Grid>
          </Grid>
          <Grid mb={1} container justifyContent="space-between">
            <Grid>{t('suspiciousTransactions.modal.paidAmount')}</Grid>
            <Grid>{item?.order_transaction?.paid_amount.formatted}</Grid>
          </Grid>
          <Grid mb={1} container justifyContent="space-between">
            <Grid>{t('suspiciousTransactions.modal.totalDiscount')}</Grid>
            <Grid>{item?.order_transaction?.total_discount.formatted}</Grid>
          </Grid>
          <Grid mb={1} container justifyContent="space-between">
            <Grid>{t('suspiciousTransactions.modal.taxAmount')}</Grid>
            <Grid>{item?.order_transaction?.tax_amount.formatted}</Grid>
          </Grid>
          {Number(item?.order_transaction?.service_charge_amount.amount) >
            0 && (
            <Grid mb={1} container justifyContent="space-between">
              <Grid>
                {t('suspiciousTransactions.modal.serviceChargeAmount')}
              </Grid>
              <Grid>
                {item?.order_transaction?.service_charge_amount.formatted}
              </Grid>
            </Grid>
          )}
          {Number(item?.order_transaction?.couvert_amount.amount) > 0 && (
            <Grid mb={1} container justifyContent="space-between">
              <Grid>{t('suspiciousTransactions.modal.couvertAmount')}</Grid>
              <Grid>{item?.order_transaction?.couvert_amount.formatted}</Grid>
            </Grid>
          )}
          <Grid mb={1} container justifyContent="space-between">
            <Grid>{t('suspiciousTransactions.modal.treatAmount')}</Grid>
            <Grid>{item?.order_transaction?.treat_amount.formatted}</Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid>{t('suspiciousTransactions.modal.paymentTypes')}</Grid>
            <Grid>
              {item?.order_transaction?.payments.map(
                (payment: {
                  amount: { formatted: string };
                  payment_type: { translations: NameTranslation[] };
                }) => (
                  <Grid>
                    {`${
                      getActiveLanguage(payment?.payment_type?.translations)
                        ?.name
                    }(${payment?.amount?.formatted})`}
                  </Grid>
                ),
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}

export default SuspiciousTransactionModal;
