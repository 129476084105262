import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { isApiError } from '../../services/api/types';
import {
  getPrinterThunk,
  getPrinterListThunk,
} from '../../store/slices/settingsSlice/printerSettings';
import {
  getBranchSettingsThunk,
  getBranchFilterSettingsThunk,
  getQrMenuTemplatesThunk,
  getChannelSalesThunk,
} from '../../store/slices/settingsSlice/restaurantSettings';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  BranchData,
  PaymentFilterType,
  QrMenuUpdate,
  UpdateBranchParams,
  UpdateCouvertParams,
  UpdatePaymentTypesParams,
  UpdateServiceChargeParams,
} from '../../services/api/settings/restauranSettings/types';
import { CreateAndUpdatePrinterSettingsParams } from '../../services/api/settings/printerSettings/types';
import api from '../../services/api';
import { meThunk, meWithPaymentTypesThunk } from '../../store/slices/authSlice';
import {
  removeSalesChannelSlice,
  updateCouvertDataSlice,
  updateGeneralSettingsSlice,
  updateServiceChargeDataSlice,
} from '../../store/slices/settingsSlice/restaurantSettings/restaurantSettingsSlice';
import arrayCatcher from '../../helpers/errorCatcher';
import { Couvert } from '../../store/slices/settingsSlice/restaurantSettings/types';
import {
  addPrinterSlice,
  removePrinterSlice,
  updatePrinterSlice,
} from '../../store/slices/settingsSlice/printerSettings/printerSettingsSlice';
import { FormDataType } from '../../pages/CustomerDisplay/types';
import { IdAndName } from '../../models/General';

const useSettings = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const meProfile = useAppSelector(state => state.auth.profile);

  const branchSettings: BranchData | null = useAppSelector(
    state => state.restaurantSettings.branchSettings,
  );
  const channelSales: IdAndName[] = useAppSelector(
    state => state.restaurantSettings.channelSales,
  );
  const branchFilterSettings: PaymentFilterType[] | null = useAppSelector(
    state => state.restaurantSettings.branchFilterSettings,
  );
  const branchSettingsError = useAppSelector(
    state => state.restaurantSettings.error,
  );
  const branchSettingsLoading = useAppSelector(
    state => state.restaurantSettings.loading,
  );

  const printerSettings = useAppSelector(
    state => state.printerSettings.printer,
  );
  const printers = useAppSelector(state => state.printerSettings.printers);
  const printersMeta = useAppSelector(state => state.printerSettings.meta);
  const printerSettingsError = useAppSelector(
    state => state.printerSettings.error,
  );
  const printerSettingsLoading = useAppSelector(
    state => state.printerSettings.loading,
  );

  const qrMenuTemplates = useAppSelector(
    state => state.restaurantSettings.qrMenuTemplates,
  );

  const couvert = useAppSelector(state => state.restaurantSettings.couvert);

  const serviceCharge = useAppSelector(
    state => state.restaurantSettings.serviceCharge,
  );

  const [serviceChargeData, setServiceChargeData] = useState<Couvert | null>(
    null,
  );
  const [couvertData, setCouvertData] = useState<Couvert | null>(null);
  const [isUninstalled, setIsUninstalled] = useState<boolean>(false);

  const [timeZones, setTimeZones] = useState<{ id: string; text: string }[]>(
    [],
  );

  const [displayScreenData, setDisplayScreenData] =
    useState<FormDataType | null>(null);
  const [onlineFoodDelivery, setOnlineFoodDelivery] = useState({
    id: '',
    logo_url: '',
    domain_name: '',
    categories: [],
    payment_types: [],
    sales_channel_id: '',
    phone_number: null,
    address: null,
    facebook_url: '',
    instagram_url: '',
    twitter_url: '',
  });

  const [staticPageInfos, setStaticPageInfos] = useState<any[]>([]);

  const fetchMe = () => {
    dispatch(meThunk());
  };

  const fetchMeWithPaymentTypes = () => {
    dispatch(meWithPaymentTypesThunk());
  };

  const fetchTimeZones = async () => {
    try {
      const response = await api.settings.generalSettings.getTimeZones();
      const timezoneData = response.data.data;
      const newArray: { id: string; text: string }[] = [];

      Object.keys(timezoneData).forEach(key => {
        newArray.push({ id: key, text: timezoneData[key] });
      });

      setTimeZones(newArray);
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      throw err;
    }
  };

  const updateGeneralSettings = async (params: UpdateBranchParams) => {
    try {
      const response = await api.settings.generalSettings.update(params);
      const result = response.data.data;
      dispatch(updateGeneralSettingsSlice(result));

      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      throw err;
    }
  };

  const updatePaymentTypesSettings = async (
    params: UpdatePaymentTypesParams,
  ) => {
    try {
      await api.settings.generalSettings.updatePaymentTypes(params);
      await fetchMe();

      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      throw err;
    }
  };

  const getCouvert = async () => {
    try {
      const response = await api.settings.generalSettings.getCouvert();
      dispatch(updateCouvertDataSlice(response.data.data));
      setCouvertData(response.data.data);
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      setIsUninstalled(err?.code === 1003);
      throw err;
    }
  };

  const getServiceCharge = async () => {
    try {
      const response = await api.settings.generalSettings.getServiceCharge();
      dispatch(updateServiceChargeDataSlice(response.data.data));
      setServiceChargeData(response.data.data);
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      setIsUninstalled(err?.code === 1003);
      throw err;
    }
  };

  const updateCouvert = async (params: UpdateCouvertParams) => {
    try {
      const response = await api.settings.generalSettings.updateCouvert(params);
      dispatch(updateCouvertDataSlice(response.data.data));
      setCouvertData(response.data.data);

      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      throw err;
    }
  };

  const updateServiceCharge = async (params: UpdateServiceChargeParams) => {
    try {
      const response = await api.settings.generalSettings.updateServiceCharge(
        params,
      );
      dispatch(updateServiceChargeDataSlice(response.data.data));
      setServiceChargeData(response.data.data);

      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      throw err;
    }
  };

  const fetchGeneralSettings = () => {
    if (meProfile?.activeBranch?.id) {
      dispatch(getBranchSettingsThunk(meProfile?.activeBranch?.id || ''));
    }
  };

  const fetchChannelSales = () => {
    dispatch(getChannelSalesThunk());
  };

  const addChannelSales = async (name: string) => {
    await api.settings.generalSettings.createChannelSales(name);
  };

  const updateChannelSales = async (id: string, name: string) => {
    await api.settings.generalSettings.updateChannelSales(id, name);
  };

  const deleteChannelSales = async (id: string) => {
    try {
      const response = await api.settings.generalSettings.removeChannelSales(
        id,
      );
      const result = response.data.data;
      dispatch(removeSalesChannelSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      arrayCatcher(err?.code);
    }
  };

  const fetchGeneralFilterSettings = (isOpen: boolean) => {
    dispatch(getBranchFilterSettingsThunk(isOpen));
  };

  const createPrinterSettings = async (
    params: CreateAndUpdatePrinterSettingsParams,
  ) => {
    try {
      const response = await api.settings.printerSettings.create(params);
      const result = response.data.data;
      dispatch(addPrinterSlice(result));

      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    }
  };

  const updatePrinterSettings = async (
    params: CreateAndUpdatePrinterSettingsParams,
  ) => {
    try {
      const response = await api.settings.printerSettings.update(params);
      const result = response.data.data;
      dispatch(updatePrinterSlice(result));

      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    }
  };

  const removePrinter = async (id: string) => {
    try {
      const response = await api.settings.printerSettings.remove(id);
      const result = response.data.data;
      dispatch(removePrinterSlice(result));
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  const fetchPrinter = (id: string) => {
    dispatch(getPrinterThunk(id));
  };

  const fetchPrinters = (params: { page: number }) => {
    try {
      dispatch(getPrinterListThunk(params));
    } catch (err: any) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
      setIsUninstalled(err?.code === 1003);
    }
  };

  const fetchQrMenuTemplates = () => {
    try {
      dispatch(getQrMenuTemplatesThunk());
    } catch (err: any) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
      setIsUninstalled(err?.code === 1003);
    }
  };

  const setQrMenuTemplates = async (id: string, params: QrMenuUpdate) => {
    try {
      await api.settings.generalSettings.setQrMenuTemplates(id, params);

      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  const downloadQrMenuTemplates = async (type: string) => {
    try {
      await api.settings.generalSettings.downloadQrMenuTemplates(type);

      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  const fetchDisplayScreen = async () => {
    try {
      const result = await api.settings.generalSettings.getDisplayScreen();
      setDisplayScreenData(result.data.data);
    } catch (err: any) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
      setIsUninstalled(err?.code === 1003);
    }
  };

  const fetchOnlineFoodOrderInfo = async () => {
    try {
      const result = await api.settings.generalSettings.getOnlineFoodDelivery();
      setOnlineFoodDelivery(result.data.data);
    } catch (err: any) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
      setIsUninstalled(err?.code === 1003);
    }
  };

  const fetchStaticPageInfo = async () => {
    try {
      const result =
        await api.settings.generalSettings.onlineFoodDeliveryGetInfo();
      setStaticPageInfos(result.data.data);
    } catch (err: any) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
      setIsUninstalled(err?.code === 1003);
    }
  };

  const setDisplayScreen = async (params: any) => {
    try {
      await api.settings.generalSettings.updateDisplayScreen(params);

      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  const updateOnlineFoodDelivery = async (params: any) => {
    try {
      await api.settings.generalSettings.updateOnlineFoodDelivery(params);

      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  const onlineFoodDeliverySetInfo = async (params: any) => {
    try {
      await api.settings.generalSettings.onlineFoodDeliverySetInfo(params);

      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  const faqBannerDelete = async (id: string) => {
    try {
      await api.settings.generalSettings.faqBannerDelete(id);

      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  const faqBannerMoveUp = async (id: string) => {
    try {
      await api.settings.generalSettings.faqBannerMoveSequenceUp(id);

      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  const faqBannerMoveDown = async (id: string) => {
    try {
      await api.settings.generalSettings.faqBannerMoveSequenceDown(id);

      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  return {
    meProfile,
    branchSettings,
    branchFilterSettings,
    branchSettingsError,
    branchSettingsLoading,
    printerSettings,
    printers,
    printerSettingsError,
    printerSettingsLoading,
    updateGeneralSettings,
    updatePrinterSettings,
    updatePaymentTypesSettings,
    createPrinterSettings,
    removePrinter,
    fetchPrinter,
    fetchPrinters,
    fetchGeneralSettings,
    fetchGeneralFilterSettings,
    fetchMe,
    fetchMeWithPaymentTypes,
    printersMeta,
    fetchQrMenuTemplates,
    qrMenuTemplates,
    setQrMenuTemplates,
    fetchChannelSales,
    addChannelSales,
    channelSales,
    updateChannelSales,
    deleteChannelSales,
    updateCouvert,
    updateServiceCharge,
    getCouvert,
    getServiceCharge,
    couvert,
    serviceCharge,
    serviceChargeData,
    couvertData,
    isUninstalled,
    fetchTimeZones,
    timeZones,
    fetchDisplayScreen,
    setDisplayScreen,
    displayScreenData,
    downloadQrMenuTemplates,
    fetchOnlineFoodOrderInfo,
    onlineFoodDelivery,
    updateOnlineFoodDelivery,
    fetchStaticPageInfo,
    onlineFoodDeliverySetInfo,
    staticPageInfos,
    faqBannerDelete,
    faqBannerMoveUp,
    faqBannerMoveDown,
  };
};

export default useSettings;
