/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { Box, Grid } from '@mui/material';

/** Types */
import { OptionsType, ExpenseBase } from '../types';

/** Components */
import FormInput from '../../../../components/FormInput';
import FormSelect from '../../../../components/FormSelect';
import FormDatePicker from '../../../../components/FormDatePicker';
import FormNumberInputSelect from '../../../../components/FormNumberInputSelect';

/** hooks */
import useExpenseCategories from '../../../../hooks/useExpenseCategories/useExpenseCategories';

interface Props {
  control: Control<ExpenseBase, Object>;
  roleOptions: OptionsType[];
  currencyOptions: OptionsType[];
  selectedCategoryId: string | null;
}

function ExpenseForm({
  control,
  roleOptions,
  selectedCategoryId,
  currencyOptions,
}: Props) {
  const { t } = useTranslation('user');
  const { expenseCategories } = useExpenseCategories();

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="expense_category_id"
            control={control}
            defaultValue={selectedCategoryId ? expenseCategories[0]?.id : ''}
            render={({ field, fieldState }) => (
              <FormSelect
                label={t('user.addModal.name')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                underline
                options={roleOptions}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="date"
            control={control}
            render={({ field, fieldState }) => (
              <FormDatePicker
                label={t('expense_form.expense_date')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="amount"
            control={control}
            render={({ field, fieldState }) => (
              <FormNumberInputSelect
                label={t('expense_form.amount')}
                selectName="currency"
                selectControl={control}
                maskProps={{
                  decimalScale: 2,
                  fixedDecimalScale: true,
                }}
                placeholder="0,00"
                error={fieldState.invalid}
                options={currencyOptions}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => (
              <FormInput
                label={t('expense_form.explanation')}
                error={fieldState.invalid}
                rows={5}
                multiline
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ExpenseForm;
