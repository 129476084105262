/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';

/** Components */
import FormInput from '../../../../../../components/FormInput';
import FormSelect from '../../../../../../components/FormSelect';
import FormNumberInput from '../../../../../../components/FormNumberInput';

/** Types */
import { SpecialOrderFormValues } from './types';

/** Hooks */
import useTaxRates from '../../../../../../hooks/useTaxRates';

interface Props {
  control: Control<SpecialOrderFormValues, Object>;
}

function Form({ control }: Props) {
  const { t } = useTranslation('pos');
  const { taxRates } = useTaxRates();

  return (
    <>
      <Controller
        name="product_name"
        control={control}
        render={({ field, fieldState }) => (
          <FormInput
            label={t('orderModal.specialOrderModal.description')}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            {...field}
          />
        )}
      />
      <Controller
        name="quantity"
        control={control}
        render={({ field, fieldState }) => (
          <FormNumberInput
            label={t('orderModal.specialOrderModal.quantity')}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            {...field}
          />
        )}
      />
      <Controller
        name="price"
        control={control}
        render={({ field, fieldState }) => (
          <FormNumberInput
            label={t('orderModal.specialOrderModal.price')}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            maskProps={{
              decimalScale: 2,
              fixedDecimalScale: true,
            }}
            {...field}
            placeholder="0,00"
          />
        )}
      />
      <Controller
        name="tax_rate"
        control={control}
        render={({ field, fieldState }) => (
          <FormSelect
            options={taxRates.map(item => ({
              id: item.tax_rate,
              text: `%${item.tax_rate}`,
            }))}
            label={t('orderModal.specialOrderModal.tax_rate')}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            {...field}
            underline
          />
        )}
      />
    </>
  );
}

export default Form;
