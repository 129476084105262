/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Components */
import FormSelectSearchable from '../../../../../components/FormSelectSearchable';
import Modal from '../../../../../components/Modal';
import Form from './Form';

/** Helpers */
import keyHasPermission from '../../../../../helpers/keyHasPermission';

/** Hooks */
import useCustomers from '../../../../../hooks/useCustomers';
import useProfile from '../../../../../hooks/useProfile';

interface Props {
  open: boolean;
  handleAddPaymentClick: (id: string, customer_id?: string) => void;
  onClose: () => void;
}

function FastPaymentModalCustomer({
  open,
  onClose,
  handleAddPaymentClick,
}: Props) {
  const { t } = useTranslation('pos');
  const { fetchCustomers, customers, customerLoading } = useCustomers();
  const { meProfile } = useProfile();

  const [firstFetch, setFirstFetch] = useState(true);
  const [customerId, setCustomerId] = useState('');
  const [customerGroupAddModal, setCustomerGroupAddModal] = useState(false);

  const handleCustomerChange = (id: string) => {
    if (id) {
      setCustomerId(id);
    } else {
      setCustomerId('');
    }
  };

  const handleReset = () => {
    if (customerId) {
      setCustomerId('');
    }
  };

  const handleSubmit = () => {
    if (customerId) {
      handleAddPaymentClick('9', customerId);
      onClose();
    }
  };

  useEffect(() => {
    if (open && (firstFetch || !customers.length)) {
      fetchCustomers({
        page: 1,
        search: '',
        is_open_account: true,
        first_name: '',
        per_page: 1000,
      });
      setFirstFetch(false);
    }
  }, [open]);

  return (
    <Modal
      maxWidth="md"
      open={open}
      onClose={onClose}
      title={t('orderModal.customerModal.title')}
      positiveAction={t('orderModal.customerModal.confirm')}
      onPositiveClick={handleSubmit}
      extraActionOne={t('orderModal.customerModal.reset')}
      onExtraActionOneClick={handleReset}
      negativeAction={t('orderModal.customerModal.cancel')}
      onNegativeClick={onClose}
      headerButton={keyHasPermission(
        'create-kitchen-group',
        meProfile?.permissions || [],
      )}
      headerButtonIconName="Add"
      headerButtonText={t('modalMidSide.addCustomer')}
      onHeaderButtonClick={() => setCustomerGroupAddModal(true)}
    >
      <FormSelectSearchable
        label={t('orderModal.customerModal.placeholderForOpenAccount')}
        options={customers?.map(customer => ({
          id: customer.id,
          text: `${customer.first_name} ${customer.last_name} | ${
            customer.phone_number || '-'
          } | ${
            customer?.open_account_info?.remained_balance.formatted || '-'
          }`,
        }))}
        value={customerId}
        onChange={id => handleCustomerChange(id)}
        loading={customerLoading}
        placeholder={t('orderModal.customerModal.placeholderForOpenAccount')}
        handleReset={() => handleReset()}
      />
      <Form
        open={customerGroupAddModal}
        onClose={() => {
          setCustomerGroupAddModal(false);
        }}
      />
    </Modal>
  );
}

export default FastPaymentModalCustomer;
