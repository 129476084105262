/** Dependencies */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

/** Styles */
import {
  alpha,
  AppBar,
  Box,
  styled,
  Typography,
  Toolbar,
  Grid,
  Button,
} from '@mui/material';
import FormDatePicker from '../../../../components/FormDatePicker';
import useExpenseCategories from '../../../../hooks/useExpenseCategories';
import useBranches from '../../../../hooks/useBranches';
import keyHasPermission from '../../../../helpers/keyHasPermission';
import useProfile from '../../../../hooks/useProfile';

const SearchComponent = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

interface Props {
  startDate: string;
  setStartDate: (value: ((prevState: string) => string) | string) => void;
  endDate: string;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
}

function DaysSearch({ endDate, startDate, setEndDate, setStartDate }: Props) {
  const { t } = useTranslation('search');
  const { meProfile } = useProfile();

  const { expenseCategories } = useExpenseCategories();
  const { fetchDays } = useBranches();

  const date = new Date(Date.now());
  date.setDate(date.getDate() - 7);

  const initialStartDate = format(date, 'yyyy-MM-dd');
  const initialEndDate = format(new Date(Date.now()), 'yyyy-MM-dd');

  const handleSubmit = () => {
    fetchDays(1, startDate, endDate);
  };

  useEffect(() => {
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
  }, [expenseCategories]);

  const hasViewPermission = keyHasPermission(
    'view-daily-report',
    meProfile?.permissions || [],
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'white',
          marginBottom: '25px',
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}
          >
            <SearchComponent>
              <Grid container justifyContent="left">
                <Grid item xs={12} sm={12} md={3} lg={2} p={1}>
                  <FormDatePicker
                    value={startDate}
                    onChange={event => setStartDate(event.target.value)}
                    sx={{ color: 'black' }}
                    label={t('filters.date')}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={2} p={1}>
                  <FormDatePicker
                    value={endDate}
                    onChange={event => setEndDate(event.target.value)}
                    sx={{ color: 'black' }}
                    label={t('filters.end_date')}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={2} p={1}>
                  <Button
                    onClick={handleSubmit}
                    sx={{
                      marginTop: { sm: '10px', md: '30px' },
                      width: '100%',
                    }}
                    size="large"
                    variant="contained"
                    disabled={!hasViewPermission}
                  >
                    {t('filters.name')}
                  </Button>
                </Grid>
              </Grid>
            </SearchComponent>
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default DaysSearch;
