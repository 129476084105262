import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getRoleListThunk,
  getRolesListThunk,
  updateRolePermissionsThunk,
} from '../../store/slices/userOperationsSlice/roles';
import { isApiError } from '../../services/api/types';
import { UpdateRolePermissionsParams } from '../../services/api/userOperations/roles/types';
import arrayCatcher from '../../helpers/errorCatcher';

const useRoles = () => {
  const dispatch = useAppDispatch();

  const roles = useAppSelector(state => state.userOperationsRoles.list);
  const role = useAppSelector(state => state.userOperationsRoles.role);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const fetchRoles = async () => {
    try {
      setLoading(true);
      await dispatch(getRolesListThunk()).unwrap();
    } catch (err: any) {
      arrayCatcher(err?.code);
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchRole = async (roleId: string) => {
    try {
      setLoading(true);
      await dispatch(getRoleListThunk(roleId)).unwrap();
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const updateRolePermissions = async (params: UpdateRolePermissionsParams) => {
    try {
      setLoading(true);
      await dispatch(updateRolePermissionsThunk(params)).unwrap();
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    fetchRoles,
    fetchRole,
    updateRolePermissions,
    roles,
    role,
    loading,
  };
};

export default useRoles;
