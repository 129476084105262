/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import Base64Downloader from 'react-base64-downloader';

/** Styles */
import { Box, Grid, Select, MenuItem, Paper, Typography } from '@mui/material';

/** Components */
import useSettings from '../../hooks/useSettings';
import QrMenuLeftSide from './Components/QrMenuLeftSide';
import QrMenuRightSide from './Components/QrMenuRightSide';
import { QrDataType, QrMenuDataType } from './types';
import useProducts from '../../hooks/useProducts';
import ConfirmationModal from '../../components/ConfirmationModal';
import IconWithContainer from '../../components/IconWithContainer';
import PleksiModal from './PleksiModal';

function QrMenu() {
  const { t } = useTranslation('qrMenu');
  const {
    downloadQrMenuTemplates,
    setQrMenuTemplates,
    fetchQrMenuTemplates,
    qrMenuTemplates,
  } = useSettings();
  const { updateImage, deleteImage } = useProducts();

  const [selectedImage, setSelectedImage] = useState<null | string>(null);
  const [selectedImageMenu, setSelectedImageMenu] = useState<null | string>(
    null,
  );
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingImageMenu, setLoadingImageMenu] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [openImageMenu, setOpenImageMenu] = useState(false);
  const [openInstallModal, setOpenInstallModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean | null>(
    null,
  );
  const [qrData, setQrData] = useState<QrDataType>({
    qr_code: '',
    url: '',
    qr_menu_logo: '',
  });
  const [qrMenuData, setQrMenuData] = useState<QrMenuDataType>({
    qr_menu_logo: '',
    color: '',
    use_category_image: false,
    use_product_image: false,
    waiter_call: false,
    check_please: false,
    is_online_order: false,
    is_online_checkout: false,
    is_active: true,
  });
  const [age, setAge] = useState(0);
  const [imageType, setImageType] = useState('');

  const handleFileChange = async (e: any) => {
    setSelectedImage(null);
    setLoadingImage(true);
    if (qrMenuTemplates) {
      await updateImage(
        qrMenuTemplates.id || '',
        'qr-code-icon',
        e.target.files[0],
      );
    }

    const file = e.target.files[0];
    if (file && Number(file?.size) < 2000000) {
      const formData = new FormData();
      formData.append('image', e.target.files[0]);

      // @ts-ignore
      setSelectedImage(URL.createObjectURL(file));
    }

    window.location.reload();

    setLoadingImage(false);
  };

  const handleFileChangeMenu = async (e: any) => {
    setSelectedImageMenu(null);

    setLoadingImageMenu(true);
    if (qrMenuTemplates) {
      await updateImage(
        qrMenuTemplates.id || '',
        'qr-menu-logo',
        e.target.files[0],
      );
    }

    const file = e.target.files[0];
    if (file && Number(file?.size) < 2000000) {
      const formData = new FormData();
      formData.append('image', e.target.files[0]);

      // @ts-ignore
      setSelectedImageMenu(URL.createObjectURL(file));
    }

    window.location.reload();

    setLoadingImageMenu(false);
  };

  const handleSubmit = () => {
    setQrMenuTemplates(qrMenuTemplates?.id || '', {
      color: qrMenuData.color,
      is_active: qrMenuData.is_active,
      use_category_image: qrMenuData.use_category_image,
      use_product_image: qrMenuData.use_product_image,
      waiter_call: qrMenuData.waiter_call,
      check_please: qrMenuData.check_please,
      is_online_order: qrMenuData.is_online_order,
      is_online_checkout: qrMenuData.is_online_checkout,
    });
  };

  const handleRemove = async () => {
    try {
      if (imageType === 'qr-code-icon') {
        setSelectedImage(null);
        setLoadingImage(true);
      } else {
        setQrMenuData({
          ...qrMenuData,
          qr_menu_logo: '',
        });
        setSelectedImageMenu(null);
        setLoadingImageMenu(true);
      }

      await deleteImage(qrMenuTemplates?.id || '', imageType);

      setLoadingImage(false);
      setLoadingImageMenu(false);
      setDeleteConfirmation(null);
      setImageType('');

      await fetchQrMenuTemplates();
    } catch (e) {
      console.log(e);
    }
  };

  const downloadQrCodePng = () => {};

  const base64: string = `data:image/png;base64, ${qrData.qr_code}`;

  useEffect(() => {
    fetchQrMenuTemplates();
  }, []);

  useEffect(() => {
    if (qrMenuTemplates) {
      setSelectedImage(null);
      setSelectedImageMenu(null);

      setQrData({
        qr_code: qrMenuTemplates.qr_code,
        url: qrMenuTemplates.url,
        qr_menu_logo: qrMenuTemplates.qr_code_icon,
      });

      setQrMenuData({
        qr_menu_logo: qrMenuTemplates.qr_menu_logo,
        color: qrMenuTemplates.color,
        use_category_image: qrMenuTemplates.use_category_image,
        use_product_image: qrMenuTemplates.use_product_image,
        waiter_call: qrMenuTemplates.waiter_call,
        check_please: qrMenuTemplates.check_please,
        is_online_checkout: qrMenuTemplates.is_online_checkout,
        is_online_order: qrMenuTemplates.is_online_order,
        is_active: qrMenuTemplates.is_active,
      });

      setSelectedImage(qrMenuTemplates?.qr_code_icon);
      setSelectedImageMenu(qrMenuTemplates?.qr_menu_logo);
    } else {
      setSelectedImage(null);
      setSelectedImageMenu(null);
    }
  }, [qrMenuTemplates]);

  return (
    <Box>
      <Paper sx={{ mb: 3 }}>
        <Grid p={2} container justifyContent="space-between">
          <Grid item>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <IconWithContainer containerStyle={{ mr: 1 }} name="QrCode" />
              <Grid>
                <Typography variant="h5">{t('title')}</Typography>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Select
              value={age}
              autoWidth
              variant="outlined"
              color="primary"
              size="medium"
              sx={{
                backgroundColor: '#26acf8',
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                color: 'white',
                '.MuiSvgIcon-root ': {
                  color: 'white !important',
                },
              }}
            >
              <MenuItem sx={{ display: 'none' }} disabled value={0}>
                {t('downloadQrCode')}
              </MenuItem>
              <Base64Downloader
                style={{
                  background: 'white',
                  border: 'none',
                  fontSize: '16px',
                  padding: 0,
                  fontWeight: 400,
                  cursor: 'pointer',
                }}
                base64={base64}
                downloadName="qrCode"
              >
                <MenuItem onClick={() => downloadQrCodePng} value={1}>
                  {t('downloadQrCodePng')}
                </MenuItem>
              </Base64Downloader>
              <MenuItem onClick={() => setOpenInstallModal(true)} value={2}>
                {t('downloadQrCodePdf')}
              </MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Paper>

      <Grid container>
        <QrMenuLeftSide
          qrData={qrData}
          setQrData={setQrData}
          qrMenuData={qrMenuData}
          setQrMenuData={setQrMenuData}
          selectedImage={selectedImage}
          loadingImage={loadingImage}
          setOpenImage={setOpenImage}
          handleFileChange={handleFileChange}
          selectedImageMenu={selectedImageMenu}
          loadingImageMenu={loadingImageMenu}
          setOpenImageMenu={setOpenImageMenu}
          handleFileChangeMenu={handleFileChangeMenu}
          handleSubmit={handleSubmit}
          setDeleteConfirmation={setDeleteConfirmation}
          setImageType={setImageType}
        />
        <QrMenuRightSide
          qrMenuData={qrMenuData}
          selectedImageMenu={selectedImageMenu}
        />
      </Grid>
      <ConfirmationModal
        title={t('deleteConfirmation.title')}
        titleIconName="QuestionMarkOutlined"
        description={t('deleteConfirmation.description')}
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        positiveAction={t('deleteConfirmation.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('deleteConfirmation.cancel')}
        onNegativeClick={() => setDeleteConfirmation(null)}
        loading={false}
      />
      <PleksiModal
        open={openInstallModal}
        onClose={() => setOpenInstallModal(false)}
        qrMenuTemplates={qrMenuTemplates}
      />
    </Box>
  );
}

export default QrMenu;
