/** Dependencies */
import React, { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Grid, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

/** Components */
import Modal from '../../../../../components/Modal';
import PaymentModalLeftSide from './Sides/PaymentModalLeftSide';
import PaymentModalMidSide from './Sides/PaymentModalMidSide';
import PaymentModalRightSide from './Sides/PaymentModalRightSide';

/** Types */
import { BasketProduct } from '../../../../../models/Product';
import { Payments, SelfPayment } from './types';
import usePosOrder from '../../../../../hooks/usePosOrder';
import useProfile from '../../../../../hooks/useProfile';
import keyHasPermission from '../../../../../helpers/keyHasPermission';

interface Props {
  open: boolean;
  onClose: () => void;
  list: BasketProduct[];
  discountTotal: number;
  tableId: string;
  totalPrice: number;
  waiterName: string;
  tableName: string;
  onBackClick: () => void;
  paidPayments?: any[];
  removeDiscount: () => void;
  currencyCode: string;
  isPackageOrder: boolean;
  handleSelfSubmit?: (selfPayment: SelfPayment | null) => void;
  isSelfOrder: boolean;
  selectedOrderTypeId: number;
}

function PaymentModal({
  open,
  onClose,
  list,
  discountTotal,
  tableId,
  totalPrice,
  waiterName,
  tableName,
  onBackClick,
  paidPayments,
  removeDiscount,
  currencyCode,
  isPackageOrder,
  handleSelfSubmit,
  isSelfOrder,
  selectedOrderTypeId,
}: Props) {
  const { addOrderTransactionPayment } = usePosOrder();
  const { enqueueSnackbar } = useSnackbar();
  const { meProfile } = useProfile();

  const { t } = useTranslation('pos');

  const initialRef: any = null;
  const inputRef = useRef(initialRef);

  const [value, setValue] = useState(totalPrice.toString());
  const [pieceValue, setPieceValue] = useState('0');
  const [dividedValue, setDividedValue] = useState(0);
  const [allPrice, setAllPrice] = useState(totalPrice);
  const [isRemoved, setIsRemoved] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [paymentList, setPaymentList] = useState<Payments[]>([]);
  const [pieceOrderList, setPieceOrderList] = useState<BasketProduct[]>([]);
  const [paymentCustomer, setPaymentCustomer] = useState(false);
  const [divided, setDivided] = useState<any[]>([]);

  const matchesLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  const hasGetOrderPaymentPermission = keyHasPermission(
    'get-order-payment',
    meProfile?.permissions || [],
  );

  const removeDiscountForValue = () => {
    window.location.reload();
  };
  const handleAddPaymentClick = (id: number, customer_id?: string) => {
    if (allPrice > 0 && !value.includes('e')) {
      // @ts-ignore
      const selectedOrders: { id: string; quantity: number }[] =
        pieceOrderList
          .map(
            item =>
              item.selected_piece_orders &&
              item.selected_piece_orders.quantity > 0 && {
                id: item.selected_piece_orders.id,
                quantity: item.selected_piece_orders.quantity,
              },
          )
          .filter(orderItem => !!orderItem) || null;

      if (Number(value) > allPrice) {
        setPaymentList([
          ...paymentList,
          {
            id: Date.now().toString(),
            amount: allPrice,
            orders: selectedOrders,
            customer_id: customer_id || null,
            payment_type_id: id,
            more_money: Number(value) - allPrice,
          },
        ]);
        setAllPrice(0);
      } else {
        setPaymentList([
          ...paymentList,
          {
            id: Date.now().toString(),
            amount: Number(Number(value).toFixed(2)),
            orders: selectedOrders,
            customer_id: customer_id || null,
            payment_type_id: id,
          },
        ]);
        setAllPrice(allPrice - Number(value));
      }
      setPieceOrderList(
        pieceOrderList.map(item =>
          item.selected_piece_orders
            ? {
                ...item,
                paid_quantity:
                  item.count -
                  (item.selected_piece_orders?.remainingPiece || 0),
                selected_orders_count:
                  (item.selected_orders_count || 0) +
                  item.selected_piece_orders.quantity,
                selected_piece_orders: null,
              }
            : item,
        ),
      );
    }

    setPieceValue('0');
    if (divided.length > 0) {
      divided.splice(0, 1);
    }
  };

  const handleRemovePaymentListItem = (id: string) => {
    const itemAmount = paymentList?.find(item => item.id === id)?.amount || 0;
    const paymentOrders =
      paymentList?.find(item => item.id === id)?.orders || [];

    paymentOrders.forEach(orders => {
      const itemIndex = pieceOrderList.findIndex(
        pieceItem => pieceItem.id === orders.id,
      );
      pieceOrderList[itemIndex] = {
        ...pieceOrderList[itemIndex],
        paid_quantity:
          pieceOrderList[itemIndex].paid_quantity - orders.quantity,
        selected_orders_count:
          (pieceOrderList[itemIndex].selected_orders_count || 0) -
          orders.quantity,
      };
    });

    setAllPrice(allPrice + itemAmount);
    setPaymentList(paymentList.filter(item => item.id !== id));
  };

  const totalPieceValue = () => {
    const arrayPrice = pieceOrderList.map(
      item =>
        ((item.selected_piece_orders?.quantity || 0) *
          Number(
            item?.total_amount
              ? item?.total_amount?.amount
              : item?.total_price
              ? (item.total_price * 100)?.toString()
              : item.active_unit
              ? item.active_unit?.amount.amount
              : item?.units?.find(unit => unit.is_default)?.amount.amount,
          )) /
        item.count,
    );

    let arrayTotal = 0;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arrayPrice.length; i++) {
      arrayTotal += Number(arrayPrice[i]);
    }

    if (dividedValue > 0 && allPrice - dividedValue !== 0) {
      setValue(dividedValue.toString());
    } else {
      setValue((arrayTotal / 100).toString());
    }
    setPieceValue((arrayTotal / 100).toString());
  };

  const handleAddPieceOrderClick = (
    id: string,
    itemTotalQuantity: number,
    itemId?: string,
  ) => {
    const orderItemIndex = pieceOrderList.findIndex(item => item.id === id);
    const orderItem = pieceOrderList[orderItemIndex];

    if (orderItem && !itemId) {
      if (!orderItem.selected_piece_orders) {
        orderItem.selected_piece_orders = {
          id: orderItem.id,
          quantity: 1,
          remainingPiece: itemTotalQuantity - 1,
        };
      } else if (
        orderItem.selected_piece_orders.quantity !== itemTotalQuantity
      ) {
        orderItem.selected_piece_orders = {
          id: orderItem.id,
          quantity: orderItem.selected_piece_orders.quantity + 1,
          remainingPiece: orderItem.selected_piece_orders.remainingPiece - 1,
        };
      }

      setPieceOrderList([]);
      setPieceOrderList([...pieceOrderList]);
      totalPieceValue();
    }

    if (itemId) {
      setPieceOrderList(
        list.map(item =>
          item.itemId === itemId
            ? {
                ...item,
                selected_piece_orders: {
                  id: item.id,
                  quantity: 1,
                  remainingPiece: 0,
                },
              }
            : item,
        ),
      );
      totalPieceValue();
    }

    setIsSubmitted(false);
  };

  const handleDecreasePieceOrderClick = (id: string) => {
    const orderItemIndex = pieceOrderList.findIndex(item => item.id === id);
    const orderItem = pieceOrderList[orderItemIndex];

    if (orderItem) {
      if (!orderItem.selected_piece_orders) {
        orderItem.selected_piece_orders = null;
      } else if (orderItem.selected_piece_orders.quantity > 0) {
        orderItem.selected_piece_orders = {
          id: orderItem.id,
          quantity: orderItem.selected_piece_orders.quantity - 1,
          remainingPiece: orderItem.selected_piece_orders.remainingPiece + 1,
        };
      }
    }

    totalPieceValue();
    setPieceOrderList([]);
    setPieceOrderList([...pieceOrderList]);
  };

  const clearList = () => {
    const removedList = list.map(listItem => ({
      ...listItem,
      selected_piece_orders: listItem.selected_piece_orders
        ? {
            id: listItem.selected_piece_orders?.id,
            quantity: 0,
            remainingPiece:
              (listItem?.selected_piece_orders?.remainingPiece || 0) +
              (listItem?.selected_piece_orders?.quantity || 0),
          }
        : null,
    }));
    setPieceOrderList(removedList);
    setPieceValue('0');
  };

  const handleKeyDown = (event: { key: any }) => {
    if (!paymentCustomer) {
      if (
        value.split('').find(item => item === '.') &&
        value.split('.')[1].length >= 2
      ) {
        setValue(value);
      } else {
        if (Number(event.key)) {
          setValue(
            value === '0' ||
              value === totalPrice.toString() ||
              value === allPrice.toString()
              ? event.key
              : value + event.key,
          );
        }

        if (Number(event.key === '.')) {
          const hasDot = value.split('').find(item => item === '.') || false;
          setValue(hasDot ? value : `${value}.`);
        }

        if (Number(event.key === ',')) {
          const hasDot = value.split('').find(item => item === '.') || false;
          setValue(hasDot ? value : `${value}.`);
        }

        if (event.key === '0') {
          setValue(value === '0' ? '0' : `${value}0`);
        }

        clearList();
      }

      if (event.key === 'Backspace') {
        if (
          value
            .slice(-2)
            .split('')
            .find(item => item === '.')
        ) {
          setValue(value.slice(0, -2));
        } else {
          setValue(value.length === 1 ? '0' : value.slice(0, -1));
        }
      }

      setDivided([]);
    }
  };

  const handleClose = () => {
    setPaymentList([]);
    setAllPrice(totalPrice);
    setValue(totalPrice.toString());
    clearList();
    onClose();
  };

  const handleAddOrderTransactionPayment = async (paymentAction: number) => {
    const params = {
      order_transaction_id: tableId,
      payment_action: paymentAction,
      payments: paymentList.map(item => ({
        amount: Number((item.amount * 100).toFixed(2)),
        payment_type_id: item.payment_type_id,
        customer_id: item.customer_id,
        orders: item.orders?.length ? item.orders : null,
        remainder_amount: item.more_money
          ? (Math.round(item.more_money * 1000) / 1000) * 100
          : 0,
      })),
    };

    try {
      if (isSelfOrder && handleSelfSubmit && !tableId) {
        handleSelfSubmit({
          payment_action: paymentAction,
          payments: params.payments,
        });
      } else if (paymentAction === 3) {
        await addOrderTransactionPayment({
          order_transaction_id: tableId,
          payment_action: paymentAction,
          payments: [
            {
              amount: 0,
              payment_type_id: 1,
              remainder_amount: 0,
            },
          ],
        });
        onBackClick();
        handleClose();
        enqueueSnackbar(t('basketList.payment_slip'), {
          variant: 'success',
        });
        setPieceValue('0');
        setDividedValue(0);
        setIsRemoved(false);
        setIsSubmitted(false);
        setPaymentList([]);
        setPieceOrderList([]);
        setPaymentCustomer(false);
        setDivided([]);
        onClose();
      } else if (
        !paymentList.length &&
        totalPrice === 0 &&
        paymentAction !== 1
      ) {
        await addOrderTransactionPayment({
          order_transaction_id: tableId,
          payment_action: paymentAction,
          payments: [
            {
              amount: 0,
              payment_type_id: 1,
              remainder_amount: 0,
            },
          ],
        });
        onBackClick();
        handleClose();
      } else if (!paymentList.length) {
        enqueueSnackbar(t('basketList.not_process'), {
          variant: 'warning',
        });
      } else {
        await addOrderTransactionPayment(params);
        onBackClick();
        handleClose();
        enqueueSnackbar(t('basketList.paymentSuccess'), {
          variant: 'success',
        });
        setPieceValue('0');
        setDividedValue(0);
        setIsRemoved(false);
        setIsSubmitted(false);
        setPaymentList([]);
        setPieceOrderList([]);
        setPaymentCustomer(false);
        setDivided([]);
        onClose();
      }
      onClose();
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  useEffect(() => {
    setAllPrice(totalPrice);
    setValue(totalPrice.toString());
  }, [totalPrice]);

  useEffect(() => {
    if (divided.length > 0) {
      setValue(Number(divided[0].value).toString());
      setDividedValue(Number(divided[0].value));
    } else if (dividedValue !== 0 && value === dividedValue.toString()) {
      setValue(allPrice.toString());
      setDividedValue(0);
    }
  }, [divided]);

  useEffect(() => {
    if (allPrice === 0) {
      setValue('0');
      setDividedValue(0);
      setDivided([]);
    } else if (divided.length > 0) {
      setValue(divided[0].value.toString());
    } else {
      setValue(allPrice.toString());
    }
  }, [allPrice]);

  useEffect(() => {
    inputRef?.current?.focus();

    if (!pieceOrderList.length) {
      setPieceOrderList(list);
    }

    if (!open && isRemoved) {
      window.location.reload();
    }
  }, [open]);

  useEffect(() => {
    if (isSubmitted) totalPieceValue();
  }, [pieceOrderList, isSubmitted]);

  return (
    <Modal
      maxWidth="xl"
      fullScreen={matchesLg}
      open={open}
      onClose={handleClose}
      title={`${t('paymentModal.pay')}: ${tableName}`}
      buttonVariant="text"
      negativeActionIconName="Close"
      positiveAction={hasGetOrderPaymentPermission && `${t('basketList.pay')}`}
      onPositiveClick={() => {
        handleAddOrderTransactionPayment(1);
      }}
      actionIconName="Save"
      extraActionOne={
        hasGetOrderPaymentPermission && `${t('paymentModal.close')}`
      }
      onExtraActionOneClick={() => handleAddOrderTransactionPayment(12)}
      extraActionOneIconName="Save"
      extraActionTwo={
        hasGetOrderPaymentPermission && `${t('paymentModal.print')}`
      }
      onExtraActionTwoClick={() => handleAddOrderTransactionPayment(3)}
      extraActionTwoIconName="Print"
      negativeAction={
        hasGetOrderPaymentPermission && `${t('paymentModal.close_screen')}`
      }
      onNegativeClick={handleClose}
      disableEscapeKeyDown
      pageName="paymentModal"
    >
      <Grid spacing={3} container ref={inputRef} onKeyDown={handleKeyDown}>
        {!isSelfOrder && (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <PaymentModalLeftSide
              list={pieceOrderList}
              pieceValue={pieceValue}
              handleAddPieceOrderClick={handleAddPieceOrderClick}
              handleDecreasePieceOrderClick={handleDecreasePieceOrderClick}
              currencyCode={currencyCode}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={isSelfOrder ? 12 : 6}
          md={isSelfOrder ? 6 : 4}
          lg={isSelfOrder ? 6 : 4}
        >
          <PaymentModalMidSide
            discountTotal={discountTotal}
            tableId={tableId}
            totalPrice={totalPrice}
            value={value}
            setValue={setValue}
            paymentList={paymentList}
            allPrice={allPrice}
            removeDiscount={removeDiscountForValue}
            removeDiscountForSelf={() => {
              removeDiscount();
              setPaymentList([]);
            }}
            onRemoved={() => setIsRemoved(true)}
            handleRemovePaymentListItem={(id: string) =>
              handleRemovePaymentListItem(id)
            }
            paidPayments={paidPayments || []}
            currencyCode={currencyCode}
            setDividedValue={setDividedValue}
            divided={divided}
            setDivided={setDivided}
            clearList={clearList}
            handleKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={isSelfOrder ? 6 : 4}
          lg={isSelfOrder ? 6 : 4}
        >
          <PaymentModalRightSide
            handleAddPaymentClick={(id: number, customer_id?: string) =>
              handleAddPaymentClick(id, customer_id)
            }
            selectedOrderTypeId={selectedOrderTypeId}
            paymentCustomer={paymentCustomer}
            setPaymentCustomer={setPaymentCustomer}
          />
        </Grid>
      </Grid>
    </Modal>
  );
}

export default PaymentModal;
