/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

/** Styles */
import { IconButton, Paper, TableCell, TableRow } from '@mui/material';

/** helpers */
import getActiveLanguage from '../../../helpers/getActiveLanguage';

/** Components */
import EmptyState from '../../../components/EmptyState';
import Icon from '../../../components/Icon';
import ConfirmationModal from '../../../components/ConfirmationModal';

/** Hooks */
import useExpenses from '../../../hooks/useExpenses';
import keyHasPermission from '../../../helpers/keyHasPermission';
import useProfile from '../../../hooks/useProfile';
import TextModal from '../../Reports/OperationLogs/TextModal';
import TableComponent from '../../../components/TableComponent';

interface Props {
  onItemEdit: (item: any) => void;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  dateRange: string;
  select: string[] | null;
}

function ExpensesTable({
  onItemEdit,
  page,
  setPage,
  dateRange,
  select,
}: Props) {
  const { t } = useTranslation('user');
  const { meProfile } = useProfile();
  const { expenses, meta, removeExpense, fetchExpenses } = useExpenses();

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState<string | null>(null);
  const [textOpenModal, setTextOpenModal] = useState(false);
  const [textModal, setTextModal] = useState('');

  const initialStartDate = format(new Date(Date.now() - 30), 'yyyy-MM-dd');
  const initialEndDate = format(new Date(Date.now()), 'yyyy-MM-dd');

  const handleRemove = () => {
    if (itemIdToDelete) {
      removeExpense(itemIdToDelete);
    }

    setItemIdToDelete(null);
    setDeleteConfirmation(false);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (value !== page) {
      setPage(value);
      const isToday = `${initialStartDate},${initialEndDate}`;

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      select
        ? fetchExpenses(value, isToday !== dateRange ? dateRange : '', select)
        : fetchExpenses(value, isToday !== dateRange ? dateRange : '');
    }
  };

  useEffect(() => {
    // @ts-ignore
    setPage(meta.current_page);
  }, [meta]);

  const hasTablePermission = keyHasPermission(
    'view-expense-listing',
    meProfile?.permissions || [],
  );

  const hasUpdatePermission = keyHasPermission(
    'update-expense',
    meProfile?.permissions || [],
  );

  const hasDeletePermission = keyHasPermission(
    'delete-expense',
    meProfile?.permissions || [],
  );

  const tableHead = [
    t('tableHead.type'),
    t('tableHead.detail'),
    t('tableHead.date'),
    t('tableHead.amount'),
    t('tableHead.currency'),
    t('tableHead.user'),
    t('tableHead.add_date'),
    t('tableHead.process'),
  ];

  const tableBody = expenses.map(row => (
    <TableRow key={row.id}>
      <TableCell>
        {getActiveLanguage(row.expense_category.translations).name}
      </TableCell>
      <TableCell
        style={{
          maxWidth: '280px',
          minWidth: '100px',
          cursor: 'pointer',
        }}
        onClick={() => {
          setTextOpenModal(true);
          setTextModal(row?.description);
        }}
      >
        {row.description.length > 30
          ? `${row.description.substring(0, 28)}...`
          : row.description}
      </TableCell>
      <TableCell>{format(new Date(row.date), 'dd.MM.yyyy')}</TableCell>
      <TableCell>{row.amount.formatted}</TableCell>
      <TableCell>{row.currency}</TableCell>
      <TableCell>{row.created_by.full_name}</TableCell>
      <TableCell>
        {format(new Date(row.created_at), 'dd.MM.yyyy HH:mm')}
      </TableCell>
      <TableCell>
        <IconButton
          onClick={event => {
            if (hasUpdatePermission) {
              onItemEdit(row);
            }
          }}
          aria-label="Add"
          color="primary"
          disabled={!hasUpdatePermission}
        >
          <Icon name="Edit" />
        </IconButton>
        <IconButton
          onClick={() => {
            setItemIdToDelete(row.id);
            if (hasDeletePermission) {
              setDeleteConfirmation(true);
            }
          }}
          aria-label="Add"
          color="error"
          disabled={!hasDeletePermission}
        >
          <Icon name="Delete" />
        </IconButton>
      </TableCell>
    </TableRow>
  ));

  const renderEmpty = () => (
    <EmptyState
      message={
        !hasTablePermission
          ? t('user_table.no_authority')
          : t('user_table.there_is_no_expense')
      }
    />
  );

  return (
    <Paper sx={{ width: '100%', p: 2 }}>
      {!hasTablePermission || !expenses.length ? (
        renderEmpty()
      ) : (
        <TableComponent
          tableHead={tableHead}
          tableBody={tableBody}
          showPagination
          page={page}
          tableStyle={{ minWidth: 650 }}
          handleChange={handleChange}
          // @ts-ignore
          count={Math.ceil(meta.total / meta.per_page)}
        />
      )}
      <ConfirmationModal
        title={t('user_table.delete_expense')}
        titleIconName="QuestionMarkOutlined"
        description={t('user_table.are_you_sure_delete_the_expense')}
        open={deleteConfirmation}
        onClose={() => {
          setDeleteConfirmation(false);
          setItemIdToDelete(null);
        }}
        positiveAction={t('user_table.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('user_table.cancel')}
        onNegativeClick={() => {
          setDeleteConfirmation(false);
          setItemIdToDelete(null);
        }}
      />
      <TextModal
        open={textOpenModal}
        onClose={() => {
          setTextOpenModal(false);
        }}
        item={textModal}
      />
    </Paper>
  );
}

export default ExpensesTable;
