import { Box, Typography, ButtonGroup, IconButton } from '@mui/material';
import React from 'react';
import Icon from '../../../components/Icon';
import arrayKeyHasPermission from '../../../helpers/arrayKeyHasPermission';
import useProfile from '../../../hooks/useProfile';

interface Props {
  title: string;
  onAddClick: () => void;
  onEditClick: () => void;
}

function ListHeader({ title, onAddClick, onEditClick }: Props) {
  const { meProfile } = useProfile();

  const hasAddPermission = arrayKeyHasPermission(
    ['create-category'],
    meProfile?.permissions || [],
  );

  const hasUpdatePermission = arrayKeyHasPermission(
    ['update-category', 'delete-category', 'move-category-sequence'],
    meProfile?.permissions || [],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        px: 2,
        pb: 1,
      }}
    >
      <Typography textAlign="center" textOverflow="ellipsis" variant="h6">
        {title}
      </Typography>
      <ButtonGroup variant="outlined" aria-label="outlined button group">
        {hasAddPermission && (
          <IconButton aria-label="Add" color="primary" onClick={onAddClick}>
            <Icon name="Add" />
          </IconButton>
        )}
        {hasUpdatePermission && (
          <IconButton aria-label="Edit" color="success" onClick={onEditClick}>
            <Icon name="Edit" />
          </IconButton>
        )}
      </ButtonGroup>
    </Box>
  );
}

export default ListHeader;
