/** Dependencies */
import { useAppDispatch, useAppSelector } from '../../store';

/** Contexts */
import {
  addBasketItem,
  removeBasketItem,
  increaseBasketItem,
  decreaseBasketItem,
  selectBasketProps,
  addMenuBasketItem,
  removeMenuBasketItem,
  increaseMenuBasketItem,
  decreaseMenuBasketItem,
  selectMenuBasketProps,
  addMenuBasketItemForBasket,
  clearBasketItems,
  clearMenuItems,
  setTreat,
  addDescriptionBasketItem,
} from '../../store/slices/basketItemSlice/basketItemSlice';

/** Models */
import { BasketProduct } from '../../models/Product';
import { ActiveBasketProps } from '../../apps/Pos/Sections/OrderModal/LeftColumn/BasketList/types';
import { MenuProduct, Menus } from '../../store/slices/basketItemSlice/types';

const useBasketItems = () => {
  const dispatch = useAppDispatch();
  const basketItems = useAppSelector(state => state.basketItems.basketItems);
  const productMenus = useAppSelector(state => state.basketItems.productMenus);

  // Basket Items
  const addItemForBasket = (item: BasketProduct, itemId: string) => {
    dispatch(addBasketItem({ item, itemId }));
  };

  const addItemDescriptionForBasket = (
    itemId: string,
    description: string,
    menuItemId?: string,
  ) => {
    const item = basketItems.find(basketItem => basketItem.itemId === itemId);

    let newItem;

    if (menuItemId && item && item.product_menus) {
      newItem = {
        ...item,
        product_menus: item.product_menus.map(menuItem =>
          menuItem.menu_items[0].itemId === menuItemId
            ? {
                ...menuItem,
                menu_items: [
                  {
                    ...menuItem.menu_items[0],
                    description,
                  },
                ],
              }
            : menuItem,
        ),
      };

      dispatch(addDescriptionBasketItem({ item: newItem, itemId }));
    }

    if (item && !menuItemId) {
      newItem = {
        ...item,
        description,
      };

      dispatch(addDescriptionBasketItem({ item: newItem, itemId }));
    }
  };

  const increaseCountForBasket = (itemId: string) => {
    const item: any = basketItems.find(
      basketItem => basketItem.itemId === itemId,
    );
    dispatch(increaseBasketItem(item));
  };

  const decreaseCountForBasket = (itemId: string) => {
    const item: any = basketItems.find(
      basketItem => basketItem.itemId === itemId,
    );
    dispatch(decreaseBasketItem(item));
  };

  const removeItemForBasket = (itemId: string) => {
    const removedItems = basketItems.filter(item => item.itemId !== itemId);
    dispatch(removeBasketItem(removedItems));
  };

  const addItemTreatForBasket = (itemId: string, quantity: number) => {
    let newTreatItem;
    const selectedItem = basketItems.find(item => item.itemId === itemId);

    if (selectedItem?.count !== quantity) {
      newTreatItem = {
        ...selectedItem,
        count: quantity,
        is_treat: true,
        itemId: Date.now().toString(),
      };
    }

    const newItems = basketItems.map(item =>
      item.itemId === itemId
        ? item.count === quantity
          ? { ...item, is_treat: true }
          : { ...item, is_treat: false, count: item.count - quantity }
        : item,
    );

    const totalItems = newTreatItem ? [...newItems, newTreatItem] : newItems;

    dispatch(setTreat(totalItems));
  };

  const removeItemTreatForBasket = (itemId: string, quantity: number) => {
    let newTreatItem;
    const selectedItem = basketItems.find(item => item.itemId === itemId);

    if (selectedItem?.count !== quantity) {
      newTreatItem = {
        ...selectedItem,
        count: quantity,
        is_treat: false,
        itemId: Date.now().toString(),
      };
    }

    const newItems = basketItems.map(item =>
      item.itemId === itemId
        ? item.count === quantity
          ? { ...item, is_treat: false }
          : { ...item, is_treat: true, count: item.count - quantity }
        : item,
    );

    const totalItems = newTreatItem ? [...newItems, newTreatItem] : newItems;

    dispatch(setTreat(totalItems));
  };

  const selectBasketItems = (
    itemId: string,
    activeProps: ActiveBasketProps,
  ) => {
    const arrayPrice = activeProps.activeMultiplePropertyNames.map(
      item => item.price,
    );
    let arrayTotal = 0;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arrayPrice.length; i++) {
      arrayTotal += Number(arrayPrice[i]);
    }

    const items = basketItems.map(item =>
      item.itemId === itemId
        ? {
            ...item,
            non_ingredient_item_id: activeProps.activeIngredientIds,
            active_single_property_id: activeProps.activeSinglePropertyId,
            active_multiple_property_id: activeProps.activeMultiplePropertyIds,
            active_single_property_name: activeProps.activeSinglePropertyName,
            active_multiple_property_names:
              activeProps.activeMultiplePropertyNames,
            total_price:
              Number(
                item?.units?.find(unit => unit.id === activeProps.activeUnitId)
                  ?.amount?.amount,
              ) /
                100 +
              activeProps.activeSinglePropertyName.price +
              arrayTotal,
            active_unit: item.units.find(
              unit => unit.id === activeProps.activeUnitId,
            ),
          }
        : item,
    );

    dispatch(selectBasketProps(items));
  };

  // Menus
  const addItemForMenu = (item: MenuProduct, menuId: string) => {
    let findedItem: any;
    let items: Menus[];
    items = productMenus;

    if (items.length === 0) {
      items = [
        {
          id: menuId,
          menu_items: [
            {
              ...item,
              itemId: Date.now().toString(),
              count: 1,
            },
          ],
        },
      ];
    } else {
      findedItem = productMenus?.find(finded => finded.id === menuId);
      if (findedItem) {
        items = [
          ...items.filter(filtered => filtered.id !== menuId),
          {
            id: menuId,
            menu_items: [
              ...findedItem.menu_items,
              {
                ...item,
                itemId: Date.now().toString(),
                count: 1,
              },
            ],
          },
        ];
      } else {
        items = [
          ...items,
          {
            id: menuId,
            menu_items: [
              {
                ...item,
                itemId: Date.now().toString(),
                count: 1,
              },
            ],
          },
        ];
      }
    }

    dispatch(addMenuBasketItem(items));
  };

  const addItemsForMenu = (menuItems: Menus[]) => {
    dispatch(addMenuBasketItem(menuItems));
  };

  const increaseCountForMenu = (itemId: string, menuId: string) => {
    const items = productMenus.map(item =>
      item.id === menuId
        ? {
            id: item.id,
            // eslint-disable-next-line @typescript-eslint/no-shadow
            menu_items: item.menu_items.map(menuItem =>
              menuItem.itemId === itemId
                ? {
                    ...menuItem,
                    count: menuItem.count + 1,
                  }
                : menuItem,
            ),
          }
        : item,
    );

    dispatch(increaseMenuBasketItem(items));
  };

  const decreaseCountForMenu = (itemId: string, menuId: string) => {
    const items = productMenus.map(item =>
      item.id === menuId
        ? {
            id: item.id,
            // eslint-disable-next-line @typescript-eslint/no-shadow
            menu_items: item.menu_items.map(menuItem =>
              menuItem.itemId === itemId
                ? {
                    ...menuItem,
                    count: menuItem.count - 1,
                  }
                : menuItem,
            ),
          }
        : item,
    );

    dispatch(decreaseMenuBasketItem(items));
  };

  const removeItemForMenu = (
    item: MenuProduct,
    menuId: string,
    itemId: string,
  ) => {
    let items: any[] = [];
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const findItemsLength = productMenus?.find(item => item.id === menuId)
      ?.menu_items.length;

    if (findItemsLength === 1) {
      items = productMenus.filter(productItem => productItem.id !== menuId);
    } else {
      items = productMenus.map(productItem =>
        productItem.id === menuId
          ? {
              id: productItem.id,
              menu_items: productItem.menu_items.filter(
                menuItems => menuItems.itemId !== itemId,
              ),
            }
          : productItem,
      );
    }

    dispatch(removeMenuBasketItem(items));
  };

  const selectMenuBasketItems = (
    itemId: string,
    menuId: string,
    activeProps: ActiveBasketProps,
  ) => {
    const arrayPrice = activeProps.activeMultiplePropertyNames.map(
      item => item.price,
    );
    let arrayTotal = 0;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arrayPrice.length; i++) {
      arrayTotal += Number(arrayPrice[i]);
    }

    const items = productMenus.map(item =>
      item.id === menuId
        ? {
            id: item.id,
            // eslint-disable-next-line @typescript-eslint/no-shadow
            menu_items: item.menu_items.map(menuItem =>
              menuItem.itemId === itemId
                ? {
                    ...menuItem,
                    non_ingredient_item_id: activeProps.activeIngredientIds,
                    active_single_property_id:
                      activeProps.activeSinglePropertyId,
                    active_multiple_property_id:
                      activeProps.activeMultiplePropertyIds,
                    active_single_property_name:
                      activeProps.activeSinglePropertyName,
                    active_multiple_property_names:
                      activeProps.activeMultiplePropertyNames,
                    total_price:
                      activeProps.activeSinglePropertyName.price + arrayTotal,
                  }
                : menuItem,
            ),
          }
        : item,
    );

    dispatch(selectMenuBasketProps(items));
  };

  const selectMenuBasketItemsForBasketItem = (basketItemId: string) => {
    let totalPrice = 0;
    // @ts-ignore
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < productMenus.length; i++) {
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < productMenus[i].menu_items.length; j++) {
        totalPrice +=
          ((productMenus[i].menu_items[j].total_price || 0) +
            Number(productMenus[i].menu_items[j].additional_price.amount) /
              100 || 0) * productMenus[i].menu_items[j].count;
      }
    }

    const items = basketItems.map(basketItem =>
      basketItem.itemId === basketItemId
        ? {
            ...basketItem,
            product_menus: productMenus,
            total_price:
              totalPrice +
              Number(
                basketItem?.units?.find(unit => unit.is_default)?.amount.amount,
              ) /
                100,
          }
        : basketItem,
    );

    dispatch(addMenuBasketItemForBasket(items));
  };

  const clearItemsForBasket = () => {
    dispatch(clearBasketItems());
  };

  const clearItemsForMenu = () => {
    dispatch(clearMenuItems());
  };

  return {
    basketItems,
    productMenus,
    addItemForBasket,
    removeItemForBasket,
    increaseCountForBasket,
    decreaseCountForBasket,
    selectBasketItems,
    selectMenuBasketItems,
    addItemForMenu,
    addItemsForMenu,
    increaseCountForMenu,
    decreaseCountForMenu,
    removeItemForMenu,
    selectMenuBasketItemsForBasketItem,
    clearItemsForBasket,
    clearItemsForMenu,
    addItemTreatForBasket,
    removeItemTreatForBasket,
    addItemDescriptionForBasket,
  };
};

export default useBasketItems;
