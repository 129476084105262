import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/Modal';
import OrderedList from '../../../../../components/OrderedList';
import { PropertyItemWithActive } from '../../../../../services/api/definitions/propertyGroups/types';

interface Item extends PropertyItemWithActive {
  name: string;
}

interface Props {
  items: Item[] | null;
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onItemEdit: (item: Item) => void;
  onItemMoveUp: (id: string) => void;
  onItemMoveDown: (id: string) => void;
}

function PropertiesOrderModal({
  items,
  open,
  loading,
  onClose,
  onItemEdit,
  onItemMoveUp,
  onItemMoveDown,
}: Props) {
  const { t } = useTranslation('product');

  if (!items) return null;
  // todo: add empty state to modal body

  return (
    <Modal
      title={t('properties.orderModal.title')}
      open={open}
      onClose={onClose}
      positiveAction={t('properties.orderModal.close')}
      onPositiveClick={onClose}
    >
      <OrderedList
        items={items}
        loading={loading}
        onItemEdit={item => onItemEdit(item)}
        onItemUp={item => onItemMoveUp(item.id)}
        onItemDown={item => onItemMoveDown(item.id)}
        removeButtons={false}
      />
    </Modal>
  );
}

export { PropertiesOrderModal };
