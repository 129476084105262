/** Dependencies */
import {
  Box,
  Divider,
  Paper,
  Stack,
  Tooltip,
  Typography,
  InputBase,
  InputAdornment,
  Grid,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Components */
import IconButton from '../../../../../components/IconButton';
import LoadingState from '../../../../../components/LoadingState';
import BasketList from './BasketList';
import Footer from './Footer';

/** Models */
import { BasketProduct } from '../../../../../models/Product';

/** Styles */
import styles from './styles';

/** Types */
import { ExtraParams, SpecialOrder, TotalPrices } from '../types';
import { SelfPayment } from '../PaymentModal/types';

/** Hooks */
import useProfile from '../../../../../hooks/useProfile';

/** Helpers */
import keyHasPermission from '../../../../../helpers/keyHasPermission';

interface Props {
  list: BasketProduct[];
  specialOrderList: SpecialOrder[];
  onIncrease: (productId: string, itemId: string) => void;
  onDecrease: (id: string) => void;
  onDelete: (id: string) => void;
  onAction: (id: string) => void;
  onDiscountPress: () => void;
  onPaymentPress: () => void;
  onFastPaymentPress: () => void;
  onPackagePaymentPress: () => void;
  onBackClick: () => void;
  onCustomerAdd: () => void;
  originalTableName: string;
  tableName: string;
  onTableNameChange: (name: string) => void;
  submitting: boolean;
  onSubmit: () => void;
  onPackageSubmit: () => void;
  loading: boolean;
  error: Error | null;
  totalPrices: TotalPrices;
  extraParams: ExtraParams;
  onSelectionModal: { isSelected: boolean; id: string };
  setOnSelectionModal: React.Dispatch<
    React.SetStateAction<{ isSelected: boolean; id: string }>
  >;
  onSelectionMenuModal: { isSelected: boolean; id: string };
  setOnSelectionMenuModal: React.Dispatch<
    React.SetStateAction<{ isSelected: boolean; id: string }>
  >;
  setSpecialOrders: (
    value: ((prevState: SpecialOrder[]) => SpecialOrder[]) | SpecialOrder[],
  ) => void;
  isActiveTransaction: boolean;
  currencyCode: string;
  selectedCustomer: {
    first_name?: string;
    last_name?: string;
  };
  onRemove: (removedPrice: number, orderId: string) => void;
  isPackageOrder: boolean;
  isSelfOrder: boolean;
  selectedPaymentName: string;
  onSelfSubmit: (selfPayment: SelfPayment | null) => void;
  isAvailableToOrder: boolean;
  onTreat: () => void;
  handleCountChange: (
    guestsCount: number,
    autoCouvert: boolean,
  ) => Promise<void>;
  onServiceChargePress: (isAuto: boolean) => void;
  isAutoCouvert: boolean;
  isAutoServiceCharge: boolean;
  colorStatus: { id: number; name: string } | undefined;
}
function LeftColumn({
  selectedCustomer,
  list,
  specialOrderList,
  onIncrease,
  onDecrease,
  onDelete,
  onAction,
  onDiscountPress,
  onPaymentPress,
  onFastPaymentPress,
  onPackagePaymentPress,
  onBackClick,
  onCustomerAdd,
  originalTableName,
  tableName,
  onTableNameChange,
  submitting,
  onSubmit,
  onPackageSubmit,
  loading,
  error,
  totalPrices,
  extraParams,
  onSelectionModal,
  setOnSelectionModal,
  onSelectionMenuModal,
  setOnSelectionMenuModal,
  isActiveTransaction,
  setSpecialOrders,
  currencyCode,
  onRemove,
  isPackageOrder,
  isSelfOrder,
  selectedPaymentName,
  onSelfSubmit,
  isAvailableToOrder,
  onTreat,
  handleCountChange,
  onServiceChargePress,
  isAutoCouvert,
  isAutoServiceCharge,
  colorStatus,
}: Props) {
  const { t } = useTranslation('pos');
  const { meProfile } = useProfile();
  const [editMode, setEditMode] = useState(false);
  const [localTableName, setLocalTableName] = useState('');

  const hasSetTableNamePermission = keyHasPermission(
    'set-table-name',
    meProfile?.permissions || [],
  );
  const hasSetCustomerPermission = keyHasPermission(
    'set-customer',
    meProfile?.permissions || [],
  );

  useEffect(() => {
    setLocalTableName(tableName);
  }, []);

  return (
    <Paper sx={styles.container} elevation={5}>
      <Stack sx={styles.innerContainer} direction="column">
        <Stack
          sx={styles.header}
          direction="row"
          divider={<Divider orientation="vertical" sx={styles.divider} />}
        >
          <Tooltip title={t('orderModal.buttons.back')} arrow>
            <IconButton
              sx={styles.icon}
              iconName="ArrowBack"
              radius={0}
              onClick={onBackClick}
            />
          </Tooltip>
          <Stack
            sx={styles.search}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {!editMode ? (
              <>
                <Grid container spacing={2}>
                  <Grid xs={12}>
                    <Typography fontSize="large" sx={{ mt: 1, ml: 1 }}>
                      {isPackageOrder
                        ? t('orderModal.packageOrderName')
                        : isSelfOrder
                        ? t('orderModal.selfOrderName')
                        : localTableName || tableName || originalTableName}
                    </Typography>
                  </Grid>
                  {selectedCustomer.first_name && (
                    <Grid xs={12}>
                      <Typography fontSize="medium" sx={{ mb: 1, ml: 1 }}>
                        {`${selectedCustomer.first_name}  ${selectedCustomer.last_name}`}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                {hasSetTableNamePermission &&
                  !isPackageOrder &&
                  !isSelfOrder && (
                    <IconButton
                      sx={styles.searchIcon}
                      iconName="Edit"
                      onClick={() => {
                        setLocalTableName('');
                        setEditMode(true);
                      }}
                    />
                  )}
              </>
            ) : (
              <InputBase
                sx={styles.searchInput}
                placeholder={tableName || originalTableName}
                value={localTableName}
                onChange={e => setLocalTableName(e.target.value)}
                inputProps={{ disableUnderline: true }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      sx={styles.searchIcon}
                      iconName="Check"
                      onClick={() => {
                        onTableNameChange(localTableName);
                        setEditMode(false);
                      }}
                    />
                    <IconButton
                      sx={styles.searchIcon}
                      iconName="Close"
                      onClick={() => setEditMode(false)}
                    />
                  </InputAdornment>
                }
              />
            )}
          </Stack>
          <Tooltip title={t('orderModal.buttons.customer')} arrow>
            <IconButton
              sx={styles.iconButton}
              iconName="Person"
              radius={0}
              onClick={onCustomerAdd}
              badgeDot={!!extraParams.customerId}
              disabled={!hasSetCustomerPermission}
            />
          </Tooltip>
        </Stack>
        {isActiveTransaction && !isPackageOrder && !isSelfOrder && (
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'end',
              fontSize: '13px',
              padding: '3px',
            }}
          >
            {t('order_status')}:
            <div
              style={{
                color: colorStatus?.id === 2 ? 'red' : 'green',
                marginLeft: '2px',
              }}
            >
              {colorStatus?.name}
            </div>
          </Grid>
        )}
        <Box sx={styles.basket}>
          {loading ? (
            <LoadingState />
          ) : (
            <BasketList
              list={list}
              isAvailableToOrder={isAvailableToOrder}
              specialOrderList={specialOrderList}
              // Functions
              onIncrease={onIncrease}
              onDecrease={onDecrease}
              onDelete={onDelete}
              onAction={onAction}
              // Selection Modal
              onSelectionModal={onSelectionModal}
              setOnSelectionModal={setOnSelectionModal}
              onSelectionMenuModal={onSelectionMenuModal}
              setOnSelectionMenuModal={setOnSelectionMenuModal}
              setSpecialOrders={setSpecialOrders}
              currencyCode={currencyCode}
              onRemove={onRemove}
              onTreat={onTreat}
            />
          )}
        </Box>
        <Box sx={styles.footer}>
          <Footer
            listLength={list.length + specialOrderList.length}
            submitting={submitting}
            onSubmit={onSubmit}
            onPackageSubmit={onPackageSubmit}
            totalPrices={totalPrices}
            onDiscountPress={onDiscountPress}
            onPaymentPress={onPaymentPress}
            onFastPaymentPress={onFastPaymentPress}
            onPackagePaymentPress={onPackagePaymentPress}
            isActiveTransaction={isActiveTransaction}
            currencyCode={currencyCode}
            isPackageOrder={isPackageOrder}
            isSelfOrder={isSelfOrder}
            selectedPaymentName={selectedPaymentName}
            onSelfSubmit={onSelfSubmit}
            handleCountChange={handleCountChange}
            guestCount={extraParams.guestsCount}
            onServiceChargePress={onServiceChargePress}
            isAutoCouvert={isAutoCouvert}
            isAutoServiceCharge={isAutoServiceCharge}
          />
        </Box>
      </Stack>
    </Paper>
  );
}

export default LeftColumn;
