import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../../components/ConfirmationModal';
import EmptyState from '../../../components/EmptyState';
import Modal from '../../../components/Modal';
import OrderedList from '../../../components/OrderedList';
import { SectionBase } from './types';
import keyHasPermission from '../../../helpers/keyHasPermission';
import useProfile from '../../../hooks/useProfile';

interface Props {
  items: SectionBase[];
  open: boolean;
  onClose: () => void;
  onItemEdit: (item: SectionBase) => void;
  onItemRemove: (id: string) => Promise<any>;
  onItemMoveUp: (id: string) => Promise<any>;
  onItemMoveDown: (id: string) => Promise<any>;
}

function SectionEditModal({
  items,
  open,
  onClose,
  onItemEdit,
  onItemRemove,
  onItemMoveUp,
  onItemMoveDown,
}: Props) {
  const { t } = useTranslation('table');
  const { meProfile } = useProfile();

  const [loading, setLoading] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(
    null,
  );

  const handleRemove = async () => {
    if (deleteConfirmation) {
      setLoading(true);
      await onItemRemove(deleteConfirmation);
      setLoading(false);
      setDeleteConfirmation(null);
    }
  };

  const handleMoveUp = async (item: SectionBase) => {
    setLoading(true);
    await onItemMoveUp(item.id);
    setLoading(false);
  };

  const handleMoveDown = async (item: SectionBase) => {
    setLoading(true);
    await onItemMoveDown(item.id);
    setLoading(false);
  };

  return (
    <>
      <Modal
        title={t('section.editModal.title')}
        open={open}
        onClose={onClose}
        positiveAction={t('section.editModal.close')}
        onPositiveClick={onClose}
      >
        {items.length ? (
          <OrderedList
            items={items}
            loading={loading}
            onItemEdit={item => onItemEdit(item)}
            onItemRemove={item => setDeleteConfirmation(item.id)}
            onItemUp={handleMoveUp}
            onItemDown={handleMoveDown}
            sortButtons={keyHasPermission(
              'move-section-sequence' || '',
              meProfile?.permissions || [],
            )}
            removeButtons={keyHasPermission(
              'update-section' || '',
              meProfile?.permissions || [],
            )}
            editButtons={keyHasPermission(
              'update-section' || '',
              meProfile?.permissions || [],
            )}
          />
        ) : (
          <EmptyState message={t('section.editModal.empty')} />
        )}
      </Modal>
      <ConfirmationModal
        title={t('section.deleteConfirmation.title')}
        titleIconName="QuestionMarkOutlined"
        description={t('section.deleteConfirmation.description')}
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        positiveAction={t('section.deleteConfirmation.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('section.deleteConfirmation.cancel')}
        onNegativeClick={() => setDeleteConfirmation(null)}
        loading={loading}
      />
    </>
  );
}

export default SectionEditModal;
