import api from '../../api';
import { ApiSuccessResponse } from '../../types';
import {
  CreateResponse,
  ListResponse,
  ViewResponse,
  UpdateResponse,
  DeleteResponse,
  UserCreateParams,
  UserUpdateParams,
} from './types';

type ListRes = ApiSuccessResponse<ListResponse>;
const list = (page: number, role?: string) =>
  api.get<ListRes>('/users', {
    params: {
      page,
      filter: {
        role,
      },
    },
  });

type ViewRes = ApiSuccessResponse<ViewResponse>;
const view = (id: string) => api.get<ViewRes>(`/users/${id}`);

type CreatRes = ApiSuccessResponse<CreateResponse>;
const create = (params: UserCreateParams) =>
  api.post<CreatRes>('/users', params);

type UpdateRes = ApiSuccessResponse<UpdateResponse>;
const update = (params: UserUpdateParams) => {
  const { userId } = params;
  return api.put<UpdateRes>(`/users/${userId}`, params);
};

const changeBranch = async (branch_id: string) =>
  api.post('/users/change-branch', { branch_id });

type DeleteRes = ApiSuccessResponse<DeleteResponse>;
const remove = (userId: string) => api.delete<DeleteRes>(`/users/${userId}`);

export default {
  list,
  view,
  create,
  update,
  remove,
  changeBranch,
};
