import { SxProps, Theme } from '@mui/material';

const styles: Record<string, SxProps<Theme>> = {
  boxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    px: 2,
    pb: 1,
  },
};

export default styles;
