/** Dependencies */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import { Box, Paper, Tab, Tabs } from '@mui/material';

/** Components */
import TabPanel from '../../CompanyRegister/TabPanel';
import ChangeUserInfoForm from './ChangeUserInfoForm';
import ChangePasswordForm from './ChangePasswordForm';

/** Helpers */
import { a11yProps } from '../../../helpers/rgbToHex';

function Form() {
  const { t } = useTranslation('auth');
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Paper sx={{ p: 3, flex: 1 }} elevation={3}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={t('profile.update_profile.label')} {...a11yProps(0)} />
            <Tab label={t('profile.change_password.label')} {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ChangeUserInfoForm />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ChangePasswordForm />
        </TabPanel>
      </Paper>
    </Box>
  );
}

export default Form;
