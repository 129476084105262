import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getExpenseCategoryListThunk } from './thunks';

import { InitialState } from './types';
import { ExpenseCategory } from '../../../../services/api/operations/expenseCategories/types';

const initialState: InitialState = { loading: false, expenseCategories: [] };

const expenseCategoriesSlice = createSlice({
  name: 'expenseCategoriesSlice',
  initialState,
  reducers: {
    addExpenseCategorySlice: (
      state,
      action: PayloadAction<ExpenseCategory>,
    ) => ({
      ...state,
      expenseCategories: [...state.expenseCategories, action.payload],
    }),
    updateExpenseCategorySlice: (
      state,
      action: PayloadAction<ExpenseCategory>,
    ) => ({
      ...state,
      expenseCategories: state.expenseCategories.map(item =>
        item.id === action.payload.id ? action.payload : item,
      ),
    }),
    removeExpenseCategorySlice: (
      state,
      action: PayloadAction<Pick<ExpenseCategory, 'id'>>,
    ) => ({
      ...state,
      expenseCategories: state.expenseCategories.filter(
        item => item.id !== action.payload.id,
      ),
    }),
  },
  extraReducers: builder => {
    builder
      .addCase(getExpenseCategoryListThunk.pending, state => ({
        ...state,
        loading: true,
      }))
      .addCase(getExpenseCategoryListThunk.fulfilled, (state, action) => ({
        ...state,
        expenseCategories: action.payload,
        loading: false,
      }))
      .addCase(getExpenseCategoryListThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }));
  },
});

export const {
  addExpenseCategorySlice,
  updateExpenseCategorySlice,
  removeExpenseCategorySlice,
} = expenseCategoriesSlice.actions;

export default expenseCategoriesSlice.reducer;
