import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialState } from './types';
import { BasketProduct } from '../../../models/Product';

const initialState: InitialState = {
  basketItems: [],
  productMenus: [],
};

const basketItemSlice = createSlice({
  name: 'basketItemSlice',
  initialState,
  reducers: {
    // Basket Items
    addBasketItem: (
      state,
      action: PayloadAction<{ item: BasketProduct; itemId: string }>,
    ) => ({
      ...state,
      basketItems: [
        ...state.basketItems,
        {
          ...action.payload.item,
          itemId: action.payload.itemId,
          count: 1,
          selected_orders_count: 0,
          selected_piece_orders: {
            id: action.payload.item.id,
            quantity: 0,
            remainingPiece: 1,
          },
        },
      ],
    }),
    addDescriptionBasketItem: (
      state,
      action: PayloadAction<{ item: BasketProduct; itemId: string }>,
    ) => ({
      ...state,
      basketItems: state.basketItems.map(item =>
        item.itemId !== action.payload.itemId ? item : action.payload.item,
      ),
    }),
    selectBasketProps: (state, action) => ({
      ...state,
      basketItems: action.payload,
    }),
    setTreat: (state, action) => ({
      ...state,
      basketItems: action.payload,
    }),
    increaseBasketItem: (state, action: PayloadAction<BasketProduct>) => ({
      ...state,
      basketItems: state.basketItems.map(item =>
        item.itemId !== action.payload.itemId
          ? item
          : {
              ...action.payload,
              count: action.payload.count + 1,
            },
      ),
    }),
    decreaseBasketItem: (state, action: PayloadAction<BasketProduct>) => ({
      ...state,
      basketItems: state.basketItems.map(item =>
        item.itemId !== action.payload.itemId
          ? item
          : {
              ...action.payload,
              count: action.payload.count - 1,
            },
      ),
    }),
    removeBasketItem: (state, action: PayloadAction<BasketProduct[]>) => ({
      ...state,
      basketItems: action.payload,
    }),

    clearBasketItems: state => ({
      ...state,
      basketItems: [],
    }),

    // Menus
    addMenuBasketItem: (state, action) => ({
      ...state,
      productMenus: action.payload,
    }),
    addMenuBasketItemForBasket: (state, action) => ({
      ...state,
      basketItems: action.payload,
      productMenus: [],
    }),
    selectMenuBasketProps: (state, action) => ({
      ...state,
      productMenus: action.payload,
    }),
    increaseMenuBasketItem: (state, action) => ({
      ...state,
      productMenus: action.payload,
    }),
    decreaseMenuBasketItem: (state, action) => ({
      ...state,
      productMenus: action.payload,
    }),
    removeMenuBasketItem: (state, action) => ({
      ...state,
      productMenus: action.payload,
    }),

    clearMenuItems: state => ({
      ...state,
      productMenus: [],
    }),
  },
});

export const {
  addBasketItem,
  addDescriptionBasketItem,
  removeBasketItem,
  increaseBasketItem,
  decreaseBasketItem,
  selectBasketProps,
  addMenuBasketItem,
  removeMenuBasketItem,
  increaseMenuBasketItem,
  decreaseMenuBasketItem,
  selectMenuBasketProps,
  addMenuBasketItemForBasket,
  clearBasketItems,
  clearMenuItems,
  setTreat,
} = basketItemSlice.actions;

export default basketItemSlice.reducer;
