/** Dependencies */
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/** Styles */
import { grey } from '@mui/material/colors';
import { Avatar, Badge } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Logout from '@mui/icons-material/Logout';
import AccountCircle from '@mui/icons-material/AccountCircle';

/** Hooks */
import useAuth from '../../hooks/useAuth';
import useProfile from '../../hooks/useProfile';
import { expiredAccountStorage } from '../../services/tokenStorage';

interface Props {
  label?: string;
}

export default function AccountMenu({ label }: Props) {
  const { logout } = useAuth();
  const { meProfile } = useProfile();
  const navigate = useNavigate();
  const { t } = useTranslation('layout');

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const token = expiredAccountStorage.getToken();
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        size="large"
        aria-label="account"
        sx={{ color: grey[700], borderRadius: 0 }}
        onClick={handleClick}
      >
        <Badge>
          <AccountCircle />
        </Badge>
        <p
          style={{
            color: '#181C32',
            fontSize: '14px',
            fontWeight: '500',
            marginLeft: '3px',
          }}
        >{`${meProfile?.firstName} ${meProfile?.lastName}`}</p>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          disabled={Boolean(token)}
          onClick={() => navigate('/profile')}
        >
          <Avatar /> {t('topbar.profile')}
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => logout()}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t('topbar.logout')}
        </MenuItem>
      </Menu>
    </>
  );
}
