import { Typography, TextFieldProps } from '@mui/material';
import React, { forwardRef } from 'react';
import { MuiTelInput } from 'mui-tel-input';
import { NumberFormatProps } from 'react-number-format';

interface Props {
  maskProps?: NumberFormatProps;
  phoneValue: string;
  handleChange(newValue: any, info: any): void;
}

function FormPhoneInput(
  {
    label,
    sx,
    maskProps,
    phoneValue,
    handleChange,
    ...props
  }: Props & TextFieldProps,
  ref: TextFieldProps['ref'],
) {
  return (
    <>
      <Typography variant="subtitle1">{label}</Typography>
      <MuiTelInput
        {...props}
        defaultCountry="TR"
        color="success"
        variant="filled"
        // placeholder="555 333 22 11"
        value={phoneValue}
        onChange={handleChange}
        splitCallingCode
        langOfCountryName="tr"
        preferredCountries={['TR']}
        InputProps={{
          sx: {
            '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
              border: 'none',
              borderBottom: '1px solid gray',
              borderRadius: 0,
              paddingBottom: '12px',
            },
            pb: 2,
          },
        }}
        fullWidth
      />
    </>
  );
}

export default forwardRef(FormPhoneInput);
