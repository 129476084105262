import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import {
  Box,
  CSSObject,
  List,
  ListItem,
  Stack,
  styled,
  Theme,
  Tooltip,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import SideBarList from './SideBarList';
import DrawerButton from './DrawerButton';
import MenuButton from '../../apps/Pos/SideMenu/MenuButton';
import {
  expiredAccountStorage,
  salesChannelNameStorage,
  salesChannelStorage,
} from '../../services/tokenStorage';
import SelectSalesChannelModal from '../../apps/Pos/Sections/Modals/SelectSalesChannelModal';
import useSettings from '../../hooks/useSettings';

export const DRAWER_WIDTH = 340;
const BASE_PATH = '/app/pos';

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('xl')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  minWidth: '50px',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const menuMixin = (theme: Theme): CSSObject => ({
  overflowX: 'hidden',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  width: `calc(${theme.spacing(7)} + 5px)`,
  // width: theme.spacing(MENU_WIDTH),
  zIndex: theme.zIndex.appBar - 1,
});

const PosDrawer = styled(MuiDrawer)(({ theme }) => ({
  ...menuMixin(theme),
  '& .MuiDrawer-paper': {
    ...menuMixin(theme),
    backgroundColor: theme.palette.grey[800],
  },
}));

interface Props {
  variant?: DrawerProps['variant'];
  drawerExpanded: boolean;
  onClose: () => void;
  onDrawerToggle: () => void;
  onLogout: () => void;
}

export default function SideBar({
  variant = 'permanent',
  drawerExpanded,
  onClose,
  onDrawerToggle,
  onLogout,
}: Props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation('pos');
  const { meProfile, channelSales, fetchChannelSales } = useSettings();

  const [open, setOpen] = useState<boolean>(false);
  const [salesChannelId, setSalesChannelId] = useState<string>('');
  const [salesChannelName, setSalesChannelName] = useState<string>('');

  const isAppScreen = pathname.includes('app');
  const isAppsScreen = pathname.includes('apps');
  const isTransitionScreen = pathname.includes('order');
  const isOrdersScreen = pathname.includes('orders');
  const isKitchenScreen = pathname.includes('kitchen');

  useEffect(() => {
    const interval = setInterval(() => {
      const newData = localStorage.getItem('SALES_CHANNEL') || '';
      if (newData !== salesChannelId) {
        setSalesChannelId(newData);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [salesChannelId]);

  const token = expiredAccountStorage.getToken();

  useEffect(() => {
    setSalesChannelId(salesChannelStorage.getToken() || '');
    setSalesChannelName(salesChannelNameStorage.getToken() || '');
  }, [channelSales]);

  if (
    (isAppScreen && !isAppsScreen && !isTransitionScreen && !isKitchenScreen) ||
    isOrdersScreen
  ) {
    if (drawerExpanded) {
      return (
        <Drawer
          variant={isAppScreen ? 'temporary' : variant}
          open={drawerExpanded}
          onClose={onClose}
        >
          <DrawerHeader>
            <DrawerButton onClick={onDrawerToggle} />
          </DrawerHeader>
          <SideBarList
            onDrawerToggle={onDrawerToggle}
            onLogout={onLogout}
            drawerExpanded={drawerExpanded}
            onLinkPress={link => {
              navigate(link);
            }}
            token={token}
          />
        </Drawer>
      );
    }
    return (
      <PosDrawer variant="permanent" anchor="left">
        <Stack justifyContent="space-between" sx={{ height: '100%' }}>
          <Box>
            <List>
              <ListItem disablePadding divider>
                <MenuButton
                  iconName="Menu"
                  text={t('sideMenu.menu')}
                  onClick={onDrawerToggle}
                />
              </ListItem>
              {meProfile?.workingTypes?.find(item => item.id === 1) && (
                <ListItem disablePadding divider>
                  <MenuButton
                    iconName="ViewQuilt"
                    text={t('sideMenu.sections')}
                    onClick={() => navigate('app/pos')}
                    selected={
                      pathname === BASE_PATH || pathname === `${BASE_PATH}`
                    }
                  />
                </ListItem>
              )}
              {(meProfile?.workingTypes?.find(item => item.id === 2) ||
                meProfile?.workingTypes?.find(item => item.id === 3)) && (
                <ListItem disablePadding divider>
                  <MenuButton
                    iconName="ViewColumn"
                    text={t('sideMenu.packageOrder')}
                    onClick={() => navigate('/app/pos/orders')}
                    selected={pathname === `/app/pos/orders`}
                  />
                </ListItem>
              )}
              {channelSales.length > 1 && (
                <Tooltip
                  title={
                    salesChannelName ||
                    channelSales?.find(item => item.id === salesChannelId)
                      ?.name ||
                    ''
                  }
                  placement="right"
                >
                  <ListItem disablePadding sx={{ mt: 1 }}>
                    <MenuButton
                      iconName="ViewCarousel"
                      text=""
                      onClick={() => setOpen(true)}
                    />
                  </ListItem>
                </Tooltip>
              )}
            </List>
          </Box>
          <Box>
            <List>
              {meProfile?.workingTypes?.find(item => item.id === 2) && (
                <ListItem disablePadding divider>
                  <MenuButton
                    iconName="StoreMallDirectory"
                    text={t('sideMenu.self')}
                    onClick={() =>
                      navigate(`/app/pos/order/self`, {
                        state: {
                          tableName: null,
                          full: false,
                        },
                      })
                    }
                  />
                </ListItem>
              )}
              {meProfile?.workingTypes?.find(item => item.id === 3) && (
                <ListItem disablePadding divider>
                  <MenuButton
                    iconName="TwoWheeler"
                    text={t('sideMenu.package')}
                    onClick={() =>
                      navigate(`/app/pos/order/package`, {
                        state: {
                          tableName: null,
                          full: false,
                        },
                      })
                    }
                    selected={pathname === `${BASE_PATH}/kitchen`}
                  />
                </ListItem>
              )}
            </List>
          </Box>
        </Stack>
        <SelectSalesChannelModal
          open={open}
          onClose={() => setOpen(false)}
          setSalesChannelId={setSalesChannelId}
          setSalesChannelName={setSalesChannelName}
        />
      </PosDrawer>
    );
  }

  return (
    <Drawer variant={variant} open={drawerExpanded} onClose={onClose}>
      <DrawerHeader>
        <DrawerButton onClick={onDrawerToggle} />
      </DrawerHeader>
      <SideBarList
        onDrawerToggle={onDrawerToggle}
        onLogout={onLogout}
        drawerExpanded={drawerExpanded}
        onLinkPress={link => {
          navigate(link);
        }}
        token={token}
      />
    </Drawer>
  );
}
