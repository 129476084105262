import { SxProps, Theme } from '@mui/material';

export const POS_HEADER_HEIGHT = 60;
const styles: Record<string, SxProps<Theme>> = {
  container: {
    flex: 1,
    backgroundColor: 'neutral.main',
  },
  paymentTypeButton: {
    height: '100px',
    border: '1px solid #DEDEDE',
    fontSize: '1rem',
    backgroundColor: 'white',
  },
  fastPaymentStack: {
    flex: 1,
    '&:focus': { outline: 'none' },
    outline: 'none',
  },
  packagePaymentStack: {
    flex: 1,
    '&:focus': { outline: 'none' },
    outline: 'none',
  },
};

export default styles;
