import * as Sentry from '@sentry/react';
import { isApiError } from '../services/api/types';

function errorReporting(err: any) {
  if (!isApiError(err)) {
    return Sentry.configureScope(() => Sentry.captureException(err));
  }

  return null;
}

export default errorReporting;
