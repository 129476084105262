import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import FormInput from '../../../../components/FormInput';
import FormSwitch from '../../../../components/FormSwitch';
import LoadingState from '../../../../components/LoadingState';
import getActiveLanguage from '../../../../helpers/getActiveLanguage';
import useUtilities from '../../../../hooks/useUtilities';
import styles from '../../styles';
import {
  RegisterSecondStepFormValues,
  RegisterSecondStepFormValidValues,
  validationSchema,
  defaultValues,
} from './types';

interface Props {
  onPrevClick: () => void;
  onSubmitClick: (values: RegisterSecondStepFormValidValues) => void;
  isLast: boolean;
  isFirst: boolean;
  loading: boolean;
  initialValues: RegisterSecondStepFormValues | null;
}
function SecondStepForm({
  onPrevClick,
  onSubmitClick,
  isLast,
  isFirst,
  loading,
  initialValues,
}: Props) {
  const { t } = useTranslation('companyRegister');
  const { handleSubmit, control, setValue, reset } =
    useForm<RegisterSecondStepFormValues>({
      defaultValues: initialValues || defaultValues,
      resolver: validationSchema,
    });

  const {
    paymentTypes,
    paymentTypesLoading,
    fetchBusinessTypes,
    fetchWorkingTypes,
    fetchCurrencies,
    fetchPaymentTypes,
  } = useUtilities();

  const onSubmit = handleSubmit(data => {
    const paymentTypesValues = data.paymentTypes.map((active, index) => ({
      id: paymentTypes[index].id,
      active,
    }));
    onSubmitClick({ name: data.name, paymentTypes: paymentTypesValues });
  });

  const setDefaultPaymentType = () => {
    const defaultTypeIndex = paymentTypes.findIndex(item => item.is_default);
    if (defaultTypeIndex > -1) {
      setValue(`paymentTypes.${defaultTypeIndex}`, true);
    }
  };

  useEffect(
    () => () => {
      fetchCurrencies();
      fetchWorkingTypes();
      fetchBusinessTypes();
      fetchPaymentTypes();
    },
    [],
  );

  useEffect(() => {
    setDefaultPaymentType();
  }, [paymentTypes]);

  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [initialValues]);

  if (loading) {
    return <LoadingState />;
  }

  return (
    <form onSubmit={onSubmit}>
      <Stack sx={styles.vStack} justifyContent="space-between">
        <Stack sx={styles.fieldsContainer} spacing={2}>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <FormInput
                label={t('company.name.label')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />

          <Box>
            <Typography variant="subtitle1">
              {t('company.paymentType.label')}
            </Typography>
            {paymentTypesLoading ? (
              <LoadingState />
            ) : (
              <Grid container gap={2}>
                {paymentTypes.map((type, index) => (
                  <Grid item>
                    <Controller
                      name={`paymentTypes.${index}`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormSwitch
                          label={getActiveLanguage(type.translations).name}
                          error={fieldState.invalid}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Button onClick={onPrevClick} disabled={isFirst}>
            {t('buttons.prev')}
          </Button>
          <Button type="submit" variant="contained" loading={loading}>
            {isLast ? t('buttons.complete') : t('buttons.next')}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

export default SecondStepForm;
