import { Divider, Paper, Tab, Tabs, Grid, Button } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import LoadingState from '../../../components/LoadingState';
import useProducts from '../../../hooks/useProducts';
import { InfoForm, PropertyForm, IngredientForm, PricingForm } from './forms';
import MenuForm from './forms/MenuForm';
import { stepKeys } from './types';
import errorReporting from '../../../helpers/errorReporting';
import GroupForm from './forms/GroupForm';

function ProductWizard() {
  const { t } = useTranslation('product');
  const { enqueueSnackbar } = useSnackbar();
  const { catId, productId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeStepParam = searchParams.get('step');

  const [activeStep, setActiveStep] = useState(0);

  const {
    addProduct,
    updateProduct,
    loading,
    productInProcessDetails,
    setProductInProgress,
    resetProductInProgress,
    productInprogressLoading,
  } = useProducts();

  const navigate = useNavigate();

  useEffect(() => {
    if (productId) {
      setProductInProgress(productId);
    }
    return () => {
      resetProductInProgress();
    };
  }, [productId]);

  useEffect(() => {
    if (activeStepParam) {
      setActiveStep(parseInt(activeStepParam, 10));
    }
  }, [activeStepParam]);

  const handleNext = () => {
    const step = activeStep + 1;
    setActiveStep(step);
    setSearchParams({ step: step.toString() });
  };

  const handlePrev = () => {
    const step = activeStep - 1;
    setActiveStep(step);
    setSearchParams({ step: step.toString() });
  };

  const renderForm = (step: number) => {
    switch (step) {
      case 0:
        return (
          <InfoForm
            categoryId={catId!}
            onValidSubmit={async values => {
              if (values.is_parent && values.is_menu) {
                enqueueSnackbar(t('wizard.form.infoSelectMenuAndGroupError'), {
                  variant: 'warning',
                  autoHideDuration: 3000,
                });
              } else if (
                values.is_parent &&
                values.is_required_property_selection
              ) {
                enqueueSnackbar(
                  t('wizard.form.infoSelectRequiredPropertyGroupAndGroupError'),
                  {
                    variant: 'warning',
                    autoHideDuration: 3000,
                  },
                );
              } else {
                try {
                  if (productId) {
                    await updateProduct({ productId, ...values });
                  } else {
                    const addedProductId = await addProduct(values);
                    if (addedProductId) navigate(addedProductId);
                  }
                  setActiveStep(activeStep + 1);
                } catch (err) {
                  errorReporting(err);
                }
              }
            }}
            submitting={loading}
            initialValues={productInProcessDetails}
            productId={productId || ''}
          />
        );
      case 1:
        if (productInProcessDetails?.is_parent) {
          return (
            <GroupForm
              onPrevClick={handlePrev}
              isLast={!productInProcessDetails?.is_menu}
              childs={productInProcessDetails?.children?.map(
                (item: { id: string }) => item.id,
              )}
              catId={catId}
            />
          );
        }
        return (
          <PricingForm
            onValidSubmit={handleNext}
            productId={productId}
            onPrevClick={handlePrev}
          />
        );
      case 2:
        if (productInProcessDetails?.is_menu) {
          return (
            <MenuForm
              productId={productId}
              onValidSubmit={() => {
                navigate('/definitions/categories');
              }}
              onPrevClick={handlePrev}
              menus={productInProcessDetails?.menus}
            />
          );
        }
        return (
          <PropertyForm
            onValidSubmit={handleNext}
            onPrevClick={handlePrev}
            initialValues={
              productInProcessDetails?.propertyGroupIds
                ? { groupIds: productInProcessDetails?.propertyGroupIds }
                : undefined
            }
          />
        );

      case 3:
        return (
          <IngredientForm
            onValidSubmit={() => {
              if (productInProcessDetails?.is_menu) {
                handleNext();
              } else {
                navigate('/definitions/categories');
              }
            }}
            onPrevClick={handlePrev}
            initialValues={
              productInProcessDetails?.ingredientGroupIds
                ? { groupIds: productInProcessDetails?.ingredientGroupIds }
                : undefined
            }
            isLast={!productInProcessDetails?.is_menu}
          />
        );
      case 4:
        return (
          <MenuForm
            productId={productId}
            onValidSubmit={() => {
              navigate('/definitions/categories');
            }}
            onPrevClick={handlePrev}
            menus={productInProcessDetails?.menus}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Paper
      sx={{ p: 3, minHeight: '100%', display: 'flex', flexDirection: 'column' }}
      elevation={3}
    >
      <Grid
        item
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Tabs
          value={activeStep}
          onChange={(_, value) => {
            setActiveStep(value);
            setSearchParams({ step: value.toString() });
          }}
          variant="scrollable"
          centered
          scrollButtons="auto"
        >
          {stepKeys
            // index 5 ise ve parent ise göster, değilse index 0 ise göster, değilse index parent değilse göster
            .filter((_, index) =>
              index === 5
                ? productInProcessDetails?.is_parent
                : index === 0
                ? true
                : !productInProcessDetails?.is_parent,
            )
            // index 4 ise ve menü ise göster, değilse göster
            .filter((_, index) =>
              index === 4 ? productInProcessDetails?.is_menu : true,
            )
            .filter((_, index) =>
              index === 3 ? !productInProcessDetails?.is_menu : true,
            )
            .filter((_, index) =>
              index === 2 ? !productInProcessDetails?.is_menu : true,
            )
            .map((step, index) => {
              /**
               * Disable tabs after info if adding new product
               * Disable menu tab if there is no active unit
               */
              const disabled: boolean =
                (!productInProcessDetails && index > 0) ||
                (index === 4 &&
                  !productInProcessDetails?.units?.filter(u => u.isActive)
                    .length);
              return (
                <Tab key={step} label={t(step as any)} disabled={disabled} />
              );
            })}
        </Tabs>
        <Divider />
        <Button onClick={() => navigate('/definitions/categories')}>
          <ArrowBackOutlinedIcon sx={{ width: '25px', height: '20px' }} />
          {t('productWizard.back')}
        </Button>
      </Grid>
      <Grid container sx={{ minHeight: '100%' }}>
        <Grid item xs>
          {productInprogressLoading ? <LoadingState /> : renderForm(activeStep)}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ProductWizard;
