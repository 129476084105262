/** Dependencies */
import React from 'react';
import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

/** Styles */
import styles from '../styles';

/** helpers */
import getActiveLanguage from '../../../../../../../helpers/getActiveLanguage';

/** Components */
import Icon from '../../../../../../../components/Icon';

/** Types */
import { MenuItem, SelectedMenuItem } from '../types';

interface Props {
  handleAddMenuItem: (item: MenuItem, selectedItem: SelectedMenuItem) => void;
  selectedItem: SelectedMenuItem;
}

function MenuModalRightSideProducts({
  handleAddMenuItem,
  selectedItem,
}: Props) {
  const { t } = useTranslation('pos');

  const addIconStyle = {
    ...styles.textButtonContainer,
    height: '100%',
    background: 'rgb(0 0 0 / 16%)',
  };

  return (
    <Box sx={styles.gridContainer}>
      <Grid container gap={3}>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="left"
            spacing={{ xs: 3, sm: 1, lg: 3 }}
          >
            {!selectedItem.selectedItem.length ? (
              <Grid sx={{ textAlign: 'center', alignItems: 'center', mt: 2 }}>
                {' '}
                {t('menu_right.select_menu')}{' '}
              </Grid>
            ) : (
              selectedItem.selectedItem.map(item => (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Paper
                    sx={styles.container}
                    onClick={() => handleAddMenuItem(item, selectedItem)}
                  >
                    <Stack direction="row" sx={{ height: '100%' }}>
                      <Grid container sx={{ height: '100%' }}>
                        <Grid item xs={8}>
                          <Stack sx={styles.textContainer}>
                            <Typography
                              sx={[styles.title, styles.nonSelectable] as any}
                            >
                              {
                                getActiveLanguage(item.product_translations)
                                  .name
                              }
                            </Typography>
                            <Typography
                              sx={[styles.title, styles.nonSelectable] as any}
                            >
                              {
                                getActiveLanguage(
                                  item.product_unit.translations,
                                ).name
                              }
                            </Typography>
                            {Number(item.additional_price.amount) > 0 && (
                              <Typography
                                sx={styles.nonSelectable}
                                variant="caption"
                              >
                                +{item.additional_price.formatted}
                              </Typography>
                            )}
                          </Stack>
                        </Grid>
                        <Grid item xs={4}>
                          <Stack sx={addIconStyle}>
                            <Icon
                              name="Add"
                              fontSize="large"
                              color="disabled"
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Paper>
                </Grid>
              ))
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MenuModalRightSideProducts;
