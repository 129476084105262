import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type SpecialOrderFormValues = {
  product_name: string;
  quantity: number;
  price: string;
  tax_rate: number;
};

const specialOrderSchema: yup.SchemaOf<SpecialOrderFormValues> = yup.object({
  product_name: yup
    .string()
    .max(255)
    .required('orderModal.specialOrderModal.required'),
  quantity: yup
    .number()
    .min(0)
    .required('orderModal.specialOrderModal.required'),
  price: yup.string().min(0).required('orderModal.specialOrderModal.required'),
  tax_rate: yup.number().required('orderModal.specialOrderModal.required'),
});

export const validationSchema = yupResolver(specialOrderSchema);

export const defaultValues: SpecialOrderFormValues = {
  product_name: '',
  quantity: 1,
  price: '',
  tax_rate: 0,
};
