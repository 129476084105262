import { SxProps, Theme } from '@mui/material';

const styles: Record<string, SxProps<Theme>> = {
  button: {
    fontWeight: 'bold',
    backgroundColor: 'white',
    border: '0.1px solid #DEDEDE',
    px: 1,
  },
  calculatorButton: {
    height: '60px',
    backgroundColor: 'white',
    border: '0.1px solid #DEDEDE',
    fontSize: '1rem',
  },
  paymentTypeButton: {
    height: '100px',
    backgroundColor: 'white',
    border: '0.1px solid #DEDEDE',
    fontSize: '1rem',
  },
  selectedQuantityInfo: {
    fontSize: 12,
    textTransform: 'capitalize',
    color: 'grey',
    opacity: '.6',
  },
  title: { fontSize: 16, textTransform: 'capitalize' },
  menuItemTitle: { fontSize: 12, textTransform: 'capitalize' },
  menuItemSubtitle: { fontSize: 10, textTransform: 'capitalize' },
  properties: { fontSize: 10, textTransform: 'capitalize' },
  priceInfo: { fontWeight: '200', padding: '0 8px 0 0', color: 'ff0000c1' },
  paymentModalPaper: {
    p: 2,
    height: { sm: '80vh', md: '78vh', lg: '88vh', xl: '80vh' },
    maxHeight: { sm: '80vh', md: '78vh', lg: '88vh', xl: '80vh' },
  },
  paymentModalLeftStack: {
    textDecoration: 'underline 1.5px red',
    textUnderlinePosition: 'under',
    textAlign: 'center',
    alignItems: 'center',
  },
  paymentModalMidStack: {
    minHeight: { sm: '180px', md: '120px', lg: '190px' },
    maxHeight: { sm: '180px', md: '120px', lg: '190px' },
    overflow: 'auto',
  },
  paymentModalRightCircleType: {
    position: 'absolute',
    top: 6,
    right: 6,
    opacity: '0.5',
  },
};

export default styles;
