import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isApiError } from '../../services/api/types';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getPaymentTypesThunk,
  getBusinessTypesThunk,
  getWorkingTypesThunk,
  getCurrenciesThunk,
} from '../../store/slices/utilitiesSlice';
import errorReporting from '../../helpers/errorReporting';

const useUtilities = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const paymentTypes = useAppSelector(state => state.utilities.paymentTypes);
  const [paymentTypesLoading, setPaymentTypesRatesLoading] = useState(false);

  const businessTypes = useAppSelector(state => state.utilities.businessTypes);
  const [businessTypesLoading, setBusinessTypesLoading] = useState(false);

  const workingTypes = useAppSelector(state => state.utilities.workingTypes);
  const [workingTypesLoading, setWorkingTypesLoading] = useState(false);

  const currencies = useAppSelector(state => state.utilities.currencies);
  const [currenciesLoading, setCurrenciesLoading] = useState(false);

  const fetchPaymentTypes = async () => {
    try {
      setPaymentTypesRatesLoading(true);
      await dispatch(getPaymentTypesThunk()).unwrap();
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err;
    } finally {
      setPaymentTypesRatesLoading(false);
    }
  };

  const fetchBusinessTypes = async () => {
    try {
      setBusinessTypesLoading(true);
      await dispatch(getBusinessTypesThunk()).unwrap();
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err;
    } finally {
      setBusinessTypesLoading(false);
    }
  };

  const fetchWorkingTypes = async () => {
    try {
      setWorkingTypesLoading(true);
      await dispatch(getWorkingTypesThunk()).unwrap();
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err;
    } finally {
      setWorkingTypesLoading(false);
    }
  };

  const fetchCurrencies = async () => {
    try {
      setCurrenciesLoading(true);
      await dispatch(getCurrenciesThunk()).unwrap();
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err;
    } finally {
      setCurrenciesLoading(false);
    }
  };

  return {
    paymentTypes,
    paymentTypesLoading,
    businessTypes,
    businessTypesLoading,
    workingTypes,
    workingTypesLoading,
    currencies,
    currenciesLoading,
    fetchBusinessTypes,
    fetchCurrencies,
    fetchPaymentTypes,
    fetchWorkingTypes,
  };
};
export default useUtilities;
