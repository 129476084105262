import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import FormInput from '../../../../components/FormInput';
import FormSelect from '../../../../components/FormSelect';
import FormSwitch from '../../../../components/FormSwitch';
import LoadingState from '../../../../components/LoadingState';
import getActiveLanguage from '../../../../helpers/getActiveLanguage';
import useUtilities from '../../../../hooks/useUtilities';
import styles from '../../styles';
import {
  defaultValues,
  RegisterThirdStepFormValues,
  RegisterThirdStepFormValidValues,
  validationSchema,
} from './types';
import useSettings from '../../../../hooks/useSettings';
import FormSelectSearchable from '../../../../components/FormSelectSearchable';

interface Props {
  onPrevClick: () => void;
  onSubmitClick: (values: RegisterThirdStepFormValidValues) => void;
  onOtpSubmitClick: (code: string) => void;
  isLast: boolean;
  isFirst: boolean;
  loading: boolean;
  initialValues: RegisterThirdStepFormValues | null;
  userPhone: string;
  open: boolean;
  setOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
}
function ThirdStepForm({
  onPrevClick,
  onSubmitClick,
  onOtpSubmitClick,
  isLast,
  isFirst,
  loading,
  initialValues,
  userPhone,
  open,
  setOpen,
}: Props) {
  const { t } = useTranslation('companyRegister');
  const { handleSubmit, control, formState } =
    useForm<RegisterThirdStepFormValues>({
      defaultValues: initialValues || defaultValues,
      resolver: validationSchema,
    });

  const {
    businessTypes,
    businessTypesLoading,
    workingTypes,
    workingTypesLoading,
    currencies,
    currenciesLoading,
  } = useUtilities();

  const { timeZones, fetchTimeZones } = useSettings();

  const durationTimeOptions = [
    { id: 0, text: '0' },
    { id: 5, text: '5' },
    { id: 10, text: '10' },
    { id: 15, text: '15' },
    { id: 30, text: '30' },
    { id: 60, text: '60' },
    { id: 90, text: '90' },
  ];

  const onSubmit = handleSubmit(data => {
    const isId = (value: string | boolean): value is string =>
      typeof value === 'number';

    const workingTypeIds = data.workingTypes
      .map((item, index) => (item ? workingTypes[index].id : false))
      .filter(isId);
    onSubmitClick({ ...data, workingTypeIds });
  });

  useEffect(() => {
    fetchTimeZones();
  }, []);

  if (loading) {
    return <LoadingState />;
  }

  return (
    <form onSubmit={onSubmit}>
      <Stack sx={styles.vStack} justifyContent="space-between">
        <Grid container sx={styles.fieldsContainer} spacing={2}>
          <Grid item md={6} xs={12}>
            <Controller
              name="startTime"
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  type="time"
                  label={t('thirdStep.startTime.label')}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name="businessTypeId"
              control={control}
              render={({ field, fieldState }) => (
                <FormSelect
                  options={businessTypes.map(item => ({
                    id: item.id,
                    text: item.name,
                  }))}
                  loading={businessTypesLoading}
                  label={t('thirdStep.businessType.label')}
                  error={fieldState.invalid}
                  underline
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Controller
              name="currencyId"
              control={control}
              render={({ field, fieldState }) => (
                <FormSelect
                  options={currencies.map(item => ({
                    id: item.id,
                    text: item.name,
                  }))}
                  loading={currenciesLoading}
                  label={t('thirdStep.currency.label')}
                  error={fieldState.invalid}
                  underline
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  color: formState.errors.workingTypes
                    ? 'error.main'
                    : undefined,
                }}
              >
                {t('thirdStep.workingType.label')}
              </Typography>
              {workingTypesLoading ? (
                <LoadingState />
              ) : (
                <Grid container gap={2}>
                  {workingTypes.map((type, index) => (
                    <Grid item>
                      <Controller
                        name={`workingTypes.${index}`}
                        control={control}
                        render={({ field }) => (
                          <FormSwitch
                            label={getActiveLanguage(type.translations).name}
                            error={!!formState.errors.workingTypes}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="timezone"
              control={control}
              render={({ field, fieldState }) => (
                <FormSelectSearchable
                  sx={styles.field}
                  label={t('thirdStep.timezone.label')}
                  error={fieldState.invalid}
                  options={timeZones}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="space-between">
          <Button onClick={onPrevClick} disabled={isFirst}>
            {t('buttons.prev')}
          </Button>
          <Button type="submit" variant="contained" loading={loading}>
            {t('buttons.next')}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

export default ThirdStepForm;
