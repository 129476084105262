/** Dependencies */
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type ChangePasswordFormValues = {
  current_password: string;
  password: string;
  password_confirmation: string;
};

export type ChangeUserInfoFormValues = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  pin: string;
  language: string;
};

const changePasswordSchema: yup.SchemaOf<ChangePasswordFormValues> = yup.object(
  {
    current_password: yup
      .string()
      .required('profile.change_password.current_password.required')
      .min(8, 'profile.change_password.current_password.min'),
    password: yup
      .string()
      .required('profile.change_password.password.required')
      .min(8, 'profile.change_password.password.min'),
    password_confirmation: yup
      .string()
      .required('profile.change_password.password_confirmation.required')
      .min(8, 'profile.change_password.password_confirmation.min')
      .oneOf(
        [yup.ref('password'), null],
        'profile.change_password.password_confirmation.match',
      ),
  },
);

const changeUserInfoSchema: yup.SchemaOf<ChangeUserInfoFormValues> = yup.object(
  {
    first_name: yup
      .string()
      .required('profile.update_profile.first_name.required'),
    last_name: yup
      .string()
      .required('profile.update_profile.last_name.required'),
    email: yup.string().required('profile.update_profile.email.required'),
    phone_number: yup
      .string()
      .required('profile.update_profile.phone.required'),
    pin: yup
      .string()
      .required('profile.update_profile.pin.required')
      .min(4, 'profile.update_profile.pin.min')
      .max(4, 'profile.update_profile.pin.min'),
    language: yup.string().required('profile.update_profile.language.required'),
  },
);

export const changePasswordDefaultValues: ChangePasswordFormValues = {
  current_password: '',
  password: '',
  password_confirmation: '',
};

export const changeUserInfoDefaultValues: ChangeUserInfoFormValues = {
  first_name: '',
  last_name: '',
  phone_number: '',
  email: '',
  pin: '',
  language: 'tr',
};

export const validationChangePasswordSchema = yupResolver(changePasswordSchema);
export const validationChangeUserInfoSchema = yupResolver(changeUserInfoSchema);
