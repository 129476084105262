/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';

/** Components */
import UtilitiesBar, {
  Props as BarProps,
} from '../../../components/UtilitiesBar';
import ErrorState from '../../../components/ErrorState';
import LoadingState from '../../../components/LoadingState';
import ExpensesTable from './ExpensesTable';
import ExpenseAddModal from './Modals/ExpenseAddModal';
import ExpenseCategoryEditModal from './Modals/ExpenseCategoryEditModal';
import useExpenses from '../../../hooks/useExpenses';
import useExpenseCategories from '../../../hooks/useExpenseCategories';
import ExpenseSearch from './Search/ExpenseSearch';
import useProfile from '../../../hooks/useProfile';
import arrayKeyHasPermission from '../../../helpers/arrayKeyHasPermission';
import useUtilities from '../../../hooks/useUtilities';

function Expenses() {
  const { t } = useTranslation('user');
  const { meProfile } = useProfile();
  const { fetchCurrencies } = useUtilities();
  const { expenses, expenseError, expenseLoading, fetchExpenses } =
    useExpenses();
  const { expenseCategories, fetchExpenseCategories, removeExpenseCategory } =
    useExpenseCategories();

  const MENU: BarProps['menu'] = [
    {
      id: 0,
      text: t('user.expence_process'),
      startIconName: 'Add',
      permissionKeys: ['create-expense'],
    },
    {
      id: 1,
      text: t('user.expence_edit'),
      startIconName: 'Edit',
      permissionKeys: [
        'view-expense-category-listing',
        'create-expense-category',
        'update-expense-category',
        'delete-expense-category',
      ],
    },
  ];

  const [expenseAddModal, setExpenseAddModal] = useState(false);
  const [expenseCategoryEditModal, setExpenseCategoryEditModal] =
    useState(false);
  const [expenseToEdit, setExpenseToEdit] = useState(null);

  const [select, setSelect] = useState<string[] | null>(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchExpenses();
    fetchExpenseCategories();
    fetchCurrencies();
  }, []);

  if (!expenses && expenseError) {
    return (
      <ErrorState
        message={
          expenseError ? expenseError.message : t('common:unknownError' as any)
        }
      />
    );
  }

  const handleAction = (id: number) => {
    switch (id) {
      case 0:
        setExpenseAddModal(true);
        break;
      case 1:
        setExpenseCategoryEditModal(true);
        break;
      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('user.expense')}
        leftIconName="Bolt"
        menu={MENU!
          .filter(item =>
            arrayKeyHasPermission(
              item?.permissionKeys || [],
              meProfile?.permissions || [],
            ),
          )
          .map(filteredItem => ({
            ...filteredItem,
            text: t(filteredItem.text as any),
          }))}
        onActionClick={handleAction}
      />
      <Grid xs={12}>
        <ExpenseSearch
          select={select}
          setSelect={setSelect}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </Grid>
      {expenseLoading ? (
        <LoadingState />
      ) : (
        <ExpensesTable
          onItemEdit={item => {
            setExpenseToEdit(item);
            setExpenseAddModal(true);
          }}
          page={page}
          setPage={setPage}
          dateRange={`${startDate},${endDate}`}
          select={select}
        />
      )}
      <ExpenseAddModal
        open={expenseAddModal}
        onClose={() => {
          setExpenseToEdit(null);
          setExpenseAddModal(false);
        }}
        item={expenseToEdit}
      />
      <ExpenseCategoryEditModal
        items={expenseCategories}
        open={expenseCategoryEditModal}
        onClose={() => setExpenseCategoryEditModal(false)}
        onItemEdit={item => {
          setExpenseToEdit(item);
          setExpenseAddModal(true);
        }}
        onItemRemove={removeExpenseCategory}
      />
    </Box>
  );
}

export default Expenses;
