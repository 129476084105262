/** Dependencies */
import React from 'react';

/** Styles */
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

/** Components */
import UtilitiesBar from '../../components/UtilitiesBar';
import Form from './forms/Form';

function Profile() {
  const { t } = useTranslation('auth');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('profile.profile')}
        leftIconName="Customer"
      />
      <Form />
    </Box>
  );
}

export default Profile;
