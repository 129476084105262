import { SxProps, Theme } from '@mui/material';
import { POS_HEADER_HEIGHT } from '../../styles';

const styles: Record<string, SxProps<Theme>> = {
  searchBar: {
    height: { xs: POS_HEADER_HEIGHT, md: 75 },
    width: '100%',
    backgroundColor: 'neutral.main',
    boxShadow: 1,
  },
  searchBarIcon: {
    color: 'white',
    backgroundColor: '#444',
    width: { xs: POS_HEADER_HEIGHT, md: 75 },
    height: { xs: POS_HEADER_HEIGHT, md: 75 },
    borderLeft: '1px solid white',
  },
  searchBarStack: {
    backgroundColor: {
      xs: '#444',
      sm: '#444',
      md: '#444',
      lg: 'neutral.main',
    },
  },
  searchInput: {
    display: { xxs: 'none', xs: 'none', sm: 'none', md: 'block' },
    width: '100px',
  },
  searchInputBase: { flex: 1, fontSize: 24, px: 3, height: '75px' },
};

export default styles;
