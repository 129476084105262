/** Dependencies */
import React from 'react';
import { Box, Paper, IconButton, Typography } from '@mui/material';

/** Components */
import Icon from '../../../../../../../../components/Icon';

/** Styles */
import generalStyles from '../../../../../../../../assets/stylesheets/generalStyles';

interface Props {
  isSubmittedOrder: boolean;
  count: number;
  onIncrease: () => void;
  onDecrease: () => void;
  onDelete: () => void;
}

function ProductItemCounter({
  isSubmittedOrder,
  count,
  onIncrease,
  onDecrease,
  onDelete,
}: Props) {
  return (
    <Paper
      sx={
        !isSubmittedOrder
          ? generalStyles.buttonContainer
          : generalStyles.orderCountContainer
      }
    >
      {!isSubmittedOrder ? (
        <Box>
          <IconButton
            sx={generalStyles.icon}
            color="success"
            onMouseDown={e => e.stopPropagation()}
            onClick={e => {
              e.stopPropagation();
              onIncrease();
            }}
          >
            <Icon name="Add" />
          </IconButton>
          <Typography
            sx={{
              ...generalStyles.generalDisplay,
              flexDirection: 'column',
            }}
          >
            {count}
          </Typography>
          <IconButton
            sx={generalStyles.icon}
            color="error"
            onMouseDown={e => e.stopPropagation()}
            onClick={e => {
              e.stopPropagation();
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              count === 1 ? onDelete() : onDecrease();
            }}
          >
            <Icon name={count === 1 ? 'Delete' : 'Remove'} />
          </IconButton>
        </Box>
      ) : (
        <Typography sx={{ p: 0.2 }}>{count}</Typography>
      )}
    </Paper>
  );
}

export default ProductItemCounter;
