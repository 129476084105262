import { Stack, Typography, Paper } from '@mui/material';
import React, { useState } from 'react';
import styles from './styles';
import { PropertyGroupWithActive } from '../../../../../../../services/api/definitions/propertyGroups/types';
import OrderChildProductModal from './OrderChildSelectModal';
import { Product } from '../../../../../../../models/Product';

interface Props {
  title: string;
  price: string;
  onIncrease: (productId: string, itemId: string, menuId: string) => void;
  setOnSelectionModal: React.Dispatch<
    React.SetStateAction<{ isSelected: boolean; id: string }>
  >;
  setOnSelectionMenuModal: React.Dispatch<
    React.SetStateAction<{ isSelected: boolean; id: string }>
  >;
  requiredSelection: boolean;
  itemId: string;
  propertyGroups: PropertyGroupWithActive[];
  isMenu: boolean;
  isParent: boolean;
  childProducts?: Product[];
  currencyCode: string;
}
function OrderProductCard({
  title,
  price,
  onIncrease,
  requiredSelection,
  setOnSelectionModal,
  setOnSelectionMenuModal,
  itemId,
  propertyGroups,
  isMenu,
  isParent,
  childProducts,
  currencyCode,
}: Props) {
  const [openModal, setOpenModal] = useState(false);

  const handleClick = () => {
    const uniqTime = Date.now().toString();
    try {
      if (isParent) {
        setOpenModal(!openModal);
      } else {
        onIncrease(itemId, '', uniqTime);
        if (isMenu) {
          setOnSelectionMenuModal({ isSelected: true, id: uniqTime });
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          requiredSelection &&
            setOnSelectionModal({ isSelected: true, id: uniqTime });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Paper sx={styles.container}>
      <Stack direction="row" sx={{ height: '100%' }}>
        <Stack
          sx={styles.textContainer}
          justifyContent="space-between"
          onClick={() => handleClick()}
        >
          <Typography sx={[styles.title, styles.nonSelectable] as any}>
            {title}
          </Typography>
          {!isParent && (
            <Typography sx={styles.nonSelectable} variant="caption">
              {price}
            </Typography>
          )}
        </Stack>
      </Stack>
      <OrderChildProductModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        childProducts={childProducts || []}
        currencyCode={currencyCode}
        setOnSelectionModal={setOnSelectionModal}
        setOnSelectionMenuModal={setOnSelectionMenuModal}
        onIncrease={(productId: string, itemmId: string, menuId: string) =>
          onIncrease(productId, '', menuId)
        }
        title={title}
      />
    </Paper>
  );
}

export default OrderProductCard;
