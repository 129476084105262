import { SxProps, Theme } from '@mui/material';

const styles: Record<string, SxProps<Theme>> = {
  calculatorButton: {
    height: '60px',
    backgroundColor: 'white',
    border: '0.1px solid #DEDEDE',
    fontSize: '1rem',
  },
};

export default styles;
