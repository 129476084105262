import { ChangePassword, UpdateProfile } from './types';
import { ApiSuccessResponse } from '../types';
import api from '../api';

type ChangePasswordRes = ApiSuccessResponse<ChangePassword>;

const changePassword = async (params: ChangePassword) => {
  await api.post<ChangePasswordRes>('/change-password', {
    current_password: params.current_password,
    password: params.password,
    password_confirmation: params.password_confirmation,
  });
};

type UpdateProfileRes = ApiSuccessResponse<UpdateProfile>;

const updateProfile = async (params: UpdateProfile) => {
  await api.post<UpdateProfileRes>('/update-profile', {
    first_name: params.first_name,
    last_name: params.last_name,
    email: params.email,
    phone_number: params.phone_number,
    phone_number_country: params.phone_number_country,
    pin: params.pin,
    language: params.language,
  });
};

const setLanguage = async (language: string) => {
  await api.post('/set-language', {
    language,
  });
};

export default {
  changePassword,
  updateProfile,
  setLanguage,
};
