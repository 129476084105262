import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useProfile from '../../../../hooks/useProfile';
import FormNumberInput from '../../../../components/FormNumberInput';
import { Trigger2 } from '../../../../services/api/operations/fraud/types';

interface Props {
  triggers: Trigger2[] | null;
  setTriggers: (
    value:
      | ((prevState: Trigger2[] | null) => Trigger2[] | null)
      | Trigger2[]
      | null,
  ) => void;
}

export function ParameterForm({ triggers, setTriggers }: Props) {
  const { t } = useTranslation('frauds');
  const { meProfile } = useProfile();

  return (
    <Grid xs={5}>
      <Typography fontSize="18px" sx={{ marginBottom: '15px' }}>
        {t('flows.addModal.forms.paramsForm.title')}
      </Typography>
      <Grid>
        <Typography color="gray" fontSize="15px" m={1}>
          {t('flows.addModal.forms.paramsForm.discount')}
        </Typography>
        <Grid container justifyContent="space-between">
          <Grid xs={5}>
            <FormNumberInput
              value={
                triggers?.find(item => item.action_type === 'discount')
                  ?.amount || null
              }
              onChange={e => {
                const newTriggers =
                  triggers?.map(item =>
                    item.action_type === 'discount'
                      ? {
                          action_type: item.action_type,
                          amount: Number(e.target.value),
                          percentage: item.percentage,
                        }
                      : item,
                  ) || [];
                setTriggers(newTriggers);
              }}
              placeholder="0.00₺"
              maskProps={{
                decimalScale: 2,
                fixedDecimalScale: true,
                suffix: ` ${meProfile?.currency?.symbol || '₺'}`,
                allowNegative: false,
              }}
            />
          </Grid>
          <Grid xs={2}>
            <Typography
              textAlign="center"
              alignItems="center"
              color="gray"
              fontSize="13px"
              m={1}
            >
              {t('flows.addModal.forms.paramsForm.or')}
            </Typography>
          </Grid>
          <Grid xs={5}>
            <FormNumberInput
              value={
                triggers?.find(item => item.action_type === 'discount')
                  ?.percentage || null
              }
              onChange={e => {
                const newTriggers =
                  triggers?.map(item =>
                    item.action_type === 'discount'
                      ? {
                          action_type: item.action_type,
                          percentage:
                            Number(e.target.value) <= 100
                              ? Number(e.target.value)
                              : Number(e.target.value.slice(0, 2)),
                          amount: item.amount,
                        }
                      : item,
                  ) || [];
                setTriggers(newTriggers);
              }}
              placeholder="%###"
              maskProps={{
                prefix: `%`,
                allowNegative: false,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Typography color="gray" fontSize="15px" m={1}>
          {t('flows.addModal.forms.paramsForm.cancel')}
        </Typography>
        <Grid container justifyContent="space-between">
          <Grid xs={5}>
            <FormNumberInput
              value={
                triggers?.find(item => item.action_type === 'cancel')?.amount ||
                null
              }
              onChange={e => {
                const newTriggers =
                  triggers?.map(item =>
                    item.action_type === 'cancel'
                      ? {
                          action_type: item.action_type,
                          amount: Number(e.target.value),
                          percentage: item.percentage,
                        }
                      : item,
                  ) || [];
                setTriggers(newTriggers);
              }}
              placeholder="0.00₺"
              maskProps={{
                decimalScale: 2,
                fixedDecimalScale: true,
                suffix: ` ${meProfile?.currency?.symbol || '₺'}`,
                allowNegative: false,
              }}
            />
          </Grid>
          <Grid xs={2}>
            <Typography
              textAlign="center"
              alignItems="center"
              color="gray"
              fontSize="13px"
              m={1}
            >
              {t('flows.addModal.forms.paramsForm.or')}
            </Typography>
          </Grid>
          <Grid xs={5}>
            <FormNumberInput
              value={
                triggers?.find(item => item.action_type === 'cancel')
                  ?.percentage || null
              }
              onChange={e => {
                const newTriggers =
                  triggers?.map(item =>
                    item.action_type === 'cancel'
                      ? {
                          action_type: item.action_type,
                          percentage:
                            Number(e.target.value) < 101
                              ? Number(e.target.value)
                              : Number(e.target.value.slice(0, 2)),
                          amount: item.amount,
                        }
                      : item,
                  ) || [];
                setTriggers(newTriggers);
              }}
              placeholder="%###"
              maskProps={{
                prefix: `%`,
                allowNegative: false,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Typography color="gray" fontSize="15px" m={1}>
          {t('flows.addModal.forms.paramsForm.complimentary')}
        </Typography>
        <Grid container justifyContent="space-between">
          <Grid xs={5}>
            <FormNumberInput
              value={
                triggers?.find(item => item.action_type === 'complimentary')
                  ?.amount || null
              }
              onChange={e => {
                const newTriggers =
                  triggers?.map(item =>
                    item.action_type === 'complimentary'
                      ? {
                          action_type: item.action_type,
                          amount: Number(e.target.value),
                          percentage: item.percentage,
                        }
                      : item,
                  ) || [];
                setTriggers(newTriggers);
              }}
              placeholder="0.00₺"
              maskProps={{
                decimalScale: 2,
                fixedDecimalScale: true,
                suffix: ` ${meProfile?.currency?.symbol || '₺'}`,
                allowNegative: false,
              }}
            />
          </Grid>
          <Grid xs={2}>
            <Typography
              textAlign="center"
              alignItems="center"
              color="gray"
              fontSize="13px"
              m={1}
            >
              {t('flows.addModal.forms.paramsForm.or')}
            </Typography>
          </Grid>
          <Grid xs={5}>
            <FormNumberInput
              value={
                triggers?.find(item => item.action_type === 'complimentary')
                  ?.percentage || null
              }
              onChange={e => {
                const newTriggers =
                  triggers?.map(item =>
                    item.action_type === 'complimentary'
                      ? {
                          action_type: item.action_type,
                          percentage:
                            Number(e.target.value) < 101
                              ? Number(e.target.value)
                              : Number(e.target.value.slice(0, 2)),
                          amount: item.amount,
                        }
                      : item,
                  ) || [];
                setTriggers(newTriggers);
              }}
              placeholder="%###"
              maskProps={{
                prefix: `%`,
                allowNegative: false,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid> */}
      {/*  <Typography color="gray" fontSize="15px" m={1}> */}
      {/*    {t('flows.addModal.forms.paramsForm.return')} */}
      {/*  </Typography> */}
      {/*  <Grid container justifyContent="space-between"> */}
      {/*    <Grid xs={5}> */}
      {/*      <FormNumberInput */}
      {/*        value={ */}
      {/*          triggers?.find(item => item.action_type === 'return')?.amount || */}
      {/*          null */}
      {/*        } */}
      {/*        onChange={e => { */}
      {/*          const newTriggers = */}
      {/*            triggers?.map(item => */}
      {/*              item.action_type === 'return' */}
      {/*                ? { */}
      {/*                    action_type: item.action_type, */}
      {/*                    amount: Number(e.target.value), */}
      {/*                    percentage: item.percentage, */}
      {/*                  } */}
      {/*                : item, */}
      {/*            ) || []; */}
      {/*          setTriggers(newTriggers); */}
      {/*        }} */}
      {/*        placeholder="0.00₺" */}
      {/*        maskProps={{ */}
      {/*          decimalScale: 2, */}
      {/*          fixedDecimalScale: true, */}
      {/*          suffix: ` ${meProfile?.currency?.symbol || '₺'}`, */}
      {/*          allowNegative: false, */}
      {/*        }} */}
      {/*      /> */}
      {/*    </Grid> */}
      {/*    <Grid xs={2}> */}
      {/*      <Typography */}
      {/*        textAlign="center" */}
      {/*        alignItems="center" */}
      {/*        color="gray" */}
      {/*        fontSize="13px" */}
      {/*        m={1} */}
      {/*      > */}
      {/*        {t('flows.addModal.forms.paramsForm.or')} */}
      {/*      </Typography> */}
      {/*    </Grid> */}
      {/*    <Grid xs={5}> */}
      {/*      <FormNumberInput */}
      {/*        value={ */}
      {/*          triggers?.find(item => item.action_type === 'return') */}
      {/*            ?.percentage || null */}
      {/*        } */}
      {/*        onChange={e => { */}
      {/*          const newTriggers = */}
      {/*            triggers?.map(item => */}
      {/*              item.action_type === 'return' */}
      {/*                ? { */}
      {/*                    action_type: item.action_type, */}
      {/*                    percentage: */}
      {/*                      Number(e.target.value) < 101 */}
      {/*                        ? Number(e.target.value) */}
      {/*                        : Number(e.target.value.slice(0, 2)), */}
      {/*                    amount: item.amount, */}
      {/*                  } */}
      {/*                : item, */}
      {/*            ) || []; */}
      {/*          setTriggers(newTriggers); */}
      {/*        }} */}
      {/*        placeholder="%###" */}
      {/*        maskProps={{ */}
      {/*          prefix: `%`, */}
      {/*          allowNegative: false, */}
      {/*        }} */}
      {/*      /> */}
      {/*    </Grid> */}
      {/*  </Grid> */}
      {/* </Grid> */}
    </Grid>
  );
}
