import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';

function SnackBarDismissButton({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation('common');
  return (
    <Button
      variant="text"
      sx={{ color: 'white', textDecorationLine: 'underline' }}
      onClick={onClick}
    >
      {t('dismiss')}
    </Button>
  );
}

export default SnackBarDismissButton;
