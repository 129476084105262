import {
  Collapse,
  TableCell,
  TableRow,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Grid,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../components/Icon';
import IconButtonWithTooltip from '../../../../../components/IconButtonWithTooltip';
import ProductCard from '../../../../../components/ProductCard';
import getActiveLanguage from '../../../../../helpers/getActiveLanguage';
import {
  ProductAPI,
  ProductMenuItem,
} from '../../../../../services/api/definitions/products/types';

interface Props {
  id: string;
  name: string;
  quantity: number;
  items: ProductMenuItem[];
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onAddItems: (id: string) => void;
  products: ProductAPI[];
}
function MenuRow({
  id,
  name,
  quantity,
  items = [],
  onEdit,
  onDelete,
  onAddItems,
  products,
}: Props) {
  const { t } = useTranslation('product');

  const [open, setOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (action?: 'add' | 'edit' | 'delete', rowId?: string) => {
    if (rowId) {
      switch (action) {
        case 'add':
          onAddItems(rowId);
          break;

        case 'edit':
          onEdit(rowId);
          break;

        case 'delete':
          onDelete(rowId);
          break;

        default:
          break;
      }
    }

    setAnchorEl(null);
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell align="left">
          <IconButtonWithTooltip
            size="small"
            onClick={() => setOpen(!open)}
            iconName={open ? 'KeyboardArrowUp' : 'KeyboardArrowDown'}
          />
        </TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{quantity}</TableCell>
        <TableCell align="right">
          <IconButtonWithTooltip
            size="small"
            onClick={handleClick}
            iconName="MoreVert"
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container spacing={2}>
              {!items.length ? (
                <Typography variant="subtitle2">
                  {t('menu.emptyItems')}
                </Typography>
              ) : (
                items.map(item => {
                  const product = products.find(p => p.id === item.product_id);

                  const itemName = product
                    ? ` ${item.quantity} X ${
                        getActiveLanguage(product.translations).name
                      }`
                    : 'unknown';

                  return (
                    <Grid item key={item.id} xs={6} sm={4} md={3} lg={2}>
                      <ProductCard
                        key={item.id}
                        name={itemName}
                        defaultUnit={{
                          name: getActiveLanguage(
                            item.product_unit.translations,
                          ).name,
                          price: item.additional_price.formatted,
                        }}
                      />
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => handleClose('add', id)}
          sx={{ color: 'primary.main' }}
        >
          <ListItemIcon>
            <Icon name="Add" color="primary" />
          </ListItemIcon>
          <ListItemText>{t('menu.actions.add')}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => handleClose('edit', id)}
          sx={{ color: 'primary.main' }}
        >
          <ListItemIcon>
            <Icon name="Edit" color="primary" />
          </ListItemIcon>
          <ListItemText>{t('menu.actions.edit')}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => handleClose('delete', id)}
          sx={{ color: 'error.main' }}
        >
          <ListItemIcon>
            <Icon name="Delete" color="error" />
          </ListItemIcon>
          <ListItemText>{t('menu.actions.delete')}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
export default MenuRow;
