import React from 'react';
import { Box, IconButton } from '@mui/material';
import styles from './styles';
import Icon from '../Icon';

interface Props {
  setActiveTransactionModal: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
  hasTableMoreVertButtonPermission: boolean;
}
function EmptyRestaurantTable({
  setActiveTransactionModal,
  hasTableMoreVertButtonPermission,
}: Props) {
  return (
    <Box sx={styles.rightTopCorner}>
      <IconButton
        sx={styles.button}
        color="info"
        onMouseDown={e => e.stopPropagation()}
        onClick={e => {
          e.stopPropagation();
          setActiveTransactionModal(true);
        }}
      >
        {hasTableMoreVertButtonPermission && <Icon name="Edit" />}
      </IconButton>
    </Box>
  );
}

export default EmptyRestaurantTable;
