import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../services/api';

import { getLanguages } from './languageSlice';

export const getLanguagesThunk = createAsyncThunk(
  'languages/get',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.utilities.getLanguages();
      const result = response.data.data;
      dispatch(getLanguages(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
