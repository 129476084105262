import api from '../api';

import { ApiSuccessResponse } from '../types';
import { ListResponse } from './types';

const PAGING_LIMIT = 100;

type ListRes = ApiSuccessResponse<ListResponse>;
const list = (
  search: string,
  is_active: boolean | null,
  kitchen_group_id?: string[] | null,
) =>
  api.get<ListRes>('/order-item-groups', {
    params: {
      per_page: PAGING_LIMIT,
      filter: {
        search,
        is_active,
        kitchen_group_id,
      },
    },
  });

type UpdateOrderItemGroupRes = ApiSuccessResponse<UpdateOrderItemGroupRes>;
const updateOrderItemGroup = (status: number, check_number: string) =>
  api.patch<UpdateOrderItemGroupRes>(
    `/order-item-group-set-group-status/${check_number}`,
    { status },
  );

type UpdateOrderItemRes = ApiSuccessResponse<UpdateOrderItemRes>;
const updateOrderItem = (status: number, item_id: string) =>
  api.patch<UpdateOrderItemRes>(
    `/order-item-group-set-item-status/${item_id}`,
    { status },
  );

type ChangeOrderItemGroupStateRes =
  ApiSuccessResponse<ChangeOrderItemGroupStateRes>;
const changeOrderItemGroupState = (check_number: string) =>
  api.patch<ChangeOrderItemGroupStateRes>(
    `/change-order-item-group-state/${check_number}`,
  );

export default {
  list,
  updateOrderItemGroup,
  updateOrderItem,
  changeOrderItemGroupState,
};
