/** Dependencies */
import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';

/** Components */
import Icon from '../../../../../../../../components/Icon';
import generalStyles from '../../../../../../../../assets/stylesheets/generalStyles';
import Button from '../../../../../../../../components/Button';

/** Helpers */
import { singlePriceFormatter } from '../../../../../../../../helpers/moneyFormatter';
import keyHasPermission from '../../../../../../../../helpers/keyHasPermission';

/** Hooks */
import useProfile from '../../../../../../../../hooks/useProfile';

/** Types */
import { Unit } from '../../../../../../../../models/Product';
import ProductNoteModal from '../../../../ProductNoteModal';

interface Props {
  isMenu: boolean;
  isSubmittedOrder: boolean;
  isActiveProperties: boolean;
  isActiveIngredients: boolean;
  hasShowDeleteButton: boolean;
  selectedItemId?: string;
  itemId?: string;
  handleOnSelection: () => void;
  onDelete: () => void;
  setDeleteConfirmation: React.Dispatch<React.SetStateAction<string | null>>;
  setOnSelectionMenuModalForBasket: React.Dispatch<
    React.SetStateAction<{ isSelected: boolean; id: string }>
  >;
  onGiftSelect: (type: string) => void;
  isTreat: boolean;
  totalPrice?: number;
  currencyCode: string;
  unit?: Unit | null;
  count: number;
  treatText: string;
  setOpenNote: React.Dispatch<React.SetStateAction<boolean>>;
  setNoteType: React.Dispatch<React.SetStateAction<string>>;
  setOpenRemoveItemModal: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
}

function ProductItemCounter({
  isSubmittedOrder,
  isActiveIngredients,
  isActiveProperties,
  isMenu,
  handleOnSelection,
  setOnSelectionMenuModalForBasket,
  selectedItemId,
  onDelete,
  hasShowDeleteButton,
  setDeleteConfirmation,
  itemId,
  onGiftSelect,
  isTreat,
  totalPrice,
  currencyCode,
  unit,
  count,
  treatText,
  setOpenNote,
  setNoteType,
  setOpenRemoveItemModal,
}: Props) {
  const { meProfile } = useProfile();

  const hasSetComplimentary = keyHasPermission(
    'set-complimentary',
    meProfile?.permissions || [],
  );

  return (
    <Box sx={{ minWidth: '60px' }}>
      {!isSubmittedOrder && (
        <>
          {(isActiveProperties || isActiveIngredients) && !isMenu && (
            <IconButton
              sx={generalStyles.icon}
              onMouseDown={e => e.stopPropagation()}
              onClick={e => {
                e.stopPropagation();
                handleOnSelection();
              }}
            >
              <Icon name="List" />
            </IconButton>
          )}
          {isMenu && (
            <IconButton
              sx={generalStyles.icon}
              onMouseDown={e => e.stopPropagation()}
              onClick={e => {
                e.stopPropagation();
                setOnSelectionMenuModalForBasket({
                  isSelected: true,
                  id: selectedItemId || '',
                });
              }}
            >
              <Icon name="RestaurantMenu" color="error" />
            </IconButton>
          )}
          {isTreat && (
            <>
              <Typography
                sx={
                  !isSubmittedOrder
                    ? {
                        fontSize: { xs: 14, sm: 13, lg: 16 },
                        textDecoration: isTreat ? 'line-through' : '',
                      }
                    : {
                        fontSize: { xs: 14, sm: 13, lg: 16 },
                        textDecoration: isTreat ? 'line-through' : '',
                      }
                }
              >
                {isSubmittedOrder
                  ? totalPrice
                    ? singlePriceFormatter(
                        (totalPrice * 100).toString(),
                        1,
                        currencyCode,
                      )
                    : singlePriceFormatter(
                        unit?.amount.amount,
                        count,
                        currencyCode,
                      )
                  : totalPrice
                  ? singlePriceFormatter(
                      (totalPrice * 100).toString(),
                      count,
                      currencyCode,
                    )
                  : singlePriceFormatter(
                      unit?.amount.amount,
                      count,
                      currencyCode,
                    )}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  borderRadius: '20px',
                  width: '50px',
                  height: '24px',
                  fontSize: '10px',
                  p: 0,
                  minWidth: '10px',
                }}
                disabled={!hasSetComplimentary}
                color="error"
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  if (isTreat) {
                    onGiftSelect('unTreat');
                  } else {
                    onGiftSelect('treat');
                  }
                }}
              >
                {treatText}
              </Button>
            </>
          )}
          <IconButton
            sx={{ ...generalStyles.icon, ml: 1 }}
            color="error"
            onMouseDown={e => e.stopPropagation()}
            onClick={e => {
              e.stopPropagation();
              onDelete();
            }}
          >
            <Icon name="Delete" />
          </IconButton>
          <IconButton
            sx={{ ...generalStyles.icon, ml: 1 }}
            color="error"
            onMouseDown={e => e.stopPropagation()}
            onClick={e => {
              e.stopPropagation();
              setOpenNote(true);
              setNoteType('order');
            }}
          >
            <Icon name="MoreVert" />
          </IconButton>
        </>
      )}
      {isSubmittedOrder && hasShowDeleteButton && (
        <>
          {isTreat ? (
            <>
              <Typography
                sx={
                  !isSubmittedOrder
                    ? {
                        fontSize: { xs: 14, sm: 13, lg: 16 },
                        textDecoration: isTreat ? 'line-through' : '',
                      }
                    : {
                        fontSize: { xs: 14, sm: 13, lg: 16 },
                        textDecoration: isTreat ? 'line-through' : '',
                      }
                }
              >
                {isSubmittedOrder
                  ? totalPrice
                    ? singlePriceFormatter(
                        (totalPrice * 100).toString(),
                        1,
                        currencyCode,
                      )
                    : singlePriceFormatter(
                        unit?.amount.amount,
                        count,
                        currencyCode,
                      )
                  : totalPrice
                  ? singlePriceFormatter(
                      (totalPrice * 100).toString(),
                      count,
                      currencyCode,
                    )
                  : singlePriceFormatter(
                      unit?.amount.amount,
                      count,
                      currencyCode,
                    )}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  borderRadius: '20px',
                  width: '50px',
                  height: '24px',
                  fontSize: '10px',
                  p: 0,
                  minWidth: '10px',
                }}
                size="small"
                onClick={e => {
                  e.stopPropagation();
                  if (isTreat) {
                    onGiftSelect('unTreat');
                  } else {
                    onGiftSelect('treat');
                  }
                }}
                disabled={!hasSetComplimentary}
              >
                {treatText}
              </Button>
            </>
          ) : (
            <IconButton
              sx={generalStyles.icon}
              color="primary"
              onMouseDown={e => e.stopPropagation()}
              onClick={e => {
                e.stopPropagation();
                if (isTreat) {
                  onGiftSelect('unTreat');
                } else {
                  onGiftSelect('treat');
                }
              }}
              disabled={!hasSetComplimentary}
            >
              <Icon name="Redeem" />
            </IconButton>
          )}
          <IconButton
            sx={{ ...generalStyles.icon, ml: 1 }}
            color="primary"
            onMouseDown={e => e.stopPropagation()}
            onClick={e => {
              e.stopPropagation();
              setDeleteConfirmation(itemId || '');
              setOpenRemoveItemModal(true);
            }}
          >
            <Icon name="Delete" />
          </IconButton>
        </>
      )}
    </Box>
  );
}

export default ProductItemCounter;
