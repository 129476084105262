import {
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useEffect, useMemo } from 'react';
import useProfile from '../../hooks/useProfile';

interface Props {
  handleChange(event: SelectChangeEvent<string | undefined>): void;
  selectValue: string;
  setSelectValue: React.Dispatch<React.SetStateAction<string>>;
  token: string | null;
}

function Logo({ handleChange, selectValue, setSelectValue, token }: Props) {
  const { meProfile } = useProfile();

  const branchGroupsOptions = useMemo(
    () =>
      meProfile?.branches?.map(item => ({
        id: item.id,
        text: item.name,
      })),
    [meProfile],
  );

  useEffect(() => {
    setSelectValue(
      meProfile?.branches?.find((item: any) => item.is_active)?.name || '',
    );
  }, [meProfile]);

  return (
    <Typography
      variant="h6"
      noWrap
      component="div"
      sx={{ display: { xs: 'block', color: grey[700] } }}
    >
      <Select
        disabled={Boolean(token) || false}
        value={selectValue}
        onChange={handleChange}
        placeholder="xxxx"
        sx={{
          width: '100%',
          height: '64px',
          border: 'none',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '& .MuiSelect-select .notranslate::after': {
            opacity: 0.42,
          },
          '&:hover': {
            '&& fieldset': {
              border: 'none',
            },
          },
          '.MuiOutlinedInput-notchedOutline': { border: 'none' },
        }}
      >
        {branchGroupsOptions?.map((group: any) => (
          <MenuItem key={group.id} value={group.text}>
            <ListItemText primary={group.text} />
          </MenuItem>
        ))}
      </Select>
    </Typography>
  );
}

export default Logo;
