/** Dependencies */
import {
  InputBase,
  Stack,
  Tooltip,
  Divider,
  InputAdornment,
  Grid,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

/** components */
import IconButton from '../../../../../../components/IconButton';

/** Styles */
import styles from './styles';

/** Types */
import { ExtraParams } from '../../types';

/** Hooks */
import useProfile from '../../../../../../hooks/useProfile';

/** Helpers */
import keyHasPermission from '../../../../../../helpers/keyHasPermission';

interface Props {
  query: string;
  onQueryChange: (q: string) => void;
  onDescriptionPress: () => void;
  onGuestsPress: () => void;
  onCouvertPress: () => void;
  onServiceChargePress: (isAuto: boolean) => void;
  onCancelPress: () => void;
  onSpecialOrderPress: () => void;
  extraParams: ExtraParams;
  orderId: string;
  isPackageOrder: boolean;
  isSelfOrder: boolean;
}

function SearchBar({
  query,
  onQueryChange,
  onDescriptionPress,
  onGuestsPress,
  onCouvertPress,
  onServiceChargePress,
  onCancelPress,
  onSpecialOrderPress,
  extraParams,
  orderId,
  isPackageOrder,
  isSelfOrder,
}: Props) {
  const { t } = useTranslation('pos');
  const { meProfile } = useProfile();
  const matchesLg = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const hasShowSpecialOrderButton = keyHasPermission(
    'add-special-product-to-order',
    meProfile?.permissions || [],
  );
  const hasDescriptionButton = keyHasPermission(
    'set-description',
    meProfile?.permissions || [],
  );
  const hasGuestCountButton = keyHasPermission(
    'set-guest-count',
    meProfile?.permissions || [],
  );
  const hasCancelOrderTransactionButton = keyHasPermission(
    'cancel-order-transaction',
    meProfile?.permissions || [],
  );

  const searchButtons = () => (
    <Grid item xs={12} sm={12} md={5} lg={5}>
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={styles.searchBarStack}
        divider={
          <Divider
            orientation="vertical"
            sx={{ backgroundColor: 'lightgrey' }}
          />
        }
      >
        <Tooltip title={t('orderModal.buttons.specialOrder')} arrow>
          <IconButton
            sx={{
              ...styles.searchBarIcon,
              '&:hover': {
                backgroundColor: '#444444',
              },
            }}
            radius={0}
            iconName="Stars"
            onClick={onSpecialOrderPress}
            disabled={!hasShowSpecialOrderButton}
          />
        </Tooltip>
        <Tooltip title={t('orderModal.buttons.description')} arrow>
          <IconButton
            sx={{
              ...styles.searchBarIcon,
              '&:hover': {
                backgroundColor: '#444444',
              },
            }}
            radius={0}
            iconName="NoteAlt"
            onClick={onDescriptionPress}
            badgeDot={!!extraParams.note}
            disabled={!hasDescriptionButton}
          />
        </Tooltip>
        {!isPackageOrder && (
          <Tooltip title={t('orderModal.buttons.guests')} arrow>
            <IconButton
              sx={{
                ...styles.searchBarIcon,
                '&:hover': {
                  backgroundColor: '#444444',
                },
              }}
              radius={0}
              iconName="Group"
              onClick={onGuestsPress}
              guestCount={extraParams.guestsCount || 1}
              disabled={!hasGuestCountButton}
            />
          </Tooltip>
        )}
        {!isPackageOrder &&
          !isSelfOrder &&
          meProfile?.installed_apps.find(item => item.slug === 'couvert') && (
            <Tooltip title={t('orderModal.buttons.couvert')} arrow>
              <IconButton
                sx={{
                  ...styles.searchBarIcon,
                  '&:hover': {
                    backgroundColor: '#444444',
                  },
                }}
                radius={0}
                iconName="RoomService"
                onClick={onCouvertPress}
              />
            </Tooltip>
          )}
        {!isPackageOrder &&
          !isSelfOrder &&
          meProfile?.installed_apps.find(
            item => item.slug === 'service-charge',
          ) && (
            <Tooltip title={t('orderModal.buttons.service_charge')} arrow>
              <IconButton
                sx={{
                  ...styles.searchBarIcon,
                  '&:hover': {
                    backgroundColor: '#444444',
                  },
                }}
                radius={0}
                iconName="MonetizationOn"
                onClick={() => onServiceChargePress(true)}
              />
            </Tooltip>
          )}
        {orderId && !isPackageOrder && (
          <Tooltip title={t('orderModal.buttons.cancel')} arrow>
            <IconButton
              sx={{
                ...styles.searchBarIcon,
                '&:hover': {
                  backgroundColor: '#444444',
                },
              }}
              radius={0}
              iconName="HighlightOff"
              onClick={onCancelPress}
              disabled={!hasCancelOrderTransactionButton}
            />
          </Tooltip>
        )}
      </Stack>
    </Grid>
  );

  const searchInput = () => (
    <Grid sx={styles.searchInput} item xs={12} sm={12} md={7} lg={7}>
      <InputBase
        placeholder={t('orderModal.searchBar.placeHolder')}
        sx={styles.searchInputBase}
        fullWidth
        value={query}
        onChange={e => onQueryChange(e.target.value)}
        endAdornment={
          query ? (
            <InputAdornment position="end">
              <IconButton
                sx={styles.searchIcon}
                iconName="Close"
                onClick={() => {
                  onQueryChange('');
                }}
              />
            </InputAdornment>
          ) : undefined
        }
      />
    </Grid>
  );

  return (
    <Grid container>
      {matchesLg ? searchButtons() : searchInput()}
      {matchesLg ? searchInput() : searchButtons()}
    </Grid>
  );
}

export default SearchBar;
