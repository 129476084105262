/** Dependencies */
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import 'yup-phone';

export type CustomerBase = {
  id: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  is_active: boolean;
  is_open_account: boolean;
  phone_number_country: string;
  chargeable_amount?: number | null;
};

const schema = yup.object({
  first_name: yup
    .string()
    .required('customer.addModal.form.first_name.required'),
  last_name: yup.string().required('customer.addModal.form.last_name.required'),
  phone_number: yup.string(),
  is_active: yup
    .boolean()
    .required('customer.addModal.form.is_active.required'),
});

export const validationSchema = yupResolver(schema);
