/** Dependencies */
import React from 'react';
import { Grid } from '@mui/material';

/** Components */
import Button from '../Button/Button';
import Icon from '../Icon';

/** Styles */
import styles from './styles';

interface Props {
  value: string;
  totalPrice?: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  isLocked?: boolean;
  setDivided?: (value: ((prevState: any[]) => any[]) | any[]) => void;
  valueLength?: number;
  clearList?: () => void;
}

function Calculator({
  value,
  totalPrice,
  setValue,
  isLocked,
  valueLength,
  setDivided,
  clearList,
}: Props) {
  const nums = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  return (
    <>
      {nums.map(num => (
        <Grid item xs={4}>
          <Button
            key={num}
            color="inherit"
            fullWidth
            sx={styles.calculatorButton}
            onClick={() => {
              if (isLocked) {
                if (value.length === (valueLength || 4)) {
                  setValue(value);
                } else {
                  setValue(value + num.toString());
                }
              } else if (
                value.split('').find(item => item === '.') &&
                value.split('.')[1].length >= 2 &&
                value !== totalPrice
              ) {
                setValue(value);
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                setDivided && setDivided([]);
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                clearList && clearList();
              } else {
                setValue(
                  value === '0' || value === totalPrice
                    ? num.toString()
                    : value + num.toString(),
                );
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                setDivided && setDivided([]);
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                clearList && clearList();
              }
            }}
          >
            {num}
          </Button>
        </Grid>
      ))}
      <Grid item xs={4}>
        <Button
          color="inherit"
          fullWidth
          sx={styles.calculatorButton}
          onClick={() => {
            const hasDot = value.split('').find(item => item === '.') || false;
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            !isLocked && setValue(hasDot ? value : `${value}.`);
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            clearList && clearList();
          }}
        >
          {isLocked ? '' : '.'}
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          color="inherit"
          fullWidth
          sx={styles.calculatorButton}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            if (isLocked) {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              value.length !== (valueLength || 4) && setValue(`${value}0`);
            } else {
              setValue(value === '0' ? '0' : `${value}0`);
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              clearList && clearList();
            }
          }}
        >
          0
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          color="inherit"
          fullWidth
          sx={styles.calculatorButton}
          onClick={() => {
            if (isLocked) {
              setValue(value.length === 1 ? '' : value.slice(0, -1));
            } else if (
              value
                .slice(-2)
                .split('')
                .find(item => item === '.')
            ) {
              setValue(value.slice(0, -2));
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              clearList && clearList();
            } else {
              setValue(value.length === 1 ? '0' : value.slice(0, -1));
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              clearList && clearList();
            }
          }}
        >
          <Icon name="ArrowBack" />
        </Button>
      </Grid>
    </>
  );
}

export default Calculator;
