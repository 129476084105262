import { Box, CircularProgress, Grid, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormInputSelect from '../../../components/FormInputSelect';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';
import arrayHasDuplicates from '../../../helpers/arrayHasDuplicates';
import useCategories from '../../../hooks/useCategories';
import { isApiError } from '../../../services/api/types';
import { useAppSelector } from '../../../store';
import { Category } from '../../../store/slices/definitionsSlice/categories/types';
import errorReporting from '../../../helpers/errorReporting';
import useProducts from '../../../hooks/useProducts';
import Button from '../../../components/Button';
import ConfirmationModal from '../../../components/ConfirmationModal';
import ImageShowModal from '../../../components/ImageShowModal';
import { NameTranslation } from '../../../models/General';
import { OptionsLanguageMemo } from '../../../helpers/optionMemo';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm?: (category: NameTranslation[]) => void;
  item?: Category | null;
}

function CategoryAddModal({ open, onClose, onConfirm, item }: Props) {
  const { t } = useTranslation('category');
  const { enqueueSnackbar } = useSnackbar();

  const { addCategory, updateCategory } = useCategories();
  const { updateImage, deleteImage } = useProducts();

  const { list } = useAppSelector(state => state.languages);

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageData, setSelectedImageData] = useState(null);

  const options = OptionsLanguageMemo(list);

  const initialValue: NameTranslation[] = [
    {
      id: Math.random().toString(),
      name: '',
      lang: options[0].id,
    },
  ];

  const [category, setCategory] = useState<NameTranslation[]>(initialValue);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean | null>(
    null,
  );

  useEffect(() => {
    if (item) {
      setCategory(item.translations);
    }
    return () => {
      setCategory(initialValue);
    };
  }, [item]);

  useEffect(() => {
    category.forEach(element => {
      if (!element.lang) {
        const temp = category.map(el =>
          el.id === element.id ? { ...element, lang: options[0].id } : el,
        );
        setCategory(temp);
      }
    });
  }, []);

  const handleClose = () => {
    setIsSubmitted(false);
    setCategory(initialValue);
    setSelectedImage(null);
    setSelectedImageData(null);
    onClose();
  };

  const handleAddItem = () => {
    let suggestedLang = options[0].id;
    options.forEach(opt => {
      if (category.find(a => a.lang !== opt.id)) {
        suggestedLang = opt.id;
      }
    });
    setCategory([
      ...category,
      {
        id: Math.random().toString(),
        name: '',
        lang: suggestedLang,
      },
    ]);
  };

  const handleConfirm = async () => {
    if (!category.some(el => !el.name || !el.lang)) {
      if (arrayHasDuplicates(category, 'lang')) {
        enqueueSnackbar(t('category.add_Modal.messages.duplicateLanguage'), {
          variant: 'error',
          autoHideDuration: 3000,
        });
        return;
      }
      try {
        setLoading(true);

        const constructedName: Record<string, string> = {};

        category.forEach(({ lang: langId, name }) => {
          constructedName[langId] = name;
        });

        const result = !item?.id
          ? await addCategory(constructedName, selectedImageData)
          : await updateCategory({ id: item.id, name: constructedName });

        setCategory(initialValue);
        setSelectedImage(null);
        setSelectedImageData(null);
        onClose();
      } catch (err: any) {
        enqueueSnackbar(
          isApiError(err) ? err.message : t('common:unknownError' as any),
          {
            variant: 'error',
            autoHideDuration: 3000,
          },
        );
        errorReporting(err);
      } finally {
        setLoading(false);
      }
    } else {
      setIsSubmitted(true);
    }
  };

  const handleFileChange = async (e: any) => {
    setLoadingImage(true);

    if (item) {
      await updateImage(item.id, 'category', e.target.files[0]);
      await handleConfirm();
      setCategory(item.translations);
    }

    const file = e.target.files[0];
    if (file && Number(file?.size) < 2000000) {
      const formData = new FormData();
      formData.append('image', e.target.files[0]);

      // @ts-ignore
      setSelectedImage(URL.createObjectURL(file));
      setSelectedImageData(file);
    }

    setLoadingImage(false);
  };

  const handleRemove = async () => {
    if (item) {
      await deleteImage(item.id, 'category');
      await handleConfirm();
      setCategory(item.translations);
    }
    setSelectedImage(null);
    setDeleteConfirmation(null);
  };

  useEffect(() => {
    if (item?.image_url) {
      // @ts-ignore
      setSelectedImage(item.image_url);
    } else {
      setSelectedImage(null);
    }
  }, [item]);

  return (
    <Modal
      title={t('category.add_Modal.title', {
        context: item ? 'edit' : 'default',
      })}
      titleIconName={item ? 'Edit' : 'Add'}
      open={open}
      onClose={handleClose}
      positiveAction={t('category.add_Modal.confirm')}
      negativeAction={t('category.add_Modal.cancel')}
      onPositiveClick={() => {
        handleConfirm();
      }}
      onNegativeClick={handleClose}
      loading={loading}
    >
      <>
        <Grid container>
          <Grid item mt={{ xs: 3, sm: 0 }} xs={12} sm={12}>
            <Box
              sx={{
                p: 2,
                border: 1,
                borderRadius: 3,
                borderColor: 'neutral.dark',
                mb: 2,
              }}
            >
              <Grid container justifyContent="space-between">
                {selectedImage || loadingImage ? (
                  loadingImage ? (
                    <Grid style={{ width: '100px', height: '100px' }}>
                      <CircularProgress />
                    </Grid>
                  ) : (
                    <Grid onClick={() => setOpenImage(true)}>
                      <img
                        style={{
                          width: '100px',
                          height: '100px',
                          cursor: 'pointer',
                        }}
                        src={selectedImage || item?.image_url}
                        alt="Uploaded"
                      />
                    </Grid>
                  )
                ) : (
                  t('add_photo')
                )}
                <Grid item>
                  <Button
                    color="primary"
                    size="small"
                    startIcon={<Icon name="Upload" />}
                    onClick={() =>
                      // @ts-ignore
                      document.getElementById('image-input').click()
                    }
                  >
                    {!selectedImage ? t('select_image') : t('change_image')}
                  </Button>
                  {selectedImage && (
                    <IconButton
                      color="error"
                      onMouseDown={e => e.stopPropagation()}
                      onClick={() => setDeleteConfirmation(true)}
                    >
                      <Icon
                        sx={{ width: '30px', height: '30px' }}
                        name="Delete"
                      />
                    </IconButton>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="image-input"
                    onChange={handleFileChange}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        {category.map((element, index) => {
          const invalid = isSubmitted && (!element.name || !element.lang);
          return (
            <Grid container key={element.id}>
              <Grid item xs={11}>
                <FormInputSelect
                  label={t('category.add_Modal.form.name.label')}
                  error={invalid}
                  helperText={
                    invalid && t('category.add_Modal.form.name.required')
                  }
                  value={element.name}
                  onChange={event => {
                    const temp = category.map(el =>
                      el.id === element.id
                        ? { ...element, name: event.target.value }
                        : el,
                    );
                    setCategory(temp);
                  }}
                  options={options}
                  selectValue={element.lang}
                  onSelectValueChange={id => {
                    const selectedLanguage = options.find(
                      lang => lang.id === id,
                    );
                    if (selectedLanguage) {
                      const temp = category.map(el =>
                        el.id === element.id
                          ? { ...element, lang: selectedLanguage.id }
                          : el,
                      );
                      setCategory(temp);
                    }
                  }}
                />
              </Grid>
              <Grid item sx={{ pt: 4 }}>
                {/* // Show only delete button if input is not the last one */}
                {index === category.length - 1 ? (
                  <>
                    {/* // Show delete button near to add button if we have more than one input only */}
                    {category.length > 1 && (
                      <IconButton
                        aria-label="delete"
                        onClick={() =>
                          setCategory(
                            category.filter(el => el.id !== element.id),
                          )
                        }
                        color="error"
                      >
                        <Icon name="Delete" />
                      </IconButton>
                    )}
                    {/* // Show add button only if category is less than available languages */}
                    {category.length < options.length && (
                      <IconButton
                        aria-label="add"
                        onClick={handleAddItem}
                        color="success"
                      >
                        <Icon name="Add" />
                      </IconButton>
                    )}
                  </>
                ) : (
                  <IconButton
                    aria-label="delete"
                    onClick={() =>
                      setCategory(category.filter(el => el.id !== element.id))
                    }
                    color="error"
                  >
                    <Icon name="Delete" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          );
        })}
        <ConfirmationModal
          title={t('deleteConfirmation.title')}
          titleIconName="QuestionMarkOutlined"
          description={t('deleteConfirmation.description')}
          open={!!deleteConfirmation}
          onClose={() => setDeleteConfirmation(null)}
          positiveAction={t('deleteConfirmation.confirm')}
          onPositiveClick={handleRemove}
          negativeAction={t('deleteConfirmation.cancel')}
          onNegativeClick={() => setDeleteConfirmation(null)}
          loading={false}
        />
        <ImageShowModal
          open={openImage}
          onClose={() => setOpenImage(false)}
          image={selectedImage}
        />
      </>
    </Modal>
  );
}

export default CategoryAddModal;
