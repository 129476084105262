import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../store';

import {
  changeBranchThunk,
  createUserThunk,
  getUsersListThunk,
  getUserThunk,
  removeUserThunk,
  updateUserThunk,
} from '../../store/slices/userOperationsSlice/users';
import {
  UserCreateParams,
  UserUpdateParams,
} from '../../services/api/userOperations/users/types';
import { isApiError } from '../../services/api/types';
import { meThunk } from '../../store/slices/authSlice';

const useUsers = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');

  const users = useAppSelector(state => state.userOperationsUsers.users);
  const meta = useAppSelector(state => state.userOperationsUsers.meta);
  const user = useAppSelector(state => state.userOperationsUsers.user);
  const userLoading = useAppSelector(
    state => state.userOperationsUsers.loading,
  );
  const userError = useAppSelector(state => state.userOperationsUsers.error);

  const fetchUsers = (params: { page: number; role?: string }) =>
    dispatch(getUsersListThunk(params));

  const getUser = (id: string) => dispatch(getUserThunk(id));

  const addUser = (params: UserCreateParams) =>
    dispatch(createUserThunk(params));

  const updateUser = (params: UserUpdateParams) =>
    dispatch(updateUserThunk(params));

  const changeBranch = async (branch_id: string) => {
    try {
      await dispatch(changeBranchThunk(branch_id));
      if (
        pathname.length > 1 &&
        !pathname.includes('kitchen') &&
        !pathname.includes('orders')
      ) {
        await dispatch(meThunk());
      }
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
      });
    } catch (err: any) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
    }
  };
  const removeUser = async (id: string) => {
    try {
      await dispatch(removeUserThunk(id)).unwrap();
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
      });
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
      });
      throw err;
    }
  };

  return {
    fetchUsers,
    getUser,
    addUser,
    updateUser,
    changeBranch,
    removeUser,
    users,
    user,
    userLoading,
    userError,
    meta,
  };
};

export default useUsers;
