import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormSwitch from '../../../../../components/FormSwitch';
import FormInputSelect from '../../../../../components/FormInputSelect';
import FormSelect from '../../../../../components/FormSelect';
import IconButtonWithTooltip from '../../../../../components/IconButtonWithTooltip';
import useCategories from '../../../../../hooks/useCategories';
import FormButtons from '../FormButtons';
import {
  ProductInfoFormValues,
  validationSchema,
  defaultValues,
} from './types';
import useTaxRates from '../../../../../hooks/useTaxRates';
import useUnits from '../../../../../hooks/useUnits';
import useKitchenGroups from '../../../../../hooks/useKitchenGroups';
import FormInput from '../../../../../components/FormInput';
import Button from '../../../../../components/Button';
import Icon from '../../../../../components/Icon';
import { useAppSelector } from '../../../../../store';
import getActiveLanguage from '../../../../../helpers/getActiveLanguage';
import useProducts from '../../../../../hooks/useProducts';
import ConfirmationModal from '../../../../../components/ConfirmationModal';
import ImageShowModal from '../../../../../components/ImageShowModal';
import { OptionsLanguageMemo } from '../../../../../helpers/optionMemo';

interface Props {
  onValidSubmit: (data: ProductInfoFormValues) => void;
  submitting: boolean;
  categoryId: string;
  initialValues?: ProductInfoFormValues;
  productId?: string;
}

function InfoForm({
  onValidSubmit,
  submitting,
  categoryId,
  initialValues,
  productId,
}: Props) {
  const { t } = useTranslation('product');
  const { categories, fetchCategories } = useCategories();
  const { units, fetchUnits } = useUnits();
  const { kitchenGroups, fetchKitchenGroups } = useKitchenGroups();
  const { updateImage, deleteImage } = useProducts();
  const { taxRates } = useTaxRates();

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageData, setSelectedImageData] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean | null>(
    null,
  );
  const [loading, setLoading] = useState(false);
  const [openImage, setOpenImage] = useState(false);

  const handleFileChange = async (e: any) => {
    setLoading(true);
    const file = e.target.files[0];

    if (initialValues && productId && file) {
      await updateImage(productId, 'product', e.target.files[0]);
    }

    if (file && Number(file?.size) < 2000000) {
      const formData = new FormData();
      formData.append('image', e.target.files[0]);

      // @ts-ignore
      setSelectedImage(URL.createObjectURL(file));
      setSelectedImageData(file);
    }

    setLoading(false);
  };

  const { handleSubmit, control, reset, setValue } =
    useForm<ProductInfoFormValues>({
      defaultValues:
        initialValues || defaultValues(categoryId!, kitchenGroups[0]?.id || ''),
      resolver: validationSchema,
    });

  useEffect(() => {
    reset(initialValues);
    // @ts-ignore
    setSelectedImage(initialValues?.image_url || null);
  }, [initialValues]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'name',
  });

  const { list } = useAppSelector(state => state.languages);
  const options = OptionsLanguageMemo(list);

  useEffect(() => {
    if (!categories?.length) {
      fetchCategories();
    }
    if (!units?.length) {
      fetchUnits();
    }
    if (!kitchenGroups?.length) {
      fetchKitchenGroups();
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    setValue(
      'kitchen_group_id',
      initialValues?.kitchen_group_id || kitchenGroups[0]?.id,
    );
  }, [kitchenGroups]);

  const onSubmit = handleSubmit(async values => {
    onValidSubmit({
      ...values,
      image: selectedImageData || '',
    });
  });

  const handleRemove = async () => {
    if (initialValues) {
      await deleteImage(productId || '', 'product');
    }
    setSelectedImage(null);
    setDeleteConfirmation(null);
  };

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100%',
        pt: 2,
      }}
    >
      <Grid container columnSpacing={3}>
        <Grid item mt={{ xs: 3, sm: 0 }} xs={12} sm={6}>
          <Box
            sx={{
              p: 2,
              border: 1,
              borderRadius: 3,
              borderColor: 'neutral.dark',
              mb: 2,
            }}
          >
            <Grid container justifyContent="space-between">
              <Grid item>
                {selectedImage || loading ? (
                  loading ? (
                    <Grid style={{ width: '100px', height: '100px' }}>
                      <CircularProgress />
                    </Grid>
                  ) : (
                    <Grid onClick={() => setOpenImage(true)}>
                      <img
                        style={{
                          width: '100px',
                          height: '100px',
                          cursor: 'pointer',
                        }}
                        src={selectedImage || initialValues?.image_url}
                        alt="Uploaded"
                      />
                    </Grid>
                  )
                ) : (
                  t('add_photo')
                )}
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  size="small"
                  startIcon={<Icon name="Upload" />}
                  // @ts-ignore
                  onClick={() => document.getElementById('image-input').click()}
                >
                  {!selectedImage ? t('select_image') : t('change_image')}
                </Button>
                {selectedImage && (
                  <IconButton
                    color="error"
                    onMouseDown={e => e.stopPropagation()}
                    onClick={() => setDeleteConfirmation(true)}
                  >
                    <Icon
                      sx={{ width: '30px', height: '30px' }}
                      name="Delete"
                    />
                  </IconButton>
                )}
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="image-input"
                  onChange={handleFileChange}
                />
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              p: 2,
              border: 1,
              borderRadius: 3,
              borderColor: 'neutral.dark',
              mb: 2,
            }}
          >
            <Stack
              sx={{ mb: 2 }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>{t('wizard.form.name.label')}</Typography>

              <Button
                onClick={() => append({ lang: 'en', text: '' })}
                color="success"
                size="small"
                startIcon={<Icon name="Add" />}
                disabled={options.length === fields.length}
              >
                {t('wizard.form.name.add')}
              </Button>
            </Stack>
            {fields.map((item, index) => (
              <Stack key={item.id} direction="row" alignItems="center">
                <Box sx={{ flex: 1 }}>
                  <Controller
                    name={`name.${index}.text`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormInputSelect
                        selectName={`name.${index}.lang`}
                        selectControl={control}
                        error={fieldState.invalid}
                        helperText={
                          fieldState.error
                            ? t(fieldState.error.message as any)
                            : undefined
                        }
                        options={options}
                        {...field}
                      />
                    )}
                  />
                </Box>
                <IconButtonWithTooltip
                  sx={{ mr: 0.5, mb: 1.5 }}
                  tip={t('wizard.form.name.remove')}
                  iconName="Delete"
                  onClick={() => remove(index)}
                  color="error"
                  disabled={fields.length < 2}
                />
              </Stack>
            ))}
          </Box>

          <Box>
            <Controller
              name="is_active"
              control={control}
              render={({ field, fieldState }) => (
                <FormSwitch
                  label={t('wizard.form.active.label')}
                  error={fieldState.invalid}
                  {...field}
                />
              )}
            />
            <Controller
              name="is_menu"
              control={control}
              render={({ field, fieldState }) => (
                <FormSwitch
                  label={t('wizard.form.menuIncluded.label')}
                  error={fieldState.invalid}
                  {...field}
                />
              )}
            />
            <Controller
              name="is_parent"
              control={control}
              render={({ field, fieldState }) => (
                <FormSwitch
                  label={t('wizard.form.productGroup.label')}
                  error={fieldState.invalid}
                  {...field}
                />
              )}
            />
            <Controller
              name="is_required_property_selection"
              control={control}
              render={({ field, fieldState }) => (
                <FormSwitch
                  label={t('wizard.form.requiredProperty.label')}
                  error={fieldState.invalid}
                  {...field}
                />
              )}
            />
            <Controller
              name="show_on_qr_menu"
              control={control}
              render={({ field, fieldState }) => (
                <FormSwitch
                  label={t('wizard.form.show_on_qr_menu')}
                  error={fieldState.invalid}
                  {...field}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item mt={{ xs: 3, sm: 0 }} xs={12} sm={6}>
          <Controller
            name="category_id"
            control={control}
            render={({ field, fieldState }) => (
              <FormSelect
                options={categories.map(item => ({
                  ...item,
                  text: getActiveLanguage(item.translations).name,
                }))}
                label={t('wizard.form.category.label')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
          <Controller
            name="kitchen_group_id"
            control={control}
            render={({ field, fieldState }) => (
              <FormSelect
                options={kitchenGroups.map(item => ({
                  ...item,
                  text: item.name,
                }))}
                label={t('wizard.form.kitchenGroup.label')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />

          <Controller
            name="tax_rate"
            control={control}
            render={({ field, fieldState }) => (
              <FormSelect
                options={taxRates.map(item => ({
                  id: item.tax_rate,
                  text: `%${item.tax_rate}`,
                }))}
                label={t('wizard.form.taxRate.label')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
          <Controller
            name="product_code"
            control={control}
            render={({ field, fieldState }) => (
              <FormInput
                label={t('wizard.form.productCode.label')}
                placeholder={t('wizard.form.productCode.product_code')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>

      <FormButtons loading={submitting} isFirst />

      <ConfirmationModal
        title={t('deleteConfirmationImage.title')}
        titleIconName="QuestionMarkOutlined"
        description={t('deleteConfirmationImage.description')}
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        positiveAction={t('deleteConfirmationImage.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('deleteConfirmationImage.cancel')}
        onNegativeClick={() => setDeleteConfirmation(null)}
        loading={false}
      />
      <ImageShowModal
        open={openImage}
        onClose={() => setOpenImage(false)}
        image={selectedImage}
      />
    </Box>
  );
}

export default InfoForm;
