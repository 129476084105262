import React, { useEffect, useState } from 'react';
import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useFlow from '../../../../hooks/useFlow';
import EmptyState from '../../../../components/EmptyState';
import Icon from '../../../../components/Icon';
import FraudFlowAddModal from '../Modals/FraudFlowAddModal';
import { Flow } from '../../../../services/api/operations/fraud/types';

interface Props {
  isSaved: boolean;
  setIsSaved: React.Dispatch<React.SetStateAction<boolean>>;
}

export function FraudFlows({ isSaved, setIsSaved }: Props) {
  const { t } = useTranslation('frauds');

  const { fetchFraudFlow, flowList, flowListMeta } = useFlow();
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [transaction, setTransaction] = useState<Flow | null>(null);

  // todo: burası generic yapılacak
  const handleChange = async (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
    await fetchFraudFlow(value);
  };

  const renderEmpty = () => <EmptyState message="Veri Bulunamadı" />;

  useEffect(() => {
    fetchFraudFlow(page);
  }, []);

  useEffect(() => {
    if (isSaved) {
      fetchFraudFlow(page);
      setIsSaved(false);
    }
  }, [isSaved]);

  return (
    <Box sx={{ width: '100%' }}>
      {!flowList.length ? (
        renderEmpty()
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('flows.table.name')}</TableCell>
                <TableCell>{t('flows.table.params')}</TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {flowList.map(row => (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {row.triggers.map(
                      (item, index) =>
                        `${item.action_type.label}${
                          index + 1 < row.triggers.length ? ', ' : ''
                        }`,
                    )}
                  </TableCell>
                  <TableCell
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setTransaction(row);
                      setOpen(true);
                    }}
                  >
                    <Icon name="Edit" color="success" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            sx={{ margin: '10px' }}
            count={Math.ceil(
              // @ts-ignore
              flowListMeta.total / flowListMeta.per_page,
            )}
            page={page}
            onChange={handleChange}
          />
        </TableContainer>
      )}
      <FraudFlowAddModal
        open={open}
        onClose={() => {
          setTransaction(null);
          setOpen(false);
        }}
        item={transaction}
        onSaved={() => setIsSaved(true)}
      />
    </Box>
  );
}
