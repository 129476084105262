import api from '../../api';
import { ApiSuccessResponse } from '../../types';
import {
  CreateResponse,
  ListResponse,
  ViewResponse,
  UpdateResponse,
  DeleteResponse,
} from './types';

const PAGING_LIMIT = 100;

type ListRes = ApiSuccessResponse<ListResponse>;
type ListArguments = {
  name?: string;
  hasProducts?: boolean;
};

const list = (input?: ListArguments) =>
  api.get<ListRes>('/categories', {
    params: {
      per_page: PAGING_LIMIT,
      filter: {
        name: input?.name,
        hasProducts: input?.hasProducts,
      },
    },
  });

type ViewRes = ApiSuccessResponse<ViewResponse>;
const view = (id: string) => api.get<ViewRes>(`/categories/${id}`);

type CreatRes = ApiSuccessResponse<CreateResponse>;
const create = (name: { [key: string]: string }, image?: any) => {
  const formData = new FormData();

  if (image) {
    formData.append('image', image);
  }
  formData.append('is_active_on_pos', '1');
  formData.append('is_active_on_online', '1');

  if (name.tr) {
    formData.append('name[tr]', name.tr);
  }

  if (name.en) {
    formData.append('name[en]', name.en);
  }

  return api.post<CreatRes>('/categories', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

type UpdateRes = ApiSuccessResponse<UpdateResponse>;
const update = (params: { id: string; name: { [key: string]: string } }) =>
  api.put<UpdateRes>(`/categories/${params.id}`, {
    name: params.name,
    is_active_on_pos: true,
    is_active_on_online: true,
  });

type DeleteRes = ApiSuccessResponse<DeleteResponse>;
const remove = (id: string) => api.delete<DeleteRes>(`/categories/${id}`);

const moveUp = (id: string) =>
  api.post<void>(`/categories/${id}/move-sequence-up`);

const moveDown = (id: string) =>
  api.post<void>(`/categories/${id}/move-sequence-down`);

export default { list, view, create, update, remove, moveUp, moveDown };
