import React, { useState } from 'react';
import { Box, Paper, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UtilitiesBar, {
  Props as BarProps,
} from '../../../components/UtilitiesBar';
import FraudFlows from './FraudFlows';
import SuspiciousTransactions from './SuspiciousTransactions';
import FraudFlowAddModal from './Modals/FraudFlowAddModal';
import { Flow } from '../../../services/api/operations/fraud/types';
import TabPanel from '../../CompanyRegister/TabPanel';
import keyHasPermission from '../../../helpers/keyHasPermission';
import useProfile from '../../../hooks/useProfile';
import EmptyState from '../../../components/EmptyState';
import { a11yProps } from '../../../helpers/rgbToHex';

export function FraudTracking() {
  const { meProfile } = useProfile();
  const { t } = useTranslation('frauds');
  const [open, setOpen] = useState<boolean>(false);
  const [transaction, setTransaction] = useState<Flow | null>(null);
  const [value, setValue] = useState(0);
  const [isSaved, setIsSaved] = useState<boolean>(false);

  const hasManageSuspiciousTransactions = keyHasPermission(
    'manage-suspicious-transactions',
    meProfile?.permissions || [],
  );

  const MENU: BarProps['menu'] = [
    { id: 0, text: t('headerButton'), startIconName: 'Add' },
  ];

  // todo: generic yap
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (!hasManageSuspiciousTransactions) {
    return <EmptyState message={t('common:none_permission' as any)} />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('title')}
        leftIconName="DesktopMac"
        menu={
          value === 0
            ? MENU!.map(item => ({ ...item, text: t(item.text as any) }))
            : []
        }
        onActionClick={() => setOpen(true)}
      />
      <Paper sx={{ p: 2, flex: 1, mb: 3 }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={t('tabs.flows')} {...a11yProps(0)} />
            <Tab label={t('tabs.suspiciousTransactions')} {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Paper>
      <Paper sx={{ p: 3, flex: 1 }} elevation={3}>
        <TabPanel value={value} index={0}>
          <FraudFlows isSaved={isSaved} setIsSaved={setIsSaved} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SuspiciousTransactions />
        </TabPanel>
      </Paper>
      <FraudFlowAddModal
        open={open}
        onClose={() => {
          setTransaction(null);
          setOpen(false);
        }}
        onSaved={() => setIsSaved(true)}
        item={transaction}
      />
    </Box>
  );
}
