import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getExpenseListThunk } from './thunks';

import { InitialState } from './types';
import { Expense } from '../../../../services/api/operations/expenses/types';

const initialState: InitialState = {
  loading: false,
  expenses: [],
  meta: [],
  expensesMeta: [],
};

const expensesSlice = createSlice({
  name: 'expensesSlice',
  initialState,
  reducers: {
    addExpenseSlice: (state, action: PayloadAction<Expense>) => ({
      ...state,
      expenses: [...state.expenses, action.payload],
    }),
    updateExpenseSlice: (state, action: PayloadAction<Expense>) => ({
      ...state,
      expenses: state.expenses.map(item =>
        item.id === action.payload.id ? action.payload : item,
      ),
    }),
    getExpensePopularSlice: (state, action) => ({
      ...state,
      expensesMeta: action.payload.meta,
    }),
    removeExpenseSlice: (
      state,
      action: PayloadAction<Pick<Expense, 'id'>>,
    ) => ({
      ...state,
      expenses: state.expenses.filter(item => item.id !== action.payload.id),
    }),
  },
  extraReducers: builder => {
    builder
      .addCase(getExpenseListThunk.pending, state => ({
        ...state,
        loading: true,
      }))
      .addCase(getExpenseListThunk.fulfilled, (state, action) => ({
        ...state,
        expenses: action.payload.data,
        meta: action.payload.meta,
        loading: false,
      }))
      .addCase(getExpenseListThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }));
  },
});

export const {
  addExpenseSlice,
  updateExpenseSlice,
  getExpensePopularSlice,
  removeExpenseSlice,
} = expensesSlice.actions;

export default expensesSlice.reducer;
