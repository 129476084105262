import { GroupedData, GroupedDataColumnType } from './types';
import api from '../api';
import { ApiSuccessResponse } from '../types';
import { PackageOrderParams, SelfOrderParams } from '../pos/types';
import { PosTransaction } from '../../../models/Pos';

type PackageOrderRes = ApiSuccessResponse<{ id: string }>;
const packageOrder = async (params: Omit<PackageOrderParams, 'type'>) =>
  api.post<PackageOrderRes>('/orders', { type: 3, ...params });

type SelfOrderRes = ApiSuccessResponse<{ id: string }>;
const selfOrder = async (params: Omit<SelfOrderParams, 'type'>) =>
  api.post<SelfOrderRes>('/orders', { type: 2, ...params });

type GroupedOrders = ApiSuccessResponse<GroupedDataColumnType[]>;
const getGroupedOrders = (
  day_id: string,
  is_active: boolean,
  is_completed: boolean,
  is_canceled: boolean,
  page: number,
  status_id: number[] | null,
) =>
  api.get<GroupedOrders>('/order-transactions', {
    params: {
      filter: {
        day_id,
        is_active,
        is_completed,
        is_canceled,
        is_pos: true,
        status_id,
      },
      page,
    },
  });

const getOrdersLastTransactions = async (customer_id: string) =>
  api.get<GroupedOrders>('/order-transactions', {
    params: {
      is_active: false,
      filter: {
        customer_id,
      },
    },
  });

const setStatus = (transactionId: string, status_id: number) =>
  api.post<GroupedOrders>(`/order-transactions/${transactionId}/set-status`, {
    status_id,
  });

type GroupedOrderDetailsRes = ApiSuccessResponse<PosTransaction>;
const getGroupedOrderDetails = (tableId: string) =>
  api.get<GroupedOrderDetailsRes>(
    `/order-transactions/${tableId}/order-transaction`,
    {
      params: {
        sort: '-created_at',
      },
    },
  );

export default {
  packageOrder,
  selfOrder,
  getGroupedOrders,
  getGroupedOrderDetails,
  setStatus,
  getOrdersLastTransactions,
};
