import {
  registrationStepStorage,
  expiredAccountStorage,
} from '../services/tokenStorage';

function arrayCatcher(code: number) {
  if (code === 1001) {
    registrationStepStorage.setToken('3');
  }

  if (code === 1002) {
    expiredAccountStorage.setToken('1');
  } else {
    expiredAccountStorage.removeToken();
  }
}

export default arrayCatcher;
