/** Dependencies */
import React from 'react';

/** Styles */
import { Grid, Typography } from '@mui/material';

/** Components */
import categoryImageOne from '../../../assets/food1.jpeg';
import categoryImageTwo from '../../../assets/food2.jpeg';
import productImageOne from '../../../assets/food1.png';
import productImageTwo from '../../../assets/food3.jpeg';
import productImageThree from '../../../assets/food2 kopyası.jpeg';
import { QrMenuDataType } from '../types';
import LogoImage from '../../../assets/qrLogo.svg';

interface Props {
  qrMenuData: QrMenuDataType;
  selectedImageMenu: string | null;
}

function QrMenu({ qrMenuData, selectedImageMenu }: Props) {
  return (
    <Grid
      item
      md={4}
      sm={12}
      sx={{
        p: 1,
      }}
    >
      <Grid
        sx={{
          backgroundColor: 'white',
          borderRadius: '10px',
        }}
        container
      >
        <Grid
          container
          sx={{
            backgroundColor: qrMenuData.color,
            p: 5,
            borderRadius: '10px 10px 0 0',
          }}
          alignItems="center"
          textAlign="center"
          justifyContent="center"
        >
          <img
            style={{ height: '75px', width: '75px' }}
            alt=""
            src={selectedImageMenu || LogoImage}
          />
        </Grid>
        <Grid container p={1}>
          <Grid item p={1} xs={4} />
          {qrMenuData.use_category_image ? (
            <>
              <Grid item p={1} xs={4}>
                <Grid>
                  <img
                    style={{ height: '75px', width: '100%' }}
                    alt=""
                    src={categoryImageOne}
                  />
                </Grid>
              </Grid>
              <Grid item p={1} xs={4}>
                <Grid>
                  <img
                    style={{ height: '75px', width: '100%' }}
                    alt=""
                    src={categoryImageTwo}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item p={1} xs={4}>
                <Grid
                  item
                  p={1}
                  sx={{
                    backgroundColor: '#80808061',
                    p: 1,
                    height: '20px',
                    borderRadius: '10px',
                  }}
                />
              </Grid>
              <Grid item p={1} xs={4}>
                <Grid
                  item
                  p={1}
                  sx={{
                    backgroundColor: '#80808061',
                    p: 1,
                    height: '20px',
                    borderRadius: '10px',
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid container p={1}>
          <>
            <Grid item p={1} xs={3}>
              <Grid
                item
                p={1}
                sx={{
                  backgroundColor: '#80808061',
                  p: 1,
                  height: '20px',
                  borderRadius: '10px',
                }}
              />
            </Grid>
            <Grid item p={1} xs={3}>
              <Grid
                item
                p={1}
                sx={{
                  backgroundColor: '#80808061',
                  p: 1,
                  height: '20px',
                  borderRadius: '10px',
                }}
              />
            </Grid>
            <Grid item p={1} xs={3}>
              <Grid
                item
                p={1}
                sx={{
                  backgroundColor: '#80808061',
                  p: 1,
                  height: '20px',
                  borderRadius: '10px',
                }}
              />
            </Grid>
            <Grid item p={1} xs={3}>
              <Grid
                item
                p={1}
                sx={{
                  backgroundColor: '#80808061',
                  p: 1,
                  height: '20px',
                  borderRadius: '10px',
                }}
              />
            </Grid>
          </>
        </Grid>

        <Grid p={2} container>
          {qrMenuData.use_product_image ? (
            <Grid
              item
              xs={12}
              sx={{
                height: '75px',
                borderRadius: '10px',
                border: '1px solid #80808061',
              }}
            >
              <img
                style={{
                  height: '50px',
                  width: '25%',
                  marginTop: '10px',
                  marginLeft: '10px',
                }}
                alt=""
                src={productImageOne}
              />
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sx={{
                height: '75px',
                borderRadius: '10px',
                border: '1px solid #80808061',
              }}
            />
          )}
        </Grid>
        <Grid p={2} container>
          {qrMenuData.use_product_image ? (
            <Grid
              item
              xs={12}
              sx={{
                height: '75px',
                borderRadius: '10px',
                border: '1px solid #80808061',
              }}
            >
              <img
                style={{
                  height: '50px',
                  width: '25%',
                  marginTop: '10px',
                  marginLeft: '10px',
                }}
                alt=""
                src={productImageTwo}
              />
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sx={{
                height: '75px',
                borderRadius: '10px',
                border: '1px solid #80808061',
              }}
            />
          )}
        </Grid>
        <Grid p={2} container>
          {qrMenuData.use_product_image ? (
            <Grid
              item
              xs={12}
              sx={{
                height: '75px',
                borderRadius: '10px',
                border: '1px solid #80808061',
              }}
            >
              <img
                style={{
                  height: '50px',
                  width: '25%',
                  marginTop: '10px',
                  marginLeft: '10px',
                }}
                alt=""
                src={productImageThree}
              />
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sx={{
                height: '75px',
                borderRadius: '10px',
                border: '1px solid #80808061',
              }}
            />
          )}
        </Grid>
        <Grid p={2} container>
          {qrMenuData.use_product_image ? (
            <Grid
              item
              xs={12}
              sx={{
                height: '75px',
                borderRadius: '10px',
                border: '1px solid #80808061',
              }}
            >
              <img
                style={{
                  height: '50px',
                  width: '25%',
                  marginTop: '10px',
                  marginLeft: '10px',
                }}
                alt=""
                src={productImageOne}
              />
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sx={{
                height: '75px',
                borderRadius: '10px',
                border: '1px solid #80808061',
              }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default QrMenu;
