import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styles from './styles';
import Form from './Form';
import { useAppSelector } from '../../store';
import useAuth from '../../hooks/useAuth';
import Logo from '../../components/Logo';
import { ReactComponent as Promotion } from '../../assets/promotion/login-promotion.svg';
import { isApiError } from '../../services/api/types';
import { ResetValues } from '../../services/api/auth/types';

function ForgotPassword() {
  const { t } = useTranslation('auth');
  const { forgotPassword, checkResetPassword, resetPassword } = useAuth();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const parts = location.pathname.split('/');
  const token = parts[2] || '';

  const isLoading = useAppSelector(state => state.auth.login.loading);

  const handleForgot = async (email: string) => {
    try {
      await forgotPassword(email);
      enqueueSnackbar(t('forgot.successMail'), {
        variant: 'success',
      });
    } catch (err) {
      // @ts-ignore
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
      });
    }
  };
  const handleCheck = async () => {
    try {
      await checkResetPassword(token);
    } catch (err) {
      // @ts-ignore
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      navigate('/login');
    }
  };

  const handleReset = async (params: ResetValues) => {
    try {
      await resetPassword(params);
      enqueueSnackbar(t('forgot.successPassword'), {
        variant: 'success',
        autoHideDuration: 3000,
      });
      navigate('/login');
    } catch (err) {
      // @ts-ignore
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  useEffect(() => {
    if (token) {
      handleCheck();
    }
  }, [token]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftContainer}>
        <Box sx={styles.logo}>
          <Logo />
          <Typography sx={styles.logoSubtitle} variant="h6" textAlign="center">
            {t('privacy')}
            <br />
            {t('kitchen_title')}
          </Typography>
        </Box>
        <Box sx={styles.promotion}>
          <Promotion height="100%" width="100%" title="promotion-picture" />
        </Box>
      </Box>
      <Box sx={styles.rightContainer}>
        <Box sx={styles.formContainer}>
          <Typography
            variant="h5"
            fontWeight="bold"
            textAlign="center"
            sx={{ mb: 1 }}
          >
            {token ? t('forgot.titleReset') : t('forgot.title')}
          </Typography>
          <Typography variant="subtitle1" textAlign="center">
            {token
              ? t('forgot.forgotSubtitleReset')
              : t('forgot.forgotSubtitle')}
          </Typography>
          <br />
          <Form
            onValidSubmit={async data =>
              token
                ? handleReset({
                    token,
                    password: data.password,
                    password_confirmation: data.rePassword,
                  })
                : handleForgot(data.email || '')
            }
            submitting={isLoading}
            token={token}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ForgotPassword;
