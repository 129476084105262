import { SxProps, Theme } from '@mui/material';

const styles: Record<string, SxProps<Theme>> = {
  container: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 2,
    },
  },
  priceInfo: {
    fontWeight: '400',
    fontSize: 12,
    padding: '4px 8px 0 0',
    color: 'ff0000c1',
  },
  categoryContainer: {
    // width: 200,
    width: '100%',
    height: { xs: 90, sm: 50, lg: 75 },
    marginTop: { xs: '15px', sm: '10px', lg: '15px' },
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 2,
    },
  },
  textContainer: {
    flex: 1,
    p: { xs: 3, sm: 1, lg: 3 },
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textButtonContainer: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: { fontSize: 16, textTransform: 'capitalize' },
  nonSelectable: { userSelect: 'none', mt: 0 },
  gridContainer: { p: 1, display: 'flex', marginBottom: '15px' },
  menuModalLeftPaper: {
    p: 2,
    flex: 3,
    height: { sm: '60vh', md: '60vh', lg: '63vh', xl: '68vh' },
    maxHeight: { sm: '60vh', md: '60vh', lg: '63vh', xl: '68vh' },
  },
  menuModalRightPaper: {
    p: 2,
    flex: 5,
    height: { sm: '60vh', md: '60vh', lg: '63vh', xl: '68vh' },
    maxHeight: { sm: '60vh', md: '60vh', lg: '63vh', xl: '68vh' },
  },
  menuName: {
    textDecoration: 'underline 1.5px red',
    textUnderlinePosition: 'under',
    fontSize: { xs: 12, sm: 13, md: 14, lg: 16 },
    mt: { xs: 1, lg: 2 },
  },
};

export default styles;
