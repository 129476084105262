import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../services/api';

export const getUnitListThunk = createAsyncThunk(
  'unit/list',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.definitions.units.list();
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
