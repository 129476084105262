import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Grid, IconButton } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import useSettings from '../../hooks/useSettings';
import useUtilities from '../../hooks/useUtilities';
import useProducts from '../../hooks/useProducts';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import FormInput from '../../components/FormInput';
import {
  GeneralSettingFromValues,
  validationOnlineFoodOrderSettingsSchema,
} from './types';
import FormSelect from '../../components/FormSelect';
import { OptionsMemo, OptionsMemoWithName } from '../../helpers/optionMemo';
import useCategories from '../../hooks/useCategories';
import styles from '../Register/styles';
import { isApiError } from '../../services/api/types';
import ConfirmationModal from '../../components/ConfirmationModal';

function OnlineFoodOrderSettings() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('apps');
  const {
    channelSales,
    fetchChannelSales,
    fetchOnlineFoodOrderInfo,
    updateOnlineFoodDelivery,
    onlineFoodDelivery,
  } = useSettings();
  const { paymentTypes, fetchPaymentTypes } = useUtilities();
  const { categories, fetchCategories } = useCategories();
  const { updateImage, deleteImage } = useProducts();

  const initialValues = {
    domain_name: onlineFoodDelivery.domain_name || '',
    categories: onlineFoodDelivery.categories || [],
    payment_types: onlineFoodDelivery.payment_types || [],
    sales_channel_id: onlineFoodDelivery.sales_channel_id || '',
    facebook_url: onlineFoodDelivery.facebook_url || '',
    instagram_url: onlineFoodDelivery.instagram_url || '',
    twitter_url: onlineFoodDelivery.twitter_url || '',
    address: onlineFoodDelivery.address || '',
    phone_number: onlineFoodDelivery.phone_number || '',
  };

  const { handleSubmit, control, reset } = useForm<GeneralSettingFromValues>({
    defaultValues: initialValues,
    resolver: validationOnlineFoodOrderSettingsSchema,
  });

  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageData, setSelectedImageData] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean | null>(
    null,
  );
  const [openImage, setOpenImage] = useState(false);

  const handleFileChange = async (e: any) => {
    setLoadingImage(true);
    await updateImage(
      onlineFoodDelivery?.id || '',
      'online-food-delivery',
      e.target.files[0],
    );

    const file = e.target.files[0];
    if (file && Number(file?.size) < 2000000) {
      const formData = new FormData();
      formData.append('image', e.target.files[0]);

      // @ts-ignore
      setSelectedImage(URL.createObjectURL(file));
      setSelectedImageData(file);
    }

    setLoadingImage(false);
  };

  const handleRemove = async () => {
    if (onlineFoodDelivery?.id) {
      await deleteImage(onlineFoodDelivery?.id || '', 'online-food-delivery');
    }
    setSelectedImage(null);
    setDeleteConfirmation(null);
  };

  const handleClick = () => {
    try {
      setLoading(true);
      handleSubmit(async values => {
        try {
          await updateOnlineFoodDelivery({
            ...values,
          });
        } catch (err) {
          enqueueSnackbar(
            isApiError(err) ? err.message : t('common:unknownError' as any),
            {
              variant: 'error',
              style: { whiteSpace: 'pre-line' },
            },
          );
          throw err;
        }
      })();
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchPaymentTypes();
    fetchChannelSales();
    fetchOnlineFoodOrderInfo();
  }, []);

  useEffect(() => {
    if (onlineFoodDelivery) {
      // @ts-ignore
      setSelectedImage(onlineFoodDelivery?.logo_url || '');
      reset(initialValues);
    }
  }, [onlineFoodDelivery]);

  return (
    <Box
      sx={{
        py: 10,
        px: 10,
        paddingX: {
          xxs: 0,
          xs: 0,
          sm: 0,
          md: 3,
          lg: 15,
          xl: 20,
        },
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid item mt={{ xs: 3, sm: 0 }} xs={12} sm={12}>
            <Box
              sx={{
                p: 2,
                border: 1,
                borderRadius: 3,
                borderColor: 'neutral.dark',
                mb: 2,
              }}
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  {selectedImage || loadingImage ? (
                    loadingImage ? (
                      <Grid style={{ width: '100px', height: '100px' }}>
                        <CircularProgress />
                      </Grid>
                    ) : (
                      <Grid onClick={() => setOpenImage(true)}>
                        <img
                          style={{
                            width: '100px',
                            height: '100px',
                            cursor: 'pointer',
                          }}
                          src={selectedImage || onlineFoodDelivery?.logo_url}
                          alt="Uploaded"
                        />
                      </Grid>
                    )
                  ) : (
                    t('online_food_order_settings.add_image')
                  )}
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    size="small"
                    startIcon={<Icon name="Upload" />}
                    onClick={() =>
                      // @ts-ignore
                      document.getElementById('image-input').click()
                    }
                  >
                    {!selectedImage
                      ? t('online_food_order_settings.select_image')
                      : t('online_food_order_settings.change_image')}
                  </Button>
                  {selectedImage && (
                    <IconButton
                      color="error"
                      onMouseDown={e => e.stopPropagation()}
                      onClick={() => setDeleteConfirmation(true)}
                    >
                      <Icon
                        sx={{ width: '30px', height: '30px' }}
                        name="Delete"
                      />
                    </IconButton>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="image-input"
                    onChange={handleFileChange}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="domain_name"
            control={control}
            render={({ field, fieldState }) => (
              <FormInput
                // sx={styles.field}
                label={t('online_food_order_settings.domain_name')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="categories"
            control={control}
            render={({ field, fieldState }) => (
              <FormSelect
                label={t('online_food_order_settings.categories')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                multiple
                underline
                options={OptionsMemo(categories)}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="payment_types"
            control={control}
            render={({ field, fieldState }) => (
              <FormSelect
                label={t('online_food_order_settings.payment_types')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                multiple
                underline
                options={OptionsMemo(paymentTypes)}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="sales_channel_id"
            control={control}
            render={({ field, fieldState }) => (
              <FormSelect
                label={t('online_food_order_settings.sales_channel_id')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                underline
                options={OptionsMemoWithName(channelSales)}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="instagram_url"
            control={control}
            render={({ field, fieldState }) => (
              <FormInput
                // sx={styles.field}
                label={t('online_food_order_settings.instagram_url')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="facebook_url"
            control={control}
            render={({ field, fieldState }) => (
              <FormInput
                // sx={styles.field}
                label={t('online_food_order_settings.facebook_url')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="twitter_url"
            control={control}
            render={({ field, fieldState }) => (
              <FormInput
                // sx={styles.field}
                label={t('online_food_order_settings.twitter_url')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="address"
            control={control}
            render={({ field, fieldState }) => (
              <FormInput
                // sx={styles.field}
                label={t('online_food_order_settings.address')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="phone_number"
            control={control}
            render={({ field, fieldState }) => (
              <FormInput
                // sx={styles.field}
                label={t('online_food_order_settings.phone_number')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="right">
        <Button
          onClick={handleClick}
          sx={styles.submit}
          variant="contained"
          size="large"
        >
          {t('online_food_order_settings.submit')}
        </Button>
      </Grid>
      <ConfirmationModal
        title={t('deleteConfirmation.title')}
        titleIconName="QuestionMarkOutlined"
        description={t('deleteConfirmation.description')}
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        positiveAction={t('deleteConfirmation.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('deleteConfirmation.cancel')}
        onNegativeClick={() => setDeleteConfirmation(null)}
        loading={false}
      />
    </Box>
  );
}

export default OnlineFoodOrderSettings;
