/** Dependencies */
import React from 'react';

/** Styles */
import {
  alpha,
  AppBar,
  Box,
  InputBase,
  styled,
  Typography,
  Toolbar,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

/** Hooks */
import useDebounce from '../../hooks/useDebounce/useDebounce';

const SearchComponent = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'black',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'black',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

interface Props {
  placeholder: string;
  searchValue: { inputValue: string; isActive: boolean | null };
  setSearchValue: React.Dispatch<
    React.SetStateAction<{ inputValue: string; isActive: boolean | null }>
  >;
  setDebouncedSearch: React.Dispatch<
    React.SetStateAction<{ inputValue: string; isActive: boolean }>
  >;
}

function SearchBar({
  placeholder,
  searchValue,
  setSearchValue,
  setDebouncedSearch,
}: Props) {
  setDebouncedSearch(useDebounce(searchValue, 1000));

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSearchValue({
      ...searchValue,
      inputValue: event.target.value,
    });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'white',
          marginBottom: '25px',
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'block' } }}
          >
            <SearchComponent>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder={placeholder}
                fullWidth
                inputProps={{ 'aria-label': 'search' }}
                value={searchValue.inputValue}
                onChange={event => handleChange(event)}
              />
            </SearchComponent>
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default SearchBar;
