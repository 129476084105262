import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import arraySwap from '../../../../helpers/arraySwap';
import { getCategoryListThunk } from './thunks';

import { InitialState, Category } from './types';

const initialState: InitialState = { loading: false, categories: [] };

const categoriesSlice = createSlice({
  name: 'categoriesSlice',
  initialState,
  reducers: {
    addCategorySlice: (state, action: PayloadAction<Category>) => ({
      ...state,
      categories: [...state.categories, action.payload],
    }),
    updateCategorySlice: (state, action: PayloadAction<Category>) => ({
      ...state,
      categories: state.categories.map(item =>
        item.id === action.payload.id ? action.payload : item,
      ),
    }),
    removeCategorySlice: (
      state,
      action: PayloadAction<Pick<Category, 'id'>>,
    ) => ({
      ...state,
      categories: state.categories.filter(
        item => item.id !== action.payload.id,
      ),
    }),
    moveUp: (state, action: PayloadAction<Pick<Category, 'id'>>) => {
      const targetIndex = state.categories.findIndex(
        item => item.id === action.payload.id,
      );
      return {
        ...state,
        categories: arraySwap(state.categories, targetIndex, targetIndex - 1),
      };
    },
    moveDown: (state, action: PayloadAction<Pick<Category, 'id'>>) => {
      const targetIndex = state.categories.findIndex(
        item => item.id === action.payload.id,
      );
      return {
        ...state,
        categories: arraySwap(state.categories, targetIndex, targetIndex + 1),
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getCategoryListThunk.pending, state => ({
        ...state,
        loading: true,
      }))
      .addCase(getCategoryListThunk.fulfilled, (state, action) => ({
        ...state,
        categories: action.payload,
        loading: false,
      }))
      .addCase(getCategoryListThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }));
  },
});

export const {
  addCategorySlice,
  updateCategorySlice,
  removeCategorySlice,
  moveUp,
  moveDown,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
