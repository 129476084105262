import api from '../../api';
import { ApiSuccessResponse } from '../../types';
import {
  CreateResponse,
  ListResponse,
  ViewResponse,
  UpdateResponse,
  DeleteResponse,
} from './types';

const PAGING_LIMIT = 100;

type ListRes = ApiSuccessResponse<ListResponse>;
const list = (section_id: string) =>
  api.get<ListRes>(`/tables`, {
    params: {
      per_page: PAGING_LIMIT,
      filter: {
        section_id,
        hasOrderTransaction: true,
      },
    },
  });

type ViewRes = ApiSuccessResponse<ViewResponse>;
const view = (id: string) => api.get<ViewRes>(`/tables/${id}`);

type CreatRes = ApiSuccessResponse<CreateResponse>;
const createCollective = (name: string, section_id: string, count: number) =>
  api.post<CreatRes>('/tables/bulk-store', { name, section_id, count });

const create = (name: string, section_id: string) =>
  api.post<CreatRes>('/tables', { name, section_id });

type UpdateRes = ApiSuccessResponse<UpdateResponse>;
const update = (id: string, name: string, section_id: string) =>
  api.put<UpdateRes>(`/tables/${id}`, { name, section_id });

type DeleteRes = ApiSuccessResponse<DeleteResponse>;
const remove = (id: string) => api.delete<DeleteRes>(`/tables/${id}`);

const moveUp = (id: string) => api.post<void>(`/tables/${id}/move-sequence-up`);

const moveDown = (id: string) =>
  api.post<void>(`/tables/${id}/move-sequence-down`);

export default {
  list,
  view,
  create,
  createCollective,
  update,
  remove,
  moveUp,
  moveDown,
};
