import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import FormInput from '../../../../components/FormInput';
import Modal from '../../../../components/Modal';
import useSettings from '../../../../hooks/useSettings';
import { isApiError } from '../../../../services/api/types';
import { IdAndName } from '../../../../models/General';

interface Props {
  open: boolean;
  onClose: () => void;
  item?: IdAndName | null;
}

function AddSalesChannelModal({ open, onClose, item }: Props) {
  const { t } = useTranslation('table');
  const { addChannelSales, updateChannelSales, fetchChannelSales } =
    useSettings();
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleClose = () => {
    setName('');
    onClose();
  };

  const handleConfirm = async () => {
    if (!name) {
      setIsSubmitted(true);
    } else {
      setLoading(true);
      try {
        if (item) {
          await updateChannelSales(item.id, name);
        } else {
          await addChannelSales(name);
        }
        enqueueSnackbar(t('common:success' as any), {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setName('');
        setIsSubmitted(false);
        fetchChannelSales();
        onClose();
      } catch (err) {
        enqueueSnackbar(
          isApiError(err) ? err.message : t('common:unknownError' as any),
          {
            variant: 'error',
            autoHideDuration: 3000,
          },
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const invalid = isSubmitted && !name;

  useEffect(() => {
    if (open && item) {
      setName(item.name);
    }
  }, [open]);

  return (
    <Modal
      title={
        item
          ? t('section.add_modal.salesChannelEditTitle')
          : t('section.add_modal.salesChannelTitle')
      }
      titleIconName={item ? 'Edit' : 'Add'}
      open={open}
      onClose={handleClose}
      positiveAction={t('section.add_modal.confirm')}
      negativeAction={t('section.add_modal.cancel')}
      onPositiveClick={handleConfirm}
      onNegativeClick={handleClose}
      loading={loading}
    >
      <FormInput
        label={t('section.add_modal.form.channel.label')}
        error={invalid}
        value={name}
        helperText={invalid && t('section.add_modal.form.channel.required')}
        onChange={event => setName(event.target.value)}
      />
    </Modal>
  );
}

export default AddSalesChannelModal;
