import axios from 'axios';
import * as Qs from 'qs';
import i18n from '../i18n';
import { AccessTokenStorage, registrationStepStorage } from '../tokenStorage';
import { ApiError, isApiErrorResponse } from './types';

const UNAUTHORIZED = 401;

const TIMEOUT = 20 * 1000;
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: TIMEOUT,
  paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'brackets' }),
});

api.interceptors.request.use(
  request => {
    const accessToken = AccessTokenStorage.getToken();
    const { language } = i18n;
    if (request.headers && language) {
      request.headers['ACCEPT-LANGUAGE'] = language;
    }

    if (request.headers && accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      delete request.headers?.Authorization;
    }

    return request;
  },

  err => Promise.reject(err),
);

api.interceptors.response.use(
  res => res,
  (err: any) => {
    if (err.response.status === UNAUTHORIZED) {
      const { url } = err.response.config;
      if (url === '/me' || url === '/logout') {
        AccessTokenStorage.removeToken();
        registrationStepStorage.removeToken();
        window.location.reload();
      }
    }

    const body = err?.response?.data;
    if (body && isApiErrorResponse(err.response)) {
      const apiError = new ApiError(body);

      return Promise.reject(apiError);
    }

    return Promise.reject(err);
  },
);

export default api;
