/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import {
  Avatar,
  Badge,
  Menu,
  MenuItem,
  Divider,
  IconButton,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

/** Hooks */
import useProfile from '../../hooks/useProfile';
import { expiredAccountStorage } from '../../services/tokenStorage';
import Icon from '../Icon';

export default function HelpCenter() {
  const { setLanguage, meProfile } = useProfile();
  const { t, i18n } = useTranslation('layout');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const cdnTrIcon = 'https://cdn-icons-png.flaticon.com/512/197/197518.png';
  const cdnEnIcon = 'https://cdn-icons-png.flaticon.com/512/197/197484.png';

  const token = expiredAccountStorage.getToken();

  useEffect(() => {
    if (meProfile) {
      i18n.changeLanguage(meProfile?.language);
    }
  }, [meProfile]);

  return (
    <>
      <IconButton
        size="large"
        aria-label="account"
        sx={{ color: grey[700], borderRadius: 0 }}
        onClick={handleClick}
        disabled={Boolean(token)}
      >
        <Badge>
          <Icon name="SupportAgent" />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <a
          target="_blank"
          href="tel:+905446220125"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'red',
            fontSize: '14px',
            fontWeight: '500',
            marginLeft: '3px',
            textDecoration: 'none',
            margin: '10px',
          }}
          rel="noreferrer"
        >
          <Icon name="PhoneInTalk" color="error" sx={{ mr: 1 }} />
          +90 (544) 622 01 25
        </a>
        <Divider />
        <a
          target="_blank"
          href="https://api.whatsapp.com/send/?phone=905446220125&text=Merhaba,%20deste%C4%9Fe%20ihtiyac%C4%B1m%20var.&type=phone_number&app_absent=0"
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'start',
            color: 'green',
            fontSize: '14px',
            fontWeight: '500',
            marginLeft: '3px',
            textDecoration: 'none',
            margin: '10px',
          }}
          rel="noreferrer"
        >
          <Icon name="WhatsApp" color="success" sx={{ mr: 1 }} />
          Kitchrest
        </a>
      </Menu>
    </>
  );
}
