/** Dependencies */
import React from 'react';

/** Styles */
import { Divider, Grid, Stack } from '@mui/material';
import styles from '../styles';

/** Components */
import OrderItemCardContent from './OrderıItemCardContent/OrderItemCardContent';
import OrderItemCardContentHeader from './OrderItemCardHeader/OrderItemCardHeader';

interface Props {
  orderItemGroup: any;
  handleOrderItemUpdate: (status: number, item_id: string) => void;
  handleOrderItemGroupUpdate: (status: number, checkNumber: string) => void;
  handleItemGroupsChangeState: (checkNumber: string) => void;
}

function OrderItemCard({
  orderItemGroup,
  handleOrderItemUpdate,
  handleOrderItemGroupUpdate,
  handleItemGroupsChangeState,
}: Props) {
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={6}
      lg={4}
      key={orderItemGroup.id}
      sx={styles.cardContainer}
    >
      <Stack sx={styles.cardShadow}>
        <OrderItemCardContentHeader
          orderItemGroup={orderItemGroup}
          handleOrderItemGroupUpdate={handleOrderItemGroupUpdate}
        />
        <Divider />
        <OrderItemCardContent
          orderItemGroup={orderItemGroup}
          handleOrderItemUpdate={handleOrderItemUpdate}
          handleItemGroupsChangeState={handleItemGroupsChangeState}
        />
      </Stack>
    </Grid>
  );
}

export default OrderItemCard;
