import React from 'react';
import { Link as RoutLink } from 'react-router-dom';
import Link, { LinkProps, LinkTypeMap } from '@mui/material/Link';

function RouterLink<
  D extends React.ElementType = LinkTypeMap['defaultComponent'],
  P = {},
>(props: LinkProps<D, P>) {
  return <Link {...props} component={RoutLink} />;
}

export default RouterLink;
