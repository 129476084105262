import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginFormValues } from '../../../pages/Login/types';
import { RegisterFormValues } from '../../../pages/Register/types';
import api from '../../../services/api';
import {
  AccessTokenStorage,
  BranchIdStorage,
  registrationStepStorage,
} from '../../../services/tokenStorage';
import { hideBackDrop, showBackDrop } from '../backdropSlice';
import {
  RegisterSecondStepParams,
  RegisterThirdStepParams,
} from '../../../services/api/auth/types';

export const loginThunk = createAsyncThunk(
  'auth/login',
  async (params: LoginFormValues, { rejectWithValue }) => {
    try {
      const response = await api.auth.login(params);
      const result = response.data.data;
      AccessTokenStorage.setToken(result.accessToken);
      registrationStepStorage.setToken(
        (result.registrationStep + 1).toString(),
      );

      BranchIdStorage.removeToken();
      return result;
    } catch (err) {
      return rejectWithValue(err); // this keeps error instance as api error
    }
  },
);

export const loginByPinThunk = createAsyncThunk(
  'auth/loginByPin',
  async (pin: string, { rejectWithValue }) => {
    try {
      const response = await api.auth.loginByPin({
        pin,
        branch_id: BranchIdStorage.getToken() || '',
      });
      const result = response.data.data;

      AccessTokenStorage.setToken(result.accessToken);
      registrationStepStorage.setToken(
        (result.registrationStep + 1).toString(),
      );
      BranchIdStorage.removeToken();

      return result;
    } catch (err) {
      return rejectWithValue(err); // this keeps error instance as api error
    }
  },
);

export const logoutThunk = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(showBackDrop({ fullPage: true }));
      const response = await api.auth.logout();
      const result = response.data.data;

      AccessTokenStorage.removeToken();
      registrationStepStorage.removeToken();
      BranchIdStorage.removeToken();

      return result;
    } catch (err) {
      return rejectWithValue(err);
    } finally {
      dispatch(hideBackDrop());
    }
  },
);

export const logoutWithPinThunk = createAsyncThunk(
  'auth/logoutPin',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(showBackDrop({ fullPage: true }));
      const response = await api.auth.logout();
      const result = response.data.data;

      AccessTokenStorage.removeToken();
      registrationStepStorage.removeToken();

      return result;
    } catch (err) {
      return rejectWithValue(err);
    } finally {
      dispatch(hideBackDrop());
    }
  },
);

export const meThunk = createAsyncThunk(
  'auth/me',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(showBackDrop({ fullPage: true }));
      const response = await api.auth.me();
      const result = response.data.data;
      return result;
    } catch (err) {
      return rejectWithValue(err);
    } finally {
      dispatch(hideBackDrop());
    }
  },
);

export const meWithPaymentTypesThunk = createAsyncThunk(
  'auth/meWithPaymentTypes',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(showBackDrop({ fullPage: true }));
      const response = await api.auth.meWithPaymentTypes();
      const result = response.data.data;
      return result;
    } catch (err) {
      return rejectWithValue(err);
    } finally {
      dispatch(hideBackDrop());
    }
  },
);

export const registerFirstStepThunk = createAsyncThunk(
  'auth/register/first',
  async (params: RegisterFormValues, { rejectWithValue }) => {
    try {
      const response = await api.auth.registerFirstStep(params);
      const result = response.data.data;
      AccessTokenStorage.setToken(result.accessToken);
      registrationStepStorage.setToken('1');
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const registerSecondStepThunk = createAsyncThunk(
  'auth/register/second',
  async (params: RegisterSecondStepParams, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.auth.registerSecondStep(params);
      const result = response.data.data;
      registrationStepStorage.setToken(result.registrationStep.toString());
      await dispatch(meWithPaymentTypesThunk());
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const registerThirdStepThunk = createAsyncThunk(
  'auth/register/third',
  async (params: RegisterThirdStepParams, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.auth.registerThirdStep(params);
      const result = response.data.data;

      registrationStepStorage.setToken(result.registrationStep.toString());
      // await dispatch(meWithPaymentTypesThunk());
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const verifyOtpThunk = createAsyncThunk(
  'auth/verify-otp',
  async (code: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.auth.verifyOtp(code);
      const result = response.data;
      registrationStepStorage.setToken('4');
      await dispatch(meWithPaymentTypesThunk());

      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const resendOtpThunk = createAsyncThunk(
  'auth/resend-otp',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.auth.resendOtp();
      const result = response.data;
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
