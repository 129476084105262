import { Box, FormControlLabel, Switch, SwitchProps } from '@mui/material';
import React, { forwardRef } from 'react';

interface Props extends SwitchProps {
  label?: string;
  error?: boolean;
}

function FormSwitch(
  { label, error, ...props }: Props,
  ref: SwitchProps['inputRef'],
) {
  return (
    <Box>
      <FormControlLabel
        sx={{ color: error ? 'error.main' : undefined }}
        control={
          <Switch
            inputRef={ref}
            sx={{ color: error ? 'error.main' : undefined }}
            color="success"
            {...props}
            checked={!!props.value}
          />
        }
        label={label || ''}
      />
    </Box>
  );
}

export default forwardRef(FormSwitch);
