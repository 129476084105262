import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from '@mui/material';
import React from 'react';
import Icon from '../Icon';

interface Props {
  text: string;
  disableEdit?: boolean;
  disableRemove?: boolean;
  disableUp?: boolean;
  disableDown?: boolean;
  onEdit?: () => void;
  onRemove?: () => void;
  onUp?: () => void;
  onDown?: () => void;
  loading?: boolean;
  sortButtons?: boolean;
  removeButtons?: boolean;
  editButtons?: boolean;
  channel?: string;
}
function OrderedListItem({
  text,
  disableEdit,
  disableRemove,
  disableUp,
  disableDown,
  onEdit,
  onRemove,
  onUp,
  onDown,
  loading,
  sortButtons = true,
  removeButtons = true,
  editButtons = true,
  channel,
}: Props) {
  return (
    <ListItem
      disabled={loading}
      secondaryAction={
        <>
          {editButtons && (
            <IconButton
              sx={{ mr: 0.3 }}
              edge="end"
              aria-label="edit"
              color="primary"
              onClick={onEdit}
              disabled={disableEdit}
            >
              <Icon name="Edit" />
            </IconButton>
          )}
          {removeButtons && (
            <IconButton
              edge="end"
              aria-label="Remove"
              color="error"
              onClick={onRemove}
              disabled={disableRemove}
            >
              <Icon name="Delete" />
            </IconButton>
          )}
        </>
      }
    >
      {sortButtons ? (
        <Stack direction="column" sx={{ mr: 0.3 }} spacing={0.3}>
          <ListItemButton onClick={onUp} disabled={disableUp}>
            <Icon name="KeyboardArrowUp" />
          </ListItemButton>
          <ListItemButton onClick={onDown} disabled={disableDown}>
            <Icon name="KeyboardArrowDown" />
          </ListItemButton>
        </Stack>
      ) : null}
      <ListItemText sx={{ maxWidth: '200px' }} primary={text} />
      {channel && <ListItemText primary={channel} />}
    </ListItem>
  );
}

export default OrderedListItem;
