/** Dependencies */
import { createSlice } from '@reduxjs/toolkit';
import { getCustomerAddressListThunk } from './thunks';

/** Types */
import { InitialState } from './types';

const initialState: InitialState = {
  loading: false,
  customerAddresses: [],
  customerAddress: null,
};

const customerAddressSlice = createSlice({
  name: 'customerAddressSlice',
  initialState,
  reducers: {
    addCustomerAddressSlice: (state, action) => ({
      ...state,
      customerAddresses: [...state.customerAddresses, action.payload],
    }),
    removeCustomerAddressSlice: (state, action) => ({
      ...state,
      customerAddresses: state.customerAddresses.filter(
        item => item.id !== action.payload.id,
      ),
    }),
    setDefaultCustomerAddressSlice: (state, action) => ({
      ...state,
      customerAddresses: action.payload,
    }),
  },
  extraReducers: builder => {
    builder
      .addCase(getCustomerAddressListThunk.pending, state => ({
        ...state,
        loading: true,
      }))
      .addCase(getCustomerAddressListThunk.fulfilled, (state, action) => ({
        ...state,
        customerAddresses: action.payload,
        loading: false,
      }))
      .addCase(getCustomerAddressListThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }));
  },
});

export const {
  addCustomerAddressSlice,
  removeCustomerAddressSlice,
  setDefaultCustomerAddressSlice,
} = customerAddressSlice.actions;

export default customerAddressSlice.reducer;
