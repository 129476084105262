/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MuiColorInput } from 'mui-color-input';
import { useSnackbar } from 'notistack';

/** Styles */
import {
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';

/** Components */
import Button from '../../../components/Button';
import FormSwitch from '../../../components/FormSwitch';
import { QrDataType, QrMenuDataType } from '../types';
import Icon from '../../../components/Icon';

interface Props {
  qrData: QrDataType;
  setQrData: React.Dispatch<React.SetStateAction<QrDataType>>;
  qrMenuData: QrMenuDataType;
  setQrMenuData: React.Dispatch<React.SetStateAction<QrMenuDataType>>;
  selectedImage: any;
  loadingImage: any;
  setOpenImage: (value: ((prevState: boolean) => boolean) | boolean) => void;
  handleFileChange: (e: any) => Promise<void>;
  selectedImageMenu: any;
  loadingImageMenu: any;
  setOpenImageMenu: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
  handleFileChangeMenu: (e: any) => Promise<void>;
  handleSubmit: () => void;
  setDeleteConfirmation: (
    value: ((prevState: boolean | null) => boolean | null) | boolean | null,
  ) => void;
  setImageType: (value: ((prevState: string) => string) | string) => void;
}

function QrMenu({
  qrData,
  qrMenuData,
  setQrMenuData,
  setQrData,
  selectedImage,
  setOpenImage,
  loadingImage,
  handleFileChange,
  selectedImageMenu,
  loadingImageMenu,
  setOpenImageMenu,
  handleFileChangeMenu,
  handleSubmit,
  setDeleteConfirmation,
  setImageType,
}: Props) {
  const { t } = useTranslation('qrMenu');
  const { enqueueSnackbar } = useSnackbar();

  function copyTextToClipboard(text: string) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    enqueueSnackbar(t('copied'), {
      variant: 'success',
    });
  }

  return (
    <Grid item md={8} sm={12}>
      <Grid item xs={12} sx={{ p: 1 }}>
        <Grid
          container
          sx={{
            backgroundColor: '#80808021',
            p: 2,
            borderRadius: '10px 10px 0 0',
          }}
        >
          <Typography variant="subtitle2">{t('qrShow')}</Typography>
        </Grid>
        <Grid
          sx={{
            borderRadius: '10px',
            py: 5,
            px: 10,
            backgroundColor: 'white',
          }}
          container
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={4}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              height: '150px',
            }}
          >
            <img src={`data:image/png;base64, ${qrData.qr_code}`} alt="" />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={8}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle1">{t('qrCodeLogo')}</Typography>
                <Grid
                  container
                  justifyContent="space-between"
                  sx={{
                    border: '1px solid #80808061',
                    marginBottom: 1,
                    p: 1,
                    borderRadius: '5px',
                  }}
                >
                  <Grid item>
                    {selectedImage || loadingImage ? (
                      loadingImage ? (
                        <Grid style={{ width: '100px', height: '100px' }}>
                          <CircularProgress />
                        </Grid>
                      ) : (
                        <Grid onClick={() => setOpenImage(true)}>
                          <img
                            style={{
                              width: '100px',
                              height: '100px',
                              cursor: 'pointer',
                            }}
                            src={selectedImage || qrData.qr_menu_logo}
                            alt="Uploaded"
                          />
                        </Grid>
                      )
                    ) : (
                      t('add_image')
                    )}
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      size="small"
                      startIcon={<Icon name="Upload" />}
                      onClick={() =>
                        // @ts-ignore
                        document.getElementById('image-input').click()
                      }
                    >
                      {!selectedImage ? t('select_image') : t('change_image')}
                    </Button>
                    {selectedImage && (
                      <IconButton
                        color="error"
                        onMouseDown={e => e.stopPropagation()}
                        onClick={() => {
                          setImageType('qr-code-icon');
                          setDeleteConfirmation(true);
                        }}
                      >
                        <Icon
                          sx={{ width: '30px', height: '30px' }}
                          name="Delete"
                        />
                      </IconButton>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="image-input"
                      onChange={handleFileChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                sx={{
                  overflow: 'hidden',
                }}
                xs={12}
              >
                <Typography variant="subtitle1">{t('qrCodeUrl')}</Typography>
                <Grid
                  container
                  justifyContent="space-between"
                  sx={{
                    border: '1px solid #80808061',
                    marginBottom: 1,
                    p: 1,
                    borderRadius: '5px',
                  }}
                >
                  <Grid item>
                    <Typography sx={{ maxWidth: '100%', fontSize: '14px' }}>
                      {qrData.url}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Icon
                      name="ContentCopy"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        copyTextToClipboard(qrData.url);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sx={{
          p: 1,
        }}
      >
        <Grid
          sx={{
            borderRadius: '10px',
            backgroundColor: 'white',
          }}
          container
        >
          <Grid
            container
            sx={{
              backgroundColor: '#80808021',
              p: 2,
              borderRadius: '10px 10px 0 0',
            }}
          >
            <Typography variant="subtitle2">{t('qrMenu')}</Typography>
          </Grid>
          <Grid
            container
            sx={{
              py: 5,
              px: 10,
            }}
          >
            <Grid item xs={12}>
              <Typography variant="subtitle1">{t('qrMenuLogo')}</Typography>
              <Grid
                container
                justifyContent="space-between"
                sx={{
                  border: '1px solid #80808061',
                  marginBottom: 1,
                  p: 1,
                  borderRadius: '5px',
                }}
              >
                <Grid item>
                  {selectedImageMenu || loadingImageMenu ? (
                    loadingImageMenu ? (
                      <Grid style={{ width: '100px', height: '100px' }}>
                        <CircularProgress />
                      </Grid>
                    ) : (
                      <Grid onClick={() => setOpenImageMenu(true)}>
                        <img
                          style={{
                            width: '100px',
                            height: '100px',
                            cursor: 'pointer',
                          }}
                          src={selectedImageMenu || qrMenuData.qr_menu_logo}
                          alt="Uploaded"
                        />
                      </Grid>
                    )
                  ) : (
                    t('add_image')
                  )}
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    size="small"
                    startIcon={<Icon name="Upload" />}
                    onClick={() =>
                      // @ts-ignore
                      document.getElementById('image-input-menu').click()
                    }
                  >
                    {!selectedImageMenu ? t('select_image') : t('change_image')}
                  </Button>
                  {selectedImageMenu && (
                    <IconButton
                      color="error"
                      onMouseDown={e => e.stopPropagation()}
                      onClick={() => {
                        setImageType('qr-menu-logo');
                        setDeleteConfirmation(true);
                      }}
                    >
                      <Icon
                        sx={{ width: '30px', height: '30px' }}
                        name="Delete"
                      />
                    </IconButton>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="image-input-menu"
                    onChange={handleFileChangeMenu}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: '8px' }}>
              <Typography variant="subtitle1">{t('color')}</Typography>
              <MuiColorInput
                sx={{ width: '100%' }}
                value={qrMenuData.color}
                format="hex"
                onChange={e =>
                  setQrMenuData({
                    ...qrMenuData,
                    color: e,
                  })
                }
              />
            </Grid>
            <Grid xs={12}>
              <FormSwitch
                value={qrMenuData.is_active}
                onChange={e =>
                  setQrMenuData({
                    ...qrMenuData,
                    is_active: !qrMenuData.is_active,
                  })
                }
                label={t('is_active')}
              />
            </Grid>
            <Grid xs={12}>
              <FormSwitch
                value={qrMenuData.use_category_image}
                onChange={e =>
                  setQrMenuData({
                    ...qrMenuData,
                    use_category_image: !qrMenuData.use_category_image,
                  })
                }
                label={t('use_category_image')}
              />
            </Grid>
            <Grid xs={12}>
              <FormSwitch
                value={qrMenuData.use_product_image}
                onChange={e =>
                  setQrMenuData({
                    ...qrMenuData,
                    use_product_image: !qrMenuData.use_product_image,
                  })
                }
                label={t('use_product_image')}
              />
            </Grid>
            <Grid xs={12} display="flex">
              <FormSwitch
                value={qrMenuData.waiter_call}
                onChange={e =>
                  setQrMenuData({
                    ...qrMenuData,
                    waiter_call: !qrMenuData.waiter_call,
                  })
                }
                label={t('waiter_call')}
                disabled
              />
              <Tooltip
                arrow
                TransitionComponent={Fade}
                title={t('comingSoon')}
                sx={{ cursor: 'pointer' }}
              >
                <Grid>
                  <Icon name="Info" />
                </Grid>
              </Tooltip>
            </Grid>
            <Grid xs={12} display="flex">
              <FormSwitch
                value={qrMenuData.check_please}
                onChange={e =>
                  setQrMenuData({
                    ...qrMenuData,
                    check_please: !qrMenuData.check_please,
                  })
                }
                label={t('check_please')}
                disabled
              />
              <Tooltip
                arrow
                TransitionComponent={Fade}
                title={t('comingSoon')}
                sx={{ cursor: 'pointer' }}
              >
                <Grid>
                  <Icon name="Info" />
                </Grid>
              </Tooltip>
            </Grid>
            <Grid xs={12} display="flex">
              <FormSwitch
                value={qrMenuData.is_online_order}
                onChange={e =>
                  setQrMenuData({
                    ...qrMenuData,
                    is_online_order: !qrMenuData.is_online_order,
                  })
                }
                label={t('is_online_order')}
                disabled
              />
              <Tooltip
                arrow
                TransitionComponent={Fade}
                title={t('comingSoon')}
                sx={{ cursor: 'pointer' }}
              >
                <Grid>
                  <Icon name="Info" />
                </Grid>
              </Tooltip>
            </Grid>
            <Grid xs={12} display="flex">
              <FormSwitch
                value={qrMenuData.is_online_checkout}
                onChange={e =>
                  setQrMenuData({
                    ...qrMenuData,
                    is_online_checkout: !qrMenuData.is_online_checkout,
                  })
                }
                label={t('is_online_checkout')}
                disabled
              />
              <Tooltip
                arrow
                TransitionComponent={Fade}
                title={t('comingSoon')}
                sx={{ cursor: 'pointer' }}
              >
                <Grid>
                  <Icon name="Info" />
                </Grid>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid p={1} container justifyContent="right">
        <Button onClick={handleSubmit} variant="contained" size="large">
          {t('actions.update')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default QrMenu;
