/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';

/** Styles */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from '@mui/material';

/** Components */
import LoadingState from '../../../components/LoadingState';
import EmptyState from '../../../components/EmptyState';

/** Hooks */
import usePosOrder from '../../../hooks/usePosOrder';
import TableComponent from '../../../components/TableComponent';

interface Props {
  selectActive: number;
}

export default function CustomerTransactionTable({ selectActive }: Props) {
  const { t } = useTranslation('customerAddress');

  const {
    getOpenAccountTransactionsDetails,
    openAccountDetails,
    openAccountMeta,
    customerDetailsLoading,
  } = usePosOrder();
  const { customerId } = useParams();

  const [page, setPage] = useState(1);

  const handleChange = async (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
    if (customerId) {
      await getOpenAccountTransactionsDetails(customerId, value);
    }
  };

  useEffect(() => {
    if (customerId) {
      if (selectActive === 1 && !openAccountDetails?.length) {
        getOpenAccountTransactionsDetails(customerId, 1);
      }
    }
  }, [selectActive]);

  const tableHead = [
    t('customerAddress.addCustomerOrders.table'),
    t('customerAddress.addCustomerOrders.amount'),
    t('customerAddress.addCustomerOrders.created_by'),
    t('customerAddress.addCustomerOrders.created_at'),
  ];

  const tableBody = openAccountDetails?.map((row: any) => (
    <TableRow key={row.id}>
      <TableCell>{row?.order_transaction?.table?.name || '-'}</TableCell>

      <TableCell>{row?.amount?.formatted}</TableCell>
      <TableCell>{`${row?.created_by?.full_name}`}</TableCell>
      <TableCell>
        {format(new Date(row?.created_at), 'dd.MM.yyyy HH:ss')}
      </TableCell>
    </TableRow>
  ));

  return (
    <div>
      {customerDetailsLoading ? (
        <LoadingState />
      ) : !openAccountDetails?.length ? (
        <EmptyState message={t('customerAddress.addCustomerOrders.empty')} />
      ) : (
        <TableComponent
          tableHead={tableHead}
          tableBody={tableBody}
          tableHeadStyle={{ width: '100%', overflowX: 'auto' }}
          showPagination
          page={page}
          tableStyle={{ minWidth: 650 }}
          handleChange={handleChange}
          // @ts-ignore
          count={Math.ceil(openAccountMeta.total / openAccountMeta.per_page)}
        />
      )}
    </div>
  );
}
