import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import IconButtonWithTooltip from '../../../../../components/IconButtonWithTooltip';
import LoadingState from '../../../../../components/LoadingState';
import getActiveLanguage from '../../../../../helpers/getActiveLanguage';
import useProducts from '../../../../../hooks/useProducts';
import { PropertyItemWithActive } from '../../../../../services/api/definitions/propertyGroups/types';
import { useAppSelector } from '../../../../../store';
import { PropertiesOrderModal } from './PropertiesOrderModal';
import PropertyItemEditModal from './PropertyItemEditModal';
import PropertyItemList from './PropertyItemList';
import errorReporting from '../../../../../helpers/errorReporting';

interface Props {
  loading: boolean;
  productId?: string;
}
function PropertyItemsContainer({ loading, productId }: Props) {
  const [orderModal, setOrderModal] = useState(false);
  const [groupIdToEdit, setGroupIdToEdit] = useState<string | null>(null);
  const [itemEditModal, setItemEditModal] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<PropertyItemWithActive | null>(
    null,
  );
  const [itemIdLoading, setItemIdLoading] = useState<string | null>(null);

  const ProductInProcess = useAppSelector(
    state => state.definitionsProducts.ProductInProcess,
  );

  const {
    productPropertyItemMoveUp,
    productPropertyItemMoveDown,
    productPropertyItemUpdate,
    loading: itemsLoading,
  } = useProducts();

  const modalGroupItems = () => {
    if (ProductInProcess?.property_groups) {
      const targetGroup = ProductInProcess.property_groups.find(
        group => group.id === groupIdToEdit,
      );
      if (targetGroup) {
        const targetItems = Object.values(targetGroup.items);
        return targetItems.map(item => ({
          ...item,
          name: `${getActiveLanguage(item.translations).name}  ${
            item.additional_price.formatted
          }`,
        }));
      }
      return null;
    }
    return null;
  };

  if (
    !ProductInProcess?.property_groups ||
    ProductInProcess?.property_groups.length < 1
  )
    return null;

  if (loading) return <LoadingState />;

  return (
    <Box sx={{ mt: 4 }}>
      {ProductInProcess.property_groups.map(group => (
        <Box key={group.id} sx={{ mb: 4 }}>
          <Stack sx={{ mb: 2 }} direction="row" alignItems="center">
            <Typography variant="h6">
              {getActiveLanguage(group.translations).name}
            </Typography>
            {/* Todo: remove Object.values when api fixed */}
            <IconButtonWithTooltip
              sx={{ ml: 2.5 }}
              size="small"
              iconName="Edit"
              tip="Edit"
              tipPlacement="right"
              color="primary"
              onClick={() => {
                setGroupIdToEdit(group.id);
                setOrderModal(true);
              }}
            />
          </Stack>
          <PropertyItemList
            list={Object.values(group.items)} // Todo: remove Object.values when api fixed
            loadingItemID={itemIdLoading}
            onItemEdit={item => {
              setItemToEdit(item);
              setItemEditModal(true);
            }}
            onItemClick={async item => {
              try {
                if (productId) {
                  setItemIdLoading(item.id);
                  await productPropertyItemUpdate({
                    productId,
                    propertyItemId: item.id,
                    price: parseFloat(item.additional_price.amount) / 100, // api handles float values
                    isActive: !item.is_active, // toggle active status
                  });
                  setItemIdLoading(null);
                }
              } catch (err) {
                setItemIdLoading(null);
                errorReporting(err);
              }
            }}
          />
        </Box>
      ))}

      <PropertiesOrderModal
        items={modalGroupItems()}
        open={orderModal}
        loading={itemsLoading}
        onClose={() => {
          setOrderModal(false);
          setGroupIdToEdit(null);
        }}
        onItemEdit={item => {
          setItemToEdit(item);
          setItemEditModal(true);
        }}
        onItemMoveUp={id => {
          if (productId) productPropertyItemMoveUp(productId, id);
        }}
        onItemMoveDown={id => {
          if (productId) productPropertyItemMoveDown(productId, id);
        }}
      />

      {itemToEdit && (
        <PropertyItemEditModal
          open={itemEditModal}
          onClose={() => {
            setItemToEdit(null);
            setItemEditModal(false);
          }}
          initialValues={{
            price: parseFloat(itemToEdit.additional_price.amount) / 100,
            isActive: itemToEdit.is_active,
          }}
          productId={productId}
          propertyItemId={itemToEdit.id}
        />
      )}
    </Box>
  );
}

export default PropertyItemsContainer;
