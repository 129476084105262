import { SxProps, Theme } from '@mui/material';

const BORDER_RADIUS = 1;

const styles: Record<string, SxProps<Theme>> = {
  container: {
    height: 180,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    boxShadow: 2,
  },
  tableModalButtons: {
    height: '100px',
    width: '100%',
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
  },
  full: { backgroundColor: 'error.light' },
  fullPaid: {
    backgroundColor: '#66bb6a',
    '&:hover': {
      backgroundColor: '#66bb6a',
    },
  },
  disabled: {
    '&:hover': {
      backgroundColor: 'unset',
      cursor: 'default',
    },
  },
  rightTopCorner: { position: 'absolute', top: 6, right: 6 },
  leftTopCorner: { position: 'absolute', top: 6, left: 6, m: 1 },
  rightBottomCorner: { position: 'absolute', bottom: 6, right: 6 },
  leftBottomCorner: { position: 'absolute', bottom: 6, left: 6, m: 1 },
  button: {
    borderRadius: BORDER_RADIUS,
    '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
      borderRadius: BORDER_RADIUS,
    },
  },
  pulse: {
    background: 'black',
    borderRadius: '50%',
    margin: 10,
    height: 20,
    width: 20,
    boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
    transform: 'scale(1)',
    animation: '$pulse 2s infinite',
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.7)',
    },
    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)',
    },
    '100%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
    },
  },
};

export default styles;
