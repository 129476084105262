import React, { SVGProps } from 'react';

function MoneyIconSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      viewBox="0 -83 500.30001 500"
      width="30"
    >
      <path d="m230.199219 189.25c-9.214844-.015625-16.683594-7.484375-16.699219-16.699219 0-5.523437-4.476562-10-10-10s-10 4.476563-10 10c.019531 16.40625 10.917969 30.8125 26.699219 35.300781v8.097657c0 5.523437 4.476562 10 10 10 5.523437 0 10-4.476563 10-10v-8.097657c17.625-4.992187 28.867187-22.214843 26.347656-40.355468s-18.03125-31.648438-36.347656-31.644532c-9.222657 0-16.699219-7.476562-16.699219-16.703124 0-9.222657 7.476562-16.699219 16.699219-16.699219 9.222656 0 16.699219 7.476562 16.699219 16.699219 0 5.523437 4.480468 10 10 10 5.523437 0 10-4.476563 10-10-.019532-16.40625-10.914063-30.8125-26.699219-35.296876v-6c0-5.523437-4.476563-10-10-10-5.523438 0-10 4.476563-10 10v6c-17.621094 4.988282-28.867188 22.210938-26.347657 40.351563 2.519532 18.144531 18.03125 31.648437 36.347657 31.648437 9.222656 0 16.699219 7.476563 16.699219 16.699219 0 9.222657-7.476563 16.699219-16.699219 16.699219zm0 0" />
      <path d="m90.601562 253.449219h279.296876c2.753906 0 5.390624-1.117188 7.300781-3.097657l40.800781-43.101562c1.765625-1.859375 2.734375-4.335938 2.699219-6.898438v-106.800781c-.011719-2.527343-.976563-4.953125-2.699219-6.800781l-40.300781-43.101562c-1.886719-2.023438-4.527344-3.179688-7.300781-3.199219h-279.796876c-2.753906 0-5.390624 1.117187-7.300781 3.101562l-40.800781 43.097657c-1.765625 1.863281-2.734375 4.335937-2.699219 6.902343v106.800781c.011719 2.554688.972657 5.011719 2.699219 6.898438l40.800781 43.101562c1.898438 1.996094 4.542969 3.117188 7.300781 3.097657zm-30.902343-155.898438 35.101562-37.101562h271.199219l34.601562 37.101562v98.898438l-35.101562 37.101562h-270.601562l-35.097657-37.101562zm0 0" />
      <path d="m459.5 41.050781c-4.421875-23.65625-25.035156-40.828125-49.101562-40.902343h-360.398438c-27.609375.011718-49.9882812 22.390624-50 50v193.402343c.0351562 24.078125 17.222656 44.714844 40.898438 49.097657 4.421874 23.65625 25.035156 40.828124 49.101562 40.902343h360.300781c27.609375-.011719 49.988281-22.390625 50-50v-193.300781c.011719-24.082031-17.132812-44.757812-40.800781-49.199219zm-439.5 202.597657v-193.398438c.046875-16.546875 13.453125-29.953125 30-30h360.300781c16.546875.046875 29.949219 13.453125 30 30v193.398438c-.050781 16.550781-13.453125 29.953124-30 30h-360.300781c-16.546875-.046876-29.953125-13.449219-30-30zm460.398438 40c-.046876 16.550781-13.449219 29.953124-30 30h-360.398438c-12.707031-.03125-24.027344-8.03125-28.300781-20h348.601562c27.609375-.007813 49.988281-22.386719 50-50v-181.699219c11.984375 4.25 19.996094 15.585937 20 28.300781v193.398438zm0 0" />
    </svg>
  );
}

export default MoneyIconSvg;
