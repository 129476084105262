/* eslint-disable @typescript-eslint/naming-convention */
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import getActiveLanguage from '../../helpers/getActiveLanguage';
import {
  ProductCreateParams,
  ProductUpdateParams,
  UpdateProductIngredientItemParams,
  UpdateProductPropertyItemParams,
} from '../../services/api/definitions/products/types';
import { isApiError } from '../../services/api/types';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getProductListThunk,
  getProductThunk,
  updateProductIngredientGroupsThunk,
  updateProductPropertyGroupsThunk,
} from '../../store/slices/definitionsSlice/products';
import {
  addProductSlice,
  moveDownSlice,
  moveUpSlice,
  removeProductSlice,
  resetProductSlice,
  updateProductSlice,
} from '../../store/slices/definitionsSlice/products/productsSlice';
import { UnitUpdateThunkParams } from '../../store/slices/definitionsSlice/products/types';
import {
  ProductInProcessDetails,
  UpdateProductIngredientGroupsParams,
  UpdateProductPropertyGroupsParams,
} from './types';
import errorReporting from '../../helpers/errorReporting';
import api from '../../services/api';
import { ProductInfoFormValues } from '../../pages/definitions/Product/forms/InfoForm/types';

const useProducts = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [productInprogressLoading, setProductInprogressLoading] =
    useState(false);
  const [deleting, setDeleting] = useState(false);
  const [groupsLoading, setGroupsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const products = useAppSelector(state => state.definitionsProducts.list);
  const ProductInProcess = useAppSelector(
    state => state.definitionsProducts.ProductInProcess,
  );
  const productLoading = useAppSelector(
    state => state.definitionsProducts.loading,
  );

  const productError = useAppSelector(state => state.definitionsProducts.error);

  const fetchProducts = (
    category_id: string,
    is_parent?: boolean,
    is_pos?: boolean,
  ) =>
    dispatch(
      getProductListThunk({
        category_id,
        is_parent,
        is_pos,
      }),
    );

  const addProduct = async (params: ProductInfoFormValues) => {
    try {
      setLoading(true);
      const response = await api.definitions.products.create(params);
      const result = response.data.data;
      await dispatch(
        addProductSlice({
          ...result,
          units: [],
          ingredient_groups: [],
          property_groups: [],
        }),
      );
      return result.id;
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err; // pass the error to prevent going to next step
    } finally {
      setLoading(false);
    }
    return null;
  };

  const updateProduct = async (params: ProductUpdateParams) => {
    try {
      setLoading(true);
      const { productId, ...rest } = params;
      await api.definitions.products.update({ ...rest, productId });
      const response = await api.definitions.products.view(productId);
      const result = response.data.data;
      dispatch(updateProductSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err; // pass the error to prevent going to next step
    } finally {
      setLoading(false);
    }
  };

  /* eslint-disable consistent-return */
  const updateImage = async (id: string, type: string, image: any) => {
    try {
      if (Number(image?.size) > 2000000) {
        enqueueSnackbar(t('common:size_error' as any), {
          variant: 'warning',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        });
      } else {
        setLoading(true);
        const response = await api.definitions.products.updateImage(
          id,
          type,
          image,
        );
        return response.data.id;
      }
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err; // pass the error to prevent going to next step
    } finally {
      setLoading(false);
    }
  };

  const deleteImage = async (id: string, type: string) => {
    try {
      setLoading(true);
      await api.definitions.products.removeImage(id, type);
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err; // pass the error to prevent going to next step
    } finally {
      setLoading(false);
    }
  };

  const setProductGroup = async (params: {
    parent_id: string;
    child_ids: string[];
  }) => {
    try {
      setLoading(true);
      await api.definitions.products.setChildren(
        params.parent_id,
        params.child_ids,
      );
      navigate('/definitions/categories');
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err; // pass the error to prevent going to next step
    } finally {
      setLoading(false);
    }
  };

  const removeProduct = async (id: string) => {
    try {
      setDeleting(true);
      const response = await api.definitions.products.remove(id);
      const result = response.data.data;
      dispatch(removeProductSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err;
    } finally {
      setDeleting(false);
    }
  };

  // @ts-ignore
  const productInProcessDetails: ProductInProcessDetails | undefined =
    useMemo(() => {
      const product = ProductInProcess;
      if (product) {
        const {
          kitchen_group,
          category,
          translations,
          tax_rate,
          units,
          property_groups,
          ingredient_groups,
          menus,
          ...rest
        } = product;

        return {
          ...rest,
          kitchen_group_id: kitchen_group.id,
          category_id: category.id,
          name: translations.map(n => ({ lang: n.lang, text: n.name })),
          tax_rate,
          units: units.map(u => ({
            unitInnerId: u.id,
            unitId: u.unit_id,
            name: getActiveLanguage(u.translations).name,
            amount: parseFloat(u.amount.amount) / 100,
            cost: u.cost ? parseFloat(u.cost.amount) / 100 : 0,
            isDefault: u.is_default,
            isActive: u.is_active,
          })),
          property_groups: property_groups?.map(pg => ({
            id: pg.id,
            is_multiple: pg.is_multiple,
            items: pg.items,
            translations: pg.translations,
          })),
          ingredient_groups: ingredient_groups?.map(pg => ({
            id: pg.id,
            items: pg.items,
            translations: pg.translations,
          })),
          propertyGroupIds: property_groups?.map(pg => pg.id),
          ingredientGroupIds: ingredient_groups?.map(pg => pg.id),
          menus,
        };
      }

      return undefined;
    }, [ProductInProcess]);

  const setProductInProgress = async (id: string) => {
    try {
      setProductInprogressLoading(true);
      const response = await dispatch(getProductThunk(id));
      const result = unwrapResult(response);
      return result.id;
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
    } finally {
      setProductInprogressLoading(false);
    }
    return null;
  };

  const resetProductInProgress = () => dispatch(resetProductSlice());

  // property groups functions
  const updateProductPropertyGroups = async (
    params: UpdateProductPropertyGroupsParams,
  ) => {
    try {
      setGroupsLoading(true);
      const response = await dispatch(updateProductPropertyGroupsThunk(params));
      const result = unwrapResult(response);
      return result.id;
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
    } finally {
      setGroupsLoading(false);
    }
    return null;
  };

  const productPropertyItemMoveDown = async (productId: string, id: string) => {
    try {
      setLoading(true);
      await api.definitions.products.movePropertyItemDown(id);
      const response = await api.definitions.products.view(productId);
      const result = response.data.data;
      dispatch(updateProductSlice(result));
      return result.id;
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
    } finally {
      setLoading(false);
    }
    return null;
  };

  const productPropertyItemMoveUp = async (productId: string, id: string) => {
    try {
      setLoading(true);
      await api.definitions.products.movePropertyItemUp(id);
      const response = await api.definitions.products.view(productId);
      const result = response.data.data;
      dispatch(updateProductSlice(result));
      return result.id;
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
    } finally {
      setLoading(false);
    }
    return null;
  };

  const productUnitMoveDown = async (productId: string, id: string) => {
    try {
      setLoading(true);
      await api.definitions.products.moveUnitDown(id);
      const response = await api.definitions.products.view(productId);
      const result = response.data.data;
      dispatch(updateProductSlice(result));
      enqueueSnackbar(t('common:unitSuccess' as any), {
        variant: 'success',
        autoHideDuration: 3000,
      });
      return result.id;
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
    } finally {
      setLoading(false);
    }
    return null;
  };

  const productUnitMoveUp = async (productId: string, id: string) => {
    try {
      setLoading(true);
      await api.definitions.products.moveUnitUp(id);
      const response = await api.definitions.products.view(productId);
      const result = response.data.data;
      dispatch(updateProductSlice(result));
      enqueueSnackbar(t('common:unitSuccess' as any), {
        variant: 'success',
        autoHideDuration: 3000,
      });
      return result.id;
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
    } finally {
      setLoading(false);
    }
    return null;
  };

  const productPropertyItemUpdate = async (
    params: UpdateProductPropertyItemParams,
  ) => {
    try {
      setLoading(true);
      await api.definitions.products.updateProductPropertyItem(params);
      const response = await api.definitions.products.view(params.productId);
      const result = response.data.data;
      dispatch(updateProductSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err; // pass the error to prevent modal closing on fail
    } finally {
      setLoading(false);
    }
    return null;
  };

  // Ingredient groups functions
  const updateProductIngredientGroups = async (
    params: UpdateProductIngredientGroupsParams,
  ) => {
    try {
      setGroupsLoading(true);
      const response = await dispatch(
        updateProductIngredientGroupsThunk(params),
      );
      const result = unwrapResult(response);
      return result.id;
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
    } finally {
      setGroupsLoading(false);
    }
    return null;
  };

  const productIngredientItemMoveDown = async (
    productId: string,
    id: string,
  ) => {
    try {
      setLoading(true);
      await api.definitions.products.moveIngredientItemDown(id);
      const response = await api.definitions.products.view(productId);
      const result = response.data.data;
      dispatch(updateProductSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
    } finally {
      setLoading(false);
    }
    return null;
  };

  const productIngredientItemMoveUp = async (productId: string, id: string) => {
    try {
      setLoading(true);
      await api.definitions.products.moveIngredientItemUp(id);
      const response = await api.definitions.products.view(productId);
      const result = response.data.data;
      dispatch(updateProductSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
    } finally {
      setLoading(false);
    }
    return null;
  };

  const productIngredientItemUpdate = async (
    params: UpdateProductIngredientItemParams,
  ) => {
    try {
      setLoading(true);
      await api.definitions.products.updateProductIngredientItem(params);
      const response = await api.definitions.products.view(params.productId);
      const result = response.data.data;
      dispatch(updateProductSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err; // pass the error to prevent modal closing on fail
    } finally {
      setLoading(false);
    }
  };

  const productUnitUpdate = async (params: UnitUpdateThunkParams) => {
    try {
      setLoading(true);
      await api.definitions.products.updateUnit(params.productId, params.units);
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err; // pass the error to prevent modal closing on fail
    } finally {
      setLoading(false);
    }
  };
  const productMoveDown = async (id: string) => {
    try {
      await api.definitions.products.moveDown(id);
      dispatch(moveDownSlice({ id }));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const productMoveUp = async (id: string) => {
    try {
      await api.definitions.products.moveUp(id);
      dispatch(moveUpSlice({ id }));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  return {
    products,
    productLoading,
    productError,
    fetchProducts,
    addProduct,
    updateProduct,
    removeProduct,
    deleting,
    loading,
    groupsLoading,
    setProductInProgress,
    resetProductInProgress,
    productInprogressLoading,
    productInProcessDetails,
    updateProductPropertyGroups,
    productPropertyItemMoveUp,
    productPropertyItemMoveDown,
    productPropertyItemUpdate,
    updateProductIngredientGroups,
    productIngredientItemMoveUp,
    productIngredientItemMoveDown,
    productIngredientItemUpdate,
    productUnitUpdate,
    productUnitMoveUp,
    productUnitMoveDown,
    productMoveDown,
    productMoveUp,
    setProductGroup,
    updateImage,
    deleteImage,
  };
};
export default useProducts;
