/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

/** Components */
import UtilitiesBar, {
  Props as BarProps,
} from '../../../components/UtilitiesBar';
import PrintersTable from './PrintersTable';
import useSettings from '../../../hooks/useSettings';
import LoadingState from '../../../components/LoadingState';
import PrinterAddModal from './Modals/PrinterAddModal';
import ErrorState from '../../../components/ErrorState';
import useProfile from '../../../hooks/useProfile';
import arrayKeyHasPermission from '../../../helpers/arrayKeyHasPermission';

/** Styles */

const MENU: BarProps['menu'] = [
  {
    id: 0,
    text: 'actions.addPrinter',
    startIconName: 'Add',
    permissionKeys: ['create-printer'],
  },
];

function PrinterSettings() {
  const { t } = useTranslation('printerSettings');
  const { meProfile } = useProfile();
  const {
    printerSettingsError,
    printerSettingsLoading,
    printers,
    removePrinter,
    fetchPrinters,
  } = useSettings();

  const [printerAddModal, setPrinterAddModal] = useState(false);
  const [printerToEdit, setPrinterToEdit] = useState<any | null>(null);

  useEffect(() => {
    fetchPrinters({ page: 1 });
  }, []);

  if (!printers && printerSettingsError) {
    return (
      <ErrorState
        message={
          printerSettingsError
            ? printerSettingsError.message
            : t('common:unknownError' as any)
        }
      />
    );
  }

  const handleAction = (id: number) => {
    switch (id) {
      case 0:
        setPrinterAddModal(true);
        break;
      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('title')}
        leftIconName="Print"
        menu={MENU!
          .filter(item =>
            arrayKeyHasPermission(
              item?.permissionKeys || [],
              meProfile?.permissions || [],
            ),
          )
          .map(filteredItem => ({
            ...filteredItem,
            text: t(filteredItem.text as any),
          }))}
        onActionClick={handleAction}
      />
      {printerSettingsLoading ? (
        <LoadingState />
      ) : (
        <PrintersTable
          printers={printers}
          onItemEdit={item => {
            setPrinterToEdit(item);
            setPrinterAddModal(true);
          }}
          onItemRemove={removePrinter}
        />
      )}
      <PrinterAddModal
        open={printerAddModal}
        onClose={() => {
          setPrinterToEdit(null);
          setPrinterAddModal(false);
        }}
        item={printerToEdit}
      />
    </Box>
  );
}

export default PrinterSettings;
