import { Box, Snackbar, Grid, ClickAwayListener } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import Pusher, { Channel } from 'pusher-js';
import CustomerModal from './Sections/OrderModal/CustomerModal';
import StopWatch from '../../components/Timer/StopWatch';
import Icon from '../../components/Icon';
import useProfile from '../../hooks/useProfile';
import keyHasPermission from '../../helpers/keyHasPermission';

function Pos() {
  interface AlertDataType {
    phone_number: string;
    name?: string;
    created_at: string;
  }

  const { meProfile } = useProfile();

  let pusher: Pusher | null = null;
  let channel: Channel | null = null;

  const [open, setOpen] = useState(false);
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [alertData, setAlertData] = useState<AlertDataType[]>([]);

  const [customerModalId, setCustomerModalId] = useState('');

  const hasViewCallerId = keyHasPermission(
    'view-caller-id-record-listing',
    meProfile?.permissions || [],
  );

  const handleClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (alertData.length > 0) {
      setOpen(true);
      localStorage.setItem('alertDataLS', JSON.stringify(alertData));
    }
  }, [alertData]);

  useEffect(() => {
    let interval: number | NodeJS.Timer | null | undefined = null;

    if (alertData.length) {
      interval = setInterval(() => {
        const nowTimestamp = new Date().getTime();

        alertData.forEach(item => {
          const createdAtTimestamp = new Date(item.created_at).getTime();
          const timeDifferenceInSeconds =
            (nowTimestamp - createdAtTimestamp) / 1000;

          if (timeDifferenceInSeconds >= 60) {
            const newData = alertData.filter(
              data => data.phone_number !== item.phone_number,
            );
            setAlertData(newData);
            localStorage.setItem('alertDataLS', JSON.stringify(newData));
          }
        });
      }, 1000);
    } else {
      // @ts-ignore
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval as NodeJS.Timeout);
    };
  }, [alertData]);

  const messageEventHandler = (data: {
    id: string;
    phone_number: string;
    first_name?: string;
    last_name?: string;
  }) => {
    const newData: AlertDataType = {
      phone_number: data.phone_number,
      name: `${data.first_name || ''} ${data.last_name || ''}` || '',
      created_at: new Date().toString(),
    };
    setAlertData(prevState => [...prevState, newData]);

    setTimeout(() => {
      setAlertData(prevState =>
        prevState.filter(item => item.phone_number !== newData.phone_number),
      );
    }, 60000);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (meProfile?.activeBranch && meProfile?.is_caller_id_active) {
      const branchId = meProfile?.activeBranch?.id;

      const appKey = process.env.REACT_APP_PUSHER_APP_KEY || '';

      // @ts-ignore
      pusher = new Pusher(appKey, {
        cluster: 'eu',
      });

      channel = pusher.subscribe(`caller-id-${branchId}`);

      channel.bind('caller-id-event', messageEventHandler);

      return () => {
        if (channel) {
          channel.unbind('caller-id-event', messageEventHandler);
          // @ts-ignore
          pusher.unsubscribe(`caller-id-${branchId}`);
        }
        if (pusher) {
          pusher.disconnect();
        }
      };
    }
  }, [meProfile]);

  useEffect(() => {
    if (!alertData.length) {
      // @ts-ignore
      const storedArray = JSON.parse(localStorage.getItem('alertDataLS')) || [];
      setAlertData(storedArray);
    }
  }, []);

  return (
    <Box
      sx={{
        minHeight: '100%',
      }}
    >
      <Outlet />
      {hasViewCallerId && alertData.length > 0 && (
        // @ts-ignore
        <ClickAwayListener onClickAway={() => setOpen(true)}>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={open}
            onClose={handleClose}
            sx={{ zIndex: '999' }}
          >
            <Grid>
              <Grid
                sx={{
                  minWidth: '300px',
                  maxHeight: '225px',
                  overflowX: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 6,
                    opacity: 0.5,
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'white',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'gray',
                    borderRadius: 2,
                  },
                }}
              >
                {alertData.map(item => (
                  <Grid key={item.created_at}>
                    <Grid
                      container
                      sx={{
                        minWidth: '300px',
                        minHeight: '70px',
                        border: '1px solid #80808029',
                        borderRadius: '20px',
                        textAlign: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(37,38,37,0.78)',
                        marginBottom: '5px',
                      }}
                    >
                      <Grid item xs={3}>
                        <StopWatch
                          duration={item.created_at}
                          backgroundColor="rgb(37 38 37 / 0%)"
                          color="white"
                          notShowHour
                        />
                      </Grid>
                      <Grid xs={6} item textAlign="start">
                        <Grid sx={{ color: 'white', fontSize: '13px' }}>
                          {item.phone_number}
                        </Grid>
                        <Grid sx={{ color: 'white' }}>{item.name}</Grid>
                      </Grid>
                      <Grid
                        xs={3}
                        item
                        justifyContent="end"
                        sx={{ cursor: 'pointer' }}
                      >
                        <Icon
                          name="Close"
                          color="error"
                          onClick={e => {
                            e.stopPropagation();
                            const newData = alertData.filter(
                              data => data.phone_number !== item.phone_number,
                            );
                            setAlertData(newData);
                            localStorage.setItem(
                              'alertDataLS',
                              JSON.stringify(newData),
                            );
                          }}
                        />
                        <Icon
                          sx={{ marginLeft: '8px' }}
                          name="TwoWheeler"
                          color="success"
                          onClick={() => {
                            const newData = alertData.filter(
                              data => data.phone_number !== item.phone_number,
                            );
                            setCustomerModalId('');
                            setPhone(item.phone_number);
                            setName(item?.name || '');
                            setOpenCustomerModal(true);
                            setAlertData(newData);
                            localStorage.setItem(
                              'alertDataLS',
                              JSON.stringify(newData),
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Snackbar>
        </ClickAwayListener>
      )}
      <CustomerModal
        open={openCustomerModal}
        onClose={() => setOpenCustomerModal(false)}
        originalCustomerId={customerModalId}
        onCustomerIdChange={() => {}}
        unSubmittedPackage={() => {}}
        isTableOrder={false}
        isPackageOrder={false}
        customerId={customerModalId}
        setCustomerId={setCustomerModalId}
        callerPhone={phone}
        callerName={name}
        isCallerIdScreen
      />
    </Box>
  );
}

export default Pos;
