import { Box, IconButton } from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { grey } from '@mui/material/colors';

interface Props {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}
function MobileMenuButton({ onClick }: Props) {
  return (
    <Box sx={{ color: grey[700] }}>
      <IconButton
        size="large"
        aria-label="show more"
        aria-controls="mobileMenuId"
        aria-haspopup="true"
        onClick={onClick}
        color="inherit"
      >
        <MoreIcon />
      </IconButton>
    </Box>
  );
}

export default MobileMenuButton;
