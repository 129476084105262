/** Services */
import api from '../../api';

/** Types */
import { ApiSuccessResponse } from '../../types';
import {
  CreateResponse,
  ListResponse,
  ViewResponse,
  UpdateResponse,
  DeleteResponse,
  CreateAndUpdatePrinterSettingsParams,
} from './types';

type ListRes = ApiSuccessResponse<ListResponse>;
const list = (page: number) =>
  api.get<ListRes>('/printers', {
    params: {
      page,
    },
  });

type ViewRes = ApiSuccessResponse<ViewResponse>;
const view = (id: string) => api.get<ViewRes>(`/printers/${id}`);

type CreatRes = ApiSuccessResponse<CreateResponse>;
const create = (params: CreateAndUpdatePrinterSettingsParams) =>
  api.post<CreatRes>('/printers', params);

type UpdateRes = ApiSuccessResponse<UpdateResponse>;
const update = (params: CreateAndUpdatePrinterSettingsParams) =>
  api.put<UpdateRes>(`/printers/${params.id}`, params);

type DeleteRes = ApiSuccessResponse<DeleteResponse>;
const remove = (id: string) => api.delete<DeleteRes>(`/printers/${id}`);

export default { list, view, create, update, remove };
