import { Grid, Box, Typography, BoxProps, Divider } from '@mui/material';
import React from 'react';
import Button from '../Button';

type Tab = { id: string; name: string; [key: string]: any };
type Size = 'medium' | 'large' | 'small';
interface Props {
  tabs: Tab[];
  activeTab: Tab['id'] | null;
  onClick: (tab: Tab) => void;
  size?: Size;
  sx?: BoxProps['sx'];
  divider?: boolean;
}

function TabBar({
  tabs,
  activeTab,
  onClick,
  sx,
  size = 'small',
  divider = true,
}: Props) {
  return (
    <Box sx={[...(sx ? (Array.isArray(sx) ? sx : [sx]) : [])]}>
      <Grid container>
        {tabs.map((tab, index) => (
          <Grid item key={tab.id} xs={6} sm={3} md={2} lg={2}>
            <Button
              key={tab.id}
              sx={{
                width: '100%',
              }}
              variant={
                activeTab === tab.id || (!activeTab && index === 0)
                  ? 'contained'
                  : 'text'
              }
              onClick={() => onClick(tab)}
            >
              <Typography
                sx={{ fontSize: { xs: '14px', sm: '13px', md: '16px' } }}
                noWrap
              >
                {tab.name}
              </Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
      {divider && <Divider sx={{ mt: 1 }} />}
    </Box>
  );
}

export default TabBar;
