import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import currency from 'currency.js';
import { useTranslation } from 'react-i18next';
import { Grid, SxProps, Theme } from '@mui/material';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import Icon from '../../../../components/Icon';
import CancelModal from '../../Sections/OrderModal/CancelModal';
import FastPaymentModal from '../../Sections/OrderModal/FastPaymentModal';
import { GroupedDataColumnType } from '../../../../services/api/orders/types';
import PaymentModal from '../../Sections/OrderModal/PaymentModal';
import { TotalPrices } from '../../Sections/OrderModal';
import usePosOrder from '../../../../hooks/usePosOrder';
import UpdateStatusModal from './UpdateStatusModal';
import keyHasPermission from '../../../../helpers/keyHasPermission';
import useProfile from '../../../../hooks/useProfile';

const styles: Record<string, SxProps<Theme>> = {
  tableModalButtons: {
    height: '100px',
    width: '100%',
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
  orderId: string;
  handlePrintTable: (orderId: string) => void;
  order?: GroupedDataColumnType;
  type: string;
  handleSetStatus: (
    table_id: string,
    status_id: number,
    isPickedUp?: boolean,
  ) => void;
  handleFilterSubmit: () => void;
  fastPaymentModal: boolean;
  setFastPaymentModal: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
}

function OrderSettingModal({
  open,
  onClose,
  orderId,
  handlePrintTable,
  order,
  type,
  handleSetStatus,
  handleFilterSubmit,
  fastPaymentModal,
  setFastPaymentModal,
}: Props) {
  const { addOrderTransactionPayment, getOrderTransaction, orderDetails } =
    usePosOrder();
  const { t } = useTranslation('orders');
  const { enqueueSnackbar } = useSnackbar();
  const { meProfile } = useProfile();

  const [cancelModal, setCancelModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);

  const [totalPrices, setTotalPrices] = useState<TotalPrices>({
    totalPrice: 0,
    taxTotal: 0,
    grossTotal: 0,
    paidTotal: 0,
    discountTotal: 0,
    treatTotal: 0,
    couvert: 0,
    serviceCharge: 0,
  });

  const hasPaymentPermission = keyHasPermission(
    'get-order-payment',
    meProfile?.permissions || [],
  );

  const hasCancelOrderPermission = keyHasPermission(
    'cancel-order-transaction',
    meProfile?.permissions || [],
  );

  const hasPrintInvoicePermission = keyHasPermission(
    'view-order-detail',
    meProfile?.permissions || [],
  );

  const hasChangeStatusPermission = keyHasPermission(
    'set-order-transaction-status',
    meProfile?.permissions || [],
  );

  const handleCloseOrder = async () => {
    try {
      await addOrderTransactionPayment({
        order_transaction_id: orderId || '',
        payment_action: 2,
        payments: [
          {
            amount: 0,
            payment_type_id: 1,
            remainder_amount: 0,
          },
        ],
      });

      enqueueSnackbar(t('order.settings.order_closed'), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
      });
      handleFilterSubmit();
      onClose();
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  useEffect(() => {
    if (open) {
      getOrderTransaction(orderId);
      setTotalPrices({
        ...totalPrices,
        totalPrice:
          (Number(order?.total_amount.amount) -
            Number(order?.total_discount.amount) -
            Number(order?.paid_amount.amount)) /
            100 || 0,
        discountTotal: Number(order?.total_discount.amount || 0),
      });
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t('order.details.fast_transactions')}
      subtitle={t('order.details.fast_transactions_subtitle')}
      headerButtonIconName="Close"
      headerButton
      onHeaderButtonClick={onClose}
      headerButtonText={t('order.details.close')}
      headerButtonIconNameErrorColor="error"
    >
      <Grid container spacing={2}>
        {hasPaymentPermission && (
          <Grid xs={6} sm={4} md={4} lg={4} item>
            <Button
              variant="outlined"
              sx={styles.tableModalButtons}
              onClick={() => setPaymentModal(true)}
            >
              <Icon color="error" sx={{ m: 1 }} name="CreditCard" />
              <span>{t('order.settings.pay')}</span>
            </Button>
          </Grid>
        )}
        {hasPaymentPermission &&
          !(
            Number(order?.total_amount.amount) -
              Number(order?.total_discount.amount) ===
            Number(order?.paid_amount.amount)
          ) && (
            <Grid xs={6} sm={4} md={4} lg={4} item>
              <Button
                variant="outlined"
                sx={styles.tableModalButtons}
                onClick={() => setFastPaymentModal(true)}
              >
                <Icon color="error" sx={{ m: 1 }} name="CardTravel" />
                <span>{t('order.settings.fast_pay')}</span>
              </Button>
            </Grid>
          )}
        {hasCancelOrderPermission && (
          <Grid xs={6} sm={4} md={4} lg={4} item>
            <Button
              variant="outlined"
              sx={styles.tableModalButtons}
              onClick={() => setCancelModal(true)}
            >
              <Icon color="error" sx={{ m: 1 }} name="HighlightOff" />
              <span>{t('order.settings.cancelTicket')}</span>
            </Button>
          </Grid>
        )}
        {hasPrintInvoicePermission && (
          <Grid xs={6} sm={4} md={4} lg={4} item>
            <Button
              variant="outlined"
              sx={styles.tableModalButtons}
              onClick={() => handlePrintTable(orderId)}
            >
              <Icon color="error" sx={{ m: 1 }} name="Print" />
              <span>{t('order.settings.print')}</span>
            </Button>
          </Grid>
        )}
        {hasChangeStatusPermission && (
          <Grid xs={6} sm={4} md={4} lg={4} item>
            <Button
              variant="outlined"
              sx={styles.tableModalButtons}
              onClick={() => setStatusModal(true)}
            >
              <Icon color="error" sx={{ m: 1 }} name="List" />
              <span>{t('order.settings.update_status')}</span>
            </Button>
          </Grid>
        )}
        {hasPaymentPermission &&
          Number(order?.total_amount.amount) -
            Number(order?.total_discount.amount) ===
            Number(order?.paid_amount.amount) && (
            <Grid xs={6} sm={4} md={4} lg={4} item>
              <Button
                variant="outlined"
                sx={styles.tableModalButtons}
                onClick={() => handleCloseOrder()}
              >
                <Icon color="error" sx={{ m: 1 }} name="Close" />{' '}
                <span>{t('order.settings.close_order')}</span>
              </Button>
            </Grid>
          )}
      </Grid>
      <CancelModal
        open={cancelModal}
        onClose={() => setCancelModal(false)}
        orderId={orderId || ''}
        onBackClick={onClose}
        handleFilterSubmit={handleFilterSubmit}
      />
      {order && (
        <FastPaymentModal
          open={fastPaymentModal}
          onClose={() => {
            setFastPaymentModal(false);
            onClose();
          }}
          totalPrice={
            (Number(order.total_amount.amount) -
              Number(order.total_discount.amount) -
              Number(order.paid_amount.amount)) /
              100 || 0
          }
          tableId={orderId || ''}
          onBackClick={() => handleFilterSubmit()}
          currencyCode={order.total_amount.currency || ''}
          isSelfOrder={false}
          isSelfCreate={false}
          selectedOrderTypeId={order?.preset_payment_type?.id || 0}
        />
      )}
      {order && (
        <PaymentModal
          open={paymentModal}
          onClose={() => {
            setPaymentModal(false);
            onClose();
          }}
          list={
            orderDetails?.orders?.map((mappedOrder: any) => ({
              id: mappedOrder.id,
              is_treat: mappedOrder.is_treat,
              is_submitted_mappedOrder: true,
              is_active: true,
              is_menu: mappedOrder.is_menu,
              is_required_property_selection: true,
              menus: [],
              created_by: mappedOrder.created_by,
              color_status: mappedOrder.color_status,
              payments: mappedOrder.payments,
              has_special_product: mappedOrder?.has_special_product || false,
              gross_amount: mappedOrder?.gross_amount,
              unit_price: mappedOrder?.unit_price,
              product: mappedOrder.product,
              items: mappedOrder.items,
              category: mappedOrder?.items[0]?.category || {},
              count: mappedOrder.quantity,
              kitchen_group:
                mappedOrder?.items[0]?.category?.translations[0] || {},
              product_code: '620910011150',
              sequence: 4,
              tax_rate: 18,
              created_at: mappedOrder.created_at,
              translations: mappedOrder.items[0].product.translations,
              paid_quantity: mappedOrder.paid_quantity,
              paid_amount: mappedOrder.paid_amount,
              total_amount: mappedOrder.total_amount,
              units: mappedOrder?.items[0]?.product_unit?.map((item: any) => ({
                ...item,
                translations: item.unit.translations,
                is_default: true,
              })),
              property_group_items:
                mappedOrder.items[0].property_group_items.map((item: any) => ({
                  ...item,
                  product_property_group_item_id:
                    item.product_property_group_item_id,
                  translations: item.translations,
                  additional_price: item.additional_price,
                  is_default: true,
                })),
              ingredient_group_items:
                mappedOrder.items[0].ingredient_group_items.map(
                  (item: any) => ({
                    ...item,
                    product_ingredient_group_item_id:
                      item.product_ingredient_group_item_id,
                    translations: item.translations,
                    additional_price: item.additional_price,
                    is_default: true,
                  }),
                ),
            })) || []
          }
          discountTotal={totalPrices.discountTotal || 0}
          removeDiscount={() => {
            setTotalPrices({
              ...totalPrices,
              totalPrice:
                totalPrices.totalPrice + totalPrices.discountTotal / 100,
              discountTotal: 0,
            });
          }}
          totalPrice={Number(
            currency(totalPrices?.totalPrice).subtract(totalPrices?.paidTotal),
          )}
          tableId={orderId || ''}
          waiterName={
            `${order.created_by.first_name} ${order.created_by.last_name}` || ''
          }
          tableName={order.type}
          onBackClick={() => handleFilterSubmit()}
          paidPayments={orderDetails?.payments || []}
          currencyCode={order.total_amount.currency || ''}
          isPackageOrder={order?.type_id === 3}
          isSelfOrder={order?.type_id === 2}
          selectedOrderTypeId={order?.preset_payment_type?.id || 0}
        />
      )}
      <UpdateStatusModal
        open={statusModal}
        onClose={() => setStatusModal(false)}
        tableId={orderId}
        typeId={order?.type_id || 0}
        type={type}
        handleSetStatus={handleSetStatus}
        isFullPaid={
          order
            ? Number(order.total_amount.amount) -
                Number(order.paid_amount.amount) -
                Number(order.total_discount.amount) ===
              0
            : false
        }
      />
    </Modal>
  );
}

export default OrderSettingModal;
