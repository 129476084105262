/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import { Grid } from '@mui/material';

/** Components */
import Icon from '../../../../components/Icon';
import FormSelect from '../../../../components/FormSelect';

interface Props {
  orderItemGroup: any;
  handleOrderItemGroupUpdate: (status: number, checkNumber: string) => void;
}

function OrderItemCardContentHeader({
  orderItemGroup,
  handleOrderItemGroupUpdate,
}: Props) {
  const { t } = useTranslation('kitchen');

  const options = [
    { id: '1', text: t('order.approval') },
    { id: '2', text: t('order.preeparing') },
    { id: '3', text: t('order.servicing') },
  ];

  return (
    <Grid mt={1} mb={1} container justifyContent="space-between">
      <Grid>
        <Grid container>
          <Grid justifyContent="center">
            <Icon
              fontSize="large"
              sx={{ color: 'darkgrey' }}
              name="BackupTable"
            />
          </Grid>
          <Grid ml={1}>
            <Grid>
              {orderItemGroup?.created_by?.full_name} /{' '}
              {orderItemGroup?.check_number}
            </Grid>
            <Grid color="gray">
              {orderItemGroup?.table
                ? `${orderItemGroup?.table?.section?.name} / ${orderItemGroup?.table?.name}`
                : orderItemGroup?.type}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Grid>
          <FormSelect
            value={orderItemGroup?.order_group_status}
            options={options}
            onChange={(event: { target: { value: any } }) =>
              handleOrderItemGroupUpdate(
                Number(event.target.value),
                orderItemGroup.id,
              )
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OrderItemCardContentHeader;
