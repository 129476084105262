import { autoBatchEnhancer } from '@reduxjs/toolkit';

const styles = {
  container: {
    mb: 2,
  },
  textFieid: {
    mb: 15,
  },
};

export default styles;
