/** Dependencies */
import React from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import currency from 'currency.js';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

/** Components */
import Button from '../../../../../../components/Button';
import Icon from '../../../../../../components/Icon';
import IconButton from '../../../../../../components/IconButton';

/** Types */
import { TotalPrices } from '../../types';
import { SelfPayment } from '../../PaymentModal/types';

/** Helpers */
import { priceFormatter } from '../../../../../../helpers/moneyFormatter';
import keyHasPermission from '../../../../../../helpers/keyHasPermission';

/** Hooks */
import useProfile from '../../../../../../hooks/useProfile';

/** Styles */
import styles from './styles';

interface Props {
  submitting: boolean;
  onSubmit: () => void;
  onPackageSubmit: () => void;
  onDiscountPress: () => void;
  onPaymentPress: () => void;
  onPackagePaymentPress: () => void;
  onFastPaymentPress: () => void;
  totalPrices: TotalPrices;
  isActiveTransaction: boolean;
  currencyCode: string;
  isPackageOrder: boolean;
  isSelfOrder: boolean;
  selectedPaymentName: string;
  listLength: number;
  onSelfSubmit: (selfPayment: SelfPayment | null) => void;
  handleCountChange: (
    guestsCount: number,
    autoCouvert: boolean,
  ) => Promise<void>;
  guestCount: number;
  onServiceChargePress: (isAuto: boolean) => void;
  isAutoCouvert: boolean;
  isAutoServiceCharge: boolean;
}
function Footer({
  submitting,
  onSubmit,
  onPackageSubmit,
  onDiscountPress,
  onPaymentPress,
  onPackagePaymentPress,
  onFastPaymentPress,
  totalPrices,
  isActiveTransaction,
  currencyCode,
  isPackageOrder,
  isSelfOrder,
  selectedPaymentName,
  listLength,
  onSelfSubmit,
  handleCountChange,
  guestCount,
  onServiceChargePress,
  isAutoCouvert,
  isAutoServiceCharge,
}: Props) {
  const { t } = useTranslation('pos');
  const { meProfile } = useProfile();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const hasPaymentPermission = keyHasPermission(
    'get-order-payment',
    meProfile?.permissions || [],
  );
  const hasSetDiscountPermission = keyHasPermission(
    'set-discount',
    meProfile?.permissions || [],
  );
  const hasTakeOrderPermission = keyHasPermission(
    'take-order',
    meProfile?.permissions || [],
  );

  return (
    <Box>
      <Divider />
      <Accordion elevation={0}>
        <AccordionSummary expandIcon={<Icon name="ExpandMore" />}>
          <Typography sx={{ ...styles.typography, flex: 1 }}>
            {t('orderModal.basket.totalPrice')}:
          </Typography>
          <Typography variant="subtitle2" sx={{ mr: 2 }}>
            {priceFormatter(totalPrices?.totalPrice, currencyCode) || '₺0,00'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <Typography sx={styles.typography}>
                {t('orderModal.basket.tax')}
              </Typography>
              <Typography sx={styles.typography}>
                {t('orderModal.basket.paid')}
              </Typography>
              <Typography sx={styles.typography}>
                {t('orderModal.basket.discount')}
              </Typography>
              <Typography sx={styles.typography}>
                {t('orderModal.basket.treat')}
              </Typography>
              {isAutoCouvert && (
                <Typography sx={styles.typography}>
                  {t('orderModal.basket.couvert')}
                </Typography>
              )}
              {isAutoServiceCharge && (
                <Typography sx={styles.typography}>
                  {t('orderModal.basket.service_charge')}
                </Typography>
              )}
            </Box>
            <Box textAlign="end">
              <Typography variant="subtitle2">
                {priceFormatter(
                  Number(currency(totalPrices?.taxTotal).divide(100)),
                  currencyCode,
                ) || '₺0,00'}
              </Typography>
              <Typography variant="subtitle2">
                {priceFormatter(
                  Number(currency(totalPrices?.paidTotal)),
                  currencyCode,
                ) || '₺0,00'}
              </Typography>
              <Typography variant="subtitle2">
                {priceFormatter(
                  Number(currency(totalPrices?.discountTotal).divide(100)),
                  currencyCode,
                ) || '₺0,00'}
              </Typography>
              <Typography variant="subtitle2">
                {priceFormatter(
                  Number(currency(totalPrices?.treatTotal).divide(100)),
                  currencyCode,
                ) || '₺0,00'}
              </Typography>
              {isAutoCouvert && (
                <Typography variant="subtitle2" display="flex">
                  <Typography
                    onClick={() => handleCountChange(guestCount, false)}
                    sx={{
                      marginRight: '10px',
                      fontSize: '0.9rem',
                      cursor: 'pointer',
                    }}
                    color="error"
                  >
                    {t('orderModal.basket.couvert_cancel')}
                  </Typography>
                  {priceFormatter(
                    Number(currency(totalPrices?.couvert).divide(100)),
                    currencyCode,
                  ) || '₺0,00'}
                </Typography>
              )}
              {isAutoServiceCharge && (
                <Typography variant="subtitle2" display="flex">
                  <Typography
                    sx={{
                      marginRight: '10px',
                      fontSize: '0.9rem',
                      cursor: 'pointer',
                    }}
                    color="error"
                    onClick={() => onServiceChargePress(false)}
                  >
                    {t('orderModal.basket.couvert_cancel')}
                  </Typography>
                  {priceFormatter(
                    Number(currency(totalPrices?.serviceCharge).divide(100)),
                    currencyCode,
                  ) || '₺0,00'}
                </Typography>
              )}
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
      {!isActiveTransaction && !isPackageOrder && !isSelfOrder && (
        <Grid sx={styles.submitPadding}>
          <Button
            sx={{ ...styles.submit, px: 1 }}
            loading={submitting}
            disabled={submitting}
            onClick={onSubmit}
            fullWidth
          >
            {t('orderModal.basket.submit').toUpperCase()}
          </Button>
        </Grid>
      )}
      {(isActiveTransaction || isSelfOrder) && (
        <Grid
          container
          sx={styles.discountPadding}
          spacing={1}
          p={1}
          justifyContent="space-between"
        >
          <Grid item xs={3}>
            <Button
              sx={styles.discountBg}
              fullWidth
              onClick={() => {
                if (listLength > 0) {
                  onDiscountPress();
                } else {
                  enqueueSnackbar(t('orderModal.emptyPackageDiscount'), {
                    variant: 'warning',
                  });
                }
              }}
              disabled={!hasSetDiscountPermission || submitting}
              loading={submitting}
            >
              <IconButton
                radius={0}
                color="error"
                iconName="LocalOffer"
                badgeDot={totalPrices?.discountTotal > 0}
                disabled={!hasSetDiscountPermission}
              />
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              loading={submitting}
              onClick={onPaymentPress}
              color="success"
              fullWidth
              sx={{
                ...styles.submit,
                fontSize: { sm: '13px', md: '14px', lg: '14px' },
              }}
              disabled={!hasPaymentPermission || submitting}
            >
              <Grid p={1}>
                <Grid>{t('sections.table.pay')}</Grid>
                <Grid>
                  {priceFormatter(
                    Number(
                      currency(totalPrices?.totalPrice).subtract(
                        totalPrices?.paidTotal,
                      ),
                    ),
                    currencyCode,
                  )}
                </Grid>
              </Grid>
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={onFastPaymentPress}
              loading={submitting}
              color="warning"
              fullWidth
              disabled={!hasPaymentPermission || submitting}
            >
              <Icon name="FlashOn" color="secondary" />
              <Grid sx={{ color: '#9c27b0', ...styles.submit }}>
                {t('sections.table.fast_pay')}
              </Grid>
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              loading={submitting}
              onClick={() =>
                isPackageOrder
                  ? onPackageSubmit()
                  : isSelfOrder
                  ? onSelfSubmit(null)
                  : onSubmit()
              }
              color="primary"
              fullWidth
              sx={styles.submit}
              disabled={!hasTakeOrderPermission || submitting}
            >
              {t('orderModal.basket.submit').toUpperCase()}
            </Button>
          </Grid>
        </Grid>
      )}
      {isPackageOrder && !id && (
        <Grid
          container
          sx={styles.discountPadding}
          spacing={1}
          p={1}
          justifyContent="space-between"
        >
          <Grid item xs={4}>
            <Button
              sx={styles.discountBg}
              fullWidth
              onClick={() => {
                if (listLength > 0) {
                  onDiscountPress();
                } else {
                  enqueueSnackbar(t('orderModal.emptyPackageDiscount'), {
                    variant: 'warning',
                  });
                }
              }}
              disabled={!hasSetDiscountPermission || submitting}
              loading={submitting}
            >
              <IconButton
                radius={0}
                color="error"
                iconName="LocalOffer"
                badgeDot={totalPrices?.discountTotal > 0}
                disabled={!hasSetDiscountPermission}
              />
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              sx={styles.discountBg}
              onClick={onPackagePaymentPress}
              loading={submitting}
              fullWidth
              disabled={!hasPaymentPermission || submitting}
            >
              <Grid sx={{ ...styles.submit, color: '#d32f2f' }}>
                {selectedPaymentName.length
                  ? selectedPaymentName.toUpperCase()
                  : t('sections.table.payment_type')}
              </Grid>
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              loading={submitting}
              onClick={() =>
                isSelfOrder ? onSelfSubmit(null) : onPackageSubmit()
              }
              color="primary"
              fullWidth
              sx={styles.submit}
              disabled={!hasTakeOrderPermission || submitting}
            >
              {t('orderModal.basket.submit').toUpperCase()}
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default Footer;
