class TokenStorage {
  private key: string;

  constructor(key: string) {
    this.key = key;
  }

  setToken = (token: string) => localStorage.setItem(this.key, token);

  getToken = () => localStorage.getItem(this.key);

  removeToken = () => localStorage.removeItem(this.key);
}

export default TokenStorage;
