import React, { SVGProps } from 'react';

function OtpIconSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="Layer_1"
      x={0}
      y={0}
      style={
        {
          // enableBackground: "new 0 0 225 125",
        }
      }
      viewBox="0 0 225 125"
      width={225}
      height={125}
      {...props}
    >
      <style>{'.st1{fill:#ebeaf0}.st2{fill:#0059ff}.st3{fill:none}'}</style>
      <path
        d="m138.3 63.23-1.41-1.07v3.17l1.48 1.12v.01l1.41-1.07v-3.28l-1.48 1.12z"
        style={{
          fill: '#e0802a',
        }}
      />
      <path
        d="M160.77 8.22h-.03c-.67 0-1.21-.54-1.21-1.21v-5.8c0-.67.54-1.21 1.21-1.21h.03c.67 0 1.21.54 1.21 1.21v5.8c0 .67-.54 1.21-1.21 1.21zM160.77 21.88h-.03c-.67 0-1.21-.54-1.21-1.21v-5.8c0-.67.54-1.21 1.21-1.21h.03c.67 0 1.21.54 1.21 1.21v5.8c0 .67-.54 1.21-1.21 1.21zM171.46 10.93v.03c0 .67-.54 1.21-1.21 1.21h-5.8c-.67 0-1.21-.54-1.21-1.21v-.03c0-.67.54-1.21 1.21-1.21h5.8c.67 0 1.21.54 1.21 1.21zM158.28 10.93v.03c0 .67-.54 1.21-1.21 1.21h-5.8c-.67 0-1.21-.54-1.21-1.21v-.03c0-.67.54-1.21 1.21-1.21h5.8c.66 0 1.21.54 1.21 1.21zM66.58 112.86h-.02c-.59 0-1.07-.48-1.07-1.07v-5.15c0-.59.48-1.07 1.07-1.07h.02c.59 0 1.07.48 1.07 1.07v5.15c.01.59-.47 1.07-1.07 1.07zM66.58 125h-.02c-.59 0-1.07-.48-1.07-1.07v-5.15c0-.59.48-1.07 1.07-1.07h.02c.59 0 1.07.48 1.07 1.07v5.15c.01.59-.47 1.07-1.07 1.07zM76.08 115.27v.02c0 .59-.48 1.07-1.07 1.07h-5.15c-.59 0-1.07-.48-1.07-1.07v-.02c0-.59.48-1.07 1.07-1.07H75c.6-.01 1.08.48 1.08 1.07zM64.36 115.27v.02c0 .59-.48 1.07-1.07 1.07h-5.15c-.59 0-1.07-.48-1.07-1.07v-.02c0-.59.48-1.07 1.07-1.07h5.15c.59-.01 1.07.48 1.07 1.07zM85.12 40.19h-17.9a6.6 6.6 0 0 1-6.6-6.6v-.15a6.6 6.6 0 0 1 6.6-6.6h17.9v13.35zM83.17 94.32H51.59a6.6 6.6 0 0 1-6.6-6.6v-.15a6.6 6.6 0 0 1 6.6-6.6h31.57v13.35zM83.71 53.55H27.94a6.6 6.6 0 0 1-6.6-6.6v-.15a6.6 6.6 0 0 1 6.6-6.6h55.78v13.35zM52.26 53.55a6.68 6.68 0 0 1 6.68 6.68v.69a6.68 6.68 0 0 1-6.68 6.68h32.85V53.55H52.26zM83.71 80.96H12.87a6.6 6.6 0 0 1-6.6-6.6v-.15a6.6 6.6 0 0 1 6.6-6.6h70.85v13.35zM171.49 53.9h13.96a6.6 6.6 0 0 0 6.6-6.6v-.15a6.6 6.6 0 0 0-6.6-6.6h-13.96V53.9zM171.65 67.26h34.36a6.6 6.6 0 0 0 6.6-6.6v-.16a6.6 6.6 0 0 0-6.6-6.6h-34.36v13.36zM187.02 67.26a6.68 6.68 0 0 0-6.68 6.68v.69a6.68 6.68 0 0 0 6.68 6.68h-15.89V67.26h15.89zM193.19 81.31h-21.58v4.62h-33.99v8.74h55.57a6.68 6.68 0 0 0 6.68-6.68 6.68 6.68 0 0 0-6.68-6.68z"
        className="st1"
      />
      <circle cx={210.29} cy={87.99} r={6.68} className="st1" />
      <path
        d="m166.33 45.21-26.56 20.18-1.41 1.07v-.01l-1.48-1.12L110.4 45.2c-.34-.25-.51-.64-.51-1.02 0-.27.09-.55.26-.78a1.3 1.3 0 0 1 1.81-.25l24.93 19.01 1.41 1.07 1.48-1.12 24.99-18.96c.57-.43 1.38-.32 1.81.24.42.58.32 1.39-.25 1.82z"
        className="st2"
      />
      <path
        d="M167.21 41.15h-57.69c-1.6 0-2.89 1.29-2.89 2.89v38.17c0 1.59 1.29 2.89 2.89 2.89h57.69c1.6 0 2.89-1.3 2.89-2.89V44.04c0-1.6-1.29-2.89-2.89-2.89z"
        className="st3"
      />
      <path
        d="M137.62 34.12c.8 0 1.44-.65 1.44-1.44V22.47c0-6.39-5.18-11.57-11.57-11.57H93.88c-6.38 0-11.57 5.18-11.57 11.57V98.4c0 6.39 5.19 11.57 11.57 11.57h33.61c6.39 0 11.57-5.18 11.57-11.57V93.3c0-.8-.65-1.44-1.44-1.44s-1.44.65-1.44 1.44v4.86c0 4.79-3.89 8.68-8.68 8.68H93.79c-4.79 0-8.68-3.89-8.68-8.68V22.39c0-4.79 3.89-8.68 8.68-8.68h8.68a2.87 2.87 0 0 0 2.85 2.56h11.05c1.48 0 2.69-1.13 2.86-2.56h8.26c4.79 0 8.68 3.89 8.68 8.68v10.29c.01.8.65 1.44 1.45 1.44z"
        className="st2"
      />
      <path
        d="M168.28 38.05h-59.83c-2.66 0-4.82 2.16-4.82 4.82v40.17c0 2.66 2.16 4.82 4.82 4.82h59.82c2.66 0 4.82-2.16 4.82-4.82V42.87a4.808 4.808 0 0 0-4.81-4.82zm1.82 44.17c0 1.59-1.29 2.89-2.89 2.89h-57.69c-1.6 0-2.89-1.3-2.89-2.89V44.04c0-1.6 1.29-2.89 2.89-2.89h57.69c1.6 0 2.89 1.29 2.89 2.89v38.18z"
        className="st2"
      />
      <path
        d="M167.21 85.11c1.6 0 2.89-1.3 2.89-2.89V44.04c0-1.6-1.29-2.89-2.89-2.89h-34.37v43.96h34.37z"
        className="st3"
      />
      <path
        d="M103.63 83.04V42.87c0-2.66 2.16-4.82 4.82-4.82h23.68V24.57a6.18 6.18 0 0 0-6.19-6.18h-30.6c-3.42 0-6.19 2.77-6.19 6.18v71.85c0 3.42 2.77 6.19 6.19 6.19h30.6c3.42 0 6.19-2.77 6.19-6.19v-8.56h-23.68c-2.66 0-4.82-2.16-4.82-4.82z"
        style={{
          opacity: 0.1,
          fill: '#0059ff',
        }}
      />
      <path
        d="M-313.48 522.9h-624c-110.46 0-200-89.54-200-200v-624c0-110.46 89.54-200 200-200h624c110.46 0 200 89.54 200 200v624c0 110.46-89.55 200-200 200z"
        className="st2"
      />
    </svg>
  );
}
export default OtpIconSvg;
