import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../../components/ConfirmationModal';
import EmptyState from '../../../components/EmptyState';
import ErrorState from '../../../components/ErrorState';
import LoadingState from '../../../components/LoadingState';
import Modal from '../../../components/Modal';
import OrderedList from '../../../components/OrderedList';
import getActiveLanguage from '../../../helpers/getActiveLanguage';
import useUnits from '../../../hooks/useUnits';
import UnitAddModal from './UnitAddModal';
import keyHasPermission from '../../../helpers/keyHasPermission';
import useProfile from '../../../hooks/useProfile';
import { IdAndNameTranslation } from '../../../models/General';

interface Props {
  open: boolean;
  onClose: () => void;
}

function UnitEditModal({ open, onClose }: Props) {
  const { t } = useTranslation('category');
  const {
    units,
    unitLoading,
    unitError,
    fetchUnits,
    removeUnit,
    productUnitMoveDown,
    productUnitMoveUp,
  } = useUnits();
  const { meProfile } = useProfile();

  useEffect(() => {
    if (open) fetchUnits();
  }, [open]);

  const [loading, setLoading] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(
    null,
  ); // item id

  const [unitAddModal, setUnitAddModal] = React.useState(false);
  const [unitToEdit, setUnitToEdit] =
    React.useState<IdAndNameTranslation | null>(null);

  const handleMoveUp = async (item: IdAndNameTranslation) => {
    setLoading(true);
    await productUnitMoveUp(item.id);
    setLoading(false);
  };

  const handleMoveDown = async (item: IdAndNameTranslation) => {
    setLoading(true);
    await productUnitMoveDown(item.id);
    setLoading(false);
  };

  const handleRemove = async () => {
    if (deleteConfirmation) {
      setLoading(true);
      await removeUnit(deleteConfirmation);
      setLoading(false);
      setDeleteConfirmation(null);
    }
  };

  const renderLoading = () => <LoadingState />;
  const renderError = () => (
    <ErrorState
      message={unitError ? unitError.message : t('common:unknownError' as any)}
    />
  );
  const renderEmpty = () => <EmptyState message={t('unit.editModal.empty')} />;
  const renderList = () => (
    <OrderedList
      items={units.map(item => ({
        ...item,
        name: getActiveLanguage(item.translations).name,
      }))}
      loading={loading}
      onItemEdit={item => {
        setUnitToEdit(item);
        setUnitAddModal(true);
      }}
      onItemUp={handleMoveUp}
      onItemDown={handleMoveDown}
      onItemRemove={item => setDeleteConfirmation(item.id)}
      dense={false}
      sortButtons={keyHasPermission(
        'move-unit-sequence',
        meProfile?.permissions || [],
      )}
      editButtons={keyHasPermission(
        'update-unit',
        meProfile?.permissions || [],
      )}
      removeButtons={keyHasPermission(
        'delete-unit',
        meProfile?.permissions || [],
      )}
    />
  );

  return (
    <>
      <Modal
        title={t('unit.editModal.title')}
        open={open}
        onClose={onClose}
        positiveAction={t('unit.editModal.close')}
        onPositiveClick={onClose}
        headerButton={keyHasPermission(
          'create-unit',
          meProfile?.permissions || [],
        )}
        headerButtonIconName="Add"
        headerButtonText={t('unit.editModal.addButton')}
        onHeaderButtonClick={() => setUnitAddModal(true)}
      >
        {unitLoading
          ? renderLoading()
          : unitError
          ? renderError()
          : units.length
          ? renderList()
          : renderEmpty()}
      </Modal>
      <ConfirmationModal
        title={t('unit.deleteConfirmation.title')}
        titleIconName="QuestionMarkOutlined"
        description={t('unit.deleteConfirmation.description')}
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        positiveAction={t('unit.deleteConfirmation.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('unit.deleteConfirmation.cancel')}
        onNegativeClick={() => setDeleteConfirmation(null)}
        loading={loading}
      />
      <UnitAddModal
        open={unitAddModal}
        item={unitToEdit}
        onClose={() => {
          setUnitAddModal(false);
          setUnitToEdit(null);
        }}
        onConfirm={() => {
          setUnitAddModal(false);
          setUnitToEdit(null);
        }}
      />
    </>
  );
}

export default UnitEditModal;
