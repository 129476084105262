import api from '../../api';
import { ApiSuccessResponse } from '../../types';
import {
  CreateResponse,
  ListResponse,
  ViewResponse,
  UpdateResponse,
  DeleteResponse,
  ItemCreateResponse,
  ItemUpdateResponse,
  ItemDeleteResponse,
} from './types';

const PAGING_LIMIT = 100;

type ListRes = ApiSuccessResponse<ListResponse>;

const list = () =>
  api.get<ListRes>('/property-groups', {
    params: {
      per_page: PAGING_LIMIT,
    },
  });

type ViewRes = ApiSuccessResponse<ViewResponse>;
const view = (id: string) => api.get<ViewRes>(`/property-groups/${id}`);

type CreatRes = ApiSuccessResponse<CreateResponse>;
const create = (name: { [key: string]: string }, is_multiple: boolean) =>
  api.post<CreatRes>('/property-groups', {
    name,
    is_multiple,
  });

type UpdateRes = ApiSuccessResponse<UpdateResponse>;
const update = (params: {
  id: string;
  name: { [key: string]: string };
  isMulti: boolean;
}) =>
  api.put<UpdateRes>(`/property-groups/${params.id}`, {
    id: params.id,
    name: params.name,
    is_multiple: params.isMulti,
  });

type DeleteRes = ApiSuccessResponse<DeleteResponse>;
const remove = (id: string) => api.delete<DeleteRes>(`/property-groups/${id}`);

type ItemCreatRes = ApiSuccessResponse<ItemCreateResponse>;
const itemCreate = (params: {
  groupId: string;
  name: { [key: string]: string };
  additional_price: number;
}) =>
  api.post<ItemCreatRes>(`/property-groups/${params.groupId}/items`, {
    name: params.name,
    additional_price: params.additional_price,
  });

type ItemUpdateRes = ApiSuccessResponse<ItemUpdateResponse>;
const itemUpdate = (params: {
  id: string;
  name: { [key: string]: string };
  groupId: string;
  additional_price: number;
}) =>
  api.put<ItemUpdateRes>(
    `/property-groups/${params.groupId}/items/${params.id}`,
    {
      id: params.id,
      name: params.name,
      additional_price: params.additional_price,
    },
  );

type ItemRemoveRes = ApiSuccessResponse<ItemDeleteResponse>;
const itemRemove = (groupId: string, id: string) =>
  api.delete<ItemRemoveRes>(`/property-groups/${groupId}/items/${id}`);

export default {
  list,
  view,
  create,
  update,
  remove,
  itemCreate,
  itemUpdate,
  itemRemove,
};
