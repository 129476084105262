/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  Radio,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  ButtonGroup,
  IconButton,
  Tooltip,
} from '@mui/material';

/** Components */
import { useNavigate } from 'react-router-dom';
import FormSelectSearchable from '../../../../../components/FormSelectSearchable';
import Modal from '../../../../../components/Modal';
import LoadingState from '../../../../../components/LoadingState';
import Icon from '../../../../../components/Icon';
import CustomerGroupAddModal from './CustomerGroupAddModal';
import CustomerAddressAddModal from '../../../../../pages/definitions/CustomerDetails/Modals/CustomerAddressAddModal';

/** Hooks */
import useCustomers from '../../../../../hooks/useCustomers';
import useCustomerAddress from '../../../../../hooks/useCustomerAddress';
import useProfile from '../../../../../hooks/useProfile';

/** Helpers */
import keyHasPermission from '../../../../../helpers/keyHasPermission';

/** Styles */
import styles from './styles';

interface Props {
  open: boolean;
  onClose: () => void;
  originalCustomerId: string;
  onCustomerIdChange: (customerId?: string, customerAddressId?: string) => void;
  settedCustomerId?: string | null;
  settedCustomerAddressId?: string | null;
  unSubmittedPackage: () => void;
  isTableOrder: boolean;
  isPackageOrder: boolean;
  customerId: string;
  setCustomerId: (value: ((prevState: string) => string) | string) => void;
  isCallerIdScreen?: boolean;
  callerPhone?: string;
  callerName?: string;
}

function CustomerModal({
  open,
  onClose,
  originalCustomerId,
  onCustomerIdChange,
  settedCustomerId,
  settedCustomerAddressId,
  unSubmittedPackage,
  isTableOrder,
  isPackageOrder,
  customerId,
  setCustomerId,
  isCallerIdScreen,
  callerPhone,
  callerName,
}: Props) {
  const { t } = useTranslation('pos');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { fetchCustomers, customers, customerLoading } = useCustomers();
  const { fetchCustomerAddresses, customerAddresses, customerAddressLoading } =
    useCustomerAddress();
  const { meProfile } = useProfile();

  const [firstFetch, setFirstFetch] = useState(true);
  const [customerAddressId, setCustomerAddressId] = useState(
    settedCustomerAddressId || '',
  );
  const [customerGroupAddModal, setCustomerGroupAddModal] = useState(false);
  const [customerAddressAddModal, setCustomerAddressAddModal] = useState(false);

  const hasCreatePermission = keyHasPermission(
    'create-customer-address',
    meProfile?.permissions || [],
  );

  const handleCustomerChange = (id: string) => {
    if (id) {
      setCustomerId(id);
      setCustomerAddressId('');
    } else {
      setCustomerId('');
      setCustomerAddressId('');
    }
  };

  const debounce = (func: any, delay: number) => {
    let timer: any;
    return function (...args: any) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const onInputChange = debounce((input: string) => {
    fetchCustomers({ page: 1, search: input, is_active: true, first_name: '' });
  }, 1000);

  const handleClose = () => {
    onClose();
    unSubmittedPackage();
    if (!isPackageOrder && !isTableOrder) {
      setCustomerId('');
      setCustomerAddressId('');
    }
  };

  const handleReset = () => {
    if (customerId) {
      onCustomerIdChange();
      setCustomerId('');
      setCustomerAddressId('');
    }
  };

  const handleSubmit = () => {
    if (isPackageOrder) {
      if (customerId && (customerAddressId || customerAddresses.length)) {
        onCustomerIdChange(
          customerId,
          customerAddresses?.find(item => item?.is_default)?.id,
        );
        onClose();
      } else {
        enqueueSnackbar(t('orderModal.customerModal.empty'), {
          variant: 'warning',
        });
      }
    } else if (isCallerIdScreen) {
      if (customerId && (customerAddressId || customerAddresses.length)) {
        navigate(`/app/pos/order/package?caller=true`, {
          state: {
            tableName: null,
            full: false,
            isCallerId: true,
            callerCustomer: customerId,
            callerAddress:
              customerAddressId ||
              settedCustomerAddressId ||
              customerAddresses[0].id,
          },
        });
      } else {
        enqueueSnackbar(t('orderModal.customerModal.empty'), {
          variant: 'warning',
        });
      }
    } else if (customerId) {
      onCustomerIdChange(
        customerId,
        isTableOrder
          ? ''
          : customerAddressId ||
              customerAddresses?.find(item => item?.is_default)?.id,
      );
      onClose();
    } else {
      onClose();
    }
    onClose();
  };

  useEffect(() => {
    if (open && (firstFetch || !customers.length)) {
      fetchCustomers({ page: 1, search: '', is_active: true, first_name: '' });
      setFirstFetch(false);
    }

    if (open && (settedCustomerId || originalCustomerId) && firstFetch) {
      setCustomerId(settedCustomerId || originalCustomerId);
      setCustomerAddressId(settedCustomerAddressId || '');
    }
  }, [open]);

  useEffect(() => {
    setCustomerId(settedCustomerId || '');
  }, []);

  useEffect(() => {
    if (open && customerId.length && !isTableOrder) {
      fetchCustomerAddresses(customerId);
    }
  }, [customerId]);

  const settedCaller = async () => {
    if (isCallerIdScreen && callerPhone) {
      const callerId = customers?.find(
        item => item?.phone_number === callerPhone,
      )?.id;
      await setCustomerId(callerId || '');

      if (!callerName?.length) {
        setCustomerGroupAddModal(true);
      }
    }
  };

  useEffect(() => {
    settedCaller();
    if (open && callerPhone && (!callerName || callerName?.length < 2)) {
      setCustomerGroupAddModal(true);
    }
  }, [callerPhone, isCallerIdScreen]);

  return (
    <Modal
      maxWidth="md"
      open={open}
      onClose={() => handleClose()}
      title={t('orderModal.customerModal.title')}
      positiveAction={t('orderModal.customerModal.confirm')}
      onPositiveClick={handleSubmit}
      extraActionOne={t('orderModal.customerModal.reset')}
      onExtraActionOneClick={handleReset}
      negativeAction={t('orderModal.customerModal.cancel')}
      onNegativeClick={() => handleClose()}
      headerButton={keyHasPermission(
        'create-kitchen-group',
        meProfile?.permissions || [],
      )}
      headerButtonIconName="Add"
      headerButtonText={t('orderModal.customerModal.add_customer')}
      onHeaderButtonClick={() => setCustomerGroupAddModal(true)}
    >
      <CustomerGroupAddModal
        open={customerGroupAddModal}
        onClose={() => {
          setCustomerGroupAddModal(false);
        }}
        onConfirm={() => {
          setCustomerGroupAddModal(false);
        }}
        callerPhone={callerPhone}
        setCustomerId={setCustomerId}
      />
      <FormSelectSearchable
        label={t('orderModal.customerModal.placeholder')}
        options={customers.map(customer => ({
          id: customer.id,
          text: `${customer.first_name} ${customer.last_name} | ${
            customer.phone_number || '-'
          }`,
        }))}
        value={customerId}
        onChange={id => handleCustomerChange(id)}
        loading={customerLoading}
        placeholder={t('orderModal.customerModal.placeholder')}
        onInputChange={onInputChange}
      />
      {customerId && (
        <CustomerAddressAddModal
          open={customerAddressAddModal}
          customerId={customerId}
          onClose={() => {
            setCustomerAddressAddModal(false);
          }}
        />
      )}
      {customerId &&
        !isTableOrder &&
        (customerAddressLoading ? (
          <LoadingState />
        ) : (
          <TableContainer sx={{ mt: 5 }}>
            <Box sx={styles.boxContainer}>
              <Typography>
                {t('orderModal.customerModal.addressPlaceholder')}
              </Typography>
              {hasCreatePermission && (
                <Tooltip title={t('orderModal.customerModal.add_adress')}>
                  <ButtonGroup
                    variant="outlined"
                    aria-label="outlined button group"
                  >
                    <IconButton
                      aria-label="Add"
                      color="primary"
                      onClick={() => setCustomerAddressAddModal(true)}
                    >
                      <Icon name="Add" />
                    </IconButton>
                  </ButtonGroup>
                </Tooltip>
              )}
            </Box>

            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('orderModal.customerModal.addressTable.select')}
                  </TableCell>
                  <TableCell>
                    {t('orderModal.customerModal.addressTable.name')}
                  </TableCell>
                  <TableCell>
                    {t('orderModal.customerModal.addressTable.address')}
                  </TableCell>
                  <TableCell>
                    {t('orderModal.customerModal.addressTable.note')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerAddresses.map(row => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Radio
                        checked={
                          (customerAddressId || settedCustomerAddressId) ===
                            row.id ||
                          (!customerAddressId.length && row.is_default)
                        }
                        onClick={() => setCustomerAddressId(row.id)}
                      />
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>{row.note || '-'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ))}
    </Modal>
  );
}

export default CustomerModal;
