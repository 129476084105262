/** Services */
import api from '../../api';

/** Types */
import { ApiSuccessResponse } from '../../types';
import {
  CreateResponse,
  ListResponse,
  PaymentListResponsive,
  PaymentList,
  UpdatePaidResponse,
  ViewResponse,
  UpdateResponse,
  DeleteResponse,
  ToggleAddress,
  ToggleOpenAccount,
  DeletePaidResponse,
} from './types';

type ListRes = ApiSuccessResponse<ListResponse>;
const list = (
  search: string,
  first_name: string,
  page: number,
  is_active?: boolean | null,
  is_open_account?: boolean | null,
  per_page?: number | null,
) =>
  api.get<ListRes>('/customers', {
    params: {
      filter: {
        search,
        is_active,
        is_open_account,
      },
      sort: {
        first_name,
      },
      page,
      per_page,
    },
  });

type ListPaymentRes = ApiSuccessResponse<PaymentListResponsive>;
const listPayment = (page: number, id: string) =>
  api.get<ListPaymentRes>(`/customers/${id}/payments`, {
    params: {
      page,
    },
  });

type ViewRes = ApiSuccessResponse<ViewResponse>;
const view = (id: string) => api.get<ViewRes>(`/customers/${id}`);

type CreatRes = ApiSuccessResponse<CreateResponse>;
const create = (
  first_name: string,
  last_name: string,
  phone_number: string,
  phone_number_country: string,
  is_open_account: boolean,
  chargeable_amount?: number | null,
) =>
  api.post<CreatRes>('/customers', {
    first_name,
    last_name,
    phone_number,
    phone_number_country,
    is_open_account,
    chargeable_amount,
  });
type CreatPaid = ApiSuccessResponse<CreatPaid>;
const payment = (
  customer_id: string,
  amount: string,
  payment_type_id: string,
) =>
  api.post<CreatPaid>(`/customers/${customer_id}/payments`, {
    amount,
    payment_type_id,
  });

type DeletePaidRes = ApiSuccessResponse<DeletePaidResponse>;
const paidRemove = (paid_id: string, customer_id: string) =>
  api.delete<DeletePaidRes>(`/customers/${customer_id}/payments/${paid_id}`);

type UpdatePaidRes = ApiSuccessResponse<UpdatePaidResponse>;
const updatePaid = (
  paid_id: string,
  customer_id: string,
  amount: string,
  payment_type_id: string,
) =>
  api.put<UpdatePaidRes>(`/customers/${customer_id}/payments/${paid_id}`, {
    paid_id,
    customer_id,
    amount,
    payment_type_id,
  });
type UpdateRes = ApiSuccessResponse<UpdateResponse>;
const update = (
  id: string,
  first_name: string,
  last_name: string,
  phone_number: string,
  is_active: boolean,
  phone_number_country: string,
  is_open_account: boolean,
  chargeable_amount?: number | null,
) =>
  api.put<UpdateRes>(`/customers/${id}`, {
    id,
    first_name,
    last_name,
    phone_number,
    is_active,
    phone_number_country,
    is_open_account,
    chargeable_amount,
  });

type ToggleAddressRes = ApiSuccessResponse<ToggleAddress>;
const toggleActive = (id: string) =>
  api.post<ToggleAddressRes>(`/customers/${id}/toggle-active`);

type ToggleOpenAccountRes = ApiSuccessResponse<ToggleOpenAccount>;
const toggleOpenActive = (id: string) =>
  api.post<ToggleOpenAccountRes>(`/customers/${id}/toggle-open-account`);

type DeleteRes = ApiSuccessResponse<DeleteResponse>;
const remove = (id: string) => api.delete<DeleteRes>(`/customers/${id}`);

export default {
  list,
  view,
  listPayment,
  create,
  payment,
  update,
  toggleActive,
  toggleOpenActive,
  remove,
  paidRemove,
  updatePaid,
};
