import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { isApiError } from '../../services/api/types';
import { useAppDispatch, useAppSelector } from '../../store';
import { getCategoryListThunk } from '../../store/slices/definitionsSlice/categories';
import api from '../../services/api';
import {
  addCategorySlice,
  moveUp,
  moveDown,
  removeCategorySlice,
  updateCategorySlice,
} from '../../store/slices/definitionsSlice/categories/categoriesSlice';

const useCategories = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const categories = useAppSelector(
    state => state.definitionsCategories.categories,
  );
  const categoryLoading = useAppSelector(
    state => state.definitionsCategories.loading,
  );

  const categoryError = useAppSelector(
    state => state.definitionsCategories.error,
  );

  const fetchCategories = () => dispatch(getCategoryListThunk());

  const addCategory = async (name: { [key: string]: string }, image: any) => {
    try {
      const response = await api.definitions.categories.create(name, image);
      const result = response.data.data;
      dispatch(addCategorySlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };
  const updateCategory = async ({
    id,
    name,
  }: {
    id: string;
    name: { [key: string]: string };
  }) => {
    try {
      const response = await api.definitions.categories.update({ id, name });
      const result = response.data.data;
      dispatch(updateCategorySlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const removeCategory = async (id: string) => {
    try {
      const response = await api.definitions.categories.remove(id);
      const result = response.data.data;
      dispatch(removeCategorySlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const moveUpCategory = async (id: string) => {
    try {
      await api.definitions.categories.moveUp(id);
      dispatch(moveUp({ id }));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const moveDownCategory = async (id: string) => {
    try {
      await api.definitions.categories.moveDown(id);
      dispatch(moveDown({ id }));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  return {
    categories,
    categoryLoading,
    categoryError,
    fetchCategories,
    addCategory,
    updateCategory,
    removeCategory,
    moveUpCategory,
    moveDownCategory,
  };
};
export default useCategories;
