import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { LAST_REGISTRATION_STEP } from '../services/api/auth/types';
import { registrationStepStorage } from '../services/tokenStorage';

function CompanyRegistrationRequired() {
  const location = useLocation();
  const step = registrationStepStorage.getToken();
  // Go to companyRegister if registration is not completed
  if (step && parseInt(step, 10) < LAST_REGISTRATION_STEP) {
    return (
      <Navigate to="/company-register" state={{ from: location }} replace />
    );
  }
  return <Outlet />;
}

export default CompanyRegistrationRequired;
