import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import styles from './styles';
import { MoneyIcon } from '../svg';

interface Props {
  totalPrice?: string;
  paidPrice?: string;
  full?: boolean;
  createdBy?: string;
  createdAt?: string;
  tableName?: string;
  paidAmount?: string;
}
function FullRestaurantTable({
  totalPrice,
  paidPrice,
  createdBy,
  createdAt,
  full,
  tableName,
  paidAmount,
}: Props) {
  const tableNameStyle =
    paidPrice === totalPrice
      ? { fontSize: '13px', color: 'black', display: 'flex' }
      : {
          fontSize: '13px',
          color: full ? 'black' : 'gray',
          display: 'flex',
        };

  const createdByStyle =
    paidPrice === totalPrice
      ? { fontSize: '10px', color: 'black', display: 'flex' }
      : {
          fontSize: '10px',
          color: full ? 'black' : 'gray',
          display: 'flex',
        };

  const createdAtStyle =
    paidPrice === totalPrice
      ? { fontSize: '10px', color: 'black' }
      : { fontSize: '10px', color: full ? 'black' : 'gray' };

  const totalPriceStyle =
    totalPrice === paidPrice && Number(paidAmount) > 0
      ? {
          fontSize: '18px',
          color: 'black',
          fontWeight: '700',
          textDecoration: 'line-through',
        }
      : {
          fontSize: '18px',
          color: full ? 'black' : 'gray',
          fontWeight: '700',
        };

  return (
    <Box>
      <Box sx={styles.leftTopCorner}>
        <Typography sx={tableNameStyle}>{tableName}</Typography>
        <Typography sx={createdByStyle}>{createdBy}</Typography>
      </Box>
      <Box sx={styles.leftBottomCorner}>
        <Typography sx={createdAtStyle}>{createdAt}</Typography>
      </Box>
      <Typography sx={totalPriceStyle}>
        {paidPrice !== '₺0,00' && totalPrice !== paidPrice
          ? `${totalPrice} | ${paidPrice}`
          : totalPrice}
      </Typography>
      {totalPrice === paidPrice && Number(paidAmount) > 0 && (
        <IconButton color="info" onMouseDown={e => e.stopPropagation()}>
          <MoneyIcon />
        </IconButton>
      )}
    </Box>
  );
}

export default FullRestaurantTable;
