import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../services/api';
import { getExpensePopularSlice } from './expensesSlice';

export const getExpenseListThunk = createAsyncThunk(
  'expense/list',
  async (
    params: {
      page?: number;
      date_range?: string;
      expense_category_id?: string[];
      day_id?: string;
      per_page?: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await api.operations.expenses.list(
        params.page,
        params.date_range,
        params.expense_category_id,
        params.day_id,
        params.per_page,
      );
      const result = response.data;
      dispatch(getExpensePopularSlice(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
