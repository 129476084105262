/** Dependencies */
import React, { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Grid, Input } from '@mui/material';
import { useTranslation } from 'react-i18next';

/** Components */
import Button from '../../../../../components/Button';
import Modal from '../../../../../components/Modal';
import LoadingState from '../../../../../components/LoadingState';
import Calculator from '../../../../../components/Calculator';
import ConfirmationModal from '../../../../../components/ConfirmationModal';

/** Hooks */
import usePosOrder from '../../../../../hooks/usePosOrder';
import useProfile from '../../../../../hooks/useProfile';

/** Helpers */
import { priceFormatter } from '../../../../../helpers/moneyFormatter';
import keyHasPermission from '../../../../../helpers/keyHasPermission';

/** Styles */
import styles from './styles';

interface Props {
  open: boolean;
  onClose: () => void;
  onRemove: () => void;
  onSubmit: (price: number) => void;
  onTableSelect: (discount_type: number, value: number) => void;
  tableId: string;
  discountTotal: number;
  priceTotal: number;
  currencyCode: string;
  purePrice: number;
  isPackageOrder: boolean;
  isSelfOrder: boolean;
  id: string;
}

function DiscountModal({
  open,
  onClose,
  onRemove,
  onSubmit,
  onTableSelect,
  tableId,
  discountTotal,
  priceTotal,
  currencyCode,
  purePrice,
  isPackageOrder,
  isSelfOrder,
  id,
}: Props) {
  const { t } = useTranslation('pos');
  const { meProfile } = useProfile();
  const { addOrderTransactionDiscount, removeOrderDiscount } = usePosOrder();
  const { enqueueSnackbar } = useSnackbar();

  const initialRef: any = null;
  const inputRef = useRef(initialRef);

  const [loading, setLoading] = useState(false);
  const [discountType, setDiscountType] = useState(2);
  const [value, setValue] = useState('0');
  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(
    null,
  );

  const hasRemoveDiscountButton = keyHasPermission(
    'remove-discount',
    meProfile?.permissions || [],
  );

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (open && !discountTotal) {
      setValue('0');
    }
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = async () => {
    // warnings
    if (Number(value) === 0) {
      return enqueueSnackbar(t('discount_modal.validation'), {
        variant: 'warning',
      });
    }
    if (Number(value) >= 100 && discountType === 1) {
      return enqueueSnackbar(`${t('discount_modal.discount_percentage')}`, {
        variant: 'warning',
      });
    }
    if (Number(value) > priceTotal && discountType === 2) {
      return enqueueSnackbar(t('discount_modal.total_price'), {
        variant: 'warning',
      });
    }

    if ((isPackageOrder || isSelfOrder) && !id) {
      onTableSelect(discountType, Number(value));
      onClose();
    } else {
      try {
        setLoading(true);

        await addOrderTransactionDiscount({
          order_transaction_id: tableId,
          discount_type: discountType,
          value: discountType === 1 ? Number(value) : Number(value) * 100,
        });

        enqueueSnackbar(t('discount_modal.successful'), {
          variant: 'success',
        });

        const price =
          discountType === 1
            ? (Number(value) * purePrice) / 100
            : Number(value);
        onSubmit(price);
        setValue('0');
        onClose();
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRemove = async () => {
    try {
      if (deleteConfirmation) {
        if (deleteConfirmation === 'self_or_package' && !id) {
          onTableSelect(0, 0);
        } else {
          setLoading(true);
          await removeOrderDiscount(
            deleteConfirmation === 'self_or_package'
              ? id || tableId
              : deleteConfirmation,
          );
        }
        enqueueSnackbar(t('discount_modal.successful'), {
          variant: 'success',
        });
        setDeleteConfirmation(null);
        onRemove();
        onClose();
      }
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event: { key: any }) => {
    if (
      value.split('').find(item => item === '.') &&
      value.split('.')[1].length >= 2
    ) {
      setValue(value);
    } else {
      if (Number(event.key)) {
        setValue(value === '0' ? event.key : value + event.key);
      }

      if (Number(event.key === '.')) {
        const hasDot = value.split('').find(item => item === '.') || false;
        setValue(hasDot ? value : `${value}.`);
      }

      if (Number(event.key === ',')) {
        const hasDot = value.split('').find(item => item === '.') || false;
        setValue(hasDot ? value : `${value}.`);
      }

      if (event.key === '0') {
        setValue(value === '0' ? '0' : `${value}0`);
      }
    }

    if (event.key === 'Backspace') {
      if (
        value
          .slice(-2)
          .split('')
          .find(item => item === '.')
      ) {
        setValue(value.slice(0, -2));
      } else {
        setValue(value.length === 1 ? '0' : value.slice(0, -1));
      }
    }
  };

  if (loading) {
    return <LoadingState />;
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      positiveAction={t('discount_modal.save')}
      onPositiveClick={() => {
        handleSubmit();
      }}
      negativeAction={t('discount_modal.close')}
      onNegativeClick={() => {
        setValue('0');
        handleClose();
      }}
      title={t('sections.apply')}
      maxWidth="xs"
    >
      <Grid
        tabIndex={0}
        container
        justifyContent="center"
        spacing={0}
        sx={styles.gridContainer}
      >
        <Grid container sx={{ pb: 2, justifyContent: 'space-between' }}>
          <Grid
            item
            md={6}
            xs={6}
            sx={{ px: 1, justifyContent: 'space-between' }}
          >
            <Button
              onClick={() => setDiscountType(2)}
              color="inherit"
              fullWidth
              sx={discountType === 2 ? styles.selectedButton : styles.button}
            >
              {t('discount_modal.amount')}
            </Button>
          </Grid>
          <Grid item md={6} xs={6} sx={styles.buttonsGridContainer}>
            <Button
              onClick={() => setDiscountType(1)}
              color="inherit"
              fullWidth
              sx={discountType === 1 ? styles.selectedButton : styles.button}
            >
              {t('discount_modal.percent')}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {discountTotal > 0 && (
            <Grid container>
              <Grid sx={styles.discountGridContainer}>
                {t('sections.discount')}:
              </Grid>
              <Grid>
                <Grid p={1}>
                  {priceFormatter(discountTotal / 100, currencyCode)}
                  {hasRemoveDiscountButton && (
                    <Button
                      variant="text"
                      color="error"
                      onClick={() => {
                        if (isPackageOrder || isSelfOrder) {
                          setDeleteConfirmation('self_or_package');
                        } else {
                          setDeleteConfirmation(tableId);
                        }
                      }}
                    >
                      {t('sections.delete')}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid sx={styles.amountGridContainer}>{t('sections.payable')}</Grid>
            <Grid p={1}>
              <Input
                autoFocus
                onKeyDown={handleKeyDown}
                value={
                  discountType === 1
                    ? `%${priceFormatter(Number(value), currencyCode).substring(
                        1,
                      )}`
                    : `₺${priceFormatter(Number(value), currencyCode).substring(
                        1,
                      )}`
                }
                disableUnderline
                sx={{ caretColor: 'transparent' }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item justifyContent="flex-end" xs={12}>
          <Calculator value={value} setValue={setValue} />
        </Grid>
      </Grid>
      <ConfirmationModal
        title={t('paymentModal.deleteConfirmationDiscount.title')}
        titleIconName="QuestionMarkOutlined"
        description={t('paymentModal.deleteConfirmationDiscount.description')}
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        positiveAction={t('paymentModal.deleteConfirmationDiscount.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('paymentModal.deleteConfirmationDiscount.cancel')}
        onNegativeClick={() => setDeleteConfirmation(null)}
        loading={loading}
      />
    </Modal>
  );
}

export default DiscountModal;
