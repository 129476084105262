import {
  Box,
  Typography,
  Autocomplete,
  AutocompleteProps,
  FormHelperText,
} from '@mui/material';
import React, { forwardRef } from 'react';
import FormInput from '../FormInput';

import styles from './styles';

type ItemBase = {
  id: string;
  text: string;
  group?: string;
};

// @ts-ignore
interface Props<T extends ItemBase>
  extends Omit<
    AutocompleteProps<T, false, false, false>,
    'renderInput' | 'onChange' | 'value'
  > {
  helperText?: string;
  placeholder?: string;
  label?: string;
  error?: boolean;
  onChange: (id: string) => void;
  value: string | undefined | null;
  handleReset?: () => void;
  onInputChange?: (input: string) => void;
}

function FormSelectSearchableComponent<T extends ItemBase>(
  {
    options,
    label,
    error,
    helperText,
    sx,
    onChange,
    value,
    placeholder,
    handleReset,
    onInputChange,
    ...props
  }: Props<T>,
  ref: Props<T>['ref'],
) {
  return (
    <Box
      sx={[styles.container, ...(sx ? (Array.isArray(sx) ? sx : [sx]) : [])]}
    >
      <Typography variant="subtitle1">{label}</Typography>
      <Autocomplete
        ref={ref}
        sx={styles.selectField}
        ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
        fullWidth
        autoComplete
        color="success"
        options={options}
        getOptionLabel={option => option.text}
        {...props}
        renderInput={params => (
          <FormInput {...params} error={error} placeholder={placeholder} />
        )}
        renderOption={(optionProps, option) => (
          <Box component="li" {...optionProps} key={option.id}>
            {option.text}
          </Box>
        )}
        defaultValue={null}
        value={options.find(item => item.id === value) || null}
        onChange={(_, element) => {
          if (element) {
            onChange(element.id);
          } else {
            // clear
            onChange('');
            if (handleReset) {
              handleReset();
            }
          }
        }}
        onInputChange={event => {
          if (onInputChange) {
            // @ts-ignore
            onInputChange(event?.target?.value);
          }
        }}
      />
      {helperText && (
        <FormHelperText sx={{ color: 'error.main' }}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
}

export default forwardRef(FormSelectSearchableComponent);
