import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { isApiError } from '../../services/api/types';
import {
  ChangePassword,
  UpdateProfile,
} from '../../services/api/profile/types';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  changePasswordThunk,
  setLanguageThunk,
  updateProfileThunk,
} from '../../store/slices/profileSlice';

const useProfile = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const meProfile = useAppSelector(state => state.auth.profile);

  const updateProfile = async (params: UpdateProfile) => {
    try {
      await dispatch(updateProfileThunk(params)).unwrap();

      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      throw err;
    }
  };

  const changePassword = async (params: ChangePassword) => {
    try {
      await dispatch(changePasswordThunk(params)).unwrap();
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      throw err;
    }
  };

  const setLanguage = (language: string) => {
    try {
      dispatch(setLanguageThunk(language)).unwrap();

      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      throw err;
    }
  };

  return {
    updateProfile,
    changePassword,
    setLanguage,
    meProfile,
  };
};

export default useProfile;
