import { SxProps, Theme } from '@mui/material';
import { POS_HEADER_HEIGHT } from '../styles';

const styles: Record<string, SxProps<Theme>> = {
  container: {
    width: { xs: '100%', sm: 700 },
    height: { xs: '100vh', sm: '100%' },
  },
  innerContainer: { maxHeight: '100vh', height: '100vh' },
  header: {
    backgroundColor: '#444',
    height: { xs: POS_HEADER_HEIGHT, md: 75 },
  },
  divider: { background: 'lightGrey' },
  icon: { color: 'white', width: { xs: POS_HEADER_HEIGHT, md: 75 } },
  search: { flex: 1, color: 'white', px: 2 },
  searchIcon: {
    width: 50,
    height: 50,
    color: 'white',
  },
  searchInput: { color: 'white', flex: 1 },
  basket: {
    flex: 1,
    py: 2,
    px: { xs: 3, sm: 1 },
    fontSize: { xs: '14px', sm: '13px', md: '16px' },
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray',
      borderRadius: 2,
    },
  },
  footer: {
    bottom: 0,
    width: '100%',
  },
  iconButton: {
    color: 'white',
    width: '75px',
    borderRight: '1px solid white',
  },
};

export default styles;
