import { SxProps, Theme } from '@mui/material';

const styles: Record<string, SxProps<Theme>> = {
  calculatorButton: {
    height: '70px',
    backgroundColor: 'white',
    border: '0.1px solid #DEDEDE',
    fontSize: '1.2rem',
  },
  button: {
    fontWeight: 'bold',
    backgroundColor: 'white',
    border: '0.1px solid #DEDEDE',
    px: 1,
  },
  selectedButton: {
    fontWeight: 'bold',
    border: '0.1px solid #DEDEDE',
    px: 1,
  },
  gridContainer: { '&:focus': { outline: 'none' }, outline: 'none' },
  buttonsGridContainer: { pb: 3, px: 1, justifyContent: 'space-between' },
  discountGridContainer: {
    color: '#26acf8',
    fontSize: 14,
    padding: '20px 1px 0px 0px',
  },
  amountGridContainer: {
    color: '#26acf8',
    fontSize: 14,
    padding: '13px 1px 0px 0px',
  },
};

export default styles;
