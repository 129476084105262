import {
  Box,
  TextField,
  Typography,
  TextFieldProps,
  Select,
  MenuItem,
  Stack,
  Divider,
  FormHelperText,
} from '@mui/material';
import React, { forwardRef } from 'react';
import { Control, Controller } from 'react-hook-form';
import styles from './styles';

type Option = { id: string; text: string };

interface Props {
  options: Option[];
  selectValue?: string;
  selectName?: string;
  selectControl?: Control<any, object>;
  onSelectValueChange?: (id: string) => void;
}

function FormInputSelect(
  {
    label,
    helperText,
    sx,
    options,
    selectValue,
    selectName,
    selectControl,
    onSelectValueChange,
    error,
    ...props
  }: Props & TextFieldProps,
  ref: TextFieldProps['ref'],
) {
  // @ts-ignore
  // eslint-disable-next-line react/no-unstable-nested-components
  const RenderSelect = React.memo(() => (
    <Select
      variant="filled"
      displayEmpty
      value={selectValue}
      sx={styles.selectField}
      SelectDisplayProps={{
        style: { paddingTop: 10, paddingBottom: 10 },
      }}
      onChange={event => {
        if (onSelectValueChange) onSelectValueChange(event.target.value);
      }}
      error={error}
      color="success"
    >
      {options.map(option => (
        <MenuItem key={option.id} value={option.id}>
          {option.text}
        </MenuItem>
      ))}
    </Select>
  ));

  return (
    <Box
      sx={[styles.container, ...(sx ? (Array.isArray(sx) ? sx : [sx]) : [])]}
    >
      <Typography variant="subtitle1">{label}</Typography>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <TextField
          ref={ref}
          variant="filled"
          hiddenLabel
          sx={styles.textField}
          fullWidth
          error={error}
          color="success"
          {...props}
        />
        {selectName && selectControl ? (
          <Controller
            name={selectName}
            control={selectControl}
            render={({ field, fieldState }) => (
              <Select
                variant="filled"
                sx={styles.selectField}
                SelectDisplayProps={{
                  style: { paddingTop: 10, paddingBottom: 10 },
                }}
                error={fieldState.invalid}
                displayEmpty
                color="success"
                {...field}
              >
                {options.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.text}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        ) : (
          <RenderSelect />
        )}
      </Stack>
      {helperText && (
        <FormHelperText sx={{ color: 'error.main' }}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
}

export default forwardRef(FormInputSelect);
