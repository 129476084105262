/** Dependencies */
import React, { useEffect, useMemo, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';

/** Components */
import Modal from '../../../../components/Modal';
import ExpenseForm from './ExpenseForm';

/** Types */
import {
  addValidationSchema,
  updateValidationSchema,
  ExpenseBase,
} from '../types';
import { isApiError } from '../../../../services/api/types';

/** Hooks */
import useExpenseCategories from '../../../../hooks/useExpenseCategories';
import useUtilities from '../../../../hooks/useUtilities';
import useExpenses from '../../../../hooks/useExpenses';
import { Expense } from '../../../../services/api/operations/expenses/types';
import useProfile from '../../../../hooks/useProfile';

/** Helpers */
import {
  OptionsMemo,
  OptionsCurrenciesMemo,
} from '../../../../helpers/optionMemo';

interface Props {
  open: boolean;
  onClose: () => void;
  item: Expense | null;
}

interface ExpenseCategoryOptionType {
  id: string;
  translations: any[];
}

function ExpenseAddModal({ open, onClose, item }: Props) {
  const { t } = useTranslation('user');
  const { addExpense, updateExpense } = useExpenses();
  const { enqueueSnackbar } = useSnackbar();
  const { meProfile } = useProfile();

  const { currencies } = useUtilities();
  const { expenseCategories } = useExpenseCategories();
  const [loading, setLoading] = useState(false);

  const initialDate = format(new Date(Date.now()), 'yyyy-MM-dd');

  const selectedCategoryId = expenseCategories[0]?.id;

  const initialValues = {
    description: item?.description || '',
    expense_category_id: item?.expense_category.id || selectedCategoryId,
    date: item?.date || initialDate || '',
    currency:
      currencies?.find(currency => currency?.code === item?.currency)?.id ||
      meProfile?.currency?.id ||
      currencies[0]?.id ||
      currencies[0]?.code,
    amount: Number(item?.amount?.amount) / 100 || null,
  };

  const { handleSubmit, control, reset } = useForm<ExpenseBase>({
    // @ts-ignore
    defaultValues: initialValues,
    resolver: item ? updateValidationSchema : addValidationSchema,
  });

  const handleConfirm = () => {
    try {
      setLoading(true);
      handleSubmit(async values => {
        try {
          if (item) {
            await updateExpense({
              ...values,
              amount: (values.amount || 0) * 100,
              expenseId: item.id,
              currency: currencies?.find(
                currencyItem =>
                  Number(currencyItem.id) === Number(values?.currency),
              )?.code,
            });
            enqueueSnackbar(t('user.success'), {
              variant: 'success',
            });
            onClose();
          } else {
            await addExpense({
              ...values,
              amount: (values.amount || 0) * 100,
              currency: currencies?.find(
                currencyItem =>
                  Number(currencyItem.id) === Number(values?.currency),
              )?.code,
            });
            enqueueSnackbar(t('user.success'), {
              variant: 'success',
            });
            onClose();
          }
          window.location.reload();
        } catch (err) {
          enqueueSnackbar(
            isApiError(err) ? err.message : t('common:unknownError' as any),
            {
              variant: 'error',
              style: { whiteSpace: 'pre-line' },
            },
          );
          throw err;
        }
      })();
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      // @ts-ignore
      reset(initialValues);
    }
  }, [open]);

  useEffect(() => {
    // @ts-ignore
    reset(initialValues);
  }, [selectedCategoryId]);

  return (
    <Modal
      title={item ? t('user.addModal.expense') : t('user.addModal.new_expense')}
      titleIconName={item ? 'Edit' : 'Add'}
      open={open}
      onClose={onClose}
      loading={loading}
      negativeAction={t('user.addModal.cancel')}
      positiveAction={item ? t('user.addModal.edit') : t('user.addModal.add')}
      onPositiveClick={handleConfirm}
      onNegativeClick={onClose}
    >
      <ExpenseForm
        control={control}
        selectedCategoryId={selectedCategoryId}
        roleOptions={OptionsMemo(expenseCategories)}
        currencyOptions={OptionsCurrenciesMemo(currencies)}
      />
    </Modal>
  );
}

export default ExpenseAddModal;
