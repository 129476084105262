/** Dependencies */
import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';

/** Styles */
import styles from '../styles';
import generalStyles from '../../../../../../../assets/stylesheets/generalStyles';

/** Components */
import {
  MenuProduct,
  Menus,
} from '../../../../../../../store/slices/basketItemSlice/types';
import { priceFormatter } from '../../../../../../../helpers/moneyFormatter';
import Icon from '../../../../../../../components/Icon';

interface Props {
  currencyCode: string;
  item: MenuProduct;
  productMenu: Menus;
  handleRemove: (
    basketItem: MenuProduct,
    menuId: string,
    itemId: string,
  ) => void;
}

function MenuModalLeftSideMenuItemsPriceAndButtons({
  currencyCode,
  item,
  productMenu,
  handleRemove,
}: Props) {
  const isActiveIngredients = (ingredientGroups: any) =>
    ingredientGroups.length
      ? ingredientGroups.some(
          (ingredientItem: any) =>
            ingredientItem.items.filter((propItem: any) => propItem.is_active)
              .length,
        )
      : false;

  const isActiveProperties = (propertyGroups: any) =>
    propertyGroups.length
      ? propertyGroups.some(
          (propertyItem: any) =>
            propertyItem.items.filter((propItem: any) => propItem.is_active)
              .length,
        )
      : false;

  return (
    <Box sx={generalStyles.generalDisplay}>
      <Typography
        sx={{
          ...styles.priceInfoUnderline,
          minWidth: '75px',
          textAlign: 'center',
        }}
      >
        {Number(item.additional_price.amount) > 0 ||
        (item?.total_price && item?.total_price > 0)
          ? `(+${priceFormatter(
              Number(
                Number(item.additional_price.amount) / 100 +
                  (item?.total_price || 0),
              ) * item.count,
              currencyCode,
            )})`
          : ''}
      </Typography>
      {(isActiveProperties(item.property_groups) ||
        isActiveIngredients(item.ingredient_groups)) && (
        <IconButton sx={styles.icon} onMouseDown={e => e.stopPropagation()}>
          <Icon name="List" />
        </IconButton>
      )}
      <IconButton
        sx={styles.icon}
        color="error"
        onMouseDown={e => e.stopPropagation()}
        onClick={e => {
          e.stopPropagation();
          handleRemove(item, productMenu.id, item.itemId || '');
        }}
      >
        <Icon name="Delete" />
      </IconButton>
    </Box>
  );
}

export default MenuModalLeftSideMenuItemsPriceAndButtons;
