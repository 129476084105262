/** Dependencies */
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import 'yup-phone';
import { IdAndName } from '../../../models/General';

export type UserBase = {
  id: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  phone_number_country?: string;
  password?: string;
  email: string;
  pin: number;
  role_id: string;
  section_ids: string[];
  sections?: any[];
  branches?: Branch[];
  is_caller_id_user?: boolean;
};

type Branch = {
  id: string;
  name: string;
  role: IdAndName;
};

export type OptionType = {
  code: any;
  id: string;
  name: string;
};

export type OptionsType = {
  id: string;
  text: string;
};

const addFormSchema = yup.object({
  first_name: yup.string().required('user.addModal.form.first_name.required'),
  last_name: yup.string().required('user.addModal.form.last_name.required'),
  phone_number: yup.string(),
  email: yup.string().email('user.addModal.form.email.invalid'),
  password: yup.string().required('user.addModal.form.password.required'),
  pin: yup
    .number()
    .integer()
    .required('user.addModal.form.pin.required')
    .min(1000, 'user.addModal.form.pin.min')
    .max(9999, 'user.addModal.form.pin.max'),
  role_id: yup.string().required('user.addModal.form.role.required'),
  section_ids: yup.array().required('user.addModal.form.section.required'),
  is_caller_id_user: yup.boolean(),
});

const updateFormSchema = yup.object({
  first_name: yup.string().required('user.addModal.form.first_name.required'),
  last_name: yup.string().required('user.addModal.form.last_name.required'),
  phone_number: yup.string(),
  email: yup.string().email('user.addModal.form.email.invalid'),
  password: yup.string(),
  pin: yup
    .number()
    .integer()
    .required('user.addModal.form.pin.required')
    .min(1000, 'user.addModal.form.pin.min')
    .max(9999, 'user.addModal.form.pin.max'),
  section_ids: yup.array().required('user.addModal.form.section.required'),
  is_caller_id_user: yup.boolean(),
});

export const addValidationSchema = yupResolver(addFormSchema);
export const updateValidationSchema = yupResolver(updateFormSchema);
