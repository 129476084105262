/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { Box, Grid } from '@mui/material';
import { matchIsValidTel } from 'mui-tel-input';

/** Types */
import { OptionsType, UserBase } from '../types';

/** Components */
import FormInput from '../../../../components/FormInput';
import FormPasswordInput from '../../../../components/FormPasswordInput';
import FormSelect from '../../../../components/FormSelect';
import FormPhoneInput from '../../../../components/FormPhoneInput';
import FormSwitch from '../../../../components/FormSwitch';
import useSettings from '../../../../hooks/useSettings';

interface Props {
  control: Control<UserBase, Object>;
  item: UserBase | null;
  roleOptions: OptionsType[];
  sectionOptions: OptionsType[];
  value: string;
  handleChange(newValue: any, info: any): void;
}

function UserForm({
  control,
  roleOptions,
  sectionOptions,
  item,
  value,
  handleChange,
}: Props) {
  const { t } = useTranslation('user');
  const { meProfile } = useSettings();

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="role_id"
            // @ts-ignore
            control={control}
            render={({ field, fieldState }) => (
              <FormSelect
                label={t('user.addModal.form.role.label')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                underline
                options={roleOptions}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="first_name"
            // @ts-ignore
            control={control}
            render={({ field, fieldState }) => (
              <FormInput
                label={t('user.addModal.form.first_name.label')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="last_name"
            // @ts-ignore
            control={control}
            render={({ field, fieldState }) => (
              <FormInput
                label={t('user.addModal.form.last_name.label')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="phone_number"
            // @ts-ignore
            control={control}
            rules={{ validate: matchIsValidTel }}
            render={({ field }) => (
              <FormPhoneInput
                label={t('user.addModal.form.phone_number.label')}
                phoneValue={value}
                handleChange={handleChange}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="email"
            // @ts-ignore
            control={control}
            render={({ field, fieldState }) => (
              <FormInput
                label={t('user.addModal.form.email.label')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="password"
            // @ts-ignore
            control={control}
            render={({ field, fieldState }) => (
              <FormPasswordInput
                label={
                  item
                    ? t('user.addModal.form.password.labelUpdate')
                    : t('user.addModal.form.password.labelAdd')
                }
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="pin"
            // @ts-ignore
            control={control}
            render={({ field, fieldState }) => (
              <FormInput
                label={t('user.addModal.form.pin.label')}
                error={fieldState.invalid}
                maskProps={{
                  min: 1000,
                  max: 9999,
                  maxLength: 4,
                }}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="section_ids"
            // @ts-ignore
            control={control}
            render={({ field, fieldState }) => (
              <FormSelect
                label={t('user.addModal.form.section.label')}
                error={fieldState.invalid}
                helperText={
                  fieldState.error
                    ? t(fieldState.error.message as any)
                    : undefined
                }
                multiple
                underline
                options={sectionOptions}
                {...field}
              />
            )}
          />
        </Grid>
        {meProfile?.installed_apps.find(app => app.slug === 'caller-id') && (
          <Grid item xs={12}>
            <Controller
              name="is_caller_id_user"
              // @ts-ignore
              control={control}
              render={({ field, fieldState }) => (
                <FormSwitch
                  label={t('user.addModal.form.callerId')}
                  error={fieldState.invalid}
                  {...field}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default UserForm;
