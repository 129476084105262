import {
  Tooltip,
  TooltipProps,
  IconButton,
  IconButtonProps,
} from '@mui/material';
import React from 'react';
import Icon, { IconName } from '../Icon';

interface Props extends IconButtonProps {
  iconName: IconName;
  tip?: string;
  tipPlacement?: TooltipProps['placement'];
}
function IconButtonWithTooltip({
  iconName,
  tip,
  tipPlacement,
  onClick,
  ...rest
}: Props) {
  return (
    <Tooltip title={tip || ''} placement={tipPlacement}>
      <IconButton onClick={onClick} {...rest}>
        <Icon name={iconName} />
      </IconButton>
    </Tooltip>
  );
}

export default IconButtonWithTooltip;
