import { Box, IconButton } from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import { grey } from '@mui/material/colors';
import Accountlanguage from './AccountLanguage';
import HelpCenter from './HelpCenter';

interface Props {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  token: string | null;
}
function MobileMenuButton({ onClick, token }: Props) {
  return (
    <Box sx={{ display: { xs: 'flex', md: 'none' }, color: grey[700] }}>
      <HelpCenter />
      <Accountlanguage />
      <IconButton
        size="large"
        aria-label="show more"
        aria-controls="mobileMenuId"
        aria-haspopup="true"
        onClick={onClick}
        color="inherit"
        disabled={Boolean(token)}
      >
        <MoreIcon />
      </IconButton>
    </Box>
  );
}

export default MobileMenuButton;
