/** Dependencies */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
  Grid,
  IconButton,
  MenuItem as MenuItemComponent,
  Pagination,
  Select,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Tooltip,
  Chip,
  Typography,
} from '@mui/material';

/** Components */
import LoadingState from '../../../../components/LoadingState';
import EmptyState from '../../../../components/EmptyState';
import StopWatch from '../../../../components/Timer/StopWatch';
import Icon from '../../../../components/Icon';

/** Helpers */
import getActiveLanguage from '../../../../helpers/getActiveLanguage';
import LazyImage from '../../../../helpers/LazımImage';
import keyHasPermission from '../../../../helpers/keyHasPermission';
import { singlePriceFormatter } from '../../../../helpers/moneyFormatter';

/** Hooks */
import useOrders from '../../../../hooks/useOrders';
import useProfile from '../../../../hooks/useProfile';

/** Styles */
import generalStyles from '../../../../assets/stylesheets/generalStyles';

/** Services */
import { GroupedDataColumnType } from '../../../../services/api/orders/types';

interface Props {
  selectActive: number;
  page: number;
  handleChange: (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => Promise<void>;
  handlePrintTable: (tableId: string) => Promise<void>;
  handleSetStatus: (
    table_id: string,
    status_id: number,
    isPickedUp?: boolean,
  ) => Promise<void>;
  setType: (value: ((prevState: number) => number) | number) => void;
  setOrder: (
    value:
      | ((
          prevState: GroupedDataColumnType | undefined,
        ) => GroupedDataColumnType | undefined)
      | GroupedDataColumnType
      | undefined,
  ) => void;
  setOrderId: (value: ((prevState: string) => string) | string) => void;
  setOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
  setDelivererModal: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
  setDelivererId: (value: ((prevState: string) => string) | string) => void;
  setStatusId: (value: ((prevState: number) => number) | number) => void;
  setFastPaymentModal: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
  setOpenLastTransactions: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
  isShowLastOrders: string;
  setIsShowLastOrders: (
    value: ((prevState: string) => string) | string,
  ) => void;
  isShowAddress: string;
  setIsShowAddress: (value: ((prevState: string) => string) | string) => void;
  groupedOrders: GroupedDataColumnType[] | null;
  meta: any;
}

function OrdersTableHeader({
  selectActive,
  page,
  handleChange,
  handlePrintTable,
  handleSetStatus,
  setType,
  setOrder,
  setOrderId,
  setOpen,
  setDelivererModal,
  setDelivererId,
  setStatusId,
  setFastPaymentModal,
  setOpenLastTransactions,
  isShowLastOrders,
  setIsShowLastOrders,
  isShowAddress,
  setIsShowAddress,
  groupedOrders,
  meta,
}: Props) {
  const { t } = useTranslation('orders');
  const { groupedOrdersLoading, getOrderLastTransactions } = useOrders();
  const navigate = useNavigate();
  const { meProfile } = useProfile();

  const hasSetDelivererPermission = keyHasPermission(
    'set-order-transaction-deliverer',
    meProfile?.permissions || [],
  );

  const selectedOptions = (status_id: number, type_id: number) => {
    if (type_id === 3) {
      if (status_id === 5) {
        return [
          { id: 1, text: t('order.placed') },
          { id: 2, text: t('order.preparing') },
          { id: 3, text: t('order.ready') },
          { id: 5, text: t('order.picked_up') },
          { id: 16, text: t('order.completed') },
        ];
      }

      return [
        { id: 1, text: t('order.placed') },
        { id: 2, text: t('order.preparing') },
        { id: 3, text: t('order.ready') },
        { id: 5, text: t('order.picked_up') },
      ];
    }

    if (type_id === 2) {
      if (status_id === 3) {
        return [
          { id: 1, text: t('order.placed') },
          { id: 2, text: t('order.preparing') },
          { id: 3, text: t('order.ready') },
          { id: 16, text: t('order.completed') },
        ];
      }

      return [
        { id: 1, text: t('order.placed') },
        { id: 2, text: t('order.preparing') },
        { id: 3, text: t('order.ready') },
      ];
    }

    return [
      { id: 1, text: t('order.placed') },
      { id: 2, text: t('order.preparing') },
      { id: 3, text: t('order.ready') },
      { id: 5, text: t('order.picked_up') },
    ];
  };

  const getStatusColor = (status_id: number) => {
    switch (status_id) {
      case 1:
        return '#fbc02d';
      case 2:
        return '#f9a825';
      case 3:
        return '#f57f17';
      case 5:
        return '#00e676';
      case 16:
        return '#00c853';
      default:
        return '#00c853';
    }
  };

  return (
    <Grid>
      {groupedOrdersLoading ? (
        <Grid alignItems="center" display="flex" justifyContent="center">
          <LoadingState />
        </Grid>
      ) : !groupedOrders?.length ? (
        <Grid alignItems="center" display="flex" justifyContent="center">
          <EmptyState message={t('order.no_order')} />
        </Grid>
      ) : (
        <Table
          sx={{ minWidth: 781, overflowX: 'auto' }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">{t('order.table.time')}</TableCell>
              <TableCell align="center">{t('order.table.details')}</TableCell>
              <TableCell align="center">
                {t('order.table.customer_details')}
              </TableCell>
              <TableCell align="center">
                {t('order.table.amount_type')}
              </TableCell>
              <TableCell align="center">{t('order.table.status')}</TableCell>
              <TableCell align="center">
                {t('order.table.operations')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedOrders?.map(item => (
              <TableRow>
                <TableCell align="center">
                  {selectActive === 0 ? (
                    <StopWatch
                      duration={item.created_at}
                      color="rgb(0, 164, 16)"
                      backgroundColor="white"
                    />
                  ) : (
                    <div>
                      {item.ended_at
                        ? format(new Date(item.ended_at), 'dd.MM.yyyy HH:mm')
                        : '_'}
                    </div>
                  )}
                </TableCell>
                <TableCell>
                  <Grid container justifyContent="center" alignItems="center">
                    {item.integrator.logo && (
                      <Grid mr={1}>
                        <LazyImage src={item.integrator.logo} width="40" />
                      </Grid>
                    )}
                    <Grid>
                      <Grid
                        xs={12}
                        sx={{
                          textAlign: 'center',
                          fontSize: '14px',
                          fontWeight: '500',
                        }}
                      >
                        Sipariş No: {item.transaction_number}
                      </Grid>
                      <Grid
                        xs={12}
                        sx={{
                          ...generalStyles.generalDisplay,
                          textAlign: 'center',
                          fontSize: '13px',
                        }}
                      >
                        {item.type}
                      </Grid>
                      <Grid
                        sx={{
                          color: '#d32f2f',
                          ...generalStyles.generalDisplay,
                          textAlign: 'center',
                          fontSize: '13px',
                          cursor: 'pointer',
                          mt: 1,
                        }}
                        onClick={() => {
                          if (item.type_id === 3) {
                            navigate(`/app/pos/order/package/${item.id}`, {
                              state: {
                                tableName: null,
                                full: false,
                              },
                            });
                          }
                          if (item.type_id === 2) {
                            navigate(`/app/pos/order/self/${item.id}`, {
                              state: {
                                tableName: null,
                                full: false,
                              },
                            });
                          }
                        }}
                      >
                        {t('order.order_details')}
                      </Grid>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="center">
                  {item.customer ? (
                    <Grid>
                      <Grid container>
                        {item.customer.order_transactions_count === 1 && (
                          <Grid
                            xs={12}
                            sx={{
                              ...generalStyles.generalDisplay,
                              textAlign: 'center',
                              fontSize: '13px',
                              marginBottom: '4px',
                            }}
                          >
                            <Chip
                              sx={{ height: '20px', fontSize: '12px' }}
                              label="Yeni Müşteri"
                              color="success"
                            />
                          </Grid>
                        )}
                        <Grid
                          xs={12}
                          sx={{
                            ...generalStyles.generalDisplay,
                            textAlign: 'center',
                            fontSize: '13px',
                          }}
                        >
                          {`${item?.customer?.first_name || ''} ${
                            item?.customer?.last_name || ''
                          }`}
                        </Grid>
                        <Grid
                          xs={12}
                          sx={{
                            ...generalStyles.generalDisplay,
                            textAlign: 'center',
                            fontSize: '13px',
                          }}
                        >
                          {item?.customer?.phone_number || ''}
                        </Grid>
                      </Grid>
                      <Grid
                        sx={{
                          color: '#d32f2f',
                          ...generalStyles.generalDisplay,
                          textAlign: 'center',
                          fontSize: '13px',
                          cursor: 'pointer',
                          marginTop: '10px',
                        }}
                        onClick={() =>
                          setIsShowAddress(
                            isShowAddress === item.id ? '' : item.id,
                          )
                        }
                      >
                        {t('order.address')}
                      </Grid>
                      {isShowAddress === item.id && (
                        <Grid sx={{ fontSize: '12px' }} mt={1}>
                          {item.customer.address || t('order.no_address')}
                        </Grid>
                      )}
                    </Grid>
                  ) : (
                    '_'
                  )}
                </TableCell>
                <TableCell align="center">
                  <Grid
                    xs={12}
                    sx={{
                      ...generalStyles.generalDisplay,
                      textAlign: 'center',
                      fontSize: '13px',
                      marginTop: '15px',
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        ...generalStyles.generalDisplay,
                        textAlign: 'center',
                        fontSize: '13px',
                        cursor: 'pointer',
                      }}
                    >
                      {item.payments && item.payments.length > 0 ? (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            ...generalStyles.generalDisplay,
                            textAlign: 'center',
                            fontSize: '13px',
                            cursor: 'pointer',
                          }}
                        >
                          {item.payments.map(item2 => (
                            <>
                              {
                                getActiveLanguage(
                                  item2.payment_type.translations,
                                ).name
                              }
                              ({item2.amount.formatted})
                              <br />
                            </>
                          ))}
                        </Grid>
                      ) : (
                        item.preset_payment_type && (
                          <Grid
                            item
                            xs={12}
                            sx={{
                              ...generalStyles.generalDisplay,
                              textAlign: 'center',
                              fontSize: '13px',
                              cursor: 'pointer',
                            }}
                          >
                            {
                              getActiveLanguage(
                                item?.preset_payment_type?.translations,
                              ).name
                            }
                          </Grid>
                        )
                      )}
                      <Grid
                        item
                        sx={{
                          ...generalStyles.generalDisplay,
                          textAlign: 'center',
                          fontSize: '13px',
                          cursor: 'pointer',
                          marginTop: '5px',
                        }}
                      >
                        <Grid container>
                          <Grid>
                            {singlePriceFormatter(
                              (
                                Number(item.total_amount.amount) -
                                Number(item.total_discount.amount)
                              ).toString(),
                              1,
                              item.total_amount.currency || 'TRY',
                            ) || '₺0,00'}
                          </Grid>{' '}
                          {Number(item.paid_amount.amount) > 0 && (
                            <Grid color="#d32f2f" ml={1}>
                              | {item.paid_amount.formatted}
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      color: '#d32f2f',
                      ...generalStyles.generalDisplay,
                      textAlign: 'center',
                      fontSize: '13px',
                      cursor: 'pointer',
                      marginTop: '10px',
                    }}
                    onClick={() => {
                      if (item.customer) {
                        getOrderLastTransactions(item.customer.id);
                      }
                      setIsShowLastOrders(
                        isShowLastOrders === item.id ? '' : item.id,
                      );
                      setOrder(item);
                      setOpenLastTransactions(true);
                    }}
                  >
                    {t('order.past_order_information')}
                  </Grid>
                </TableCell>
                <TableCell align="center">
                  {selectActive === 0 ? (
                    <>
                      <Select
                        variant="filled"
                        SelectDisplayProps={{
                          style: { paddingTop: 10, paddingBottom: 10 },
                        }}
                        sx={{
                          borderRadius: '20px',
                          background: getStatusColor(item.status_id),
                          color: 'white',
                          '.MuiSvgIcon-root ': {
                            color: 'white !important',
                          },
                          '&:hover': {
                            background: '#26acf8',
                          },
                          '& [aria-expanded=true]': {
                            background: getStatusColor(item.status_id),
                            borderRadius: '20px',
                          },
                          '& [aria-expanded=false]': {
                            background: getStatusColor(item.status_id),
                            borderRadius: '20px',
                          },
                          height: '30px',
                          fontSize: '12px',
                        }}
                        displayEmpty
                        disableUnderline
                        value={item.status_id}
                      >
                        {selectedOptions(item.status_id, item.type_id).map(
                          option => (
                            <MenuItemComponent
                              onClick={event => {
                                if (option.id === 5) {
                                  if (!item.deliverer) {
                                    setOrderId(item.id);
                                    setStatusId(option.id);
                                    setDelivererModal(true);
                                  } else {
                                    handleSetStatus(item.id, 5);
                                  }
                                } else if (option.id === 16) {
                                  if (!item.is_paid) {
                                    setOrderId(item.id);
                                    setOrder(item);
                                    setOpen(true);
                                    setFastPaymentModal(true);
                                  } else {
                                    handleSetStatus(item.id, 16);
                                  }
                                } else {
                                  handleSetStatus(item.id, option.id);
                                }
                              }}
                              disabled={option.id === item.status_id}
                              key={option.id}
                              value={option.id}
                            >
                              {option.text}
                            </MenuItemComponent>
                          ),
                        )}
                      </Select>
                      {item.deliverer && (
                        <Typography
                          sx={{
                            fontSize: '13px',
                            fontWeight: '500',
                            mt: 1,
                          }}
                        >
                          Kurye: {item.deliverer.full_name}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Chip
                      sx={{ height: '25px', fontSize: '12px' }}
                      label={
                        selectActive === 1
                          ? t('order.completed')
                          : t('order.cancelled')
                      }
                      color="primary"
                    />
                  )}
                </TableCell>
                <TableCell>
                  <Grid container mt={1} justifyContent="space-around">
                    <Tooltip title={t('order.details.print')} arrow>
                      <IconButton
                        sx={generalStyles.icon}
                        onMouseDown={e => e.stopPropagation()}
                        onClick={() => handlePrintTable(item.id)}
                      >
                        <Icon name="Print" color="error" />
                      </IconButton>
                    </Tooltip>
                    {hasSetDelivererPermission &&
                      (item?.status_id === 1 || item?.status_id === 2) &&
                      item?.type_id === 3 &&
                      !item?.deliverer && (
                        <Tooltip
                          title={t('order.settings.select_deliverer')}
                          arrow
                        >
                          <IconButton
                            sx={generalStyles.icon}
                            onMouseDown={e => e.stopPropagation()}
                            onClick={() => {
                              setStatusId(item.status_id);
                              setOrderId(item?.id);
                              setOrder(item);
                              setDelivererId(item?.deliverer?.id || '');
                              setDelivererModal(true);
                            }}
                          >
                            <Icon name="TwoWheeler" color="error" />
                          </IconButton>
                        </Tooltip>
                      )}
                    {selectActive === 0 && (
                      <Tooltip
                        title={t('order.details.fast_transactions')}
                        arrow
                      >
                        <IconButton
                          sx={generalStyles.icon}
                          onMouseDown={e => e.stopPropagation()}
                          onClick={() => {
                            setOpen(true);
                            setOrderId(item.id);
                            setOrder(item);
                            setType(item.status_id);
                          }}
                        >
                          <Icon name="MoreHoriz" color="error" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <Pagination
            sx={{ margin: '10px' }}
            count={Math.ceil(
              // @ts-ignore
              meta.total / meta.per_page,
            )}
            page={page}
            onChange={handleChange}
          />
        </Table>
      )}
    </Grid>
  );
}

export default OrdersTableHeader;
