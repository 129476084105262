import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import Modal from '../../../components/Modal';
import { AppPaymentItem } from '../../../services/api/utilities/types';

interface Props {
  open: boolean;
  onClose: () => void;
  paymentItems: AppPaymentItem[];
}
function DetailModal({ open, onClose, paymentItems }: Props) {
  const { t } = useTranslation('paymentHistory');

  const handleClose = () => {
    onClose();
  };

  const tableHead = [
    t('paymentItem.app_name'),
    t('paymentItem.billing_cycle_month'),
    t('paymentItem.total_amount'),
  ];

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title=""
      positiveAction={t('paymentItem.submit')}
      onPositiveClick={handleClose}
      maxWidth="md"
    >
      <Paper>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('paymentItem.app_name')}</TableCell>
                <TableCell>{t('paymentItem.billing_cycle_month')}</TableCell>
                <TableCell>{t('paymentItem.total_amount')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ cursor: 'pointer' }}>
              {paymentItems?.map(row => (
                <TableRow key={row.app.id}>
                  <TableCell component="th" scope="row">
                    {row?.app?.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row?.billing_cycle_month} {t('paymentItem.month')}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row?.total_amount?.formatted}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Modal>
  );
}

export default DetailModal;
