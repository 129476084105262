import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { getCustomerAddressListThunk } from '../../store/slices/definitionsSlice/customerAddresses';
import { CustomerAddressBase } from '../../pages/definitions/CustomerDetails/types';
import api from '../../services/api';
import {
  addCustomerAddressSlice,
  removeCustomerAddressSlice,
  setDefaultCustomerAddressSlice,
} from '../../store/slices/definitionsSlice/customerAddresses/customerAddressesSlice';
import { isApiError } from '../../services/api/types';

const useCustomerAddress = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const customerAddresses = useAppSelector(
    state => state.definitionsCustomerAddresses.customerAddresses,
  );
  const customerAddressLoading = useAppSelector(
    state => state.definitionsCustomerAddresses.loading,
  );
  const customerAddressError = useAppSelector(
    state => state.definitionsCustomerAddresses.error,
  );

  const fetchCustomerAddresses = (id: string) =>
    dispatch(getCustomerAddressListThunk(id));

  const addCustomerAddress = async (params: CustomerAddressBase) => {
    try {
      const response = await api.definitions.customerAddresses.create(params);
      const result = response.data.data;
      dispatch(addCustomerAddressSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const removeCustomerAddress = async (
    address_id: string,
    customer_id: string,
  ) => {
    try {
      const response = await api.definitions.customerAddresses.remove(
        address_id,
        customer_id,
      );
      const result = response.data.data;
      dispatch(removeCustomerAddressSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const setDefaultCustomerAddress = async (
    address_id: string,
    customer_id: string,
  ) => {
    try {
      await api.definitions.customerAddresses.setDefault(
        address_id,
        customer_id,
      );
      const response = await api.definitions.customerAddresses.list(
        customer_id,
      );
      const result = response.data.data;
      dispatch(setDefaultCustomerAddressSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  return {
    customerAddresses,
    customerAddressLoading,
    customerAddressError,
    fetchCustomerAddresses,
    addCustomerAddress,
    removeCustomerAddress,
    setDefaultCustomerAddress,
  };
};
export default useCustomerAddress;
