import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import en from '../locales/en';
import tr from '../locales/tr';

type Resource = typeof en;

export type Language = 'en' | 'tr';

const resources: Record<Language, Resource> = {
  en,
  tr,
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
