/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';

/** Styles */
import {
  Grid,
  Pagination,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from '@mui/material';

/** Components */
import UtilitiesBar, {
  Props as BarProps,
} from '../../../components/UtilitiesBar';
import InfoCard from '../../../components/Card/InfoCard';
import useReports from '../../../hooks/useReports';
import useExpenses from '../../../hooks/useExpenses';
import EmptyState from '../../../components/EmptyState';
import TextModal from '../OperationLogs/TextModal';

const MENU: BarProps['menu'] = [
  { id: 0, text: 'endOfDays.details.back', startIconName: 'Back' },
];

function EndOfDayWizard() {
  const { t } = useTranslation('endOfDays');
  const navigate = useNavigate();
  const { dayId } = useParams();
  const theme = useTheme();

  const {
    dailyReports,
    mostPopularMeta,
    mostPopular,
    paymentTypeReports,
    fetchDailyReports,
    fetchMostPopular,
    fetchPaymentTypeReports,
  } = useReports();
  const { expensesMeta, expenses, fetchExpenses } = useExpenses();
  const [textOpenModal, setTextOpenModal] = useState(false);
  const [textModal, setTextModal] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const [page, setPage] = useState(1);
  const [pageExpense, setPageExpense] = useState(1);
  const [isSorted, setIsSorted] = useState(false);

  const handleChange = async (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    if (value !== page && dayId) {
      setPage(value);
      await fetchMostPopular(dayId, value, undefined, undefined, undefined, 10);
    }
  };
  const handleChangeExpense = async (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    if (value !== pageExpense && dayId) {
      setPageExpense(value);
      await fetchExpenses(value, undefined, undefined, dayId, 10);
    }
  };

  const handleSort = async () => {
    setIsSorted(!isSorted);

    if (dayId) {
      await fetchMostPopular(
        dayId,
        page,
        isSorted ? '-total_quantity' : 'total_quantity',
        undefined,
        undefined,
        10,
      );
    }
  };

  useEffect(() => {
    if (dayId) {
      fetchDailyReports(dayId);
      fetchMostPopular(dayId, undefined, undefined, undefined, undefined, 10);
      fetchPaymentTypeReports(undefined, undefined, dayId);
      fetchExpenses(undefined, undefined, undefined, dayId, 10);
    }
  }, []);

  return (
    <div>
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('title')}
        leftIconName="SupervisorAccount"
        menu={MENU!.map(item => ({ ...item, text: t(item.text as any) }))}
        onActionClick={() => navigate('/reports/end-of-day')}
      />
      <Stack>
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
          {/* row 1 */}
          <Grid item xs={12} sx={{ mb: -2.25 }}>
            <Typography variant="h5" />
          </Grid>
          <br />
          <InfoCard
            title={t('endOfDays.details.guestCount')}
            count={dailyReports.guest_count.toString()}
            extra="20.000"
          />
          <InfoCard
            title={t('endOfDays.details.totalOrderAmount')}
            count={dailyReports.total_order_amount.formatted}
            extra="20.000"
            // isShowTrend
          />
          <InfoCard
            title={t('endOfDays.details.totalExpenseAmount')}
            count={dailyReports.total_expense_amount.formatted}
            extra="20.000"
            // isShowTrend
          />
          <InfoCard
            title={t('endOfDays.details.totalProductCostAmount')}
            count={dailyReports.total_cost_amount.formatted}
            extra="20.000"
            // isShowTrend
          />
          <InfoCard
            title={t('endOfDays.details.orderTransactionCount')}
            count={dailyReports.order_transaction_count.toString()}
            extra="20.000"
          />

          <Grid
            item
            md={8}
            sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }}
          />
        </Grid>
        <Grid mt={5} container rowSpacing={4.5} columnSpacing={2.75}>
          <Grid item xs={12} md={7} lg={8}>
            <Item
              sx={{
                padding: 3,
                border: '1px solid',
                borderRadius: 2,
                backgroundColor: 'white',
                borderColor: theme.palette.divider,
                boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
                '& pre': {
                  m: 0,
                  p: '16px !important',
                  fontFamily: theme.typography.fontFamily,
                  fontSize: '0.75rem',
                },
                minHeight: 822,
                overflow: 'auto',
              }}
            >
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item sx={{ textAlign: 'start' }}>
                  <Grid container>
                    <Typography variant="h5">
                      {t('endOfDays.details.detailTable.title')}
                      <Grid sx={{ fontSize: '12px', textAlign: 'start' }}>
                        (
                        {dailyReports?.day_start_date &&
                          format(
                            new Date(dailyReports?.day_start_date),
                            'dd.MM.yyyy HH:mm',
                          )}
                        {` - ${
                          dailyReports?.day_end_date
                            ? format(
                                new Date(dailyReports?.day_end_date),
                                'dd.MM.yyyy HH:mm',
                              )
                            : dailyReports?.day_start_date &&
                              format(new Date(Date.now()), 'dd.MM.yyyy HH:mm')
                        }`}
                        )
                      </Grid>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item />
              </Grid>
              <br />
              {!mostPopular.length ? (
                <EmptyState
                  message={t('endOfDays.details.detailTable.empty')}
                />
              ) : (
                <TableContainer>
                  <Table
                    sx={{ width: '100%', overflowX: 'auto' }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {t('endOfDays.details.detailTable.name')}
                        </TableCell>
                        <TableCell onClick={handleSort}>
                          <TableSortLabel
                            active
                            direction={isSorted ? 'asc' : 'desc'}
                          >
                            {t('endOfDays.details.detailTable.quantity')}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          {t('endOfDays.details.detailTable.unit')}
                        </TableCell>
                        <TableCell>
                          {t('endOfDays.details.detailTable.unitPrice')}
                        </TableCell>
                        <TableCell>
                          {t('endOfDays.details.detailTable.cost')}
                        </TableCell>
                        <TableCell>
                          {t('endOfDays.details.detailTable.totalAmount')}
                        </TableCell>
                        <TableCell>
                          {t('endOfDays.details.detailTable.rate')}(%)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mostPopular.map((row, index) => (
                        <TableRow
                          key={row?.product?.id ? row.product.id : index}
                        >
                          <TableCell>
                            {row?.product?.id
                              ? row?.product?.translations[0]?.name
                              : row.product}
                          </TableCell>
                          <TableCell>{row?.total_quantity}</TableCell>
                          <TableCell>
                            {row?.unit?.translations[0]?.name}
                          </TableCell>
                          <TableCell>{row?.unit_price?.formatted}</TableCell>
                          <TableCell>{row?.cost?.formatted}</TableCell>
                          <TableCell>{row?.total_amount?.formatted}</TableCell>
                          <TableCell>
                            {(
                              (Number(row?.total_amount?.amount) /
                                100 /
                                (Number(
                                  dailyReports?.total_order_amount?.amount,
                                ) /
                                  100)) *
                              100
                            ).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {Math.ceil(
                    // @ts-ignore
                    mostPopularMeta.total / mostPopularMeta.per_page,
                  ) > 1 && (
                    <Pagination
                      sx={{ margin: '10px' }}
                      count={Math.ceil(
                        // @ts-ignore
                        mostPopularMeta.total / mostPopularMeta.per_page,
                      )}
                      page={page}
                      onChange={handleChange}
                    />
                  )}
                </TableContainer>
              )}
            </Item>
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <Item
              sx={{
                padding: 3,
                border: '1px solid',
                borderRadius: 2,
                backgroundColor: 'white',
                borderColor: theme.palette.divider,
                boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
                '& pre': {
                  m: 0,
                  p: '16px !important',
                  fontFamily: theme.typography.fontFamily,
                  fontSize: '0.75rem',
                },
                minHeight: 822,
                overflow: 'auto',
              }}
            >
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item sx={{ textAlign: 'start' }}>
                  <Grid container>
                    <Typography variant="h5">
                      {t('endOfDays.details.payment_table.title')}
                      <Grid sx={{ fontSize: '12px', textAlign: 'start' }}>
                        (
                        {dailyReports?.day_start_date &&
                          format(
                            new Date(dailyReports?.day_start_date),
                            'dd.MM.yyyy HH:mm',
                          )}
                        {` - ${
                          dailyReports?.day_end_date
                            ? format(
                                new Date(dailyReports?.day_end_date),
                                'dd.MM.yyyy HH:mm',
                              )
                            : format(new Date(Date.now()), 'dd.MM.yyyy HH:mm')
                        }`}
                        )
                      </Grid>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <br />
              {!paymentTypeReports.length ? (
                <EmptyState
                  message={t('endOfDays.details.payment_table.empty')}
                />
              ) : (
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {t('endOfDays.details.payment_table.type')}
                        </TableCell>
                        <TableCell>
                          {t('endOfDays.details.payment_table.totalAmount')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paymentTypeReports.map(row => (
                        <TableRow>
                          <TableCell>
                            {row.payment_type.translations[0].name}
                          </TableCell>
                          <TableCell>{row.total_amount.formatted}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item
              sx={{
                padding: 3,
                border: '1px solid',
                borderRadius: 2,
                backgroundColor: 'white',
                borderColor: theme.palette.divider,
                boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
                '& pre': {
                  m: 0,
                  p: '16px !important',
                  fontFamily: theme.typography.fontFamily,
                  fontSize: '0.75rem',
                },
                minHeight: 950,
                overflow: 'auto',
              }}
            >
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Grid container sx={{ textAlign: 'start' }}>
                    <Typography variant="h5">
                      {t('endOfDays.details.expense_table.title')}
                      <Grid sx={{ fontSize: '12px', textAlign: 'start' }}>
                        (
                        {dailyReports?.day_start_date &&
                          format(
                            new Date(dailyReports?.day_start_date),
                            'dd.MM.yyyy HH:mm',
                          )}
                        {` - ${
                          dailyReports?.day_end_date
                            ? format(
                                new Date(dailyReports?.day_end_date),
                                'dd.MM.yyyy HH:mm',
                              )
                            : dailyReports?.day_start_date &&
                              format(new Date(Date.now()), 'dd.MM.yyyy HH:mm')
                        }`}
                        )
                      </Grid>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item />
              </Grid>
              <br />
              {!expenses.length ? (
                <EmptyState
                  message={t('endOfDays.details.expense_table.empty')}
                />
              ) : (
                <TableContainer>
                  <Table
                    sx={{ width: '100%', overflowX: 'auto' }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {t('endOfDays.details.expense_table.type')}
                        </TableCell>
                        <TableCell>
                          {t('endOfDays.details.expense_table.detail')}
                        </TableCell>
                        <TableCell>
                          {t('endOfDays.details.expense_table.date')}
                        </TableCell>
                        <TableCell>
                          {t('endOfDays.details.expense_table.amount')}
                        </TableCell>
                        <TableCell>
                          {t('endOfDays.details.expense_table.currency_code')}
                        </TableCell>
                        <TableCell>
                          {t('endOfDays.details.expense_table.added_customer')}
                        </TableCell>
                        <TableCell>
                          {t('endOfDays.details.expense_table.created_date')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {expenses.map(row => (
                        <TableRow key={row.id}>
                          <TableCell>
                            {row.expense_category.translations[0].name}
                          </TableCell>
                          <TableCell
                            style={{
                              maxWidth: '300px',
                              minWidth: '100px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setTextOpenModal(true);
                              setTextModal(row?.description);
                            }}
                          >
                            {row.description?.length > 30
                              ? `${row?.description?.substring(0, 30)}...`
                              : row?.description || '-'}
                          </TableCell>
                          <TableCell>
                            {format(new Date(row.date), 'dd.MM.yyyy')}
                          </TableCell>
                          <TableCell>{row.amount.formatted}</TableCell>
                          <TableCell>{row.currency}</TableCell>
                          <TableCell>{row.created_by.full_name}</TableCell>
                          <TableCell>
                            {format(
                              new Date(row.created_at),
                              'dd.MM.yyyy HH:mm',
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {Math.ceil(
                    // @ts-ignore
                    expensesMeta.total / expensesMeta.per_page,
                  ) > 1 && (
                    <Pagination
                      sx={{ margin: '10px' }}
                      count={Math.ceil(
                        // @ts-ignore
                        expensesMeta.total / expensesMeta.per_page,
                      )}
                      page={pageExpense}
                      onChange={handleChangeExpense}
                    />
                  )}
                </TableContainer>
              )}
            </Item>
          </Grid>
        </Grid>
      </Stack>
      <TextModal
        open={textOpenModal}
        onClose={() => {
          setTextOpenModal(false);
        }}
        item={textModal}
      />
    </div>
  );
}

export default EndOfDayWizard;
