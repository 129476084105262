import {
  Box,
  Typography,
  Select,
  SelectProps,
  MenuItem,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import React, { forwardRef } from 'react';

import styles from './styles';

type ItemBase = {
  id: string | number;
  text: string | number;
};

interface Props<T extends ItemBase> extends SelectProps {
  options: T[];
  helperText?: string;
  loading?: boolean;
  underline?: boolean;
}

function LoadingComponent() {
  return <CircularProgress size={30} sx={{ m: 1 }} />;
}

function FormSelectComponent<T extends ItemBase>(
  { options, label, helperText, sx, loading, underline, ...props }: Props<T>,
  ref: SelectProps['ref'],
) {
  return (
    <Box
      sx={[styles.container, ...(sx ? (Array.isArray(sx) ? sx : [sx]) : [])]}
    >
      <Typography variant="subtitle1">{label}</Typography>
      <Select
        ref={ref}
        variant="filled"
        sx={styles.selectField}
        SelectDisplayProps={{
          style: { paddingTop: 10, paddingBottom: 10 },
        }}
        fullWidth
        displayEmpty
        disableUnderline={!underline}
        color="success"
        inputProps={{ disabled: !!loading, disableUnderline: !!loading }}
        IconComponent={loading ? LoadingComponent : undefined}
        {...props}
      >
        {options.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <FormHelperText sx={{ color: 'error.main' }}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
}

export default forwardRef(FormSelectComponent);
