import { SxProps, Theme } from '@mui/material';

const styles: Record<string, SxProps<Theme>> = {
  container: {
    width: '100%',
    height: { xs: '100vh', sm: '100%' },
  },
  innerContainer: {
    height: '100%',
  },
  content: { flex: 1 },
  tabBarContainer: { px: 3, pt: 1, pb: 1.5, borderRadius: 0 },
  gridContainer: { p: 3, overflow: 'auto', height: '75vh' },
};

export default styles;
