/** Services */
import api from '../../api';

/** Types */
import { ApiSuccessResponse } from '../../types';
import { ListResponse } from './types';

type ListRes = ApiSuccessResponse<ListResponse>;
const list = (
  search: string,
  page: number,
  // phone_number?: string | null,
  // line_no?: string | null,
  // device_no?: string | null,
  // created_at?: string | null,
  // called_at?: string | null,
  per_page?: number | null,
) =>
  api.get<ListRes>('/caller-id/records', {
    params: {
      filter: {
        search,
        // phone_number,
        // line_no,
        // device_no
      },
      sort: {
        // created_at,
        // called_at,
      },
      page,
      per_page,
    },
  });

export default {
  list,
};
