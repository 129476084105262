import customerAddress from './definitions.customerAddress.json';
import auth from './auth.json';
import layout from './layout.json';
import companyRegister from './companyRegister.json';
import common from './common.json';
import table from './definitions.table.json';
import category from './definitions.category.json';
import property from './definitions.property.json';
import ingredient from './definitions.ingredient.json';
import product from './definitions.product.json';
import customer from './definitions.customer.json';
import search from './definitions.search.json';
import pos from './pos.json';
import user from './userOperations.user.json';
import role from './userOperations.role.json';
import restaurantSettings from './settings.restaurantSettings.json';
import printerSettings from './settings.printerSettings.json';
import paymentHistory from './settings.paymentHistory.json';
import endOfDays from './reports.endOfDays.json';
import operationReports from './reports.operationReports.json';
import dashboard from './dashboard.json';
import kitchen from './kitchen.json';
import error from './error.json';
import discount from './discount.json';
import notFound from './notFound.json';
import orders from './orders.json';
import apps from './apps.json';
import qrMenu from './qrMenu.json';
import bulkProduct from './bulkProduct.json';
import frauds from './frauds.json';
import waiterSales from './reports.waiterSales.json';
import sectionSales from './reports.sectionSales.json';
import categorySales from './reports.categorySales.json';
import productSales from './reports.productSales.json';
import callerId from './definitions.callerId.json';
import couvertSettings from './couvertSettings.json';
import serviceChargeSettings from './serviceChargeSettings.json';
import integrations from './integration.json';

export default {
  auth,
  layout,
  companyRegister,
  common,
  table,
  category,
  property,
  ingredient,
  product,
  customer,
  customerAddress,
  search,
  pos,
  user,
  role,
  restaurantSettings,
  printerSettings,
  paymentHistory,
  endOfDays,
  dashboard,
  operationReports,
  kitchen,
  error,
  discount,
  notFound,
  orders,
  apps,
  qrMenu,
  bulkProduct,
  frauds,
  waiterSales,
  sectionSales,
  categorySales,
  productSales,
  callerId,
  couvertSettings,
  serviceChargeSettings,
  integrations,
};
