import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductAPI } from '../../../../services/api/definitions/products/types';
import { getAllProductListThunk, getProductListThunk } from './thunks';

import { InitialState } from './types';
import arraySwap from '../../../../helpers/arraySwap';
import { IdAndNameTranslation } from '../../../../models/General';

const initialState: InitialState = { loading: false, list: [], listAll: [] };

const productsSlice = createSlice({
  name: 'productsSlice',
  initialState,
  reducers: {
    addProductSlice: (state, action: PayloadAction<ProductAPI>) => ({
      ...state,
      ProductInProcess: action.payload,
    }),
    updateProductSlice: (state, action: PayloadAction<ProductAPI>) => ({
      ...state,
      ProductInProcess: { ...state.ProductInProcess, ...action.payload },
    }),
    resetProductSlice: state => ({
      ...state,
      ProductInProcess: undefined,
    }),
    removeProductSlice: (
      state,
      action: PayloadAction<Pick<ProductAPI, 'id'>>,
    ) => ({
      ...state,
      list: state.list.filter(item => item.id !== action.payload.id),
    }),
    moveUpSlice: (
      state,
      action: PayloadAction<Pick<IdAndNameTranslation, 'id'>>,
    ) => {
      const targetIndex = state.list.findIndex(
        item => item.id === action.payload.id,
      );
      return {
        ...state,
        list: arraySwap(state.list, targetIndex, targetIndex - 1),
      };
    },
    moveDownSlice: (
      state,
      action: PayloadAction<Pick<IdAndNameTranslation, 'id'>>,
    ) => {
      const targetIndex = state.list.findIndex(
        item => item.id === action.payload.id,
      );
      return {
        ...state,
        list: arraySwap(state.list, targetIndex, targetIndex + 1),
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getProductListThunk.pending, state => ({
        ...state,
        loading: true,
      }))
      .addCase(getProductListThunk.fulfilled, (state, action) => ({
        ...state,
        list: action.payload,
        loading: false,
      }))
      .addCase(getProductListThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }))
      .addCase(getAllProductListThunk.pending, state => ({
        ...state,
        loading: false,
      }))
      .addCase(getAllProductListThunk.fulfilled, (state, action) => ({
        ...state,
        listAll: action.payload,
        loading: false,
      }))
      .addCase(getAllProductListThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }));
  },
});

export const {
  addProductSlice,
  updateProductSlice,
  removeProductSlice,
  resetProductSlice,
  moveUpSlice,
  moveDownSlice,
} = productsSlice.actions;

export default productsSlice.reducer;
