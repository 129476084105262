/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

/** Components */
import EmptyState from '../../../components/EmptyState';

/** Hooks */
import useRoles from '../../../hooks/useRoles';
import useProfile from '../../../hooks/useProfile';
import keyHasPermission from '../../../helpers/keyHasPermission';

function RolesTable() {
  const { t } = useTranslation('role');
  const { meProfile } = useProfile();
  const navigate = useNavigate();
  const { roles } = useRoles();

  const hasListPermission = keyHasPermission(
    'view-role-listing',
    meProfile?.permissions || [],
  );

  const hasViewDetailPermission = keyHasPermission(
    'view-role-detail',
    meProfile?.permissions || [],
  );

  const renderEmpty = () => (
    <EmptyState
      message={
        !hasListPermission
          ? t('permission.no_authorized')
          : t('permission.no_rol')
      }
    />
  );

  return (
    <Paper sx={{ width: '100%' }}>
      {!roles.length ? (
        renderEmpty()
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{t('role.roleTable.name')}</TableCell>
                <TableCell align="center">
                  {t('role.roleTable.actions')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roles.map(row => (
                <TableRow key={row.id}>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={event => {
                        if (hasViewDetailPermission) {
                          navigate(`/user-operations/roles/${row.id}`);
                        }
                      }}
                      color="error"
                      disabled={!hasViewDetailPermission}
                    >
                      {t('role.details.edit')}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}

export default RolesTable;
