/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

/** Styles */
import {
  AccordionSummary,
  Box,
  AccordionDetails,
  Accordion,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  AccordionSummaryProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

/** Components */
import LoadingState from '../../../components/LoadingState';
import EmptyState from '../../../components/EmptyState';

/** Hooks */
import useRoles from '../../../hooks/useRoles';
import Button from '../../../components/Button';
import { PermissionGroup } from '../../../store/slices/userOperationsSlice/roles/types';
import keyHasPermission from '../../../helpers/keyHasPermission';
import useProfile from '../../../hooks/useProfile';

export default function CustomerAddressDataTable() {
  const { fetchRole, updateRolePermissions, role, loading } = useRoles();
  const { t } = useTranslation('role');
  const { meProfile } = useProfile();

  const MuiAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary
      expandIcon={
        <ArrowForwardIosSharpIcon sx={{ fontSize: '1.2rem', color: 'black' }} />
      }
      {...props}
    />
  ))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
    '& .MuiAccordionSummary': {
      marginLeft: theme.spacing(1),
    },
  }));

  const { roleId } = useParams();
  const [updatePermissionList, setUpdatePermissionList] = useState<
    PermissionGroup[]
  >([]);

  useEffect(() => {
    if (roleId) {
      fetchRole(roleId);
      setUpdatePermissionList(role.permission_groups);
    }
  }, []);

  useEffect(() => {
    setUpdatePermissionList(role.permission_groups);
  }, [role]);

  const handleUpdate = async () => {
    const updateRolePermissionsForBE = updatePermissionList.map(item =>
      item.permissions.map(permission => ({
        id: permission.id,
        active: permission.is_active,
      })),
    );

    const updateRolePermissionsConcatForBE = [].concat.apply(
      [],
      // @ts-ignore
      [...updateRolePermissionsForBE],
    );

    await updateRolePermissions({
      role_id: roleId,
      rolePermissions: updateRolePermissionsConcatForBE,
    });
  };

  const hasViewDetailPermission = keyHasPermission(
    'view-role-detail',
    meProfile?.permissions || [],
  );

  const hasUpdatePermission = keyHasPermission(
    'update-role',
    meProfile?.permissions || [],
  );

  const renderEmpty = () => (
    <EmptyState
      message={
        !hasViewDetailPermission
          ? t('permission.no_authorized')
          : t('role.details.empty')
      }
    />
  );

  if (!hasViewDetailPermission || !role.permission_groups) {
    return renderEmpty();
  }

  if (loading) {
    return <LoadingState />;
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 3 }}>
        <Button
          sx={{ fontSize: '16px', mb: 2 }}
          size="large"
          onClick={hasUpdatePermission ? handleUpdate : () => {}}
          disabled={!hasUpdatePermission}
        >
          {t('role.details.update')}
        </Button>
      </Box>
      <Grid>
        {updatePermissionList.map(permissionGroup => (
          <Accordion defaultExpanded elevation={0}>
            <MuiAccordionSummary
              sx={{
                minHeight: '0',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '18px',
                  fontWeight: '600',
                }}
              >
                {permissionGroup.name}
              </Box>
            </MuiAccordionSummary>
            <AccordionDetails
              sx={{
                padding: '0px 16px 0px',
              }}
            >
              <Grid container>
                {permissionGroup.permissions.map(item => (
                  <Grid item xs={6} sm={4} md={3} lg={2} py={2}>
                    <FormGroup sx={{ width: '100%' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.is_active}
                            onClick={() => {
                              setUpdatePermissionList([
                                ...updatePermissionList.map(list =>
                                  list.name === permissionGroup.name
                                    ? {
                                        ...list,
                                        permissions: list.permissions.map(
                                          listItem =>
                                            listItem.id === item.id
                                              ? {
                                                  ...listItem,
                                                  is_active:
                                                    !listItem.is_active,
                                                }
                                              : listItem,
                                        ),
                                      }
                                    : list,
                                ),
                              ]);
                            }}
                            disabled={!hasUpdatePermission}
                          />
                        }
                        label={item.name}
                      />
                    </FormGroup>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Box>
  );
}
