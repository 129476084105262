/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

/** Components */
import UtilitiesBar, {
  Props as BarProps,
} from '../../../components/UtilitiesBar';
import ErrorState from '../../../components/ErrorState';
import LoadingState from '../../../components/LoadingState';
import EndOfDayTable from './EndOfDayTable';
import useRoles from '../../../hooks/useRoles';
import useBranches from '../../../hooks/useBranches';
import DaysSearch from './Search/DaysSearch';

function EndOfDay() {
  const { t } = useTranslation('endOfDays');
  const { fetchDays, days, loading } = useBranches();
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    fetchDays();
  }, []);

  if (!days) {
    return <ErrorState message={t('common:unknownError' as any)} />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('title')}
        leftIconName="SupervisorAccount"
      />
      <DaysSearch
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      {loading ? (
        <LoadingState />
      ) : (
        <EndOfDayTable page={page} setPage={setPage} />
      )}
    </Box>
  );
}

export default EndOfDay;
