import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from '../../../components/ConfirmationModal';
import EmptyState from '../../../components/EmptyState';
import ErrorState from '../../../components/ErrorState';
import LoadingState from '../../../components/LoadingState';
import ProductCard from '../../../components/ProductCard';
import getActiveLanguage from '../../../helpers/getActiveLanguage';
import useProducts from '../../../hooks/useProducts';
import keyHasPermission from '../../../helpers/keyHasPermission';
import useProfile from '../../../hooks/useProfile';
import errorReporting from '../../../helpers/errorReporting';

interface Props {
  categoryId: string | null;
}

function ProductList({ categoryId }: Props) {
  const { t } = useTranslation('product');
  const { meProfile } = useProfile();
  const [deleteCatConfirmation, setDeleteCatConfirmation] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState<string | null>(null);
  const navigate = useNavigate();

  const {
    products,
    productLoading,
    productError,
    fetchProducts,
    removeProduct,
    deleting,
  } = useProducts();

  useEffect(() => {
    if (categoryId) {
      fetchProducts(categoryId);
    }
  }, [categoryId]);

  const handleRemove = async () => {
    try {
      if (itemIdToDelete) {
        await removeProduct(itemIdToDelete);
      }
      setItemIdToDelete(null);
      setDeleteCatConfirmation(false);
    } catch (err: any) {
      errorReporting(err);
    }
  };

  const hasUpdatePermission = keyHasPermission(
    'update-product',
    meProfile?.permissions || [],
  );

  if (productLoading) return <LoadingState />;
  if (productError)
    return (
      <ErrorState
        message={productError.message || t('common:unknownError' as any)}
      />
    );
  if (!categoryId || products.length === 0)
    return <EmptyState message={t('emptyList')} />;

  return (
    <>
      <Grid container spacing={2}>
        {products
          .slice()
          .sort((a, b) =>
            a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1,
          )
          .map(p => {
            const defaultUnit = p.units.find(unit => unit.is_default);
            return (
              <Grid item key={p.id} xs={6} sm={4} md={3} lg={2}>
                <ProductCard
                  name={getActiveLanguage(p.translations).name}
                  active={p.is_active}
                  onClick={() =>
                    hasUpdatePermission &&
                    navigate(`/definitions/product/${categoryId}/${p.id}`)
                  }
                  onDelete={() => {
                    setItemIdToDelete(p.id);
                    setDeleteCatConfirmation(true);
                  }}
                  defaultUnit={
                    defaultUnit && {
                      name: getActiveLanguage(defaultUnit.translations).name,
                      price: defaultUnit.amount.formatted,
                    }
                  }
                />
              </Grid>
            );
          })}
      </Grid>
      <ConfirmationModal
        title={t('deleteConfirmation.title')}
        titleIconName="QuestionMarkOutlined"
        description={t('deleteConfirmation.description')}
        open={deleteCatConfirmation}
        onClose={() => {
          setItemIdToDelete(null);
          setDeleteCatConfirmation(false);
        }}
        positiveAction={t('deleteConfirmation.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('deleteConfirmation.cancel')}
        onNegativeClick={() => {
          setItemIdToDelete(null);
          setDeleteCatConfirmation(false);
        }}
        loading={deleting}
      />
    </>
  );
}

export default ProductList;
