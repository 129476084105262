/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

/** Components */
import UtilitiesBar, {
  Props as BarProps,
} from '../../../components/UtilitiesBar';
import PaymentHistoryTable from './PaymentHistoryTable';
import useSettings from '../../../hooks/useSettings';
import LoadingState from '../../../components/LoadingState';
import ErrorState from '../../../components/ErrorState';
import useBranches from '../../../hooks/useBranches';

/** Styles */

function PaymentHistory() {
  const { t } = useTranslation('paymentHistory');
  const { paymentHistories, getPaymentHistories } = useBranches();

  const { printerSettingsError, printerSettingsLoading, printers } =
    useSettings();

  useEffect(() => {
    getPaymentHistories();
  }, []);

  if (!printers && printerSettingsError) {
    return (
      <ErrorState
        message={
          printerSettingsError
            ? printerSettingsError.message
            : t('common:unknownError' as any)
        }
      />
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('title')}
        leftIconName="Payment"
      />
      {printerSettingsLoading ? (
        <LoadingState />
      ) : (
        <PaymentHistoryTable paymentHistories={paymentHistories} />
      )}
    </Box>
  );
}

export default PaymentHistory;
