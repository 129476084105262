import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import Button from '../../components/Button';
import FormInput from '../../components/FormInput';
import FormPasswordInput from '../../components/FormPasswordInput';
import styles from './styles';
import { LoginFormValues, validationSchema } from './types';
import RouterLink from '../../components/RouterLink';

interface Props {
  onValidSubmit: (data: LoginFormValues) => void;
  submitting: boolean;
}
function Form({ onValidSubmit, submitting }: Props) {
  const { t } = useTranslation('auth');
  const { handleSubmit, control } = useForm<LoginFormValues>({
    defaultValues: { email: '', password: '' },
    resolver: validationSchema,
  });

  const onSubmit = handleSubmit(onValidSubmit);

  return (
    <form onSubmit={onSubmit}>
      <Controller
        name="email"
        control={control}
        render={({ field, fieldState }) => (
          <FormInput
            label={t('login.email.label')}
            autoComplete="username"
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            {...field}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        render={({ field, fieldState }) => (
          <FormPasswordInput
            label={t('login.password.label')}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            {...field}
          />
        )}
      />

      <Grid
        sx={{ color: '#26acf8', cursor: 'pointer', fontSize: 'inherit' }}
        textAlign="end"
      >
        <RouterLink to="/reset-password">{t('login.forgot')}</RouterLink>
      </Grid>

      <Button type="submit" sx={styles.submit} loading={submitting} fullWidth>
        {t('login.submit')}
      </Button>
    </form>
  );
}

export default Form;
