/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import { Box, Paper, Tab, Tabs } from '@mui/material';

/** Components */
import TabPanel from '../../../CompanyRegister/TabPanel';
import GeneralSettingsForm from './GeneralSettingsForm';
import useProfile from '../../../../hooks/useProfile';
import keyHasPermission from '../../../../helpers/keyHasPermission';
import ProductsForm from './ProductsForm';
import IngredientsForm from './IngredientsForm';
import PropertiesForm from './PropertiesForm';

/** Helpers */
import { a11yProps } from '../../../../helpers/rgbToHex';

interface Props {
  value: number;
  setValue: (value: ((prevState: number) => number) | number) => void;
}

function Form({ value, setValue }: Props) {
  const { meProfile } = useProfile();
  const { t } = useTranslation('integrations');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const hasShowGeneralSettings = keyHasPermission(
    'view-general-settings',
    meProfile?.permissions || [],
  );

  const hasShowPaymentTypes = keyHasPermission(
    'view-payment-types',
    meProfile?.permissions || [],
  );

  return (
    <Box>
      <Paper sx={{ p: 3, flex: 1 }} elevation={3}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          {hasShowGeneralSettings && hasShowPaymentTypes && (
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label={t('general_settings')} {...a11yProps(0)} />
              <Tab label={t('menu_items')} {...a11yProps(1)} />
              <Tab label={t('properties')} {...a11yProps(2)} />
              <Tab label={t('ingredients')} {...a11yProps(3)} />
            </Tabs>
          )}
        </Box>
        {hasShowGeneralSettings && (
          <>
            <TabPanel value={value} index={0}>
              <GeneralSettingsForm />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ProductsForm />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <PropertiesForm />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <IngredientsForm />
            </TabPanel>
          </>
        )}
      </Paper>
    </Box>
  );
}

export default Form;
