/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

/** Components */
import EmptyState from '../../../components/EmptyState';

/** Hooks */
import useBranches from '../../../hooks/useBranches';
import useProfile from '../../../hooks/useProfile';
import keyHasPermission from '../../../helpers/keyHasPermission';

function ProductSalesTable() {
  const { t } = useTranslation('productSales');
  const { meProfile } = useProfile();

  const { salesByProducts } = useBranches();

  const hasViewPermission = keyHasPermission(
    'view-daily-report',
    meProfile?.permissions || [],
  );
  const renderEmpty = () => (
    <EmptyState
      message={
        !hasViewPermission
          ? 'Bu sayfayı görmeye yetkiniz yok!'
          : t('reports.empty')
      }
    />
  );

  return (
    <Paper sx={{ width: '100%', p: 2 }}>
      {!hasViewPermission || !salesByProducts ? (
        renderEmpty()
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {t('reports.reportsTable.code')}
                </TableCell>
                <TableCell align="center">
                  {t('reports.reportsTable.name')}
                </TableCell>
                <TableCell align="center">
                  {t('reports.reportsTable.quantity')}
                </TableCell>
                <TableCell align="center">
                  {t('reports.reportsTable.unit')}
                </TableCell>
                <TableCell align="center">
                  {t('reports.reportsTable.category')}
                </TableCell>
                <TableCell align="center">
                  {t('reports.reportsTable.unit_price')} (₺)
                </TableCell>
                <TableCell align="center">
                  {t('reports.reportsTable.cost')} (₺)
                </TableCell>
                <TableCell align="center">
                  {t('reports.reportsTable.total_amount')} (₺)
                </TableCell>
                <TableCell align="center">
                  {t('reports.reportsTable.rate')} (%)
                </TableCell>
              </TableRow>
            </TableHead>
            {salesByProducts?.products.map(row =>
              row.units.map(unit => (
                <TableBody>
                  <TableRow key={row.product.id}>
                    <TableCell align="center">
                      {row.product.code || ''}
                    </TableCell>
                    <TableCell align="center">{row.product.name}</TableCell>
                    <TableCell align="center">{unit.total_quantity}</TableCell>
                    <TableCell align="center">{unit.unit}</TableCell>
                    <TableCell align="center">{row.category}</TableCell>
                    <TableCell align="center">
                      {unit.unit_price.formatted}
                    </TableCell>
                    <TableCell align="center">{unit.cost.formatted}</TableCell>
                    <TableCell align="center">
                      {unit.total_amount.formatted}
                    </TableCell>
                    <TableCell align="center">{unit.rate}</TableCell>
                  </TableRow>
                </TableBody>
              )),
            )}
            <TableBody>
              <TableRow style={{ background: '#f0f0f0' }}>
                <TableCell style={{ fontWeight: '700' }} align="center">
                  {' '}
                </TableCell>
                <TableCell style={{ fontWeight: '700' }} align="center">
                  {t('reports.reportsTable.total')}
                </TableCell>
                <TableCell style={{ fontWeight: '700' }} align="center">
                  {salesByProducts.total_quantity}
                </TableCell>
                <TableCell style={{ fontWeight: '700' }} align="center">
                  {' '}
                </TableCell>
                <TableCell style={{ fontWeight: '700' }} align="center">
                  {' '}
                </TableCell>
                <TableCell style={{ fontWeight: '700' }} align="center">
                  {' '}
                </TableCell>
                <TableCell style={{ fontWeight: '700' }} align="center">
                  {' '}
                </TableCell>
                <TableCell style={{ fontWeight: '700' }} align="center">
                  {salesByProducts?.total_amount.formatted}
                </TableCell>
                <TableCell style={{ fontWeight: '700' }} align="center">
                  {salesByProducts?.rate}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}

export default ProductSalesTable;
