import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PaymentTypesResource,
  BushinessTypesResource,
  WorkingTypesResource,
  CurrenciesResource,
} from '../../../services/api/utilities/types';
import { InitialState } from './types';

const initialState: InitialState = {
  paymentTypes: [],
  businessTypes: [],
  workingTypes: [],
  currencies: [],
};

const taxRatesSlice = createSlice({
  name: 'taxRatesSlice',
  initialState,
  reducers: {
    getPaymentTypes: (state, action: PayloadAction<PaymentTypesResource>) => ({
      ...state,
      paymentTypes: action.payload,
    }),
    getBusinessTypes: (
      state,
      action: PayloadAction<BushinessTypesResource>,
    ) => ({
      ...state,
      businessTypes: action.payload,
    }),
    getWorkingTypes: (state, action: PayloadAction<WorkingTypesResource>) => ({
      ...state,
      workingTypes: action.payload,
    }),
    getCurrencies: (state, action: PayloadAction<CurrenciesResource>) => ({
      ...state,
      currencies: action.payload,
    }),
  },
});

export const {
  getPaymentTypes,
  getBusinessTypes,
  getWorkingTypes,
  getCurrencies,
} = taxRatesSlice.actions;

export default taxRatesSlice.reducer;
