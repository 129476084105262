import api from '../../api';
import { ApiSuccessResponse } from '../../types';
import {
  CreateResponse,
  ListResponse,
  ViewResponse,
  UpdateResponse,
  DeleteResponse,
  ExpenseCreateParams,
  ExpenseUpdateParams,
} from './types';

type ListRes = ApiSuccessResponse<ListResponse>;
const list = (
  page?: number,
  date_range?: string | null,
  expense_category_id?: string[] | null,
  day_id?: string,
  per_page?: number,
) =>
  api.get<ListRes>('/expenses', {
    params: {
      filter: {
        date_range,
        expense_category_id,
        day_id,
      },
      page,
      per_page,
    },
  });

type ViewRes = ApiSuccessResponse<ViewResponse>;
const view = (id: string) => api.get<ViewRes>(`/expenses/${id}`);

type CreatRes = ApiSuccessResponse<CreateResponse>;
const create = (params: ExpenseCreateParams) =>
  api.post<CreatRes>('/expenses', params);

type UpdateRes = ApiSuccessResponse<UpdateResponse>;
const update = (params: ExpenseUpdateParams) => {
  const { expenseId } = params;
  return api.put<UpdateRes>(`/expenses/${expenseId}`, params);
};

type DeleteRes = ApiSuccessResponse<DeleteResponse>;
const remove = (expenseId: string) =>
  api.delete<DeleteRes>(`/expenses/${expenseId}`);

export default {
  list,
  view,
  create,
  update,
  remove,
};
