import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../services/api';

import {
  getApps,
  getBranches,
  getDays,
  getPaymentHistories,
  getSalesByCategories,
  getSalesByProducts,
  getSalesBySections,
  getSalesByUsers,
  installApp,
} from './branchSlice';
import { useAppSelector } from '../../store';

export const getBranchesThunk = createAsyncThunk(
  'branches/get',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.utilities.getBranches();
      const result = response.data.data;
      dispatch(getBranches(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getDaysThunk = createAsyncThunk(
  'days/get',
  async (
    params: { page?: number; start_date?: string; end_date?: string },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await api.utilities.getDays(
        params.page,
        params.start_date,
        params.end_date,
      );
      const result = response.data;
      dispatch(getDays(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getSalesByUsersThunk = createAsyncThunk(
  'users/get',
  async (
    params: { page?: number; start_date?: string; end_date?: string },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await api.utilities.getSalesByUsers(
        params.page,
        params.start_date,
        params.end_date,
      );
      const result = response.data;
      dispatch(getSalesByUsers(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getSalesByProductsThunk = createAsyncThunk(
  'products/get',
  async (
    params: { page?: number; start_date?: string; end_date?: string },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await api.utilities.getSalesByProduct(
        params.page,
        params.start_date,
        params.end_date,
      );
      const result = response.data;
      dispatch(getSalesByProducts(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getSalesBySectionsThunk = createAsyncThunk(
  'sections/get',
  async (
    params: { page?: number; start_date?: string; end_date?: string },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await api.utilities.getSalesBySection(
        params.page,
        params.start_date,
        params.end_date,
      );
      const result = response.data;
      dispatch(getSalesBySections(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getSalesByCategoriesThunk = createAsyncThunk(
  'categories/get',
  async (
    params: { page?: number; start_date?: string; end_date?: string },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await api.utilities.getSalesByCategory(
        params.page,
        params.start_date,
        params.end_date,
      );
      const result = response.data;
      dispatch(getSalesByCategories(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateBranchesStartDateThunk = createAsyncThunk(
  'branches/update',
  async (branchId: string, { rejectWithValue }) => {
    try {
      const response = await api.utilities.updateBranchStartDate(branchId);
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateBranchesEndDateThunk = createAsyncThunk(
  'branches/update',
  async (branchId: string, { rejectWithValue }) => {
    try {
      const response = await api.utilities.updateBranchEndDate(branchId);
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getAppsThunk = createAsyncThunk(
  'apps/get',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.utilities.getApps();
      const result = response.data.data;
      dispatch(getApps(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const installAppThunk = createAsyncThunk(
  'apps/post',
  async (slug: string, { rejectWithValue, dispatch }) => {
    try {
      const result = await api.utilities.installApp(slug);
      dispatch(installApp(slug));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getPaymentHistoryThunk = createAsyncThunk(
  'apps/get',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.utilities.getPaymentHistory();
      const result = response.data.data;
      dispatch(getPaymentHistories(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
