import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { isApiError, isApiErrorResponse } from '../../services/api/types';
import {
  getDailyReportsThunk,
  getDashboardReportsThunk,
  getMostPopularThunk,
  getOperationLogsThunk,
  getPaymentTypeReportsThunk,
  resetAccountThunk,
} from '../../store/slices/reportsSlice';
import arrayCatcher from '../../helpers/errorCatcher';
import { expiredAccountStorage } from '../../services/tokenStorage';

const useReports = () => {
  const dispatch = useAppDispatch();

  const dailyReports = useAppSelector(state => state.reports.dailyReports);
  const dashboardReports = useAppSelector(
    state => state.reports.dashboardReports,
  );
  const paymentTypeReports = useAppSelector(
    state => state.reports.paymentTypeReports,
  );
  const mostPopular = useAppSelector(state => state.reports.mostPopular);
  const mostPopularMeta = useAppSelector(
    state => state.reports.mostPopularMeta,
  );

  const logs = useAppSelector(state => state.reports.logs);
  const meta = useAppSelector(state => state.reports.meta);

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const fetchMostPopular = async (
    dayId?: string,
    page?: number,
    sort?: string,
    start_date?: string,
    end_date?: string,
    per_page?: number,
  ) => {
    try {
      setLoading(true);
      await dispatch(
        getMostPopularThunk({
          dayId,
          page,
          sort,
          start_date,
          end_date,
          per_page,
        }),
      ).unwrap();
      expiredAccountStorage.removeToken();
    } catch (err: any) {
      if (err?.code !== 1001 && err?.code !== 1002) {
        enqueueSnackbar(
          isApiError(err) ? err.message : t('common:unknownError' as any),
          {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
          },
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchDashboardReports = async () => {
    try {
      setLoading(true);
      await dispatch(getDashboardReportsThunk()).unwrap();
      expiredAccountStorage.removeToken();
    } catch (err: any) {
      arrayCatcher(err?.code);
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchDailyReports = async (dayId: string) => {
    try {
      setLoading(true);
      await dispatch(getDailyReportsThunk(dayId)).unwrap();
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchPaymentTypeReports = async (
    start_date?: string,
    end_date?: string,
    day_id?: string,
  ) => {
    try {
      setLoading(true);
      await dispatch(
        getPaymentTypeReportsThunk({ start_date, end_date, day_id }),
      ).unwrap();
      expiredAccountStorage.removeToken();
    } catch (err: any) {
      if (err?.code !== 1001 && err?.code !== 1002) {
        enqueueSnackbar(
          isApiError(err) ? err.message : t('common:unknownError' as any),
          {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
          },
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchOperationLogs = async (
    day_id?: string,
    type?: number,
    page?: number,
    per_page?: number,
  ) => {
    try {
      setLoading(true);
      await dispatch(
        getOperationLogsThunk({ day_id, type, page, per_page }),
      ).unwrap();
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const resetAccount = async () => {
    try {
      setLoading(true);
      await dispatch(resetAccountThunk()).unwrap();
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
      });
      window.location.reload();
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    dailyReports,
    dashboardReports,
    paymentTypeReports,
    mostPopular,
    mostPopularMeta,
    logs,
    meta,
    fetchDailyReports,
    fetchDashboardReports,
    fetchPaymentTypeReports,
    fetchMostPopular,
    fetchOperationLogs,
    loading,
    resetAccount,
  };
};

export default useReports;
