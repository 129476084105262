import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type PrinterAddAndUpdateFormValues = {
  name: string;
  connection_type: 'windows' | 'cups' | 'network';
  capability_profile: 'default' | 'simple' | 'SP2000' | 'TEP-200M' | 'P822D';
  char_per_line?: number;
  ip_address?: string;
  port?: string;
  path?: string;
  font_size: 'small' | 'medium' | 'large';
  footer_description: string;
  kitchen_group_ids: string[];
};

// @ts-ignore
const printerSchema: yup.SchemaOf<PrinterAddAndUpdateFormValues> = yup.object({
  name: yup.string().required('printer.addModal.form.name.required'),
  connection_type: yup.string().required('printer.addModal.form.name.required'),
  capability_profile: yup
    .string()
    .required('printer.addModal.form.name.required'),
  char_per_line: yup
    .number()
    .min(0, 'printer.addModal.form.char_per_line.min')
    .required('printer.addModal.form.char_per_line.required'),
  ip_address: yup.string().when('connection_type', {
    is: (value: any) => value === 'network' || value === 'Network',
    then: yup.string().required('printer.addModal.form.ip_address.required'),
    otherwise: yup.string().nullable(),
  }),
  port: yup.string().when('connection_type', {
    is: (value: any) => value === 'network' || value === 'Network',
    then: yup.string().required('printer.addModal.form.port.required'),
    otherwise: yup.string().nullable(),
  }),
  path: yup.string().when('connection_type', {
    is: (value: any) => value === 'windows' || value === 'cups',
    then: yup.string().required('printer.addModal.form.path.required'),
    otherwise: yup.string().nullable(),
  }),
  font_size: yup.string().required('printer.addModal.form.name.required'),
  footer_description: yup.string(),
  kitchen_group_ids: yup
    .array(yup.string().required())
    .required('printer.addModal.form.kitchen_group_ids.required'),
});

export const validationSchema = yupResolver(printerSchema);

export const defaultValues: PrinterAddAndUpdateFormValues = {
  name: '',
  connection_type: 'windows',
  capability_profile: 'default',
  char_per_line: 0,
  ip_address: '',
  port: '',
  path: '',
  font_size: 'medium',
  footer_description: '',
  kitchen_group_ids: [],
};
