import { SxProps, Theme } from '@mui/material';

const styles: Record<string, SxProps<Theme>> = {
  sectionsPaper: {
    p: 3,
    mb: { xs: 0, sm: 15, md: 15, lg: 20 },
  },
  tableGrid: {
    position: 'sticky',
    bottom: 10,
    right: 0,
    left: 0,
    textAlign: 'center',
    padding: 1,
    mb: 3,
  },
  tableButton: {
    m: 2,
    borderRadius: 10,
    width: { sm: '85px', md: '150px' },
    height: { sm: '50px', md: '60px' },
    boxShadow: '0 5px 15px 0 #00000026',
  },
  paymentTypeButton: {
    height: '75px',
    border: '1px solid #DEDEDE',
    fontSize: '1rem',
    backgroundColor: 'white',
  },
};

export default styles;
