import React, { useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import FormCheckbox from '../../components/FormCheckbox';
import FormInput from '../../components/FormInput';
import FormPasswordInput from '../../components/FormPasswordInput';
import GDPRModal from './GDPRModal';
import ContractModal from './ContractModal';
import styles from './styles';
import { RegisterFormValues, validationSchema, defaultValues } from './types';
import FormPhoneInput from '../../components/FormPhoneInput';

interface Props {
  onValidSubmit: (data: RegisterFormValues) => void;
  submitting: boolean;
}
function Form({ onValidSubmit, submitting }: Props) {
  const { t } = useTranslation('auth');
  const { handleSubmit, control, setValue } = useForm<RegisterFormValues>({
    defaultValues,
    resolver: validationSchema,
  });

  const [phoneValue, setPhoneValue] = useState('');
  const [info, setInfo] = useState<any>(null);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleChange = (newValue: any, info: any) => {
    setPhoneValue(newValue);
    setInfo(info);
  };

  const onSubmit = handleSubmit(async values =>
    onValidSubmit({
      ...values,
      phone: info?.nationalNumber,
      phoneNumberCountry: info?.countryCode,
    }),
  );

  const [gdprModal, setGdprModal] = useState(false);
  const [contractModal, setContractModal] = useState(false);

  return (
    <form onSubmit={onSubmit}>
      <Box sx={{ py: 5 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Controller
              name="firstName"
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  sx={styles.field}
                  label={t('register.first_name.label')}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="lastName"
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  sx={styles.field}
                  label={t('register.last_name.label')}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  sx={styles.field}
                  label={t('register.email.label')}
                  autoComplete="username"
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <FormPhoneInput
                  label={t('register.phone.label')}
                  phoneValue={phoneValue}
                  handleChange={handleChange}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState }) => (
                <FormPasswordInput
                  sx={styles.field}
                  label={t('register.password.label')}
                  autoComplete="new-password"
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field, fieldState }) => (
                <FormPasswordInput
                  sx={styles.field}
                  label={t('register.confirm_password.label')}
                  autoComplete="new-password"
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Grid container justifyContent="flex-start">
        <Grid item xs={1}>
          <Controller
            name="contract"
            control={control}
            render={({ field, fieldState }) => (
              <FormCheckbox error={fieldState.invalid} {...field} />
            )}
          />
        </Grid>
        <Grid sx={{ cursor: 'pointer' }} item xs={11} mt={1}>
          <Typography color="#26ACF8" onClick={() => setContractModal(true)}>
            {t('register.contract.link')}
          </Typography>
        </Grid>
        <ContractModal
          open={contractModal}
          onClose={() => setContractModal(false)}
          onConfirm={() => {
            setContractModal(false);
            setValue('contract', true);
          }}
        />
      </Grid>

      <Grid container justifyContent="flex-start">
        <Grid item xs={1}>
          <Controller
            name="gdpr"
            control={control}
            render={({ field, fieldState }) => (
              <FormCheckbox error={fieldState.invalid} {...field} />
            )}
          />
        </Grid>
        <Grid sx={{ cursor: 'pointer' }} item xs={11} mt={1}>
          <Typography color="#26ACF8" onClick={() => setGdprModal(true)}>
            {t('register.gdpr.link')}
          </Typography>
        </Grid>
        <GDPRModal
          open={gdprModal}
          onClose={() => setGdprModal(false)}
          onConfirm={() => {
            setGdprModal(false);
            setValue('gdpr', true);
          }}
        />
      </Grid>

      <Button
        type="submit"
        sx={styles.submit}
        variant="contained"
        fullWidth
        loading={submitting}
      >
        {t('register.submit')}
      </Button>
    </form>
  );
}

export default Form;
