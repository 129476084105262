import { SxProps, Theme } from '@mui/material';

const styles: Record<string, SxProps<Theme>> = {
  tableContainer: {
    overflow: 'auto',
    maxHeight: { sm: '50vh', md: '50vh', lg: '60vh', xl: '60vh' },
  },
  tableRow: {
    cursor: 'pointer',
    ':hover': {
      background: '#40bff5',
    },
  },
};

export default styles;
