import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import { LAST_REGISTRATION_STEP } from '../services/api/auth/types';
import { isApiError } from '../services/api/types';
import {
  AccessTokenStorage,
  BranchIdStorage,
  registrationStepStorage,
} from '../services/tokenStorage';
import { useAppDispatch } from '../store';
import { meThunk } from '../store/slices/authSlice';
import { getLanguagesThunk } from '../store/slices/languageSlice';

function AuthRequired({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');

  const AUTHENTICATED = AccessTokenStorage.getToken();
  const LOGOUT_BY_PIN = BranchIdStorage.getToken();

  if (!AUTHENTICATED) {
    window.location.replace('/login');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (LOGOUT_BY_PIN) {
    return <Navigate to="/lock" state={{ from: location }} replace />;
  }

  useEffect(() => {
    const init = async () => {
      try {
        // Skip init if registration is not completed
        const step = registrationStepStorage.getToken();
        if (step && parseInt(step, 10) < LAST_REGISTRATION_STEP) {
          return;
        }
        const response = await dispatch(meThunk());
        unwrapResult(response);
      } catch (err) {
        enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      dispatch(getLanguagesThunk());
    };
    init();
  }, []);

  return children;
}

export default AuthRequired;
