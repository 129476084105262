/** Dependencies */
import React from 'react';
import { Box, IconButton } from '@mui/material';

/** Components */
import Icon from '../../../../components/Icon';

/** Styles */
import styles from '../../styles';

interface Props {
  item: any;
  handleOrderItemUpdate: (status: number, item_id: string) => void;
}

function ItemCheckButton({ item, handleOrderItemUpdate }: Props) {
  return (
    <Box sx={styles.displayContainer}>
      <IconButton
        sx={styles.icon}
        onMouseDown={e => e.stopPropagation()}
        onClick={() =>
          handleOrderItemUpdate(item.order_item_status === 2 ? 3 : 2, item.id)
        }
      >
        <Icon
          fontSize="large"
          name={
            item.order_item_status === 3 ? 'CheckCircle' : 'CheckCircleOutline'
          }
          color={item.order_item_status === 3 ? 'success' : 'inherit'}
        />
      </IconButton>
    </Box>
  );
}

export default ItemCheckButton;
