import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialState, TaxRate } from './types';

const initialState: InitialState = {
  list: [],
};

const taxRatesSlice = createSlice({
  name: 'taxRatesSlice',
  initialState,
  reducers: {
    getTaxRates: (_, action: PayloadAction<TaxRate[]>) => ({
      list: action.payload,
    }),
  },
});

export const { getTaxRates } = taxRatesSlice.actions;

export default taxRatesSlice.reducer;
