import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IngredientGroup, IngredientItem } from '../../../../models/Ingredient';
import { getIngredientGroupsListThunk } from './thunks';

import { InitialState } from './types';

const initialState: InitialState = { loading: false, ingredientGroups: [] };

const ingredientGroupsSlice = createSlice({
  name: 'ingredientGroupsSlice',
  initialState,
  reducers: {
    addIngredientGroupsSlice: (
      state,
      action: PayloadAction<IngredientGroup>,
    ) => ({
      ...state,
      ingredientGroups: [...state.ingredientGroups, action.payload],
    }),
    updateIngredientGroupsSlice: (
      state,
      action: PayloadAction<IngredientGroup>,
    ) => ({
      ...state,
      ingredientGroups: state.ingredientGroups.map(item =>
        item.id === action.payload.id ? action.payload : item,
      ),
    }),
    removeIngredientGroupsSlice: (
      state,
      action: PayloadAction<Pick<IngredientGroup, 'id'>>,
    ) => ({
      ...state,
      ingredientGroups: state.ingredientGroups.filter(
        item => item.id !== action.payload.id,
      ),
    }),
    addIngredientItemSlice: (
      state,
      {
        payload: { groupId, ...item },
      }: PayloadAction<IngredientItem & { groupId: string }>,
    ) => {
      const targetGroup = state.ingredientGroups.findIndex(
        el => el.id === groupId,
      );
      state.ingredientGroups[targetGroup].items.push(item);
    },
    updateIngredientItemSlice: (
      state,
      {
        payload: { groupId, ...item },
      }: PayloadAction<IngredientItem & { groupId: string }>,
    ) => {
      const group = state.ingredientGroups.find(el => el.id === groupId);
      if (group) {
        const targetGroup = state.ingredientGroups.findIndex(
          el => el.id === groupId,
        );
        const targetItem = state.ingredientGroups[targetGroup].items.findIndex(
          el => el.id === item.id,
        );
        state.ingredientGroups[targetGroup].items[targetItem] = item;
      }
      return state;
    },
    removeIngredientItemSlice: (
      state,
      {
        payload: { groupId, id },
      }: PayloadAction<{ id: string; groupId: string }>,
    ) => {
      const targetGroup = state.ingredientGroups.findIndex(
        el => el.id === groupId,
      );
      const targetItem = state.ingredientGroups[targetGroup].items.findIndex(
        el => el.id === id,
      );
      state.ingredientGroups[targetGroup].items.splice(targetItem, 1);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getIngredientGroupsListThunk.pending, state => ({
        ...state,
        loading: true,
      }))
      .addCase(getIngredientGroupsListThunk.fulfilled, (state, action) => ({
        ...state,
        ingredientGroups: action.payload,
        loading: false,
      }))
      .addCase(getIngredientGroupsListThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }));
  },
});

export const {
  addIngredientGroupsSlice,
  updateIngredientGroupsSlice,
  removeIngredientGroupsSlice,
  addIngredientItemSlice,
  updateIngredientItemSlice,
  removeIngredientItemSlice,
} = ingredientGroupsSlice.actions;

export default ingredientGroupsSlice.reducer;
