import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Backdrop,
  Box,
  CircularProgress,
  DrawerProps,
  Grid,
  SelectChangeEvent,
} from '@mui/material';
import TopBar from '../TopBar';
import SideBar from '../SideBar';
import useAuth from '../../hooks/useAuth';
import { useAppSelector } from '../../store';
import useProfile from '../../hooks/useProfile';
import useUsers from '../../hooks/useUsers';
import { expiredAccountStorage } from '../../services/tokenStorage';
import useSettings from '../../hooks/useSettings';

interface Props {
  variant: DrawerProps['variant'];
  children: React.ReactNode;
}

function Layout({ variant, children }: Props) {
  const { logout } = useAuth();
  const { meProfile } = useProfile();
  const { changeBranch } = useUsers();
  const [selectValue, setSelectValue] = useState<string>('');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isUninstalled } = useSettings();

  const { loading, fullPage } = useAppSelector(state => state.backDrop);
  const [drawerExpanded, setDrawerExpanded] = useState(variant === 'permanent');
  const [variantName, setVariantName] = useState(variant);

  const isPosScreen = pathname.includes('pos');
  const isKitchenScreen = pathname.includes('kitchen');

  const handleChange = async (event: SelectChangeEvent<typeof selectValue>) => {
    const {
      target: { value },
    } = event;
    const selectedBranchId = meProfile?.branches?.find(
      item => item.name === value,
    )?.id;

    if (selectedBranchId) {
      await changeBranch(selectedBranchId);
      setSelectValue(value);

      if (isPosScreen) {
        await navigate('/app/pos');
      } else if (isKitchenScreen) {
        window.location.reload();
      } else {
        if (pathname.length === 1) {
          window.location.reload();
        }
        await navigate('/');
      }
    }
  };

  const token = expiredAccountStorage.getToken();

  useEffect(() => {
    if (token || isUninstalled) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    setDrawerExpanded(false);
    setVariantName(variant);
  }, [variant]);

  return (
    <Box sx={{ backgroundColor: 'neutral.main' }}>
      {variantName === 'permanent' ? (
        <Grid container>
          <Grid item xs={1}>
            <SideBar
              variant={variant}
              drawerExpanded={drawerExpanded}
              onClose={() => setDrawerExpanded(false)}
              onDrawerToggle={() => setDrawerExpanded(!drawerExpanded)}
              onLogout={() => logout()}
            />
          </Grid>
          <Grid item sx={{ minHeight: '100vh' }} xs={11}>
            <TopBar
              drawerExpanded={drawerExpanded}
              sideBarPermanent={variant === 'permanent'}
              onDrawerToggle={() => setDrawerExpanded(!drawerExpanded)}
              handleChange={handleChange}
              selectValue={selectValue}
              setSelectValue={setSelectValue}
            />
            <Box
              sx={{
                paddingY: 5,
                paddingLeft: {
                  xxs: 0,
                  xs: 4,
                  sm: 4,
                  md: 3,
                  lg: 4,
                  xl: 0,
                },
                paddingRight: {
                  xxs: 0,
                  xs: '10px',
                  sm: '30px',
                  md: '60px',
                  lg: '80px',
                  xl: '80px',
                },
                position: fullPage ? null : 'relative',
              }}
            >
              <Backdrop
                sx={{
                  color: 'primary.dark',
                  zIndex: theme => theme.zIndex.drawer + 1,
                  position: fullPage ? null : 'absolute',
                }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              {children}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid item sx={{ minHeight: '100vh' }} xs={12}>
          <TopBar
            drawerExpanded={drawerExpanded}
            sideBarPermanent={variant === 'permanent'}
            onDrawerToggle={() => setDrawerExpanded(!drawerExpanded)}
            handleChange={handleChange}
            selectValue={selectValue}
            setSelectValue={setSelectValue}
          />
          <SideBar
            variant={variant}
            drawerExpanded={drawerExpanded}
            onClose={() => setDrawerExpanded(false)}
            onDrawerToggle={() => setDrawerExpanded(!drawerExpanded)}
            onLogout={() => logout()}
          />
          <Box
            sx={{
              p: 5,
              position: fullPage ? null : 'relative',
            }}
          >
            <Backdrop
              sx={{
                color: 'primary.dark',
                zIndex: theme => theme.zIndex.drawer + 1,
                position: fullPage ? null : 'absolute',
              }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            {children}
          </Box>
        </Grid>
      )}
    </Box>
  );
}

export default Layout;
