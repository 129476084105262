import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import Modal from '../../../../../components/Modal';
import usePosOrder from '../../../../../hooks/usePosOrder';
import useProfile from '../../../../../hooks/useProfile';
import { PosOrder, PosTransaction } from '../../../../../models/Pos';
import getActiveLanguage from '../../../../../helpers/getActiveLanguage';
import LoadingState from '../../../../../components/LoadingState';
import EmptyState from '../../../../../components/EmptyState';
import styles from './styles';
import { SuspiciousTransaction } from '../../../../../services/api/operations/fraud/types';
import { NameTranslation } from '../../../../../models/General';

interface Props {
  open: boolean;
  onClose: () => void;
  tableId: string;
  onBackClick: () => void;
  tableName: string;
}
function SetActiveTransactionModal({
  open,
  onClose,
  tableId,
  onBackClick,
  tableName,
}: Props) {
  const { t } = useTranslation('pos');
  const {
    changeOrderTransactionActive,
    getInactiveOrderTransactionDetails,
    inActiveOrderDetails,
    orderDetailsLoading,
  } = usePosOrder();
  const { meProfile } = useProfile();
  const { enqueueSnackbar } = useSnackbar();

  const [transactionOrders, setTransactionOrders] = useState<PosOrder[]>([]);
  const [transactionPrices, setTransactionPrices] =
    useState<PosTransaction | null>(null);
  const [transactionId, setTransactionId] = useState('');

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = async () => {
    await changeOrderTransactionActive(transactionId);
    await onBackClick();
    enqueueSnackbar(
      t('orderModal.activeTransactionModal.reloadTransactionSuccess'),
      {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
      },
    );
  };

  useEffect(() => {
    if (open) {
      getInactiveOrderTransactionDetails(
        tableId,
        meProfile?.activeBranch?.day_id || '',
      );
    }
  }, [open]);

  return (
    <Modal
      maxWidth="xl"
      open={open}
      onClose={handleClose}
      title={t('orderModal.activeTransactionModal.title')}
      subtitle={t('orderModal.activeTransactionModal.subtitle')}
      negativeAction={t('orderModal.activeTransactionModal.cancel')}
      onNegativeClick={handleClose}
    >
      <Box sx={{ overflow: 'auto', maxHeight: '350px' }}>
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
          <Grid item xs={12} md={6}>
            <Typography>{tableName}</Typography>
            <TableContainer
              sx={{ ...styles.tableContainer, minHeight: '300px' }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                {orderDetailsLoading ? (
                  <LoadingState />
                ) : (
                  <TableBody>
                    {!inActiveOrderDetails?.length ? (
                      <EmptyState
                        message={t(
                          'orderModal.activeTransactionModal.emptyTransactions',
                        )}
                      />
                    ) : (
                      inActiveOrderDetails?.map(row => (
                        <TableRow
                          sx={{
                            ...styles.tableRow,
                            background:
                              row.id === transactionPrices?.id ? '#40bff5' : '',
                          }}
                          onClick={() => {
                            setTransactionOrders(row.orders || []);
                            setTransactionPrices(row || null);
                            setTransactionId(row.id);
                          }}
                        >
                          <TableCell>
                            {row.ended_at
                              ? format(
                                  new Date(row.ended_at),
                                  'dd.MM.yyyy HH:mm',
                                )
                              : '-'}
                          </TableCell>
                          <TableCell align="right">
                            {row.paid_amount.formatted}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              {t('orderModal.activeTransactionModal.details')}
            </Typography>
            <TableContainer sx={styles.tableContainer}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderDetailsLoading ? (
                    <LoadingState />
                  ) : (
                    transactionOrders?.map(row => (
                      <TableRow>
                        <TableCell>
                          {`${row.quantity} x ${
                            row.product
                              ? getActiveLanguage(row.product.translations).name
                              : row.items[0].product.translations
                              ? getActiveLanguage(
                                  row.items[0].product.translations,
                                ).name
                              : row.items[0].product_name
                          }`}
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            sx={{
                              fontSize: '14px',
                              textDecoration: row.is_treat
                                ? 'line-through'
                                : '',
                            }}
                          >
                            {row.total_amount.formatted}
                          </Typography>
                          {row.is_treat && (
                            <Button
                              variant="contained"
                              sx={{
                                borderRadius: '20px',
                                width: '50px',
                                height: '24px',
                                fontSize: '10px',
                                p: 0,
                                minWidth: '10px',
                              }}
                              color="error"
                              size="small"
                            >
                              {t('basketList.treat')}
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {transactionPrices && (
              <Box
                sx={{
                  background: '#f6f6f7',
                  border: '1px solid #e3e3e7',
                  borderRadius: '0 0 10px 10px',
                  padding: '15px 10px',
                  fontSize: '14px',
                }}
              >
                <Grid mb={1} container justifyContent="space-between">
                  <Grid>{t('payments.totalAmount')}</Grid>
                  <Grid>{transactionPrices?.total_amount.formatted}</Grid>
                </Grid>
                <Grid mb={1} container justifyContent="space-between">
                  <Grid>{t('payments.paidAmount')}</Grid>
                  <Grid>{transactionPrices?.paid_amount.formatted}</Grid>
                </Grid>
                <Grid mb={1} container justifyContent="space-between">
                  <Grid>{t('payments.totalDiscount')}</Grid>
                  <Grid>{transactionPrices?.total_discount.formatted}</Grid>
                </Grid>
                <Grid mb={1} container justifyContent="space-between">
                  <Grid>{t('payments.taxAmount')}</Grid>
                  <Grid>{transactionPrices?.tax_amount.formatted}</Grid>
                </Grid>
                {Number(transactionPrices?.service_charge_amount.amount) >
                  0 && (
                  <Grid mb={1} container justifyContent="space-between">
                    <Grid>{t('payments.serviceChargeAmount')}</Grid>
                    <Grid>
                      {transactionPrices?.service_charge_amount.formatted}
                    </Grid>
                  </Grid>
                )}
                {Number(transactionPrices?.couvert_amount.amount) > 0 && (
                  <Grid mb={1} container justifyContent="space-between">
                    <Grid>{t('payments.couvertAmount')}</Grid>
                    <Grid>{transactionPrices?.couvert_amount.formatted}</Grid>
                  </Grid>
                )}
                <Grid mb={1} container justifyContent="space-between">
                  <Grid>{t('payments.treatAmount')}</Grid>
                  <Grid>{transactionPrices?.treat_amount.formatted}</Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Grid>{t('payments.paymentTypes')}</Grid>
                  <Grid>
                    {transactionPrices?.payments.map(
                      (payment: {
                        amount: { formatted: string };
                        payment_type: { translations: NameTranslation[] };
                      }) => (
                        <Grid>
                          {`${
                            getActiveLanguage(
                              payment?.payment_type?.translations,
                            )?.name
                          }(${payment?.amount?.formatted})`}
                        </Grid>
                      ),
                    )}
                  </Grid>
                </Grid>
              </Box>
            )}
          </Grid>
          {transactionOrders.length ? (
            <Grid container justifyContent="right" sx={{ mt: 3 }}>
              <Button
                variant="contained"
                color="success"
                onClick={handleConfirm}
              >
                {t('orderModal.activeTransactionModal.confirm')}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Modal>
  );
}

export default SetActiveTransactionModal;
