import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BulkProductAPI,
  ProductAddMenuParams,
  ProductMenuAddItemParams,
  ProductMenuUpdateItemParams,
  ProductUpdateMenuParams,
} from '../../services/api/definitions/products/types';
import { isApiError } from '../../services/api/types';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getAllProductListThunk,
  updateBulkProductsThunk,
} from '../../store/slices/definitionsSlice/products';
import errorReporting from '../../helpers/errorReporting';
import api from '../../services/api';
import { updateProductSlice } from '../../store/slices/definitionsSlice/products/productsSlice';

const useProductsMenu = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const allProducts = useAppSelector(
    state => state.definitionsProducts.listAll,
  );

  const allProductLoading = useAppSelector(
    state => state.definitionsProducts.loading,
  );

  const allProductError = useAppSelector(
    state => state.definitionsProducts.error,
  );

  const fetchAllProducts = (
    name: string | '',
    category_id: string[] | null,
    is_bulk_update: boolean | null,
  ) => dispatch(getAllProductListThunk({ name, category_id, is_bulk_update }));

  const addProductMenu = async (params: ProductAddMenuParams) => {
    try {
      setLoading(true);
      await api.definitions.products.productAddMenu(params);
      const response = await api.definitions.products.view(params.productId);
      const result = response.data.data;
      dispatch(updateProductSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err;
    } finally {
      setLoading(false);
    }
    return null;
  };

  const updateBulkProduct = async (params: BulkProductAPI) => {
    try {
      setLoading(true);
      await dispatch(updateBulkProductsThunk(params)).unwrap();
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err;
    } finally {
      setLoading(false);
    }
    return null;
  };

  const updateProductMenu = async (
    params: ProductUpdateMenuParams & { productId: string },
  ) => {
    try {
      setLoading(true);
      await api.definitions.products.productUpdateMenu(params);
      const response = await api.definitions.products.view(params.productId);
      const result = response.data.data;
      dispatch(updateProductSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err;
    } finally {
      setLoading(false);
    }
    return null;
  };

  const removeProductMenu = async (params: {
    productId: string;
    id: string;
  }) => {
    try {
      setLoading(true);
      await api.definitions.products.productRemoveMenu(params.id);
      const response = await api.definitions.products.view(params.productId);
      const result = response.data.data;
      dispatch(updateProductSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err;
    } finally {
      setLoading(false);
    }
    return null;
  };

  const addProductMenuItem = async (params: ProductMenuAddItemParams) => {
    try {
      setLoading(true);
      await api.definitions.products.productMenuAddItem(params);
      const response = await api.definitions.products.view(params.productId);
      const result = response.data.data;
      dispatch(updateProductSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err;
    } finally {
      setLoading(false);
    }
    return null;
  };

  const updateProductMenuItem = async (params: ProductMenuUpdateItemParams) => {
    try {
      setLoading(true);
      await api.definitions.products.productMenuUpdateItem(params);
      const response = await api.definitions.products.view(params.productId);
      const result = response.data.data;
      dispatch(updateProductSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err;
    } finally {
      setLoading(false);
    }
    return null;
  };

  const removeProductMenuItem = async (params: {
    productId: string;
    id: string;
  }) => {
    try {
      setLoading(true);
      await api.definitions.products.productRemoveItemMenu(params.id);
      const response = await api.definitions.products.view(params.productId);
      const result = response.data.data;
      dispatch(updateProductSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err;
    } finally {
      setLoading(false);
    }
    return null;
  };

  return {
    allProducts,
    allProductLoading,
    allProductError,
    fetchAllProducts,
    addProductMenu,
    updateProductMenu,
    updateBulkProduct,
    removeProductMenu,
    addProductMenuItem,
    updateProductMenuItem,
    removeProductMenuItem,
    loading,
  };
};
export default useProductsMenu;
