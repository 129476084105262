/** Dependencies */
import React, { useEffect } from 'react';
import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

/** Styles */
import styles from '../styles';

/** helpers */
import getActiveLanguage from '../../../../../../../helpers/getActiveLanguage';

/** Types */
import { Menu, SelectedMenuItem } from '../types';

interface Props {
  menus: Menu[];
  menuItemsCountLength: (id: string, selectableQuantity: number) => number;
  selectedItem: SelectedMenuItem;
  setSelectedItem: (
    value:
      | ((prevState: SelectedMenuItem) => SelectedMenuItem)
      | SelectedMenuItem,
  ) => void;
}

function MenuModalRightSideCategories({
  menus,
  menuItemsCountLength,
  selectedItem,
  setSelectedItem,
}: Props) {
  const { t } = useTranslation('pos');

  return (
    <Box sx={styles.gridContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="left" spacing={0}>
            {menus.map((menuItem: Menu) => (
              <Grid item xs={6} sm={4} lg={3}>
                <Paper
                  sx={
                    selectedItem.selectedItem === menuItem.items
                      ? { ...styles.categoryContainer, background: '#c7c7c7' }
                      : { ...styles.categoryContainer }
                  }
                  onClick={() => {
                    setSelectedItem({
                      selectedItem: menuItem?.items,
                      selectedItemSelectableQuantity:
                        menuItem?.selectable_quantity,
                      selectedMenuId: menuItem?.id,
                    });
                  }}
                >
                  <Stack direction="row" sx={{ height: '100%' }}>
                    <Stack
                      sx={styles.textContainer}
                      justifyContent="space-between"
                    >
                      <Typography
                        sx={[styles.title, styles.nonSelectable] as any}
                      >
                        {getActiveLanguage(menuItem.translations).name}
                      </Typography>
                      <Typography
                        sx={{ ...styles.nonSelectable, color: 'red' }}
                        variant="caption"
                      >
                        {t('menu_right.remainder_selection')}:{' '}
                        {menuItemsCountLength(
                          menuItem.id,
                          menuItem.selectable_quantity,
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MenuModalRightSideCategories;
