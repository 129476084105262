/** Dependencies */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MuiColorInput } from 'mui-color-input';

/** Styles */
import { Grid, Typography } from '@mui/material';
import styles from '../Register/styles';

/** Components */
import Button from '../../components/Button';
import FormInput from '../../components/FormInput';

/** Types */
import { FormDataType } from './types';

/** Helpers */
import getActiveLanguage from '../../helpers/getActiveLanguage';
import { convertRGBtoHex } from '../../helpers/rgbToHex';

/** Hooks */
import useUtilities from '../../hooks/useUtilities';
import FormSwitch from '../../components/FormSwitch';
import useSettings from '../../hooks/useSettings';

interface Props {
  displayFormData: FormDataType;
  setFormData: (
    value: ((prevState: FormDataType) => FormDataType) | FormDataType,
  ) => void;
}
function GeneralSettingsForm({ displayFormData, setFormData }: Props) {
  const { t } = useTranslation('restaurantSettings');
  const { workingTypes, fetchWorkingTypes } = useUtilities();
  const { setDisplayScreen } = useSettings();

  const handleClick = () => {
    const data = {
      is_shown_logo: displayFormData?.is_shown_logo || false,
      name: displayFormData?.name || '',
      caption: displayFormData?.caption || '',
      font_size: displayFormData?.font_size || 14,
      product_font_size: displayFormData?.product_font_size || 14,
      theme_color: convertRGBtoHex(displayFormData?.theme_color) || '',
      text_color: convertRGBtoHex(displayFormData?.text_color) || '',
      is_active: displayFormData?.is_active || false,
      working_types: displayFormData.working_types,
    };
    // @ts-ignore
    setDisplayScreen(data);
  };

  useEffect(() => {
    if (!workingTypes.length) {
      fetchWorkingTypes();
    }
  }, []);

  return (
    <Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormSwitch
            value={displayFormData.is_active}
            onChange={() =>
              setFormData({
                ...displayFormData,
                is_active: !displayFormData.is_active,
              })
            }
            label={t('displaySettings.is_active')}
          />
        </Grid>
        <Grid item xs={12}>
          <FormSwitch
            disabled={!displayFormData.is_active}
            value={displayFormData.is_shown_logo}
            onChange={() =>
              setFormData({
                ...displayFormData,
                is_shown_logo: !displayFormData.is_shown_logo,
              })
            }
            label={t('displaySettings.is_shown_logo')}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            disabled={!displayFormData.is_active}
            value={displayFormData.name}
            onChange={e =>
              setFormData({
                ...displayFormData,
                name: e.target.value,
              })
            }
            label={t('displaySettings.name')}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            disabled={!displayFormData.is_active}
            value={displayFormData.caption}
            onChange={e =>
              setFormData({
                ...displayFormData,
                caption: e.target.value,
              })
            }
            label={t('displaySettings.caption')}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            disabled={!displayFormData.is_active}
            type="number"
            value={displayFormData.font_size}
            onChange={e =>
              setFormData({
                ...displayFormData,
                font_size: Number(e.target.value),
              })
            }
            label={t('displaySettings.font_size')}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            disabled={!displayFormData.is_active}
            type="number"
            value={displayFormData.product_font_size}
            onChange={e =>
              setFormData({
                ...displayFormData,
                product_font_size: Number(e.target.value),
              })
            }
            label={t('displaySettings.product_font_size')}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">
            {t('displaySettings.theme_color')}
          </Typography>
          <MuiColorInput
            disabled={!displayFormData.is_active}
            sx={{ width: '100%' }}
            value={displayFormData.theme_color}
            format="hex"
            onChange={e =>
              setFormData({
                ...displayFormData,
                theme_color: e,
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">
            {t('displaySettings.text_color')}
          </Typography>
          <MuiColorInput
            disabled={!displayFormData.is_active}
            sx={{ width: '100%' }}
            value={displayFormData.text_color}
            format="hex"
            onChange={e =>
              setFormData({
                ...displayFormData,
                text_color: e,
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {t('displaySettings.working_types')}
          </Typography>
          {workingTypes.map(type => (
            <Grid item>
              <FormSwitch
                disabled={!displayFormData.is_active}
                label={getActiveLanguage(type.translations).name}
                onClick={e => {
                  e.stopPropagation();
                  setFormData({
                    ...displayFormData,
                    working_types: displayFormData.working_types.find(
                      item => item === type.id,
                    )
                      ? [
                          ...displayFormData.working_types.filter(
                            f => f !== type.id,
                          ),
                        ]
                      : [...displayFormData.working_types, type.id],
                  });
                }}
                value={displayFormData.working_types.find(
                  item => item === type.id,
                )}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="right">
            <Button
              onClick={handleClick}
              sx={styles.submit}
              variant="contained"
              size="large"
            >
              {t('generalSettings.actions.submit')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GeneralSettingsForm;
