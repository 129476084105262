import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { isApiError } from '../../services/api/types';
import { useAppDispatch, useAppSelector } from '../../store';
import { getTableListThunk } from '../../store/slices/definitionsSlice/tables';
import api from '../../services/api';
import {
  addTableSlice,
  moveDownSlice,
  moveUpSlice,
  removeTableSlice,
  updateTableSlice,
  updateTableSliceWithPusher,
} from '../../store/slices/definitionsSlice/tables/tablesSlice';
import { UpdateResponse } from '../../services/api/definitions/tables/types';

const useTables = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const tables = useAppSelector(state => state.definitionsTables.tables);
  const tableLoading = useAppSelector(state => state.definitionsTables.loading);
  const tableError = useAppSelector(state => state.definitionsTables.error);

  const fetchTables = (sectionId: string) =>
    dispatch(getTableListThunk(sectionId));

  const addTable = async (name: string, sectionId: string) => {
    try {
      const response = await api.definitions.tables.create(name, sectionId);
      const result = response.data.data;
      dispatch(addTableSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const addCountTable = async (
    name: string,
    sectionId: string,
    count: number,
  ) => {
    try {
      const response = await api.definitions.tables.createCollective(
        name,
        sectionId,
        count,
      );
      const result = response.data.data;
      dispatch(addTableSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const updateTable = async ({
    id,
    name,
    sectionId,
  }: {
    id: string;
    name: string;
    sectionId: string;
  }) => {
    try {
      const response = await api.definitions.tables.update(id, name, sectionId);
      const result = response.data.data;
      dispatch(updateTableSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const updateTableWithPusher = async (result: UpdateResponse[]) => {
    try {
      await dispatch(updateTableSliceWithPusher(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const updateCountTable = async ({
    id,
    name,
    sectionId,
    count,
  }: {
    id: string;
    name: string;
    sectionId: string;
    count: number;
  }) => {
    try {
      const response = await api.definitions.tables.update(id, name, sectionId);
      const result = response.data.data;
      dispatch(addTableSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const removeTable = async (id: string) => {
    try {
      const response = await api.definitions.tables.remove(id);
      const result = response.data.data;
      dispatch(removeTableSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const moveUpTable = async (id: string) => {
    try {
      const response = await api.definitions.tables.moveUp(id);
      const result = response.data;
      dispatch(moveUpSlice({ id }));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const moveDownTable = async (id: string) => {
    try {
      const response = await api.definitions.tables.moveDown(id);
      const result = response.data;
      dispatch(moveDownSlice({ id }));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  return {
    tables,
    tableLoading,
    tableError,
    fetchTables,
    addTable,
    updateTable,
    removeTable,
    moveUpTable,
    moveDownTable,
    addCountTable,
    updateCountTable,
    updateTableWithPusher,
  };
};
export default useTables;
