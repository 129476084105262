/** Dependencies */
import { createAsyncThunk } from '@reduxjs/toolkit';

/** Services */
import api from '../../../../services/api';

export const getCustomerAddressListThunk = createAsyncThunk(
  'customerAddress/list',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.definitions.customerAddresses.list(id);
      const result = response.data.data;
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
