import { Box, TextField, Typography, TextFieldProps } from '@mui/material';
import React, { forwardRef } from 'react';
import { NumberFormatProps } from 'react-number-format';
import styles from './styles';

interface Props {
  disableUnderline?: boolean;
  maskProps?: NumberFormatProps;
  min?: number;
}

function FormInput(
  {
    label,
    sx,
    maskProps,
    disableUnderline = false,
    min,
    ...props
  }: Props & TextFieldProps,
  ref: TextFieldProps['ref'],
) {
  return (
    <Box
      sx={[styles.container, ...(sx ? (Array.isArray(sx) ? sx : [sx]) : [])]}
    >
      <Typography variant="subtitle1">{label}</Typography>
      <TextField
        ref={ref}
        variant="filled"
        color="success"
        hiddenLabel
        InputProps={{
          disableUnderline,
          inputProps: { ...maskProps, min: min || 0 },
        }}
        fullWidth
        {...props}
      />
    </Box>
  );
}

export default forwardRef(FormInput);
