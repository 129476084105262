import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { getExpenseCategoryListThunk } from '../../store/slices/operationsSlice/expenseCategories';
import {
  ExpenseCategoryCreateParams,
  ExpenseCategoryUpdateParams,
} from '../../services/api/operations/expenseCategories/types';
import { isApiError } from '../../services/api/types';
import api from '../../services/api';
import {
  addExpenseCategorySlice,
  removeExpenseCategorySlice,
  updateExpenseCategorySlice,
} from '../../store/slices/operationsSlice/expenseCategories/expenseCategoriesSlice';

const useExpenseCategories = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const expenseCategories = useAppSelector(
    state => state.operationsExpenseCategories.expenseCategories,
  );
  const expenseCategoryLoading = useAppSelector(
    state => state.operationsExpenseCategories.loading,
  );

  const expenseCategoryError = useAppSelector(
    state => state.operationsExpenseCategories.error,
  );

  const fetchExpenseCategories = () => dispatch(getExpenseCategoryListThunk());

  const addExpenseCategory = async (params: ExpenseCategoryCreateParams) => {
    try {
      const response = await api.operations.expenseCategories.create(params);
      const result = response.data.data;
      dispatch(addExpenseCategorySlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const updateExpenseCategory = async (params: ExpenseCategoryUpdateParams) => {
    try {
      const response = await api.operations.expenseCategories.update(params);
      const result = response.data.data;
      dispatch(updateExpenseCategorySlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const removeExpenseCategory = async (id: string) => {
    try {
      const response = await api.operations.expenseCategories.remove(id);
      const result = response.data.data;
      dispatch(removeExpenseCategorySlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  return {
    expenseCategories,
    expenseCategoryLoading,
    expenseCategoryError,
    fetchExpenseCategories,
    addExpenseCategory,
    updateExpenseCategory,
    removeExpenseCategory,
  };
};
export default useExpenseCategories;
