import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../services/api';
import arrayCatcher from '../../../../helpers/errorCatcher';

export const getPrinterListThunk = createAsyncThunk(
  'printer/list',
  async (params: { page: number }, { rejectWithValue }) => {
    try {
      const response = await api.settings.printerSettings.list(params.page);
      return response.data;
    } catch (err: any) {
      arrayCatcher(err?.code);
      return rejectWithValue(err);
    }
  },
);

export const getPrinterThunk = createAsyncThunk(
  'printer/get',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.settings.printerSettings.view(id);
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
