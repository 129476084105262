export const singlePriceFormatter = (
  amount: string | undefined,
  count: number,
  currencyCode: string,
) => {
  const parsedAmount = parseFloat(amount || '0') / 100;
  const formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: currencyCode || 'TRY',
  });
  return formatter.format(parsedAmount * count);
};

export const priceFormatter = (amount: number, currencyCode: string) => {
  const formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: currencyCode || 'TRY',
  });

  return formatter.format(amount);
};
