import api from '../../api';
import { CreateAndUpdateUser, CreateFlow, UpdateFlow } from './types';

const flowList = (page: number) =>
  api.get('/frauds/flows', {
    params: {
      page,
    },
  });
const getFlowById = (id: string) => api.get(`/frauds/flows/${id}`);

const suspiciousTransactionList = (page: number) =>
  api.get('/frauds/suspicious-transactions', {
    params: {
      page,
    },
  });

const createFlow = (params: CreateFlow) => api.post('/frauds/flows', params);

const updateFlow = (params: UpdateFlow) =>
  api.put(`/frauds/flows/${params.id}`, params);

const createUser = (params: CreateAndUpdateUser) =>
  api.post(`/frauds/flows/${params.flow_id}/notification-users`, params);

const updateUser = (params: CreateAndUpdateUser) =>
  api.put(
    `/frauds/flows/${params.flow_id}/notification-users/${params.notification_user_id}`,
    params,
  );

const deleteUser = (flowId: string, notificationUserId: string) => {
  api.delete(
    `/frauds/flows/${flowId}/notification-users/${notificationUserId}`,
  );
};
export default {
  flowList,
  getFlowById,
  suspiciousTransactionList,
  createFlow,
  updateFlow,
  createUser,
  updateUser,
  deleteUser,
};
