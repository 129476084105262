import { Stack, Box, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../../components/Button';
import FormInputSelect from '../../../../../../components/FormInputSelect';
import FormInput from '../../../../../../components/FormInput';
import Icon from '../../../../../../components/Icon';
import IconButtonWithTooltip from '../../../../../../components/IconButtonWithTooltip';
import Modal from '../../../../../../components/Modal';
import { useAppSelector } from '../../../../../../store';
import { defaultValues, MenuAddFormValues, validationSchema } from './types';
import useProductsMenu from '../../../../../../hooks/useProductMenu';
import errorReporting from '../../../../../../helpers/errorReporting';
import { OptionsLanguageMemo } from '../../../../../../helpers/optionMemo';

interface Props {
  open: boolean;
  onClose: () => void;
  initialValues?: MenuAddFormValues;
  productId?: string;
  productMenuId?: string;
}

function MenuAddModal({
  open,
  onClose,
  initialValues,
  productId,
  productMenuId,
}: Props) {
  const { t } = useTranslation('product');
  const { addProductMenu, updateProductMenu, loading } = useProductsMenu();

  const { handleSubmit, control, reset } = useForm<MenuAddFormValues>({
    defaultValues: initialValues || defaultValues,
    resolver: validationSchema,
  });

  useEffect(() => reset(initialValues), [initialValues]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'name',
  });

  const { list } = useAppSelector(state => state.languages);
  const options = OptionsLanguageMemo(list);

  const handleConfirm = () => {
    handleSubmit(async values => {
      try {
        if (initialValues && Number(initialValues.quantity) > 0) {
          if (productId && productMenuId) {
            await updateProductMenu({
              productId,
              productMenuId,
              name: values.name,
              selectableQuantity: Number(values.quantity),
            });
            reset(initialValues);
            onClose();
          }
        } else if (productId) {
          await addProductMenu({
            productId,
            name: values.name,
            selectableQuantity: Number(values.quantity),
          });
          reset(initialValues);
          onClose();
        }
      } catch (err) {
        errorReporting(err);
      }
    })();
  };

  return (
    <Modal
      title={t('menu.addModal.title', {
        context:
          initialValues && Number(initialValues.quantity) > 0
            ? 'edit'
            : 'default',
      })}
      titleIconName={
        initialValues && Number(initialValues.quantity) > 0 ? 'Edit' : 'Add'
      }
      open={open}
      onClose={onClose}
      positiveAction={t('menu.addModal.confirm')}
      negativeAction={t('menu.addModal.cancel')}
      onPositiveClick={handleConfirm}
      onNegativeClick={onClose}
      loading={loading}
    >
      <Box
        sx={{
          p: 2,
          border: 1,
          borderRadius: 3,
          borderColor: 'neutral.dark',
          mb: 2,
        }}
      >
        <Stack
          sx={{ mb: 2 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>{t('menu.addModal.form.name.label')}</Typography>

          <Button
            onClick={() => append({ lang: 'en', text: '' })}
            color="success"
            size="small"
            startIcon={<Icon name="Add" />}
            disabled={options.length === fields.length}
          >
            {t('menu.addModal.form.name.add')}
          </Button>
        </Stack>
        {fields.map((item, index) => (
          <Stack key={item.id} direction="row" alignItems="center">
            <Box sx={{ flex: 1 }}>
              <Controller
                name={`name.${index}.text`}
                control={control}
                render={({ field, fieldState }) => (
                  <FormInputSelect
                    selectName={`name.${index}.lang`}
                    selectControl={control}
                    error={fieldState.invalid}
                    helperText={
                      fieldState.error
                        ? t(fieldState.error.message as any)
                        : undefined
                    }
                    options={options}
                    {...field}
                  />
                )}
              />
            </Box>
            <IconButtonWithTooltip
              sx={{ mr: 0.5, mb: 1.5 }}
              tip={t('menu.addModal.form.name.remove')}
              iconName="Delete"
              onClick={() => remove(index)}
              color="error"
              disabled={fields.length < 2}
            />
          </Stack>
        ))}
      </Box>

      <Controller
        name="quantity"
        control={control}
        render={({ field, fieldState }) => (
          <FormInput
            label={t('menu.addModal.form.quantity.label')}
            type="number"
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            {...field}
          />
        )}
      />
    </Modal>
  );
}

export default MenuAddModal;
