/** Dependencies */
import React from 'react';
import { Box, Typography, List, ListItem } from '@mui/material';

import { useTranslation } from 'react-i18next';

/** Styles */
import styles from '../styles';

/** helpers */
import getActiveLanguage from '../../../../../../../helpers/getActiveLanguage';

/** Components */
import useBasketItems from '../../../../../../../hooks/useBasketItems/useBasketItems';
import {
  MenuProduct,
  Menus,
} from '../../../../../../../store/slices/basketItemSlice/types';
import { ActiveBasketProps } from '../../../LeftColumn/BasketList/types';

interface Props {
  item: MenuProduct;
}

function MenuModalLeftSideMenuItemsNameAndPropertyInfo({ item }: Props) {
  const { productMenus } = useBasketItems();
  const { t } = useTranslation('pos');

  return (
    <Box>
      <Typography sx={styles.title}>
        {getActiveLanguage(item.product_translations).name}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{ fontSize: { xs: 12, sm: 11, lg: 14 } }}
      >
        {getActiveLanguage(item.product_unit.translations).name}
      </Typography>
      {productMenus && (
        <Typography sx={styles.properties} variant="subtitle2">
          <List>
            <ListItem sx={styles.priceInfo}>
              {item.active_single_property_name?.name}
            </ListItem>

            {item.active_multiple_property_names?.map(i => (
              <ListItem sx={styles.priceInfo}>{i.name}</ListItem>
            ))}
          </List>

          {item.non_ingredient_item_id?.length ? (
            <Typography sx={styles.priceInfo}>
              {t('menuModel.material')} (+
              {item.non_ingredient_item_id?.length})
            </Typography>
          ) : (
            ''
          )}
        </Typography>
      )}
    </Box>
  );
}

export default MenuModalLeftSideMenuItemsNameAndPropertyInfo;
