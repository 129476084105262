/** Dependencies */
import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Components */
import EmptyState from '../../../../components/EmptyState';

/** Hooks */
import useProfile from '../../../../hooks/useProfile';

/** Helpers */
import keyHasPermission from '../../../../helpers/keyHasPermission';

/** Styles */
import styles from '../styles';
// @ts-ignore
import {
  salesChannelNameStorage,
  salesChannelStorage,
} from '../../../../services/tokenStorage';

interface Props {
  handleSubmit: () => void;
  setIsChangeable: (value: ((prevState: boolean) => boolean) | boolean) => void;
  setIsMoveOrder: (value: ((prevState: boolean) => boolean) | boolean) => void;
  setIsMergeable: (value: ((prevState: boolean) => boolean) | boolean) => void;
}

function SectionSplitButtons({
  handleSubmit,
  setIsMoveOrder,
  setIsMergeable,
  setIsChangeable,
}: Props) {
  const { t } = useTranslation('table');

  const { meProfile } = useProfile();

  const hasShowSections = keyHasPermission(
    'view-section-listing',
    meProfile?.permissions || [],
  );

  return hasShowSections ? (
    <Grid sx={styles.tableGrid}>
      <Button
        variant="contained"
        size="large"
        color="error"
        sx={styles.tableButton}
        onClick={() => {
          setIsChangeable(false);
          setIsMergeable(false);
          setIsMoveOrder(false);
        }}
      >
        {t('section.add_modal.cancel')}
      </Button>
      <Button
        variant="contained"
        size="large"
        sx={styles.tableButton}
        onClick={() => {
          handleSubmit();
          setIsChangeable(false);
          setIsMergeable(false);
          setIsMoveOrder(false);
        }}
      >
        {t('section.add_modal.confirm')}
      </Button>
    </Grid>
  ) : (
    <EmptyState message={t('empty')} />
  );
}

export default SectionSplitButtons;
