import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../components/FormInput';
import FormPhoneInput from '../../../../components/FormPhoneInput';
import Button from '../../../../components/Button';
import { NotificationUser } from '../../../../services/api/operations/fraud/types';
import useFlow from '../../../../hooks/useFlow';
import { isApiError } from '../../../../services/api/types';

interface Props {
  notificationUsers: NotificationUser[] | null;
  setNotificationUsers: React.Dispatch<
    React.SetStateAction<NotificationUser[] | null>
  >;
  onClose: () => void;
  created: () => void;
  item?: NotificationUser | null;
  id?: string;
}

export function PersonForm({
  notificationUsers,
  setNotificationUsers,
  onClose,
  created,
  item,
  id,
}: Props) {
  const { createUser, updateUser } = useFlow();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('frauds');

  const [value, setValue] = useState('');
  const [phone, setPhone] = useState('');
  const [info, setInfo] = useState<any>(null);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleChange = (newValue: any, info: any) => {
    setPhone(newValue);
    setInfo(info);
  };

  const handleSave = async (type: string) => {
    try {
      const newUser = {
        full_name: value,
        phone_number_country: info.countryCode,
        phone_number_code: item
          ? `${info.countryCallingCode}`
          : `+${info.countryCallingCode}`,
        phone_number: info.nationalNumber,
      };

      if (!value || !phone) {
        enqueueSnackbar(t('flows.addModal.snackbarMessages.emptyPhoneOrUser'), {
          variant: 'warning',
          style: { whiteSpace: 'pre-line' },
        });
      } else if (
        notificationUsers?.find(
          user => user.phone_number === info.nationalNumber,
        ) &&
        !id
      ) {
        enqueueSnackbar(t('flows.addModal.snackbarMessages.phoneNotUniq'), {
          variant: 'warning',
          style: { whiteSpace: 'pre-line' },
        });
      } else {
        if (id) {
          if (item?.id) {
            await updateUser({
              ...newUser,
              flow_id: id,
              notification_user_id: item.id,
            });
            setNotificationUsers(
              notificationUsers?.map(user =>
                user?.id === item?.id ? newUser : { ...user, id: user.id },
              ) || [],
            );
          } else {
            await createUser({
              ...newUser,
              flow_id: id,
              notification_user_id: '',
            });
            setNotificationUsers(
              notificationUsers ? [...notificationUsers, newUser] : [newUser],
            );
            created();
          }
        } else if (notificationUsers) {
          setNotificationUsers([...notificationUsers, newUser]);
        } else {
          setNotificationUsers([newUser]);
        }

        if (type === 'saveAndClose') {
          onClose();
        } else {
          setValue('');
          setPhone('');
          setInfo(null);
        }
      }
    } catch (err) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
    }
  };

  useEffect(() => {
    if (item) {
      setValue(item.full_name);
      setPhone(item.phone_number);
      setInfo({
        countryCode: item.phone_number_country,
        countryCallingCode: item.phone_number_code,
        nationalNumber: item.phone_number,
      });
    }
  }, [item]);

  return (
    <Grid xs={5}>
      <Typography fontSize="18px" sx={{ marginBottom: '15px' }}>
        {t('flows.addModal.forms.personForm.title')}
      </Typography>
      <Grid>
        <FormInput
          value={value}
          onChange={e => setValue(e.target.value)}
          label={t('flows.addModal.forms.personForm.name')}
        />
      </Grid>
      <Grid>
        <FormPhoneInput
          phoneValue={phone}
          handleChange={handleChange}
          label={t('flows.addModal.forms.personForm.phone')}
        />
      </Grid>
      <Grid>
        <Button
          onClick={() => handleSave('save')}
          color="inherit"
          fullWidth
          sx={{ marginTop: '15px' }}
        >
          {item
            ? t('flows.addModal.forms.personForm.buttons.saveAndCloseEdit')
            : t('flows.addModal.forms.personForm.buttons.saveAndClose')}
        </Button>
        <Button
          onClick={() => handleSave('saveAndClose')}
          fullWidth
          sx={{ marginTop: '15px' }}
        >
          {item
            ? t('flows.addModal.forms.personForm.buttons.saveEdit')
            : t('flows.addModal.forms.personForm.buttons.save')}
        </Button>
      </Grid>
    </Grid>
  );
}
