/** Dependencies */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

/** Styles */
import { Grid, Box, IconButton, CircularProgress } from '@mui/material';
import styles from '../../../Register/styles';

/** Components */
import Button from '../../../../components/Button';
import FormInput from '../../../../components/FormInput';
import FormSelect from '../../../../components/FormSelect';
import LoadingState from '../../../../components/LoadingState';

/** Types */
import {
  validationGeneralSettingsSchema,
  GeneralSettingFromValues,
} from './types';

/** Helpers */
import getActiveLanguage from '../../../../helpers/getActiveLanguage';

/** Hooks */
import useSettings from '../../../../hooks/useSettings';
import useUtilities from '../../../../hooks/useUtilities';
import keyHasPermission from '../../../../helpers/keyHasPermission';
import FormSelectSearchable from '../../../../components/FormSelectSearchable';
import useProducts from '../../../../hooks/useProducts';
import Icon from '../../../../components/Icon';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import ImageShowModal from '../../../../components/ImageShowModal';
import { OptionsMemo } from '../../../../helpers/optionMemo';

function GeneralSettingsForm() {
  const { t } = useTranslation('restaurantSettings');
  const {
    fetchGeneralSettings,
    updateGeneralSettings,
    branchSettings,
    meProfile,
    fetchMe,
    fetchTimeZones,
    timeZones,
  } = useSettings();
  const { workingTypes, currencies, fetchWorkingTypes, fetchCurrencies } =
    useUtilities();
  const { updateImage, deleteImage } = useProducts();

  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageData, setSelectedImageData] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean | null>(
    null,
  );
  const [openImage, setOpenImage] = useState(false);

  const initialValues: GeneralSettingFromValues = {
    name: branchSettings?.branch?.name || '',
    start_time: branchSettings?.start_time || '07:00',
    working_types: branchSettings?.working_types?.map(
      workingType => workingType.id,
    ) || [2, 3],
    currency_id: branchSettings?.currency?.id || 1,
    timezone: branchSettings?.timezone || 'EUROPE_ISTANBUL',
  };

  const { handleSubmit, control, reset } = useForm<GeneralSettingFromValues>({
    defaultValues: initialValues,
    resolver: validationGeneralSettingsSchema,
  });

  // todo: bu da generic yapılabilir
  const handleFileChange = async (e: any) => {
    setLoadingImage(true);
    if (branchSettings) {
      await updateImage(
        meProfile?.activeBranch?.id || '',
        'branch',
        e.target.files[0],
      );
    }

    const file = e.target.files[0];
    if (file && Number(file?.size) < 2000000) {
      const formData = new FormData();
      formData.append('image', e.target.files[0]);

      // @ts-ignore
      setSelectedImage(URL.createObjectURL(file));
      setSelectedImageData(file);
    }

    setLoadingImage(false);
  };

  const handleRemove = async () => {
    if (branchSettings) {
      await deleteImage(meProfile?.activeBranch?.id || '', 'branch');
    }
    setSelectedImage(null);
    setDeleteConfirmation(null);
  };

  useEffect(() => {
    if (branchSettings?.image_url) {
      // @ts-ignore
      setSelectedImage(branchSettings.image_url);
    } else {
      setSelectedImage(null);
    }
  }, [branchSettings]);

  const handleClick = () => {
    handleSubmit(async values => {
      setLoading(true);
      try {
        setLoading(true);
        await updateGeneralSettings({
          ...values,
          active_branch_id: meProfile?.activeBranch?.id || '',
        });
        reset(initialValues);
        fetchGeneralSettings();
        fetchMe();
        // eslint-disable-next-line no-empty
      } catch (err) {
      } finally {
        setLoading(false);
      }
    })();
  };

  useEffect(() => {
    fetchGeneralSettings();
    reset(initialValues);
  }, [meProfile]);

  useEffect(() => {
    reset(initialValues);
  }, [branchSettings]);

  useEffect(() => {
    if (!workingTypes.length) {
      fetchWorkingTypes();
    }
  }, []);

  useEffect(() => {
    if (!currencies.length) {
      fetchCurrencies();
    }
  }, []);

  useEffect(() => {
    fetchTimeZones();
  }, []);

  const timeOptions = [
    { id: '00:00', text: '00:00' },
    { id: '00:15', text: '00:15' },
    { id: '00:30', text: '00:30' },
    { id: '00:45', text: '00:45' },
    { id: '01:00', text: '01:00' },
    { id: '01:15', text: '01:15' },
    { id: '01:30', text: '01:30' },
    { id: '01:45', text: '01:45' },
    { id: '02:00', text: '02:00' },
    { id: '02:15', text: '02:15' },
    { id: '02:30', text: '02:30' },
    { id: '02:45', text: '02:45' },
    { id: '03:00', text: '03:00' },
    { id: '03:15', text: '03:15' },
    { id: '03:30', text: '03:30' },
    { id: '03:45', text: '03:45' },
    { id: '04:00', text: '04:00' },
    { id: '04:15', text: '04:15' },
    { id: '04:30', text: '04:30' },
    { id: '04:45', text: '04:45' },
    { id: '05:00', text: '05:00' },
    { id: '05:15', text: '05:15' },
    { id: '05:30', text: '05:30' },
    { id: '05:45', text: '05:45' },
    { id: '06:00', text: '06:00' },
    { id: '06:15', text: '06:15' },
    { id: '06:30', text: '06:30' },
    { id: '06:45', text: '06:45' },
    { id: '07:00', text: '07:00' },
    { id: '07:15', text: '07:15' },
    { id: '07:30', text: '07:30' },
    { id: '07:45', text: '07:45' },
    { id: '08:00', text: '08:00' },
    { id: '08:15', text: '08:15' },
    { id: '08:30', text: '08:30' },
    { id: '08:45', text: '08:45' },
    { id: '09:00', text: '09:00' },
    { id: '09:15', text: '09:15' },
    { id: '09:30', text: '09:30' },
    { id: '09:45', text: '09:45' },
    { id: '10:00', text: '10:00' },
    { id: '10:15', text: '10:15' },
    { id: '10:30', text: '10:30' },
    { id: '10:45', text: '10:45' },
    { id: '11:00', text: '11:00' },
    { id: '11:15', text: '11:15' },
    { id: '11:30', text: '11:30' },
    { id: '11:45', text: '11:45' },
    { id: '12:00', text: '12:00' },
    { id: '12:15', text: '12:15' },
    { id: '12:30', text: '12:30' },
    { id: '12:45', text: '12:45' },
    { id: '13:00', text: '13:00' },
    { id: '13:15', text: '13:15' },
    { id: '13:30', text: '13:30' },
    { id: '13:45', text: '13:45' },
    { id: '14:00', text: '14:00' },
    { id: '14:15', text: '14:15' },
    { id: '14:30', text: '14:30' },
    { id: '14:45', text: '14:45' },
    { id: '15:00', text: '15:00' },
    { id: '15:15', text: '15:15' },
    { id: '15:30', text: '15:30' },
    { id: '15:45', text: '15:45' },
    { id: '16:00', text: '16:00' },
    { id: '16:15', text: '16:15' },
    { id: '16:30', text: '16:30' },
    { id: '16:45', text: '16:45' },
    { id: '17:00', text: '17:00' },
    { id: '17:15', text: '17:15' },
    { id: '17:30', text: '17:30' },
    { id: '17:45', text: '17:45' },
    { id: '18:00', text: '18:00' },
    { id: '18:15', text: '18:15' },
    { id: '18:30', text: '18:30' },
    { id: '18:45', text: '18:45' },
    { id: '19:00', text: '19:00' },
    { id: '19:15', text: '19:15' },
    { id: '19:30', text: '19:30' },
    { id: '19:45', text: '19:45' },
    { id: '20:00', text: '20:00' },
    { id: '20:15', text: '20:15' },
    { id: '20:30', text: '20:30' },
    { id: '20:45', text: '20:45' },
    { id: '21:00', text: '21:00' },
    { id: '21:15', text: '21:15' },
    { id: '21:30', text: '21:30' },
    { id: '21:45', text: '21:45' },
    { id: '22:00', text: '22:00' },
    { id: '22:15', text: '22:15' },
    { id: '22:30', text: '22:30' },
    { id: '22:45', text: '22:45' },
    { id: '23:00', text: '23:00' },
    { id: '23:15', text: '23:15' },
    { id: '23:30', text: '23:30' },
    { id: '23:45', text: '23:45' },
  ];

  const durationTimeOptions = [
    { id: 0, text: '0' },
    { id: 5, text: '5' },
    { id: 10, text: '10' },
    { id: 15, text: '15' },
    { id: 30, text: '30' },
    { id: 60, text: '60' },
    { id: 90, text: '90' },
  ];

  const currencyOptions = useMemo(
    () =>
      currencies.map(item => ({
        id: item.id,
        text: `${item.name} - ${item.symbol}`,
      })),
    [currencies],
  );

  const hasUpdateGeneralSettings = keyHasPermission(
    'update-general-settings',
    meProfile?.permissions || [],
  );

  useEffect(() => {
    if (branchSettings?.branch?.logo_url) {
      // @ts-ignore
      setSelectedImage(branchSettings?.branch?.logo_url);
    } else {
      setSelectedImage(null);
    }
  }, [branchSettings]);

  if (loading) {
    return <LoadingState />;
  }

  return (
    <Box
      sx={{
        py: 10,
        px: 10,
        paddingX: {
          xxs: 0,
          xs: 0,
          sm: 0,
          md: 3,
          lg: 15,
          xl: 20,
        },
      }}
    >
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid item mt={{ xs: 3, sm: 0 }} xs={12} sm={12}>
              <Box
                sx={{
                  p: 2,
                  border: 1,
                  borderRadius: 3,
                  borderColor: 'neutral.dark',
                  mb: 2,
                }}
              >
                <Grid container justifyContent="space-between">
                  <Grid item>
                    {selectedImage || loadingImage ? (
                      loadingImage ? (
                        <Grid style={{ width: '100px', height: '100px' }}>
                          <CircularProgress />
                        </Grid>
                      ) : (
                        <Grid onClick={() => setOpenImage(true)}>
                          <img
                            style={{
                              width: '100px',
                              height: '100px',
                              cursor: 'pointer',
                            }}
                            src={selectedImage || branchSettings?.image_url}
                            alt="Uploaded"
                          />
                        </Grid>
                      )
                    ) : (
                      t('add_image')
                    )}
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      size="small"
                      startIcon={<Icon name="Upload" />}
                      onClick={() =>
                        // @ts-ignore
                        document.getElementById('image-input').click()
                      }
                    >
                      {!selectedImage ? t('select_image') : t('change_image')}
                    </Button>
                    {selectedImage && (
                      <IconButton
                        color="error"
                        onMouseDown={e => e.stopPropagation()}
                        onClick={() => setDeleteConfirmation(true)}
                      >
                        <Icon
                          sx={{ width: '30px', height: '30px' }}
                          name="Delete"
                        />
                      </IconButton>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="image-input"
                      onChange={handleFileChange}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <FormInput
                  // sx={styles.field}
                  label={t('generalSettings.name.label')}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  {...field}
                  disabled={!hasUpdateGeneralSettings}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="start_time"
              control={control}
              render={({ field, fieldState }) => (
                <FormSelect
                  label={t('generalSettings.start_time')}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  underline
                  options={timeOptions}
                  {...field}
                  disabled={!hasUpdateGeneralSettings}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="currency_id"
              control={control}
              render={({ field, fieldState }) => (
                <FormSelect
                  sx={styles.field}
                  label={t('generalSettings.currency')}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  underline
                  options={currencyOptions}
                  {...field}
                  disabled={!hasUpdateGeneralSettings}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="working_types"
              control={control}
              render={({ field, fieldState }) => (
                <FormSelect
                  label={t('generalSettings.working_type')}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  multiple
                  underline
                  options={OptionsMemo(workingTypes)}
                  {...field}
                  disabled={!hasUpdateGeneralSettings}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="timezone"
              control={control}
              render={({ field, fieldState }) => (
                <FormSelectSearchable
                  label={t('generalSettings.timezone')}
                  error={fieldState.invalid}
                  helperText={
                    fieldState.error
                      ? t(fieldState.error.message as any)
                      : undefined
                  }
                  options={timeZones}
                  {...field}
                  // disabled={!hasUpdateGeneralSettings}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="right">
          <Button
            onClick={handleClick}
            sx={styles.submit}
            variant="contained"
            size="large"
            disabled={!hasUpdateGeneralSettings}
          >
            {t('generalSettings.actions.submit')}
          </Button>
        </Grid>
      </Box>
      <ConfirmationModal
        title={t('deleteConfirmation.title')}
        titleIconName="QuestionMarkOutlined"
        description={t('deleteConfirmation.description')}
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        positiveAction={t('deleteConfirmation.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('deleteConfirmation.cancel')}
        onNegativeClick={() => setDeleteConfirmation(null)}
        loading={false}
      />
      <ImageShowModal
        open={openImage}
        onClose={() => setOpenImage(false)}
        image={selectedImage}
      />
    </Box>
  );
}

export default GeneralSettingsForm;
