/** Dependencies */
import { Box, Paper, Stack } from '@mui/material';
import React from 'react';

/** Components */
import LoadingState from '../../../../../components/LoadingState';
import TabBar from '../../../../../components/TabBar';
import SearchBar from './SearchBar';
import ProductGrid from './ProductGrid';

/** Helpers */
import getActiveLanguage from '../../../../../helpers/getActiveLanguage';

/** Models */
import { Product } from '../../../../../models/Product';

/** Store */
import { Category } from '../../../../../store/slices/definitionsSlice/categories/types';

/** Styles */
import styles from './styles';

/** Types */
import { ExtraParams } from '../types';

interface Props {
  categories: Category[];
  activeCategory: string | null;
  onCategoryChange: (id: string) => void;
  products: Product[];
  onIncrease: (productId: string, itemId: string, menuId: string) => void;
  query: string;
  onQueryChange: (q: string) => void;
  onCancelPress: () => void;
  loading: boolean;
  onDescriptionPress: () => void;
  onGuestsPress: () => void;
  onCouvertPress: () => void;
  onServiceChargePress: (isAuto: boolean) => void;
  onSpecialOrderPress: () => void;
  extraParams: ExtraParams;
  setOnSelectionModal: React.Dispatch<
    React.SetStateAction<{ isSelected: boolean; id: string }>
  >;
  setOnSelectionMenuModal: React.Dispatch<
    React.SetStateAction<{ isSelected: boolean; id: string }>
  >;
  orderId: string;
  currencyCode: string;
  isPackageOrder: boolean;
  isSelfOrder: boolean;
}

function ContentColumn({
  categories,
  activeCategory,
  onCategoryChange,
  products,
  onIncrease,
  query,
  onQueryChange,
  loading,
  onDescriptionPress,
  onGuestsPress,
  onCouvertPress,
  onServiceChargePress,
  onCancelPress,
  onSpecialOrderPress,
  extraParams,
  setOnSelectionModal,
  setOnSelectionMenuModal,
  orderId,
  currencyCode,
  isPackageOrder,
  isSelfOrder,
}: Props) {
  return (
    <Box sx={styles.container}>
      <Stack sx={styles.innerContainer} direction="column">
        <SearchBar
          query={query}
          onQueryChange={onQueryChange}
          onDescriptionPress={onDescriptionPress}
          onGuestsPress={onGuestsPress}
          onCouvertPress={onCouvertPress}
          onServiceChargePress={onServiceChargePress}
          onCancelPress={onCancelPress}
          onSpecialOrderPress={onSpecialOrderPress}
          extraParams={extraParams}
          orderId={orderId}
          isPackageOrder={isPackageOrder}
          isSelfOrder={isSelfOrder}
        />
        <Box sx={styles.content}>
          {!query ? (
            <Paper sx={styles.tabBarContainer} elevation={1}>
              <TabBar
                size="large"
                tabs={categories.map(item => ({
                  ...item,
                  name: getActiveLanguage(item.translations).name,
                }))}
                activeTab={activeCategory}
                onClick={tab => onCategoryChange(tab.id)}
                divider={false}
              />
            </Paper>
          ) : null}
          <Box sx={styles.gridContainer}>
            {!loading ? (
              <ProductGrid
                list={products}
                onIncrease={onIncrease}
                setOnSelectionModal={setOnSelectionModal}
                setOnSelectionMenuModal={setOnSelectionMenuModal}
                currencyCode={currencyCode}
              />
            ) : (
              <LoadingState />
            )}
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}
export default ContentColumn;
