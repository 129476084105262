import { Box, Divider, Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import FormSelectSearchable from '../../../../../components/FormSelectSearchable';
import useProducts from '../../../../../hooks/useProducts';
import FormButtons from '../FormButtons';
import {
  defaultValues,
  ProductIngredientFormValues,
  validationSchema,
} from './types';
import getActiveLanguage from '../../../../../helpers/getActiveLanguage';
import Button from '../../../../../components/Button';
import ProductCard from '../../../../../components/ProductCard';
import ConfirmationModal from '../../../../../components/ConfirmationModal';

interface Props {
  onPrevClick: () => void;
  isLast: boolean;
  childs: string[];
  catId?: string;
}
function GroupForm({ onPrevClick, isLast, childs, catId }: Props) {
  const { t } = useTranslation('product');
  const { enqueueSnackbar } = useSnackbar();
  const { setProductGroup, fetchProducts, products, productLoading } =
    useProducts();

  const { handleSubmit, control, reset } = useForm<ProductIngredientFormValues>(
    {
      defaultValues: defaultValues(),
      resolver: validationSchema,
    },
  );

  const { productId } = useParams();

  const [productGroupIds, setProductGroupIds] = useState<string[]>([]);
  const [deleteMenuConfirmation, setDeleteMenuConfirmation] = useState<
    string | null
  >(null);
  const [filteredProducts, setFilteredProducts] = useState(products);

  const onSubmit = () => {
    handleSubmit(values => {
      if (
        !productGroupIds.find(item => item === values.groupIds) &&
        values.groupIds.length
      ) {
        setProductGroupIds([...productGroupIds, values.groupIds]);
        reset(defaultValues());
      } else if (productGroupIds.find(item => item === values.groupIds)) {
        enqueueSnackbar(t('wizard.form.productGroup.sameProductError'), {
          variant: 'warning',
        });
      }
    })();
  };

  const handleRemove = () => {
    setProductGroupIds(
      productGroupIds.filter(a => a !== deleteMenuConfirmation),
    );
    setDeleteMenuConfirmation(null);
  };

  const onNextClick = () => {
    if (productId) {
      setProductGroup({ parent_id: productId, child_ids: productGroupIds });
    }
  };

  useEffect(() => {
    setProductGroupIds(childs);
  }, [childs]);

  useEffect(() => {
    if (catId) {
      fetchProducts(catId, false);
    }
  }, [catId]);

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100%',
      }}
    >
      <Box sx={{ py: 4 }}>
        <Grid container>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <Stack direction="row" gap={1}>
              <Controller
                name="groupIds"
                control={control}
                render={({ field, fieldState }) => (
                  <FormSelectSearchable
                    sx={{ flex: 1 }}
                    label={t('menu.itemsModal.form.product.label')}
                    error={fieldState.invalid}
                    helperText={
                      fieldState.error
                        ? t(fieldState.error.message as any)
                        : undefined
                    }
                    options={filteredProducts
                      .map(p => ({
                        id: p.id,
                        text: getActiveLanguage(p.translations).name,
                        group: getActiveLanguage(p.category.translations).name,
                      }))
                      .sort((a, b) => a.group.localeCompare(b.group))}
                    groupBy={option => option.group || ''}
                    {...field}
                  />
                )}
              />
              <Button sx={{ alignSelf: 'flex-end', mb: 4 }} onClick={onSubmit}>
                {t('wizard.form.productGroup.submit')}
              </Button>
            </Stack>

            <Divider sx={{ mb: 2 }} />

            <Grid
              container
              spacing={2}
              mt={1}
              justifyContent={productGroupIds.length ? 'left' : 'center'}
            >
              {!productGroupIds.length ? (
                <Grid>{t('wizard.form.productGroup.empty')}</Grid>
              ) : (
                productGroupIds?.slice(0).map(item => {
                  const product = products.find(p => p.id === item);

                  const itemName = product
                    ? `${getActiveLanguage(product.translations).name}`
                    : 'unknown';

                  const itemPrice = product
                    ? product?.units?.find(unit => unit.is_default)?.amount
                        ?.formatted
                    : '₺0,00';

                  return (
                    <Grid item key={item} xs={6} sm={4} md={3} lg={2}>
                      <ProductCard
                        key={item}
                        name={itemName}
                        price={itemPrice}
                        onDelete={() => setDeleteMenuConfirmation(item)}
                      />
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </Box>

      <FormButtons
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
        isLast={isLast}
      />

      <ConfirmationModal
        title={t('wizard.form.productGroup.deleteConfirmation.title')}
        titleIconName="QuestionMarkOutlined"
        description={t(
          'wizard.form.productGroup.deleteConfirmation.description',
        )}
        open={!!deleteMenuConfirmation}
        onClose={() => {
          setDeleteMenuConfirmation(null);
        }}
        positiveAction={t(
          'wizard.form.productGroup.deleteConfirmation.confirm',
        )}
        onPositiveClick={handleRemove}
        negativeAction={t('wizard.form.productGroup.deleteConfirmation.cancel')}
        onNegativeClick={() => {
          setDeleteMenuConfirmation(null);
        }}
        loading={productLoading}
      />
    </Box>
  );
}
export default GroupForm;
