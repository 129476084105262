/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { format } from 'date-fns';

/** Components */
import UtilitiesBar from '../../../components/UtilitiesBar';
import ErrorState from '../../../components/ErrorState';
import LoadingState from '../../../components/LoadingState';
import WaiterSalesTable from './WaiterSalesTable';
import useBranches from '../../../hooks/useBranches';
import WaiterSalesSearch from './Search/WaiterSalesSearch';
import EmptyState from '../../../components/EmptyState';
import useProfile from '../../../hooks/useProfile';

function WaiterSales() {
  const { t } = useTranslation('waiterSales');
  const { fetchSalesByUsers, loading } = useBranches();
  const { meProfile } = useProfile();
  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endDate, setEndDate] = useState('');
  const [endTime, setEndTime] = useState('');

  const initialDate = format(new Date(Date.now()), 'yyyy-MM-dd');

  useEffect(() => {
    if (meProfile) {
      fetchSalesByUsers(
        1,
        `${initialDate} ${meProfile?.activeBranch?.start_time || '00:00'}`,
        `${initialDate} 23:59`,
      );
    }
  }, [meProfile]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('title')}
        leftIconName="SupervisorAccount"
      />
      <WaiterSalesSearch
        startDate={startDate}
        setStartDate={setStartDate}
        startTime={startTime}
        setStartTime={setStartTime}
        endDate={endDate}
        setEndDate={setEndDate}
        endTime={endTime}
        setEndTime={setEndTime}
      />
      {loading ? <LoadingState /> : <WaiterSalesTable />}
    </Box>
  );
}

export default WaiterSales;
