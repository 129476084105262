/** Dependencies */
import React, { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

/** Components */
import Modal from '../../../../components/Modal';
import Form from './Form';

/** Hooks */
import useCustomers from '../../../../hooks/useCustomers';

/** Types */
import { CustomerBase, validationSchema } from '../types';
import { isApiError } from '../../../../services/api/types';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  item?: CustomerBase | null;
}

function CustomerAddModal({ open, onClose, onConfirm, item }: Props) {
  const { t } = useTranslation('customer');
  const { enqueueSnackbar } = useSnackbar();
  const { addCustomer, updateCustomer } = useCustomers();

  // @ts-ignore
  const selectableChargeable = item?.chargeable_amount?.amount;
  const initialValues = {
    id: '',
    first_name: item?.first_name || '',
    last_name: item?.last_name || '',
    phone_number: item?.phone_number || '',
    is_active: item?.is_active || false,
    phone_number_country: item?.phone_number_country || 'TR',
    is_open_account: item?.is_open_account || false,
    chargeable_amount: Number(selectableChargeable) / 100 || null,
  };

  const { handleSubmit, control, reset } = useForm<CustomerBase>({
    defaultValues: initialValues,
    resolver: validationSchema,
  });

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [info, setInfo] = useState<any>(null);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleChange = (newValue: any, info: any) => {
    setValue(newValue);
    setInfo(info);
  };

  const handleClose = () => {
    onClose();
    reset(initialValues);
    setInfo(null);
  };

  useEffect(() => {
    reset(initialValues);
    if (item) {
      setValue(item.phone_number);
    } else {
      setValue('');
    }
  }, [open]);

  const handleConfirm = () => {
    try {
      setLoading(true);
      handleSubmit(async values => {
        try {
          if (item) {
            await updateCustomer({
              ...values,
              id: item.id,
              chargeable_amount: (values.chargeable_amount || 0) * 100,
              phone_number_country:
                info?.countryCode || item.phone_number_country,
              phone_number: value?.replace(/\s/g, '') || '',
            });
          } else {
            await addCustomer({
              ...values,
              chargeable_amount: (values.chargeable_amount || 0) * 100,
              phone_number_country: info?.countryCode,
              phone_number: info?.nationalNumber,
            });
          }
          setInfo(null);
          onClose();
          onConfirm();
        } catch (err: any) {
          enqueueSnackbar(
            isApiError(err) ? err.message : t('common:unknownError' as any),
            {
              variant: 'error',
              style: { whiteSpace: 'pre-line' },
              autoHideDuration: 3000,
            },
          );
          throw err;
        }
      })();
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      title={
        item ? t('customer.editModal.title') : t('customer.addModal.title')
      }
      titleIconName={item ? 'Edit' : 'Add'}
      open={open}
      onClose={handleClose}
      loading={loading}
      negativeAction={t('customer.addModal.cancel')}
      positiveAction={
        item ? t('customer.addModal.edit') : t('customer.addModal.add')
      }
      onPositiveClick={handleConfirm}
      onNegativeClick={handleClose}
    >
      <Form
        item={item}
        control={control}
        value={value}
        handleChange={handleChange}
      />
    </Modal>
  );
}

export default CustomerAddModal;
