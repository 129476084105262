/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

/** Styles */
import {
  alpha,
  AppBar,
  Box,
  styled,
  Typography,
  Toolbar,
  Grid,
  Button,
} from '@mui/material';
import FormDatePicker from '../../../../components/FormDatePicker';
import useExpenseCategories from '../../../../hooks/useExpenseCategories';
import useBranches from '../../../../hooks/useBranches';
import keyHasPermission from '../../../../helpers/keyHasPermission';
import useProfile from '../../../../hooks/useProfile';
import FormSelect from '../../../../components/FormSelect';

const SearchComponent = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

interface Props {
  startDate: string;
  setStartDate: (value: ((prevState: string) => string) | string) => void;
  endDate: string;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  startTime: string;
  setStartTime: (value: ((prevState: string) => string) | string) => void;
  endTime: string;
  setEndTime: React.Dispatch<React.SetStateAction<string>>;
}

function DaysSearch({
  endDate,
  startDate,
  setEndDate,
  setStartDate,
  endTime,
  startTime,
  setEndTime,
  setStartTime,
}: Props) {
  const { t } = useTranslation('waiterSales');
  const { meProfile } = useProfile();

  const { expenseCategories } = useExpenseCategories();
  const { fetchSalesByUsers } = useBranches();
  const [daySelect, setDaySelect] = useState('today');

  const date = new Date(Date.now());
  date.setDate(date.getDate() - 7);

  const yesterdayDate = new Date(Date.now());
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  const initialStartDate = format(new Date(Date.now()), 'yyyy-MM-dd');
  const initialEndDate = format(new Date(Date.now()), 'yyyy-MM-dd');
  const initialStartTime = meProfile?.activeBranch?.start_time || '00:00';
  const initialEndTime = '23:59';

  const timeOptions = [
    { id: '00:00', text: '00:00' },
    { id: '00:15', text: '00:15' },
    { id: '00:30', text: '00:30' },
    { id: '00:45', text: '00:45' },
    { id: '01:00', text: '01:00' },
    { id: '01:15', text: '01:15' },
    { id: '01:30', text: '01:30' },
    { id: '01:45', text: '01:45' },
    { id: '02:00', text: '02:00' },
    { id: '02:15', text: '02:15' },
    { id: '02:30', text: '02:30' },
    { id: '02:45', text: '02:45' },
    { id: '03:00', text: '03:00' },
    { id: '03:15', text: '03:15' },
    { id: '03:30', text: '03:30' },
    { id: '03:45', text: '03:45' },
    { id: '04:00', text: '04:00' },
    { id: '04:15', text: '04:15' },
    { id: '04:30', text: '04:30' },
    { id: '04:45', text: '04:45' },
    { id: '05:00', text: '05:00' },
    { id: '05:15', text: '05:15' },
    { id: '05:30', text: '05:30' },
    { id: '05:45', text: '05:45' },
    { id: '06:00', text: '06:00' },
    { id: '06:15', text: '06:15' },
    { id: '06:30', text: '06:30' },
    { id: '06:45', text: '06:45' },
    { id: '07:00', text: '07:00' },
    { id: '07:15', text: '07:15' },
    { id: '07:30', text: '07:30' },
    { id: '07:45', text: '07:45' },
    { id: '08:00', text: '08:00' },
    { id: '08:15', text: '08:15' },
    { id: '08:30', text: '08:30' },
    { id: '08:45', text: '08:45' },
    { id: '09:00', text: '09:00' },
    { id: '09:15', text: '09:15' },
    { id: '09:30', text: '09:30' },
    { id: '09:45', text: '09:45' },
    { id: '10:00', text: '10:00' },
    { id: '10:15', text: '10:15' },
    { id: '10:30', text: '10:30' },
    { id: '10:45', text: '10:45' },
    { id: '11:00', text: '11:00' },
    { id: '11:15', text: '11:15' },
    { id: '11:30', text: '11:30' },
    { id: '11:45', text: '11:45' },
    { id: '12:00', text: '12:00' },
    { id: '12:15', text: '12:15' },
    { id: '12:30', text: '12:30' },
    { id: '12:45', text: '12:45' },
    { id: '13:00', text: '13:00' },
    { id: '13:15', text: '13:15' },
    { id: '13:30', text: '13:30' },
    { id: '13:45', text: '13:45' },
    { id: '14:00', text: '14:00' },
    { id: '14:15', text: '14:15' },
    { id: '14:30', text: '14:30' },
    { id: '14:45', text: '14:45' },
    { id: '15:00', text: '15:00' },
    { id: '15:15', text: '15:15' },
    { id: '15:30', text: '15:30' },
    { id: '15:45', text: '15:45' },
    { id: '16:00', text: '16:00' },
    { id: '16:15', text: '16:15' },
    { id: '16:30', text: '16:30' },
    { id: '16:45', text: '16:45' },
    { id: '17:00', text: '17:00' },
    { id: '17:15', text: '17:15' },
    { id: '17:30', text: '17:30' },
    { id: '17:45', text: '17:45' },
    { id: '18:00', text: '18:00' },
    { id: '18:15', text: '18:15' },
    { id: '18:30', text: '18:30' },
    { id: '18:45', text: '18:45' },
    { id: '19:00', text: '19:00' },
    { id: '19:15', text: '19:15' },
    { id: '19:30', text: '19:30' },
    { id: '19:45', text: '19:45' },
    { id: '20:00', text: '20:00' },
    { id: '20:15', text: '20:15' },
    { id: '20:30', text: '20:30' },
    { id: '20:45', text: '20:45' },
    { id: '21:00', text: '21:00' },
    { id: '21:15', text: '21:15' },
    { id: '21:30', text: '21:30' },
    { id: '21:45', text: '21:45' },
    { id: '22:00', text: '22:00' },
    { id: '22:15', text: '22:15' },
    { id: '22:30', text: '22:30' },
    { id: '22:45', text: '22:45' },
    { id: '23:00', text: '23:00' },
    { id: '23:15', text: '23:15' },
    { id: '23:30', text: '23:30' },
    { id: '23:45', text: '23:45' },
    { id: '23:59', text: '23:59' },
  ];
  const dayOptions = [
    { id: 'today', text: 'Bugün' },
    { id: 'yesterday', text: 'Dün' },
    { id: 'week', text: 'Bu Hafta' },
  ];

  const handleSubmit = () => {
    fetchSalesByUsers(1, `${startDate} ${startTime}`, `${endDate} ${endTime}`);
  };

  useEffect(() => {
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
    setStartTime(initialStartTime);
    setEndTime(initialEndTime);
  }, [expenseCategories, meProfile]);

  useEffect(() => {
    if (daySelect === 'today') {
      setStartDate(initialStartDate);
      setEndDate(initialEndDate);
    } else if (daySelect === 'yesterday') {
      setStartDate(format(yesterdayDate, 'yyyy-MM-dd'));
      setEndDate(initialEndDate);
    } else {
      setStartDate(format(date, 'yyyy-MM-dd'));
      setEndDate(initialEndDate);
    }
  }, [daySelect]);

  const hasViewPermission = keyHasPermission(
    'view-daily-report',
    meProfile?.permissions || [],
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'white',
          marginBottom: '25px',
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}
          >
            <SearchComponent>
              <Grid container justifyContent="left">
                <Grid item xs={12} sm={12} md={3} lg={2} p={1}>
                  <FormSelect
                    options={dayOptions}
                    value={daySelect}
                    onChange={event =>
                      setDaySelect(event.target.value as string)
                    }
                    sx={{ color: 'black' }}
                    label={t('reports.search.date')}
                    underline
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={2} p={1}>
                  <FormDatePicker
                    value={startDate}
                    onChange={event => setStartDate(event.target.value)}
                    sx={{ color: 'black' }}
                    label={t('reports.search.startDate')}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={2} p={1}>
                  <FormSelect
                    options={timeOptions}
                    value={startTime}
                    onChange={event =>
                      setStartTime(event.target.value as string)
                    }
                    sx={{ color: 'black' }}
                    label={t('reports.search.startTime')}
                    underline
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={2} p={1}>
                  <FormDatePicker
                    value={endDate}
                    onChange={event => setEndDate(event.target.value)}
                    sx={{ color: 'black' }}
                    label={t('reports.search.endDate')}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={2} p={1}>
                  <FormSelect
                    options={timeOptions}
                    value={endTime}
                    onChange={event => setEndTime(event.target.value as string)}
                    sx={{ color: 'black' }}
                    label={t('reports.search.endTime')}
                    underline
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={2} p={1}>
                  <Button
                    onClick={handleSubmit}
                    sx={{
                      marginTop: { sm: '10px', md: '30px' },
                      width: '100%',
                    }}
                    size="large"
                    variant="contained"
                    disabled={!hasViewPermission}
                  >
                    {t('reports.search.filter')}
                  </Button>
                </Grid>
              </Grid>
            </SearchComponent>
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default DaysSearch;
