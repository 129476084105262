/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { format } from 'date-fns';

/** Components */
import UtilitiesBar from '../../../components/UtilitiesBar';
import ErrorState from '../../../components/ErrorState';
import LoadingState from '../../../components/LoadingState';
import SectionSalesTable from './SectionSalesTable';
import useBranches from '../../../hooks/useBranches';
import SectionSalesSearch from './Search/SectionSalesSearch';
import useProfile from '../../../hooks/useProfile';

function SectionSales() {
  const { t } = useTranslation('sectionSales');
  const { fetchSalesBySections, loading } = useBranches();
  const { meProfile } = useProfile();

  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endDate, setEndDate] = useState('');
  const [endTime, setEndTime] = useState('');
  const initialDate = format(new Date(Date.now()), 'yyyy-MM-dd');

  useEffect(() => {
    if (meProfile) {
      fetchSalesBySections(
        1,
        `${initialDate} ${meProfile?.activeBranch?.start_time || '00:00'}`,
        `${initialDate} 23:59`,
      );
    }
  }, [meProfile]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('title')}
        leftIconName="SupervisorAccount"
      />
      <SectionSalesSearch
        startDate={startDate}
        setStartDate={setStartDate}
        startTime={startTime}
        setStartTime={setStartTime}
        endDate={endDate}
        setEndDate={setEndDate}
        endTime={endTime}
        setEndTime={setEndTime}
      />
      {loading ? <LoadingState /> : <SectionSalesTable />}
    </Box>
  );
}

export default SectionSales;
