import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../components/FormInput';
import Modal from '../../../components/Modal';
import useKitchenGroups from '../../../hooks/useKitchenGroups';
import { isApiError } from '../../../services/api/types';
import errorReporting from '../../../helpers/errorReporting';
import { IdAndName } from '../../../models/General';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm?: (name: string) => void;
  item?: IdAndName | null;
}

function KitchenGroupAddModal({ open, onClose, onConfirm, item }: Props) {
  const { t } = useTranslation('category');
  const { enqueueSnackbar } = useSnackbar();
  const { addKitchenGroups, updateKitchenGroups } = useKitchenGroups();

  const [name, setName] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (item) {
      setName(item.name);
    }
    return () => {
      setName('');
    };
  }, [item]);

  const handleConfirm = async () => {
    if (name) {
      try {
        setLoading(true);

        const result = !item?.id
          ? await addKitchenGroups(name)
          : await updateKitchenGroups({ id: item.id, name });

        setName('');
        if (onConfirm) onConfirm(name);
      } catch (err: any) {
        enqueueSnackbar(
          isApiError(err) ? err.message : t('common:unknownError' as any),
          {
            variant: 'error',
            autoHideDuration: 3000,
          },
        );
        errorReporting(err);
      } finally {
        setLoading(false);
      }
    } else {
      setIsSubmitted(true);
    }
  };

  const invalid = !name && isSubmitted;

  return (
    <Modal
      title={t('kitchenGroup.add_Modal.title')}
      titleIconName="Add"
      open={open}
      onClose={onClose}
      positiveAction={t('kitchenGroup.add_Modal.confirm')}
      negativeAction={t('kitchenGroup.add_Modal.cancel')}
      onPositiveClick={handleConfirm}
      onNegativeClick={onClose}
      loading={loading}
    >
      <FormInput
        label={t('kitchenGroup.add_Modal.form.name.label')}
        error={invalid}
        helperText={invalid && t('kitchenGroup.add_Modal.form.name.required')}
        value={name}
        onChange={event => setName(event.target.value)}
        onKeyPress={event => event.key === 'Enter' && handleConfirm()}
      />
    </Modal>
  );
}

export default KitchenGroupAddModal;
