import { SxProps, Theme } from '@mui/material';

const styles: Record<string, SxProps<Theme>> = {
  container: {
    width: '100%',
    height: { xs: '150px', sm: '100px', lg: '150px' },
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 2,
    },
  },
  textContainer: {
    flex: 1,
    pt: { xs: 4, sm: 3, md: 4 },
    pb: { xs: 4, sm: 3, md: 4 },
    textAlign: 'center',
  },
  title: { fontSize: { xs: 14, sm: 13, lg: 16 }, textTransform: 'capitalize' },
  nonSelectable: { userSelect: 'none' },
};

export default styles;
