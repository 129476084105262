import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { isApiError } from '../../services/api/types';
import { useAppDispatch, useAppSelector } from '../../store';
import { getSectionListThunk } from '../../store/slices/definitionsSlice/sections';
import api from '../../services/api';
import {
  addSectionSlice,
  moveDownSlice,
  moveUpSlice,
  removeSectionSlice,
  updateSectionSlice,
} from '../../store/slices/definitionsSlice/sections/sectionsSlice';

const useSections = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const sections = useAppSelector(state => state.definitionsSections.sections);
  const sectionLoading = useAppSelector(
    state => state.definitionsSections.loading,
  );
  const sectionError = useAppSelector(state => state.definitionsSections.error);

  const fetchSections = (sales_channel_id?: string) =>
    dispatch(getSectionListThunk({ sales_channel_id }));

  const addSection = async (name: string, sales_channel_id: string) => {
    try {
      const response = await api.definitions.sections.create(
        name,
        sales_channel_id,
      );
      const result = response.data.data;
      dispatch(addSectionSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const updateSection = async ({
    id,
    name,
    sales_channel_id,
  }: {
    id: string;
    name: string;
    sales_channel_id: string;
  }) => {
    try {
      const response = await api.definitions.sections.update(
        id,
        name,
        sales_channel_id,
      );
      const result = response.data.data;
      dispatch(updateSectionSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const removeSection = async (id: string) => {
    try {
      const response = await api.definitions.sections.remove(id);
      const result = response.data.data;
      dispatch(removeSectionSlice(result));
      enqueueSnackbar(t('common:success' as any), {
        variant: 'success',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 3000,
      });
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const moveUpSection = async (id: string) => {
    try {
      const response = await api.definitions.sections.moveUp(id);
      const result = response.data;
      dispatch(moveUpSlice({ id }));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };

  const moveDownSection = async (id: string) => {
    try {
      const response = await api.definitions.sections.moveDown(id);
      const result = response.data;
      dispatch(moveDownSlice({ id }));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
    }
  };
  return {
    sections,
    sectionLoading,
    sectionError,
    fetchSections,
    addSection,
    updateSection,
    removeSection,
    moveUpSection,
    moveDownSection,
  };
};
export default useSections;
