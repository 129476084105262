/** Dependencies */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Grid, Paper } from '@mui/material';
import { useSnackbar } from 'notistack';
import currency from 'currency.js';

/** Components */
import PaidPaymentList from './Components/PaidPaymentList';
import PaymentNumpad from './Components/PaymentNumpad';
import PaymentDiscount from './Components/PaymentDiscount';
import ConfirmationModal from '../../../../../../components/ConfirmationModal';

/** Helpers */
import { priceFormatter } from '../../../../../../helpers/moneyFormatter';

/** Hooks */
import usePosOrder from '../../../../../../hooks/usePosOrder';

/** Styles */
import styles from '../styles';

import { Payments } from '../types';

interface Props {
  discountTotal: number;
  tableId: string;
  totalPrice: number;
  allPrice: number;
  value: string;
  setValue: (value: ((prevState: string) => string) | string) => void;
  paymentList: Payments[];
  handleRemovePaymentListItem: (id: string) => void;
  paidPayments: any[];
  removeDiscount: () => void;
  onRemoved: () => void;
  currencyCode: string;
  setDividedValue: (value: ((prevState: number) => number) | number) => void;
  removeDiscountForSelf: () => void;
  divided: any[];
  setDivided: (value: ((prevState: any[]) => any[]) | any[]) => void;
  clearList: () => void;
  handleKeyDown: (event: { key: any }) => void;
}

function PaymentModalMidSide({
  discountTotal,
  tableId,
  totalPrice,
  allPrice,
  value,
  setValue,
  paymentList,
  handleRemovePaymentListItem,
  paidPayments,
  removeDiscount,
  onRemoved,
  currencyCode,
  setDividedValue,
  removeDiscountForSelf,
  divided,
  setDivided,
  clearList,
  handleKeyDown,
}: Props) {
  const { t } = useTranslation('pos');
  const { removeOrderDiscount, removeOrderTransactionPayment } = usePosOrder();
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState<string | null>(
    null,
  );
  const [deleteConfirmationForDiscount, setDeleteConfirmationForDiscount] =
    useState<string | null>(null);

  const handleOneOverN = (num: number) => {
    if (num) {
      setDivided(currency(allPrice).distribute(num));
      clearList();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    clearList();
  };
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemove = async () => {
    if (deleteConfirmationForDiscount) {
      if (deleteConfirmationForDiscount !== 'self') {
        await removeOrderDiscount(deleteConfirmationForDiscount);
        removeDiscount();
        onRemoved();
      } else {
        removeDiscountForSelf();
      }
      enqueueSnackbar(t('paymentModal.deleteConfirmation.success'), {
        variant: 'success',
      });
      setDeleteConfirmationForDiscount(null);
    }
  };

  const handlePaymentItemRemove = async () => {
    if (deleteConfirmation) {
      await removeOrderTransactionPayment(deleteConfirmation);
      window.location.reload();
      setDeleteConfirmation(null);
    }
  };

  return (
    <Paper sx={styles.paymentModalPaper} elevation={3}>
      <Grid container justifyContent="space-between">
        <Grid>{t('modalMidSide.total')}</Grid>
        <Grid>{priceFormatter(totalPrice, currencyCode)}</Grid>
      </Grid>
      <Divider />

      <PaidPaymentList
        paymentList={paymentList}
        handleRemovePaymentListItem={handleRemovePaymentListItem}
        paidPayments={paidPayments}
        currencyCode={currencyCode}
        setDeleteConfirmation={setDeleteConfirmation}
      />

      <Grid
        sx={{
          bottom: 0,
          right: 0,
          left: 0,
          textAlign: 'center',
        }}
      >
        <PaymentDiscount
          discountTotal={discountTotal}
          tableId={tableId}
          value={value}
          currencyCode={currencyCode}
          setDeleteConfirmationForDiscount={setDeleteConfirmationForDiscount}
          handleKeyDown={handleKeyDown}
        />

        <PaymentNumpad
          allPrice={allPrice}
          value={value}
          setValue={setValue}
          handleClick={handleClick}
          handleClose={handleClose}
          open={open}
          handleOneOverN={handleOneOverN}
          anchorEl={anchorEl}
          setDivided={setDivided}
          clearList={clearList}
        />
      </Grid>

      <ConfirmationModal
        title={t('paymentModal.deleteConfirmation.title')}
        titleIconName="QuestionMarkOutlined"
        description={t('paymentModal.deleteConfirmation.description')}
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        positiveAction={t('paymentModal.deleteConfirmation.confirm')}
        onPositiveClick={handlePaymentItemRemove}
        negativeAction={t('paymentModal.deleteConfirmation.cancel')}
        onNegativeClick={() => setDeleteConfirmation(null)}
        loading={false}
      />
      <ConfirmationModal
        title={t('paymentModal.deleteConfirmationDiscount.title')}
        titleIconName="QuestionMarkOutlined"
        description={t('paymentModal.deleteConfirmationDiscount.description')}
        open={!!deleteConfirmationForDiscount}
        onClose={() => setDeleteConfirmationForDiscount(null)}
        positiveAction={t('paymentModal.deleteConfirmationDiscount.confirm')}
        onPositiveClick={handleRemove}
        negativeAction={t('paymentModal.deleteConfirmationDiscount.cancel')}
        onNegativeClick={() => setDeleteConfirmationForDiscount(null)}
        loading={false}
      />
    </Paper>
  );
}

export default PaymentModalMidSide;
