import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  TextField,
  Typography,
  TextFieldProps,
  InputAdornment,
  IconButton,
} from '@mui/material';
import React, { forwardRef, useState } from 'react';
import styles from './styles';

interface Props {}

function FormPasswordInput(
  { label, sx, ...props }: Props & TextFieldProps,
  ref: TextFieldProps['ref'],
) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box
      sx={[styles.container, ...(sx ? (Array.isArray(sx) ? sx : [sx]) : [])]}
    >
      <Typography variant="subtitle1">{label}</Typography>
      <TextField
        ref={ref}
        variant="filled"
        hiddenLabel
        type={showPassword ? 'text' : 'password'}
        autoComplete="new-password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
        color="success"
        {...props}
      />
    </Box>
  );
}

export default forwardRef(FormPasswordInput);
