/** Dependencies */
import React, { useCallback, useState } from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

/** Styles */
import styles from './styles';

/** helpers */
import getActiveLanguage from '../../../../../../helpers/getActiveLanguage';

/** Components */
import Modal from '../../../../../../components/Modal';
import Icon from '../../../../../../components/Icon';
import Button from '../../../../../../components/Button';
import { ActiveBasketProps } from '../../LeftColumn/BasketList/types';
import { Item } from '../../types';
import { NameTranslation } from '../../../../../../models/General';

interface Props {
  name: string;
  open: boolean;
  onClose: () => void;
  onSelection: () => void;
  menuItem?: any;
  activeBasketProps: ActiveBasketProps;
  setActiveBasketProps: React.Dispatch<React.SetStateAction<ActiveBasketProps>>;
}

function MenuOnSelectionModal({
  name,
  open,
  onClose,
  onSelection,
  menuItem,
  activeBasketProps,
  setActiveBasketProps,
}: Props) {
  const { t } = useTranslation('pos');
  const [expanded, setExpanded] = useState(true);

  const propertyButtonStyle = useCallback(
    (isMultiple: boolean, itemId: string) => {
      if (isMultiple) {
        return activeBasketProps.activeMultiplePropertyIds.find(
          activeMultipleId => activeMultipleId === itemId,
        )
          ? [styles.button, styles.checked]
          : styles.button;
      }
      return activeBasketProps.activeSinglePropertyId === itemId
        ? [styles.button, styles.checked]
        : styles.button;
    },
    [activeBasketProps],
  );

  const propertyButtonClick = useCallback(
    (isMultiple: boolean, itemId: string, item: Item) => {
      if (isMultiple) {
        if (
          activeBasketProps.activeMultiplePropertyIds.find(
            activeItemId => activeItemId === itemId,
          )
        ) {
          setActiveBasketProps({
            ...activeBasketProps,
            activeMultiplePropertyIds:
              activeBasketProps.activeMultiplePropertyIds.filter(
                filteredId => filteredId !== itemId,
              ),
            activeMultiplePropertyNames:
              activeBasketProps.activeMultiplePropertyNames.filter(
                filteredProp =>
                  filteredProp.name !==
                  `${getActiveLanguage(item.translations).name} ${
                    item.additional_price.formatted !== '₺0,00'
                      ? `+${item.additional_price.formatted}`
                      : ''
                  }`,
              ),
          });
        } else {
          setActiveBasketProps({
            ...activeBasketProps,
            activeMultiplePropertyIds: [
              ...activeBasketProps.activeMultiplePropertyIds,
              itemId,
            ],
            activeMultiplePropertyNames: [
              ...activeBasketProps.activeMultiplePropertyNames,
              {
                name: `${getActiveLanguage(item.translations).name} ${
                  item.additional_price.formatted !== '₺0,00'
                    ? `+${item.additional_price.formatted}`
                    : ''
                }`,
                price: Number(item.additional_price.amount) / 100,
              },
            ],
          });
        }
      } else if (
        activeBasketProps.activeSinglePropertyName?.name ===
        `${getActiveLanguage(item.translations).name} ${
          item.additional_price.formatted !== '₺0,00'
            ? `+${item.additional_price.formatted}`
            : ''
        }`
      ) {
        setActiveBasketProps({
          ...activeBasketProps,
          activeSinglePropertyName: {
            name: '',
            price: 0,
          },
          activeSinglePropertyId: '',
        });
      } else {
        setActiveBasketProps({
          ...activeBasketProps,
          activeSinglePropertyName: {
            name: `${getActiveLanguage(item.translations).name} ${
              item.additional_price.formatted !== '₺0,00'
                ? `+${item.additional_price.formatted}`
                : ''
            }`,
            price: Number(item.additional_price.amount) / 100,
          },
          activeSinglePropertyId: itemId,
        });
      }
    },
    [activeBasketProps],
  );

  const ingredientButtonStyle = useCallback(
    (itemId: string) =>
      activeBasketProps.activeIngredientIds.find(
        activeMultipleId => activeMultipleId === itemId,
      )
        ? [styles.button, styles.nonIngredient]
        : styles.button,
    [activeBasketProps],
  );
  const ingredientButtonClick = useCallback(
    (itemId: string) =>
      activeBasketProps.activeIngredientIds.find(
        activeItemId => activeItemId === itemId,
      )
        ? setActiveBasketProps({
            ...activeBasketProps,
            activeIngredientIds: activeBasketProps.activeIngredientIds.filter(
              filteredId => filteredId !== itemId,
            ),
          })
        : setActiveBasketProps({
            ...activeBasketProps,
            activeIngredientIds: [
              ...activeBasketProps.activeIngredientIds,
              itemId,
            ],
          }),
    [activeBasketProps],
  );

  const handleClose = () => {
    onClose();
  };

  const isActiveProperties =
    menuItem && menuItem.property_groups.length
      ? menuItem.property_groups.some(
          (item: any) =>
            item.items.filter((propItem: any) => propItem.is_active).length,
        )
      : false;
  const isActiveIngredients =
    menuItem && menuItem.ingredient_groups.length
      ? menuItem.ingredient_groups.some(
          (item: any) =>
            item.items.filter((ingredientItem: any) => ingredientItem.is_active)
              .length,
        )
      : false;

  return (
    <Modal
      maxWidth="md"
      open={open}
      onClose={onClose}
      title={`${name}`}
      positiveAction={t('selectionModal.buttons.confirm')}
      subtitle={t('selectionModal.subtitle')}
      onPositiveClick={() => {
        onSelection();
        onClose();
      }}
      negativeAction={t('selectionModal.buttons.cancel')}
      onNegativeClick={() => {
        handleClose();
        onClose();
      }}
    >
      {isActiveProperties && (
        <div>
          <Accordion defaultExpanded elevation={0}>
            <AccordionSummary
              sx={styles.menuMinHeight}
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              <Box sx={styles.menuDisplay}>
                {expanded ? (
                  <Icon name="KeyboardArrowUp" />
                ) : (
                  <Icon name="KeyboardArrowDown" />
                )}
                {t('selectionModal.properties')}
              </Box>
            </AccordionSummary>
            {menuItem?.property_groups?.map(
              (product: {
                translations: NameTranslation[];
                is_multiple: boolean;
                items: {
                  translations: { name: string }[];
                  additional_price: {
                    amount: string;
                    currency: string;
                    formatted: string;
                  };
                  is_active: boolean;
                  id: string;
                }[];
              }) =>
                product.items.find(item => item.is_active) && (
                  <AccordionDetails sx={styles.accordionPadding}>
                    <Accordion defaultExpanded elevation={0}>
                      <AccordionSummary
                        sx={styles.menuMinHeight}
                        onClick={() => {
                          setExpanded(!expanded);
                        }}
                      >
                        <Box
                          sx={{
                            ...styles.menuDisplay,
                            borderBottom: '1px solid black',
                          }}
                        >
                          {expanded ? (
                            <Icon name="KeyboardArrowUp" />
                          ) : (
                            <Icon name="KeyboardArrowDown" />
                          )}
                          {getActiveLanguage(product.translations).name}
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails sx={styles.accordionPadding}>
                        {product?.items?.map(
                          (item: {
                            // todo: Buralar değişecek
                            translations: any[];
                            additional_price: {
                              amount: string;
                              currency: string;
                              formatted: string;
                            };
                            is_active: boolean;
                            id: string;
                          }) =>
                            item?.is_active && (
                              <Button
                                sx={propertyButtonStyle(
                                  product.is_multiple,
                                  item.id,
                                )}
                                onClick={() =>
                                  propertyButtonClick(
                                    product.is_multiple,
                                    item.id,
                                    item as Item,
                                  )
                                }
                                variant="outlined"
                              >
                                {getActiveLanguage(item.translations).name}
                                {item.additional_price.formatted !==
                                  '₺0,00' && (
                                  <Typography
                                    sx={{
                                      fontSize: '10px',
                                      marginLeft: '7px',
                                      color: '#ff0000c1',
                                    }}
                                  >
                                    +{item.additional_price.formatted}
                                  </Typography>
                                )}
                              </Button>
                            ),
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </AccordionDetails>
                ),
            )}
          </Accordion>
        </div>
      )}
      {isActiveIngredients && (
        <div>
          <Accordion defaultExpanded elevation={0}>
            <AccordionSummary
              sx={styles.menuMinHeight}
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              <Box sx={styles.menuDisplay}>
                {expanded ? (
                  <Icon name="KeyboardArrowUp" />
                ) : (
                  <Icon name="KeyboardArrowDown" />
                )}
                {t('selectionModal.ingredients')}
              </Box>
            </AccordionSummary>
            {menuItem?.ingredient_groups?.map(
              (product: {
                translations: NameTranslation[];
                items: {
                  translations: { name: string }[];
                  is_active: boolean;
                  id: string;
                }[];
              }) =>
                product.items.find(item => item.is_active) && (
                  <AccordionDetails sx={styles.accordionPadding}>
                    <Accordion defaultExpanded elevation={0}>
                      <AccordionSummary
                        sx={styles.menuMinHeight}
                        onClick={() => {
                          setExpanded(!expanded);
                        }}
                      >
                        <Box
                          sx={{
                            ...styles.menuMinHeight,
                            borderBottom: '1px solid black',
                          }}
                        >
                          {expanded ? (
                            <Icon name="KeyboardArrowUp" />
                          ) : (
                            <Icon name="KeyboardArrowDown" />
                          )}
                          {getActiveLanguage(product.translations).name}
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails sx={styles.accordionPadding}>
                        {product?.items?.map(
                          (item: {
                            // todo : buralar yapılacak
                            translations: any[];
                            is_active: boolean;
                            id: string;
                          }) =>
                            item?.is_active && (
                              <Button
                                sx={ingredientButtonStyle(item.id)}
                                variant="outlined"
                                onClick={() => ingredientButtonClick(item.id)}
                              >
                                {getActiveLanguage(item.translations).name}
                              </Button>
                            ),
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </AccordionDetails>
                ),
            )}
          </Accordion>
        </div>
      )}
    </Modal>
  );
}

export default MenuOnSelectionModal;
