import TokenStorage from './tokenStorage';

const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';
export const AccessTokenStorage = new TokenStorage(ACCESS_TOKEN_KEY);

const REGISTRATION_STEP_KEY = 'REGISTRATION_STEP';
export const registrationStepStorage = new TokenStorage(REGISTRATION_STEP_KEY);

const LANGUAGE_KEY = 'i18nextLng';
export const languageLocalStorage = new TokenStorage(LANGUAGE_KEY);

const BRANCH_ID_KEY = 'BRANCH_ID';
export const BranchIdStorage = new TokenStorage(BRANCH_ID_KEY);

const EXPIRED_ACCOUNT = 'EXPIRED_ACCOUNT';
export const expiredAccountStorage = new TokenStorage(EXPIRED_ACCOUNT);

const SALES_CHANNEL = 'SALES_CHANNEL';
export const salesChannelStorage = new TokenStorage(SALES_CHANNEL);

const SALES_CHANNEL_NAME = 'SALES_CHANNEL_NAME';
export const salesChannelNameStorage = new TokenStorage(SALES_CHANNEL_NAME);
