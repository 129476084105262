import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type MenuAddFormValues = {
  name: { lang: string; text: string }[];
  quantity: string;
};

const schema = yup.object({
  name: yup
    .array(
      yup.object().shape({
        lang: yup.string().required('menu.addModal.form.name.required'),
        text: yup.string().required('menu.addModal.form.name.required'),
      }),
    )
    .min(1),
  quantity: yup.string().required('menu.addModal.form.name.required'),
});

export const validationSchema = yupResolver(schema);

export const defaultValues: MenuAddFormValues = {
  name: [{ lang: 'tr', text: '' }],
  quantity: '1',
};
