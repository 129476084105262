import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialState, ShowParameter } from './types';

const initialState: InitialState = { loading: false, fullPage: false };

const backdropSlice = createSlice({
  name: 'backdropSlice',
  initialState,
  reducers: {
    showBackDrop: (_, action: PayloadAction<ShowParameter | undefined>) => ({
      loading: true,
      fullPage: !!action.payload?.fullPage,
    }),
    hideBackDrop: () => ({
      loading: false,
      fullPage: false,
    }),
  },
});

export const { showBackDrop, hideBackDrop } = backdropSlice.actions;
export default backdropSlice.reducer;
