import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  ListItemProps,
} from '@mui/material';
import React, { useState } from 'react';
import Icon, { IconName } from '../Icon';

interface Props {
  title: string;
  subtitle?: string;
  iconName: IconName;
  rightIconName?: IconName;
  popUpDisabled: boolean;
  onClick?: () => void;
  selected?: boolean;
  sx?: ListItemProps['sx'];
  disabled?: boolean;
}
function MenuItemComp({
  iconName,
  title,
  subtitle,
  popUpDisabled,
  onClick,
  rightIconName,
  sx,
  selected,
  disabled,
}: Props) {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <ListItem
      sx={[
        {
          color: selected ? 'primary.dark' : 'inherit',
        },
        ...(sx ? (Array.isArray(sx) ? sx : [sx]) : []),
      ]}
      button
      onMouseOver={() => setShowPopup(true)}
      onMouseOut={() => setShowPopup(false)}
      onClick={onClick}
      selected={selected}
      disabled={disabled}
    >
      <Tooltip
        title={title}
        open={!popUpDisabled && showPopup}
        placement="right"
        arrow
      >
        <ListItemIcon>
          <Icon
            name={iconName}
            sx={{ color: selected ? 'primary.dark' : 'inherit' }}
          />
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary={title} secondary={subtitle} />
      {rightIconName && (
        <ListItemIcon>
          <Icon name={rightIconName} />
        </ListItemIcon>
      )}
    </ListItem>
  );
}

export default MenuItemComp;
