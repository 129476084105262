import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import 'yup-phone';

export type EmailFormValues = {
  email: string;
};

export type PasswordFormValues = {
  password: string;
  rePassword: string;
};

const emailSchema: yup.SchemaOf<EmailFormValues> = yup.object({
  email: yup.string().required('login.email.required'),
});

const passwordSchema: yup.SchemaOf<PasswordFormValues> = yup.object({
  password: yup
    .string()
    .required('login.password.required')
    .min(8, 'login.password.min'),
  rePassword: yup
    .string()
    .required('login.password.required')
    .min(8, 'login.password.min'),
});

export const emailValidationSchema = yupResolver(emailSchema);
export const passwordValidationSchema = yupResolver(passwordSchema);
