import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';

interface Props {
  open: boolean;
  onClose: () => void;
  image: any;
}

function ImageShowModal({ open, onClose, image }: Props) {
  const { t } = useTranslation('category');

  return (
    <Modal
      title=""
      open={open}
      onClose={onClose}
      negativeAction={t('category.add_Modal.close')}
      onPositiveClick={onClose}
      onNegativeClick={onClose}
    >
      <Grid container>
        <img
          style={{ width: '100%', height: '100%' }}
          src={image}
          alt="Uploaded"
        />
      </Grid>
    </Modal>
  );
}

export default ImageShowModal;
