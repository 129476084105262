/** Dependencies */
import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

/** Components */
import Modal from '../Modal';
import Button from '../Button';
import Icon from '../Icon';
import CancelModal from '../../apps/Pos/Sections/OrderModal/CancelModal';

/** Styles */
import styles from './styles';
import useProfile from '../../hooks/useProfile';
import keyHasPermission from '../../helpers/keyHasPermission';
import usePosOrder from '../../hooks/usePosOrder';

interface Props {
  name: string;
  open: boolean;
  setOpen: any;
  setIsMergeable: any;
  setIsChangeable: any;
  setIsMoveOrder: any;
  onClose: () => void;
  isMerged: boolean;
  handleSplitTables?: () => void;
  tableActiveOrderTransactionsId: string;
  isFullPaid: boolean;
  handleCloseOrder: () => Promise<void>;
  onOpenPaymentModal: () => void;
  onOpenFastPaymentModal: () => void;
  handleBackClick: () => void;
  tableId: string;
}

function TableSettingsModal({
  name,
  open,
  onClose,
  setOpen,
  setIsMergeable,
  setIsChangeable,
  setIsMoveOrder,
  isMerged,
  handleSplitTables,
  tableActiveOrderTransactionsId,
  isFullPaid,
  handleCloseOrder,
  onOpenPaymentModal,
  onOpenFastPaymentModal,
  handleBackClick,
  tableId,
}: Props) {
  const { t } = useTranslation('pos');
  const { enqueueSnackbar } = useSnackbar();
  const { meProfile } = useProfile();
  const { addOrderTransactionPayment } = usePosOrder();
  const [cancelModal, setCancelModal] = useState(false);

  const handlePrintTable = async () => {
    await addOrderTransactionPayment({
      order_transaction_id: tableId,
      payment_action: 3,
      payments: [
        {
          amount: 0,
          payment_type_id: 1,
          remainder_amount: 0,
        },
      ],
    });
    enqueueSnackbar('Hesap fişi yazdırıldı.', {
      variant: 'success',
      autoHideDuration: 3000,
    });
    handleBackClick();
  };

  const hasPaymentPermission = keyHasPermission(
    'get-order-payment',
    meProfile?.permissions || [],
  );
  const hasMoveTableOrderPermission = keyHasPermission(
    'move-table-order',
    meProfile?.permissions || [],
  );
  const hasChangeTablePermission = keyHasPermission(
    'change-table',
    meProfile?.permissions || [],
  );
  const hasMergeTablePermission = keyHasPermission(
    'merge-tables',
    meProfile?.permissions || [],
  );
  const hasSplitTablePermission = keyHasPermission(
    'split-table',
    meProfile?.permissions || [],
  );
  const hasPrintInvoicePermission = keyHasPermission(
    'view-order-detail',
    meProfile?.permissions || [],
  );
  const hasCancelOrderPermission = keyHasPermission(
    'cancel-order-transaction',
    meProfile?.permissions || [],
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`${t('sections.table.tableName')} : ${name}`}
      headerButtonIconName="Close"
      headerButton
      onHeaderButtonClick={onClose}
      headerButtonText={t('sections.table.close')}
      headerButtonIconNameErrorColor="error"
      nonDivider
    >
      <Grid container spacing={2}>
        {hasPaymentPermission && (
          <Grid xs={6} sm={4} md={4} lg={4} item>
            <Button
              variant="outlined"
              sx={styles.tableModalButtons}
              onClick={() => onOpenPaymentModal()}
            >
              <Icon color="error" sx={{ m: 1 }} name="CreditCard" />
              <span>{t('sections.table.pay')}</span>
            </Button>
          </Grid>
        )}
        {hasPaymentPermission && (
          <Grid xs={6} sm={4} md={4} lg={4} item>
            {isFullPaid ? (
              <Button
                variant="outlined"
                sx={styles.tableModalButtons}
                onClick={() => handleCloseOrder()}
              >
                <Icon color="error" sx={{ m: 1 }} name="Close" />{' '}
                {t('sections.table.closeOrder')}
              </Button>
            ) : (
              <Button
                variant="outlined"
                sx={styles.tableModalButtons}
                onClick={() => onOpenFastPaymentModal()}
              >
                <Icon color="error" sx={{ m: 1 }} name="CardTravel" />{' '}
                {t('sections.table.fast_pay')}
              </Button>
            )}
          </Grid>
        )}
        {hasMoveTableOrderPermission && (
          <Grid xs={6} sm={4} md={4} lg={4} item>
            <Button
              variant="outlined"
              sx={styles.tableModalButtons}
              onClick={() => {
                setOpen(false);
                setIsMoveOrder(true);
              }}
            >
              <Icon color="error" sx={{ m: 1 }} name="CompareArrows" />
              {t('sections.table.transferTicket')}
            </Button>
          </Grid>
        )}
        {hasChangeTablePermission && (
          <Grid xs={6} sm={4} md={4} lg={4} item>
            <Button
              variant="outlined"
              sx={styles.tableModalButtons}
              onClick={() => {
                setOpen(false);
                setIsChangeable(true);
              }}
            >
              <Icon color="error" sx={{ m: 1 }} name="ChangeCircleOutlined" />{' '}
              {t('sections.table.changeTable')}
            </Button>
          </Grid>
        )}
        {hasMergeTablePermission && (
          <Grid xs={6} sm={4} md={4} lg={4} item>
            <Button
              variant="outlined"
              sx={styles.tableModalButtons}
              onClick={() => {
                setOpen(false);
                setIsMergeable(true);
              }}
            >
              <Icon color="error" sx={{ m: 1 }} name="BackupTableOutlined" />{' '}
              {t('sections.table.mergeTable')}
            </Button>
          </Grid>
        )}
        {isMerged && hasSplitTablePermission && (
          <Grid xs={6} sm={4} md={4} lg={4} item>
            <Button
              variant="outlined"
              sx={styles.tableModalButtons}
              onClick={() => {
                setOpen(false);
                if (handleSplitTables) {
                  handleSplitTables();
                }
              }}
            >
              <Icon color="error" sx={{ m: 1 }} name="CallSplit" />{' '}
              {t('sections.table.splitTable')}
            </Button>
          </Grid>
        )}
        {hasPrintInvoicePermission && (
          <Grid xs={6} sm={4} md={4} lg={4} item>
            <Button
              variant="outlined"
              sx={styles.tableModalButtons}
              onClick={handlePrintTable}
            >
              <Icon color="error" sx={{ m: 1 }} name="PrintOutlined" />{' '}
              {t('sections.table.print')}
            </Button>
          </Grid>
        )}
        {hasCancelOrderPermission && (
          <Grid xs={6} sm={4} md={4} lg={4} item>
            <Button
              variant="outlined"
              sx={styles.tableModalButtons}
              onClick={() => setCancelModal(true)}
            >
              <Icon color="error" sx={{ m: 1 }} name="HighlightOff" />
              {t('sections.table.cancelTicket')}
            </Button>
          </Grid>
        )}
      </Grid>
      <CancelModal
        open={cancelModal}
        onClose={() => setCancelModal(false)}
        orderId={tableActiveOrderTransactionsId || ''}
        onBackClick={handleBackClick}
      />
    </Modal>
  );
}

export default TableSettingsModal;
