import { createSlice } from '@reduxjs/toolkit';
import {
  loginThunk,
  registerFirstStepThunk,
  logoutThunk,
  meThunk,
  registerSecondStepThunk,
  meWithPaymentTypesThunk,
  registerThirdStepThunk,
} from './thunks';
import { InitialState } from './types';

const initialState: InitialState = {
  login: { loading: false },
  register: { loading: false },
  logout: { loading: false },
  profile: null,
  companyRegistration: { loading: false },
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loginThunk.pending, state => ({
        ...state,
        login: { loading: true },
      }))
      .addCase(loginThunk.fulfilled, (state, action) => ({
        ...state,
        login: { loading: false, ...action.payload },
        registrationStep: action.payload.registrationStep,
      }))
      .addCase(loginThunk.rejected, state => ({
        ...state,
        login: { loading: false },
      }))
      .addCase(registerFirstStepThunk.pending, state => ({
        ...state,
        register: { loading: true },
      }))
      .addCase(registerFirstStepThunk.fulfilled, (state, action) => ({
        ...state,
        register: { loading: false, ...action.payload },
        registrationStep: 1,
      }))
      .addCase(registerFirstStepThunk.rejected, state => ({
        ...state,
        register: { loading: false },
      }))
      .addCase(logoutThunk.pending, state => ({
        ...state,
        logout: { loading: false },
      }))
      .addCase(logoutThunk.fulfilled, state => ({
        ...state,
        logout: { loading: false },
        profile: null,
      }))
      .addCase(logoutThunk.rejected, state => ({
        ...state,
        logout: { loading: false },
      }))
      .addCase(meThunk.pending, state => ({
        ...state,
        profile: null,
      }))
      .addCase(meThunk.fulfilled, (state, action) => ({
        ...state,
        profile: action.payload,
      }))
      .addCase(meThunk.rejected, state => ({
        ...state,
        profile: null,
      }))
      .addCase(meWithPaymentTypesThunk.pending, state => ({
        ...state,
        profile: null,
      }))
      .addCase(meWithPaymentTypesThunk.fulfilled, (state, action) => ({
        ...state,
        profile: action.payload,
      }))
      .addCase(meWithPaymentTypesThunk.rejected, state => ({
        ...state,
        profile: null,
      }))
      .addCase(registerSecondStepThunk.pending, state => ({
        ...state,
        companyRegistration: { loading: true },
      }))
      .addCase(registerSecondStepThunk.fulfilled, (state, action) => ({
        ...state,
        companyRegistration: {
          loading: false,
          registrationStep: action.payload.registrationStep,
        },
      }))
      .addCase(registerSecondStepThunk.rejected, state => ({
        ...state,
        companyRegistration: { loading: false },
      }))
      .addCase(registerThirdStepThunk.pending, state => ({
        ...state,
        companyRegistration: { loading: true },
      }))
      .addCase(registerThirdStepThunk.fulfilled, (state, action) => ({
        ...state,
        companyRegistration: {
          loading: false,
        },
      }))
      .addCase(registerThirdStepThunk.rejected, state => ({
        ...state,
        companyRegistration: { loading: false },
      }));
  },
});

export default authSlice.reducer;
