import { SxProps, Theme } from '@mui/material';

const styles: Record<string, SxProps<Theme>> = {
  kitchenFilterBarContainer: {
    width: '100%',
    height: { xs: '56px', sm: '64px' },
    border: '1 px solid white',
    boxShadow:
      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    backgroundColor: 'white',
  },
};

export default styles;
