import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../components/FormInput';
import Modal from '../../../components/Modal';
import useTables from '../../../hooks/useTables';
import { isApiError } from '../../../services/api/types';
import { CountTable, validationSchema } from './types';
import errorReporting from '../../../helpers/errorReporting';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm?: (name: string, count: number) => void;
  sectionId: string | null;
  item?: CountTable | null;
}

function AddBulkTableModal({
  open,
  onClose,
  onConfirm,
  sectionId,
  item,
}: Props) {
  const { t } = useTranslation('table');
  const { enqueueSnackbar } = useSnackbar();
  const { addCountTable, updateCountTable } = useTables();

  const [loading, setLoading] = useState(false);

  const initialValues = {
    name: '',
    count: 1,
  };

  const { handleSubmit, control, reset } = useForm<CountTable>({
    defaultValues: initialValues,
    resolver: validationSchema,
  });

  useEffect(() => reset(initialValues), [open]);

  const handleConfirm = () => {
    handleSubmit(async values => {
      try {
        if (values && sectionId) {
          try {
            setLoading(true);

            const result = !item?.id
              ? await addCountTable(values.name, sectionId, values.count)
              : await updateCountTable({
                  id: item.id,
                  name: values.name,
                  sectionId,
                  count: values.count,
                });

            if (onConfirm) onConfirm(values.name, values.count);
          } catch (err) {
            enqueueSnackbar(
              isApiError(err) ? err.message : t('common:unknownError' as any),
              {
                variant: 'error',
              },
            );
            errorReporting(err);
          } finally {
            setLoading(false);
          }
        }
      } catch (err) {
        errorReporting(err);
      }
    })();
  };

  return (
    <Modal
      title={t('section.add_modal.tableIdentification')}
      titleIconName="Add"
      open={open}
      onClose={onClose}
      positiveAction={t('table.addModal.confirm')}
      negativeAction={t('table.addModal.cancel')}
      onPositiveClick={handleConfirm}
      onNegativeClick={onClose}
      loading={loading}
    >
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <FormInput
            label={t('addBulkTable.tableName')}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            {...field}
          />
        )}
      />
      <Controller
        name="count"
        control={control}
        render={({ field, fieldState }) => (
          <FormInput
            label={t('addBulkTable.quantity')}
            type="number"
            error={fieldState.invalid}
            min={1}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            {...field}
          />
        )}
      />
    </Modal>
  );
}

export default AddBulkTableModal;
