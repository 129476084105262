import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormNumberInput from '../../../../../../components/FormNumberInput';
import FormSwitch from '../../../../../../components/FormSwitch';
import Modal from '../../../../../../components/Modal';
import useProducts from '../../../../../../hooks/useProducts';
import { ProductPropertyItemFormValues, validationSchema } from './types';
import useProfile from '../../../../../../hooks/useProfile';
import errorReporting from '../../../../../../helpers/errorReporting';

interface Props {
  open: boolean;
  onClose: () => void;
  initialValues: ProductPropertyItemFormValues;
  productId?: string;
  propertyItemId: string;
}

function PropertyItemEditModal({
  open,
  onClose,
  initialValues,
  productId,
  propertyItemId,
}: Props) {
  const { t } = useTranslation('product');
  const { meProfile } = useProfile();

  const { handleSubmit, control, reset } =
    useForm<ProductPropertyItemFormValues>({
      defaultValues: initialValues,
      resolver: validationSchema,
    });

  useEffect(() => reset(initialValues), [initialValues, open]);

  const { productPropertyItemUpdate, loading } = useProducts();
  const handleConfirm = () => {
    handleSubmit(async values => {
      if (productId) {
        try {
          await productPropertyItemUpdate({
            ...values,
            productId,
            propertyItemId,
          });
          onClose();
        } catch (err) {
          errorReporting(err);
        }
      }
    })();
  };

  return (
    <Modal
      title={t('properties.editModal.title')}
      titleIconName="Edit"
      open={open}
      onClose={onClose}
      positiveAction={t('properties.editModal.confirm')}
      negativeAction={t('properties.editModal.cancel')}
      onPositiveClick={handleConfirm}
      onNegativeClick={onClose}
      loading={loading}
    >
      <Controller
        name="price"
        control={control}
        render={({ field, fieldState }) => (
          <FormNumberInput
            label={t('properties.editModal.form.price.label')}
            maskProps={{
              decimalScale: 2,
              fixedDecimalScale: true,
              suffix: ` ${meProfile?.currency?.code || 'TRY'}`,
            }}
            error={fieldState.invalid}
            helperText={
              fieldState.error ? t(fieldState.error.message as any) : undefined
            }
            {...field}
          />
        )}
      />
      <Controller
        name="isActive"
        control={control}
        render={({ field, fieldState }) => (
          <FormSwitch
            label={t('properties.editModal.form.active.label')}
            error={fieldState.invalid}
            {...field}
          />
        )}
      />
    </Modal>
  );
}

export default PropertyItemEditModal;
