/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';

/** Styles */
import {
  Grid,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
  Pagination,
} from '@mui/material';

/** Components */
import UtilitiesBar, {
  Props as BarProps,
} from '../../../components/UtilitiesBar';
import useReports from '../../../hooks/useReports';
import EmptyState from '../../../components/EmptyState';
import LoadingState from '../../../components/LoadingState';
import TextModal from './TextModal';

const MENU: BarProps['menu'] = [
  { id: 0, text: 'endOfDays.details.back', startIconName: 'Back' },
];

function OperationLogsWizard() {
  const { t } = useTranslation('operationReports');
  const navigate = useNavigate();
  const { dayId } = useParams();
  const theme = useTheme();
  const [value, setValue] = useState('0');
  const [page, setPage] = useState(1);
  const [textOpenModal, setTextOpenModal] = useState(false);
  const [textModal, setTextModal] = useState('');

  const {
    dailyReports,
    logs,
    fetchOperationLogs,
    fetchDailyReports,
    loading,
    meta,
  } = useReports();

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    pageValue: number,
  ) => {
    if (pageValue !== page) {
      setPage(pageValue);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      fetchOperationLogs(
        dayId,
        value !== '0' ? Number(value) : undefined,
        pageValue,
        15,
      );
    }
  };

  useEffect(() => {
    // @ts-ignore
    setPage(meta.current_page);
  }, [meta]);

  const status = [
    {
      value: '0',
      label: t('endOfDays.details.detailTable.tableSelect.allLogs'),
    },
    {
      value: '1',
      label: t(
        'endOfDays.details.detailTable.tableSelect.canceled_order_transaction',
      ),
    },
    {
      value: '2',
      label: t('endOfDays.details.detailTable.tableSelect.deleted_order'),
    },
    {
      value: '3',
      label: t('endOfDays.details.detailTable.tableSelect.deleted_payment'),
    },
    {
      value: '4',
      label: t('endOfDays.details.detailTable.tableSelect.added_discount'),
    },
    {
      value: '5',
      label: t('endOfDays.details.detailTable.tableSelect.remove_discount'),
    },
    {
      value: '13',
      label: t('endOfDays.details.detailTable.tableSelect.added_payment'),
    },
  ];

  useEffect(() => {
    if (value !== '0') {
      fetchOperationLogs(dayId, Number(value), page, 15);
    } else {
      fetchOperationLogs(dayId, undefined, page, 15);
    }
  }, [value]);

  useEffect(() => {
    if (dayId) {
      fetchDailyReports(dayId);
    }
  }, []);

  if (loading) {
    return <LoadingState />;
  }

  return (
    <div>
      <UtilitiesBar
        containerStyle={{ mb: 3 }}
        title={t('title')}
        leftIconName="Hub"
        menu={MENU!.map(item => ({ ...item, text: t(item.text as any) }))}
        onActionClick={() => navigate('/reports/end-of-day')}
      />
      <Stack>
        <Grid
          sx={{
            padding: 3,
            mt: 5,
            border: '1px solid',
            borderRadius: 2,
            backgroundColor: 'white',
            borderColor: theme.palette.divider,
            boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
            '& pre': {
              m: 0,
              p: '16px !important',
              fontFamily: theme.typography.fontFamily,
              fontSize: '0.75rem',
            },
          }}
        >
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Grid container>
                <Typography variant="h5">
                  {t('endOfDays.details.detailTable.title')}
                  <Grid sx={{ fontSize: '12px' }}>
                    (
                    {dailyReports?.day_start_date &&
                      format(
                        new Date(dailyReports?.day_start_date),
                        'dd.MM.yyyy HH:mm',
                      )}
                    {` - ${
                      dailyReports?.day_end_date
                        ? format(
                            new Date(dailyReports?.day_end_date),
                            'dd.MM.yyyy HH:mm',
                          )
                        : dailyReports?.day_start_date &&
                          format(new Date(Date.now()), 'dd.MM.yyyy HH:mm')
                    }`}
                    )
                  </Grid>
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <TextField
                id="standard-select-currency"
                size="small"
                select
                value={value}
                placeholder="xxx"
                onChange={e => {
                  setValue(e.target.value);
                  setPage(1);
                }}
                sx={{
                  '& .MuiInputBase-input': { py: 0.5, fontSize: '0.875rem' },
                }}
              >
                {status.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <br />
          <TableContainer>
            {!logs.length ? (
              <EmptyState message={t('endOfDays.empty')} />
            ) : (
              <Table
                sx={{ width: '100%', overflowX: 'auto' }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {t('endOfDays.details.detailTable.type')}
                    </TableCell>
                    <TableCell>
                      {t('endOfDays.details.detailTable.table')}
                    </TableCell>
                    <TableCell>
                      {t('endOfDays.details.detailTable.product')}
                    </TableCell>
                    <TableCell>
                      {t('endOfDays.details.detailTable.description')}
                    </TableCell>
                    <TableCell>
                      {t('endOfDays.details.detailTable.amount')}
                    </TableCell>
                    <TableCell>
                      {t('endOfDays.details.detailTable.created_by')}
                    </TableCell>
                    <TableCell>
                      {t('endOfDays.details.detailTable.created_at')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logs.map((row: any) => (
                    <TableRow key={row.id}>
                      <TableCell>{row?.type}</TableCell>
                      <TableCell>{row?.table?.name || '-'}</TableCell>
                      <TableCell>
                        {row?.product?.translations[0]?.name || '-'}
                      </TableCell>
                      <TableCell
                        style={{
                          maxWidth: '300px',
                          minWidth: '100px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setTextOpenModal(true);
                          setTextModal(row?.description);
                        }}
                      >
                        {row.description?.length > 30
                          ? `${row?.description?.substring(0, 30)}...`
                          : row?.description || '-'}
                      </TableCell>
                      <TableCell>{row?.amount?.formatted}</TableCell>
                      <TableCell>
                        {`${row?.created_by?.first_name} ${row?.created_by?.last_name}`}
                      </TableCell>
                      <TableCell>
                        {format(new Date(row?.created_at), 'dd.MM.yyyy HH:ss')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
          <Pagination
            sx={{ margin: '10px', width: '100%' }}
            // @ts-ignore
            count={Math.ceil(meta.total / meta.per_page)}
            page={page}
            onChange={handleChange}
            size="small"
          />
        </Grid>
      </Stack>
      <TextModal
        open={textOpenModal}
        onClose={() => {
          setTextOpenModal(false);
        }}
        item={textModal}
      />
    </div>
  );
}

export default OperationLogsWizard;
