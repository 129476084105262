import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../services/api';

import { getTaxRates } from './taxRatesSlice';

export const getTaxRatesThunk = createAsyncThunk(
  'taxRates/get',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.utilities.getTaxRates();
      const result = response.data.data;
      dispatch(getTaxRates(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
