import React from 'react';
import {
  List as ListBase,
  ListItem,
  ListItemText,
  ListItemProps,
  ListProps,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import Icon from '../Icon';

type ItemBase = {
  id: string;
  text: string;
};

interface Props<Item extends ItemBase> extends ListProps {
  itemSx?: ListItemProps['sx'];
  items: Item[];
  activeItemId: string | null;
  onItemClick: (itemId: string) => void;
  actions: string[];
  onActionClick: (index: number, item: Item) => void;
  hasMoreVertPermission?: boolean;
}

function List<Item extends ItemBase>({
  itemSx,
  items,
  activeItemId,
  onItemClick,
  actions,
  onActionClick,
  hasMoreVertPermission,
}: Props<Item>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [itemToAct, setItemToAct] = React.useState<null | Item>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: Item,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setItemToAct(item);
  };

  const handleACtionClose = (index: number) => {
    setAnchorEl(null);
    if (itemToAct) {
      onActionClick(index, itemToAct);
      setItemToAct(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setItemToAct(null);
  };

  return (
    <>
      <ListBase sx={{ py: 0, overflow: 'auto', maxHeight: '500px' }}>
        {items.map((item, index) => (
          <ListItem
            key={item.id}
            sx={[
              ...(itemSx ? (Array.isArray(itemSx) ? itemSx : [itemSx]) : []),
            ]}
            secondaryAction={
              hasMoreVertPermission && (
                <IconButton
                  onClick={event => handleClick(event, item)}
                  edge="end"
                  aria-label="item-actions"
                >
                  <Icon name="MoreVert" />
                </IconButton>
              )
            }
            divider={index !== items.length - 1}
            button
            selected={activeItemId ? item.id === activeItemId : index === 0}
            onClick={() => onItemClick(item.id)}
          >
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </ListBase>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {actions.map((action, index) => (
          <MenuItem key={action} onClick={() => handleACtionClose(index)}>
            {action}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default List;
