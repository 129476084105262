import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type MenuAddItemFormValues = {
  productId: string;
  productUnitId: string;
  quantity: string;
  price: number;
};

const schema = yup.object({
  productId: yup.string().required('menu.addModal.form.name.required'),
  productUnitId: yup.string().required('menu.addModal.form.name.required'),
  quantity: yup.string().required('menu.addModal.form.name.required'),
  price: yup
    .number()
    .default(0)
    .min(0, 'menu.addModal.form.name.min')
    .typeError('menu.addModal.form.name.required')
    .required('menu.addModal.form.name.required'),
});

export const validationSchema = yupResolver(schema);

export const defaultValues: MenuAddItemFormValues = {
  productId: '',
  productUnitId: '',
  quantity: '1',
  price: 0,
};
