import { Grid, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormInputSelect from '../../../components/FormInputSelect';
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';
import arrayHasDuplicates from '../../../helpers/arrayHasDuplicates';
import useUnits from '../../../hooks/useUnits';
import { isApiError } from '../../../services/api/types';
import { useAppSelector } from '../../../store';
import errorReporting from '../../../helpers/errorReporting';
import { IdAndNameTranslation, NameTranslation } from '../../../models/General';
import { OptionsLanguageMemo } from '../../../helpers/optionMemo';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm?: (category: NameTranslation[]) => void;
  item?: IdAndNameTranslation | null;
}

function UnitAddModal({ open, onClose, onConfirm, item }: Props) {
  const { t } = useTranslation('category');
  const { enqueueSnackbar } = useSnackbar();

  const { addUnit, updateUnit } = useUnits();

  const { list } = useAppSelector(state => state.languages);

  const options = OptionsLanguageMemo(list);

  const initialValue: NameTranslation[] = [
    {
      id: Math.random().toString(),
      name: '',
      lang: options[0].id,
    },
  ];

  const [unit, setUnit] = useState<NameTranslation[]>(initialValue);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (item) {
      setUnit(item.translations);
    }
    return () => {
      setUnit(initialValue);
    };
  }, [item]);

  useEffect(() => {
    unit.forEach(element => {
      if (!element.lang) {
        const temp = unit.map(el =>
          el.id === element.id ? { ...element, lang: options[0].id } : el,
        );
        setUnit(temp);
      }
    });
  }, []);

  const handleClose = () => {
    setIsSubmitted(false);
    setUnit(initialValue);
    onClose();
  };

  const handleAddItem = () => {
    let suggestedLang = options[0].id;
    options.forEach(opt => {
      if (unit.find(a => a.lang !== opt.id)) {
        suggestedLang = opt.id;
      }
    });
    setUnit([
      ...unit,
      {
        id: Math.random().toString(),
        name: '',
        lang: suggestedLang,
      },
    ]);
  };

  const handleConfirm = async () => {
    if (!unit.some(el => !el.name || !el.lang)) {
      if (arrayHasDuplicates(unit, 'lang')) {
        enqueueSnackbar(t('unit.add_Modal.messages.duplicateLanguage'), {
          variant: 'error',
          autoHideDuration: 3000,
        });
        return;
      }
      try {
        setLoading(true);

        const constructedName: Record<string, string> = {};

        unit.forEach(({ lang: langId, name }) => {
          constructedName[langId] = name;
        });

        const result = !item?.id
          ? await addUnit(constructedName)
          : await updateUnit({ id: item.id, name: constructedName });

        if (onConfirm) onConfirm(unit);
        setUnit(initialValue);
      } catch (err: any) {
        enqueueSnackbar(
          isApiError(err) ? err.message : t('common:unknownError' as any),
          {
            variant: 'error',
            autoHideDuration: 3000,
          },
        );
        errorReporting(err);
      } finally {
        setLoading(false);
      }
    } else {
      setIsSubmitted(true);
    }
  };

  return (
    <Modal
      title={t('unit.add_Modal.title', {
        context: item ? 'edit' : 'default',
      })}
      titleIconName={item ? 'Edit' : 'Add'}
      open={open}
      onClose={handleClose}
      positiveAction={t('unit.add_Modal.confirm')}
      negativeAction={t('unit.add_Modal.cancel')}
      onPositiveClick={handleConfirm}
      onNegativeClick={handleClose}
      loading={loading}
    >
      {unit.map((element, index) => {
        const invalid = isSubmitted && (!element.name || !element.lang);
        return (
          <Grid container key={element.id}>
            <Grid item xs={10}>
              <FormInputSelect
                label={t('unit.add_Modal.form.name.label')}
                error={invalid}
                helperText={invalid && t('unit.add_Modal.form.name.required')}
                value={element.name}
                onChange={event => {
                  const temp = unit.map(el =>
                    el.id === element.id
                      ? { ...element, name: event.target.value }
                      : el,
                  );
                  setUnit(temp);
                }}
                options={options}
                selectValue={element.lang}
                onSelectValueChange={id => {
                  const selectedLanguage = options.find(lang => lang.id === id);
                  if (selectedLanguage) {
                    const temp = unit.map(el =>
                      el.id === element.id
                        ? { ...element, lang: selectedLanguage.id }
                        : el,
                    );
                    setUnit(temp);
                  }
                }}
              />
            </Grid>
            <Grid item sx={{ pt: 4 }}>
              {/* // Show only delete button if input is not the last one */}
              {index === unit.length - 1 ? (
                <>
                  {/* // Show delete button near to add button if we have more than one input only */}
                  {unit.length > 1 && (
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        setUnit(unit.filter(el => el.id !== element.id))
                      }
                      color="error"
                    >
                      <Icon name="Delete" />
                    </IconButton>
                  )}
                  {/* // Show add button only if category is less than available languages */}
                  {unit.length < options.length && (
                    <IconButton
                      aria-label="add"
                      onClick={handleAddItem}
                      color="success"
                    >
                      <Icon name="Add" />
                    </IconButton>
                  )}
                </>
              ) : (
                <IconButton
                  aria-label="delete"
                  onClick={() =>
                    setUnit(unit.filter(el => el.id !== element.id))
                  }
                  color="error"
                >
                  <Icon name="Delete" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        );
      })}
    </Modal>
  );
}

export default UnitAddModal;
