/** Dependencies */
import React from 'react';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

/** Components */
import StopWatch from '../../../../components/Timer/StopWatch';
import Icon from '../../../../components/Icon';
import ItemInfo from './ItemInfo';
import ItemCheckButton from './ItemCheckButton';

/** Styles */
import styles from '../../styles';

/** Helpers */
import getActiveLanguage from '../../../../helpers/getActiveLanguage';

interface Props {
  orders: any;
  orderItemGroup: any;
  handleOrderItemUpdate: (status: number, item_id: string) => void;
}

function MenuItemContent({
  orders,
  orderItemGroup,
  handleOrderItemUpdate,
}: Props) {
  const { t } = useTranslation('kitchen');

  return (
    <>
      <Stack mt={2} mb={2}>
        <Stack direction="row">
          <Box>
            <Typography sx={{ color: 'rgb(222, 143, 0)' }} variant="subtitle2">
              <Grid container>{t('order.ready')}</Grid>
            </Typography>
            <StopWatch
              duration={orders.created_at || orderItemGroup.created_at}
              backgroundColor="red"
              color="white"
            />
          </Box>
          <Stack
            sx={{ flex: 1, pl: 2, cursor: 'pointer' }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography color="gray" variant="subtitle2">
                <Grid container>
                  <Icon fontSize="small" name="Customer" />{' '}
                  {orderItemGroup.created_by.full_name}
                </Grid>
              </Typography>
              <Typography sx={styles.title}>
                {`${orders?.quantity} -
                              ${
                                orders?.product
                                  ? getActiveLanguage(
                                      orders?.product?.translations,
                                    )?.name
                                  : ''
                              }`}
              </Typography>
              {orders.description && (
                <Typography
                  sx={{
                    ...styles.title,
                    color: '#FF0000',
                    marginTop: '5px',
                  }}
                >
                  {t('note')}: {orders.description}
                </Typography>
              )}
            </Box>
          </Stack>
        </Stack>
      </Stack>
      {orders.items.map((item: any) => (
        <Stack mt={2} mb={2}>
          <Stack direction="row">
            <Icon
              sx={{
                mt: 3,
                mr: 1,
                ml: 5,
                color: 'rgba(211,47,47,0.44)',
              }}
              name="SubdirectoryArrowRight"
            />
            <Stack
              sx={{ flex: 1, pl: 2, cursor: 'pointer' }}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <ItemInfo
                  item={item}
                  orders={orders}
                  orderItemGroup={orderItemGroup}
                />

                {item.description && (
                  <Typography
                    sx={{
                      ...styles.title,
                      color: '#FF0000',
                      marginTop: '5px',
                    }}
                  >
                    {t('note')}: {item.description}
                  </Typography>
                )}
              </Box>
              <ItemCheckButton
                item={item}
                handleOrderItemUpdate={handleOrderItemUpdate}
              />
            </Stack>
          </Stack>
        </Stack>
      ))}
      <Divider />
    </>
  );
}

export default MenuItemContent;
