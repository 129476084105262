import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Button from '../../components/Button';
import styles from '../Register/styles';
import './style.css';
import useSettings from '../../hooks/useSettings';
import FormInput from '../../components/FormInput';
import Icon from '../../components/Icon';
import useProducts from '../../hooks/useProducts';

function InfoSettings() {
  const { t } = useTranslation('apps');
  const {
    fetchStaticPageInfo,
    onlineFoodDeliverySetInfo,
    staticPageInfos,
    faqBannerDelete,
    faqBannerMoveDown,
    faqBannerMoveUp,
  } = useSettings();
  const { updateImage, deleteImage } = useProducts();

  const [selectedLanguage, setSelectedLanguage] = useState('tr');
  const [aboutData, setAboutData] = useState([
    { lang: 'tr', description: '' },
    { lang: 'en', description: '' },
  ]);
  const [contactData, setContactData] = useState([
    { lang: 'tr', description: '' },
    { lang: 'en', description: '' },
  ]);
  const [seoTitleData, setSeoTitleData] = useState([
    { lang: 'tr', description: '' },
    { lang: 'en', description: '' },
  ]);
  const [seoDescriptionData, setSeoDescriptionData] = useState([
    { lang: 'tr', description: '' },
    { lang: 'en', description: '' },
  ]);
  const [termsOfUseData, setTermsOfUseData] = useState([
    { lang: 'tr', description: '' },
    { lang: 'en', description: '' },
  ]);
  const [confidentialityAgreementData, setDataConfidentialityAgreementData] =
    useState([
      { lang: 'tr', description: '' },
      { lang: 'en', description: '' },
    ]);
  const [faqData, setFaqData] = useState([
    {
      lang: 'tr',
      description: '',
      title: '',
      sequence: 0,
      id: '',
      itemId: Date.now(),
    },
  ]);
  const [bannerData, setBannerData] = useState([
    {
      lang: 'tr',
      description: '',
      title: '',
      sequence: 0,
      id: '',
      redirect_url: '',
      image_url: '',
      itemId: Date.now(),
    },
  ]);

  const handleOnlineFoodDeliverySetInfo = async (data: any) => {
    console.log(data);
    await onlineFoodDeliverySetInfo(data);
    await fetchStaticPageInfo();
  };

  const handleFaqBannerDelete = async (id: any) => {
    await faqBannerDelete(id);
    await fetchStaticPageInfo();
  };

  const handleMoveUp = async (id: any) => {
    await faqBannerMoveUp(id);
    await fetchStaticPageInfo();
  };

  const handleMoveDown = async (id: any) => {
    await faqBannerMoveDown(id);
    await fetchStaticPageInfo();
  };

  const handleUpload = async (
    e: any,
    id: string,
    count: number,
    sLang: string,
  ) => {
    if (id) {
      const imageResId = await updateImage(
        id || '',
        'online-food-delivery-info-translation',
        e.target.files[0],
      );
    } else {
      const imageResId = await updateImage(
        'banner',
        'online-food-delivery-info-translation',
        e.target.files[0],
      );

      await onlineFoodDeliverySetInfo({
        id: imageResId,
        lang: sLang,
        type: 'banner',
        sequence: count,
        title: '',
        description: '',
        redirect_url: '',
      });
    }

    await fetchStaticPageInfo();
  };

  const handleRemove = async (id: any) => {
    if (id) {
      await deleteImage(id || '', 'online-food-delivery-info-translation');
    }
    await fetchStaticPageInfo();
  };

  const getActiveTitle = (type: any) => {
    switch (type) {
      case 'about':
        return selectedLanguage === 'tr' ? 'Hakkımızda' : 'About';
      case 'confidentiality_agreement':
        return selectedLanguage === 'tr' ? 'Gizlilik Politikası' : 'Privacy';
      case 'faq':
        return selectedLanguage === 'tr' ? 'Sıkça Sorulan Sorular' : 'FAQ';
      case 'contact':
        return selectedLanguage === 'tr' ? 'İletisim' : 'Contact';
      case 'terms_of_use':
        return selectedLanguage === 'tr'
          ? 'Kullanım Sozlesmesi'
          : 'Terms of Use';
      case 'seo_title':
        return selectedLanguage === 'tr' ? 'SEO Başlığı' : 'SEO Title';
      case 'seo_description':
        return selectedLanguage === 'tr' ? 'SEO Açıklaması' : 'SEO Description';
      case 'banner':
        return selectedLanguage === 'tr' ? 'Banner' : 'Banner';
      default:
        return '';
    }
  };

  useEffect(() => {
    fetchStaticPageInfo();
  }, []);

  useEffect(() => {
    if (staticPageInfos) {
      setAboutData([
        {
          lang: 'tr',
          description:
            staticPageInfos
              ?.filter(item => item.type === 'about')
              ?.filter(item2 => item2.lang === 'tr')[0]?.description || '',
        },
        {
          lang: 'en',
          description:
            staticPageInfos
              ?.filter(item => item.type === 'about')
              ?.filter(item2 => item2.lang === 'en')[0]?.description || '',
        },
      ]);

      setContactData([
        {
          lang: 'tr',
          description:
            staticPageInfos
              ?.filter(item => item.type === 'contact')
              ?.filter(item2 => item2.lang === 'tr')[0]?.description || '',
        },
        {
          lang: 'en',
          description:
            staticPageInfos
              ?.filter(item => item.type === 'contact')
              ?.filter(item2 => item2.lang === 'en')[0]?.description || '',
        },
      ]);

      setSeoTitleData([
        {
          lang: 'tr',
          description:
            staticPageInfos
              ?.filter(item => item.type === 'seo_title')
              ?.filter(item2 => item2.lang === 'tr')[0]?.description || '',
        },
        {
          lang: 'en',
          description:
            staticPageInfos
              ?.filter(item => item.type === 'seo_title')
              ?.filter(item2 => item2.lang === 'en')[0]?.description || '',
        },
      ]);

      setSeoDescriptionData([
        {
          lang: 'tr',
          description:
            staticPageInfos
              ?.filter(item => item.type === 'seo_description')
              ?.filter(item2 => item2.lang === 'tr')[0]?.description || '',
        },
        {
          lang: 'en',
          description:
            staticPageInfos
              ?.filter(item => item.type === 'seo_description')
              ?.filter(item2 => item2.lang === 'en')[0]?.description || '',
        },
      ]);

      setTermsOfUseData([
        {
          lang: 'tr',
          description:
            staticPageInfos
              ?.filter(item => item.type === 'terms_of_use')
              ?.filter(item2 => item2.lang === 'tr')[0]?.description || '',
        },
        {
          lang: 'en',
          description:
            staticPageInfos
              ?.filter(item => item.type === 'terms_of_use')
              ?.filter(item2 => item2.lang === 'en')[0]?.description || '',
        },
      ]);

      setDataConfidentialityAgreementData([
        {
          lang: 'tr',
          description:
            staticPageInfos
              ?.filter(item => item.type === 'confidentiality_agreement')
              ?.filter(item2 => item2.lang === 'tr')[0]?.description || '',
        },
        {
          lang: 'en',
          description:
            staticPageInfos
              ?.filter(item => item.type === 'confidentiality_agreement')
              ?.filter(item2 => item2.lang === 'en')[0]?.description || '',
        },
      ]);

      setFaqData(staticPageInfos?.filter(item => item.type === 'faq') || []);

      setBannerData(
        staticPageInfos?.filter(item => item.type === 'banner') || [],
      );
    }
  }, [staticPageInfos, selectedLanguage]);
  console.log(staticPageInfos);
  return (
    <Grid>
      <Grid container justifyContent="right">
        <Button
          onClick={() => setSelectedLanguage('tr')}
          sx={styles.infoSubmit}
          color={selectedLanguage === 'tr' ? 'info' : 'inherit'}
          variant="contained"
          size="large"
        >
          TR
        </Button>
        <Button
          onClick={() => setSelectedLanguage('en')}
          sx={styles.infoSubmit}
          color={selectedLanguage === 'en' ? 'info' : 'inherit'}
          variant="contained"
          size="large"
        >
          EN
        </Button>
      </Grid>
      <Box
        sx={{
          py: 10,
          px: 10,
          paddingX: {
            xxs: 0,
            xs: 0,
            sm: 0,
            md: 3,
            lg: 15,
            xl: 20,
          },
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} m={2}>
            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ArrowForwardIosSharpIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5" sx={{ fontSize: '1rem' }}>
                  {getActiveTitle('about')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {aboutData?.map(
                  item =>
                    item.lang === selectedLanguage && (
                      <Grid item xs={12} m={2}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={item.description}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setAboutData(
                              aboutData.map(item2 =>
                                item2.lang === selectedLanguage
                                  ? { ...item2, description: data }
                                  : item2,
                              ),
                            );
                          }}
                        />
                        <Grid container justifyContent="right">
                          <Button
                            onClick={() =>
                              handleOnlineFoodDeliverySetInfo({
                                type: 'about',
                                lang: selectedLanguage,
                                description: aboutData?.filter(
                                  item2 => item2.lang === selectedLanguage,
                                )[0]?.description,
                              })
                            }
                            sx={styles.infoSubmit}
                            variant="contained"
                            size="large"
                          >
                            Gönder
                          </Button>
                        </Grid>
                      </Grid>
                    ),
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ArrowForwardIosSharpIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5" sx={{ fontSize: '1rem' }}>
                  {getActiveTitle('contact')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {contactData?.map(
                  item =>
                    item.lang === selectedLanguage && (
                      <Grid item xs={12} m={2}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={item.description}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setContactData(
                              contactData.map(item2 =>
                                item2.lang === selectedLanguage
                                  ? { ...item2, description: data }
                                  : item2,
                              ),
                            );
                          }}
                        />
                        <Grid container justifyContent="right">
                          <Button
                            onClick={() =>
                              handleOnlineFoodDeliverySetInfo({
                                type: 'contact',
                                lang: selectedLanguage,
                                description: contactData?.filter(
                                  item2 => item2.lang === selectedLanguage,
                                )[0]?.description,
                              })
                            }
                            sx={styles.infoSubmit}
                            variant="contained"
                            size="large"
                          >
                            Gönder
                          </Button>
                        </Grid>
                      </Grid>
                    ),
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ArrowForwardIosSharpIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5" sx={{ fontSize: '1rem' }}>
                  {getActiveTitle('seo_title')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {seoTitleData?.map(
                  item =>
                    item.lang === selectedLanguage && (
                      <Grid item xs={12} m={2}>
                        <FormInput
                          value={item.description}
                          onChange={event => {
                            setSeoTitleData(
                              seoTitleData.map(item2 =>
                                item2.lang === selectedLanguage
                                  ? {
                                      ...item2,
                                      description: event.target.value,
                                    }
                                  : item2,
                              ),
                            );
                          }}
                        />
                        <Grid container justifyContent="right">
                          <Button
                            onClick={() =>
                              handleOnlineFoodDeliverySetInfo({
                                type: 'seo_title',
                                lang: selectedLanguage,
                                description: seoTitleData?.filter(
                                  item2 => item2.lang === selectedLanguage,
                                )[0]?.description,
                              })
                            }
                            sx={styles.infoSubmit}
                            variant="contained"
                            size="large"
                          >
                            Gönder
                          </Button>
                        </Grid>
                      </Grid>
                    ),
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ArrowForwardIosSharpIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5" sx={{ fontSize: '1rem' }}>
                  {getActiveTitle('seo_description')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {seoDescriptionData?.map(
                  item =>
                    item.lang === selectedLanguage && (
                      <Grid item xs={12} m={2}>
                        <FormInput
                          value={item.description}
                          onChange={event => {
                            setSeoDescriptionData(
                              seoDescriptionData.map(item2 =>
                                item2.lang === selectedLanguage
                                  ? {
                                      ...item2,
                                      description: event.target.value,
                                    }
                                  : item2,
                              ),
                            );
                          }}
                        />
                        <Grid container justifyContent="right">
                          <Button
                            onClick={() =>
                              handleOnlineFoodDeliverySetInfo({
                                type: 'seo_description',
                                lang: selectedLanguage,
                                description: seoDescriptionData?.filter(
                                  item2 => item2.lang === selectedLanguage,
                                )[0]?.description,
                              })
                            }
                            sx={styles.infoSubmit}
                            variant="contained"
                            size="large"
                          >
                            Gönder
                          </Button>
                        </Grid>
                      </Grid>
                    ),
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ArrowForwardIosSharpIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5" sx={{ fontSize: '1rem' }}>
                  {getActiveTitle('confidentiality_agreement')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {confidentialityAgreementData?.map(
                  item =>
                    item.lang === selectedLanguage && (
                      <Grid item xs={12} m={2}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={item.description}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setDataConfidentialityAgreementData(
                              confidentialityAgreementData.map(item2 =>
                                item2.lang === selectedLanguage
                                  ? { ...item2, description: data }
                                  : item2,
                              ),
                            );
                          }}
                        />
                        <Grid container justifyContent="right">
                          <Button
                            onClick={() =>
                              handleOnlineFoodDeliverySetInfo({
                                type: 'confidentiality_agreement',
                                lang: selectedLanguage,
                                description:
                                  confidentialityAgreementData?.filter(
                                    item2 => item2.lang === selectedLanguage,
                                  )[0]?.description,
                              })
                            }
                            sx={styles.infoSubmit}
                            variant="contained"
                            size="large"
                          >
                            Gönder
                          </Button>
                        </Grid>
                      </Grid>
                    ),
                )}
              </AccordionDetails>
            </Accordion>

            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ArrowForwardIosSharpIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5" sx={{ fontSize: '1rem' }}>
                  {getActiveTitle('terms_of_use')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {termsOfUseData?.map(
                  item =>
                    item.lang === selectedLanguage && (
                      <Grid item xs={12} m={2}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={item.description}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setTermsOfUseData(
                              termsOfUseData.map(item2 =>
                                item2.lang === selectedLanguage
                                  ? { ...item2, description: data }
                                  : item2,
                              ),
                            );
                          }}
                        />
                        <Grid container justifyContent="right">
                          <Button
                            onClick={() =>
                              handleOnlineFoodDeliverySetInfo({
                                type: 'terms_of_use',
                                lang: selectedLanguage,
                                description: termsOfUseData?.filter(
                                  item2 => item2.lang === selectedLanguage,
                                )[0]?.description,
                              })
                            }
                            sx={styles.infoSubmit}
                            variant="contained"
                            size="large"
                          >
                            Gönder
                          </Button>
                        </Grid>
                      </Grid>
                    ),
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} m={2}>
            <Grid container justifyContent="space-around" mb={5}>
              <Grid item xs={6}>
                <Typography
                  sx={{ textDecoration: 'underline', fontSize: '1.2rem' }}
                  variant="h5"
                  ml={2}
                >
                  {getActiveTitle('faq')}
                </Typography>
              </Grid>
              <Grid
                sx={{ display: 'flex', justifyContent: 'right' }}
                xs={6}
                item
              >
                <Button
                  onClick={() => {
                    const newData = {
                      lang: selectedLanguage,
                      title: '',
                      description: '',
                      sequence: 0,
                      id: '',
                      itemId: Date.now(),
                    };
                    setFaqData([...faqData, newData]);
                  }}
                  sx={{
                    height: 35,
                  }}
                  variant="contained"
                  size="large"
                >
                  Ekle
                </Button>
              </Grid>
            </Grid>
            {faqData.map(
              (item, index) =>
                item.lang === selectedLanguage && (
                  <Accordion elevation={0}>
                    <AccordionSummary
                      expandIcon={<ArrowForwardIosSharpIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h5" sx={{ fontSize: '1rem' }}>
                        {item.title || `${getActiveTitle('faq')} ${index + 1}`}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid p={1} key={item.itemId}>
                        <FormInput
                          value={item.title}
                          onChange={e =>
                            setFaqData(
                              faqData.map(item2 =>
                                item2.itemId === item.itemId
                                  ? { ...item2, title: e.target.value }
                                  : item2,
                              ),
                            )
                          }
                          label="Başlık"
                        />
                        <FormInput
                          value={item.description}
                          onChange={e =>
                            setFaqData(
                              faqData.map(item2 =>
                                item2.itemId === item.itemId
                                  ? { ...item2, description: e.target.value }
                                  : item2,
                              ),
                            )
                          }
                          label="Açıklama"
                        />
                        <FormInput
                          value={item.sequence}
                          onChange={e =>
                            setFaqData(
                              faqData.map(item2 =>
                                item2.itemId === item.itemId
                                  ? {
                                      ...item2,
                                      sequence: Number(e.target.value),
                                    }
                                  : item2,
                              ),
                            )
                          }
                          label="Sıra"
                        />
                        <Grid container justifyContent="right">
                          {item.id && (
                            <>
                              <Button
                                onClick={() => handleMoveUp(item.id)}
                                sx={{ ...styles.infoSubmit, mr: 1 }}
                                variant="contained"
                                size="large"
                              >
                                <Icon fontSize="large" name="KeyboardArrowUp" />
                              </Button>
                              <Button
                                onClick={() => handleMoveDown(item.id)}
                                sx={{ ...styles.infoSubmit, mr: 1 }}
                                variant="contained"
                                size="large"
                              >
                                <Icon
                                  fontSize="large"
                                  name="KeyboardArrowDown"
                                />
                              </Button>
                            </>
                          )}
                          <Button
                            onClick={() => {
                              setFaqData(
                                faqData.map(item2 =>
                                  item2.itemId === item.itemId
                                    ? { ...item2, lang: selectedLanguage }
                                    : item2,
                                ),
                              );
                              handleOnlineFoodDeliverySetInfo({
                                type: 'faq',
                                lang: selectedLanguage,
                                title: item.title,
                                description: item.description,
                                sequence: item.sequence,
                                id: item.id,
                              });
                            }}
                            sx={{ ...styles.infoSubmit, mr: 1 }}
                            variant="contained"
                            size="large"
                          >
                            {!item.id ? 'Ekle' : 'Güncelle'}
                          </Button>
                          <Button
                            onClick={() => {
                              if (item.id) {
                                handleFaqBannerDelete(item.id);
                              } else {
                                setFaqData(
                                  faqData.filter(
                                    item2 => item2.itemId !== item.itemId,
                                  ),
                                );
                              }
                            }}
                            sx={styles.infoSubmit}
                            color="error"
                            variant="contained"
                            size="large"
                          >
                            Sil
                          </Button>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ),
            )}
          </Grid>
          <Grid item xs={12} m={2}>
            <Grid container justifyContent="space-around" mb={5}>
              <Grid item xs={6}>
                <Typography
                  sx={{ textDecoration: 'underline', fontSize: '1.2rem' }}
                  variant="h5"
                  ml={2}
                >
                  {getActiveTitle('banner')}
                </Typography>
              </Grid>
              <Grid
                sx={{ display: 'flex', justifyContent: 'right' }}
                xs={6}
                item
              >
                <Button
                  onClick={() => {
                    const newData = {
                      lang: selectedLanguage,
                      title: '',
                      description: '',
                      sequence: 0,
                      redirect_url: '',
                      image_url: '',
                      id: '',
                      itemId: Date.now(),
                    };
                    setBannerData([...bannerData, newData]);
                  }}
                  sx={{
                    height: 35,
                  }}
                  variant="contained"
                  size="large"
                >
                  Ekle
                </Button>
              </Grid>
            </Grid>

            {bannerData.map(
              (item, index) =>
                item.lang === selectedLanguage && (
                  <Accordion elevation={0}>
                    <AccordionSummary
                      expandIcon={<ArrowForwardIosSharpIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h5" sx={{ fontSize: '1rem' }}>
                        {item.title || `Banner ${index + 1}`}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid p={1} key={item.itemId}>
                        <Box
                          sx={{
                            p: 2,
                            border: 1,
                            borderRadius: 3,
                            borderColor: 'neutral.dark',
                            mb: 2,
                          }}
                        >
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              {item?.image_url ? (
                                <Grid
                                // onClick={() => setOpenImage(true)}
                                >
                                  <img
                                    style={{
                                      width: '100px',
                                      height: '100px',
                                      cursor: 'pointer',
                                    }}
                                    src={item?.image_url}
                                    alt="Uploaded"
                                  />
                                </Grid>
                              ) : (
                                t('online_food_order_settings.add_image')
                              )}
                            </Grid>
                            <Grid item>
                              <Button
                                color="primary"
                                size="small"
                                startIcon={<Icon name="Upload" />}
                                onClick={() =>
                                  // @ts-ignore
                                  document
                                    .getElementById(`image-input${item.itemId}`)
                                    .click()
                                }
                              >
                                {!item?.image_url
                                  ? t('online_food_order_settings.select_image')
                                  : t(
                                      'online_food_order_settings.change_image',
                                    )}
                              </Button>
                              {item?.image_url && (
                                <IconButton
                                  color="error"
                                  onMouseDown={e => e.stopPropagation()}
                                  onClick={() => handleRemove(item.id)}
                                >
                                  <Icon
                                    sx={{ width: '30px', height: '30px' }}
                                    name="Delete"
                                  />
                                </IconButton>
                              )}
                              <input
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                id={`image-input${item.itemId}`}
                                onChange={e =>
                                  handleUpload(
                                    e,
                                    item.id,
                                    index + 1,
                                    selectedLanguage,
                                  )
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <FormInput
                          value={item.title}
                          onChange={e =>
                            setBannerData(
                              bannerData.map(item2 =>
                                item2.itemId === item.itemId
                                  ? { ...item2, title: e.target.value }
                                  : item2,
                              ),
                            )
                          }
                          label="Başlık"
                        />
                        <FormInput
                          value={item.description}
                          onChange={e =>
                            setBannerData(
                              bannerData.map(item2 =>
                                item2.itemId === item.itemId
                                  ? { ...item2, description: e.target.value }
                                  : item2,
                              ),
                            )
                          }
                          label="Açıklama"
                        />
                        <FormInput
                          value={item.sequence}
                          onChange={e =>
                            setBannerData(
                              bannerData.map(item2 =>
                                item2.itemId === item.itemId
                                  ? {
                                      ...item2,
                                      sequence: Number(e.target.value),
                                    }
                                  : item2,
                              ),
                            )
                          }
                          label="Sıra"
                        />
                        <FormInput
                          value={item.redirect_url}
                          onChange={e =>
                            setBannerData(
                              bannerData.map(item2 =>
                                item2.itemId === item.itemId
                                  ? { ...item2, redirect_url: e.target.value }
                                  : item2,
                              ),
                            )
                          }
                          label="Yönlendirme Linki"
                        />
                        <Grid container justifyContent="right">
                          {item.id && (
                            <>
                              <Button
                                onClick={() => handleMoveUp(item.id)}
                                sx={{ ...styles.infoSubmit, mr: 1 }}
                                variant="contained"
                                size="large"
                              >
                                <Icon fontSize="large" name="KeyboardArrowUp" />
                              </Button>
                              <Button
                                onClick={() => handleMoveDown(item.id)}
                                sx={{ ...styles.infoSubmit, mr: 1 }}
                                variant="contained"
                                size="large"
                              >
                                <Icon
                                  fontSize="large"
                                  name="KeyboardArrowDown"
                                />
                              </Button>
                            </>
                          )}
                          <Button
                            onClick={() => {
                              setBannerData(
                                bannerData.map(item2 =>
                                  item2.itemId === item.itemId
                                    ? { ...item2, lang: selectedLanguage }
                                    : item2,
                                ),
                              );
                              handleOnlineFoodDeliverySetInfo({
                                type: 'banner',
                                lang: selectedLanguage,
                                title: item.title,
                                description: item.description,
                                sequence: item.sequence,
                                redirect_url: item.redirect_url,
                                id: item.id,
                              });
                            }}
                            sx={{ ...styles.infoSubmit, mr: 1 }}
                            variant="contained"
                            size="large"
                          >
                            {!item.id ? 'Ekle' : 'Güncelle'}
                          </Button>
                          <Button
                            onClick={() => {
                              if (item.id) {
                                handleFaqBannerDelete(item.id);
                              } else {
                                setBannerData(
                                  bannerData.filter(
                                    item2 => item2.itemId !== item.itemId,
                                  ),
                                );
                              }
                            }}
                            sx={styles.infoSubmit}
                            color="error"
                            variant="contained"
                            size="large"
                          >
                            Sil
                          </Button>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ),
            )}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}

export default InfoSettings;
