/** Dependencies */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import { Box, Paper, Tab, Tabs } from '@mui/material';

/** Components */
import TabPanel from '../../../CompanyRegister/TabPanel';
import GeneralSettingsForm from './GeneralSettingsForm';
import PaymentTypesForm from './PaymentTypesForm';

/** Hooks */
import useProfile from '../../../../hooks/useProfile';

/** Helpers */
import keyHasPermission from '../../../../helpers/keyHasPermission';
import { a11yProps } from '../../../../helpers/rgbToHex';

function Form() {
  const { meProfile } = useProfile();
  const [value, setValue] = useState(0);
  const { t } = useTranslation('printerSettings');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const hasShowGeneralSettings = keyHasPermission(
    'view-general-settings',
    meProfile?.permissions || [],
  );

  const hasShowPaymentTypes = keyHasPermission(
    'view-payment-types',
    meProfile?.permissions || [],
  );

  return (
    <Box>
      <Paper sx={{ p: 3, flex: 1 }} elevation={3}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          {hasShowGeneralSettings && hasShowPaymentTypes && (
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {hasShowGeneralSettings && (
                <Tab label={t('general_settings')} {...a11yProps(0)} />
              )}
              {hasShowPaymentTypes && (
                <Tab label={t('payload_type')} {...a11yProps(1)} />
              )}
            </Tabs>
          )}
        </Box>
        {hasShowGeneralSettings && (
          <TabPanel value={value} index={0}>
            <GeneralSettingsForm />
          </TabPanel>
        )}
        {hasShowPaymentTypes && (
          <TabPanel value={value} index={1}>
            <PaymentTypesForm />
          </TabPanel>
        )}
      </Paper>
    </Box>
  );
}

export default Form;
