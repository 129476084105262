/** Dependencies */
import React from 'react';
import { Divider, Grid, Paper, Typography, keyframes } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PaymentModalCustomer from './PaymentModalCustomer';

/** Components */
import Button from '../../../../../../components/Button';
import Icon from '../../../../../../components/Icon';

/** Hooks */
import useProfile from '../../../../../../hooks/useProfile';

/** Styles */
import styles from '../styles';

/** Helpers */
import keyHasPermission from '../../../../../../helpers/keyHasPermission';
import useSettings from '../../../../../../hooks/useSettings';

interface Props {
  handleAddPaymentClick: (id: number, customer_id?: string) => void;
  selectedOrderTypeId: number;
  paymentCustomer: boolean;
  setPaymentCustomer: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
}

const blink = keyframes` from { color: white } to { color: red }`;

function PaymentModalRightSide({
  handleAddPaymentClick,
  selectedOrderTypeId,
  paymentCustomer,
  setPaymentCustomer,
}: Props) {
  const { meProfile } = useProfile();
  const { fetchMeWithPaymentTypes } = useSettings();

  const { t } = useTranslation('pos');

  const hasGetOrderPaymentPermission = keyHasPermission(
    'get-order-payment',
    meProfile?.permissions || [],
  );

  return (
    <Paper sx={styles.paymentModalPaper} elevation={3}>
      {t('modalMidSide.type')}
      <Divider />
      <Grid sx={{ mt: 1 }} container item justifyContent="flex-start" xs={12}>
        {meProfile?.paymentTypes?.map(
          item =>
            item.is_active && (
              <Grid sx={{ p: 1 }} item xs={6} sm={6} lg={4}>
                <Button
                  color="inherit"
                  fullWidth
                  sx={styles.paymentTypeButton}
                  onClick={() => {
                    if (item.id === 9) {
                      setPaymentCustomer(true);
                    } else {
                      handleAddPaymentClick(item.id);
                    }
                  }}
                  disabled={!hasGetOrderPaymentPermission}
                >
                  <Grid justifyContent="center">
                    {item.id === selectedOrderTypeId && (
                      <Grid
                        sx={{
                          ...styles.paymentModalRightCircleType,
                          animation: `${blink} 2s linear infinite`,
                        }}
                      >
                        <Icon name="Circle" />
                      </Grid>
                    )}
                    <Grid>
                      <img
                        src={item.logo_url}
                        alt=""
                        width="40px"
                        height="40px"
                      />
                    </Grid>
                    <Grid>
                      <Typography noWrap>{item.name}</Typography>
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
            ),
        )}
      </Grid>
      <PaymentModalCustomer
        open={paymentCustomer}
        handleAddPaymentClick={(id, customer_id) =>
          handleAddPaymentClick(id, customer_id)
        }
        onClose={() => setPaymentCustomer(false)}
      />
    </Paper>
  );
}

export default PaymentModalRightSide;
