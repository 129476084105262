import React, { useState } from 'react';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { SelectChangeEvent, styled } from '@mui/material';
import MobileMenuButton from './MobileMenuButton';
import MobileMenu from './MobileMenu';
import TopBarIcons from './TopBarIcons';
import Logo from './Logo';
import { DRAWER_WIDTH } from '../SideBar/SideBar';
import DrawerButton from '../SideBar/DrawerButton';
import useUtilities from '../../hooks/useUtilities';
import useExpenseCategories from '../../hooks/useExpenseCategories';
import { expiredAccountStorage } from '../../services/tokenStorage';

interface AppBarProps extends MuiAppBarProps {
  open: boolean;
  sideBarPermanent: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open, sideBarPermanent }) => ({
  width: sideBarPermanent ? `calc(100% - ${theme.spacing(7)} + 1px)` : '100%',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface Props {
  drawerExpanded: boolean;
  sideBarPermanent: boolean;
  onDrawerToggle: () => void;
  handleChange(event: SelectChangeEvent<string | undefined>): void;
  selectValue: string;
  setSelectValue: React.Dispatch<React.SetStateAction<string>>;
}
export default function PrimarySearchAppBar({
  drawerExpanded,
  sideBarPermanent,
  onDrawerToggle,
  handleChange,
  selectValue,
  setSelectValue,
}: Props) {
  const { fetchCurrencies } = useUtilities();
  const { fetchExpenseCategories } = useExpenseCategories();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [expenseAddModal, setExpenseAddModal] = useState(false);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleExpenseModalClose = () => {
    setExpenseAddModal(false);
  };

  const getExpenseModalData = async () => {
    await fetchCurrencies();
    await fetchExpenseCategories();
    setExpenseAddModal(true);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const token = expiredAccountStorage.getToken();

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: 'white', zIndex: '1' }}
        open={drawerExpanded}
        sideBarPermanent={sideBarPermanent}
      >
        <Toolbar>
          {!sideBarPermanent && !drawerExpanded ? (
            <DrawerButton onClick={onDrawerToggle} />
          ) : null}
          <Logo
            handleChange={handleChange}
            selectValue={selectValue}
            setSelectValue={setSelectValue}
            token={token}
          />
          <Box sx={{ flexGrow: 1 }} />
          <TopBarIcons
            getExpenseModalData={getExpenseModalData}
            onClose={handleExpenseModalClose}
            expenseAddModal={expenseAddModal}
            token={token}
          />
          <MobileMenuButton onClick={handleMobileMenuOpen} token={token} />
        </Toolbar>
      </AppBar>
      <Toolbar />
      <MobileMenu
        anchor={mobileMoreAnchorEl}
        onClose={handleMobileMenuClose}
        getExpenseModalData={getExpenseModalData}
        onExpenseClose={handleExpenseModalClose}
        expenseAddModal={expenseAddModal}
        token={token}
      />
    </Box>
  );
}
