/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

/** Styles */
import { Avatar, CardHeader, Divider, Switch } from '@mui/material';
import { Phone, ToggleOn } from '@mui/icons-material';
import AddCardIcon from '@mui/icons-material/AddCard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PaymentIcon from '@mui/icons-material/Payment';

/** Components */
import useCustomers from '../../../hooks/useCustomers';
import CustomerDetailsHeader from './CustomerDetailsHeader';
import ErrorState from '../../../components/ErrorState';
import LoadingState from '../../../components/LoadingState';
import keyHasPermission from '../../../helpers/keyHasPermission';
import useProfile from '../../../hooks/useProfile';
import errorReporting from '../../../helpers/errorReporting';
import CustomerDetailsEditModal from './Modals/CustomerDetailsEditModal';
import { CustomerBase1 } from './types';

interface Props {
  customerBalance: boolean;
  customerBalanceFalse: () => void;
  onOpenAccount: () => void;
  onCloseOpenAccount: () => void;
}
function CustomerInfo({
  customerBalance,
  onOpenAccount,
  customerBalanceFalse,
  onCloseOpenAccount,
}: Props) {
  const { t } = useTranslation('customerAddress');
  const { customerId } = useParams();
  const { meProfile } = useProfile();
  const {
    customer,
    customerError,
    getCustomer,
    toggleActiveSingle,
    toggleOpenAccountSingle,
  } = useCustomers();

  const [loadingCustomer, setLoadingCustomer] = useState<boolean>(false);
  const [loadingOpenAccount, setLoadingOpenAccount] = useState<boolean>(false);
  const [customerRefresh, setCustomerRefresh] = useState<boolean>(false);
  const [customerToEdit, setCustomerToEdit] = useState<CustomerBase1 | null>(
    null,
  );
  const [customerEditModal, setCustomerEditModal] = useState(false);

  useEffect(() => {
    if (customerId && !customerBalance) {
      getCustomer(customerId);
    }
    customerBalanceFalse();
  }, [customerBalance]);

  useEffect(() => {
    if (customerId && !customerRefresh) {
      getCustomer(customerId);
    }
    setCustomerRefresh(false);
  }, [customerRefresh]);

  useEffect(() => {
    if (customer?.is_open_account) {
      onOpenAccount();
    } else {
      onCloseOpenAccount();
    }
  }, [customer?.is_open_account]);

  const handleToggleActive = async (id: string) => {
    setLoadingCustomer(true);
    try {
      await toggleActiveSingle(id);
      if (customerId) {
        getCustomer(customerId);
      }
    } catch (err: any) {
      errorReporting(err);
    } finally {
      setLoadingCustomer(false);
    }
  };
  const handleToggleOpenAccount = async (id: string) => {
    setLoadingOpenAccount(true);
    try {
      await toggleOpenAccountSingle(id);
      if (customerId) {
        getCustomer(customerId);
      }
    } catch (err: any) {
      errorReporting(err);
    } finally {
      setLoadingOpenAccount(false);
    }
  };

  if (loadingCustomer) {
    return <LoadingState />;
  }

  if (customerError) {
    return <ErrorState message={customerError.message} />;
  }

  const hasUpdatePermission = keyHasPermission(
    'update-customer',
    meProfile?.permissions || [],
  );

  return (
    <div>
      <CustomerDetailsHeader
        title={t('customerDetails.title')}
        onAddClick={() => {
          setCustomerToEdit(customer);
          setCustomerEditModal(true);
        }}
        tooltipTitle={t('customerDetails.customerEdit')}
        addButton={hasUpdatePermission}
      />
      <Divider style={{ marginTop: '15px' }} />
      <CardHeader
        avatar={
          <Avatar aria-label="recipe">
            {customer?.first_name?.charAt(0).toUpperCase() || '*'}
          </Avatar>
        }
        title={t('customerDetails.name')}
        subheader={`${customer?.first_name || ''} ${customer?.last_name || ''}`}
      />
      <CardHeader
        avatar={
          <Avatar aria-label="recipe">
            <Phone />
          </Avatar>
        }
        title={t('customerDetails.phoneNumber')}
        subheader={customer?.phone_number || '-'}
      />
      <CardHeader
        onClick={() => {
          if (customerId && hasUpdatePermission) {
            handleToggleActive(customerId);
          }
        }}
        avatar={
          <Avatar aria-label="recipe">
            <ToggleOn />
          </Avatar>
        }
        title={t('customerDetails.isActive')}
        subheader={
          <Switch
            checked={!!customer?.is_active}
            disabled={loadingCustomer || !hasUpdatePermission}
          />
        }
      />
      <CardHeader
        onClick={() => {
          if (customerId && hasUpdatePermission) {
            handleToggleOpenAccount(customerId);
          }
        }}
        avatar={
          <Avatar aria-label="recipe">
            <ToggleOn />
          </Avatar>
        }
        title={
          !customer?.is_open_account
            ? t('customerDetails.openAccount')
            : t('customerDetails.isOpenAccount')
        }
        subheader={
          <Switch
            checked={!!customer?.is_open_account}
            disabled={loadingOpenAccount || !hasUpdatePermission}
          />
        }
      />

      {loadingOpenAccount ? (
        <LoadingState />
      ) : (
        customer?.is_open_account && (
          <>
            <Divider />
            {customer?.chargeable_amount && (
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe">
                    <Avatar aria-label="recipe">
                      <CreditScoreIcon />
                    </Avatar>
                  </Avatar>
                }
                title={t('customerDetails.openAccountLimit')}
                subheader={customer.chargeable_amount?.formatted}
                titleTypographyProps={{ variant: 'subtitle2' }}
              />
            )}

            <CardHeader
              avatar={
                <Avatar aria-label="recipe">
                  <Avatar aria-label="recipe">
                    <PaymentIcon />
                  </Avatar>
                </Avatar>
              }
              title={t('customerDetails.balance')}
              subheader={customer?.open_account_info?.balance?.formatted}
              titleTypographyProps={{ variant: 'subtitle2' }}
            />
            <CardHeader
              avatar={
                <Avatar aria-label="recipe">
                  <AccountBalanceWalletIcon />
                </Avatar>
              }
              title={t('customerDetails.totalAmountOrder')}
              subheader={
                customer?.is_open_account === true
                  ? customer?.open_account_info?.total_transaction_amount
                      ?.formatted
                  : null
              }
              titleTypographyProps={{ variant: 'subtitle2' }}
            />

            <CardHeader
              avatar={
                <Avatar aria-label="recipe">
                  <AddCardIcon />
                </Avatar>
              }
              title={t('customerDetails.totalAmountPaid')}
              subheader={
                customer?.open_account_info?.total_customer_payment_amount
                  ?.formatted
              }
              titleTypographyProps={{ variant: 'subtitle2' }}
              subtitleStyle={{ color: 'red' }}
            />
          </>
        )
      )}
      {customer && (
        <CustomerDetailsEditModal
          open={customerEditModal}
          item={customerToEdit}
          onClose={() => {
            setCustomerToEdit(null);
            setCustomerEditModal(false);
          }}
          onConfirm={() => {
            setCustomerToEdit(null);
            setCustomerEditModal(false);
            setCustomerRefresh(true);
          }}
        />
      )}
    </div>
  );
}

export default CustomerInfo;
