import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../services/api';
import {
  getDailyReports,
  getDashboardReports,
  getMostPopular,
  getOperationLogs,
  getPaymentTypeReports,
} from './reportsSlice';

export const getDailyReportsThunk = createAsyncThunk(
  'reports/daily-reports',
  async (dayId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.reports.endOfDays.getDailyReport(dayId);
      const result = response.data.data;
      dispatch(getDailyReports(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getMostPopularThunk = createAsyncThunk(
  'reports/most-popular',
  async (
    params: {
      dayId?: string;
      page?: number;
      sort?: string;
      start_date?: string;
      end_date?: string;
      per_page?: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await api.reports.endOfDays.getMostPopularProductsOfDay(
        params.dayId,
        params.page,
        params.sort,
        params.start_date,
        params.end_date,
        params.per_page,
      );
      const result = response.data;
      dispatch(getMostPopular(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getDashboardReportsThunk = createAsyncThunk(
  'reports/dashboard',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.reports.endOfDays.getDashboardReports();
      const result = response.data.data;
      dispatch(getDashboardReports(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getPaymentTypeReportsThunk = createAsyncThunk(
  'reports/most-popular',
  async (
    params: {
      start_date?: string;
      end_date?: string;
      day_id?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await api.reports.endOfDays.getPaymentTypeReports(
        params.start_date,
        params.end_date,
        params.day_id,
      );
      const result = response.data.data;
      dispatch(getPaymentTypeReports(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getOperationLogsThunk = createAsyncThunk(
  'reports/operation-logs',
  async (
    params: {
      day_id?: string;
      type?: number;
      page?: number;
      per_page?: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await api.reports.endOfDays.getOperationLogs(
        params.day_id,
        params.type,
        params.page,
        params.per_page,
      );
      const result = response.data;
      dispatch(getOperationLogs(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const resetAccountThunk = createAsyncThunk(
  'reset-account',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.reports.endOfDays.resetAccount();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
