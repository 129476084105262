import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../services/api';
import { getRoles, getRole, updateRoles } from './rolesSlice';
import { UpdateRolePermissionsParams } from '../../../../services/api/userOperations/roles/types';

export const getRolesListThunk = createAsyncThunk(
  'role/list',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.userOperations.roles.list();
      const result = response.data.data;
      dispatch(getRoles(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getRoleListThunk = createAsyncThunk(
  'role/list',
  async (roleId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.userOperations.roles.viewRole(roleId);
      const result = response.data.data;
      dispatch(getRole(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateRolePermissionsThunk = createAsyncThunk(
  'role/update',
  async (
    params: UpdateRolePermissionsParams,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await api.userOperations.roles.update(params);
      const result = response.data.data;
      dispatch(updateRoles(result));
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
