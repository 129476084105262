/** Dependencies */
import React from 'react';
import { Divider, Paper, Stack } from '@mui/material';

import { useTranslation } from 'react-i18next';

/** Styles */
import styles from '../styles';

/** helpers */
import getActiveLanguage from '../../../../../../../helpers/getActiveLanguage';

/** Components */
import MenuOnSelectionModal from '../../MenuOnSelectionModal';
import useBasketItems from '../../../../../../../hooks/useBasketItems/useBasketItems';
import { MenuProduct } from '../../../../../../../store/slices/basketItemSlice/types';
import { ActiveBasketProps } from '../../../LeftColumn/BasketList/types';
import { priceFormatter } from '../../../../../../../helpers/moneyFormatter';
import MenuModalLeftSideMenuItems from './MenuModalLeftSideMenuItems';

interface Props {
  menuNames: { id: string; name: string }[];
  handleIncrease: (itemId: string, menuId: string) => void;
  activeBasketProps: ActiveBasketProps;
  setActiveBasketProps: React.Dispatch<React.SetStateAction<ActiveBasketProps>>;
  selectedItem: MenuProduct | undefined;
  setSelectedItem: React.Dispatch<
    React.SetStateAction<MenuProduct | undefined>
  >;
  onMenuOnSelectionModal: { isSelected: boolean; id: string };
  setOnMenuOnSelectionModal: (
    value:
      | ((prevState: { isSelected: boolean; id: string }) => {
          isSelected: boolean;
          id: string;
        })
      | { isSelected: boolean; id: string },
  ) => void;
  currencyCode: string;
}

function MenuModalLeftSide({
  menuNames,
  handleIncrease,
  activeBasketProps,
  setActiveBasketProps,
  selectedItem,
  setSelectedItem,
  onMenuOnSelectionModal,
  setOnMenuOnSelectionModal,
  currencyCode,
}: Props) {
  const {
    productMenus,
    decreaseCountForMenu,
    removeItemForMenu,
    selectMenuBasketItems,
  } = useBasketItems();
  const { t } = useTranslation('pos');

  const handleRemove = (
    basketItem: MenuProduct,
    menuId: string,
    itemId: string,
  ) => {
    removeItemForMenu(basketItem, menuId, itemId);
  };

  const handleDecrease = (itemId: string, menuId: string) => {
    decreaseCountForMenu(itemId, menuId);
  };

  const handleSelect = (
    itemId: string,
    menuId: string,
    activeProps: ActiveBasketProps,
  ) => {
    selectMenuBasketItems(itemId, menuId, activeProps);
  };

  const menuTotalCount = (productMenu: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    const menuId = menuNames?.find(item => item.id === productMenu.id)?.id;
    const selectedProduct = productMenus.find(item => item.id === menuId);

    let totalCount = 0;

    if (selectedProduct) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < selectedProduct.menu_items.length; i++) {
        totalCount +=
          ((Number(selectedProduct.menu_items[i].additional_price.amount) /
            100 || 0) +
            (selectedProduct.menu_items[i].total_price || 0)) *
          selectedProduct.menu_items[i].count;
      }
    }
    return totalCount > 0
      ? [`(+${priceFormatter(totalCount, currencyCode)})`, totalCount]
      : '';
  };

  const selectedTotalCount = () => {
    const menusPrices = productMenus.map(
      productMenu => menuTotalCount(productMenu)[1] || 0,
    );
    let totalCount = 0;

    if (menusPrices) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < menusPrices.length; i++) {
        totalCount += Number(menusPrices[i]);
      }
    }

    return totalCount > 0
      ? `(+${priceFormatter(totalCount, currencyCode)})`
      : null;
  };

  return (
    <Paper
      style={{ overflow: 'auto' }}
      sx={styles.menuModalLeftPaper}
      elevation={3}
    >
      {t('menuModel.chose')} {selectedTotalCount()}
      <Divider />
      {productMenus.length && menuNames.length
        ? productMenus.map(productMenu => (
            <>
              {/* Menu Name */}
              <Stack sx={styles.menuName}>
                {menuNames?.find(item => item.id === productMenu.id)?.name ||
                  ''}{' '}
                {menuTotalCount(productMenu)[0]}
              </Stack>

              {
                // Menu Items
                productMenu.menu_items &&
                  productMenu.menu_items.map(item => (
                    <MenuModalLeftSideMenuItems
                      currencyCode={currencyCode}
                      item={item}
                      productMenu={productMenu}
                      handleRemove={handleRemove}
                      handleDecrease={handleDecrease}
                      handleIncrease={handleIncrease}
                      setSelectedItem={setSelectedItem}
                      onMenuOnSelectionModal={onMenuOnSelectionModal}
                      setOnMenuOnSelectionModal={setOnMenuOnSelectionModal}
                      setActiveBasketProps={setActiveBasketProps}
                    />
                  ))
              }
            </>
          ))
        : ''}
      <br />
      <MenuOnSelectionModal
        name={
          selectedItem?.product_translations
            ? getActiveLanguage(selectedItem?.product_translations).name
            : ''
        }
        open={onMenuOnSelectionModal.isSelected}
        onClose={() => {
          setOnMenuOnSelectionModal({ isSelected: false, id: '' });
          setActiveBasketProps({
            activeSinglePropertyId: '',
            activeMultiplePropertyIds: [],
            activeSinglePropertyName: {
              name: '',
              price: 0,
            },
            activeMultiplePropertyNames: [],
            activeIngredientIds: [],
            activeUnitId: '',
          });
        }}
        menuItem={selectedItem}
        onSelection={() =>
          handleSelect(
            selectedItem?.itemId || '',
            selectedItem?.product_menu_id || '',
            activeBasketProps,
          )
        }
        activeBasketProps={activeBasketProps}
        setActiveBasketProps={setActiveBasketProps}
      />
    </Paper>
  );
}

export default MenuModalLeftSide;
