/** Dependencies */
import React from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

/** Components */
import EmptyState from '../../../components/EmptyState';

/** Hooks */
import useBranches from '../../../hooks/useBranches';
import useProfile from '../../../hooks/useProfile';
import keyHasPermission from '../../../helpers/keyHasPermission';

function WaiterSalesTable() {
  const { t } = useTranslation('waiterSales');
  const { meProfile } = useProfile();

  const { salesByUsers } = useBranches();

  const hasViewPermission = keyHasPermission(
    'view-daily-report',
    meProfile?.permissions || [],
  );
  const renderEmpty = () => (
    <EmptyState
      message={
        !hasViewPermission
          ? 'Bu sayfayı görmeye yetkiniz yok!'
          : t('reports.empty')
      }
    />
  );

  return (
    <Paper sx={{ width: '100%', p: 2 }}>
      {!hasViewPermission || !salesByUsers ? (
        renderEmpty()
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {t('reports.reportsTable.productName')}
                </TableCell>
                <TableCell align="center">
                  {t('reports.reportsTable.quantity')}
                </TableCell>
                <TableCell align="center">
                  {t('reports.reportsTable.amount')}
                </TableCell>
                <TableCell align="center">
                  {t('reports.reportsTable.discount')}
                </TableCell>
              </TableRow>
            </TableHead>
            {salesByUsers?.users.map(row => (
              <TableBody>
                <TableRow
                  key={row.created_by.id}
                  style={{ background: '#f0f0f0' }}
                >
                  <TableCell align="center">
                    {t('reports.reportsTable.waiter')}: {row.created_by.name}
                  </TableCell>
                  <TableCell align="center">
                    {t('reports.reportsTable.total_sales_quantity')}:{' '}
                    {row.total_sales_quantity}
                  </TableCell>
                  <TableCell align="center">
                    {t('reports.reportsTable.total_sales_amount')}:{' '}
                    {row.total_sales_amount.formatted}
                  </TableCell>
                  <TableCell align="center">
                    {t('reports.reportsTable.total_discount')}:{' '}
                    {row.total_discount.formatted}
                  </TableCell>
                </TableRow>
                {row.products.map(product => (
                  <TableRow key={product.product_id}>
                    <TableCell align="center">{product.product_name}</TableCell>
                    <TableCell align="center">
                      {product.total_quantity}
                    </TableCell>
                    <TableCell align="center">
                      {product.total_amount.formatted}
                    </TableCell>
                    <TableCell align="center"> </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ))}
            <TableBody>
              <TableRow style={{ background: '#f0f0f0', fontStyle: 'bold' }}>
                <TableCell style={{ fontWeight: '700' }} align="center">
                  {t('reports.reportsTable.total')}
                </TableCell>
                <TableCell style={{ fontWeight: '700' }} align="center">
                  {salesByUsers?.total_quantity}
                </TableCell>
                <TableCell style={{ fontWeight: '700' }} align="center">
                  {salesByUsers?.total_amount.formatted}
                </TableCell>
                <TableCell style={{ fontWeight: '700' }} align="center">
                  {salesByUsers?.total_discount.formatted}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}

export default WaiterSalesTable;
