import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/Modal';
import LoadingState from '../../../../components/LoadingState';
import useUsers from '../../../../hooks/useUsers';
import usePosOrder from '../../../../hooks/usePosOrder';
import FormSelectSearchable from '../../../../components/FormSelectSearchable';

interface Props {
  open: boolean;
  onClose: () => void;
  activeDelivererId?: string | null;
  orderId: string;
  handleSetStatus: (
    table_id: string,
    status_id: number,
    isPickedUp?: boolean,
  ) => void;
  statusId?: number;
}

function DelivererModal({
  open,
  onClose,
  activeDelivererId,
  orderId,
  handleSetStatus,
  statusId,
}: Props) {
  const { t } = useTranslation('orders');
  const { enqueueSnackbar } = useSnackbar();

  const { fetchUsers, users, userLoading } = useUsers();
  const { setDeliverer } = usePosOrder();
  const [delivererId, setDelivererId] = useState(
    activeDelivererId || users[0]?.id || '',
  );

  const handleClose = () => {
    onClose();
    setDelivererId('');
  };

  const handleSubmit = (type: string) => {
    if (delivererId || type !== 'submit') {
      if (type === 'submit') {
        setDeliverer(orderId, delivererId);
      }
      handleSetStatus(
        orderId,
        5,
        statusId === 1 || statusId === 2 ? true : !!activeDelivererId,
      );
      onClose();
    } else {
      enqueueSnackbar(t('order.deliverer_modal.empty_error'), {
        variant: 'warning',
      });
    }
  };

  useEffect(() => {
    if (open) {
      fetchUsers({ page: 1, role: 'deliverer' });
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      if (activeDelivererId) {
        setDelivererId(activeDelivererId);
      } else {
        setDelivererId(users[0]?.id || '');
      }
    }
  }, [users, open]);

  return (
    <Modal
      maxWidth="md"
      open={open}
      onClose={() => handleClose()}
      title={t('order.deliverer_modal.title')}
      positiveAction={t('order.deliverer_modal.submit')}
      onPositiveClick={() => handleSubmit('submit')}
      negativeAction={t('order.deliverer_modal.close')}
      onNegativeClick={() => handleClose()}
      extraActionOne={
        !(statusId === 1 || statusId === 2) &&
        t('order.deliverer_modal.extra_action')
      }
      onExtraActionOneClick={() => handleSubmit('withoutChoosing')}
    >
      {userLoading ? (
        <LoadingState />
      ) : (
        <FormSelectSearchable
          label={t('order.deliverer_modal.name')}
          options={users.map(customer => ({
            id: customer.id,
            text: `${customer.first_name} ${customer.last_name} - ${customer.phone_number}`,
          }))}
          value={delivererId}
          onChange={id => setDelivererId(id)}
          loading={userLoading}
          placeholder={t('order.deliverer_modal.name')}
          handleReset={() => setDelivererId('')}
        />
      )}
    </Modal>
  );
}

export default DelivererModal;
