import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../services/api';
import arrayCatcher from '../../../../helpers/errorCatcher';

export const getCustomerListThunk = createAsyncThunk(
  'customer/payment-list',
  async (
    params: {
      search: string;
      is_active?: boolean | null;
      first_name: string;
      page: number;
      is_open_account?: boolean | null;
      per_page?: number | null;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.definitions.customers.list(
        params.search,
        params.first_name,
        params.page,
        params.is_active,
        params.is_open_account,
        params.per_page,
      );
      const result = response.data;
      return result;
    } catch (err: any) {
      arrayCatcher(err?.code);
      return rejectWithValue(err);
    }
  },
);
export const getListPaymentThunk = createAsyncThunk(
  'customer/list-payment',
  async (
    params: {
      page: number;
      id: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.definitions.customers.listPayment(
        params.page,
        params.id,
      );
      const result = response.data;
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getCustomerThunk = createAsyncThunk(
  'customer/get',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.definitions.customers.view(id);
      const result = response.data.data;
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
