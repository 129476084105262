/** Dependencies */
import React from 'react';
import {
  Box,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
  List,
  ListItem,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

/** Styles */
import styles from '../styles';
import generalStyles from '../../../../../../../assets/stylesheets/generalStyles';

/** helpers */
import getActiveLanguage from '../../../../../../../helpers/getActiveLanguage';

/** Components */
import useBasketItems from '../../../../../../../hooks/useBasketItems/useBasketItems';
import {
  MenuProduct,
  Menus,
} from '../../../../../../../store/slices/basketItemSlice/types';
import { priceFormatter } from '../../../../../../../helpers/moneyFormatter';
import Icon from '../../../../../../../components/Icon';
import { ActiveBasketProps } from '../../../LeftColumn/BasketList/types';
import MenuModalLeftSideMenuItemsCountButtons from './MenuModalLeftSideMenuItemsCountButtons';
import MenuModalLeftSideMenuItemsPriceAndButtons from './MenuModalLeftSideMenuItemsPriceAndButtons';
import MenuModalLeftSideMenuItemsNameAndPropertyInfo from './MenuModalLeftSideMenuItemsNameAndPropertyInfo';

interface Props {
  currencyCode: string;
  item: MenuProduct;
  productMenu: Menus;
  handleRemove: (
    basketItem: MenuProduct,
    menuId: string,
    itemId: string,
  ) => void;
  handleDecrease: (itemId: string, menuId: string) => void;
  handleIncrease: (itemId: string, menuId: string) => void;
  setSelectedItem: React.Dispatch<
    React.SetStateAction<MenuProduct | undefined>
  >;
  onMenuOnSelectionModal: { isSelected: boolean; id: string };
  setOnMenuOnSelectionModal: (
    value:
      | ((prevState: { isSelected: boolean; id: string }) => {
          isSelected: boolean;
          id: string;
        })
      | { isSelected: boolean; id: string },
  ) => void;
  setActiveBasketProps: React.Dispatch<React.SetStateAction<ActiveBasketProps>>;
}

function MenuModalLeftSideMenuItems({
  currencyCode,
  item,
  productMenu,
  handleIncrease,
  handleDecrease,
  handleRemove,
  setSelectedItem,
  onMenuOnSelectionModal,
  setOnMenuOnSelectionModal,
  setActiveBasketProps,
}: Props) {
  const { productMenus } = useBasketItems();
  const { t } = useTranslation('pos');

  const isActiveIngredients = (ingredientGroups: any) =>
    ingredientGroups.length
      ? ingredientGroups.some(
          (ingredientItem: any) =>
            ingredientItem.items.filter((propItem: any) => propItem.is_active)
              .length,
        )
      : false;

  const isActiveProperties = (propertyGroups: any) =>
    propertyGroups.length
      ? propertyGroups.some(
          (propertyItem: any) =>
            propertyItem.items.filter((propItem: any) => propItem.is_active)
              .length,
        )
      : false;

  return (
    <>
      <Stack direction="row" sx={{ color: 'red', marginTop: '10px' }}>
        <MenuModalLeftSideMenuItemsCountButtons
          item={item}
          productMenu={productMenu}
          handleRemove={handleRemove}
          handleDecrease={handleDecrease}
          handleIncrease={handleIncrease}
        />
        <Stack
          sx={generalStyles.generalFlex}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            if (
              isActiveProperties(item.property_groups) ||
              isActiveIngredients(item.ingredient_groups)
            ) {
              setSelectedItem(item);
              setTimeout(() => {
                setOnMenuOnSelectionModal({
                  ...onMenuOnSelectionModal,
                  isSelected: true,
                });
              }, 500);
              setActiveBasketProps({
                activeIngredientIds: item.non_ingredient_item_id || [],
                activeUnitId: '',
                activeSinglePropertyId: item.active_single_property_id || '',
                activeMultiplePropertyIds:
                  item.active_multiple_property_id || [],
                activeSinglePropertyName: item.active_single_property_name || {
                  name: '',
                  price: 0,
                },
                activeMultiplePropertyNames:
                  item.active_multiple_property_names || [],
              });
            }
          }}
        >
          <MenuModalLeftSideMenuItemsNameAndPropertyInfo item={item} />
          <MenuModalLeftSideMenuItemsPriceAndButtons
            currencyCode={currencyCode}
            item={item}
            productMenu={productMenu}
            handleRemove={handleRemove}
          />
        </Stack>
      </Stack>
      <Divider />
    </>
  );
}

export default MenuModalLeftSideMenuItems;
