import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type SectionBase = {
  id: string;
  name: string;
  [key: string]: any;
  sales_channel?: { id: string; name: string };
};
export type TableBase = {
  id: string;
  name: string;
  [key: string]: any;
};
export type CountTable = {
  id: string;
  name: string;
  [key: string]: any;
  count: number;
};

const schema = yup.object({
  name: yup.string().required('addBulkTable.form.required'),
  count: yup.string().required('addBulkTable.form.amount'),
});

export const validationSchema = yupResolver(schema);
