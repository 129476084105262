import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import arraySwap from '../../../../helpers/arraySwap';
import { getTableListThunk } from './thunks';

import { InitialState, Table } from './types';

const initialState: InitialState = { loading: false, tables: [] };

const tableSlice = createSlice({
  name: 'tableSlice',
  initialState,
  reducers: {
    addTableSlice: (state, action: PayloadAction<Table>) => ({
      ...state,
      tables: [...state.tables, action.payload],
    }),
    addCountTableSlice: (state, action: PayloadAction<Table>) => ({
      ...state,
      tables: state.tables.map(
        () => action.payload,
        // item.id === action.payload.id ? action.payload : item,
      ),
    }),
    updateTableSlice: (state, action: PayloadAction<Table>) => ({
      ...state,
      tables: state.tables.map(item =>
        item.id === action.payload.id ? action.payload : item,
      ),
    }),
    updateTableSliceWithPusher: (state, action: PayloadAction<Table[]>) => ({
      ...state,
      tables: action.payload,
    }),
    removeTableSlice: (state, action: PayloadAction<Pick<Table, 'id'>>) => ({
      ...state,
      tables: state.tables.filter(item => item.id !== action.payload.id),
    }),
    moveUpSlice: (state, action: PayloadAction<Pick<Table, 'id'>>) => {
      const targetIndex = state.tables.findIndex(
        item => item.id === action.payload.id,
      );
      return {
        ...state,
        tables: arraySwap(state.tables, targetIndex, targetIndex - 1),
      };
    },
    moveDownSlice: (state, action: PayloadAction<Pick<Table, 'id'>>) => {
      const targetIndex = state.tables.findIndex(
        item => item.id === action.payload.id,
      );
      return {
        ...state,
        tables: arraySwap(state.tables, targetIndex, targetIndex + 1),
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getTableListThunk.pending, state => ({
        ...state,
        loading: true,
      }))
      .addCase(getTableListThunk.fulfilled, (state, action) => ({
        ...state,
        tables: action.payload,
        loading: false,
      }))
      .addCase(getTableListThunk.rejected, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
      }));
  },
});

export const {
  addTableSlice,
  updateTableSlice,
  removeTableSlice,
  addCountTableSlice,
  moveUpSlice,
  moveDownSlice,
  updateTableSliceWithPusher,
} = tableSlice.actions;

export default tableSlice.reducer;
