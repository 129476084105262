/** Dependencies */
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export type CustomerAddressBase = {
  id: string;
  name: string;
  address: string;
  is_default: boolean;
  note?: string;
};

const schema = yup.object({
  name: yup.string().required('customerAddress.addModal.form.name.required'),
  address: yup
    .string()
    .required('customerAddress.addModal.form.address.required'),
  is_default: yup.boolean(),
  note: yup.string(),
});

export const validationSchema = yupResolver(schema);

export type GetPaidBase = {
  id: string;
  amount: string;
  payment_type_id: string;
};

export type CustomerBase = {
  id: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  is_active: boolean;
  is_open_account: boolean;
  phone_number_country: string;
  chargeable_amount?: number | null;
};
export type CustomerBase1 = {
  id: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  is_active: boolean;
  is_open_account: boolean;
  phone_number_country: string;
  chargeable_amount?: {
    amount: string;
    currency: string;
    formatted: string;
  };
};

const schemaEditCustomer = yup.object({
  first_name: yup
    .string()
    .required('customer.addModal.form.first_name.required'),
  last_name: yup.string().required('customer.addModal.form.last_name.required'),
  phone_number: yup.string(),
  is_active: yup
    .boolean()
    .required('customer.addModal.form.is_active.required'),
});

export const validationEditSchema = yupResolver(schemaEditCustomer);
