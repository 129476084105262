/** Dependencies */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Styles */
import {
  Box,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import styles from '../styles';

/** Hooks */
import useDebounce from '../../../hooks/useDebounce/useDebounce';
import useKitchenGroups from '../../../hooks/useKitchenGroups';
import SearchBar from '../../../components/SearchBar';
import SearchSelectBar from '../../../components/SearchSelectBar';
import { OptionsMemoWithName } from '../../../helpers/optionMemo';
import Icon from '../../../components/Icon';
import Button from '../../../components/Button';

interface Props {
  searchValue: { inputValue: string; isActive: boolean | null };
  selectValue: string[];
  debouncedSearch: { inputValue: string; isActive: boolean };
  setSearchValue: React.Dispatch<
    React.SetStateAction<{ inputValue: string; isActive: boolean | null }>
  >;
  setDebouncedSearch: React.Dispatch<
    React.SetStateAction<{ inputValue: string; isActive: boolean }>
  >;
  setSelectValue: React.Dispatch<React.SetStateAction<string[]>>;
  debouncedAndFilteredSearch: () => void;
}

function KitchenFilterBar({
  searchValue,
  debouncedSearch,
  setSearchValue,
  setDebouncedSearch,
  selectValue,
  setSelectValue,
  debouncedAndFilteredSearch,
}: Props) {
  const { t } = useTranslation('kitchen');
  const { kitchenGroups, fetchKitchenGroups } = useKitchenGroups();
  const [reloadTime, setReloadTime] = useState<number>(0);

  const handleChange = (event: SelectChangeEvent<typeof selectValue>) => {
    const {
      target: { value },
    } = event;

    setSelectValue(typeof value === 'string' ? value.split(',') : value);
  };

  const handleFilterSubmit = () => {
    debouncedAndFilteredSearch();
  };

  const handleAutoReload = (time: number) => {
    setReloadTime(time);
    localStorage.setItem('reloadTime', JSON.stringify(time));
  };

  setDebouncedSearch(useDebounce(searchValue, 1000));

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (reloadTime) {
      const interval = setInterval(
        () => debouncedAndFilteredSearch(),
        1000 * (reloadTime || 30),
      );
      return () => {
        clearInterval(interval);
      };
    }
  }, [reloadTime, selectValue, debouncedSearch.inputValue]);

  useEffect(() => {
    debouncedAndFilteredSearch();

    setReloadTime(Number(localStorage.getItem('reloadTime')));
  }, [debouncedSearch.inputValue]);

  useEffect(() => {
    fetchKitchenGroups();
    setReloadTime(Number(localStorage.getItem('reloadTime') || 30));
  }, []);

  return (
    <>
      <Paper sx={{ mb: 3 }}>
        <Grid p={2} container justifyContent="space-between">
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <Grid>
                <Typography variant="h5">{t('navbar.title')}</Typography>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Select
              value={reloadTime || 30}
              autoWidth
              variant="outlined"
              color="primary"
              size="medium"
              sx={{
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                background: '#f5f8fa',
                color: 'black',
                '.MuiSvgIcon-root ': {
                  color: 'black !important',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black',
                },
                '& .MuiSelect-select .notranslate::after': {
                  opacity: 0.42,
                },
                '&:hover': {
                  '&& fieldset': {
                    border: 'none',
                  },
                },
              }}
            >
              <MenuItem value={30} onClick={() => handleAutoReload(30)}>
                {t('navbar.timeHalfMinute')}
              </MenuItem>
              <MenuItem value={60} onClick={() => handleAutoReload(60)}>
                {t('navbar.timeEveryMinute')}
              </MenuItem>
            </Select>
            <Button
              sx={{ padding: '20px', color: 'black' }}
              key={t('navbar.refresh')}
              variant="text"
              onClick={() => debouncedAndFilteredSearch()}
              startIcon={<Icon name="Refresh" />}
            >
              {t('navbar.refresh')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <br />
      <Grid container>
        <Grid xs={8} sm={9}>
          <SearchBar
            placeholder={t('search.check')}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedSearch={setDebouncedSearch}
          />
        </Grid>
        <Grid xs={4} sm={3} sx={styles.kitchenFilterBarContainer}>
          <SearchSelectBar
            selectValue={selectValue}
            handleChange={handleChange}
            content={t('search.kitchen_group')}
            options={OptionsMemoWithName(kitchenGroups)}
            filterButton
            handleFilterSubmit={handleFilterSubmit}
            filterText={t('search.filter')}
          />
        </Grid>
      </Grid>
      <br />
    </>
  );
}

export default KitchenFilterBar;
