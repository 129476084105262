/** Dependencies */
import React from 'react';

/** Styles */
import { Box } from '@mui/material';

/** Components */
import Form from './Form';

function CustomerDisplay() {
  return (
    <Box>
      <Form />
    </Box>
  );
}

export default CustomerDisplay;
