import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isApiError } from '../../services/api/types';
import { useAppDispatch, useAppSelector } from '../../store';
import { getPropertyGroupsListThunk } from '../../store/slices/definitionsSlice/propertyGroups';
import errorReporting from '../../helpers/errorReporting';
import api from '../../services/api';
import {
  addPropertyGroupsSlice,
  addPropertyItemSlice,
  removePropertyGroupsSlice,
  removePropertyItemSlice,
  updatePropertyGroupsSlice,
  updatePropertyItemSlice,
} from '../../store/slices/definitionsSlice/propertyGroups/propertyGroupsSlice';

const usePropertyGroups = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const propertyGroups = useAppSelector(
    state => state.definitionsPropertyGroups.propertyGroups,
  );
  const propertyGroupsLoading = useAppSelector(
    state => state.definitionsPropertyGroups.loading,
  );

  const propertyGroupsError = useAppSelector(
    state => state.definitionsPropertyGroups.error,
  );

  const fetchPropertyGroups = () => dispatch(getPropertyGroupsListThunk());

  const addPropertyGroups = async (
    name: { [key: string]: string },
    isMulti: boolean,
  ) => {
    try {
      setLoading(true);
      const response = await api.definitions.propertyGroups.create(
        name,
        isMulti,
      );
      const result = response.data.data;
      dispatch(addPropertyGroupsSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      errorReporting(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const updatePropertyGroups = async ({
    id,
    name,
    isMulti,
  }: {
    id: string;
    name: { [key: string]: string };
    isMulti: boolean;
  }) => {
    try {
      setLoading(true);
      const response = await api.definitions.propertyGroups.update({
        id,
        name,
        isMulti,
      });
      const result = response.data.data;
      dispatch(updatePropertyGroupsSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const removePropertyGroups = async (id: string) => {
    try {
      setDeleting(true);
      const response = await api.definitions.propertyGroups.remove(id);
      const result = response.data.data;
      dispatch(removePropertyGroupsSlice(result));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      throw err;
    } finally {
      setDeleting(false);
    }
  };

  const addPropertyItem = async (
    groupId: string,
    name: { [key: string]: string },
    additional_price: number,
  ) => {
    try {
      setLoading(true);
      const response = await api.definitions.propertyGroups.itemCreate({
        groupId,
        name,
        additional_price,
      });
      const result = response.data.data;
      dispatch(addPropertyItemSlice({ ...result, groupId }));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const updatePropertyItem = async ({
    groupId,
    id,
    name,
    additional_price,
  }: {
    groupId: string;
    id: string;
    name: { [key: string]: string };
    additional_price: number;
  }) => {
    try {
      setLoading(true);
      const response = await api.definitions.propertyGroups.itemUpdate({
        id,
        groupId,
        name,
        additional_price,
      });
      const result = response.data.data;
      dispatch(updatePropertyItemSlice({ ...result, groupId }));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const removePropertyItem = async (groupId: string, id: string) => {
    try {
      setDeleting(true);
      const response = await api.definitions.propertyGroups.itemRemove(
        groupId,
        id,
      );
      const result = response.data.data;
      dispatch(removePropertyItemSlice({ ...result, groupId }));
    } catch (err: any) {
      enqueueSnackbar(
        isApiError(err) ? err.message : t('common:unknownError' as any),
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
          autoHideDuration: 3000,
        },
      );
      throw err;
    } finally {
      setDeleting(false);
    }
  };

  const propertyItems = (groupId: string | undefined) => {
    if (groupId) {
      return propertyGroups.find(group => group.id === groupId)?.items || [];
    }
    return [];
  };

  return {
    propertyGroups,
    propertyItems,
    propertyGroupsLoading,
    propertyGroupsError,
    fetchPropertyGroups,
    addPropertyGroups,
    updatePropertyGroups,
    removePropertyGroups,
    addPropertyItem,
    updatePropertyItem,
    removePropertyItem,
    deleting,
    loading,
  };
};
export default usePropertyGroups;
