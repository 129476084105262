import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../services/api';
import { isApiError } from '../../services/api/types';

const useKitchenOrder = () => {
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();

  const [orderItemGroupsDetailsLoading, setOrderItemGroupsDetailsLoading] =
    useState(false);
  const [orderItemGroupsDetails, setOrderItemGroupsDetails] = useState<any[]>();
  const [orderItemGroupsDetailsError, setOrderItemGroupsDetailsError] =
    useState<null | Error>(null);

  const getOrderItemGroups = async (
    search: string,
    is_active: boolean,
    kitchen_group_id?: string[] | null,
  ) => {
    try {
      const result = await api.kitchen.list(
        search,
        is_active,
        kitchen_group_id || null,
      );
      setOrderItemGroupsDetails(result.data.data);
    } catch (err) {
      setOrderItemGroupsDetailsError(err as Error);
    } finally {
      setOrderItemGroupsDetailsLoading(false);
    }
  };

  const updateOrderItemGroup = async (status: number, item_id: string) => {
    try {
      const updatedOrder = await api.kitchen.updateOrderItemGroup(
        status,
        item_id,
      );
      setOrderItemGroupsDetails(
        orderItemGroupsDetails?.map(item =>
          item.id === updatedOrder.data.data.id ? updatedOrder.data.data : item,
        ),
      );
      // window.location.reload();
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
      });
    }
  };

  const updateOrderItem = async (status: number, item_id: string) => {
    try {
      const updatedOrder = await api.kitchen.updateOrderItem(status, item_id);
      setOrderItemGroupsDetails(
        orderItemGroupsDetails?.map(item =>
          item.id === updatedOrder.data.data.id ? updatedOrder.data.data : item,
        ),
      );
      // window.location.reload();
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
      });
    }
  };

  const changeOrderItemGroupState = async (item_id: string) => {
    try {
      const changedOrder = await api.kitchen.changeOrderItemGroupState(item_id);
      setOrderItemGroupsDetails(
        orderItemGroupsDetails?.filter(
          item => item.id !== changedOrder.data.data.id,
        ),
      );
    } catch (err) {
      enqueueSnackbar(isApiError(err) ? err.message : t('unknownError'), {
        variant: 'error',
      });
    }
  };

  return {
    getOrderItemGroups,
    orderItemGroupsDetails,
    orderItemGroupsDetailsLoading,
    orderItemGroupsDetailsError,
    updateOrderItemGroup,
    updateOrderItem,
    changeOrderItemGroupState,
  };
};
export default useKitchenOrder;
